import { AiInsight } from '../context/AiInsightsApiTypesServices';

export const examplePayload: AiInsight[] = [
	{
		kpi: 'AbandonedBasketAmount', 
		category: 'AbandonedBasket',
		date: ' 2024-11-03',
		translatedFindingName: 'Le nombre de paniers abandonnés', 
		findingType: 'BigUplift',
		favorableness: -1,
		period: 'Last7Days',
		dimensions: {
			TrafficSource: 'Paid',
			DeviceType: 'Mobile',
			VisitorEngagement: 'X',
		},
		findingData: {
			AbandonedBasketAmount: {
				translatedDescription: 'Paniers abandonnés', 
				absolute: 14502,
				absoluteType: 'Number',
				relative: 0.207, 
				favorableness: -1,
			},
		},
	},
	{
		kpi: 'Conversion',
		category: 'Product',
		date: ' 2024-11-03',
		translatedFindingName: 'Le produit 6848652',
		findingType: 'ProductPositiveSpotlight',
		favorableness: 1,
		period: 'LastDay',
		dimensions: {},
		findingData: {
			Conversions: {
				translatedDescription: 'Achats',
				absolute: 0.45,
				absoluteType: 'Percentage',
				favorableness: 1,
			},
			Turnover: {
				translatedDescription: 'Chiffre d\'affaire',
				absolute: 8546,
				absoluteType: 'Amount',
				favorableness: 1,
			},
		},
		findingAdditionalParams: {
			ProductId: '6848652',
		},
	},
	{
		kpi: 'Conversion',
		category: 'Product',
		date: ' 2024-11-03',
		translatedFindingName: 'Le produit 6848652',
		findingType: 'ProductPositiveSpotlight',
		favorableness: -1,
		period: 'LastDay',
		dimensions: {},
		findingData: {
			Turnover: {
				translatedDescription: 'CA / Achats',
				absolute: 8546,
				absoluteType: 'Amount',
				relative: 101,
				favorableness: -1,
			},
		},
		findingAdditionalParams: {
			ProductId: '6848652',
		},
	},
	{
		kpi: 'Conversion',
		category: 'Conversion',
		date: ' 2024-11-03',
		translatedFindingName: 'Votre taux de réachat',
		findingType: 'NegativeBusinessComparison',
		favorableness: -1,
		period: 'Last7Days',
		dimensions: {},
		findingData: {

			AbandonedBasketAmount: {
				translatedDescription: 'Taux de réachat',
				absolute: 0.012,
				absoluteType: 'Percentage',
				favorableness: -1,
			},

		},
	},
];