import React, { useEffect, useState } from 'react';

const Highcharts = window.Highcharts;

const ChartEmerchComparison = ({
	tagStudiedEl,

	visitsAverage,
	addToCartRateAverage,
	conversionRateAverage,

	visitsAverageComparison,
	addToCartRateAverageComparison,
	conversionRateAverageComparison,

	emercheOrdinate
}) => {

	const [chartOptions, setChartOptions] = useState({});

	useEffect(() => {
		if (tagStudiedEl && tagStudiedEl.length === 2) {
			const options = {
				chart: {
					type: 'bubble',
					plotBorderWidth: 1,
					zoomType: 'xy',
					zooming: {
						mouseWheel: {
							enabled: false
						}
					},
					panning: { enabled: true, type: 'xy' },
					panKey: 'shift',
					events: {
						render: function () {
						// @ts-ignore
						// eslint-disable-next-line @typescript-eslint/no-this-alias
							const chart = this;
							// @ts-ignore
							if (!chart.labelAdded) {
							// @ts-ignore
								chart.renderer.text('0', 20, 480)
									.css({ color: '#d9d7d7', fontSize: '14px' })
									.add();
								// @ts-ignore
								chart.labelAdded = true;
							}
						}
					}
				},
				title: { text: '' },
				xAxis: {
					title: { text: 'Visits' },
					plotLines: [
						{
							color: '#006fff',
							width: 2,
							value: visitsAverage,
							dashStyle: 'dash',
							label: {
								text: 'Average number of visits',
								align: 'top',
								style: { color: '#006fff' }
							}
						},
						{
							color: '#ffa133',
							width: 2,
							value: visitsAverageComparison,
							dashStyle: 'dash',
							label: {
								text: 'Average number of visits',
								align: 'top',
								style: { color: '#ffa133' }
							}
						}
					],
					gridLineWidth: 1,
					minRange: 1
				},
				yAxis: {
					title: {
						text: emercheOrdinate === 'addToCartRate' ? 'Add-to-cart rate (%)' :
							emercheOrdinate === 'conversionRate' ? 'Conversion rate (%)' : 'Conversions'
					},
					plotLines: [
						{
							color: '#006fff',
							width: 2,
							value: emercheOrdinate === 'addToCartRate' ? addToCartRateAverage : conversionRateAverage,
							dashStyle: 'dash',
							label: {
								text: 'Average ' + (emercheOrdinate === 'addToCartRate' ? 'add-to-cart rate' :
									emercheOrdinate === 'conversionRate' ? 'conversion rate' : 'conversions'),
								align: 'right',
								style: { color: '#006fff' }
							}
						},
						{
							color: '#ffa133',
							width: 2,
							value: emercheOrdinate === 'addToCartRate' ? addToCartRateAverageComparison : conversionRateAverageComparison,
							dashStyle: 'dash',
							label: {
								text: 'Average ' + (emercheOrdinate === 'addToCartRate' ? 'add-to-cart rate' :
									emercheOrdinate === 'conversionRate' ? 'conversion rate' : 'conversions'),
								align: 'right',
								style: { color: '#ffa133' }
							}
						}
					],
					labels: {
						format: '{value}' + (emercheOrdinate === 'addToCartRate' || emercheOrdinate === 'conversionRate' ? '%' : '')
					},
					gridLineWidth: 1,
					minRange: 0.1
				},
				tooltip: {
					useHTML: true,
					headerFormat: '<div class="graph_tooltip">',
					pointFormat: '<p><span>{point.tagType}</span>{point.tagName}</p>' +
						'<div class="tooltip_label">Visits</div>' +
						'<div><span>studied</span>{point.visits}</div>' +
						'<div class="tooltip_compared"><span>compared</span> {point.visitsComparison}</div>' +
						'<div class="tooltip_label">Add-to-cart</div>' +
						'<div>{point.addToCart} ({point.y}%)</div>' +
						'<div class="tooltip_compared">{point.addToCartComparison} ({point.addToCartRateComparison}%)</div>' +
						'<div class="tooltip_label">Amount </div>' +
						'<div>{point.amountReference} €</div>' +
						'<div class="tooltip_compared">{point.amountComparison} €</div>' +
						'<div class="tooltip_label">Conversion</div>' +
						'<div>{point.conversions} ({point.conversionsRate}%)</div>' +
						'<div class="tooltip_compared">{point.conversionsComparison}  ({point.conversionsRateComparison}%)</div>' +
						'<div class="tooltip_label">Abandonments</div>' +
						'<div>{point.abandonments} ({point.abandonmentsRate}%)</div>' +
						'<div class="tooltip_compared">{point.abandonmentsComparison}  ({point.abandonmentsRateComparison}%)</div>',
					footerFormat: '</div>',
					crosshairs: true,
					backgroundColor: '#38444A',
					// @ts-ignore
					color: '#FFF',
					style: { color: '#FFFFFF' },
					outside: true
				},
				plotOptions: {
					bubble: {
						minSize: 20,
						maxSize: 120,
						zMin: 0,
						zMax: Math.max(...tagStudiedEl[0].data.map(point => point.z), ...tagStudiedEl[1].data.map(point => point.z)),
						dataLabels: {
							enabled: true,
							format: '{point.tagName}',
							style: { textOutline: 'none', fontWeight: 'normal' }
						},
						animation: { duration: 1000 },
						states: { hover: { halo: { size: 5 } } }
					}
				},
				navigation: { buttonOptions: { enabled: true } },
				exporting: {
					enabled: true,
					buttons: {
						contextButton: {
							menuItems: ['viewFullscreen', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
						}
					}
				},
				series: [
					{
						data: tagStudiedEl[0].data,
						name: 'Studied period',
						marker: { fillOpacity: 0.5, lineWidth: 0, fillColor: '#006fff' }
					},
					{
						data: tagStudiedEl[1].data,
						name: 'Previous period',
						marker: { fillColor: '#ffa133',fillOpacity: 0.5, lineWidth: 0, }
					}
				],
				credits: { enabled: false }
			};

			setChartOptions(options);
		} else {
			console.error('tagStudiedEl is not defined or does not contain two datasets');
		}
	}, [
		tagStudiedEl,
		visitsAverage,
		addToCartRateAverage,
		conversionRateAverage,
		visitsAverageComparison,
		addToCartRateAverageComparison,
		conversionRateAverageComparison,
		emercheOrdinate
	]);

	useEffect(() => {
		if (Object.keys(chartOptions).length > 0) {
			Highcharts.chart('chart-container', chartOptions);
		}
	}, [chartOptions]);

	return (
		<div id="chart-container" style={{ height: '550px', minWidth: '310px' }}></div>
	);
};

export default ChartEmerchComparison;
