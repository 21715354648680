import React, { } from 'react';
import RouterItems from '../../Menu/RouterItems.tsx';
import { getFinalUrl } from '../../Menu/utils';
export default function RouterWrapper({
	children,
	props
}) {
	const permissionsAccount = Object.keys(
		props.$rootScope.$$childHead.permissions
	).map((x) => ({
		Name: x,
		Value: props.$rootScope.$$childHead.permissions[x],
	}));

	const viewABtest = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'ABTESTS_CAN_VIEW');
	const viewPerfCampaign = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'PERFCAMPAIGN_CAN_VIEW');
	const viewNotifCampaign = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'NOTIFICATIONCENTER_CAN_VIEW');
	const viewServerSideCampaign = { Value: true };// permissionsAccount && permissionsAccount.find(perm => perm.Name === 'SERVERSIDE_CAN_VIEW');

	let list = [
		{
			label: 'Campaigns',
			icon: 'list',
			activeUrls: ['/Onsite/Dashboard'],
			link: getFinalUrl('/Onsite/Dashboard', props)
		}
	];
	viewABtest && viewABtest.Value === true && list.push({
		label: 'A/B test',
		icon: 'abtest',
		items: [
			{
				label: 'Pending',
				icon: 'abtest',
				activeUrls: ['/ABTests/Dashboard/Pending'],
				link: getFinalUrl('/ABTests/Dashboard/Pending', props)
			},
			{
				label: 'Completed',
				icon: 'abtest',
				activeUrls: ['/ABTests/Dashboard/Completed'],
				link: getFinalUrl('/ABTests/Dashboard/Completed', props)
			},
		],
	});
	list.push(
		{
			label: 'Planning',
			icon: 'planning',
			activeUrls: ['/Onsite/Planning'],
			link: getFinalUrl('/Onsite/Planning', props)
		},
		{
			label: 'Orchestration',
			icon: 'orchestration',
			activeUrls: ['/Dashboard/Orchestration'],
			link: getFinalUrl('/Dashboard/Orchestration', props)
		}
	);
	
	if (viewNotifCampaign && viewNotifCampaign.Value === true) {
		list.push(
			{
				label: 'Notification center',
				icon: 'notification',
				activeUrls: ['/NotificationCenter/Dashboard'],
				link: getFinalUrl('/NotificationCenter/Dashboard', props)
			}
		);
	}

	if (viewPerfCampaign && viewPerfCampaign.Value === true) {
		list.push({
			label: 'Performance tracking',
			icon: 'performance_cpg',
			activeUrls : ['/PerformanceTracking/Dashboard'],
			link :  getFinalUrl('/PerformanceTracking/Dashboard',props)
		});
	}

	if (viewServerSideCampaign && viewServerSideCampaign.Value === true && 1 === 0) {
		list.push({
			label : 'Server Side',
			icon: 'performance_cpg',
			activeUrls : ['/ServerSide/Dashboard'],
			link :  getFinalUrl('/ServerSide/Dashboard',props)
		});
	}


	return (
		<RouterItems
			title="On-site activation"
			list={list}
			routes={<></>}
		>
			{children}
		</RouterItems>
	);
}
