import React from 'react';
import styles from './Chip.module.css';

const Chip = ({ type = 'Active visitors', title = 'title', handleDelete }) => (
	<div className="btn_wrapper">
		<div className={styles.chip} onClick={handleDelete}>
			<div className="flex">
				<div className="flex_item_full_fix">
					<div className={styles.chip_type}>{type}</div>
					<div className={styles.chip_value}>{title}</div>
				</div>
				<div className="flex_item_fix analytics_range_close">
					<i className='fas fa-times-circle'></i>
				</div>
			</div>
		</div>
	</div>
);

Chip.displayName = 'Chip';
export default Chip;