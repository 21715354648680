import React, { useState } from 'react';
import moment from 'moment';
import { ItemProcess, ProcessRequestModel, RequestState, ProcessModel, ProcessType } from '../context/typesWorkflow';
import { useWorkFlowContext } from '../context/WorkflowProcessContext';
import { formatLabel } from '../utils';
import RequestItem from '../UI/RequestItem';
import ExpandableSection from '../UI/ExpandSection';
import PanelProcessesByIdentifiers from '../UI/PanelProcessesByIdentifiers';
import Btn from '../../../../Components/Btn';
import TableGridContainer from '../../../../Components/Table/TableGridContainer';
import TableHeadRow from '../../../../Components/Table/TableHeadRow';
import TableHeadCol from '../../../../Components/Table/TableHeadCol';
import EmptyState from '../../../../Components/EmptyState';
import Checkbox from '../../../../Components/Checkbox';

const tableRowStyle = {
	gridTemplateColumns: '2.7fr 140px 1fr 1fr 2fr 1fr',
	alignItems: 'center',
	justifyContent: 'flex-start',
};

const centerStyle = {
	display: 'flex',
	alignItems: 'center',
	height: '100%',
};

export const WorkflowProcessContainer = () => {
	const { workflowProcessItems, sendAWorkflowRequest } = useWorkFlowContext();
	const [processByIdentifiersPanelIsOpen, setProcessByIdentifiersPanelIsOpen] = useState(false);
	const [processByIdentifiers, setProcessByIdentifiers] = useState<[ProcessType | null, ProcessModel[]]>([null, []]);

	const [selectedProcessTypes, setSelectedProcessTypes] = useState<ProcessType[]>([]);

	if (!workflowProcessItems || workflowProcessItems.length === 0) {
		return (
			<div className="empty-state">
				<EmptyState
					title={'No process found'}
					text={'There are currently no items in the workflow. Please check back later.'}
					icon={'far fa-star'}
					verticalSize='s'
				/>
			</div>
		);
	}

	const handleLaunchSelectedProcessTypes = () => {
		for (let index = 0; index < selectedProcessTypes.length; index++) {
			const processType = selectedProcessTypes[index];
			const requestApis = [
				{
					processType: processType,
					processIdentifier: '',
					force: false,
				},
			];
			sendAWorkflowRequest(requestApis);

		}
		setProcessByIdentifiersPanelIsOpen(false);
	};

	const handleRefreshByItemsSelected = (type: string, process: Array<string>) => {
		const requestApis = process.map((state) => ({
			processType: type,
			processIdentifier: state,
			force: false,
		}));
		sendAWorkflowRequest(requestApis);
		setProcessByIdentifiersPanelIsOpen(false);
	};

	const handleOpenClosePanel = (open: boolean) => {
		setProcessByIdentifiersPanelIsOpen(open);
	};

	const handleOpenPanel = (processType: ProcessType, processWithIdentifiers: ProcessModel[]) => {
		setProcessByIdentifiers([processType, processWithIdentifiers]);
		setProcessByIdentifiersPanelIsOpen(true);
	};

	const handleProcessTypeSelected = (processType: ProcessType) => {
		if (selectedProcessTypes.includes(processType)) {
			setSelectedProcessTypes(x => x.filter(t => t != processType));
		} else {
			setSelectedProcessTypes(x => [...x, processType]);
		}
	};

	const processTypesThatSupportIdentifiers = ['ProductRecommendations'];

	return (
		<div>
			<div>
				<span>launch selected: </span>
				<Btn
					style={'outline'}
					onClick={() => handleLaunchSelectedProcessTypes()}
					disabled={selectedProcessTypes.length == 0}
					tooltip={'Launch'}
					icon={'fa fa-sync'}
					
				/>
			</div>

			<PanelProcessesByIdentifiers
				handleOpenPanel={handleOpenClosePanel}
				isOpen={processByIdentifiersPanelIsOpen}
				processByIdentifiers={processByIdentifiers}
				handleRefreshByItemsSelected={handleRefreshByItemsSelected}
			/>
			<TableGridContainer
				hasAlternatingBackground={true}
				specialClassName={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
			>
				<TableHeadRow style={tableRowStyle} isSticky={true}>
					<TableHeadCol fontSize='s'>Process Type</TableHeadCol>
					<TableHeadCol fontSize='s'>Message</TableHeadCol>
					<TableHeadCol fontSize='s'>Process State</TableHeadCol>
					<TableHeadCol fontSize='s'>Last State Date</TableHeadCol>
					<TableHeadCol fontSize='s'>Requests</TableHeadCol>
					<TableHeadCol fontSize='s'>Actions</TableHeadCol>
				</TableHeadRow>
				{workflowProcessItems.map((process: ItemProcess, index) => (
					<React.Fragment key={index}>
						<TableHeadRow style={tableRowStyle}>
							<TableHeadCol style={centerStyle}>{formatLabel(process.processType)}</TableHeadCol>
							<TableHeadCol style={centerStyle}>{process.mainState.message}</TableHeadCol>
							<TableHeadCol style={centerStyle}>{process.mainState.processState}</TableHeadCol>
							<TableHeadCol style={centerStyle}>
								{moment(process.mainState.stateDateUtc).format('dddd, MMMM Do YYYY')}
							</TableHeadCol>
							<TableHeadCol style={centerStyle}>
								<div>
									{process.mainState.requests?.map((request: ProcessRequestModel, reqIndex) => (
										<ExpandableSection key={reqIndex} title={request.requestStatus}>
											<RequestItem key={`${index}-${reqIndex}`} states={request} />
										</ExpandableSection>
									))}
									{processTypesThatSupportIdentifiers.includes(process.processType) && (
										<div>
											{process.stateWithIdentifiers?.map((state, stateIndex) => {
												const key = `${stateIndex}-${state.processIdentifier}`;
												if (!state.requests) return null;
												return state?.requests?.length > 0 ? (
													<ExpandableSection key={key} title={state.processState}>
														{state.requests?.map((request: RequestState, reqIndex) => (
															<div key={`${stateIndex}-${reqIndex}`}>
																<RequestItem states={request} />
															</div>
														))}
													</ExpandableSection>
												) : null;
											})}
										</div>
									)}
								</div>
							</TableHeadCol>
							<TableHeadCol style={centerStyle}>
								<div style={centerStyle}>
									<Checkbox
										name="restart"
										forcedChecked={selectedProcessTypes.includes(process.processType)}
										value={selectedProcessTypes.includes(process.processType)}
										onChange={() => handleProcessTypeSelected(process.processType)}
									>
									</Checkbox>
									{process.stateWithIdentifiers?.length > 0 && (
										<Btn
											style={'outline'}
											tooltip={'Launch specific identifiers'}
											icon={'fa fa-cog'}
											onClick={() => handleOpenPanel(process.processType, process.stateWithIdentifiers)}
										/>
									)}
								</div>
							</TableHeadCol>
						</TableHeadRow>
					</React.Fragment>
				))}
			</TableGridContainer>
		</div>
	);
};