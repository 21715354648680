import React from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './Copy.module.css';

interface ICopy {
	children: React.ReactNode;
	textToCopy: string;
	className?: string;
	appearence?: string;
	hasIcon?: boolean;
}

function Copy({
	children,
	textToCopy,
	className,
	appearence = 'none',
	hasIcon = true
}:ICopy) {

	const cssClass:string[] = [styles.text];

	if (className) {
		cssClass.push(className);
	}

	if (appearence === 'tag') {
		cssClass.push(styles.tag);
	}

	const handleCopy = () => {
		navigator.clipboard.writeText(textToCopy)
			.then(() => {
				const tooltip = document.createElement('div');
				tooltip.classList.add(styles.tooltip);
				tooltip.innerText = 'Copied to clipboard: "' + textToCopy + '"';
				document.body.appendChild(tooltip);

				setTimeout(() => {
					tooltip.classList.add(styles.tooltipHide);
					setTimeout(() => {
						tooltip.remove();
					}, 150);
				}, 3000);
			})
			.catch((err) => {
				console.error('Erreur lors de la copie :', err);
			});
	};

	return (
		<>
			<ReactTooltip
				backgroundColor='black'
				effect='solid'
				place="bottom"
				globalEventOff="click"
				delayShow={600}
			/>
			<span
				onClick={() => handleCopy()}
				className={cssClass.join(' ')}
				data-tip={'Click to copy'}
			>
				{children}
				{hasIcon &&
					<i className={'far fa-clone fa-sm ml_2 ' + styles.icon} />
				}
			</span>
		</>
	);
}

export {
	Copy
};
