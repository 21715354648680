import React, { useEffect, useState } from 'react';
import { multiChoice, singleChoice } from '../context/Constants.js';

export default function CampaignsListCreationOptions({ isAdmin, permissionsAccount }) {

    const [canCreateCampaigns, setCanCreateCampaigns] = useState(false);
    const [canEditCampaigns, setCanEditCampaigns] = useState(false);
    const [canCreatePalCampaigns, setCanCreatePalCampaigns] = useState(false);
    const [canCreatePatchCampaigns, setCanCreatePatchCampaigns] = useState(false);
    const [canCreateCodePatchCampaigns, setCanCreateCodePatchCampaigns] = useState(false);
    const [canCreateExternalAppCampaigns, setCanCreateExternalAppCampaigns] = useState(false);
    const [canCreateClassicCampaigns, setCanCreateClassicCampaigns] = useState(false);
    const [canCreateSaaSCampaigns, setCanCreateSaaSCampaigns] = useState(false);
    const [canCreateABtest, setCanCreateABtest] = useState(false);
    const [canCreateInAppCampaigns, setCanCreateInAppCampaigns] = useState(false);

    const [campaignCreationVersion, setCampaignCreationVersion] = useState();

    useEffect(() => {
        const editCampaigns = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'STATISTICSCAMPAIGNS_CAN_ACCESS');
        setCanEditCampaigns(editCampaigns && editCampaigns.Value == true);

        const createCampaigns = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'CAMPAIGNS_CAN_CREATE');
        setCanCreateCampaigns(createCampaigns && createCampaigns.Value == true);

        const createSaaSCampaigns = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'SAAS_CAMPAIGN_CAN_CREATE');
        setCanCreateSaaSCampaigns(createSaaSCampaigns && createSaaSCampaigns.Value == true);

        const createClassicCampaigns = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'CLASSIC_CAMPAIGN_CAN_CREATE');
        setCanCreateClassicCampaigns(createClassicCampaigns && createClassicCampaigns.Value == true);

        const createPalCampaigns = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'PALCAMPAIGN_CAN_CREATE');
        setCanCreatePalCampaigns(createPalCampaigns && createPalCampaigns.Value == true);

        const createPatchCampaigns = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'PATCH_CAN_CREATE');
        setCanCreatePatchCampaigns(createPatchCampaigns && createPatchCampaigns.Value == true);

        const createCodePatchCampaigns = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'CODE_PATCH_CAN_CREATE');
        setCanCreateCodePatchCampaigns(createCodePatchCampaigns && createCodePatchCampaigns.Value == true);

        const viewABtest = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'ABTESTS_CAN_VIEW');
        setCanCreateABtest(viewABtest && viewABtest.Value == true && createCampaigns && createCampaigns.Value == true);

        const createExternalAppStoreCampaign = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'EXTERNALAPPCAMPAIGN_CAN_CREATE');
        setCanCreateExternalAppCampaigns(createExternalAppStoreCampaign && createExternalAppStoreCampaign.Value == true);

        const createInAppCampaign = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'NATIVE_APP_CAMPAIGNS_CAN_CREATE');
        setCanCreateInAppCampaigns(createInAppCampaign && createInAppCampaign.Value == true);

    }, [permissionsAccount]);


    useEffect(() => {
        if (isAdmin) {
            setCampaignCreationVersion(multiChoice);
            return;
        }
        const permissions = [
            canCreateClassicCampaigns,
            canCreateSaaSCampaigns,
            canCreatePalCampaigns,
            canCreatePatchCampaigns,
            canCreateCodePatchCampaigns,
            canCreateExternalAppCampaigns,
            canCreateABtest,
            canCreateInAppCampaigns
        ];
        const hasMultiple = permissions.filter(x => x).length > 1;
        setCampaignCreationVersion(hasMultiple ? multiChoice : singleChoice);
    }, [isAdmin, canCreateCampaigns, canCreatePalCampaigns, canCreatePatchCampaigns, canCreateCodePatchCampaigns, canCreateSaaSCampaigns, canCreateClassicCampaigns, canCreateExternalAppCampaigns, canCreateABtest, canCreateInAppCampaigns]);


    return {
        campaignCreationVersion,
        canCreateCampaigns,
        canCreatePalCampaigns,
        canCreateCodePatchCampaigns,
        canCreatePatchCampaigns,
        canCreateClassicCampaigns,
        canCreateSaaSCampaigns,
        canCreateExternalAppCampaigns,
        canCreateInAppCampaigns,
        canCreateABtest,
        canEditCampaigns
    };
} 
