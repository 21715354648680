
export const richTextOption = {
	toolbar_items_size: 'small',
	statusbar: false,
	height: 260,
	fontsize_formats: '8px 10px 11px 12px 13px 14px 15px 16px 17px 18px 19px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 42px 44px 46px 48px 50px 52px 54px 56px 58px 60px 62px 64px 66px 68px 70px 72px',
	font_formats: `
		Arial=arial,helvetica,sans-serif;
		Helvetica=helvetica,arial,sans-serif;
		Verdana=verdana,helvetica,sans-serif;
		Courier=courier new,courier,monospace;
	`,
	font_css: ['https://tag.beyable.com/fonts/_v1/all.min.css'],
	content_style: 'p {margin-top: 0; margin-bottom: 0;}',
	plugins: [
		'autolink link',
		'code',
		'emoticons',
		'paste'
	],
	menubar: 'edit insert format tools',
	toolbar: [
		'bold italic underline | alignleft aligncenter alignright | forecolor backcolor',
		'fontsizeselect | lineheight | fontselect'
	],
	paste_as_text: true
};

export const richTextOptionMinimal = {
	toolbar_items_size: 'small',
	statusbar: false,
	height: 260,
	font_css: ['https://tag.beyable.com/fonts/_v1/all.min.css'],
	content_style: 'p {margin-top: 0; margin-bottom: 0;}',
	plugins: [
		'code',
		'paste'
	],
	menubar: '',
	toolbar: [
		'bold',
	],
	paste_as_text: true
};

export function fontUtil(fontData) {

	const getFontFamily = (htmlText) => {
		return htmlText.split('font-family:').reduce((acc, val, i) => {
			if (i < 1) return acc;
			let fontFamily = val.split(';')[0].trim();
			if (fontFamily.contains(',')) fontFamily = fontFamily.split(',')[0].trim();
			const fontArray = [...acc, fontFamily];
			return [...new Set(fontArray)];
		}, []);
	};

	const getFontImport = (fonts) => {
		const fontImports: string[] = [];
		for (let i = 0; i < fonts.length; i++) {
			const f = fonts[i];
			for (let j = 0; j < fontData.length; j++) {
				const fd = fontData[j];
				if (f.indexOf(fd.displayName) != -1) {
					fontImports.push(fd.fontsURL + 'style.css');
				}
			}
		}
		return fontImports;
	};

	const extractFontImport = (value) => {
		if (typeof value === 'string' || value instanceof String) {
			let font: string[] = [];
			if ((value as any).isFont) {
				if (value.toString() !== 'inherit') {
					font.push(value.toString());
				}
			} else {
				font = getFontFamily(value);
			}
			const fontImports = getFontImport(font);
			return fontImports.map(url => `@import url('${url}')`);
		}
		return [];
	};

	return {
		extractFontImport
	};
}

