import HttpServices from './HttpServices';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { OrderByModificationDateDesc, OrderByModificationDateAsc, OrderByNameDesc, OrderByNameAsc } from './Queries/OrderByFilter';
import { getCampaigns, getCampaignsForReportGenerator, getEmailRemarketingCampaigns, getBusinessOperations } from './Queries/OnSiteDashboardQueries';
import { OnGoing, RanBetween } from './Queries/DefinitionFilters';
import SystemServices from './SystemServices';


// displaylibrary/bespoketemplates/ba14e9e3-bbcd-49d4-8c7d-34e6ede2bbf8 Business Operation

export default class GlobalDashboardServices {
	constructor($http, authServices){

		const token = authServices.getAccessToken();
		const defaultOptions = {
			watchQuery: {
				fetchPolicy: 'no-cache',
				errorPolicy: 'ignore',
			},
			query: {
				fetchPolicy: 'no-cache',
				errorPolicy: 'all',
			},
		};
		const authLink = setContext((_, { headers }) => {
			return {
				headers: {
					...headers,
					authorization: token ? `Bearer ${token}` : '',
				}
			};
		});
		const httpLink = createHttpLink({
			uri: window.BEYABLE_env.URI_GRAPHQL
		});

		this.HttpServices = new HttpServices($http);
		this.client = new ApolloClient({
			link: authLink.concat(httpLink),
			cache: new InMemoryCache(),
			defaultOptions: defaultOptions,
		});
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}
    
	chargeEmailRemarketingCount(accountId, successCallback, errorCallback) {
		this.client.query({
			query: getEmailRemarketingCampaigns,
			variables: {
				accountId: accountId,
				skip: 0,
				take: 0,
				order: [OrderByModificationDateDesc],
				where:  OnGoing
			}
		}).then(response => {
			successCallback(response.data.account.emailRemarketingCampaigns.totalCount); 
		}
		).catch(error => {
			console.log(error);
			errorCallback && errorCallback(error);
		});
	}

	chargeOnSiteCampaignsCount(accountId, successCallback, errorCallback) {
		this.client.query({
			query: getCampaigns,
			variables: {
				accountId: accountId,
				skip: 0,
				take: 0,
				order: [OrderByModificationDateDesc],
				where:  OnGoing
			}
		}).then(response => {
			successCallback(response.data.account.onSiteCampaigns.totalCount); 
		}
		).catch(error => {
			console.log(error);
			errorCallback && errorCallback(error);
		});
	}

	chargeBusinessOperationsCount(accountId, successCallback, errorCallback) {
		this.client.query({
			query: getBusinessOperations,
			variables: {
				accountId: accountId,
				skip: 0,
				take: 0,
				where: OnGoing
			}
		}).then(response => {
			successCallback(response.data.account.businessOperations.totalCount);
		}
		).catch(error => {
			console.log(error);
			errorCallback && errorCallback(error);
		});
	}

	getAvailableFormats(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}displaylibrary/bespoketemplates/${accountId}`, callbackSuccess, callbackError);
	}

	chargeTop5DisplayedOnSiteCampaigns(accountId, successCallback, errorCallback) {
		this.client.query({
			query: getCampaigns,
			variables: {
				accountId: accountId,
				skip: 0,
				take: 5,
				order: [],
				where:  OnGoing
			}
		}).then(response => {
			successCallback(response.data.account.onSiteCampaigns.items); 
		}
		).catch(error => {
			console.log(error);
			errorCallback && errorCallback(error);
		});
	}

	async chargeReportCampaigns(accountId, fromDate, toDate, successCallback, errorCallback) {		
		try {
			const filter = {
				and: [RanBetween(fromDate.format('YYYY/MM/DD'), toDate.format('YYYY/MM/DD')), { technicalPurpose: { eq: 'ACTUAL_CAMPAIGN' } }],
			}
			const response = await this.client.query({
				query: getCampaignsForReportGenerator,
				variables: {
					accountId: accountId,
					skip: 0,
					take: 100, // TODO: replace by paging!
					order: [OrderByModificationDateDesc],
					where: filter,
				}
			});
			const items = response.data.account.onSiteCampaigns.items;
			successCallback(items);
			return items;
		} catch (error) {
			console.log(error);
			errorCallback && errorCallback(error);
		}
	}
	async chargeReportCampaignBYiD(accountId, cpgId, successCallback, errorCallback) {
		try {
			const response = await this.client.query({
				query: getCampaignsForReportGenerator,
				variables: {
					accountId: accountId,
					skip: 0,
					take: 10,
					order: [OrderByModificationDateDesc],
					where: { 'id': { 'eq': cpgId } }
				}
			});
			const items = response.data.account.onSiteCampaigns.items[0];
			successCallback(items);
			return items;
		} catch (error) {
			console.log(error);
			errorCallback && errorCallback(error);
		}
	}

}