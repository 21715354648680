import React, { useEffect, useState, Fragment } from 'react';
import { react2angular } from 'react2angular';
import { Section } from '../../Components/Section/Section';
import { Skeleton } from '../../Components/Skeleton/Skeleton';
import Btn from '../../Components/Btn';
import { getUserFullName } from '../../Hooks/useDisplayName';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import AdminServices from '../../Services/AdminServices';
import newInstanceI18next from '../../Util/traductionUtils';
import { I18nextProvider, useTranslation } from 'react-i18next';
import styles from './ModulesSelector.module.css';

import {
	iconOnSite,
	iconEmr,
	iconEmerch,
	iconSimplify,
	iconABtest,
	iconAnalytics,
	iconProductMonitoring,
	iconScoring,
	iconGraphicPatch
} from '../../Components/CustomIcon/CustomIcon';

const allModules = [
	{
		id: 'onsite',
		color: 'blue',
		path: '/Onsite/Dashboard',
		plans: ['ActivationOnSiteFunctional', 'ActivationOnSiteMarketing', 'CodePatch', 'PalModules', 'ExternalAppStore'],
		permissions: ['CAMPAIGNS_CAN_VIEW']
	},
	{
		id: 'emr',
		color: 'yellow',
		path: '/Dashboard/Home?tab=campaigncrm',
		plans: ['EmailRemarketing'],
		permissions: ['STATISTICSCAMPAIGNSCRM_CAN_LIST']
	},
	{
		id: 'eMerch',
		color: 'turquoise',
		path: '/ProductSettings/Reco',
		plans: ['Recommendations'],
		permissions: ['RECOMMENDATIONS_CAN_SET']
	},
	{
		id: 'abtests',
		color: 'orange',
		path: '/ABTests/Dashboard/Pending',
		plans: ['AbTests'],
		permissions: ['ABTESTS_CAN_VIEW']
	},
	{
		id: 'graphicPatchs',
		color: 'green',
		path: '/Onsite/Dashboard',
		plans: ['Patch'],
		permissions: ['CAMPAIGNS_CAN_VIEW']
	},
	{
		id: 'simplify',
		color: 'cyan',
		path: '/BusinessOperations/Planning',
		plans: ['Simplify'],
		permissions: ['BUSINESSOPE_PLANNING_CAN_VIEW']
	},
	{
		id: 'analytics',
		color: 'red',
		path: '/WebAnalytics/Dashboard/?subdimension=overview&dimension=sessions',
		freeTrial: true,
		plans: ['WebAnalytics', 'WebAnalyticsEcommerce'],
		permissions: ['WEBANALYTICS_CAN_VIEW']
	},
	{
		id: 'productMonitoring',
		color: 'purple',
		path: '/Insight/scoring',
		plans: ['ProductMonitoring'],
		permissions: ['STATISTICSINSIGHTS_CAN_VIEW']
	},
	{
		id: 'scoring',
		color: 'pink',
		path: '/VisitorEngagementReports/Home',
		plans: ['VisitorEngagement'],
		permissions: ['VISITOR_ENGAGEMENT_REPORT_CAN_VIEW']
	}
];

const modulesIcon = {
	onsite: iconOnSite,
	emr: iconEmr,
	eMerch: iconEmerch,
	simplify: iconSimplify,
	abtests: iconABtest,
	analytics: iconAnalytics,
	productMonitoring: iconProductMonitoring,
	scoring: iconScoring,
	graphicPatchs: iconGraphicPatch
};

export default function ModulesSelector(props) {
	return (
		<I18nextProvider i18n={newInstanceI18next}>
			<ModulesSelectorInner {...props} />
		</I18nextProvider>
	);
}

function ModulesSelectorInner(props) {

	const [t] = useTranslation('modules');

	const [licenseModules, setLicenseModules] = useState([]);
	const [otherModules, setOtherModules] = useState([]);
	const [loading, setLoading] = useState(true);

	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const adminServices = new AdminServices(props.$http, props.$routeParams);
	const user = {...props.$rootScope.User};
	user.FullName = getUserFullName(user);
	const isAdmin = user?.roles.includes('Admin');
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const tmpAccountId = impersonatedAccount || (props.$rootScope && user?.Account?.Key);

	const repeat = (jsx, nb) => {
		const result = [];
		for (let i = 0; i < nb; i++) {
			result.push(
				<Fragment key={i}>{jsx}</Fragment>
			);
		}
		return result;
	};

	function checkModulePermissions(module) {
		if (isAdmin) return true;

		const permissions = module.permissions;
		if (!permissions || permissions.length === 0) return true;

		for (let i = 0; i < permissions.length; i++) {
			const hasCurrentPermission = getPermissionValue(permissions[i]);
			if (!hasCurrentPermission) return false; 
		}

		return true;
	}

	function getPermissionValue (name) {
		if (user && user.Permissions) {
			let perm = user.Permissions.find(element => element.Name === name);
			if (perm) {
				return perm.Value;
			}
		}
		return false;
	}

	useEffect(() => {
		if (tmpAccountId) {
			adminServices.GetAccountModules(tmpAccountId, modules => {
				const modulesIDs = modules.map((m) => m.moduleId);
				const licenseModules = [];
				const otherModules = [];
				const allModulesLength = allModules.length;
	
				for (let i = 0; i < allModulesLength; i++) {
					const module = {...allModules[i]};
					const hasLicense = module.plans.some(id => modulesIDs.includes(id));
					if (hasLicense) {
						module.hasPermission = checkModulePermissions(module);
						licenseModules.push(module);
					} else {
						otherModules.push(module);
					}
				}
	
				setLicenseModules(licenseModules);
				setOtherModules(otherModules);
				setLoading(false);
			}, () => {
				setLoading(false);
			});
		}
	}, []);

	return (
		<Section width="s">
			<h1 className={styles.title}>
				{t('hello', {name: user.FullName})}
			</h1>

			{loading &&
				<div className={styles.section}>
					<div className={styles.moduleGrid}>
						{repeat(
							<div className={styles.module}>
								<Skeleton appearance='sumup' />
							</div>,
							5
						)}
					</div>
				</div>
			}

			{!loading &&
				<>
					{licenseModules && licenseModules.length === 0 &&
						<div className={styles.section}>
							<div className={styles.sectionEmpty}>
								{t('noAvailableModules')}
							</div>
						</div>
					}
					{licenseModules && licenseModules.length > 0 &&
						<div className={styles.section}>
							<div className='flex'>
								<div className='flex_item_full'>
									<div className={styles.sectionTitle}>
										{licenseModules.length === 1 ?
											<>{t('availableModule')}</>
											:
											<>{t('availableModules', {n: licenseModules.length})}</>
										}
									</div>
								</div>
								<div className='flex_item_fix ml_30'>
									
								</div>
							</div>
							<div className={styles.moduleGrid}>
								{licenseModules.map((m) => 
									<ModuleItem
										key={m.id}
										module={m}
										hasLicense={true}
										hasPermission={m.hasPermission}
										href={impersonatingServices.getImpersonatedUrl(m.path)}
									/>
								)}
							</div>
						</div>
					}
					{otherModules && otherModules.length > 0 &&
						<div className={styles.section}>
							<div className={styles.sectionTitle}>
								{t('otherModules')}
							</div>
							<div className={styles.moduleGrid}>
								{otherModules.map((m) => 
									<ModuleItem
										key={m.id}
										module={m}
										hasLicense={false}
										hasPermission={false}
									/>
								)}
							</div>
						</div>
					}
				</>
			}
		</Section>
	);
}

function ModuleItem({
	module,
	hasLicense,
	hasPermission,
	href
}) {

	const [t] = useTranslation('modules');
	const iconClass = [styles.moduleIcon];
	if (hasLicense && module.color) {
		iconClass.push(styles['moduleIcon_' + module.color]);
	}
	
	const icon = modulesIcon[module.id] || <></>;

	if (hasLicense && hasPermission) return (
		<a className={styles.moduleClickable} href={href}>
			<span className={iconClass.join(' ')}>
				{icon}
			</span>
			<span className={styles.moduleLabel}>
				{t('moduleNames.' + module.id)}
			</span>
			<span className={styles.moduleArrow}>
				<i className='fas fa-chevron-right'></i>
			</span>
		</a>
	);

	if (hasLicense && !hasPermission) return (
		<div className={styles.module}>
			<span className={iconClass.join(' ')}>
				{icon}
			</span>
			<div className={styles.moduleLabel}>
				<div>{t('moduleNames.' + module.id)}</div>
				<div className={styles.modulePermission}>{t('youDoNotHaveAccess')}</div>
			</div>
		</div>
	);

	return (
		<div className={styles.module}>
			<span className={iconClass.join(' ')}>
				{icon}
			</span>
			<div className={styles.moduleLabel}>
				<div>{t('moduleNames.' + module.id)}</div>
				<div className={styles.moduleBtnWrapper}>
					{module.freeTrial ?
						<Btn
							message={t('tryForFree')}
							style="link"
							icon="fas fa-angle-double-right fa-sm"
							color="success"
							fontSize="s"
						/>
						:
						<Btn
							message={t('askForADemo')}
							style="link"
							icon="far fa-calendar-alt fa-sm"
							fontSize="s"
							href="https://www.beyable.com/demande-de-demo?utm_source=beyable_plateforme&utm_campaign=module_page"
							target="_blank"
							rel="noreferrer"
						/>
					}
				</div>
			</div>
		</div>
	);
}

angular
	.module('beyableSaasApp.ModulesSelector', [])
	.component('modulesselector', react2angular(ModulesSelector, [], ['$http', '$rootScope', '$routeParams']));