import React, {useState, useEffect} from 'react';
//import { useTranslation } from 'react-i18next';
import { useCatalogueEditorContext } from '../Context/CatalogueContextProvider';
import './TemplateDisplay.css';
import {DeviceType} from '../Context/TemplateTypes';
import { propertiesProcessing } from '../Utils/util.js';

import TemplateRender from '../../../WysiwygEditor/Components/TemplateRender';
import styles from '../CatalogueContainer.module.css';

type Props = {
	device:DeviceType,
}

export default function TemplateDisplay({device}:Props) {

	const { getLiquidModel, hasCountDowns, templatePropertyToStyleProperty } = propertiesProcessing();
	const {
		template,
		modelProperties,
		setModelProperties,
		styleSelected,
		defaultStyleFormat
	} = useCatalogueEditorContext();

	const [otherProperties, setOtherProperties] = useState({});
	const [liquidModel, setLiquidModel] = useState({});

	const [modelHasCountDowns, setModelHasCountDowns] = useState<boolean>(false);

	useEffect(() => {
		if (styleSelected) {
			if(styleSelected.id !== 'default'){
				const mergedProperties =  [...styleSelected.properties];
				setModelProperties(mergedProperties|| []);
			}else{

				// @ts-ignore
				setModelProperties([...defaultStyleFormat?.properties.map(
					templatePropertyToStyleProperty
				) || []]);
			}


		}
	}, [template, styleSelected]);

	const refreshTemplateDisplay = (modelProperties:any) => {
		const values = getLiquidModel(modelProperties);
		const result = {
			...values,
			...otherProperties,
		};
		setLiquidModel(result);
	};

	useEffect(() => {
		refreshTemplateDisplay(modelProperties);
		setModelHasCountDowns(hasCountDowns(modelProperties));
	}, [modelProperties, otherProperties]);

	useEffect(() => {
		if(template){
			const getCssFromDescriptor = () => {
				const descriptor = device === DeviceType.Desktop ? template.SlideTemplateDescriptorDesktop : template.SlideTemplateDescriptorMobile;
				return descriptor && descriptor.Css;
			};
			const css = getCssFromDescriptor();
			css && setOtherProperties(x => ({ ...x, Css: css }));

		}
	}, [template, modelProperties]);

	// periodically refresh countdowns
	const [seconds, setSeconds] = useState(0);
	useEffect(() => {
		// const interval = setInterval(() => {
		// 	setSeconds(seconds => seconds + 1);
		// }, 1000);
		const interval = setTimeout(() => {
			setSeconds(seconds => seconds + 1);
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (modelHasCountDowns)
			refreshTemplateDisplay(modelProperties);
	}, [seconds,modelProperties]);

	const wysiwygDeviceKey = `Wysiwyg${device === 'desktop' ? 'Desktop' : 'Mobile'}`;

	if (!template || !template.WysiwygDesktop) return <></>;

	return (
		<div className={device === 'desktop' ? styles.templatePageThumb__desktop : styles.templatePageThumb__mobile}>
			<TemplateRender
				device={device}
				formatType={template.Format?.id}
				html={template[wysiwygDeviceKey].Template || ''}
				className={styles.templatePageThumbIframe}
				liquidModel={liquidModel}
				inCatalog={true}
			/>
		</div>
	);
}