import HttpServices from './HttpServices';

export default class AiInsightsService {
	HttpServices: HttpServices;
	BO_API_URL: string;
	accountId: string;
	constructor(accId: string,$http:any) {
		this.HttpServices = new HttpServices($http);
		this.accountId = accId;
		if (!window || !(window as any).BEYABLE_env || !(window as any).BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${(window as any).BEYABLE_env.BO_API_URL}`;
		}
	}
	getAiInsights(locale, callbackSuccess, callbackError) {
		let i18nL = locale;
		i18nL = i18nL.replace(/"/g, '');
		const url = `${this.BO_API_URL}/${this.accountId}/insight-findings?locale=${i18nL}`;
		this.HttpServices.get(url, callbackSuccess, callbackError);
	}
	/// DEVONLY
	// getAiInsights(locale, callbackSuccess, callbackError) {
	// 	let i18nL = locale;
	// 	i18nL = i18nL.replace(/"/g, '');
	// 	const url = `${this.BO_API_URL}devOnly/3a9bf1d0-2c4f-4caf-aad2-06855a8b9ab0/insight-findings?locale=${i18nL}`;
	// 	this.HttpServices.get(url, callbackSuccess, callbackError);
	// }

}