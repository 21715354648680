import React from 'react';
import { Article } from '../../../../../Components/Article/Article';
import CardHeader from './CardHeader';
import Metric from '../Metric';
import CardIcon from './CardIcon';
import SentenceGenerator from '../SentenceGenerator';
import { FindingDataItem, AiInsight } from '../../context/AiInsightsApiTypesServices';
import styles from './Card.module.css';

interface InsightsCardProps {
	insight: AiInsight;
}

const InsightsCard: React.FC<InsightsCardProps> = ({ insight }) => {

	return (
		<Article innerSize='l'>
			<CardHeader insight={insight} />
			<div className="flex flex_align_start">
				<div className='flex_item_fix'>
					<CardIcon findingType={insight.findingType} favorableness={insight.favorableness} />
				</div>
				<div className='flex_item_full'>
					<div className='s_16'>
						<SentenceGenerator insight={insight} />
					</div>
					<div className={styles.metricList}>
						{insight.findingData && Object.entries<FindingDataItem>(insight.findingData).map(([, value], index) => (
							<Metric
								key={index}
								label={value.translatedDescription}
								value={value.absolute}
								prevValue={value.previousAbsolute}
								type={value.absoluteType}
								evolution={value.relative}
								isFavorable={value.favorableness}
							/>
						))}
					</div>
				</div>
			</div>
		</Article>
	);
};

export default InsightsCard;
