import React from 'react';

export default function TableGridContainer({
	specialClasseName,
	children,
	provided = {
		innerRef: React.createRef()
	},
	hasAlternatingBackground = false
}) {

	const cssClass = ['table_grid'];

	if (specialClasseName) {
		cssClass.push(specialClasseName);
	}

	if (hasAlternatingBackground) {
		cssClass.push('has_alternating_background');
	}

	return (
		<div className={cssClass.join(' ')}
			{...provided.droppableProps}
			ref={provided.innerRef}
		>
			{children}
		</div>
	);
}
