import gql from 'graphql-tag';
const getCampaignsNotificationsSource = gql`
query($accountId: String, $where:OnSiteCampaignFilterInput,){
  account(id: $accountId){
    onSiteCampaigns(where:$where,){
         pageInfo{
            hasNextPage,
            hasPreviousPage,
        },
        totalCount,
        items{
           id,
           searchId,
           name,
           description
           isStopped,
           objective,
           startDate,
           endDate, 
           modificationDate,
           executionMode,
           trackingMode,
           technicalPurpose,
           editor,

           labels{
            name,
            color,
            labelId
           },
           campaignDebugLinks{
            debugLink,
            variation
           },
           screenShots {
            id,
            screenShotUrl
           },          
           testModeIpAddresses{
            ipAddress,
            label,
            isDisabled
           },
           devices{
            deviceId
           },
           defaultPermissions{
            permission,
            isAllowed
           },
           currentUserPermissions{
            permission,
            isAllowed
           }
       }
    }
  }
}
`;

export {getCampaignsNotificationsSource};