type DeviceType = 'Desktop' | 'Tablet' | 'Mobile';
type Devices = Array<DeviceType>;
type initDates = {
	fromDateToCompare: string;
	initialFrom: string;
	initialLabel: string;
	initialLabel2: string;
	initialTo: string;
	toDateToCompare:string;
}
type navFilterP = {
	fromDate: string;
	toDate: string;
	filters: [],
	label:string
}
export {DeviceType,Devices,initDates, navFilterP};

export type VisitorEngagementLevel = 'X' // Lost
	| 'C' // Consideration
	| 'D' // Discover
	| 'I' // Intention
	| 'A' // Purchase
	| 'E' // Experience
	| 'R' // Recurring purchase
	| 'none'; // None
export type EngagementLevel = {
	value: VisitorEngagementLevel;
	label: string;
}
export const engagementLevels:Array<EngagementLevel> = [
	{
		value : 'X',
		label : 'Lost'
	},
	{
		value : 'C',
		label : 'Consideration'
	},
	{
		value : 'D',
		label : 'Discover'
	},
	{
		value : 'I',
		label : 'Intention'
	},
	{
		value : 'A',
		label : 'Purchase'
	},
	{
		value : 'E',
		label : 'Experience'
	},
	{
		value : 'R',
		label : 'Recurring purchase'
	},
	{
		value : 'none', // mettre ""
		label : 'none'
	},
];
export type Parts = {
	partName: string;
	metric: string | undefined;
	dimensions: Array<string>;
}
export type dateT = {
	greaterThan: string,
	lowerThan: string
};
export type ValueElementWithLabelTraduction = {
	id?: string;
	value?:string;
	label:string;
}
export type DimensionT = {
	id:string;
	label:string;
	values : Array<ValueElementWithLabelTraduction>
}
export type dataInfos = {
	dimensions:
		{
			PageType: string,
			Device: string
		};
	sampleSize : number;
	value: number;
}
export type PartsItem = {
	id: string;
	metric: string;
	items: Array<dataInfos>;
}
export type Filter = {
	[key:string] : any
}
export type Filters = {
		UtcDate:dateT,
		tenant?: {
			equals: string,
		},
		device?: {
			in: Array<DeviceType>
		},
		trafficSource?: {
			equals: string,
		},

} & Filter;
export type ObjectFunnelApiPost = {
	parts: Array<Parts>;
	filters: Filters;
};
export type ObjectFunnelApiPostResponse = {
	parts: Array<PartsItem>;
	filters: Filters;
};
export type OptionnalFilter = {
	value : string;
	key : string
}