import React, {useState, useEffect} from 'react';
import TemplateDisplay from '../Editor/TemplateDisplay';
import {DeviceType} from '../Context/TemplateTypes';
import {StylesGallery} from '../Editor/StylesGallery';
import {useCatalogueEditorContext} from '../Context/CatalogueContextProvider';
import Btn from '../../../../Components/Btn.js';
import {useTranslation} from 'react-i18next';
import styles from '../CatalogueContainer.module.css';

export const PanelTemplateSelected = () => {

	const [t] = useTranslation('inspiration');

	const {
		panelIsOpen,
		setPanelIsOpen,
		template,
		styleSelected,
		onHideCatalog
	} = useCatalogueEditorContext();

	const [isScroll, setIsScroll] = useState(false);
	const [shouldRender, setRender] = useState(panelIsOpen);
	const [linkToEditor, setLinkToEditor] = useState('');

	useEffect(() => {
		if (panelIsOpen) {
			setRender(true);
		}
	}, [panelIsOpen]);

	useEffect(() => {
		if (template) {
			let url = template.ModuleUrl;
			if (styleSelected) {
				url += `&styleId=${styleSelected?.id}`;
			}
			if (url) {
				setLinkToEditor(url);
			}
		}
	}, [template, styleSelected]);

	const onAnimationEnd = () => {
		if (!panelIsOpen) {
			setRender(false);
		}
	};

	function handleOnScroll(ev) {
		const t = ev.currentTarget;
		const tmpIsScroll = t.scrollTop > 100;
		if (tmpIsScroll != isScroll) {
			setIsScroll(tmpIsScroll);
		}
	}

	const panelClass = [styles.view, 'vscroll'];
	const overlayClass = [styles.overlay];
	if (panelIsOpen) {
		panelClass.push(styles.view_anim_show);
		overlayClass.push(styles.overlay_anim_show);
	} else {
		panelClass.push(styles.view_anim_hide);
		overlayClass.push(styles.overlay_anim_hide);
	}

	if (!shouldRender) return <></>;

	return (
		<>
			<div className={overlayClass.join(' ')}></div>
			<div className={panelClass.join(' ')}
				onAnimationEnd={onAnimationEnd}
				onScroll={(ev) => handleOnScroll(ev)}>

				{panelIsOpen &&
					<>
						<div className={[styles.viewHeaderSticky, isScroll ? styles.viewHeaderScroll : ''].join(' ')}>
							<div className='flex'>
								<div className='flex_item_fix mr_15'>
									<Btn
										tooltip="Back to list"
										onClick={() => setPanelIsOpen(false)}
										style="outline"
										color="secondary"
										round={true}
										size="s"
										icon="fas fa-chevron-left"
									/>
								</div>
								<div className='flex_item_full'>
									{template && 
										<div className={isScroll ? styles.viewHeaderTitle2Visible : styles.viewHeaderTitle2Hidden}>{template.Name}</div>
									}
								</div>
							</div>
						</div>
						<div className={styles.viewBody}>
							<div className={styles.templatePage}>
								{template &&
									<>
										<div className={styles.templatePageHead}>
											<div className='flex flex_align_start'>
												<div className='flex_item_full'>
													<div className={styles.templatePageTitle}>
														{template.TranslationKey ?
															<>{t('modules.' + template.TranslationKey + '.label')}</>
															:
															<>{template.Name}</>
														}
													</div>
													<div className={styles.templatePageTagList}>
														<div className={styles.tagList}>
															{template.Format &&
																<span className={styles.tagItem}>
																	<i className={styles.tagItemIcon + ' fas fa-vector-square fa-sm'}></i>
																	{t('formats.' + template.Format.id + '.label' )}
																</span>
															}
															{template.Objective &&
																<span className={styles.tagItem}>
																	<i className={styles.tagItemIcon + ' fas fa-crosshairs'}></i>
																	{t('objectives.' + template.Objective.id + '.label' )}
																</span>
															}
														</div>
													</div>
												</div>
												<div className='flex_item_fix ml_30'>
													<Btn
														onClick={() => onHideCatalog()}
														href={linkToEditor}
														message={'Use this template'}
													/>
												</div>
											</div>
										</div>
										<div className={styles.templatePageSection}>
											<div className={styles.templatePagePreview}>
												<div className={styles.templatePagePreviewMobile}>
													<TemplateDisplay
														device={DeviceType.Mobile}
													/>
												</div>
												<div className={styles.templatePagePreviewDesktop}>
													<TemplateDisplay
														device={DeviceType.Desktop}
													/>
												</div>
											</div>
										</div>
										<StylesGallery />
										{template.TranslationKey &&
											<div className={styles.templatePageSection}>
												<p className='s_16'>
													{t('modules.' + template.TranslationKey + '.description')}
												</p>
											</div>
										}
									</>
								}
							</div>
						</div>
					</>
				}
			</div>
		</>
	);
};
