
import ProductInsightsServices from '../../../../../Services/ProductInsightsServices';
import ImpersonatingServices from '../../../../../Services/ImpersonatingServices';

import SystemServices from '../../../../../Services/SystemServices';
import {handlerCatch} from '../../utils';

export function crudListMappingProduct($http,$rootScope,$routeParams,AuthServices,setAccountHasNoDatas) {
	const impersonatingServices = new ImpersonatingServices($rootScope,$routeParams );
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;

	const insightsServices = new ProductInsightsServices($http,AuthServices,accountId);
	const systemServices = new SystemServices($rootScope);

	type CallbackError = (error: any) => void;

	function loadInsightsProductsSimple(options,callbackSuccess, callbackError,signal) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		insightsServices.loadInsightsProductsForProductsComparison(
			options,[],
			(data: any[]) => {
				callbackSuccess(data);
				setAccountHasNoDatas(false);
			},
			(err: any, dataError: any) => {
				console.warn('error', err);
				console.warn('=>(dataError', dataError);
				const is500 = handlerCatch(err);
				if(is500){
					setAccountHasNoDatas(true);
				}else{
					systemServices.showError('An Error occured while fetching insights data');
				}
				callbackError(false);

			}
		);
	}
	function loadInsightsProducts(options,filters,callbackSuccess, callbackError,signal) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		insightsServices.loadInsightsProductsForCategories(
			options,filters,
			(data: any[]) => {
				callbackSuccess(data);
				setAccountHasNoDatas(false);
			},
			(_err,dataError: CallbackError) => {
				console.log('=>(CrudKpiInsights.tsx:57) dataError', dataError);
				const is500 = handlerCatch(dataError);
				if(is500){
					setAccountHasNoDatas(true);
				}else{
					systemServices.showError('An Error occured while fetching insights data');
				}
				callbackError(false);
			}
		);
	}
	function exportCsv(csvData, fileName) {
		const blob = new Blob([csvData], { type: 'text/csv' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	}
	function postQuerySpeficifProductExportInsightsServices(options, callbackSuccess, callbackError, signal) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		insightsServices.exportInsightsProductsForProductsOutliers(
			options,
			(success: any) => {

				exportCsv(success, 'insights.csv');
				callbackSuccess(success);
				setAccountHasNoDatas(false);
			},
			(_err,dataError: CallbackError) => {
				console.log('=>(CrudKpiInsights.tsx:57) dataError', dataError);
				const is500 = handlerCatch(dataError);
				if(is500){
					setAccountHasNoDatas(true);
				}else{
					systemServices.showError('An Error occured while fetching insights data');
				}
				callbackError(false);
			}
		);
	}
	function loadInsightsProductsForCategoriesComparison(options,filterSelected,callbackSuccess, callbackError,signal) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		insightsServices.loadInsightsProductsForCategoriesComparison(
			options,filterSelected,
			(data: any[]) => {
				callbackSuccess(data);
				setAccountHasNoDatas(false);
			},
			(dataError: CallbackError) => {
				console.log('=>(CrudKpiInsights.tsx:57) dataError', dataError);
				const is500 = handlerCatch(dataError);
				if(is500){
					setAccountHasNoDatas(true);
				}else{
					systemServices.showError('An Error occured while fetching insights data');
				}
				callbackError(false);
			}
		);
	}

	return {
		loadInsightsProducts,
		postQuerySpeficifProductExportInsightsServices,
		loadInsightsProductsForCategoriesComparison,
		loadInsightsProductsSimple
	};
}
