import React, {useEffect, useRef, useState} from 'react';
import InputCustom from '../../../../Components/InputCustom';
import Btn from '../../../../Components/Btn';
import { useProductFeedEditionContext } from '../../context/ProductFeedEditionContext';
import { getFieldByTypeOfMappingGenericUri} from '../../utils';
import editionStyles from '../ProductFeedEdition.module.css';
import { Editor } from '@tinymce/tinymce-react';
import {FeedCardHeader} from '../../Feed/FeedCardHeader';
import XmlSettingsContainer from '../../Feed/XmlSettingsContainer';
import { v4 as uuidv4 } from 'uuid';
import {ControlledEditor} from '@monaco-editor/react';
import AdditionnalHeaders from '../../Feed/AdditionnalHeaders';
import Fieldset from '../../../../Components/Fieldset';
import optionsEditor from './optionsEditor';
let editorOpts = {
	toolbar_items_size: 'small',
	statusbar: false,
	height: 260,
	fontsize_formats: '8px 10px 11px 12px 13px 14px 15px 16px 17px 18px 19px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 42px 44px 46px 48px 50px 52px 54px 56px 58px 60px 62px 64px 66px 68px 70px 72px',
	font_formats: 'Arial=arial,helvetica,sans-serif;Avenir Roman=Avenir-Roman,Nunito Sans,sans-serif;',
	content_css: [
		'//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
	],
	content_style: '',
	plugins: [
		'autolink link',
		'code',
		'emoticons'
	],
	menubar: '',
	toolbar: [
	]
};

export default function ConnectionEdition({headerCreation}) {
	const {
		step,
		productFeedObject,
		mode,
		handleChangeConnexion,
		handleChangeSimpleField,
		handleChangesTypeFormatField,
		handleChangesTypeFormatFieldObj,
		handleChangesTypeFormatFieldRichText,
		handleChangeStep
	} = useProductFeedEditionContext();
	const valueGetter = useRef();
	const [elementToSave, setelementToSave] = useState();
	const [connexionField, setConnexionFields] = useState(<></>);
	if( !productFeedObject.hasOwnProperty('auth')){
		return <></>;
	}
	const example = '{"exe1": "4", "exe2": "25"}';
	function renameKeys(elementToSave) {
		if(!elementToSave) return;
		if(elementToSave && elementToSave.length > 0){
			const transformArrToObj = elementToSave.reduce((o, key2:any) => {
				return Object.assign(o, {[key2.key]: key2.value});
			}, {});
			return transformArrToObj;
		}else{
			const objN = { [elementToSave[0].key]: elementToSave[0].value };
			return objN;
		}
	}
	function saveElementInArrayOfHeaders(){
		const newObjKeys = renameKeys(elementToSave);

		if(!productFeedObject.hasOwnProperty('httpBody')){
			productFeedObject.httpBody = null;
		}
		handleChangeSimpleField('additionalHeaders', newObjKeys);

	}
	const handleUpdate = (_prevValue, newVal) => {
		let oldV = {...productFeedObject?.httpBody};
		if(productFeedObject.hasOwnProperty('httpBody')){
			oldV.Content = newVal;
		}else{
			oldV = {
				ContentType : '',
				Content :newVal
			};
		}
		handleChangeSimpleField('httpBody', oldV);
	};

	function handleEditorDidMount(event) {
		valueGetter.current = event;
	}


	function handleChangeSimpleFieldFn(e){
		const value = e.target.value;
		const name = e.target.name;
		handleChangeSimpleField(name,value);
	}
	function handleChangeField(e){
		const value = e.target.value;
		const name = e.target.name;
		handleChangeConnexion(name,value);
	}
	function handleChangeContentType(evt){
		const value = evt.target.value;
		let oldV = {...productFeedObject?.httpBody};
		if(productFeedObject.hasOwnProperty('httpBody')){
			oldV.contentType = value;
		}else{
			oldV = {
				contentType : value,
				content : ''
			};
		}
		handleChangeSimpleField('httpBody', oldV);
	}
	function handleChangeContentTypeHttp(evt){
		const value = evt.target.value;
		let oldV = {...productFeedObject?.httpBody};
		if(productFeedObject.hasOwnProperty('httpBody')){
			oldV.ContentType = value;
		}else{
			oldV = {
				ContentType : value,
				Content : ''
			};
		}
		handleChangeSimpleField('httpBody', oldV);
	}
	function getRenderFunctionByKeyType(key, authKey){
		switch (key) {
			case 'PrestaShop':
				return getFieldConnectionPrestashop(authKey);
			case 'Custom':
				return getFieldConnectionCustom(authKey);
			case 'GenericUri':
				return getFieldConnectionGenericUri(authKey);
			case 'Http':
				return getFieldConnectionHttp(authKey);
			case 'OnSiteData':
				return <></>;
			case 'None':
				return <></>;
		}
	}
	function getFieldConnectionPrestashop(key){
		return <>
			<InputCustom
				value={productFeedObject.resourceIdentifier}
				type={'text'}
				label={'URL'}
				name={'resourceIdentifier'}
				placeholder="https://exemple.com/feed"
				onChange={handleChangeSimpleFieldFn}
				fullWidth={true}
				autoFocus={true}
				size="l"
				autoComplete={false}
			/>
			<InputCustom
				value={productFeedObject.auth.apiKey}
				type={'password'}
				label={'Api key'}
				name={'apiKey'}
				placeholder="4585yw5-54523cx-2125kj"
				onChange={handleChangeField}
				fullWidth={true}
				size="l"
				autoComplete={false}
			/>
			<InputCustom
				value={productFeedObject.languageId}
				type={'number'}
				label={'Language id'}
				name={'languageId'}
				placeholder="2"
				onChange={handleChangeSimpleFieldFn}
				fullWidth={true}
				size="l"
				autoComplete={false}
			/>
		</>;
	}
	
	function getTypesFieldsForFormatGenericUri(type){
		const typeObj = {...type};
		const skeletonObjectType = getFieldByTypeOfMappingGenericUri(typeObj.type);
		return skeletonObjectType.map((field,id) => {

			function getValueOfField(){
				const specialsCaracters = [
					{key:'\n', value:'\\n' },
					{key:'\r\n', value:'\\r\\n' },
					{key:'\t', value:'\\t' },
					{key:'\r', value:'\\r' },
					{key:'\f', value:'\\f' },
					{key:'\v', value:'\\v' },
				];
				if(specialsCaracters.find(el => el.key === productFeedObject.format[field.key])){
					return  specialsCaracters.find(el => el.key === productFeedObject.format[field.key]).value;
				}else{
					return productFeedObject.format[field.key];
				}
			}
			// if(field.name === 'linesSeparator')  {
			// 	return <div key={id}>
			// 		<Editor
			// 			apiKey="bjl49uswgxt3c5t7pl7dh6d41jyi57tp5icuisogwx80tfw9"
			// 			init={editorOpts}
			// 			value={productFeedObject.format[field.key]}
			// 			onEditorChange={(value, editor) => handleChangesTypeFormatFieldRichText(value)}
			// 		/></div>;
			// }
			// else 
			if(field.type === 'text'){
				return <div key={id}>
					<InputCustom
						value={getValueOfField()}
						type={'text'}
						label={field.label}
						name={field.name}
						placeholder=""
						onChange={handleChangesTypeFormatField}
						fullWidth={true}
						size="l"
					/>
				</div>;
			}
			else if(field.type === 'object'){
				return <div key={id}>
					<InputCustom
						value={productFeedObject.format[field.key][field.objectName] || '' }
						type={'textarea'}
						label={field.objectLabel}
						name={field.objectName}
						placeholder=""
						onChange={(e)=>handleChangesTypeFormatFieldObj(field.key,field.objectName ,e.target.value)}
						fullWidth={true}
						size="l"
					/>
				</div>;
			}else if(field.type === 'namespaces'){
				return <div key={id+uuidv4()}><XmlSettingsContainer namespaces={productFeedObject.format[field.key]}
				/></div>;
			}
		});
	}

	function getFieldConnectionCustom(key){
		switch (key) {
			case 'None':
				return <>
					<InputCustom
						value={productFeedObject.resourceIdentifier}
						type={'text'}
						label={'Resource identifier '}
						name={'resourceIdentifier '}
						placeholder="test"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
						autoFocus={true}
					/>
					<InputCustom
						value={productFeedObject.connectorName}
						type={'text'}
						label={'Connector name'}
						name={'connectorName'}
						placeholder="test"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
					/>
					<InputCustom
						value={productFeedObject.connectorVersion }
						type={'text'}
						label={'Version'}
						name={'connectorVersion'}
						placeholder="1.2"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
					/>
				</>
				;
			case 'LoginPassword':
				return <>
					<InputCustom
						value={productFeedObject.resourceIdentifier }
						type={'text'}
						label={'URL'}
						name={'resourceIdentifier'}
						placeholder="https://exemple.com/feed"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
						autoFocus={true}
					/>
					<InputCustom
						value={productFeedObject.auth.login}
						type="text"
						label={'Login'}
						name={'login'}
						placeholder=""
						onChange={handleChangeField}
						fullWidth={true}
					/>
					<InputCustom
						value={productFeedObject.auth.password}
						type={'password'}
						label={'Password'}
						name={'password'}
						placeholder="Type the password"
						onChange={handleChangeField}
						size="l"
						fullWidth={true}
					/>
					<div className={editionStyles.form_group_label}>
						{productFeedObject.format.type} settings
					</div>
					<InputCustom
						value={productFeedObject.connectorName}
						type={'text'}
						label={'Connector name'}
						name={'connectorName'}
						placeholder="test"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
					/>
					<InputCustom
						value={productFeedObject.connectorVersion }
						type={'text'}
						label={'Version'}
						name={'connectorVersion'}
						placeholder="1.2"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
					/>
				</>;
			case 'ApiKey':
				return <>
					<InputCustom
						value={productFeedObject.resourceIdentifier}
						type={'text'}
						label={'URL'}
						name={'resourceIdentifier'}
						placeholder="https://exemple.com/feed"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
						autoFocus={true}
					/>
					<InputCustom
						value={productFeedObject.auth.apiKey}
						type={'password'}
						label={'Api key'}
						name={'apiKey'}
						placeholder="4585yw5-54523cx-2125kj"
						onChange={handleChangeField}
						size="l"
						fullWidth={true}
					/>
					<div className={editionStyles.form_group_label}>
						{productFeedObject.format.type} settings
					</div>
				
					<InputCustom
						value={productFeedObject.connectorName}
						type={'text'}
						label={'Connector name'}
						name={'connectorName'}
						placeholder="test"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
					/>
					<InputCustom
						value={productFeedObject.connectorVersion }
						type={'text'}
						label={'Version'}
						name={'connectorVersion'}
						placeholder="1.2"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
					/>
				</>;
			case 'SasToken':
				return <>
					<InputCustom
						value={productFeedObject.resourceIdentifier}
						type={'text'}
						label={'URL'}
						name={'resourceIdentifier'}
						placeholder="https://exemple.com/feed"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
						autoFocus={true}
					/>
					<InputCustom
						value={productFeedObject.auth.sasToken}
						type={'password'}
						label={'Sas token'}
						name={'sasToken'}
						placeholder="4585yw5-54523cx-2125kj"
						onChange={handleChangeField}
						size="l"
						fullWidth={true}
					/>
					<div className={editionStyles.form_group_label}>
						{productFeedObject.format.type} settings
					</div>
					<InputCustom
						value={productFeedObject.connectorName}
						type={'text'}
						label={'Connector name'}
						name={'connectorName'}
						placeholder="test"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
					/>
					<InputCustom
						value={productFeedObject.connectorVersion }
						type={'text'}
						label={'Version'}
						name={'connectorVersion'}
						placeholder="1.2"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
					/>
				</>;
			case 'AuthorizationHeader':
				return <>
					<InputCustom
						value={productFeedObject.resourceIdentifier}
						type={'text'}
						label={'URL'}
						name={'resourceIdentifier'}
						placeholder="https://exemple.com/feed"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
						autoFocus={true}
					/>
					<InputCustom
						value={productFeedObject.auth.authorizationHeaderName}
						type={'text'}
						label={'Authorization header (leave empty for "Authorization")'}
						name={'authorizationHeaderName'}
						placeholder="Authorization"
						onChange={handleChangeField}
						size="l"
						fullWidth={true}
					/>
					<InputCustom
						value={productFeedObject.auth.authorizationHeaderContent}
						type={'text'}
						label={'Authorization header value'}
						name={'authorizationHeaderContent'}
						placeholder="Basic ZGFmeS11YXQ6dWFnZWlaNXk=,Token 1CD70AD30C46DF21879FEDBEE"
						onChange={handleChangeField}
						size="l"
						fullWidth={true}
					/>
					<InputCustom
						value={productFeedObject.connectorName}
						type={'text'}
						label={'Connector name'}
						name={'connectorName'}
						placeholder="test"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
					/>
					<InputCustom
						value={productFeedObject.connectorVersion}
						type={'text'}
						label={'Version'}
						name={'connectorVersion'}
						placeholder="1.2"
						onChange={handleChangeSimpleFieldFn}
						size="l"
						fullWidth={true}
					/>
				</>;
		}
	}
	function getFieldConnectionGenericUri(key){
		switch (key) {
			case 'None':
				return <>
					<InputCustom
						value={productFeedObject.resourceIdentifier}
						type={'text'}
						label={'URL'}
						name={'resourceIdentifier'}
						placeholder="https://exemple.com/feed"
						onChange={handleChangeSimpleFieldFn}
						fullWidth={true}
						autoFocus={true}
						size="l"
					/>
					<div className={editionStyles.form_group_label}>
						{productFeedObject.format.type} settings
					</div>
					{getTypesFieldsForFormatGenericUri(productFeedObject.format)}
				</>
				;
			case 'LoginPassword':
				return <>
					<InputCustom
						value={productFeedObject.resourceIdentifier }
						type={'text'}
						label={'URL'}
						name={'resourceIdentifier'}
						placeholder="https://exemple.com/feed"
						onChange={handleChangeSimpleFieldFn}
						fullWidth={true}
						autoFocus={true}
						size="l"
					/>
					<InputCustom
						value={productFeedObject.auth.login}
						type="text"
						label={'Login'}
						name={'login'}
						placeholder=""
						onChange={handleChangeField}
						fullWidth={true}
						size="l"
					/>
					<InputCustom
						value={productFeedObject.auth.password}
						type={'password'}
						label={'Password'}
						name={'password'}
						placeholder="Tape the password"
						onChange={handleChangeField}
						fullWidth={true}
						size="l"
					/>
					<div className={editionStyles.form_group_label}>
						{productFeedObject.format.type} settings
					</div>
					{getTypesFieldsForFormatGenericUri(productFeedObject.format)}
				</>;
			case 'ApiKey':
				return <>
					<InputCustom
						value={productFeedObject.resourceIdentifier}
						type={'text'}
						label={'URL'}
						name={'resourceIdentifier'}
						placeholder="https://exemple.com/feed"
						onChange={handleChangeSimpleFieldFn}
						fullWidth={true}
						autoFocus={true}
						size="l"
					/>
					<InputCustom
						value={productFeedObject.auth.apiKey}
						type={'password'}
						label={'Api key'}
						name={'apiKey'}
						placeholder="4585yw5-54523cx-2125kj"
						onChange={handleChangeField}
						fullWidth={true}
						size="l"
					/>
					<div className={editionStyles.form_group_label}>
						{productFeedObject.format.type} settings
					</div>
					{getTypesFieldsForFormatGenericUri(productFeedObject.format)}
				</>;
			case 'SasToken':
				return <>
					<InputCustom
						value={productFeedObject.resourceIdentifier}
						type={'text'}
						label={'URL'}
						name={'resourceIdentifier'}
						placeholder="https://exemple.com/feed"
						onChange={handleChangeSimpleFieldFn}
						fullWidth={true}
						autoFocus={true}
						size="l"
					/>
					<InputCustom
						value={productFeedObject.auth.sasToken}
						type={'password'}
						label={'Sas token'}
						name={'sasToken'}
						placeholder="4585yw5-54523cx-2125kj"
						onChange={handleChangeField}
						fullWidth={true}
						size="l"
					/>
					<div className={editionStyles.form_group_label}>
						{productFeedObject.format.type} settings
					</div>
					{getTypesFieldsForFormatGenericUri(productFeedObject.format)}
				</>;
			case 'AuthorizationHeader':
				return <>
					<InputCustom
						value={productFeedObject.resourceIdentifier}
						type={'text'}
						label={'URL'}
						name={'resourceIdentifier'}
						placeholder="https://exemple.com/feed"
						onChange={handleChangeSimpleFieldFn}
						fullWidth={true}
						autoFocus={true}
						size="l"
					/>
					<InputCustom
						value={productFeedObject.auth.authorizationHeaderName}
						type={'text'}
						label={'Authorization header (leave empty for "Authorization")'}
						name={'authorizationHeaderName'}
						placeholder="Authorization"
						onChange={handleChangeField}
						fullWidth={true}
						size="l"
					/>
					<InputCustom
						value={productFeedObject.auth.authorizationHeaderContent}
						type={'text'}
						label={'Authorization header value'}
						name={'authorizationHeaderContent'}
						placeholder="Basic ZGFmeS11YXQ6dWFnZWlaNXk=,Token 1CD70AD30C46DF21879FEDBEE"
						onChange={handleChangeField}
						fullWidth={true}
						size="l"
					/>
					{getTypesFieldsForFormatGenericUri(productFeedObject.format)}
				</>;
		}
	}
	function getFieldConnectionHttp(key){
		switch (key) {
			case 'None':
				return <>
					<InputCustom
						value={productFeedObject.resourceIdentifier}
						type={'text'}
						label={'URL'}
						name={'resourceIdentifier'}
						placeholder="https://exemple.com/feed"
						onChange={handleChangeSimpleFieldFn}
						fullWidth={true}
						autoFocus={true}
						size="l"
					/>
					<InputCustom
						value={productFeedObject?.httpBody?.ContentType ? productFeedObject?.httpBody?.ContentType  : ''}
						type={'text'}
						label={'Content type'}
						name={'contentType'}
						placeholder="Json"
						onChange={(e) => handleChangeContentTypeHttp(e)}
						fullWidth={true}
						autoFocus={true}
					/>
					<div className={editionStyles.form_group_label}>
						{productFeedObject.format.type} settings
					</div>
					<Fieldset label="Payload" description={<>Example : <code>{example}</code></>}>
						<ControlledEditor
							height="160px"
							width="100%"
							theme="dark"
							language={'json'}
							loading={
								<>
									<span className="mask">
										<span className="wheel"></span>
									</span>
								</>
							}
							value={productFeedObject?.httpBody?.Content? productFeedObject?.httpBody?.Content : ''}
							onChange={handleUpdate}
							editorDidMount={handleEditorDidMount}
							options={optionsEditor}
						/>
					</Fieldset>


					{getTypesFieldsForFormatGenericUri(productFeedObject.format)}
				</>
				;
			case 'ApiKey':
				return <>
					<InputCustom
						value={productFeedObject.resourceIdentifier}
						type={'text'}
						label={'URL'}
						name={'resourceIdentifier'}
						placeholder="https://exemple.com/feed"
						onChange={handleChangeSimpleFieldFn}
						fullWidth={true}
						autoFocus={true}
						size="l"
					/>
					<InputCustom
						value={productFeedObject.auth.apiKey}
						type={'password'}
						label={'Api key'}
						name={'apiKey'}
						placeholder="4585yw5-54523cx-2125kj"
						onChange={handleChangeField}
						fullWidth={true}
						size="l"
					/>
					<div className={editionStyles.form_group_label}>
						{productFeedObject.format.type} settings
					</div>
					{getTypesFieldsForFormatGenericUri(productFeedObject.format)}
				</>;
			
			case 'AuthorizationHeader':
				return <>
					<InputCustom
						value={productFeedObject.resourceIdentifier}
						type={'text'}
						label={'URL'}
						name={'resourceIdentifier'}
						placeholder="https://exemple.com/feed"
						onChange={handleChangeSimpleFieldFn}
						fullWidth={true}
						autoFocus={true}
						size="l"
					/>
					<InputCustom
						value={productFeedObject.auth.authorizationHeaderName}
						type={'text'}
						label={'Authorization header (leave empty for "Authorization")'}
						name={'authorizationHeaderName'}
						placeholder="Authorization"
						onChange={handleChangeField}
						fullWidth={true}
						size="l"
					/>
					<InputCustom
						value={productFeedObject.auth.authorizationHeaderContent}
						type={'text'}
						label={'Authorization header value'}
						name={'authorizationHeaderContent'}
						placeholder="Basic ZGFmeS11YXQ6dWFnZWlaNXk=,Token 1CD70AD30C46DF21879FEDBEE"
						onChange={handleChangeField}
						fullWidth={true}
						size="l"
					/>
					{getTypesFieldsForFormatGenericUri(productFeedObject.format)}
				</>;
		}
	}
	useEffect(()=>{
		setConnexionFields(getRenderFunctionByKeyType(productFeedObject?.type,productFeedObject?.auth?.type));
	},[productFeedObject]);
	function handleSave(){
		handleChangeStep(2);
		if(mode === 'edition' && productFeedObject?.additionalHeaders){
			saveElementInArrayOfHeaders();
		}
	}
	return (
		<div className={editionStyles.card}>
			<div className={editionStyles.card_body_l}>
				{mode === 'edition' &&
					<>
						<div className={editionStyles.card_back_title} onClick={() => handleChangeStep(2)}>
							<div className={editionStyles.card_back_title_icon}>
								<i className='fas fa-arrow-left fa-fw'></i>
							</div>
							<div className={editionStyles.card_back_title_txt}>
								Back to mapping
							</div>
						</div>
						<FeedCardHeader />
					</>
				}
				{mode === 'creation'&& headerCreation()}
				{connexionField}
				{mode === 'edition' && productFeedObject?.additionalHeaders &&
					<AdditionnalHeaders
						elementToSave={elementToSave}
						setelementToSave={setelementToSave}
						additionalHeaders={productFeedObject?.additionalHeaders}
					/>
				}
				<div className='mt_30 al_right'>
					<Btn
						message="Update settings"
						size="l"
						onClick={() => handleSave()}
					/>
				</div>
			</div>
		</div>
	);
}
