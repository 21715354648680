
import WorkflowServices from '../../../../Services/WorkflowServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';

import SystemServices from '../../../../Services/SystemServices';
import { ItemProcess } from '../context/typesWorkflow';

type CrudEmerchInsightsType = {
	loadWorkflowState: (callbackSuccess: (itemProcesses: ItemProcess[]) => void,
		callbackError: (error: any) => void, signal: { aborted: any; }) => void,
	accountWorkflowRequest: (item: any, callbackSuccess: () => void, callbackError: () => void, signal: { aborted: any; }) => void
}

export function crudEmerchInsights($http, $rootScope, $routeParams): CrudEmerchInsightsType {
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;

	const workflowServices = new WorkflowServices(accountId, $http);
	const systemServices = new SystemServices($rootScope);

	function loadWorkflowState(callbackSuccess: (itemProcesses: ItemProcess[]) => void, callbackError: (error: any) => void, signal: { aborted: any; }) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		workflowServices.getProcesses(
			(data: ItemProcess[]) => {
				if (callbackSuccess) callbackSuccess(data);
			},
			(err: any, dataError: any) => {
				console.warn('error', err);
				console.warn('=>(dataError', dataError);
				systemServices.showError();
				if (callbackError) callbackError(err);
			}
		);
	}

	function accountWorkflowRequest(item: any, callbackSuccess: () => void, callbackError: () => void, signal: { aborted: any; }) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		workflowServices.accountWorkflowRequest(
			item, () => {
				callbackSuccess();
				systemServices.showSuccess('Recommendations has been refreshed');
			},
			(err: any, dataError: any) => {
				console.warn('error', err);
				console.warn('=>(dataError', dataError);
				systemServices.showError();
				callbackError();
			}
		);
	}



	return {
		loadWorkflowState,
		accountWorkflowRequest
	};
}
