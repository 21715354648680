import React, { createContext, useContext, useState, useEffect } from 'react';
import useDisplayName from '../../../Hooks/useDisplayName';
import SystemServices from '../../../Services/SystemServices';
export const AnalyticsConfigContext = createContext();

export function useAnalyticsConfigContext() {
	return useContext(AnalyticsConfigContext);
}
export const AnalyticsConfigProvider = (props) => {
	const { getTheDisplayNameOfUser } = useDisplayName();
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);

	const isAnAdmin = props.isAnAdmin;

	const shouldLoad = props.shouldLoad;
	const [usersList, setUsersList] = useState([]);
	const [referenceData, setReferenceData] = useState({
		HasUsersByTenantConfig: false,
		tenantLoaded: false, tenant: [],
		usersLoaded: false, users: [],
	});

	const [isCreation, setisCreation] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [referenceDataIsAllLoaded, setReferenceDataIsAllLoaded] = useState(false);
	const [dataFiltered, setDataFiltered] = useState({...referenceData?.tenant});

	const [tenantModalIsOpen, setTenantModalIsOpen] = useState(false);
	const [tenantSelectedToModify, setTenantSelectedToModify ]= useState();
	const account = referenceData.account;
	const beyableKey = props.$rootScope.User.Account.ClientKey;
	useEffect(() => {
		loadAccountUsers();
		loadTenant();
	}, [shouldLoad]);


	useEffect(() => {
		if (referenceData.usersLoaded && referenceData.tenantLoaded) {
			setReferenceDataIsAllLoaded(true);
		} else {
			setReferenceDataIsAllLoaded(false);
		}
	}, [referenceData]);


	const loadAccountUsers = () => {
		setReferenceData(s => ({ ...s, users: [], usersLoaded: false }));
		props.getAccountUsers(data => {
			setReferenceData(s => ({ ...s, users: data.map(el => ({...el, 
				displayName :getTheDisplayNameOfUser(el)})), usersLoaded: true }));
		},setReferenceData(s => ({ ...s, users: [], usersLoaded: true })) );
	};
	const loadTenant = ()=>{
		setReferenceData(s => ({ ...s, tenant: [], tenantLoaded: false }));
		props.tm.getTenants(
			success => {
				const tenants = {...success.UsersByTenant};
				setReferenceData(s => ({ ...s, HasUsersByTenantConfig : success.HasUsersByTenantConfig, tenant: tenants, tenantLoaded: true }));
				setIsLoading(false);
			},
			err =>{
				setReferenceData(s => ({ ...s, HasUsersByTenantConfig :false, 
					tenant: [], tenantLoaded: true }));
				setIsLoading(false);
			}
		);
	};
	const setHasUsersByTenantConfig = (val) =>{
		setReferenceData(s => ({ ...s, HasUsersByTenantConfig: val }));
		changeAccountAnalyticsConfig(val);
	};

	const handleOpenCloseModalTenant = (value) =>{
		setTenantModalIsOpen(value);
	};
	const handleSelectTenant = (value) =>{
		setTenantSelectedToModify(value);
	};
	const handleChangeObjetTenantUsers =(value) =>{
		setTenantSelectedToModify(t => ({
			...t, users : value}));
	};
	const handleChangeTenant =(newTenant, tenantToSave) => {
		setReferenceData(data =>({
			...data,
			tenant : newTenant
		}));
		handleOpenCloseModalTenant(false);
		const nameOfTenant = tenantSelectedToModify.name;
		let users = [];
		if(!isCreation){
			users  = newTenant[nameOfTenant];
		}else{
			users = tenantSelectedToModify.users;
		}
		const obj = {
			tenant : nameOfTenant !== '' ? nameOfTenant : null,
			userIds : users
		};
		props.tm.putTenant(nameOfTenant, obj, 
			callbackSuccess => {
				setisCreation(false);
				loadTenant();
				systemServices.showSuccess('The tenant has been saved with success');
			},
			callbackError =>{
				systemServices.showError('An error occured');
			}
		);
	};
	
	const handleChangeTenantWithoutClose =(newTenant) => {
		handleOpenCloseModalTenant(true);
		setReferenceData(data =>({
			...data,
			tenant : newTenant
		}));
	};
	const deleteTenant = (tenant) =>{
		setIsLoading(true);
		props.tm.deleteTenant(tenant,
			callbackSuccess => {
				loadTenant();
				setIsLoading(false);
				systemServices.showSuccess('The tenant has been deleted with success');
			},
			callbackError =>{
				setIsLoading(false);
				systemServices.showError('An error occured');
			}
		);
	};
	function changeAccountAnalyticsConfig(val){
		setIsLoading(true);
		const obj = {
			apiKey: beyableKey,
			hasUsersByTenantConfig: val,
		};
		props.webAnalyticsServices.changeAccountConfig(obj,
			success =>{
				loadTenant();
				setIsLoading(false);
				systemServices.showSuccess('The config has been saved with success');
			},
			err =>{
				setIsLoading(false);
				systemServices.showError('An error occured');
			});
	}

	return (
		<AnalyticsConfigContext.Provider
			value={{
				isLoading,
				referenceData,
				setHasUsersByTenantConfig,
				dataFiltered,
				setDataFiltered,
				usersList,
				tenantModalIsOpen,
				handleOpenCloseModalTenant,
				handleSelectTenant,
				tenantSelectedToModify,
				handleChangeTenant,
				handleChangeTenantWithoutClose,
				isCreation,
				setisCreation,
				handleChangeObjetTenantUsers,
				deleteTenant
			}}
		>
			{props.children}
		</AnalyticsConfigContext.Provider>
	);
};

