import {isEqual} from 'lodash';
import {triggerCategories} from './CategoriesData';
import TriggerUtil from '../Campaigns/Triggers/TriggerUtil';
const {getDefaultOperator} = TriggerUtil();

export const addGroupForTriggers = (rule) => {
	let prevState = {...rule};
	for (const [key, value] of Object.entries(prevState.triggers)) {
		const group = {
			Label: prevState.name,
			Id: prevState.id
		};
		if (key === 'condition') {
			value.map(trigger => trigger.Group = group);
		} else {
			value.Group = group;
		}
	}
	return prevState;
};

export const isUsedSegment = (librarySegmentTriggers, usedSegments) => {
	let temp = 0;
	let index = -1;
	usedSegments.map((e, i) => {
		if (compareAllTriggersUnBuild(e.triggers, librarySegmentTriggers)) {
			temp += 1;
			index = i;
		}
	});

	return {isUsed: temp > 0, index: index};
};

export const compareAllTriggersUnBuild = (usedSegmentTrigger, librarySegmentTriggers) => {
	const rawUsedSegmentTrigger = unBuildTriggers(usedSegmentTrigger);
	const rawLibrarySegmentTriggers = unBuildTriggers(librarySegmentTriggers);

	let temp = false;

	if (rawUsedSegmentTrigger.length === rawLibrarySegmentTriggers.length) {

		temp = 0;
		for (let i = 0; i < rawUsedSegmentTrigger.length; i++) {
			for (let j = 0; j < rawLibrarySegmentTriggers.length; j++) {
				if (isEqual(filterTriggerId(rawUsedSegmentTrigger[i]), filterTriggerId(rawLibrarySegmentTriggers[j]))) {
					temp++;
				}
			}
		}
		temp === rawUsedSegmentTrigger.length ? temp = true : temp = false;
	}
	return temp;
};

const filterTriggerId = (trigger) => {
	return {
		Categorie: trigger.Categorie,
		Label: trigger.Label,
		Name: trigger.Name,
		Separator: trigger.Separator,
		Tooltip: trigger.Tooltip,
		Type: trigger.Type,
		Unit: trigger.Unit,
		ValueToCompare: trigger.ValueToCompare
	};
};

const unBuildTriggers = (rule) => {
	const arrayOfTrigger = [];
	for (const [key, value] of Object.entries(rule)) {
		if (key === 'condition') {
			value.map(e => arrayOfTrigger.push(e));
		} else {
			arrayOfTrigger.push(value);
		}
	}
	return arrayOfTrigger;
};
const i18nL= localStorage.getItem('i18nL');

export const getTriggerLabel = (type, label, value, name) => {
	if (name === 'TRGREGISTERED') {
		if(i18nL && i18nL.includes('fr')){
			return value ? 'Le visiteur est enregistré' : 'Le visiteur n\'est pas enregistré';
		}else{
			return value ? 'Visitor is registered' : 'Visitor is not registered';
		}
	}

	if (name === 'TRGCLIENT') {
		if(i18nL && i18nL.includes('fr')){
			return value ? 'Le visiteur est enregistré' : 'Le visiteur n\'est pas un client';
		}else{
			return value ? 'Le visiteur est un client' : 'Visitor is not a client';
		}
	}

	return label;
};

export const getTriggerToolTip = (tooltip, value, name) => {
	if (name === 'TRGCLIENT') {
		if(i18nL && i18nL.includes('fr')){
			return value ? 'L\'utilisateur a effectué un achat au cours des 14 derniers jours' : 'Pas d\'achat au cours des 14 derniers jours';
		}
		else{
			return value ? 'The user has made a purchase in the last 14 days' : 'No purchase in the last 14 days';
		}
	}

	return tooltip;
};

export const getTriggerValue = (type, value, name) => {

	//WIP
	const getEnumSentence = (items) => {
		// Return a sentence with commas and a final "and" between list items
		let str = '';
		for (let i = 0; i < items.length; i++) {
			const item = items[i];
			if (i === 0) {
				str += item;
			} else if (i === items.length - 1) {
				const and = i18nL && !i18nL.includes('fr') ? ' and ' : ' et ' ;
				str += and + item;
			} else {
				str += ', ' + item;
			}
		}
		return str;
	};
	if (type === 'geoloc') {
		const city = value.map(e => e.Address);
		return city.join(' - ');
	}

	if (typeof value == 'boolean') {
		return '';
	}

	if (name === 'TRGGIVEPRODUCTDETAILS') {
		return value.toString().replace(/;/g, '\n');
	}

	if (name === 'TRGTARGETTENANT' || name === 'TRGCHECKURLTAGS') {
		const list = value.toString().split(';');
		return getEnumSentence(list);
	}

	if (name === 'TRGTARGETDAY') {
		const days = i18nL && i18nL.includes('fr') ? ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'] : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
		const list = value.map(v => days[v-1]);
		return getEnumSentence(list);
	}

	if (name === 'TRGCHECKLANGUAGE' || name === 'TRGEXCLUDEDLANGUAGES') {
		const words =i18nL && i18nL.includes('fr') ? {
			'it': 'italien',
			'fr': 'français',
			'en': 'anglais',
			'de': 'allemand',
			'pt': 'portugais',
			'es': 'espagnol'
		} : {
			'it': 'italian',
			'fr': 'french',
			'en': 'english',
			'de': 'german',
			'pt': 'portugues',
			'es': 'spanish'
		};
		const list = value.map(v => words[v]);
		return getEnumSentence(list);
	}

	if (name === 'TRGCHECKURLTYPE') {
		const types = i18nL && i18nL.includes('fr') ?
			{'H' : 'Accueil',
				'C' : 'Catégorie',
				'FP' : 'Produit',
				'B' : 'Panier (B)',
				'BS' : 'Panier (BS)',
				'BL' : 'Panier (BL)',
				'T' : 'Confirmation',
				'GBlog' : 'Blog (GBlog)',
				'G' : 'Autres (non commercial)',
				'E' : 'Erreur'
			} :
			{
				'H': 'Home',
				'C': 'Category',
				'FP': 'Product',
				'B': 'Basket (B)',
				'BS': 'Basket (BS)',
				'BL': 'Basket (BL)',
				'T': 'Confirmation',
				'GBlog': 'Blog (GBlog',
				'G': 'Others (non-commercial)',
				'E': 'Error'
			};
		const list = value.map(v => types[v]);
		return getEnumSentence(list);
	}

	if (name === 'TRGCHECKENGAGEMENT') {
		const types =i18nL && i18nL.includes('fr') ?
			{
				'D' : 'Découverte',
				'C' : 'Considération',
				'I' : 'Intention',
				'A' : 'Décision',
				'E' : 'Expérience',
				'R' : 'Ré-achat'
			}:
			{
				'D': 'Discovery',
				'C': 'Consideration',
				'I': 'Intention',
				'A': 'Decision',
				'E': 'Experience',
				'R': 'Repetitive Purchase'
			};
		const list = value.map(v => types[v]);
		return getEnumSentence(list);
	}

	if (name === 'TRGTARGETHOUR') {
		return value + 'h';
	}

	if (name === 'TRGTIMESPENTONSITE' || name === 'TRGINACTIVE') {
		return value + ' seconds';
	}

	return value.toString().trim();
};

export const getTriggerIcon = (cat) => {
	if (cat in triggerCategories) {
		return triggerCategories[cat].Icon;
	}
};

export const getTriggerOperand = (trigger) => {
	if (trigger.DataTriggerOperands && trigger.DataTriggerOperands.length > 0) {
		const defaultOperator = getDefaultOperator(trigger);
		const o = trigger.DataTriggerOperands.find(e => e.value === defaultOperator);
		if (!o || !o.label) return '';
		let l = o.label;
		l = l.replace('(⩾)', '');
		l = l.replace('(=)', '');
		l = l.replace('(⩽)', '');
		return l;
	}
	return '';
};

export const updateNewNameForAllTriggersGroup = (rule, newName) => {
	for (const [key, value] of Object.entries(rule.triggers)) {
		if (key === 'condition') {
			value.map(trigger => trigger.Group.Label = newName);
		} else {
			value.Group.Label = newName;
		}
	}
	rule.name = newName;
	return rule;
};

export const getLocalisedValue = (v) => {
	const i18nL= localStorage.getItem('i18nL');
	const currLangCode = i18nL.includes('fr') ? 'fr' : 'en';
	const defaultLangCode = 'en';

	if (!v) return '';

	if (typeof v === 'string') return v;

	if (typeof v === 'object') {
		if (currLangCode in v) return v[currLangCode];
		if (defaultLangCode in v) return v[defaultLangCode];

		const [firstKey, firstValue] = Object.entries(v)[0];
		return firstValue;
	}
};
