
import React, { useState } from 'react';
import Dropdown from '../../../../Components/Dropdown';
import Btn from '../../../../Components/Btn';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { multiChoice, singleChoice } from '../context/Constants.js';

import { onSiteContext } from '../context';
export default function CampaignsListCreateButton({ isAdmin, $routeParams, campaignsListCreationOptions }) {
	const [createDropdownIsOpen, setCreateDropdownIsOpen] = useState(false);

	const [t] = useTranslation(['common']);

	const {
		campaignCreationVersion,
		canCreateInAppCampaigns,
		canCreatePalCampaigns,	
		canCreateExternalAppCampaigns,
		canCreateCodePatchCampaigns,
		canCreatePatchCampaigns,
		canCreateClassicCampaigns,
		canCreateSaaSCampaigns
	} = campaignsListCreationOptions;

	const {
		openInspirations,
		openCatalogue
	} = onSiteContext();
	return (
		<>
			{campaignCreationVersion === multiChoice && (
				<Dropdown
					isOpen={createDropdownIsOpen}
					setIsOpen={setCreateDropdownIsOpen}
					onHide={(ev) => setCreateDropdownIsOpen(false)}
					button={
						<Btn
							message={t('nav.newCampaign')}
							arrow={true}
							className="btn_w_l"
						/>
					}
				>
					<ul className="listbox">
						{(isAdmin || canCreateSaaSCampaigns) && (
							<li>
								<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}` : '/Campaigns/Editor'}>
									<span className="flex">
										<span className="flex_item_fix listbox_item_icon">
											<span className="icon_32 icon_layout icon_color_turquoise"></span>
										</span>
										<span className="flex_item_full">
											<span className="listbox_item_title">
												{t('nav.CustomTemplate')}
											</span>
											<span className="listbox_item_desc">{t('nav.useCustomTemplate')}</span>
										</span>
									</span>
								</a>
							</li>
						)}
						<li className="hr"></li>
						{(isAdmin || canCreatePalCampaigns) && (
							<>
								<li className='flex'>
									<a className="listbox_item l flex flex_item_full" onClick={() => { setCreateDropdownIsOpen(false); openInspirations(); }}>
										<span className="flex">
											<span className="flex_item_fix listbox_item_icon">
												<span className="icon_32 icon_idea icon_color_turquoise"></span>
											</span>
											<span className="flex_item_full">
												<span className="listbox_item_title">{t('nav.inspiration library')}</span>
												<span className="listbox_item_desc">
													{t('nav.Browse our library of ready-to-use templates')}
												</span>
											</span>
										</span>
									</a>
									{isAdmin &&
										<Btn
											color="secondary"
											style="outline"
											size="xxs"
											className="s_11 mr_10 btn_no_min_width"
											onClick={() => { setCreateDropdownIsOpen(false); openCatalogue(); }}
											message="BETA"
										/>
									}
								</li>
							</>
						)}
						<li className="hr"></li>
						{(isAdmin || canCreatePatchCampaigns) && (
							<li>
								<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&graph` : '/Campaigns/Editor?graph'}>
									<span className="flex">
										<span className="flex_item_fix listbox_item_icon">
											<span className="icon_32 icon_color icon_color_turquoise"></span>
										</span>
										<span className="flex_item_full">
											<span className="listbox_item_title">
												{t('nav.graphicPatch')}
											</span>
											<span className="listbox_item_desc">{t('nav.useGraphic')}</span>
										</span>
									</span>
								</a>
							</li>
						)}
						<li className="hr"></li>
						{(isAdmin || canCreateCodePatchCampaigns) && (
							<li>
								<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&patch` : '/Campaigns/Editor?patch'}>
									<span className="flex">
										<span className="flex_item_fix listbox_item_icon">
											<span className="icon_32 icon_code icon_color_turquoise"></span>
										</span>
										<span className="flex_item_full">
											<span className="listbox_item_title">
												{t('nav.codePatch')}
											</span>
											<span className="listbox_item_desc">
												{t('nav.patchYourWeb')}
											</span>
										</span>
									</span>
								</a>
							</li>
						)}
						<li className="hr"></li>
						{(isAdmin) && (
						// {(isAdmin || canCreateABtest) && (
							<li>
								<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&abtest` : '/Campaigns/Editor?abtest'}>
									<span className="flex">
										<span className="flex_item_fix listbox_item_icon">
											<span className="icon_32 icon_abtest icon_color_turquoise"></span>
										</span>
										<span className="flex_item_full">
											<span className="listbox_item_title">
												{t('staterOnSite.abTest')}
											</span>
											<span className="listbox_item_desc">
												{t('staterOnSite.makeAbTests')}
											</span>
										</span>
									</span>
								</a>
							</li>
						)}
						<li className="hr"></li>
						{/* Remove false to show Shopify link for admins or users with multiple permissons */}
						{(canCreateExternalAppCampaigns && false) && (
							<li>
								<Link className="listbox_item xl flex"
									to={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&module=shopify001` : '/Campaigns/Editor?module=shopify001'}>
									<span className="flex">
										<span className="flex_item_fix listbox_item_icon">
											<span className="icon_32 icon_shopify icon_color_turquoise"></span>
										</span>
										<span className="flex_item_full">
											<span className="listbox_item_title">
												{t('nav.ShopifyCampaign')}
											</span>
										</span>
									</span>
								</Link>
							</li>
						)}
						<li className="hr"></li>
						{(canCreateInAppCampaigns) && (
							<li>
								<Link className="listbox_item xl flex"
									to={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&appNative` : '/Campaigns/Editor?appNative'}>
									<span className="flex">
										<span className="flex_item_fix listbox_item_icon">
											<span className="icon_32 icon_mobile_app icon_color_turquoise"></span>
										</span>
										<span className="flex_item_full">
											<span className="listbox_item_title">
												{t('nav.inAppCampaign')}
											</span>
											<span className="listbox_item_desc">
												{t('nav.inAppCampaignDescription')}
											</span>
										</span>
									</span>
								</Link>
							</li>
						)}
						<li className="hr"></li>
						{(isAdmin || canCreateClassicCampaigns) && (
							<li>
								<a className="listbox_item xl" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Create?ka=${$routeParams.ka}&ku=${$routeParams.ku}` : '/Campaigns/Create'}>
									<span className="flex">
										<span className="flex_item_fix listbox_item_icon listbox_item_icon_32">
											<span className="icon_20 icon_idea icon_color_grey"></span>
										</span>
										<span className="flex_item_full grey_2">
											{t('nav.classicCampaign')}
										</span>
									</span>
								</a>
							</li>
						)}
					</ul>
				</Dropdown>
			)}
			{campaignCreationVersion === singleChoice &&
                <>
                	{canCreatePalCampaigns &&
                        <Btn
                        	onClick={(ev) => openInspirations()}
                        >{t('nav.newCampaign')}</Btn>
                	}
                	{canCreateSaaSCampaigns &&
                        <Link className="btnUi btn_primary btn_l btn_fs_l"
                        	to={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}` : '/Campaigns/Editor'}>
                        	<div><i className="fa fa-plus"></i><span className="btn_txt">{t('nav.newCampaign')}</span></div>
                        </Link>
                	}
                	{canCreateExternalAppCampaigns &&
                        <Link className="btnUi btn_primary btn_l btn_fs_l"
                        	to={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&module=shopify001` : '/Campaigns/Editor?module=shopify001'}>
                        	<div><i className="fa fa-plus"></i><span className="btn_txt">{t('nav.newCampaign')}</span></div>
                        </Link>
                	}
                </>
			}
		</>
	);
}