
import React, {useEffect, useState} from 'react';
import { TemplateProperty } from '../../../WysiwygEditor/Types/BespokeTypes';

import { DeviceType, Template } from '../Context/TemplateTypes';
import TemplateDisplayThumbnail from './TemplateDisplayThumbnail';
import styles from '../CatalogueContainer.module.css';
type Props = {
	properties : TemplateProperty[],
	styleProperties: TemplateProperty[],
	template:Template,
	name: string,
	handleClick?: ()=>void;
	isSelected?: boolean;
	isMinimized?: boolean;
	device:DeviceType
	hasPlaceholder?: boolean;
	idStyle? : string;
	style?:any;
}

export const TemplateStyle = ({
	template,
	properties,
	styleProperties,
	name,
	handleClick,
	isSelected = false,
	isMinimized = false,
	device,
	hasPlaceholder = false,
	idStyle,
}:Props) => {

	const [modelTemplateWithStyleSelected, setModelTemplateWithStyleSelected] = useState<Template>();
	useEffect(() => {
		if(template && properties && styleProperties){
			if (styleProperties) {
				if(idStyle !== 'default'){

					const templateWithMergeProperties = {...template,
						Properties : styleProperties};
					setModelTemplateWithStyleSelected(templateWithMergeProperties);
				}
				else{
					const mergeProperties =([...properties|| []]);
					const templateWithMergeProperties = {...template,
						Properties : mergeProperties};
					setModelTemplateWithStyleSelected(templateWithMergeProperties);
				}
			}
		}
	}, [template, properties, styleProperties]);

	return (
		<>
			{modelTemplateWithStyleSelected &&
				<TemplateDisplayThumbnail
					device={device}
					template={modelTemplateWithStyleSelected}
					onClick={handleClick ? () => handleClick() : () => null}
					title={name}
					tags={[]}
					isSelected={isSelected}
					isMinimized={isMinimized}
					isStyle={true}
					idStyle={idStyle}
					styleProperties={styleProperties}
				/>
			}
			{!modelTemplateWithStyleSelected && hasPlaceholder &&
				<div className={styles.catalogueItem}></div>
			}
		</>
	);
};


