import React, {useEffect, useState} from 'react';
import { react2angular } from 'react2angular';
import angular from 'angular';
import WorkflowProvider from './context/WorkflowProcessContext';

import {ProcessContainer} from './UI/ProcessContainer';

export default function WorkFlowContainer(props) {
	const [tabSelected, setTabSelected] = useState(false);
	useEffect(()=>{
		if( props?.tabSelected === 14){
			setTabSelected(true);
		}else{
			setTabSelected(false);
		}
	},[props.tabSelected]);
	return (
		<WorkflowProvider
			$http={props.$http}
			$routeParams={props.$routeParams}
			AuthServices={props.AuthServices}
			$timeout={props.$timeout}
			$location={props.$location}
			$rootScope={props.$rootScope}
			tabSelected={tabSelected}
		>
			<div className={'flex'}>
				<ProcessContainer></ProcessContainer>
			</div>
		</WorkflowProvider>

	);
}

angular
	.module('beyableSaasApp.WorkflowProcess', [])
	.component('workflowprocess', react2angular(WorkFlowContainer, ['tabSelected'],
		['$rootScope', '$http', '$routeParams', '$timeout','AuthServices','$location']));