import React, { useState, useEffect } from 'react';

import { ValueElementWithLabelTraduction } from '../../NavigationFunnelTypes';
import SelectDropdown from '../../../../../../Components/SelectDropdown';

type Props = {
	name: string;
	children?: React.ReactNode;
	context:any;
};

export const OptionnalFilterItem = ({ name ,context}: Props): React.ReactElement => {
	const {
		filterOptionnal,
		rawDimensions,
		handleChangeOptionnalKeyOption
	} = context;

	const [searchResult, setSearchResult] = useState<any>();
	const [inputSearch, setInputSearch] = useState('');
	const [filterValueSelected, setFilterValueSelected] = useState<any>('');
	const [labelElement, setLabelElement] = useState('');
	const [listValues, setListValues] = useState<ValueElementWithLabelTraduction[]>();

	function handleChangeFilterSelected(elementSelected: string) {
		if(!elementSelected){
			setFilterValueSelected('');
			handleChangeOptionnalKeyOption({
				key: name,
				value: '',
			});
		}else{
			setFilterValueSelected(elementSelected);
			handleChangeOptionnalKeyOption({
				key: name,
				value: elementSelected,
			});

		}
	}

	const handleChangeSearch = (event: string) => {
		setInputSearch(event);
		search(listValues, event);
	};

	const search = (table:any = [], word: string) => {
		if (word.length > 0) {
			const resultName = table.filter(line =>
				line.label.toUpperCase().includes(word.toUpperCase())
			);
			const res = resultName.map(i => ({
				value: i.value,
				label: i.label,
			}));
			setSearchResult(res);
		} else {
			setSearchResult(listValues);
		}
	};

	useEffect(() => {

		if ( rawDimensions) {
			const isEmpty = (rawDimensions) => Object.keys(rawDimensions).length === 0;
			const isEmptyBool = isEmpty(rawDimensions);
			if(!isEmptyBool){
				const item = rawDimensions[name];
				const arrayOfDimensionsWhereNeedToDelete = ['PageType', 'FirstPageType', 'LastPageType'];
				const keyToDelete = ['','O','G'];
				let valuesToMapped = [...item.values];
				if(arrayOfDimensionsWhereNeedToDelete.includes(name)){
					valuesToMapped = valuesToMapped.filter(el => !keyToDelete.includes(el.id) );
				}
				setLabelElement(item.label);

				const findElementInList = filterOptionnal?.find(x => x.key === name);
				if (findElementInList) {
					setFilterValueSelected(findElementInList.value);
				}

				const mappedValuesKeys = valuesToMapped.map(c => ({
					value: c.id,
					label: c.label,
				}));

				mappedValuesKeys.unshift({
					value: '',
					label: 'All'
				});

				setSearchResult(mappedValuesKeys);
				setListValues(mappedValuesKeys);

			}
		}

	}, [rawDimensions, filterOptionnal, name]);

	if (!rawDimensions || !filterOptionnal) {
		return <></>;
	}
	const friendlyName = listValues?.find(x => x.value === filterValueSelected);

	return (
		<>
			{listValues && listValues.length > 1 && (
				<SelectDropdown
					label={labelElement}
					labelPosition={'inner'}
					size={'m'}
					optionsList={searchResult}
					value={filterValueSelected}
					onChange={handleChangeFilterSelected}
					color={'white'}
					friendlyValue={friendlyName ? friendlyName.label : filterValueSelected}
					autocomplete={true}
					onAutocomplete={handleChangeSearch}
					autocompleteValue={inputSearch}
					autocompletePlaceholder={'Search'}
					className={filterValueSelected ? 'highlighted' : ''}
					isClearable={!!filterValueSelected}
				/>
			)}
		</>
	);
};

