
import { TemplateDescriptor, TemplateProperty, Wysiwyg} from '../../../WysiwygEditor/Types/BespokeTypes';
import {Style} from '../../../EmailTemplate/Editor/context/StyleTypes';
export enum DeviceType {
	Desktop = 'desktop',
	Mobile = 'mobile',
}
export type TemplateObject = {
	//SlideFormat: string;
	Id: string | null;
	Name: string;
	Description: string | null;
	DesignProperties: [];
	Properties: TemplateProperty[];
	TemplateType: string;
	TemplateName: string;
	TemplateDescriptorDesktop: TemplateDescriptor;
	TemplateDescriptorMobile: TemplateDescriptor;
	SlideTemplateDescriptorDesktop?:TemplateDescriptor;
	SlideTemplateDescriptorMobile?:TemplateDescriptor;
	SavedStyle: string;
	Template: Template | null;
	Status: TemplateStatus;
	WysiwygDesktop: Wysiwyg;
	WysiwygMobile: Wysiwyg;
}

export type Template = {
	Key: string;
	Name: string;
	Description: string;
	ThumbnailUrl: string;
	TemplateName: string;
	Format?: {id: string};
	Objective?: {id: string; icon?: string};
	TemplateDescriptorDesktop: TemplateDescriptor;
	TemplateDescriptorMobile: TemplateDescriptor;
	Properties: TemplateProperty[];
	WysiwygDesktop: Wysiwyg;
	WysiwygMobile: Wysiwyg;
	DefaultStyle?: Style;
	SlideTemplateDescriptorDesktop?:TemplateDescriptor;
	SlideTemplateDescriptorMobile?:TemplateDescriptor;
	IdentifierModule?:string;
	ModuleUrl?:string;
	TranslationKey?:string;
}


export enum TemplateStatus {
	Active = 'Active',
	Inactive = 'Inactive'
}
export const emptyTemplate: TemplateObject = {
	//SlideFormat: '',
	Id: null,
	Name: 'New personalized email',
	Description: '',
	DesignProperties: [],
	Properties: [],
	TemplateType: '',
	TemplateName: '',
	TemplateDescriptorDesktop: {
		Css: '',
		EditableCss: '',
		EditableTemplate: {
			ContentTemplateType: 'Fluid',
			Content: '',
		},
	},
	WysiwygDesktop: {Template: ''},
	WysiwygMobile: {Template: ''},
	TemplateDescriptorMobile: {
		Css: '',
		EditableCss: '',
		EditableTemplate: {
			ContentTemplateType: 'Fluid',
			Content: '',
		},
	},
	SavedStyle: '',
	Template: null,
	Status: TemplateStatus.Inactive
};
