import React, { useState, ReactNode } from 'react';

interface ExpandableSectionProps {
	title: string;
	children: ReactNode;
}

const ExpandableSection: React.FC<ExpandableSectionProps> = ({ title, children }) => {
	const [expanded, setExpanded] = useState(false);

	const toggleExpand = () => {
		setExpanded(!expanded);
	};

	return (
		<div>
			<div onClick={toggleExpand} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', minHeight: '30px' }}>
				<i className={`fas ${expanded ? 'fa-chevron-up' : 'fa-chevron-down'}`} style={{ marginRight: '8px' }} />
				{title}
			</div>
			{expanded && <div>{children}</div>}
		</div>
	);
};

export default ExpandableSection;