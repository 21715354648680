
import React, {useEffect, useState} from 'react';
import moment from 'moment/moment';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
import formatMoment from '../../../Constants/FormatMoment';
import Btn from '../../../Components/Btn';
import CampaignStatus from '../../Dashboard/OnSite/UI/CampaignStatus';
import DropDownAction from '../../Dashboard/OnSite/UI/DropDownAction';
import Dropdown from '../../../Components/Dropdown';
//import IconBtn from '../../../Components/IconBtn';
import Picto from '../../../Components/Picto';
import { useTranslation } from 'react-i18next';
import renderJsxTag from '../../../Translation/translation-utils';
import useCampaignStatus from '../../../Hooks/useCampaignStatus';
import styles from './NotificationCenter.module.css';
import PreviewLink from '../../Dashboard/OnSite/List/PreviewLink';

import { iconNotification } from '../../../Components/CustomIcon/CustomIcon';

export const NotificationCenterItem = ({
	center,
	permissionsAccount,
	isAdmin,
	accountId,
	$routeParams,
	centersAreOpen,
	setCentersAreOpen
}) => {

	const [t] = useTranslation('common');
	const tooltipID = uuidv4();
	const [devices, setdevices] = useState<any>([]);
	const [permissionsUser, setPermissionsUser] = useState({});
	const [modificationCampaign, setmodificationCampaign] = useState(<></>);
	const [reportingLink, setReportingLink] = useState(<></>);
	const [editDropdownIsOpen, setEditDropdownIsOpen] = useState(false);
	const [actionsDropdownIsOppen, setActionsDropdownIsOppen] = useState(false);
	const status = useCampaignStatus(center);

	const { formatDateToLocal } = formatMoment;
	const startDate = formatDateToLocal(center.startDate);
	let endDate;
	if (center.endDate) {
		endDate = formatDateToLocal(center.endDate);
	}

	const getDuplicatedCampaignEditorPath = (campaignId) => {
		const isClassicEditor = !center.editor || center.editor === 'classic';
		if (isClassicEditor) {
			let url = '/Campaigns/Create/' + campaignId;
			if ($routeParams && $routeParams.ka && isAdmin) {
				url += '?ka=' + $routeParams.ka + '&ku=' + $routeParams.ku;
			}
			return url;
		}
		else {
			const url:string = '/Campaigns/Editor/' + campaignId;
			const query:Array<string> = [];
			query.push('from=NotificationCenter/Dashboard');
			if ($routeParams && $routeParams.ka && isAdmin) {
				query.push('ka=' + $routeParams.ka);
				query.push('ku=' + $routeParams.ku);
			}
			query.push('edit=info');
			return url + '?' + query.join('&');
		}
	};
	const getTransformedToNativeAppCampaignEditorPath = (campaignId) => {
		const url = '/Campaigns/Editor/' + campaignId;
		const query:Array<string> = [];
		query.push('from=NotificationCenter/Dashboard');
		if ($routeParams && $routeParams.ka && isAdmin) {
			query.push('ka=' + $routeParams.ka);
			query.push('ku=' + $routeParams.ku);
		}
		query.push('edit=info');
		return url + '?' + query.join('&');
	};
	const hasAccountPermission = (permission) =>
		isAdmin || (permissionsAccount && (permissionsAccount.find(perm => perm.Name === permission) || {}).Value == true);
	const hasPermission = (permission) =>
		isAdmin || (hasAccountPermission(permission) && permissionsUser && permissionsUser[permission]);
	const statusModificationTorender = () => {
		if (hasPermission('CAMPAIGNS_CAN_CREATE')) {
			const url = '/Campaigns/Editor/' + center.id;
			const query:any = [];
			query.push('from=NotificationCenter/Dashboard');
			if ($routeParams && $routeParams.ka) {
				query.push('ka=' + $routeParams.ka);
				query.push('ku=' + $routeParams.ku);
			}
			const toQueryString = (moreQuery) => '?' + [...query, ...moreQuery].join('&');

			setmodificationCampaign(
				<>
					<Dropdown
						isOpen={editDropdownIsOpen}
						setIsOpen={() => setEditDropdownIsOpen(true)}
						onHide={() => setEditDropdownIsOpen(false)}
						button={
							<Btn
								style="outline"
								color="secondary"
								arrow="true"
								size="xs"
								icon="fas fa-pen"
							/>
						}
					>
						<ul className="listbox">
							<li>
								<a className="listbox_item" href={url + toQueryString(['edit=format'])}>
									<i className="fas fa-palette listbox_item_icon"></i>
									{t('CampaignItem.editDesign')}
								</a>
							</li>
							<li className="hr"></li>
							<li>
								<a className="listbox_item" href={url + toQueryString(['edit=triggers'])}>
									<i className="fas fa-bolt listbox_item_icon"></i>
									{renderJsxTag(t('CampaignItem.EditDisplayRules'))}
								</a>
							</li>
							<li className="hr"></li>
							<li>
								<a className="listbox_item" href={url + toQueryString(['edit=info'])}>
									<i className="fas fa-info-circle listbox_item_icon"></i>
									{t('CampaignItem.EditCampaign')}
								</a>
							</li>
						</ul>
					</Dropdown>
				</>
			);

			const statisticsAcess = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'STATISTICSCAMPAIGNS_CAN_ACCESS');
			const can = isAdmin || statisticsAcess && statisticsAcess.Value == true;
			if (can) {
				let reportingURL = '/NotificationCenter/Reporting/?dimension=onSite&id=' + center.id;
				if ($routeParams) {
					if (isAdmin) {
						reportingURL += '&ka=' + accountId + '&ku=' + $routeParams.ku;
					}
				}
				setReportingLink(
					<Btn
						style="reverse_outline"
						message="Report"
						icon="far fa-chart-bar"
						tooltip={t('CampaignItem.viewReport')}
						href={reportingURL}
						fullWidth={true}
						size="s"
					/>
				);
			}
			
		}
	};

	function getLastUpdateDate(date){
		if (date) {
			return moment(date).format('DD/MM/YYYY - hh:mm');
		}
	}

	useEffect(() => {
		if (center) {
			const campaignDevices = center.devices.map(device => device.deviceId);
			setdevices(campaignDevices);

			const listOfCampaignPermission = (center.currentUserPermissions || [])
				.reduce((acc, perm) => ({ ...acc, [perm.permission]: perm.isAllowed }), {});
			setPermissionsUser(listOfCampaignPermission);
		}
	}, [center]);

	useEffect(() => {
		statusModificationTorender();
	}, [permissionsUser,editDropdownIsOpen]);

	return (
		<div className={centersAreOpen ? styles.centerItemOpen : styles.centerItemClosed}>
			<ReactTooltip
				id={tooltipID}
				backgroundColor="black"
				effect="solid"
				place="bottom"
				globalEventOff="mouseup"
				delayShow={600}
			/>


			<div className={styles.centerItemHeader} onClick={() => setCentersAreOpen(!centersAreOpen)}>
				<div className='flex'>
					<div className='flex_item_fix'>
						<div className={styles.centerItemPicto}>
							<Picto
								color="dark_blue"
								iconCustom={iconNotification}
								size={centersAreOpen ? 'l' : 'm'}
							/>
						</div>
					</div>
					<div className='flex_item_full'>
						<div className='flex'>
							<div className='flex_item_auto ellips'>
								<span className={styles.centerItemName}>{center.name}</span>
							</div>
							{!centersAreOpen &&
								<div className='flex_item_fix'>
									<div className={styles.centerItemStatus}>
										<span
											className={'campaign_status_tag_color ' + status.Key}
											data-for={tooltipID}
											data-tip={status.Label}
										></span>
									</div>
								</div>
							}
						</div>
						
						{centersAreOpen &&
							<div className={styles.centerItemDate}>
								{startDate.format('DD/MM/YYYY')}
								<i className={styles.centerItemDateArrow + ' fas fa-arrow-right'}></i>
								{endDate ?
									<>{endDate.format('DD/MM/YYYY')}</>
									:
									<span className="grey_2 fw_normal">({t('CampaignInformations.noEndDate')})</span>
								}
							</div>
						}
					</div>
					<div className='flex_item_fix ml_10'>
						<div className={styles.centerItemFold}>
							<i className={centersAreOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}></i>
						</div>
					</div>
				</div>
			</div>

			{centersAreOpen &&
				<div className={styles.centerItemBody}>
					<div className={styles.centerItemMainActions}>
						<div className='flex'>
							<div className='flex_item_fix mr_10'>
								<PreviewLink 
									campaign={center}
									size="s"
									hasIcon={true}
								/>
							</div>
							<div className='flex_item_full'>
								{reportingLink}
							</div>
						</div>
					</div>
					<div className={styles.centerItemInfo}>
						<div className={styles.centerItemInfoLine}>
							<span className={styles.centerItemInfoLineLabel}>
								{t('campaignsList.LastUpdate')}:
							</span> {getLastUpdateDate(center.modificationDate)}
						</div>
						<div className={styles.centerItemInfoLine}>
							<span className={styles.centerItemInfoLineLabel}>
								{t('campaignsList.devices')}:
							</span>
							{devices.includes('D_SMARTPHONE') && (
								<i
									data-for={tooltipID}
									className="fas fa-mobile-alt grey_2"
									data-tip="Mobile"
								/>
							)}
							{devices.includes('D_PC') && (
								<i
									data-for={tooltipID}
									className="fas fa-desktop grey_2"
									data-tip="Desktop"
								/>
							)}
						</div>
						<div className={styles.centerItemInfoLine}>
							<span className={styles.centerItemInfoLineLabel}>ID:</span> {center.id}
						</div>
					</div>
					<div className='flex'>
						<div className='flex_item_full'>
							<CampaignStatus campaign={center} />
						</div>
						<div className='flex_item_fix ml_20'>
							{modificationCampaign}
							<DropDownAction
								campaign={center}
								dropdownIsOppen={actionsDropdownIsOppen}
								setDropdownIsOppen={setActionsDropdownIsOppen}
								getDuplicatedCampaignEditorPath={getDuplicatedCampaignEditorPath}
								getTransformedToNativeAppCampaignEditorPath={getTransformedToNativeAppCampaignEditorPath}
							/>
						</div>
					</div>
				</div>
			}
		</div>
	);
};
