import React from 'react';
import Confirm from '../../../../Components/Confirm';
import Header from '../../UI/Header';
import PannelOptionsList from '../../UI/PannelOptionsList';
import ProductList from '../../UI/ProductList/ProductList';
import { useTaggingContext } from '../context/TaggingContext';
import MessageErrorNoConfiguration from '../../UI/MessageErrorNoConfiguration';

export default function TaggingSettingsContainer() {
	const {
		mode,
		deviceType,
		changeDeviceSelected,
		accountWithoutCategories,
		loadingProductList,
		loadingSaving,
		loadingSettings,
		taggingName,
		taggingObject,
		handleChangeName,
		onAddFilter,
		onEditFilter,
		onRemoveFilter,
		handleChangeOperatorOfFilters,
		productList,
		ListFilter,
		changeCategorySelected,
		categorySelected,
		typeCategories,
		typeCategoriesSecondLevel,
		typeCategoriesSecondLevelSelected,
		changeSecondCategorySelected,
		setupAccountServices,
		accountId,
		checkIfCanQuitWithoutSave,
		cancelActions,
		modalConfirmIsOpen,
		handleCloseConfirm,
		returnToDashboard,
		CreateTaggingRule,
		PutTaggingById,
		listIdProducts,
		handleChangenemOfProductbasedOn,
		inputProductBasedOn,
		handleSelectidOfProduct,
		valueOfSelectedproduct,
		autocompleteListIsLoading,
		handleChangeTenant,
		previewTenant,
		listTenantAccount,
		tenantsCatalog,
		handleChangeTenantPreview,
		listTags,
		valueOfSelectedTag,
		autocompleteTagListIsLoading,
		handleChangenemOfTagbasedOn,
		inputTagBasedOn,
		handleSelectidOfTag,
		paginationOptions,
		currentPageProductList,
		setCurrentPageProductList,
		handleChangePaginationOptions,
		setNewPaginationNeed,
		newPaginationNeed,
		idInClipBoard,
		copyToClipBoard,
		handleChangeTaggingDescription,
		descriptionRule,
		errorNoConfiguration,
		getCheckIfIsAValidUrlFetch
	} = useTaggingContext();

	return (
		<>
			<div className="collection_container page_full flex flex_v">
				<Confirm
					isOpen={modalConfirmIsOpen}
					onClose={(e) => handleCloseConfirm()}
					title="Close rule editor"
					text="Are you sure to close without saving?"
					cancelText="Stay"
					confirmText="Quit without saving"
					confirmCallback={(e) => returnToDashboard()}
				/>
				<Header
					title="Tagging rule"
					subTitle={taggingName}
					returnUrl='/ProductSettings/Tagging'
					valid={mode === 'edition' ? PutTaggingById : CreateTaggingRule}
					checkIfCanQuitWithoutSave={checkIfCanQuitWithoutSave}
					cancelActions={cancelActions}
				/>
				<div className="collection_body flex_item_full flex flex_align_stretch">
					{!errorNoConfiguration && <>
						<PannelOptionsList
							previewTenant={previewTenant}
							handleChangeRankingDescription={handleChangeTaggingDescription}
							descriptionRule={descriptionRule}
							nameInput={taggingName}
							setRuleName={handleChangeName}
							setupAccountServices={setupAccountServices}
							accountId={accountId}
							typeDimensions='tagging'
							data={taggingObject}
							ListFilter={ListFilter}
							onAddFilter={onAddFilter}
							onEditFilter={onEditFilter}
							onRemoveFilter={onRemoveFilter}
							handleChangeTenant={handleChangeTenant}
							tenantsCatalog={tenantsCatalog}
							handleChangeOperatorOfFilters={handleChangeOperatorOfFilters}
							getCheckIfIsAValidUrlFetch={getCheckIfIsAValidUrlFetch}
						/>

						<ProductList
							filterIsEmpty={taggingObject.filtering.filters.length === 0}
							products={productList}
							listTags={listTags}
							isLoading={loadingProductList}
							valueOfSelectedTag={valueOfSelectedTag}
							autocompleteTagListIsLoading={autocompleteTagListIsLoading}
							handleChangenemOfTagbasedOn={handleChangenemOfTagbasedOn}
							inputTagBasedOn={inputTagBasedOn}
							handleSelectidOfTag={handleSelectidOfTag}
							typeDimensions='Tag'
							changeCategorySelected={changeCategorySelected}
							categorySelected={categorySelected}
							typeCategories={typeCategories}
							typeCategoriesSecondLevel={typeCategoriesSecondLevel}
							typeCategoriesSecondLevelSelected={typeCategoriesSecondLevelSelected}
							changeSecondCategorySelected={changeSecondCategorySelected}
							AccountWithoutCategories={accountWithoutCategories}
							autocompleteListIsLoading={autocompleteListIsLoading}
							valueOfSelectedproduct={valueOfSelectedproduct}
							listIdProducts={listIdProducts}
							handleChangenemOfProductbasedOn={handleChangenemOfProductbasedOn}
							inputProductBasedOn={inputProductBasedOn}
							handleSelectidOfProduct={handleSelectidOfProduct}
							deviceType={deviceType}
							changeDeviceSelected={changeDeviceSelected}
							handleChangeTenantPreview={handleChangeTenantPreview}
							previewTenant={previewTenant}
							listTenantAccount={listTenantAccount}
							needToHidePreviewTenants={
								(taggingObject.tenant !== null && tenantsCatalog.length > 1) ||
							listTenantAccount.length === 1}
							tenantsCatalog={tenantsCatalog}
							contextBasedOn={taggingObject.context}
							idInClipBoard={idInClipBoard}
							copyToClipBoard={copyToClipBoard}
							paginationOptions={paginationOptions}
							currentPageProductList={currentPageProductList}
							setCurrentPageProductList={setCurrentPageProductList}
							handleChangePaginationOptions={handleChangePaginationOptions}
							setNewPaginationNeed={setNewPaginationNeed}
						/>
					</>}
					{errorNoConfiguration && 
				<MessageErrorNoConfiguration/>

					}
				</div>
			</div>
		</>
	);
}
