import ProductEMerchServices from '../../../../../Services/ProductEMerchServices';
import ProductInsightsServices from '../../../../../Services/ProductInsightsServices';
import ImpersonatingServices from '../../../../../Services/ImpersonatingServices';

import SystemServices from '../../../../../Services/SystemServices';
import {handlerCatch} from '../../utils';

export function crudKpiInsights($http,$rootScope,$routeParams,AuthServices,setAccountHasNoDatas) {
	const impersonatingServices = new ImpersonatingServices($rootScope,$routeParams );
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;
	const emerchServices = new ProductEMerchServices($http,AuthServices,accountId);
	const insightsServices = new ProductInsightsServices($http,AuthServices,accountId);
	const systemServices = new SystemServices($rootScope);

	type CallbackError = (error: any) => void;

	function postQueryKpiInsights(options:any,paginationOptions,data,callbackSuccess, callbackError,signal) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		emerchServices.getProductListPreview(
			options,
			paginationOptions,
			data,
			(data: any[]) => {
				callbackSuccess(data);
				setAccountHasNoDatas(false);
			},
			(err: any, dataError: any) => {
				console.warn('error', err);
				console.warn('=>(dataError', dataError);
				const is500 = handlerCatch(err);
				if(is500){
					setAccountHasNoDatas(true);
				}else{
					systemServices.showError('An Error occured while fetching insights data');
				}
				callbackError(false);
			}
		);
	}
	function postQueryKpiInsightsServices(options,data,callbackSuccess, callbackError,signal) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		insightsServices.loadInsightsProductsForProductsComparison(
			options,
			data,
			(data: any[]) => {
				callbackSuccess(data);
				setAccountHasNoDatas(false);
			},
			(_err,dataError: CallbackError) => {
				console.log('=>(CrudKpiInsights.tsx:57) dataError', dataError);
				const is500 = handlerCatch(dataError);
				if(is500){
					setAccountHasNoDatas(true);
				}else{
					systemServices.showError('An Error occured while fetching insights data');
				}

				callbackError(false);
			}
		);
	}
	function exportCsv(csvData, fileName) {
		const blob = new Blob([csvData], { type: 'text/csv' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	}
	function postQueryKpiExportInsightsServices(options,data, callbackSuccess, callbackError, signal) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		insightsServices.exportInsightsProductsForProducts(
			options,data,
			(success: any) => {
				exportCsv(success, 'insights.csv');
				callbackSuccess(success);
				setAccountHasNoDatas(false);
			},
			(_err,dataError: CallbackError) => {
				console.log('=>(CrudKpiInsights.tsx:57) dataError', dataError);
				const is500 = handlerCatch(dataError);
				if(is500){
					setAccountHasNoDatas(true);
				}else{
					systemServices.showError('An Error occured while fetching insights data');
				}
				callbackError(false);
			}
		);
	}
	function loadInsightsProductsForAccountComparison(options,callbackSuccess, callbackError,signal) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		insightsServices.loadInsightsProductsForAccountComparison(
			options,
			(data: any[]) => {
				callbackSuccess(data);
				setAccountHasNoDatas(false);
			},
			(err: any, dataError: any) => {
				console.warn('error', err);
				console.warn('=>(dataError', dataError);
				const is500 = handlerCatch(dataError);
				if(is500){
					setAccountHasNoDatas(true);
				}else{
					systemServices.showError('An Error occured while fetching insights data');
				}
				callbackError(false);

			}
		);
	}
	function loadInsightsProductsForAccountS(options,callbackSuccess, callbackError,signal) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		insightsServices.loadInsightsProductsForAccountS(
			options,
			(data: any[]) => {
				callbackSuccess(data);
				setAccountHasNoDatas(false);
			},
			(err: any, dataError: any) => {
				console.warn('error', err);
				console.warn('=>(dataError', dataError);
				const is500 = handlerCatch(dataError);
				if(is500){
					setAccountHasNoDatas(true);
				}else{
					systemServices.showError('An Error occured while fetching insights data');
				}
				callbackError(false);

			}
		);
	}
	function loadBestProgressScoreForProductsCompare(options,callbackSuccess, callbackError,signal) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		insightsServices.loadBest5ProgressScoreForProducts(
			options,
			(data: any[]) => {
				callbackSuccess(data);
				setAccountHasNoDatas(false);
			},
			(err: any, dataError: any) => {
				console.warn('error', err);
				console.warn('=>(dataError', dataError);
				const is500 = handlerCatch(dataError);
				if(is500){
					setAccountHasNoDatas(true);
				}else{
					systemServices.showError('An Error occured while fetching insights data');
				}

				callbackError(false);

			}
		);
	}
	function loadBestScoreForProduct(options,callbackSuccess, callbackError,signal) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		insightsServices.loadBestScoreForProduct(
			options,
			(data: any[]) => {
				callbackSuccess(data);
				setAccountHasNoDatas(false);
			},
			(err: any, dataError: any) => {
				console.warn('error', err);
				console.warn('=>(dataError', dataError);
				const is500 = handlerCatch(dataError);
				if(is500){
					setAccountHasNoDatas(true);
				}else{
					systemServices.showError('An Error occured while fetching insights data');
				}

				callbackError(false);

			}
		);
	}
	function loadWorstScoreForProduct(options,callbackSuccess, callbackError,signal) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		insightsServices.loadWorstScoreForProduct(
			options,
			(data: any[]) => {
				callbackSuccess(data);
				setAccountHasNoDatas(false);
			},
			(err: any, dataError: any) => {
				console.warn('error', err);
				console.warn('=>(dataError', dataError);
				const is500 = handlerCatch(dataError);
				if(is500){
					setAccountHasNoDatas(true);
				}else{
					systemServices.showError('An Error occured while fetching insights data');
				}

				callbackError(false);

			}
		);
	}


	return {
		postQueryKpiInsights,
		postQueryKpiInsightsServices,
		postQueryKpiExportInsightsServices,
		loadInsightsProductsForAccountComparison,
		loadInsightsProductsForAccountS,
		loadBestProgressScoreForProductsCompare,
		loadBestScoreForProduct,
		loadWorstScoreForProduct
	};
}
