import React from 'react';
import Collapse from '../../../../Components/Collapse';
import DraggableContainer from './DraggableContainer';
import PaginationProductList from './PaginationProductList';
import { useRankingContext } from '../../Ranking/context/RankingContextProvider';
import { Article } from '../../../../Components/Article/Article';

export default function DemotedProducts({
	typeDimensions,
	screenTypeSelected,
}) {

	const {
		demotedProduct,
		changeOrderOfDemotedProduct,
		paginationOptions,
		handleChangePaginationOptions,
		currentPageProductList,
		setCurrentPageProductList,
		setNewPaginationNeed,
		categorySelected,
		typeCategoriesSecondLevelSelected,
	} = useRankingContext();

	if (!demotedProduct.pageItems || demotedProduct.pageItems.length === 0) {
		return <></>;
	}

	const inner = (
		<>
			<DraggableContainer
				dimension="demoted"
				type={screenTypeSelected}
				elementToMap={demotedProduct.pageItems ? demotedProduct.pageItems : []}
				handleOnDragEnd={changeOrderOfDemotedProduct}
			/>
			<PaginationProductList
				categorySelected={categorySelected}
				typeCategoriesSecondLevelSelected={typeCategoriesSecondLevelSelected}
				totalCount={demotedProduct.totalCount || 0}
				pageSize={40}
				paginate={(e) => {
					handleChangePaginationOptions('dpoffset', e >= 0 ? e : 0);
					setNewPaginationNeed(true);
				}}
				skipIdNumber={paginationOptions.dpoffset}
				currentPage={currentPageProductList}
				setCurrentPage={(e) => setCurrentPageProductList(e)}
			/>
		</>
	);

	return (
		<Collapse label="Demoted Product" defaultOpen={true}>
			{screenTypeSelected === 'grid' ?
				<>{inner}</>
				:
				<Article>
					{inner}
				</Article>
			}
		</Collapse>
	);
}
