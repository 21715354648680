import { useEffect } from 'react';
import { Chip,DataStructure } from '../utils';
export const useChipManagement = (filterSelected, setChipsByFilterSelected) => {
	useEffect(() => {
		const newArrayOfFilterChips: Chip[] = [];
		filterSelected.map((filter: DataStructure) => {
			if (filter.value.length > 1) {
				newArrayOfFilterChips.push({
					type: filter.key,
					title: filter.value[0].name + ' +' + (filter.value.length - 1),
					handleDelete: () => { }
				});
			} else {
				newArrayOfFilterChips.push({
					type: filter.key,
					title: filter.value[0].name,
					handleDelete: () => { }
				});
			}
		});
		setChipsByFilterSelected(newArrayOfFilterChips);
	}, [filterSelected]);
};
