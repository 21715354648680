import React, { useEffect, useState } from 'react';
import ChangeFilterStatusMode from './ChangeFilterStatusMode';
import Dropdown from '../../../../Components/Dropdown';
import Listbox from '../../../../Components/Listbox';
import ListboxItem from '../../../../Components/ListboxItem';
import { onSiteContext } from '../context/index';
import { useTranslation } from 'react-i18next';

export default function StatusFilterSelector({
	setFilterStatusMode,
	filterTestMode,
	setFilterTestMode,
	statusArray,
	setstatusArray
}) {
	const [t] = useTranslation('common');
	const [isOpen, setIsOpen] = useState(false);
	const [selectedFinalStatusLabel, setSelectedFinalStatusLabel] = useState([]);
	const [unselectedFinalStatusLabel, setUnselectedFinalStatusLabel] = useState([]);
	const [selectedFinalStatusNb, setSelectedFinalStatusNb] = useState();
	const btnClass = ['custom_input', 'clickable', 'w_auto', 'm', 'bg_white'];
	const allStatusInfo = [
		{
			id: 'draft',
			label: t('filterListActions.Draft')
		},
		{
			id: 'onGoing',
			label: t('filterListActions.Ongoing')
		},
		{
			id: 'scheduled',
			label: t('filterListActions.Scheduled')
		},
		{
			id: 'stopped',
			label: t('filterListActions.Stopped')
		},
		{
			id: 'past',
			label: t('filterListActions.Past')
		}
	];

	const {
		setisSimpleModeFilterStatus,
	} = onSiteContext();

	useEffect(() => {
		const testStatusMode = ChangeFilterStatusMode(statusArray);
		let status = testStatusMode && testStatusMode.status;
		if (testStatusMode && testStatusMode.mode === 1) {
			setisSimpleModeFilterStatus(true);
		} else {
			setisSimpleModeFilterStatus(false);
		}
		if (!status) {
			setisSimpleModeFilterStatus(false);
		}
		setFilterStatusMode(status ? [status] : []);
	}, []);

	useEffect(() => {
		const finalStatus = [...statusArray];
		if (filterTestMode) finalStatus.push('draft');

		const selected = allStatusInfo.filter((s) => finalStatus.includes(s.id));
		setSelectedFinalStatusLabel(selected.map(s => s.label));
		setSelectedFinalStatusNb(finalStatus.length);

		const unSelected = allStatusInfo.filter((s) => !finalStatus.includes(s.id));
		setUnselectedFinalStatusLabel(unSelected.map(s => s.label));
	}, [statusArray, filterTestMode]);

	const canRemoveFilter = (type, status) => {
		let filter_lenght = statusArray.length;

		if (filterTestMode) {
			filter_lenght++;
		}

		if (filter_lenght > 1) {
			return true;
		}

		if (type == 'test') {
			return !filterTestMode;
		}

		return !statusArray.includes(status);
	};

	const statusIsSelected = (s) => {
		if (s === 'draft') return filterTestMode == true;
		return statusArray.includes(s);
	};

	const updateTestMode = () => {
		if (!canRemoveFilter('test')) return;

		const newState = !filterTestMode;
		setFilterTestMode(newState);
		sessionStorage.setItem('onSiteTestFilter', newState);
	};

	const updateStatusArray = (filter) => {
		if (filter === 'draft') {
			updateTestMode();
			return;
		}

		if (!canRemoveFilter('status', filter)) return;
		let actualStatusArray = [...statusArray];
		if (actualStatusArray.includes(filter)) {
			actualStatusArray = actualStatusArray.filter(status => status !== filter);
			setstatusArray(actualStatusArray);
			const testStatusMode = ChangeFilterStatusMode(actualStatusArray);
			let status = testStatusMode && testStatusMode.status;
			if (testStatusMode && testStatusMode.mode === 1) {
				setisSimpleModeFilterStatus(true);
			} else {
				setisSimpleModeFilterStatus(false);
			}
			if (!status) {
				setisSimpleModeFilterStatus(false);
			}
			setFilterStatusMode(status ? [status] : []);
			sessionStorage.setItem('onSiteStatusFilter', JSON.stringify(actualStatusArray));
		} else {
			actualStatusArray.push(filter);
			setstatusArray(actualStatusArray);
			const testStatusMode = ChangeFilterStatusMode(actualStatusArray);
			let status = testStatusMode && testStatusMode.status;
			if (testStatusMode && testStatusMode.mode === 1) {
				setisSimpleModeFilterStatus(true);
			} else {
				setisSimpleModeFilterStatus(false);
			}
			if (!status) {
				setisSimpleModeFilterStatus(false);
			}
			setFilterStatusMode(status ? [status] : []);
			sessionStorage.setItem('onSiteStatusFilter', JSON.stringify(actualStatusArray));
		}
	};
	return (
		<>
			<Dropdown
				isOpen={isOpen}
				toggle={(s) => setIsOpen(s)}
				scrollbar={true}
				button={
					<button className={btnClass.join(' ')}>
						<span className="custom_input_value has_label">
							<span className="custom_input_value_label">{t('common.status')}</span>
							{selectedFinalStatusNb === allStatusInfo.length &&
								<>{t('common.all')}</>
							}
							{selectedFinalStatusNb === allStatusInfo.length - 1 &&
								<>{t('common.allExcept', {content: unselectedFinalStatusLabel.join(', ')})}</>
							}
							{selectedFinalStatusNb < allStatusInfo.length - 1 &&
								<>{selectedFinalStatusLabel.join(', ')}</>
							}
						</span>
						<span className="custom_input_arrow"></span>
					</button>
				}
			>
				<Listbox>
					{allStatusInfo.map(s =>
						<ListboxItem
							key={s.id}
							onClick={() => updateStatusArray(s.id)}
							selected={statusIsSelected(s.id)}
							hasCheckbox={true}
							message={s.label}
						/>
					)}
				</Listbox>
			</Dropdown>
		</>
	);
}
