import React, {useState, useEffect} from 'react';
import { react2angular } from 'react2angular';
import Btn from '../../Components/Btn';
import {Checkbox, CheckboxList} from '../../Components/Checkbox/Checkbox';
import {listOfKpis} from './Utils';
import SystemServices from '../../Services/SystemServices';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import InsightsServices from '../../Services/InsightsServices';
import { Article } from '../../Components/Article/Article';
import { Section } from '../../Components/Section/Section';

export default function ConfigurationDashboardInsights(props) {
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatingServices = new ImpersonatingServices(
		props.$rootScope,
		props.$routeParams
	);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const insightsServices = new InsightsServices(
		props.$http,
		props.$routeParams
	);
	const [userConfiguration, setuserConfiguration] = useState<string[]>([]);

	const [newUserConfig, setnewUserConfig] = useState<string[]>([]);
	function submitChanges ( ) {
		const modules = [
			{
				'dashboardModuleId': 'campaigns',
				'isActive': false
			},
			{
				'dashboardModuleId': 'ecommerce',
				'isActive': false
			},
			{
				'dashboardModuleId': 'global',
				'isActive': false
			},
			{
				'dashboardModuleId': 'products',
				'isActive': false
			},
			{
				'dashboardModuleId': 'turnover',
				'isActive': false
			},
			{
				'dashboardModuleId': 'aiinsights',
				'isActive': false
			},
		];
		const newConfigModule = modules.map(el => {
			const isActive = newUserConfig.find(element => element === el.dashboardModuleId);
			return {...el, isActive : isActive ? true : false  };
		});
		insightsServices.putInsightsActivated(accountId,newConfigModule,()=> {

			systemServices.showSuccess('The changes have been saved correctly');
			sessionStorage.removeItem('insightsdashboard');

		}, () => {
			systemServices.showError('An error occurred while trying to get data');
		});
    
	}
	function isActive(el:string){
		return newUserConfig.includes(el);
	}
	function handleChangeChangeDashboard(el){
		const newArray = [...newUserConfig];
		if(!newArray.includes(el)){
			setnewUserConfig([...newArray, el]);
		}else{
			setnewUserConfig(newArray.filter(elem => elem !== el));
		}
	}
	useEffect(()=>{
		if(userConfiguration){
			setnewUserConfig(userConfiguration);
		}
	},[userConfiguration]);
	useEffect(()=>{
		insightsServices.getInsightsActivated(accountId,(success)=> {
			setuserConfiguration(success);		
		}, () => {
			setuserConfiguration([]);	
			systemServices.showError('An error occurred while trying to get data');
		});
	},[]);
	return (
		<Section width='xs'>
			<div className='h2'>Select active insights sections</div>
			<Article innerSize='l'>
				<CheckboxList size="s" hasBorder={false}>
					{listOfKpis.map(elem =>
						<Checkbox
							key={elem.id}
							forcedChecked={isActive(elem.id)}
							onChange={() => handleChangeChangeDashboard(elem.id)}
							label={elem.label}
						/>
					)}
				</CheckboxList>
				<div className="mt_20">
					<Btn color="primary" onClick={() => submitChanges()}>
						Save
					</Btn>
				</div>
			</Article>
		</Section>
	);
}
// @ts-ignore
angular
	.module('beyableSaasApp.Insights', [])
	.component('insights', react2angular(ConfigurationDashboardInsights, [], ['$http', '$rootScope', '$timeout', '$routeParams', 'AuthServices', '$scope']));
