export const getCSS = (type) => {
	const reset = `
html {
	height: 100%;
}
body {
	padding: 0;
	margin: 0;
	font-family: 'BY Inter', sans-serif;
}
	`;

	const css = `
html {
	height: 100%;
}
body {
	height: 100%;
	position: relative;
}
.mockup_wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: 16px;
	box-sizing: border-box;
}
.mockup_wrapper_inpage {
	padding-top: 20px;
	padding-bottom: 20px;
}
.mockup_wrapper_overlay {
	padding-top: 20px;
	padding-bottom: 20px;
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
.mockup_row {
	display: flex;
	align-items: stretch;
	gap: 16px;
	margin-left: 20px;
	margin-right: 20px;
}
.mockup_row_no_margin {
	margin-left: 0;
	margin-right: 0;
}
.mockup_row_full {
	flex: 1 1 auto;
}
.mockup_row_fix {
	flex: 0 0 auto;
}
.mockup_col {
	background-color: #FFF;
	border-radius: 4px;
}
.mockup_col_full {
	flex: 1 1 auto;
	min-width: 30px;
}
.mockup_col_fix {
	flex: 0 1 auto;
    min-width: 0;
}
.mockup_col_quarter {
	width: 25%;
}
.mockup_col_third {
	width: 33.33%;
}
.mockup_col_half {
	width: 50%;
}
    `;

	if (type === '') return reset;

	return reset + css;
};
