
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Btn from '../../../Components/Btn';

export default function NotificationCreationButton({
	isAdmin,
	$routeParams,
	permissionsAccount
}) {

	const [t] = useTranslation(['common']);
	const createNotifCampaigns = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'NOTIFICATIONCENTER_CAN_CREATE');
	const canCreateNotificationCampaigns = createNotifCampaigns && createNotifCampaigns.Value == true;

	const button = React.forwardRef(({ navigate, ...props }, ref) => {
		return (
			<Btn
				ref={ref}
				{...props}
				htmlTag='a'
				message={t('nav.newNotificationCenterNotification')}
				horizontalSize="l"
			/>
		);
	});

	if (!canCreateNotificationCampaigns) return <></>;

	return (
		<>
			<Link
				to={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?notificationCenterNotification&from=NotificationCenter/Dashboard&ka=${$routeParams.ka}&ku=${$routeParams.ku}` : '/Campaigns/Editor?notificationCenterNotification&from=NotificationCenter/Dashboard'}
				component={button}
			/>
		</>
	);
}