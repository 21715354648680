import React, {useState,useEffect} from 'react';
import CreateTriggerContextProvider from '../../Triggers_React/TriggerContext';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import SystemServices from '../../../Services/SystemServices';
import TargetingRulesContainer from '../../Triggers_React/TargetingRulesContainer';
import setUpSpecifiations from './Services/setUpSpecifiations';

export default function TriggerGroupEditorContainer({ $http, $routeParams, $rootScope, UtilCampaignsServices, triggersChangedCallback,initialTriggers }) {

	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const systemServices = new SystemServices($rootScope);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	//const isAdmin = impersonatingServices.isAnAdmin();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;
	const [triggersToPush, setTriggersToPush] = useState({ isInitialized: false, triggers: [], shouldLoadDefault: false });

	const mode = !$routeParams.id ? 'creating' : 'edition';

	const { setUpSpecificationsForLoading, setUpSpecificationsForSaving, referenceDataTriggerOperands }  = setUpSpecifiations(UtilCampaignsServices);
	
	useEffect(() => {
		if (initialTriggers && !triggersToPush.isInitialized) {
			const triggers = setUpSpecificationsForLoading(initialTriggers.triggerGroups);
			setTriggersToPush({ isInitialized: true, triggers: triggers, shouldLoadDefault: false });
		}
	}, [initialTriggers]);


	if (!triggersToPush.isInitialized) return <></>;

	return (
		<CreateTriggerContextProvider
			accountId={accountId}
			$http={$http}
			initialTriggers={triggersToPush.triggers}
			mode={mode}
			canSwitchToNoTracking={false}
			impersonatingServices={impersonatingServices}
			saveChangeOnTrigger={triggersChangedCallback}
			setUpSpecificationsForSaving={setUpSpecificationsForSaving}
			referenceDataTriggerOperands={referenceDataTriggerOperands}
			trackingMode={2}
			handleTrackingModeChange={(e) => { console.log(e); }}
			isModuleEditorMode={false}
			isAbTestMode={false}
			getModuleFormats={(callbackSuccess) => callbackSuccess([])}
			isNativeApp={false}
			$routeParams={$routeParams}
			useModuleOnCreation={false}
		>
			<TargetingRulesContainer
				isABTestingActive={false}
				systemServices={systemServices}
				needWrapper={true}
			/>
		</CreateTriggerContextProvider>
	);
}
