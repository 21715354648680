import HttpServices from './HttpServices';

export default class CatalogueServices {
	HttpServices: HttpServices;
	BO_API_URL: string;
	accountId: string;

	constructor(accountId: string, $http: any) {
		this.HttpServices = new HttpServices($http);
		if (!window || !(window as any).BEYABLE_env || !(window as any).BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		} else {
			this.BO_API_URL = `${(window as any).BEYABLE_env.BO_API_URL}`;
		}
		this.accountId = accountId;
	}

	getAvailableFormats(module, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}displaylibrary/bespoketemplates/${this.accountId}?editor=targetedMessage/module/${module}`, callbackSuccess, callbackError);
	}

	getAvailableFormatsLibrary(callbackSuccess, callbackError, signal) {
		this.HttpServices.get(`${this.BO_API_URL}displaylibrary/bespoketemplates/${this.accountId}?editor=targetedMessage/library`, callbackSuccess, callbackError, signal);
	}

	getFormatSavedStyles(templateId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}displaylibrary/templatestyle/${this.accountId}/template/${templateId}`, callbackSuccess, callbackError);
	}

	getOneModuleTemplates(moduleId, callbackSuccess, callbackError, signal) {
		this.HttpServices.get(`${this.BO_API_URL}displaylibrary/module/${this.accountId}/${moduleId}`, callbackSuccess, callbackError, signal);
	}

	getModuleTemplates(callbackSuccess, callbackError, signal) {
		this.HttpServices.get(`${this.BO_API_URL}displaylibrary/module/${this.accountId}`, callbackSuccess, callbackError, signal);
	}
}