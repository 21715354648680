import React, { createContext, useState, useContext, useEffect } from 'react';
import { crudSpecificProduct } from '../ProductInsights/ProductSpecific/api/CrudSpecificProduct';
import { Product } from '../ProductInsights/Kpi/TypeKpi';
import {Chip, DataStructure, SESSION_STORAGE_KEY, transformToDataStructureArray,getInitialSelectedValues} from '../ProductInsights/utils';
import {useThrottle} from '../../.././Hooks/useThrottle';
import {useChipManagement} from '../ProductInsights/Chip/chipManagement';

export const recommendations = [
	{ key: 'RECOMMENDATION_SIMPLE_01', label: 'Top product !' },
	{ key: 'RECOMMENDATION_SIMPLE_02',  label: 'Improve product page' },
	{ key: 'RECOMMENDATION_SIMPLE_03',  label: 'Increase visibility' },
	{ key: 'RECOMMENDATION_SIMPLE_04',  label: 'Poor visibility' },
];
type Tip =  'RECOMMENDATION_SIMPLE_01' | 'RECOMMENDATION_SIMPLE_02' | 'RECOMMENDATION_SIMPLE_03' | 'RECOMMENDATION_SIMPLE_04';
type ProductInsightsContextType = {
	isLoading: boolean;
	isFirstLoading: boolean;
	$location: any;
	insightsKpiPaylod: InsightsKpiPaylod;
	changeDeviceSelected: (s: Devices) => void;
	deviceType: Devices;
	productsSelections: Product[];
	totalCount: number;
	newPaginationNeed: boolean;
	currentPageProductList: number;
	setNewPaginationNeed: React.Dispatch<React.SetStateAction<boolean>>;
	paginationOptions: PaginationOptions;
	setCurrentPageProductList: React.Dispatch<React.SetStateAction<number>>;
	handleChangePaginationOptions: (arg1: string, arg2: string | number) => void;
	valueInSearchBar:string;
	handleChange:(st:string)=>void;
	fetchExportKpis:()=>void;
	isExporting: boolean;
	ComparisonSelected:boolean;
	handleSelectComparaisonPeriodHandler: (b:boolean)=>void;
	onChangeNavFilter:(o:any)=>void;
	initialDates:any;
	dateStringToTimestamp:(o:any)=>void;
	navFilter:any;
	setNavFilter:React.Dispatch<React.SetStateAction<any>>;
	setisCustomSelected:React.Dispatch<React.SetStateAction<any>>;
	isCustomSelected:boolean;
	customDateRange:any;
	setCustomDateRange:React.Dispatch<React.SetStateAction<any>>;
	updateStorage:any;
	handleChangeFormData: (data:any)=>void;
	handleOpenClosePanelForm: (evt:boolean)=>void;
	panelFormIsOpen:boolean;
	filterHierarchies:any;
	selectedValues:any;
	setSelectedValues:React.Dispatch<React.SetStateAction<any>>;
	chipsByFilterSelected:Chip[];
	setFilterSelected:React.Dispatch<React.SetStateAction<DataStructure[]>>;
	accountHasNoDatas: boolean;
	tipSelected: Tip;
	setTipSelected: React.Dispatch<React.SetStateAction<Tip>>;
};

type PaginationOptions = {
  poffset: number;
  plimit: number;
  directions:string;
  orderBy:string;
  rowsperpage: number;
  totalRows:number;
};

type filter = {
  op: string;
  filters: Array<any>;
};

type Devices = 'Mobile' | 'Desktop' | 'Tablet';

type InsightsKpiPaylod = {
  context: string;
  sorting: Array<any>;
  tenant: null;
  filtering: filter;
  groupSorting: null;
  transformations: Array<any>;
  name: string;
};
interface ProductSpecificProviderProps {
  $http: any;
  children?: React.ReactNode;
  $rootScope: any;
  $routeParams: any;
  $timeout: any;
  $location: any;
  AuthServices: any;
	ComparisonSelected:boolean;
	handleSelectComparaisonPeriodHandler: (b:boolean)=>void;
	onChangeNavFilter:(o:any)=>void;
	initialDates:any;
	dateStringToTimestamp:(o:any)=>void;
	navFilter:any;
	setNavFilter:React.Dispatch<React.SetStateAction<any>>;
	setisCustomSelected:React.Dispatch<React.SetStateAction<any>>;
	isCustomSelected:boolean;
	customDateRange:any;
	setCustomDateRange:React.Dispatch<React.SetStateAction<any>>;
	updateStorage:any
	selectedValues:any;
	setSelectedValues:React.Dispatch<React.SetStateAction<any>>;
	accountHasNoDatas: boolean;
	setAccountHasNoDatas:React.Dispatch<React.SetStateAction<boolean>>;

}

const ProductSpecificContext = createContext<ProductInsightsContextType | undefined>(undefined);

// Custom hook to use the AiInsightsContext
export const useProductSpecificContext = () => {
	const contextValue = useContext(ProductSpecificContext);
	if (contextValue === undefined) {
		throw new Error(' must be used within an insights element');
	}
	return contextValue;
};
export const ProductSpecificContextProvider: React.FC<ProductSpecificProviderProps> = ({
	children,
	$location,
	$rootScope,
	$routeParams,
	AuthServices,
	$http,
	ComparisonSelected,
	handleSelectComparaisonPeriodHandler,
	onChangeNavFilter,
	initialDates,
	dateStringToTimestamp,
	navFilter,
	setNavFilter,
	isCustomSelected,
	setisCustomSelected,
	customDateRange,
	setCustomDateRange,
	updateStorage,
	selectedValues,
	setSelectedValues,
	accountHasNoDatas,
	setAccountHasNoDatas,

}) => {
	const [insightsKpiPaylod] = useState({
		context: 'Global',
		name: 'empty',
		sorting: [],
		tenant: null,
		filtering: {
			op: 'And',
			filters: [],
		},
		groupSorting: null,
		transformations: [],
	});

	const [deviceType, setDeviceType] = useState<Devices>('Desktop');

	const {
		postQuerySpeficifProductExportInsightsServices,
		loadInsightsProductsForProductsOutliers
	} = crudSpecificProduct($http, $rootScope, $routeParams, AuthServices,setAccountHasNoDatas);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
	const productPerPage = 10;
	const [paginationOptions, setpaginationOptions] = useState<PaginationOptions>({
		poffset: 1,
		plimit: productPerPage,
		directions: 'desc',
		orderBy: 'surconversions',
		rowsperpage: productPerPage,
		totalRows: 0,
	});

	const [currentPageProductList, setCurrentPageProductList] = useState(1);

	const [productsSelections, setProductsSelections] = useState<Product[]>([]);
	const [newPaginationNeed, setNewPaginationNeed] = useState(true);
	const [totalCount, setTotalCount] = useState(0);

	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [panelFormIsOpen, setPanelFormIsOpen] = useState(false);
	const [filterHierarchies, setFilterHierarchies] = useState([]);
	const [filterSelected, setFilterSelected] = useState<DataStructure[]>([]);
	const [chipsByFilterSelected, setChipsByFilterSelected] = useState<Chip[]>([]);
	const [isFilterSelectedInitialized, setIsFilterSelectedInitialized] = useState(false);
	const [tipSelected, setTipSelected] = useState<Tip>('RECOMMENDATION_SIMPLE_01');
	const [isExporting, setIsExporting] = useState(false);
	const throttledValueInSearchBar = useThrottle(valueInSearchBar, 800);
	useChipManagement(filterSelected, setChipsByFilterSelected);

	function handleOpenClosePanelForm(evt:boolean){
		setPanelFormIsOpen(evt);
	}
	function handleChangeFormData(data:any){
		sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(selectedValues));
		setFilterSelected(data);
		setPanelFormIsOpen(false);
	}
	function handleChange(evt:string){
		setValueInSearchBar(evt);
	}
	function changeDeviceSelected(type:Devices):void{
		setDeviceType(type);
	}
	const handleChangePaginationOptions = (elemName: string , elemValue: string |number) => {
		if (elemName === 'orderBy') {
			if (elemValue === paginationOptions.orderBy) {
				setpaginationOptions((pagination) => ({
					...pagination,
					directions: pagination.directions === 'desc' ? 'asc' : 'desc',
				}));
			} else {
				// @ts-ignore
				setpaginationOptions((pagination) => ({
					...pagination,
					orderBy: elemValue,
					directions: 'desc',
				}));
			}
		} else {
			setpaginationOptions((pagination) => ({
				...pagination,
				[elemName]: elemValue,
			}));
		}
		setNewPaginationNeed(true);
	};


	const fetchExportKpis = () => {
		setIsExporting(true);
		const date1 = dateStringToTimestamp(navFilter.fromDate);
		const date2 =dateStringToTimestamp(navFilter.toDate);
		const optionsObject = {
			startDate1: date1,
			endDate1: date2,
			endDate2:'',
			startDate2: '',
			rowsperpage :paginationOptions.plimit,
			orderby: paginationOptions.orderBy,
			direction:paginationOptions.directions,
			pattern: valueInSearchBar,
		};
		const controller = new AbortController();
		const signal = controller.signal;
		postQuerySpeficifProductExportInsightsServices(
			optionsObject ,
			_data => {
				setIsExporting(false);
				return;
			},
			err => {
				setIsExporting(false);
				console.error('Error fetching data:', err);
			},
			signal,
		);

	};
	const fetchData = (blocNb,filters) => {
		const date1 =  dateStringToTimestamp(navFilter.fromDate);
		const date2 =dateStringToTimestamp(navFilter.toDate);
		const optionsObject = {
			startDate1: date1,
			endDate1: date2,
			endDate2:'',
			startDate2: '',
			rowsperpage :paginationOptions.plimit,
			blocnumber: blocNb !== null ? blocNb : currentPageProductList,
			orderby: paginationOptions.orderBy,
			direction:paginationOptions.directions,
			pattern: valueInSearchBar,
			recommendation:tipSelected
		};
		const controller = new AbortController();
		const signal = controller.signal;
		setIsLoading(true);
		loadInsightsProductsForProductsOutliers(
			optionsObject ,filters,
			data => {
				setProductsSelections(data.Items);
				setCurrentPageProductList(data.Pagination.BlocNumber);
				setTotalCount(data.Pagination.TotalRows);
				setIsLoading(false);
				setIsFirstLoading(false);
				setNewPaginationNeed(false);
				const breadcrumb = [];
				const mainHierarchy = [];

				data.Categories.forEach((category:any )=> {
					// @ts-ignore
					if (!mainHierarchy.includes(category.Type) && category.Type !== 'root') {
						// @ts-ignore
						mainHierarchy.push(category.Type);
					}
				});

				mainHierarchy.forEach(type => {
					const hierarchy = {
						name: type,
						categories: data.Categories
							.filter(category => category.Type === type)
							.map(category => ({
								name: category.Name,
								value: category.Value
							}))
					};
					// @ts-ignore
					breadcrumb.push(hierarchy);
				});
				setFilterHierarchies(breadcrumb);
				setpaginationOptions({
					poffset: data.Pagination.BlocNumber,
					plimit: productPerPage,
					directions:	data.Pagination.Direction,
					orderBy	:	data.Pagination.OrderBy,
					rowsperpage: data.Pagination.RowsPerPage,
					totalRows:data.Pagination.TotalRows,
				});
			},
			err => {
				setIsLoading(false);
				setIsFirstLoading(false);
				console.error('Error fetching data:', err);
				setNewPaginationNeed(false);
			},
			signal,
		);

		return () => {
			controller.abort();
		};
	};


	useEffect(() => {
		if (newPaginationNeed && isFilterSelectedInitialized && !accountHasNoDatas) {
			const cleanup = fetchData(null,filterSelected);
			return cleanup();
		}
	}, [newPaginationNeed,filterSelected,isFilterSelectedInitialized,accountHasNoDatas]);

	useEffect(() => {
		if (isFilterSelectedInitialized && !accountHasNoDatas) {
			setIsLoading(true);
			if (throttledValueInSearchBar.length > 0) {
				fetchData(1, filterSelected);
			} else if (throttledValueInSearchBar.length === 0 && !newPaginationNeed) {
				fetchData(1, filterSelected);
			}
		}
	}, [throttledValueInSearchBar, navFilter, ComparisonSelected, filterSelected,isFilterSelectedInitialized,tipSelected,accountHasNoDatas]);
	useEffect(() => {
		const  hasData = getInitialSelectedValues();

		if(hasData) {
			const arrayOfSelectedFilters = transformToDataStructureArray(hasData);
			setFilterSelected(arrayOfSelectedFilters);
			setIsFilterSelectedInitialized(true);
		}
	}, []);
	useEffect(() => {
		const newArrayOfFilterChips:Chip[] = [];
		filterSelected.map((filter:DataStructure) => {
			if(filter.value.length > 1){
				newArrayOfFilterChips.push({
					type: filter.key,
					title:filter.value[0].name + ' +' + (filter.value.length - 1),
					handleDelete: ()=> {}
				});
			}else{
				newArrayOfFilterChips.push({
					type: filter.key,
					title:filter.value[0].name,
					handleDelete: ()=> {}
				});
			}
		});
		setChipsByFilterSelected(newArrayOfFilterChips);

	}, [ filterSelected]);
	return (
		<ProductSpecificContext.Provider
			value={{
				accountHasNoDatas,
				isLoading,
				isFirstLoading,
				$location,
				changeDeviceSelected,
				deviceType,
				insightsKpiPaylod,
				productsSelections,
				newPaginationNeed,
				setNewPaginationNeed,
				totalCount,
				handleChangePaginationOptions,
				currentPageProductList,
				setCurrentPageProductList,
				paginationOptions,
				valueInSearchBar,
				handleChange,
				fetchExportKpis,
				isExporting,
				ComparisonSelected,
				handleSelectComparaisonPeriodHandler,
				onChangeNavFilter,
				initialDates,
				dateStringToTimestamp,
				navFilter,
				setNavFilter,
				isCustomSelected,
				setisCustomSelected,
				customDateRange,
				setCustomDateRange,
				updateStorage,
				handleChangeFormData,
				handleOpenClosePanelForm,
				panelFormIsOpen,
				filterHierarchies,
				selectedValues,
				setSelectedValues,
				chipsByFilterSelected,
				setFilterSelected,
				tipSelected,
				setTipSelected
			}}
		>
			{children}
		</ProductSpecificContext.Provider>
	);
};


export default ProductSpecificContextProvider;
