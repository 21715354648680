import React, { useEffect, useState } from 'react';
import Btn from '../../../../Components/Btn';

const PaginationProduct = ({
	totalCount,
	pageSize,
	paginate,
	currentPage,
	setCurrentPage,
}) => {
	const [paginateToDisplay, setPaginateToDisplay] = useState([]);
	const [totalPages, setTotalPages] = useState(0);

	useEffect(() => {
		const calculateTotalPages = () => Math.ceil(totalCount / pageSize);
		setTotalPages(calculateTotalPages());
	}, [totalCount, pageSize]);

	useEffect(() => {
		const generatePageNumbers = () => {
			const pageNumbersArray = [];
			let idx = 1;
			for (let i = 1; i <= totalPages; i++) {
				// @ts-ignore
				pageNumbersArray.push({ nb: i, idx });
				idx += pageSize;
			}
			return pageNumbersArray;
		};

		const pageNumbersArray = generatePageNumbers();
		const paginationSize = pageNumbersArray.length;

		if (paginationSize <= 5) {
			setPaginateToDisplay(pageNumbersArray);
			return;
		}

		if (currentPage < 3) {
			setPaginateToDisplay(pageNumbersArray.slice(0, 5));
			return;
		}

		if (currentPage > paginationSize - 3) {
			setPaginateToDisplay(pageNumbersArray.slice(paginationSize - 5, paginationSize));
			return;
		}

		setPaginateToDisplay(pageNumbersArray.slice(currentPage - 3, currentPage + 2));
	}, [totalPages, pageSize, currentPage]);

	if (totalCount <= pageSize) {
		return null;
	}

	return (
		<nav className="flex flex_justify_center collection_pagin">
			<div className="flex_item_fix">
				<div className="btn_group">
					<Btn
						icon="fas fa-chevron-left"
						onClick={() => {
							paginate((currentPage - 2) * pageSize);
							setCurrentPage(currentPage - 1);
						}}
						style="ghost"
						color="secondary"
						size="s"
						className="btn_w_xs"
						disabled={currentPage === 1}
					/>
					<div className="btn_switch">
						{currentPage > 3 && (
							<>
								<Btn
									onClick={() => {
										setCurrentPage(1);
										paginate(0);
									}}
									style="outline"
									color="secondary"
									size="s"
									message="1"
								/>
								<Btn
									style="outline"
									color="secondary"
									size="s"
									message="..."
								/>
							</>
						)}
						{paginateToDisplay.map((number:any) => (
							<Btn
								key={number.nb}
								onClick={() => {
									paginate((number.nb - 1) * pageSize);
									setCurrentPage(number.nb);
								}}
								style="outline"
								color="secondary"
								size="s"
								message={number.nb}
								className={currentPage === number.nb ? 'active' : ''}
							/>
						))}
						{currentPage < totalPages - 2 && (
							<>
								<Btn
									style="outline"
									color="secondary"
									size="s"
									message="..."
								/>
								<Btn
									onClick={() => {
										setCurrentPage(totalPages);
										paginate((totalPages - 1) * pageSize);
									}}
									style="outline"
									color="secondary"
									size="s"
									message={totalPages}
								/>
							</>
						)}
					</div>
					<Btn
						icon="fas fa-chevron-right"
						onClick={() => {
							paginate(currentPage * pageSize);
							setCurrentPage(currentPage + 1);
						}}
						style="ghost"
						color="secondary"
						size="s"
						className="btn_w_xs"
						disabled={currentPage === totalPages}
					/>
				</div>
			</div>
		</nav>
	);
};


export default PaginationProduct;
