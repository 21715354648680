import React from 'react';
import SectionMessage from '../../../../../../Components/SectionMessage';

export const WarningDataInfo = ({context}:any) => {
	const {
		detectedErrors
	} = context;

	if (!detectedErrors || detectedErrors.length === 0) {
		return <></>;
	}
	return (
		<SectionMessage
			type="warning"
			title={'The values of the filters above are incorrect.'}
			text={
				<>
					The data below may not accurately reflect your filters,
					particularly for the {detectedErrors?.map((warn, i) => (
						<span key={i}>
							{warn.key}
							{i < detectedErrors.length - 2 ? ', ' : i === detectedErrors.length - 2 ? ' and ' : ''}
						</span>
					))}.
					Please review and correct them.
				</>
			}
		/>
	);
};
