import React, {ReactElement} from 'react';
import angular from 'angular';
import { react2angular } from 'react2angular';
import EngagementFunnelContextProvider from '../../context/ContextEngagementFunnel';

import {EngagementContainer} from './EngagementContainer';
type Props = {
	$http: any
	$rootScope: any
	$routeParams:any
	AuthServices:any
	$location:any
	$timeout:any
	children: React.ReactNode
}
export const EngagementFunnelContainer = (props:Props):ReactElement => {


	if(location.pathname !== '/Insight/engagement'){
		return <></>;
	}

	return (
		<EngagementFunnelContextProvider
			$http={props.$http}
			$routeParams={props.$routeParams}
			AuthServices={props.AuthServices}
			$timeout={props.$timeout}
			$location={props.$location}
			$rootScope={props.$rootScope}
		>
			<EngagementContainer />
		</EngagementFunnelContextProvider>
	);
};
angular.module('beyableSaasApp.ProductInsights', [])
	.component(
		'engagementfunnel',
		react2angular(
			EngagementFunnelContainer,
			[],
			['$http', '$rootScope', '$routeParams', '$location', '$timeout', '$scope']
		)
	);