import React, { createContext, useContext, useState,useEffect } from 'react';
import SystemServices from '../../../Services/SystemServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import AbTestsServices from '../../../Services/AbTestsServices';
import InsightsServices from '../../../Services/InsightsServices';
import { useTranslation } from 'react-i18next';
import {getFinalUrl} from '../../Menu/utils';
export const insightsContext = createContext();
export function useInsightsContext() {
	return useContext(insightsContext);
}
const InsightProvider = (props) => {

	const [t] = useTranslation('kpi', 'segments');
	const routeParams  = props.$routeParams;
	const AuthServices = props.AuthServices;
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatingServices = new ImpersonatingServices(
		props.$rootScope,
		props.$routeParams
	);

	const insightsServices = new InsightsServices(
		props.$http,
		props.$routeParams,
		AuthServices
	);
	const isAdmin = impersonatingServices.isAnAdmin();
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const abTestsServices = new AbTestsServices(props.$http, accountId);
	const token = props.AuthServices && props.AuthServices.getAccessToken();
	const optionsDate = {
		weekday: 'long',
		//year: 'numeric',
		month: 'long',
		day: 'numeric',
	};
	const today = new Date().toLocaleDateString(undefined,optionsDate);
	const [modulesActivated, setmodulesActivated] = useState([]);

	function getInsightsModules(){
		
		// Get Available kpis
		insightsServices.getInsightsActivated(accountId,(availableModules)=> {

			const menuItems = [];
			menuItems.push(
				{
					label: t('common.mainKPI'),
					icon: 'dashboard',
					activeUrls: ['/Insight/kpi'],
					link: getFinalUrl('/Insight/kpi', props)
				}
			);

			if (isAdmin) {
				menuItems.push(
					{
						label: 'AI insights',
						icon: 'ai',
						aiFlag: true,
						activeUrls: ['/Insight/AiInsights'],
						link: getFinalUrl('/Insight/AiInsights', props)
					}
				);
			}

			if (availableModules.includes('global')) {
				if (isAdmin) {
					menuItems.push(
						{
							label : t('traffic.traffic',),
							icon: 'traffic',
							items: [
								{
									label: t('traffic.traffic'),
									activeUrls: ['/Insight/traffic'],
									link: getFinalUrl('/Insight/traffic', props)
								},
								{
									label : t('traffic.navigationFunnel'),
									activeUrls: ['/Insight/funnel'],
									link :  getFinalUrl('/Insight/funnel', props)
								},
								{
									label : t('traffic.engagementFunnel'),
									activeUrls: ['/Insight/engagement'],
									link :  getFinalUrl('/Insight/engagement', props)
								}
							]
						},
					);
				} else {
					menuItems.push(
						{
							label: t('traffic.traffic'),
							icon: 'traffic',
							activeUrls: ['/Insight/traffic'],
							link: getFinalUrl('/Insight/traffic', props)
						}
					);
				}
			}

			if (availableModules.includes('ecommerce')) {
				menuItems.push(
					{
						label: t('ecommerce.ecommerce'),
						icon: 'ecommerce',
						activeUrls: ['/Insight/ecommerce'],
						link: getFinalUrl('/Insight/ecommerce', props)
					}
				);
			}

			if (availableModules.includes('products')) {
				menuItems.push(
					{
						label : t('productInsights.productInsights'),
						icon: 'product_insights',
						items: [
							{
								label: t('productInsights.scoring'),
								activeUrls: ['/Insight/scoring'],
								link: getFinalUrl('/Insight/scoring', props)
							},
							{
								label: t('productInsights.catalogAudit'),
								activeUrls: ['/Insight/catalogaudit'],
								link: getFinalUrl('/Insight/catalogaudit', props)
							},
							{
								label: t('productInsights.categoryPerformance'),
								activeUrls: ['/Insight/categoryperformance'],
								link: getFinalUrl('/Insight/categoryperformance', props)
							},
							// {
							// 	label: t('productInsights.productInsightslistMapping'),
							// 	activeUrls: ['/Insight/productlistmapping'],
							// 	link: getFinalUrl('/Insight/productlistmapping', props)
							// },
						]
					},
				);
			}

			menuItems.push(
				{
					label : t('segments', {ns: 'segments'}),
					icon: 'segments',
					items: [
						{
							label: t('analyse', {ns: 'segments'}),
							icon: 'segments',
							activeUrls: ['/Insight/segment'],
							link: getFinalUrl('/Insight/segment' , props)
						},
						{
							label : t('manageList', {ns: 'segments'}),
							icon: 'segments',
							activeUrls: ['/Insight/segment/settings'],
							link :  getFinalUrl('/Insight/segment/settings', props)
						}
					]
				}
			);

			if (isAdmin) {
				menuItems.push(
					{
						label: t('audit.scenarioRecommendation'),
						aiFlag: true,
						icon: 'audit',
						activeUrls: ['/Insight/audit'],
						link: getFinalUrl('/Insight/audit' , props)
					}
				);
			}

			setmodulesActivated(menuItems);

			const storage = JSON.stringify({
				accountId: accountId,
				menu : [...menuItems]
			});
			sessionStorage.setItem('insightsdashboard', storage);

			// const pathName = window.location;
			// if(menuItems.length >0 && pathName.pathname.includes('Insight')){
			// 	const newModulesClientsMap = menuItems.map(el => el.link);

			// 	if(!newModulesClientsMap.includes(pathName.pathname + pathName.search)){
			// 		window.location.href=menuItems[0].link;
			// 	}
			// }
		}, () => {
			setmodulesActivated([]);	
			systemServices.showError('An error occurred while trying to get data');
		});
	}

	useEffect(()=>{
		const handler = setTimeout(() => {
			const storage = sessionStorage.getItem('insightsdashboard');
			if (storage) {
				const parseModulesInsights = JSON.parse(storage);
				if (parseModulesInsights.accountId !== accountId) {
					getInsightsModules();
				} else {
					// const pathName = window.location;
					// if(parseModulesInsights.menu.length >0 && pathName.pathname.includes('Insight')){
					// 	const newModulesClientsMap = parseModulesInsights.menu.map(el => el.link);
					// 	if(!newModulesClientsMap.includes(pathName.pathname + pathName.search)){
					// 		sessionStorage.removeItem('insightsdashboard');
					// 	}
					// }
					setmodulesActivated(parseModulesInsights.menu);
				}

			} else {
				getInsightsModules();
			}
		}, 250);


	},[]);
	return (
		<insightsContext.Provider value={{
			routeParams,
			accountId,
			isAdmin,
			token,
			systemServices,
			abTestsServices,
			insightsServices,
			modulesActivated,
			today,
			props
		}}>
			{props.children}
		</insightsContext.Provider>
	);

};
export default InsightProvider;