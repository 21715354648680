import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import EmailRemarkettingServices from '../../../../Services/EmailRemarkettingServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import { setContext } from 'apollo-link-context';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { getEmailRemarketingCampaignsSimple } from '../../../../Services/Queries/OnSiteDashboardQueries.js';
import {initialDates} from '../../../../Components/SelectPicker/config.js';
import SystemServices from '../../../../Services/SystemServices';
import { I18nextProvider } from 'react-i18next';
type Remarketing = {
  isLoading: boolean;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  stopCampaignCRM: (key: string) => void;
  goCampaignCRM: (key: string) => void;
  duplicateCampaign: (campaignToDuplicate: any) => void;
  removeCampaign: (type: string, campaign: any, campaignName: string) => void;
  showConfigurationEmr: (id: string, name: string,bool:boolean) => void;
  idOfTheCampaign: string;
  showPopinConfigurationEmr:boolean;
  campaignName:string;
  handleActionsIsOpen: (bool:boolean)=>void;
  actionsIsOpen:boolean;
  campaigns: any[];
  filterState : string;
  setFilterState: (st:string)=>void;
	campaignsSorted:any[];
	setCampaignsSorted: React.Dispatch<React.SetStateAction<any[]>>;
	navFilter: any;
	setNavFilter:React.Dispatch<React.SetStateAction<any>>;
	isCustomSelected:boolean;
	setisCustomSelected:React.Dispatch<React.SetStateAction<boolean>>;
	customDateRange:DateRange;
	setCustomDateRange:React.Dispatch<React.SetStateAction<DateRange>>;
	initialDates:any;
};

const EmailRemarketing = createContext<Remarketing | undefined>(undefined);

export const useEmailRemarketing = () => {
	const contextValue = useContext(EmailRemarketing);
	if (contextValue === undefined) {
		throw new Error('useEmailRemarketing must be used within a EmailRemarketingProvider');
	}
	return contextValue;
};

interface EmailRemarketingProviderProps {
  children: ReactNode;
  $http: any;
  $rootScope: any;
  $routeParams: any;
  AuthServices: any;
  $location: any;
  $timeout: any;
}
import moment from 'moment';
import newInstanceI18next from '../../../../Util/traductionUtils';

interface DateRange {
	startDate: moment.Moment;
	endDate: moment.Moment;
}



export const EmailRemarketingProvider: React.FC<EmailRemarketingProviderProps> = ({
	children,
	$http,
	$rootScope,
	$routeParams,
	AuthServices,
	$timeout
}) => {
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;
	const crud = new EmailRemarkettingServices(accountId, $http);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [value, setValue] = useState<string>('');
	const [campaigns, setCampaigns] = useState<any[]>([]);
	const [showPopinConfigurationEmr, setShowPopinConfigurationEmr] = useState(false);
	const [idOfTheCampaign, setIdOfTheCampaign] = useState('');
	const [campaignName, setCampaignName] = useState('');
	const [actionsIsOpen, setActionsIsOpen] = useState(false);
	const [filterState, setFilterState] = useState('');
	const [campaignsSorted, setCampaignsSorted] = useState<any[]>([]);
	const [isCustomSelected, setisCustomSelected] = useState(false);
	const [customDateRange, setCustomDateRange] = useState<DateRange>( {
		startDate: moment().subtract(1, 'month'),
		endDate: moment().subtract(1, 'day')
	});
	const { initialFrom, initialTo } = initialDates;
	const [navFilter, setNavFilter] = useState({
		fromDate: initialFrom,
		toDate: initialTo,
		filters: [],
	});

	const systemServices = new SystemServices($rootScope, $timeout);
	const token = AuthServices && AuthServices.getAccessToken();
	const httpLink = createHttpLink({
	// @ts-ignore
		uri: window.BEYABLE_env.URI_GRAPHQL,
	});

	const authLink = setContext((_, { headers }) => {
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : '',
			},
		};
	});

	const defaultOptions = {
		watchQuery: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'ignore',
		},
		query: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'all',
		},
	};

	const client = new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache(),
		// @ts-ignore
		defaultOptions: defaultOptions,
	});

	const RanBetween = (fromDate, toDate) => ({
		'and': [
			{ 'startDate': { 'lte': toDate } },
			{
				'or': [
					{ 'endDate': { 'gte': fromDate } },
					{ 'endDate': { 'eq': null } }
				]
			}
		]
	});
	function fetchElement() {
		client
			.query({
				query: getEmailRemarketingCampaignsSimple,
				variables: {
					accountId: accountId,
					where:  RanBetween(moment(navFilter.fromDate).format('YYYY/MM/DD'), moment(navFilter.toDate).format('YYYY/MM/DD'))
				},
			})
			.then((response) => {
				const fetchedCampaigns = response.data.account.emailRemarketingCampaigns.items;
				setCampaigns(fetchedCampaigns);
				setCampaignsSorted(fetchedCampaigns);
				setIsLoading(false);
			})
			.catch((e) => {
				console.log('err', e);
				setIsLoading(false);
			});
	}

	useEffect(() => {
		fetchElement();
	}, [navFilter]);


	const duplicateCampaign = (campaignToDuplicate: any) => {
		crud.DuplicateCampaign(campaignToDuplicate.id,_data => {
			systemServices.showSuccess(
				'Campaign hes been duplicates with success'
			);
			fetchElement();
		}, error =>{
			systemServices.showError('An error occured');
			console.log('=>(contextEmailRemarketing.tsx:199) error', error);
		}
		);
	};

	const stopCampaignCRM = (key: string) => {
		crud.StopGoCampaignCRM(key, true,
			_data => {
				systemServices.showSuccess(
					'Campaign hes been stopped with success'
				);
				fetchElement();
			}, error =>{
				systemServices.showError('An error occured');
				console.log('=>(contextEmailRemarketing.tsx:199) error', error);
			}
		);
	};

	const removeCampaign = ( campaign: any,) => {
		crud.DestroyCampaignCRM(campaign,
			_data => {
				systemServices.showSuccess(
					'Campaign hes been delete with success'
				);
				fetchElement();
			}, error =>{
				systemServices.showError('An error occured');
				console.log('=>(contextEmailRemarketing.tsx:199) error', error);
			}
		);
	};

	const showConfigurationEmr = (id: string, name: string, bool:boolean) => {
		setShowPopinConfigurationEmr(bool);
		setIdOfTheCampaign(id);
		setCampaignName(name);
	};
	const handleActionsIsOpen = (bool:boolean) => {
		setActionsIsOpen(bool);
	};

	const goCampaignCRM = (key: string) => {
		crud.StopGoCampaignCRM(key, false,
			_data => {
				systemServices.showSuccess(
					'Campaign hes been started with success'
				);
				fetchElement();
			}, error =>{
				systemServices.showError('An error occured');
				console.log('=>(contextEmailRemarketing.tsx:199) error', error);
			}
		);
	};

	return (
		<EmailRemarketing.Provider
			value={{
				isLoading,
				value,
				setValue,
				stopCampaignCRM,
				goCampaignCRM,
				duplicateCampaign,
				removeCampaign,
				showConfigurationEmr,
				campaigns,
				showPopinConfigurationEmr,
				idOfTheCampaign,
				campaignName,
				handleActionsIsOpen,
				actionsIsOpen,
				filterState,
				setFilterState,
				campaignsSorted,
				setCampaignsSorted,
				navFilter,
				setNavFilter,
				isCustomSelected,
				setisCustomSelected,
				customDateRange,
				setCustomDateRange,
				initialDates
			}}
		>
			<I18nextProvider i18n={newInstanceI18next}>
				{children}
			</I18nextProvider>
		</EmailRemarketing.Provider>
	);
};

export default EmailRemarketingProvider;