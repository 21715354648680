import React, { useState, useEffect } from 'react';
import { DayPickerRangeController } from 'react-dates';
import moment from 'moment';
import Dropdown from '../../../../Components/Dropdown';
import Btn from '../../../../Components/Btn';
import SectionMessage from '../../../../Components/SectionMessage';

const NavComparison = ({
	handleClick,
	initialDates,
	navLabel = 'Period',
	customDateRange,
	setCustomDateRange,
	setComparisonIsEnable
}) => {

	const [focusedInput, setFocusedInput] = useState('startDate');
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [minimumNights, setminimumNights] = useState(null);
	const handleSaveCustomDate = (start, end) => {
		setCustomDateRange({
			startDate: start,
			endDate: end
		});
	};

	const handleOnDateChange = ({ startDate, endDate }) => {
		if (startDate !== null && minimumNights) {
			const newEndDate = moment(startDate).add(minimumNights, 'day');

			handleSaveCustomDate(startDate,newEndDate);
		}else{
			handleSaveCustomDate(startDate,startDate);
		}
	};


	const handleValidDate = () => {
		setDropdownIsOpen(false);
		handleClick(
			{
				fromDate: customDateRange.startDate,
				toDate: customDateRange.endDate
			});
	};
	const onFocused = (focused) => {
		if (focused === null) {
			setDropdownIsOpen(false);
		}
	};
	useEffect(()=>{
		if(initialDates){
			const initialFrom = moment(initialDates.initialFrom).startOf('day');
			const initialTo = moment(initialDates.initialTo).startOf('day');
			let diffBetweenToDateToCompare = initialTo.diff(initialFrom, 'days');
			setminimumNights(diffBetweenToDateToCompare);
		}
	},[initialDates]);
	const DatePicker =
		<>
			<div className='section'>
				<SectionMessage title='Select the start date of the comparison range.' noMargin={true}>
					The duration is automatically set to {minimumNights} days.
				</SectionMessage>
			</div>
			<DayPickerRangeController
				startDate={customDateRange.startDate}
				endDate={customDateRange.endDate}
				// minimumNights={0}
				// isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
				focusedInput={focusedInput}
				onFocusChange={(focused) => { onFocused(focused); }}
				onDatesChange={(dates) => { handleOnDateChange(dates); }}
				numberOfMonths={3}
				daySize={32}
				firstDayOfWeek={1}
			/>
			<div className='section has_top_border'>
				<Btn
					onClickFunction={() => handleValidDate()}
					message="Confirm"
					color="primary"
				/>
			</div>
		</>;


	const handlePreventToggleDropdown = (e) => {
		e.stopPropagation();
	};
	const handleRemoveComparison = (e) => {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		if (typeof setComparisonIsEnable === 'function') {
			setComparisonIsEnable(false);
		}
	};

	const button = (
		<button type="button" className="custom_input clickable m w_auto bg_white">
			<span className="custom_input_value has_label">
				<span className="custom_input_value_label">{navLabel}</span>
				<>
					{moment(customDateRange.startDate).format('MMM DD, YYYY')}
					<i className="analytics_range_arrow fas fa-arrow-right"></i>
					{moment(customDateRange.endDate).format('MMM DD, YYYY')}
				</>
			</span>
			<i className="custom_input_icon fas fa-calendar-alt"></i>
			<span className="custom_input_reset"
				onMouseDown={(e) => handlePreventToggleDropdown(e)}
				onClick={(e) => handleRemoveComparison(e)}>
				<i className="fas fa-times-circle"></i>
			</span>
		</button>
	);


	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={button}
			flip={false}
		>
			{DatePicker}
		</Dropdown>
	);
};


NavComparison.displayName = 'Nav';
export default NavComparison;