import React, { useState, useEffect } from 'react';
import { useEmailRemarketing } from '../context/contextEmailRemarketing';
import subNavStyles from './ListEmailRemarketing.module.css';
import EmailRemarketingConfiguration from '../../../Tooling/EmailRemarketingConfiguration/EmailRemarketingConfiguration.js';
interface EmailRemarketingStatisticsTotal {
	numberOfEmailsSent: number;
	numberOfEmailsDelivered: number;
	numberOfEmailsOpened: number;
	numberOfEmailsClicked: number;
	__typename: string;
}

interface EmailRemarketingStatistics {
	total: EmailRemarketingStatisticsTotal;
	byDate: any[];
	__typename: string;
}

interface EmailRemarketingCampaign {
	id: string;
	name: string;
	description: string | null;
	searchId: string;
	isStopped: boolean;
	startDate: string;
	endDate: string;
	creationDate: string;
	statistics: EmailRemarketingStatistics;
	__typename: string;
}

interface DataCRM {
	CampaignAccount: {
		Campaigns: EmailRemarketingCampaign[];
	};
}

const ListEmailRemarketing: React.FC = (props) => {
	const [dataCRM, setDataCRM] = useState<DataCRM>({ CampaignAccount: { Campaigns: [] } });
	const [sortType, setSortType] = useState<string>('');
	const [sortReverseCRM, setSortReverseCRM] = useState<boolean>(false);
	const [loadingProcessCampaignCRM] = useState<boolean>(false);
	const { campaigns, stopCampaignCRM, goCampaignCRM, duplicateCampaign, removeCampaign, showConfigurationEmr,showPopinConfigurationEmr,idOfTheCampaign,
		campaignName } = useEmailRemarketing();
	const [openSubMenuId, setOpenSubMenuId] = useState<string | null>(null);

	useEffect(() => {
		setDataCRM({ CampaignAccount: { Campaigns: campaigns } });
	}, [campaigns]);

	const handleSort = (type: string) => {
		setSortType(type);
		setSortReverseCRM(!sortReverseCRM);
	};

	const toggleSubMenu = (campaignId: string) => {
		setOpenSubMenuId(openSubMenuId === campaignId ? null : campaignId);
	};
	function onLoad (){
		showConfigurationEmr('','',false)
	}

	const renderCampaigns = () => {
		if (loadingProcessCampaignCRM || dataCRM.CampaignAccount.Campaigns.length === 0) {
			return (
				<div className="empty_page has_picto">
					<div className="empty_page_picto empty_page_picto_email_remarketing"></div>
					<div className="empty_page_title">Email remarketing</div>
					<div className="empty_page_text">
						Stay in touch with your most valuable visitors and generate additional income with abandoned cart email automations!
					</div>
					<div className="empty_page_btn">
						<a className="btnUi btn_primary btn_l btn_fs_l" href="/CampaignsCrmOnSite/Create">
							<i className="fa fa-plus"></i>
							<span className="btn_txt">Create a remarketing campaign</span>
						</a>
					</div>
				</div>
			);
		}

		return (
			<React.Fragment>
				<EmailRemarketingConfiguration
					idOfCampaign={idOfTheCampaign}
					campaignName={campaignName}
					onLoad={onLoad}
					{...props}
					isOppen={showPopinConfigurationEmr}/>
				<div className="dashboard_table dashboard_table_email">

					<div className="grid-head" onClick={() => handleSort('name')}>
					Title {sortType === 'name' && <i className={`fa fa-caret-${sortReverseCRM ? 'up' : 'down'}`}></i>}
					</div>
					<div className="grid-head" onClick={() => handleSort('numberOfEmailsSent')}>
					Sent {sortType === 'numberOfEmailsSent' && <i className={`fa fa-caret-${sortReverseCRM ? 'up' : 'down'}`}></i>}
					</div>
					<div className="grid-head" onClick={() => handleSort('numberOfEmailsDelivered')}>
					Delivered {sortType === 'numberOfEmailsDelivered' && <i className={`fa fa-caret-${sortReverseCRM ? 'up' : 'down'}`}></i>}
					</div>
					<div className="grid-head" onClick={() => handleSort('numberOfEmailsOpened')}>
					Opened {sortType === 'numberOfEmailsOpened' && <i className={`fa fa-caret-${sortReverseCRM ? 'up' : 'down'}`}></i>}
					</div>
					<div className="grid-head" onClick={() => handleSort('numberOfEmailsClicked')}>
					Clicked {sortType === 'numberOfEmailsClicked' && <i className={`fa fa-caret-${sortReverseCRM ? 'up' : 'down'}`}></i>}
					</div>
					<div className="grid-head"></div>

					{dataCRM.CampaignAccount.Campaigns.map((campaign, index) => (
						<React.Fragment key={index}>
							<div className="grid-campaign">
								<p className="campaign-title">{campaign.name}</p>
								<div className="campaign-id">
									<p className="by_code">
									ID : <span>{campaign.id}</span> <i className="far fa-clipboard"></i>
									</p>
								</div>
								<div className="campaign-detail">
									<div className="campaign-status" dangerouslySetInnerHTML={{ __html: campaign.isStopped ? 'Stopped' : 'Active' }}></div>
									<p className="campaign-date">
										{new Date(campaign.startDate).toLocaleDateString('fr-FR')} - {new Date(campaign.endDate).toLocaleDateString('fr-FR')}
									</p>
								</div>
								<p className="campaign-description">{campaign.description}</p>
							</div>
							<div className="grid-campaign">{campaign.statistics.total.numberOfEmailsSent > 0 ? campaign.statistics.total.numberOfEmailsSent : '-'}</div>
							<div className="grid-campaign">{campaign.statistics.total.numberOfEmailsDelivered > 0 ? campaign.statistics.total.numberOfEmailsDelivered : '-'}</div>
							<div className="grid-campaign">{campaign.statistics.total.numberOfEmailsOpened > 0 ? campaign.statistics.total.numberOfEmailsOpened : '-'}</div>
							<div className="grid-campaign">{campaign.statistics.total.numberOfEmailsClicked > 0 ? campaign.statistics.total.numberOfEmailsClicked : '-'}</div>
							<div className="grid-campaign">
								<div className={subNavStyles.sub_nav}>
									<button onClick={() => toggleSubMenu(campaign.id)}>
										<i className="fa fa-cog"></i>
									</button>
									{openSubMenuId === campaign.id && (
										<ul className={subNavStyles.sub_menu}>
											<li>
												<a href="" onClick={() => stopCampaignCRM(campaign.id)}>
													<i className="fa fa-pause"></i> <span>Stop</span>
												</a>
											</li>
											<li>
												<a href="" onClick={() => goCampaignCRM(campaign.id)}>
													<i className="fa fa-play"></i> <span>Go</span>
												</a>
											</li>
											<li>
												<a href={`/CampaignsCrmOnSite/Create/${campaign.id}`}>
													<i className="fa fa-pen"></i> <span>Edit</span>
												</a>
											</li>
											<li>
												<a href={`/EmailingReporting/?id=${campaign.id}`}>
													<i className="fa fa-chart-area"></i> <span>Stats</span>
												</a>
											</li>
											<li>
												<a href="" onClick={() => duplicateCampaign(campaign)}>
													<i className="fa fa-plus"></i> <span>Duplicate</span>
												</a>
											</li>
											<li>
												<a href="" onClick={() => removeCampaign('crm', campaign, campaign.name)}>
													<i className="fa fa-trash"></i> <span>Delete</span>
												</a>
											</li>
											<li>
												<a onClick={() => showConfigurationEmr(campaign.id, campaign.name, true)}>
													<i className="far fa-envelope-open"></i> <span>Config</span>
												</a>
											</li>
										</ul>
									)}
								</div>
							</div>
						</React.Fragment>

					))}
				</div>
			</React.Fragment>
		);
	};

	return <>{renderCampaigns()}</>;
};

export default ListEmailRemarketing;