import React, { useState }from 'react';
import {useAnalyticsConfigContext} from './userAnalyticsConfigContext';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';
import IconBtn from '../../../Components/IconBtn';
import Avatar   from '../../../Components/Avatar';
import Confirm from '../../../Components/Confirm';
import Link from '../../../Components/Link';

export default function TenantAnalyticsItem({
	tenant,
	handleSelect,
	data,
	HasUsersByTenantConfig
}) {
	const {referenceData,
		deleteTenant,
	} = useAnalyticsConfigContext();

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [confirmIsOpen, setConfirmIsOpen] = useState(false);

	const getUserInfos = (u) => {
		const userInfos = referenceData.users.find(user => user.id === u);
		return userInfos ? userInfos : {id : u, firstName : 'unknown'};
	};

	return (
		<>
			<div className="table_row table_body_row clickable grey_0"
				style={{'gridTemplateColumns': '1fr 4fr 180px'}}
				onClick={HasUsersByTenantConfig ?
					(e) => handleSelect(e,tenant, data) :  null}>
				<div className="table_col">
					<div className='fw_medium'>{tenant}</div>
				</div>
				{HasUsersByTenantConfig && 
				<><div className="table_col over_hidden">
					{data.length == 0 &&
						<span className='grey_3'>No user can access this tenant</span>
					}
					<div className='ellips'>
						{data.map(k => {
							const user = getUserInfos(k);
							return (
								<span key={k} className='mr_15'>
									<Avatar 
										id={user.id}
										name={user.displayName}
										size="xxs"
									/>
									<span className='ml_5 v_al_middle'>{user.displayName}</span>
								</span>
							);
						})}
					</div>
				</div>
				<div className="table_col flex_justify_end">
					<Link
						message="Edit user list"
						className='mr_20'
					/>
					<Dropdown
						isOpen={dropdownIsOpen}
						toggle={(s) => setDropdownIsOpen(s)}
						button={<IconBtn icon="fas fa-ellipsis-v" />}
					>
						<Listbox>
							<ListboxItem
								message="Delete this tenant"
								icon="fas fa-trash"
								onClick={(e) => {
									setConfirmIsOpen(true);
									setDropdownIsOpen(false);
								} } />
						</Listbox>
					</Dropdown>

				</div></>}
				{!HasUsersByTenantConfig && <div className="table_col flex_justify_end">
					<Listbox>
						<ListboxItem
							message="Delete this tenant"
							icon="fas fa-trash"
							onClick={(e) => {
								setConfirmIsOpen(true);
								setDropdownIsOpen(false);
							} } />
					</Listbox>
				</div>
				}
			</div>
			<Confirm
				title="Do you really want to delete this tenant?"
				text="This action will delete the corresponding traffic data, it is irreversible."
				isOpen={confirmIsOpen}
				confirmText="Delete"
				cancelText="Cancel"
				confirmInput="DELETE"
				confirmInputLabel='Please write "DELETE" in the text field.'
				confirmColor="alert"
				confirmCallback={() => {deleteTenant(tenant); setConfirmIsOpen(false);}}
				onClose={() => setConfirmIsOpen(false)}
				width={500}
			/>
		</>
	);
}
