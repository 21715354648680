import React, { useState, useEffect } from 'react';
import Modal from '../../../Components/Modal';
import Btn from '../../../Components/Btn';
import PopinConfirm from '../../../Components/PopinConfirm';
import InputCustom from '../../../Components/InputCustom';
import { v4 as uuidv4 } from 'uuid';
import ColorPicker from '../../Labels/ColorPicker';
import CreateLabel from '../../Labels/CreateLabel';
import ReactTooltip from 'react-tooltip';

export default function LabelGlobalAccounts({
	accountId,
	modalLabelsAccountisOppen,
	setmodalLabelsAccountisOppen,
	systemServices,
	labelService,
	onClose,
	objectType
}) {
	const [isLoading, setisLoading] = useState(true);
	const [labelsListcampaign, setlabelsListcampaign] = useState();
	const [labelEdition, setlabelEdition] = useState({});
	const [refetch, setrefetch] = useState(false);
	const [idOflabelToDelete, setidOflabelToDelete] = useState();
	const idTooltipOfDelete = uuidv4();
	const idTooltipOfPut = uuidv4();
	useEffect(() => {
		labelService.getLabels(accountId,
			objectType,
			newData => {
				setisLoading(false);
				setlabelsListcampaign(newData);
				setrefetch(false);
			}, err => {
				setisLoading(false);
				systemServices.showError('An Error occured while fetching labels');
			});
	}, [refetch]);
	const handleChangeName = (e) => {
		e.preventDefault();
		const value = e.target.value;
		setlabelEdition(l => ({ ...l, name: value }));
	};
	const putLabel = () => {
		setisLoading(true);
		const newValueForLabel = {
			name: labelEdition.name,
			color: labelEdition.color,
			objectType: objectType
		};
		labelService.putLabel(accountId, labelEdition.id, newValueForLabel,
			newData => {
				setisLoading(false);
				setrefetch(true);
				setlabelEdition({});
			}, err => {
				setisLoading(false);
				systemServices.showError('An Error occured during the modification of the label');
			});
	};
	const DeleteLabel = () => {
		setisLoading(true);
		labelService.deleteLabel(accountId, idOflabelToDelete.id,
			newData => {
				setisLoading(false);
				setrefetch(true);
				setidOflabelToDelete();
			}, err => {
				setisLoading(false);
				systemServices.showError('An Error occured during the delete of the label');
			});
	};

	return (
		<Modal
			isOpen={modalLabelsAccountisOppen}
			width="400"
			// height='700'
			onClose={(e) => { setmodalLabelsAccountisOppen(false); onClose(); }}
		>
			<div className="modal_header">
				Edit labels
			</div>
			{idOflabelToDelete &&
				<PopinConfirm
					cancel={() => setidOflabelToDelete()}
					title={`Do you really want to delete ${idOflabelToDelete.name} ? `} >
					<Btn
						onClickFunction={(e) => { setidOflabelToDelete(); }}
						message="No"
						color="alert"
					/>
					<Btn
						onClickFunction={DeleteLabel}
						message="Yes"
						color="primary"
					/>
				</PopinConfirm>
			}
			<CreateLabel
				systemServices={systemServices}
				labelService={labelService}
				accountId={accountId}
				setrefetch={(e) => setrefetch(e)}
				setisLoading={(e) => setisLoading(e)}
				objectType={objectType}
			/>
			<div className="modal_body">
				<ul className="label_edition_list">
					{labelsListcampaign && labelsListcampaign.map(label =>
						<li className="flex" key={label.id}>
							<div className="flex_item_fix">
								<div>
									<ColorPicker label={label}
										systemServices={systemServices}
										labelService={labelService}
										accountId={accountId}
										setrefetch={(e) => setrefetch(e)}
									/>
								</div>
							</div>
							{labelEdition.id !== label.id &&
								<>
									<div className="flex_item_full" >
										{label.name || 'No name'}
									</div>
									<div className="flex_item_fix" >
										<ReactTooltip id={idTooltipOfPut} backgroundColor='black' effect='solid' />
										<a data-for={idTooltipOfPut} data-tip="Rename" className="label_edition_action" onClick={() => setlabelEdition(label)}>
											<i className="fas fa-pen"></i>
										</a>
									</div>
									<div className="flex_item_fix">
										<ReactTooltip id={idTooltipOfDelete} backgroundColor='black' effect='solid' />
										<a data-for={idTooltipOfDelete} data-tip="Delete" className="label_edition_action" onClick={() => setidOflabelToDelete(label)}>
											<i className="fas fa-trash-alt"></i>
										</a>
									</div>
								</>
							}
							{labelEdition && labelEdition.id == label.id &&
								<>
									<div className="flex_item_full">
										<InputCustom
											type="text"
											placeholder="Label name"
											value={labelEdition.name}
											onChange={(e) => handleChangeName(e)}
											fullSize={true}
										/>
									</div>
									<div className="flex_item_fix" >
										<a className="label_edition_action" title="Save"
											onClick={() => putLabel()}>
											<i className="fas fa-save"></i>
										</a>
									</div>
									<div className="flex_item_fix" >
										<a className="label_edition_action" title="Cancel" onClick={() => setlabelEdition({})}>
											<i className="fas fa-times"></i>
										</a>
									</div>
								</>
							}
						</li>
					)}
				</ul>
				{!isLoading && labelsListcampaign && labelsListcampaign.length == 0 && <div className="popin_labels_empty">
					<i className="fas fa-tag"></i>
					<div>No label yet</div>
				</div>}
			</div>

			{isLoading &&
				<div className="spinner_overlay">
					<div className="spinner">
						<div className="rect1"></div>
						<div className="rect2"></div>
						<div className="rect3"></div>
						<div className="rect4"></div>
						<div className="rect5"></div>
					</div>
				</div>
			}
		</Modal>
	);
}
