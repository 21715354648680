import HttpServices from './HttpServices';

interface PaginationOptions {
	offset: number;
	limit: number;
}

type CallbackSuccess = (response: any) => void;
type CallbackError = (err: any, dataError: any) => void;

function transformObject(input) {
	return input.map(item => {
		const { key, value } = item;
		return value.map(subItem => {
			return {
				Type: key,
				Value: subItem.value,
				Name: subItem.name
			};
		});
	}).flat();
}
interface FilterParams {
	tag?: string;
	tagtype?:string;
	xUnit?: string;
	yUnit?: string;
	startDate1?: number;
	endDate1?: number;
	startDate2?: number;
	endDate2?: number;
	sDate1?: number;
	eDate1?: number;
	sDate2?: number;
	eDate2?: number;
	rowsperpage?: number;
	blocnumber?: number;
	orderby?: string;
	direction?: string;
	pattern?: string;
	dimnamx?:string;
	dimnamy?:string;
	recommendation?:string;
}

interface AuthServices {
	getAccessToken: () => string | null;
}

interface HttpServices {
	get: (url: string, callbackSuccess?: CallbackSuccess, callbackError?: CallbackError) => Promise<any>;
	post: (url: string, data?: any, callbackSuccess?: CallbackSuccess, callbackError?: CallbackError) => Promise<any>;
}

class ProductInsightsServices {
	private HttpServices: HttpServices;
	private BO_API_URL: string;
	private SERVER_URL: string;
	private config: { headers: { Authorization?: string } };
	private accountId: string;

	constructor($http: any, authServices: AuthServices, accountId: string) {
		this.HttpServices = new HttpServices($http);

		// @ts-ignore
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw new Error('Configuration [window.BEYABLE_env.BO_API_URL] missing');
		} else {
			// @ts-ignore

			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		// @ts-ignore

		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;

		const token = authServices.getAccessToken();
		this.config = {
			headers: {}
		};
		if (token) {
			this.config.headers.Authorization = 'Bearer ' + token;
		}
		this.accountId = accountId;
	}

	getRuleList(valueInSearchBar: string, dataFiltered: string, descSort: boolean, paginationOptions: PaginationOptions, callbackSuccess: CallbackSuccess, callbackError: CallbackError) {
		this.HttpServices.get(
			`${this.BO_API_URL}${this.accountId}/emerch-rule/?offset=${paginationOptions.offset}&limit=${paginationOptions.limit}&searchTerm=${valueInSearchBar}&sortBy=${dataFiltered}&descSort=${descSort}`,
			callbackSuccess,
			callbackError
		);
	}
	checkIfAccounthasDatas( callbackSuccess: CallbackSuccess, callbackError: CallbackError) {
		const url = `${this.SERVER_URL}/users/${this.accountId}/accountproductsinsisghtconfig/${this.accountId}`;
		return this.HttpServices.get(url, callbackSuccess, callbackError);
	}
	loadInsightsProductsSimple(options: FilterParams, callbackSuccess: CallbackSuccess, callbackError: CallbackError) {
		const {
			startDate1: sDate1,
			endDate1: eDate1,
		} = options;
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, this.accountId, {
			sDate1,
			eDate1,
			tagtype: ''
		});
		return this.HttpServices.post(url,[], callbackSuccess, callbackError);
	}
	loadInsightsProductsForAccountS(options: FilterParams, callbackSuccess: CallbackSuccess, callbackError: CallbackError) {
		const {
			startDate1: sDate1,
			endDate1: eDate1,

		} = options;
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, this.accountId, {
			sDate1,
			eDate1,
		});
		return this.HttpServices.get(url,callbackSuccess, callbackError);
	}
	loadInsightsProductsForAccountComparison(options: FilterParams, callbackSuccess: CallbackSuccess, callbackError: CallbackError) {
		const {
			startDate1: sDate1,
			endDate1: eDate1,
			startDate2: sDate2,
			endDate2: eDate2,
		} = options;
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, this.accountId, {
			sDate1,
			eDate1,
			sDate2,
			eDate2
		});
		return this.HttpServices.get(url,callbackSuccess, callbackError);
	}

	loadInsightsProductsForCategoriesComparison(options: FilterParams,filterSelected, callbackSuccess: CallbackSuccess, callbackError: CallbackError) {
		const {
			startDate1: sDate1,
			endDate1: eDate1,
			startDate2: sDate2,
			endDate2: eDate2,
			tagtype,
			dimnamx,
			dimnamy,
		} = options;
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, this.accountId, {
			tagtype,
			sDate1,
			eDate1,
			sDate2,
			eDate2,
			dimnamx,
			dimnamy,
		});
		if(Object.keys(filterSelected).length > 0){
			return this.HttpServices.post(url,transformObject(filterSelected), callbackSuccess, callbackError);
		}else{
			return this.HttpServices.get(url,callbackSuccess, callbackError);
		}
	}

	loadInsightsProductsForCategoriesComparisonWithFilters(key: string, tag: string, xUnit: string, yUnit: string, startDate1: number, endDate1: number, startDate2: number, endDate2: number, categories: any) {
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, key, {
			tag,
			xUnit,
			yUnit,
			startDate1,
			endDate1,
			startDate2,
			endDate2
		});
		return this.HttpServices.post(url, categories);
	}

	loadInsightsProductsForCategoriesEmerchComparison(options: FilterParams,filters, callbackSuccess: CallbackSuccess, callbackError: CallbackError) {
		const {
			startDate1: sDate1,
			endDate1: eDate1,
			startDate2: sDate2,
			endDate2: eDate2,
			tagtype
		} = options;
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, this.accountId, {
			tagtype,
			sDate1,
			eDate1,
			sDate2,
			eDate2
		});
		if(Object.keys(filters).length > 0){
			return this.HttpServices.post(url,transformObject(filters), callbackSuccess, callbackError);
		}else{
			return this.HttpServices.get(url,callbackSuccess, callbackError);
		}

	}

	loadInsightsProductsForCategoriesEmerchComparisonWithFilters(key: string, tag: string, startDate1: number, endDate1: number, startDate2: number, endDate2: number, categories: any) {
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, key, {
			tag,
			startDate1,
			endDate1,
			startDate2,
			endDate2
		});
		return this.HttpServices.post(url, categories);
	}

	loadInsightsProductsForProductsComparison(options: FilterParams, data: any, callbackSuccess: CallbackSuccess, callbackError: CallbackError) {
		const {
			startDate1: sDate1,
			endDate1: eDate1,
			startDate2: sDate2,
			endDate2: eDate2,
			rowsperpage,
			blocnumber,
			orderby,
			direction,
			pattern
		} = options;

		const params = {
			sDate1,
			eDate1,
			sDate2,
			eDate2,
			rowsperpage,
			blocnumber,
			orderby,
			direction,
			pattern
		};

		// @ts-ignore
		const filteredParams = Object.fromEntries(
			Object.entries(params).filter(([key, value]) => {
				if (key === 'pattern') {
					return true;
				}
				if (value === '' || value === null || value === undefined) {
					return false;
				}
				if (typeof value === 'object' && Object.keys(value).length === 0) {
					return false;
				}
				if (Array.isArray(value) && value.length === 0) {
					return false;
				}
				return true;
			})
		);

		const url = this.buildUrl(`/users/${this.accountId}/accountproductsinsisghts/`, this.accountId, filteredParams);
		return this.HttpServices.post(url, transformObject(data), callbackSuccess, callbackError);
	}
	loadBest5ProgressScoreForProducts(options: FilterParams, callbackSuccess: CallbackSuccess, callbackError: CallbackError){
		const {
			startDate1: sDate1,
			endDate1: eDate1,
			startDate2: sDate2,
			endDate2: eDate2,
			rowsperpage,
			blocnumber,
			orderby,
			direction,
			pattern
		} = options;

		const url = this.buildUrl(`/users/${this.accountId}/accountproductsinsisghts/`, this.accountId, {
			sDate1,
			eDate1,
			sDate2,
			eDate2,
			rowsperpage,
			blocnumber,
			orderby,
			direction,
			pattern
		});
		return this.HttpServices.post(url, [], callbackSuccess, callbackError);
	}
	loadBestScoreForProduct(options: FilterParams, callbackSuccess: CallbackSuccess, callbackError: CallbackError){
		const {
			startDate1: sDate1,
			endDate1: eDate1,
			rowsperpage,
			blocnumber,
			orderby,
			direction,
			pattern
		} = options;

		const url = this.buildUrl(`/users/${this.accountId}/accountproductsinsisghts/`, this.accountId, {
			sDate1,
			eDate1,
			rowsperpage,
			blocnumber,
			orderby,
			direction,
			pattern
		});
		return this.HttpServices.post(url, [], callbackSuccess, callbackError);
	}
	loadWorstScoreForProduct(options: FilterParams, callbackSuccess: CallbackSuccess, callbackError: CallbackError){
		const {
			startDate1: sDate1,
			endDate1: eDate1,
			startDate2: sDate2,
			endDate2: eDate2,
			rowsperpage,
			blocnumber,
			orderby,
			direction,
			pattern
		} = options;

		const url = this.buildUrl(`/users/${this.accountId}/accountproductsinsisghts/`, this.accountId, {
			sDate1,
			eDate1,
			sDate2,
			eDate2,
			rowsperpage,
			blocnumber,
			orderby,
			direction,
			pattern
		});
		return this.HttpServices.post(url, [], callbackSuccess, callbackError);
	}

	exportInsightsProductsForProductsComparison(key: string, startDate1: number, endDate1: number, startDate2: number, endDate2: number, orderby: string, direction: string, data: any) {
		const url = this.buildUrl(`/users/${this.accountId}/accountproductsinsisghts/`, key, {
			startDate1,
			endDate1,
			startDate2,
			endDate2,
			orderby,
			direction
		}, true);
		return this.HttpServices.post(url, data);
	}

	loadInsightsProductsForProductsOutliers(options: FilterParams,filterSelected, callbackSuccess: CallbackSuccess, callbackError: CallbackError) {
		const {
			startDate1: sDate1,
			endDate1: eDate1,
			rowsperpage,
			blocnumber,
			orderby,
			direction,
			pattern,
			recommendation
		} = options;
		const url = this.buildUrl(`/users/${this.accountId}/accountproductsinsisghtsoutliers/`, this.accountId, {
			eDate1,
			sDate1,
			rowsperpage,
			blocnumber,
			orderby,
			direction,
			pattern,
			recommendation
		});
		return this.HttpServices.post(url, transformObject(filterSelected), callbackSuccess, callbackError);
	}

	exportInsightsProductsForProductsOutliers(options: FilterParams, callbackSuccess: CallbackSuccess, callbackError: CallbackError) {
		const {
			startDate1: sDate1,
			endDate1: eDate1,
			orderby,
			direction,
		} = options;
		const url = this.buildUrl(`/users/${this.accountId}/accountproductsinsisghtsoutliers/`, this.accountId, {
			sDate1,
			eDate1,
			orderby,
			direction
		}, true);
		return this.HttpServices.post(url, [], callbackSuccess, callbackError);
	}

	loadInsightsProductsForAccount(options: FilterParams, callbackSuccess: CallbackSuccess, callbackError: CallbackError) {
		const {
			startDate1: sDate1,
			endDate1: eDate1,
		} = options;
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, this.accountId, {
			sDate1,
			eDate1,
		});
		return this.HttpServices.get(url, callbackSuccess, callbackError);
	}

	loadInsightsProductsForCategories(options: FilterParams,filters, callbackSuccess: CallbackSuccess, callbackError: CallbackError) {
		const {
			startDate1: sDate1,
			endDate1: eDate1,
			tagtype,
			dimnamx,
			dimnamy,
		} = options;
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, this.accountId, {
			tagtype,
			dimnamx,
			dimnamy,
			eDate1,
			sDate1
		});
		if(Object.keys(filters).length > 0){
			return this.HttpServices.post(url,transformObject(filters), callbackSuccess, callbackError);
		}else{
			return this.HttpServices.get(url,callbackSuccess, callbackError);
		}
	}

	exportInsightsProductsForCategories(options: FilterParams, callbackSuccess: CallbackSuccess, callbackError: CallbackError) {
		const {
			startDate1: sDate1,
			endDate1: eDate1,
			tag,
			dimnamx,
			dimnamy,
		} = options;
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, this.accountId, {
			tag,
			dimnamx,
			dimnamy,
			sDate1,
			eDate1
		}, true);
		return this.HttpServices.get(url,callbackSuccess, callbackError);
	}

	loadInsightsProductsForCategoriesWithFilters(key: string, tag: string, dimnamx: string, dimnamy: string, startDate1: number, endDate1: number, categories: any) {
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, key, {
			tag,
			dimnamx,
			dimnamy,
			startDate1,
			endDate1
		});
		return this.HttpServices.post(url, categories);
	}

	exportInsightsProductsForCategoriesWithFilters(key: string, tag: string, dimnamx: string, dimnamy: string, startDate1: number, endDate1: number, categories: any) {
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, key, {
			tag,
			dimnamx,
			dimnamy,
			startDate1,
			endDate1
		}, true);
		return this.HttpServices.post(url, categories);
	}

	loadInsightsProductsForCategoriesEmerch(options: FilterParams,filters, callbackSuccess: CallbackSuccess, callbackError: (err: any, dataError: any) => void) {

		const {
			startDate1: sDate1,
			endDate1: eDate1,
			tagtype
		} = options;
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, this.accountId, {
			sDate1,
			eDate1,
			tagtype
		});
		if(Object.keys(filters).length > 0){
			return this.HttpServices.post(url,transformObject(filters), callbackSuccess, callbackError);
		}else{
			return this.HttpServices.get(url,callbackSuccess, callbackError);
		}
	}

	exportInsightsProductsForCategoriesEmerch(key: string, tag: string, startDate1: number, endDate1: number) {
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, key, {
			tag,
			startDate1,
			endDate1
		}, true);
		return this.HttpServices.get(url);
	}

	loadInsightsProductsForCategoriesEmerchWithFilters(options: FilterParams,payload, callbackSuccess: CallbackSuccess, callbackError: (err: any, dataError: any) => void) {
		const {
			startDate1: sDate1,
			endDate1: eDate1,
			tagtype
		} = options;

		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, this.accountId, {
			sDate1,
			eDate1,
			tagtype
		});
		return this.HttpServices.post(url, transformObject(payload) ,callbackSuccess, callbackError);
	}

	exportInsightsProductsForCategoriesEmerchWithFilters(key: string, tag: string, startDate1: number, endDate1: number, categories: any) {
		const url = this.buildUrl(`/users/${this.accountId}/accountinsightsstatistics/`, key, {
			tag,
			startDate1,
			endDate1
		}, true);
		return this.HttpServices.post(url, categories);
	}

	loadInsightsProductsForProducts(key: string, startDate1: number, endDate1: number, rowsperpage: number, blocnumber: number, orderby: string, direction: string, pattern: string, data: any) {
		const url = this.buildUrl(`/users/${this.accountId}/accountproductsinsisghts/`, key, {
			startDate1,
			endDate1,
			rowsperpage,
			blocnumber,
			orderby,
			direction,
			pattern
		});
		return this.HttpServices.post(url, data);
	}

	exportInsightsProductsForProducts(options:any,data, callbackSuccess: CallbackSuccess, callbackError: CallbackError) {
		const {
			startDate1: sDate1,
			endDate1: eDate1,
			orderby,
			direction,

		} = options;
		const url = this.buildUrl(`/users/${this.accountId}/accountproductsinsisghts/`, this.accountId, {
			sDate1,
			eDate1,
			orderby,
			direction
		}, true);
		return this.HttpServices.post(url, data,callbackSuccess, callbackError);
	}

	private buildUrl(baseUrl: string, key: string, params: FilterParams, exportCSV = false): string {
		let url = `${this.SERVER_URL}${baseUrl}${key}?device=all`;
		for (const [param, value] of Object.entries(params)) {
			if (value !== undefined && value !== null) {
				url += `&${param}=${value}`;
			}else if (value === undefined) {
				url += `&${param}=`;
			}
		}
		if (exportCSV) {
			url += '&export=true';
		}
		return url;
	}
}

export default ProductInsightsServices;
