export const getFormattedNumber = (n = 0, decimals = 0, unit = '', forceSign = false):string => {
	const s = n.toFixed(decimals);
	const parts = s.split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	const finalUnit = unit ? '\u00a0' + unit : '';
	const sign = (forceSign && n >= 0) ? '+' : '';
	return sign + parts.join('.') + finalUnit;
};

export const getFormattedPercent = (n = 0, decimals = 1, forceSign = false):string => {
	return getFormattedNumber(n, decimals, '%', forceSign);
};
