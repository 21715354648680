import React, { Component, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

class Iframe extends Component {
	componentDidMount() {
		this.node.addEventListener('load', this.handleLoad);
	}

	componentWillUnmout() {
		this.node.removeEventListener('load', this.handleLoad);
	}

	handleLoad = () => {
		this.iframeRoot = this.node.contentDocument.body;
		this.forceUpdate();
	};

	render() {
		const {
			children,
			className,
			style
		} = this.props;
		return (
			<iframe
				srcDoc={'<!DOCTYPE html>'}
				ref={node => (this.node = node)}
				className={className}
				style={style}
			>
				{this.iframeRoot && ReactDOM.createPortal(children, this.iframeRoot)}
			</iframe>
		);
	}
}

export function ShadowDom ({
	children,
	className
}) {

	const rootRef = useRef(0);
	const [shadow, setShadow] = useState(null);
	const [isShadow, setIsShadow] = useState(false);

	useEffect(() => {
		if (rootRef.current && !isShadow) {
			setIsShadow(true);
			const shadow = rootRef.current.attachShadow({ mode: 'open' });
			setShadow(shadow);
		}
	}, [rootRef.current]);

	return (
		<div ref={rootRef} className={className}>
			{shadow && ReactDOM.createPortal(children, shadow)}
		</div>
	);
}

export default Iframe;
