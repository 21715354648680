
import {EngagementLevel} from '../../NavigationFunnelTypes';
import SelectFilter from '../../../../../../Components/SelectFilter.js';
import React  from 'react';

export const VisitorEngagementLevelFilter = ({context}:any) => {

	const {
		$location,
		needToRefreshAngularJs,
		visitorEngamentSelected,
		setVisitorEngamentSelected,
		rawDimensions,
		typeEnv
	} = context;

	let listWithLabelAndId: EngagementLevel[]=[];
	const listEngagementsRaw = rawDimensions ? rawDimensions['VisEngLevel'] : undefined;

	if(listEngagementsRaw){
		listWithLabelAndId = listEngagementsRaw.values.map(element => {
			return {
				value : element.id,
				label: element.label
			};
		});
	}
	function handleChangeVisitorEngagement(visitorEngagement:EngagementLevel){
		const newKey = visitorEngamentSelected.find(el => el === visitorEngagement);
		let filterEngagement:any = [];
		if(newKey) {
			filterEngagement = visitorEngamentSelected.filter(x => x !== visitorEngagement);
		}else{
			filterEngagement = [...visitorEngamentSelected,visitorEngagement];
			setVisitorEngamentSelected(filterEngagement);

		}
		const funnelStorage = localStorage.getItem(typeEnv);
		$location.search('engagement', null);
		if(filterEngagement.length > 0){
			$location.search('engagement', filterEngagement.join(','));
		}
		if(funnelStorage){
			const newFunnel = JSON.parse(funnelStorage);
			const reset = {...newFunnel, engagement: filterEngagement.join(',')};
			localStorage.setItem(typeEnv,JSON.stringify(reset));
			needToRefreshAngularJs(false);
		}else{
			localStorage.setItem(typeEnv,JSON.stringify({ engagement: filterEngagement.join(',') }));
			needToRefreshAngularJs(false);
		}
	}
	function onReset(){
		const funnelStorage = localStorage.getItem(typeEnv);
		setVisitorEngamentSelected([]);
		$location.search('engagement', '');
		if(funnelStorage){
			$location.search('engagement', '');
			const newFunnel = JSON.parse(funnelStorage);
			const reset = {...newFunnel, engagement: ''};
			delete reset['engagement'];
			localStorage.setItem(typeEnv,JSON.stringify(reset));
			needToRefreshAngularJs(false);
		}else{
			$location.search('engagement', '');
			//localStorage.setItem(typeEnv,JSON.stringify({ engagement: '' }));
			needToRefreshAngularJs(false);
		}
	}

	return (
		<SelectFilter
			label={'Visitor Engagement'}
			onChange={handleChangeVisitorEngagement}
			onReset={onReset}
			value={visitorEngamentSelected}
			optionsList={listWithLabelAndId}
			hasResetButton={true}
		/>
	);
};


export default VisitorEngagementLevelFilter;