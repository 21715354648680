import React, { useState, useEffect } from 'react';
import { DeviceType, Template } from '../Context/TemplateTypes';
import { propertiesProcessing } from '../Utils/util.js';
import { TemplateProperty } from '../../../WysiwygEditor/Types/BespokeTypes';
import TemplateRender from '../../../WysiwygEditor/Components/TemplateRender';
import styles from '../CatalogueContainer.module.css';

type Props = {
  device: DeviceType;
  title: string | React.ReactElement;
  tags?: Array<any>;
  template?: Template;
  onClick?: any;
  isSelected?: boolean;
  isMinimized?: boolean;
  isStyle?: boolean;
  idStyle?: string;
	styleProperties?:  TemplateProperty[];
};

export default function TemplateDisplayThumbnail({
	device,
	template,
	onClick,
	title,
	tags,
	isSelected = false,
	isMinimized = false,
	isStyle = false,
	idStyle,
}: Props) {
	const { getLiquidModel, hasCountDowns, templatePropertyToStyleProperty } = propertiesProcessing();
	const [modelProperties, setModelProperties] = useState<TemplateProperty[]>([]);
	const [otherProperties, setOtherProperties] = useState({});
	const [liquidModel, setLiquidModel] = useState({});
	const [modelHasCountDowns, setModelHasCountDowns] = useState<boolean>(false);

	useEffect(() => {
		if (template) {
			if (idStyle === 'default') {
				// @ts-ignore
				setModelProperties([...template?.Properties.map(templatePropertyToStyleProperty) || []]);
			} else {
				// @ts-ignore
				const hasStylesAssociates = template.hasOwnProperty('stylesAssociates');
				if(isStyle && hasStylesAssociates){


					const findStyle = template.stylesAssociates.find(st => st.id === idStyle);
					if(findStyle){
						setModelProperties(findStyle.properties || []);
					}
				}else{
					const arrayProperties = [...template.Properties];
					setModelProperties(arrayProperties || []);
				}
			}
		}
	}, [template]);

	const refreshTemplateDisplay = () => {
		const values = getLiquidModel(modelProperties);
		const result = { ...values, ...otherProperties };
		setLiquidModel(result);
	};

	useEffect(() => {
		refreshTemplateDisplay();
		setModelHasCountDowns(hasCountDowns(modelProperties));
	}, [modelProperties, otherProperties]);

	useEffect(() => {
		if (template) {
			const getCssFromDescriptor = () => {
				const descriptor = device === DeviceType.Desktop ? template.SlideTemplateDescriptorDesktop : template.SlideTemplateDescriptorMobile;
				return descriptor && descriptor.Css;
			};
			const css = getCssFromDescriptor();
			css && setOtherProperties(x => ({ ...x, Css: css }));
		}
	}, [modelProperties, template]);

	// Periodically refresh countdowns
	const [seconds, setSeconds] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			setSeconds(seconds => seconds + 1);
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (modelHasCountDowns) {
			refreshTemplateDisplay();
		}
	}, [
		seconds,
		modelProperties]);

	const handleOnClick = () => {
		if (typeof onClick === 'function') {
			onClick();
		}
	};

	const wysiwygDeviceKey = `Wysiwyg${device === 'desktop' ? 'Desktop' : 'Mobile'}`;

	return (
		<>
			{(template && template.Properties) && (
				<div className={isSelected ? styles.catalogueItemSelected : styles.catalogueItem} onClick={handleOnClick}>
					<div className={device === 'desktop' ? styles.catalogueItemThumb__desktop : styles.catalogueItemThumb__mobile}>
						<TemplateRender
							device={device}
							formatType={template.Format?.id}
							html={template[wysiwygDeviceKey].Template || ''}
							className={styles.catalogueItemThumbIframe}
							liquidModel={liquidModel}
							inCatalog={true}
						/>
					</div>
					<div className={styles.catalogueItemInfos}>
						<div className={styles.catalogueItemTitle}>
							{isStyle && !isMinimized && <i className='fas fa-palette grey_4 s_13 mr_5'></i>}
							{title}
						</div>
						{!isMinimized && (
							<div className={styles.catalogueItemTagList}>
								{tags && tags.map((t, i) => (
									<span className={styles.catalogueItemTag} key={i}>{t.label}</span>
								))}
							</div>
						)}
						
					</div>
				</div>
			)}
		</>
	);
}