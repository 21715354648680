import React, {useEffect, useState,ReactElement} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SpinnerWheel from '../../../../Components/SpinnerWheel';

import mapDataWorld from '@highcharts/map-collection/custom/world.geo.json';
import {countries} from '../../util.js';
import { useGeoContext } from '../../context/GeolocContext.js';
import hcMap from 'highcharts/modules/map';
import proj4 from 'proj4';
import {DatasWorld, GeolocalisationObject,} from './GeolocalisationType';
import {useAnalyticsContext} from '../../context/AnalyticsContextProvider';
import {ChartByPoints} from './ChartByPoint';

hcMap(Highcharts);

declare global {
	interface Window {
		proj4: typeof proj4;
	}
}

window.proj4 = proj4; // Assign the proj4 library to the window object
const HighchartMapPointGeoloc = ({ data, isLoading }) :ReactElement=> {
	if (typeof window !== 'undefined') {
		window.proj4 = window.proj4 || proj4;
	}
	const {selectedTenant} = useAnalyticsContext();
	const {
		metricGeolocalisationSelected,
		world,
		setWorld
	} = useGeoContext();

	const [finalDataFormatted, setfinalDataFormatted] = useState<DatasWorld[]| []>(
		[]
	);

	const [dataToManipulate, setDataToManipulate] = useState([]);
	const [worldMapChart, setWorldMap] = useState();
	const [key, setKey] = useState(0);
	const [countryCode, setCountryCode] = useState<string | undefined >();
	const [isoName, setIsoName] = useState<string | undefined>();

	const getNameDataChart = (name:string | undefined):string  => {
		const dataName =  name !== undefined ? 'https://code.highcharts.com/mapdata/countries/' + name?.toLowerCase() + '/' + name?.toLowerCase() +'-all.geo.json' : 'https://code.highcharts.com/mapdata/custom/world.geo.json';
		setIsoName(name);
		setCountryCode(dataName);
		setWorld(false);
		return dataName;
	};

	useEffect(() => {
		if (world) {
			setCountryCode(undefined);
			setIsoName(undefined);
		}
	}, [world]);

	useEffect(() => {
		const timer = setTimeout(() => {
			const dataToManip = (data.length > 0) ? data : null;
			if(dataToManip){
				const dataWithValues = dataToManip.map((el) => {
					return {...el, value: el[metricGeolocalisationSelected.key]};
				});
				const newArrayOfoCountries: string[] = [];

				for (const property in countries) {
					newArrayOfoCountries.push(property);
				}
				const countryDataAggregateValues = dataWithValues.reduce((acc, point) => {
					acc[point.iso] = (acc[point.iso] || 0) + point.value;
					return acc;
				}, {});
				const dataWithAllCountries: DatasWorld[] = newArrayOfoCountries.map((ctr) => {
					const name = ctr.toLocaleLowerCase();
					const hasKeyInCountryData = countryDataAggregateValues[ctr];
					return [name, hasKeyInCountryData ? hasKeyInCountryData : 0];
				});

				setDataToManipulate(dataToManip);
				setfinalDataFormatted(dataWithAllCountries);

			}

		}, 50 );
		return () => {
			clearTimeout(timer);
		};
	}, [data,metricGeolocalisationSelected,selectedTenant]);


	useEffect(() => {
		const dataWithValues = dataToManipulate.map((el: GeolocalisationObject) => ({
			...el,
			value: el[metricGeolocalisationSelected.key],
		}));
		const countryData = dataWithValues.reduce((acc, point) => {
			if (point.iso) {
				acc[point.iso] = (acc[point.iso] || 0) + point.value;
			}
			return acc;
		}, {});
		const formattedCountryData = Object.entries(countryData).map(
			([iso, value]) => [iso, value]
		);

		const allValues = dataWithValues.map(o => o.value);
		const maxValue: number = Math.max(...allValues);


		const options:any = {

			chart: {
				// backgroundColor: 'transparent',
				map: mapDataWorld,
				animation: false,
				height: 500
			},
			title: {
				text: '',
			},
			credits: {
				enabled: false,
			},
			legend: {
				enabled: false
			},
			mapNavigation: {
				enabled: true,
				buttonOptions: {
					alignTo: 'spacingBox',
				},
			},
			colorAxis: {
				min: 0,
				max: maxValue,
				minColor: '#E0E9FD',
				maxColor: '#0032A0',
				stops: [
					[0, '#E0E9FD'],
					[1, '#0032A0'],
				],
				nullColor: '#f0f0f0',
			},
			tooltip: {
				shared: true,
				useHTML: true,
				formatter: function (this:any) {
					if (!this.point) return '';
					const s = `<b>${this.point.name || 'Unknown Name'}</b>: ${
						this.point.value || 'Loading ...'
					}<br/>`;
					return s;
				},
				backgroundColor: '#FFF',
				borderWidth: 0,
				opacity: 1,
				borderRadius: 10,
				style: {
					color: '#151E39',
					fontWeight: 'normal',
					fontSize: '12px',
					fontFamily: 'Roboto',
				}
			},
			plotOptions: {
				series: {
					shadow:false,
					cursor: 'pointer',
					point: {
						events: {
							click: function(this:any) {
								getNameDataChart(this['iso-a2']);
							}
						}
					}
				},
			},
			series:  [
				{
					name: metricGeolocalisationSelected.label,
					data: formattedCountryData,
					mapData: mapDataWorld,
					dataLabels: {
						enabled: true,
						color: '#FFFFFF',
						style: {
							fontWeight: 'bold'
						},

					},
					joinBy: ['iso-a2', 'hc-key'],
					// borderColor: '#FFF',
					// nullColor: '#E9EAF0',
					states: {
						hover: {
							color: '#2E90FA',
						},
					},
					showInLegend: false,
				}],
		};

		setWorldMap(options);
		setKey(prevKey => prevKey + 1);
		

	}, [finalDataFormatted,metricGeolocalisationSelected,dataToManipulate,countryCode]);

	return (
		<div>
			{isLoading && <SpinnerWheel />}
			{!isLoading && data.length > 0 &&
				<>
					{world && <HighchartsReact
						key={key}
						highcharts={Highcharts}
						constructorType={'mapChart'}
						options={worldMapChart}
					/>
					}
					{!world && <ChartByPoints
						isoName={isoName}
						countryCode={countryCode}
						dataToManipulate={dataToManipulate}
						metricGeolocalisationSelected={metricGeolocalisationSelected}
					/>}
				</>
			}
			{!isLoading &&  data.length === 0 &&
				<div className="empty_state analytics_empty_state">
					<img className="empty_state_img" src="/Assets/analytics_data_empty.svg"/>
					<div className="empty_state_title">No data for this period</div>
					<div className="empty_state_text">Maybe try with another period or other filters</div>
				</div>}
		</div>
	);
};


export default HighchartMapPointGeoloc;
