import React from 'react';
import angular from 'angular';
import { react2angular } from 'react2angular';
import { I18nextProvider } from 'react-i18next';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import IpAdressesServices from '../../../Services/IpAdressesServices';
import ReportingCampaignServices from '../../../Services/ReportingCampaignServices';
import SystemServices from '../../../Services/SystemServices';
import LabelServices from '../../../Services/LabelsServices';
import ScreenShotServices from '../../../Services/ScreenShotServices';
import { ContextProvider } from '../../Dashboard/OnSite/context';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import newInstanceI18next from '../../../Util/traductionUtils';
import OnSiteLayout from '../../../Auth/Dashboard/OnSite/layout/OnSiteLayout';
import OnSiteDashboardListContainer from '../../../Auth/Dashboard/OnSite/List/OnSiteDashboardListContainer';
import NotificationCreationButton from './NotificationCreationButton';
import NotificationCenterContextualLabels from './NotificationCenterContextualLabels';
import NotificationCenterList from './NotificationCenterList';

export default function NotificationCenterContainer(props) {
	const AuthServices = props.AuthServices;
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatingServices = new ImpersonatingServices(
		props.$rootScope,
		props.$routeParams
	);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const IpAdressesService = new IpAdressesServices(
		props.$http,
		props.AuthServices
	);
	const screenShotServices = new ScreenShotServices(
		props.$http,
		props.AuthServices
	);
	const ReportingCampaignService = new ReportingCampaignServices(
		props.$http,
		props.$routeParams
	);
	const httpLink = createHttpLink({
		uri: window.BEYABLE_env.URI_GRAPHQL,
	});
	// graphQl config
	const authLink = setContext((_, { headers }) => {
		// get the authentication token from local storage if it exists
		// return the headers to the context so httpLink can read them
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : '',
			},
		};
	});
	const defaultOptions = {
		watchQuery: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'ignore',
		},
		query: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'all',
		},
	};
	const client = new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache(),
		defaultOptions: defaultOptions,
	});

	const labelServices = new LabelServices(props.$http, props.$routeParams);
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const token = props.AuthServices && props.AuthServices.getAccessToken();
	const permissionsAccount = Object.keys(
		props.$rootScope.$$childHead.permissions
	).map((x) => ({
		Name: x,
		Value: props.$rootScope.$$childHead.permissions[x],
	}));

	return (
		<BrowserRouter>
			<I18nextProvider i18n={newInstanceI18next}>
				<ContextProvider
					AuthServices={AuthServices}
					$rootScope={props.$rootScope}
					$http={props.$http}
					$routeParams={props.$routeParams}
					$timeout={props.$timeout}
					ImpersonatingServices={impersonatingServices}
					IpAdressesServices={IpAdressesService}
					ReportingCampaignServices={ReportingCampaignService}
					screenShotServices={screenShotServices}
					systemServices={systemServices}
					labelServices={labelServices}
					accountId={accountId}
					token={token}
					permissionsAccount={permissionsAccount}
					client={client}
					isNotificationMode={true}
					technicalPurposes={['NOTIFICATION_CENTER_MESSAGE']}
					getContextualLabels={NotificationCenterContextualLabels}
				>
					<ApolloProvider client={client}>
						<OnSiteLayout
							header={
								<NotificationCenterList />
							}
							primaryAction={
								<NotificationCreationButton
									isAdmin={impersonatingServices.isAnAdmin()}
									$routeParams={props.$routeParams}
									permissionsAccount={permissionsAccount}
								/>
							}
							actionPosition="inner"
							minimalStatusSelector={true}
						>
							<Switch>
								<Route
									exact
									path="/NotificationCenter/Dashboard"
									component={OnSiteDashboardListContainer}
								/>
								<Route
									path="/NotificationCenter/Dashboard?ka=:ka&ku=:ku"
									component={OnSiteDashboardListContainer}
								/>
							</Switch>
						</OnSiteLayout>
					</ApolloProvider>
				</ContextProvider>
			</I18nextProvider>
		</BrowserRouter>
	);
}
angular
	.module('beyableSaasApp.NotificationCenter', [])
	.component('notificationcentercontainer', react2angular(NotificationCenterContainer, [], ['$http', '$rootScope', '$routeParams', 'AuthServices']));