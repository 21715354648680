import React, {Children, cloneElement, Fragment} from 'react';
import styles from './PageBreadcrumb.module.css';

function PageBreadcrumb(props) {
	
	const arrayChildren = Children.toArray(props.children);

	return (
		<ul {...props} className={styles.list}>
			{Children.map(arrayChildren, (child, i) => 
				<Fragment key={i}>
					{i !== 0 &&
						<li className={styles.chevron}>
							<i className='fas fa-chevron-right fa-fw' />
						</li>
					}
					<li className={styles.item}>
						{cloneElement(child)}
					</li>
				</Fragment>
			)}
		</ul>
	);
}

export {
	PageBreadcrumb
};
