import React from 'react';
import { react2angular } from 'react2angular';
import EmailRemarketingProvider from '../context/contextEmailRemarketing';
import angular from 'angular';
import {HeaderDashboard} from './HeaderDashboard';
import ListEmailRemarketing from './ListEmailRemarketing';
export const ContainerDashBoard = (props) => {
	return (
		<>
			<EmailRemarketingProvider
				$http={props.$http}
				$routeParams={props.$routeParams}
				AuthServices={props.AuthServices}
				$timeout={props.$timeout}
				$location={props.$location}
				$rootScope={props.$rootScope}
			>
				<div className="page_wrapper">
					<HeaderDashboard></HeaderDashboard>
					<ListEmailRemarketing {...props}></ListEmailRemarketing>
				</div>

			</EmailRemarketingProvider>
		</>
	);
};
angular.module('beyableSaasApp.ReportingEmailingRemarketing', [])
	.component('emailremarketing', react2angular(ContainerDashBoard, [], ['$http', '$rootScope', '$routeParams', '$location', 'AuthServices', '$timeout']));
