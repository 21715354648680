import React from 'react';
import ReactTooltip from 'react-tooltip';

const Btn = React.forwardRef(({
	children,
	type = 'button',
	htmlTag = 'button',
	onClickFunction,
	onClick,
	onMouseDown,
	href = null,
	target = '',
	message = '',
	color = 'primary',
	style = '',
	inlineStyle,
	rounded = false,
	disabled = false,
	className = '',
	strong = false,
	light = false,
	isActive = false,
	isLoading = false,
	size = '',
	horizontalSize = '',
	fontSize = '',
	fullWidth = false,
	fullHeight = false,
	icon,
	iconPosition = 'before',
	arrow = false,
	tooltip,
	tooltipHTML,
	doInputHug = false,
	dataFor, // deprecated
	dataTip // deprecated
}, ref) => {

	const click = (ev) => {
		if (disabled) return;

		if (typeof onClickFunction === 'function') {
			onClickFunction(ev);
		}
		if (typeof onClick === 'function') {
			onClick(ev);
		}
	};

	let cssClass = ['btnUi'];

	if (className) {
		cssClass = cssClass.concat(className);
	}

	// Style and Color
	let styleClass = 'btn';
	if (
		style === 'outline'
		|| style === 'ghost'
		|| style === 'ghost_outline'
		|| style === 'reverse'
		|| style === 'reverse_outline'
		|| style === 'link'
	) {
		styleClass += '_' + style;
	}
	styleClass += '_' + color;
	cssClass.push(styleClass);

	// Rounded
	if (rounded) {
		cssClass.push('btn_rounded');
	}

	// Disabled
	if (disabled) {
		cssClass.push('btn_disabled');
	}

	// Loading
	if (isLoading) {
		cssClass.push('btn_loading');
	}

	// Strong
	if (strong) {
		cssClass.push('btn_strong');
	}

	// Light
	if (light) {
		cssClass.push('btn_light');
	}

	// Arrow
	if (arrow) {
		cssClass.push('has_arrow');
	}

	// Size : xs, s, default, l, xl, xxl
	if (size) {
		cssClass.push('btn_' + size);
	}

	// Horizontale size : xs, s, default, l
	if (horizontalSize) {
		cssClass.push('btn_w_' + horizontalSize);
	}

	// Font size : s
	if (fontSize) {
		cssClass.push('btn_fs_' + fontSize);
	}

	// Icon
	if (icon) {
		cssClass.push('has_icon');
	}

	// Full width
	if (fullWidth) {
		cssClass.push('w_full');
	}

	// Full height
	if (fullHeight) {
		cssClass.push('h_full');
	}

	// Active
	if (isActive) {
		cssClass.push('active');
	}

	if (doInputHug) {
		cssClass.push('do_input_hug');
	}

	// Type
	if (href) {
		htmlTag = 'a';
	}

	return (
		<>
			{htmlTag == 'button' &&
				<button
					type={type}
					className={cssClass.join(' ')}
					onClick={(e) => click(e)}
					onMouseDown={onMouseDown}
					data-tip={tooltipHTML ? 'a' : tooltip || dataTip}
					style={inlineStyle}
					ref={ref}>
					{icon && iconPosition !== 'after' &&
						<i className={'btn_first_icon ' + icon}></i>
					}
					{message &&
						<span className="btn_txt">{message}</span>
					}
					{children &&
						<span className="btn_txt">{children}</span>
					}
					{icon && iconPosition === 'after' &&
						<i className={'btn_last_icon ' + icon}></i>
					}
					{isLoading &&
						<span className="btn_spinner">
							<svg width="1em" height="1em" stroke="currentColor" viewBox="0 0 24 24">
								<g>
									<circle cx="12" cy="12" r="9.5" fill="none" strokeWidth="3" strokeLinecap="round">
										<animate attributeName="stroke-dasharray" dur="1.5s" calcMode="spline" values="0 150;42 150;42 150;42 150" keyTimes="0;0.475;0.95;1" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" repeatCount="indefinite"/>
										<animate attributeName="stroke-dashoffset" dur="1.5s" calcMode="spline" values="0;-16;-59;-59" keyTimes="0;0.475;0.95;1" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" repeatCount="indefinite"/>
									</circle>
									<animateTransform attributeName="transform" type="rotate" dur="2s" values="0 12 12;360 12 12" repeatCount="indefinite"/>
								</g>
							</svg>
						</span>
					}

					{tooltip &&
						<ReactTooltip
							backgroundColor="black"
							effect="solid"
							place="bottom"
							globalEventOff="mouseup"
							delayShow={600}
						/>
					}
					{tooltipHTML &&
						<ReactTooltip
							backgroundColor="black"
							effect="solid"
							place="bottom"
							globalEventOff="mouseup"
							delayShow={600}
							getContent={function() {
								return tooltipHTML;
							}}
						/>
					}
				</button>
			}
			{htmlTag == 'a' &&
				<a
					className={cssClass.join(' ')}
					onClick={(e) => click(e)}
					onMouseDown={onMouseDown}
					target={target}
					href={disabled ? null : href}
					data-tip={tooltipHTML ? 'a' : tooltip || dataTip}
					style={inlineStyle}
					ref={ref}>
					{icon && iconPosition !== 'after' &&
						<i className={'btn_first_icon ' + icon}></i>
					}
					{message &&
						<span className="btn_txt">{message}</span>
					}
					{children &&
						<span className="btn_txt">{children}</span>
					}
					{icon && iconPosition === 'after' &&
						<i className={'btn_last_icon ' + icon}></i>
					}
					{tooltip &&
						<ReactTooltip
							backgroundColor="black"
							effect="solid"
							place="bottom"
							globalEventOff="click"
							delayShow={600}
						/>
					}
					{tooltipHTML &&
						<ReactTooltip
							backgroundColor="black"
							effect="solid"
							place="bottom"
							globalEventOff="click"
							delayShow={600}
							getContent={function() {
								return tooltipHTML;
							}}
						/>
					}
				</a>
			}
		</>
	);
});

Btn.displayName = 'Btn';

export default React.memo(Btn);
