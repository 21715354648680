import React, { useState, useEffect } from 'react';
import ControlPannelContainer from './ControlPannelContainer';
import Dropdown from '../../Components/Dropdown';
import Btn from '../../Components/Btn';
import Listbox from '../../Components/Listbox';
import ListboxItem from '../../Components/ListboxItem';
import ChartPerformCampaign from './ChartPerformCampaign';
import ResultsCampaignContainer from './Results/ResultsCampaignContainer';
import { Trans, useTranslation } from 'react-i18next';
import Nav from './Nav/Nav';
import moment from 'moment';


const onSiteContainer = (props) => {

	const [t, i18n] = useTranslation('reporting');
	const [initialDates, setInitialDates] = useState({
		fromDate: null,
		toDate: null,
		initialLabel: null
	});

	function handleClickOriginal(obj) {
		props.handleOnDateChange({
			startDate: moment(obj.fromDate),
			endDate: moment(obj.toDate)
		},{
			startDate: moment(obj.fromDate),
			endDate: moment(obj.toDate)
		});
	}

	useEffect(() => {
		if (props && props.startDate !== undefined && props.endDate !== undefined) {
			setInitialDates({
				fromDate: props.startDate,
				toDate: props.endDate,
				initialLabel: props.initialLabel
			});
		}
	}, [props.startDate, props.endDate, props.initialLabel]);

	return (
		<> {props.activeLink === 'onSite' &&
		<div >
			<div className="reporting_filters flex">
				<div className="flex_item_full">
					<div className="btn_group_l">
						<Nav
							datainformation={props.datainformation}
							handleClick={handleClickOriginal}
							initialDates={initialDates}
							setisCustomSelected={props.setisCustomSelected}
							isCustomSelected={props.isCustomSelected}
							customDateRange={props.customDateRange}
							setCustomDateRange={props.setCustomDateRange}
							activeLink={props.activeLink}
						/>

						<ControlPannelContainer
							changeDevice={props.changeDevice}
							loadAllInformationCampaign={props.loadAllInformationCampaign}
							loadSecondIndicator={props.loadSecondIndicator} device={props.device}
							datainformation={props.dataObjectiveCampaign && props.dataObjectiveCampaign}
						/>
						{props.hasVariations &&
							<>
								<Dropdown
									isOpen={props.abtestDropdownIsOpen}
									setIsOpen={(e) => props.setAbtestDropdownIsOpen(true)}
									onHide={(e) => props.setAbtestDropdownIsOpen(false)}
									button={
										<button className='custom_input clickable w_auto m bg_white'>
											<span className="custom_input_value has_label">
												<span className="custom_input_value_label">A/B Test</span>
												<span className="custom_input_placeholder">
													{props.variationSelectedLabel ? props.variationSelectedLabel : ''}
												</span>
											</span>
											<span className='custom_input_arrow'></span>
										</button>
									}>
									<Listbox>
										{props.arrayOfVariations && props.arrayOfVariations.map((x, i) =>
											<ListboxItem
												key={i}
												message={x.label}
												selected={props.variationSelected === x.value}
												onClick={(e) => { props.handleChange(e, x); props.setAbtestDropdownIsOpen(false); }}
											/>
										)}
									</Listbox>
								</Dropdown>
							</>
						}
					</div>
				</div>
				<div className="flex_item_fix">
					<Dropdown
						isOpen={props.frequencyDropdownIsOpen}
						setIsOpen={(e) => props.setFrequencyDropdownIsOpen(true)}
						onHide={(e) => props.setFrequencyDropdownIsOpen(false)}
						button={
							<button className='custom_input clickable w_auto m bg_white'>
								<span className="custom_input_value has_label">
									<span className="custom_input_value_label">{t('ChartPerformCampaign.Granularity')}</span>
									<span className="custom_input_placeholder">
										{props.granularity == 'day' && t('ChartPerformCampaign.Day')}
										{props.granularity == 'week' && t('ChartPerformCampaign.Week')}
										{props.granularity == 'month' && t('ChartPerformCampaign.Month')}
									</span>
								</span>
								<span className='custom_input_arrow'></span>
							</button>
						}>
						<Listbox>
							<ListboxItem
								message={t('ChartPerformCampaign.Day')}
								selected={props.granularity == 'day'}
								onClick={() => props.changeGranularityManually('day')}
							/>
							<ListboxItem
								message={t('ChartPerformCampaign.Week')}
								selected={props.granularity == 'week'}
								onClick={() => props.changeGranularityManually('week')}
							/>
							<ListboxItem
								message={t('ChartPerformCampaign.Month')}
								selected={props.granularity == 'month'}
								onClick={() => props.changeGranularityManually('month')}
							/>
						</Listbox>
					</Dropdown>
				</div>
			</div>

			<div className="reporting_body">
				<div className="">
					{!props.isLoadingInformationsCampaign ?
						<ChartPerformCampaign
							loadIndicators={props.loadIndicators}
							device={props.device}
							updateInteractionIndicator={props.updateInteractionIndicator}
							searchIndicators={props.searchIndicators}
							systemServices={props.systemServices}
							dataInteractionIndicators={props.dataInteractionIndicators && props.dataInteractionIndicators}
							setDataInteractionIndicators={(e) => props.setDataInteractionIndicators(e)}
							interactionsIndicators={props.interactionsIndicators && props.interactionsIndicators}
							momentStartDate={props.momentStartDate}
							momentEndDate={props.momentEndDate}
							hasVariations={props.hasVariations}
							variationSelected={props.variationSelected}
							granularity={props.granularity}
							allIndicatorsofCampaign={props.newArrayOfIndicators && props.newArrayOfIndicators}
							campaignId={props.campaignId}
						/> :
						<div style={{ height: '300px' }}>
							{/* when data is reloading after click on new campaign id to keep the size of the graph in the view */}
						</div>
					}
				</div>
				<div className="">
					<ResultsCampaignContainer
						loadSecondIndicator={props.loadSecondIndicator}
						device={props.device}
						searchIndicators={props.searchIndicators}
						getAllSecondaryIndicators={props.getAllSecondaryIndicators}
						systemServices={props.systemServices} updateSummaryIndicators={props.updateSummaryIndicators}
						datainformation={props.dataObjectiveCampaign && props.dataObjectiveCampaign}
						SummaryFirstIndicators={props.SummaryFirstIndicators}
						DataPrimaryIndicators={props.DataPrimaryIndicators}
						DataSecondaryIndicators={props.DataSecondaryIndicators && props.DataSecondaryIndicators}
						allIndicatorsofCampaign={props.newArrayOfIndicators && props.newArrayOfIndicators}
						interactionsIndicators={props.interactionsIndicators && props.interactionsIndicators}
						accountId={props.accountId}
						campaignId={props.campaignId}
						momentStartDate={props.momentStartDate}
						momentEndDate={props.momentEndDate}
						hasVariations={props.hasVariations}
						variationSelected={props.variationSelected}
						targetIndicators={props.targetIndicators}
						isLoadingInformationsCampaign={props.isLoadingInformationsCampaign}
						activeLink={props.activeLink}
						isAdmin={props.isAdmin}
						handleGetCampaignConversion={props.handleGetCampaignConversion}
					/>
				</div>
			</div>
		</div>}
		</>
	);
};
export default onSiteContainer;