import HttpServices from './HttpServices';
import axios from 'axios';
export default class InsightsServices {
	constructor($http, $routeParams,authServices) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
		let token = authServices && authServices.getAccessToken();
		this.config = {
			headers: {}
		};
		if (token) {
			this.config.headers.Authorization = 'Bearer ' + token;
		}
	}
	getInsightsCampaign(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}kpi/${accountId}/campaign?period=last7Days`, callbackSuccess, callbackError);
	}
	getInsightsActivated(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}account/${accountId}/dashboard`, callbackSuccess, callbackError);
	}
	putInsightsActivated(accountId, data,callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}account/${accountId}/dashboard`, data,callbackSuccess, callbackError);
	}
	getInsightsKpis(accountId,signal) {
		return axios.get(`${this.BO_API_URL}kpi/${accountId}/global/`,
			{
				headers: this.config.headers, 'Content-Type': 'multipart/form-data',
			},signal
		);
		// this.HttpServices.get(`${this.BO_API_URL}kpi/${accountId}/global/`, callbackSuccess, callbackError,null,signal);
	}
	getEcommerceInsights(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}kpi/${accountId}/ecommerce/favorites`, callbackSuccess, callbackError);
	}
	getAvailableFormats(accountId, callbackSuccess, callbackError,needEditor = false) {
		let editor = '';
		if(needEditor){
			editor = '?editor=targetedMessage/library';
		}

		this.HttpServices.get(`${this.BO_API_URL}displaylibrary/bespoketemplates/${accountId}${editor}`, callbackSuccess, callbackError);
	}
	getAvailableFormatsCampaignsCount(accountId, startDate, endDate, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}displaylibrary/bespoketemplates/${accountId}/campaignsCount?startDate=${startDate}&endDate=${endDate}`, callbackSuccess, callbackError);
	}
	getEcommerceDataByDate(accountId,period, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}kpi/${accountId}/ecommerce?period=${period}`, callbackSuccess, callbackError);
	}
	getWebtrafficSumOvertime(accountId,payload, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}kpi/${accountId}/global/metricssumsovertime`, payload,callbackSuccess, callbackError);
	}
	getWebtrafficMetricMetricovertime(accountId,payload, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}kpi/${accountId}/global/metricovertime`, payload,callbackSuccess, callbackError);
	}
	getWebtrafficMetrics(accountId,payload, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}kpi/${accountId}/global/metrics`, payload,callbackSuccess, callbackError);
	}
	getEcommerceSumOvertime(accountId,payload, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}kpi/${accountId}/ecommerce/metricssumsovertime`, payload,callbackSuccess, callbackError);
	}
	getEcommerceMetricMetricovertime(accountId,payload,signal) {
		return axios.post(`${this.BO_API_URL}kpi/${accountId}/ecommerce/metricovertime`,
			payload,
			{
				headers: this.config.headers, 'Content-Type': 'multipart/form-data',
			},signal
		);
		// this.HttpServices.post(`${this.BO_API_URL}kpi/${accountId}/ecommerce/metricovertime`, payload,callbackSuccess, callbackError);
	}
	getEcommerceMetrics(accountId,payload, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}kpi/${accountId}/ecommerce/metrics`, payload,callbackSuccess, callbackError);
	}



}