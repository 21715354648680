import React from 'react';
import EditorTemplate from './Editor/EditorTemplate';
import {PanelTemplateSelected} from './DashBoard/PanelTemplateSelected';
import newInstanceI18next from '../../../Util/traductionUtils.js';
import { I18nextProvider } from 'react-i18next';
import {NavFormat} from './NavFormat';
import {IframeContainer} from './Editor/IframeContainer';
import {MenuList} from '../../Menu/MenuItem';
import {useCatalogueEditorContext} from './Context/CatalogueContextProvider';
import { useTranslation } from 'react-i18next';
import styles from './CatalogueContainer.module.css';
import {
	iconLight,
	iconCustomTemplate,
	iconTemplate
} from '../../../Components/CustomIcon/CustomIcon';

export default function CatalogueContainer() {
	return (
		<I18nextProvider i18n={newInstanceI18next}>
			<CatalogueContainerInner />
		</I18nextProvider>
	);
}


function CatalogueContainerInner() {

	const [t] = useTranslation('inspiration');
	const {
		mode,
		handleSelectMode,
		objectivesForMenu,
		setPanelIsOpen
	} = useCatalogueEditorContext();

	const handleObjectifClick = (objId) => {
		const el = document.querySelector('.js-catalog-group-' + objId);
		if (el) el.scrollIntoView({ behavior: 'smooth'});
		setPanelIsOpen(false);
	};

	const handleReadyClick = (newMode) => {
		const el = document.querySelector('.js-catalog-view');
		if (el) el.scrollTo({top: 0, behavior: 'smooth'});
		handleSelectMode(newMode);
		setPanelIsOpen(false);
	};

	const objectivesForList = objectivesForMenu.map((o) => {
		return {
			label: t('objectives.' + o.id + '.label'),
			onClick: () => handleObjectifClick(o.id),
			isDisabled: o.nbItems === 0
		};
	});

	const menuItems = [
		{
			label: t('templateLibrary'),
			onClick: () => handleReadyClick('templates'),
			isActive: mode === 'templates',
			customIcon: iconTemplate,
			items: objectivesForList,
			isControlled: true,
			skeletonNumber: 6
		},
		{
			label: t('myCustomTemplates'),
			onClick: () => handleSelectMode('custom'),
			isActive: mode === 'custom',
			customIcon: iconCustomTemplate
		},
		// {
		// 	label: t('styleLibrary'),
		// 	onClick: () => handleReadyClick('library'),
		// 	isActive: mode === 'library',
		// 	customIcon: iconPerformance,
		// 	items: objectivesForList,
		// 	isControlled: true
		// },
		{
			label: t('realizations'),
			onClick: () => handleSelectMode('realizations'),
			isActive: mode === 'realizations',
			customIcon: iconLight
		},
	];

	return (
		<>
			<div className={[styles.sidebar, 'vscroll'].join(' ')}>
				<MenuList items={menuItems} />
			</div>
			<div className={styles.main}>
				<div className={[styles.view, 'vscroll', 'js-catalog-view'].join(' ')}>
					<NavFormat />
					<EditorTemplate />
					<IframeContainer src={'https://catalogue.beyable.com/'} />
				</div>
				<PanelTemplateSelected />
			</div>
		</>
	);
}



