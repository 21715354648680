import React, {useState, useCallback, useEffect, Fragment} from 'react';
import { Skeleton } from '../../../../Components/Skeleton/Skeleton';
import { ProductThumb } from '../../../../Components/Product/ProductThumb';
import { ProductScore } from '../../../../Components/Product/ProductScore';
import { getFormattedNumber } from '../../../../Util/numberFormats';
import styles from './TopProductsMinify.module.css';

export const TopProductsMinify = ({
	products,
	isLoading,
	onClick
}) => {

	const [needAfterIndicator, setNeedAfterIndicator] = useState<boolean>(false);
	const [needBeforeIndicator, setNeedBeforeIndicator] = useState<boolean>(false);

	const handleScroll = (target) => {
		const scrollEnd = target.offsetWidth + target.scrollLeft >= target.scrollWidth;
		setNeedAfterIndicator(!scrollEnd);
		setNeedBeforeIndicator(target.scrollLeft > 0);
	};

	const scrollRef = useCallback((node: HTMLDivElement) => {
		if (node !== null) {
			handleScroll(node);
		}
	}, []);

	const repeat = (jsx:React.ReactNode, nb:number) => {
		const result:React.ReactNode[] = [];
		for (let i = 0; i < nb; i++) {
			result.push(
				<Fragment key={i}>{jsx}</Fragment>
			);
		}
		return result;
	};

	return (
		<>
			<div className={[styles.list, needAfterIndicator ? styles.list_has_after_shadow : '', needBeforeIndicator ? styles.list_has_before_shadow : ''].join(' ')}>
				{isLoading && 
					<div className={styles.track}>
						{repeat(
							<div className={styles.item_empty}>
								<Skeleton appearance="entity" />
							</div>
							, 4
						)}
					</div>
				}
				{!isLoading && products && products.length > 0 &&
					<div className={styles.track} ref={scrollRef} onScroll={(ev) => handleScroll(ev.target)}>
						{products.map((p, i) =>
							<TopProductsMinifyItem
								key={p.AssociatedProduct.Id + '_' + i}
								product={p}
								onClick={onClick}
							/>
						)}
					</div>
				}
			</div>
		</>
	);
};

const TopProductsMinifyItem = ({
	product,
	onClick
}) => {

	const [score, setScore] = useState(Math.round(product.Score * 100));
	const [uplift, setUplift] = useState(0);

	useEffect(() => {
		const score1 = Math.round(product.Score.ValueReference * 100);
		const score2 = Math.round(product.Score.ValueComparison * 100);
		setScore(score2);
		setUplift(score2 - score1);
	}, [product]);

	function getUpliftColor(uplift) {
		if (uplift == 0) return 'c_warning';
		if (uplift > 0) return 'c_success';
		return 'c_alert';
	}

	return (
		<div className={[styles.item, styles.item_clickable].join(' ')} onClick={onClick}>
			<div className={styles.item_col_img}>
				<ProductThumb
					width={50}
					height={'auto'}
					url={product.AssociatedProduct.Thumbnail}
					alt={product.AssociatedProduct.Name}
				/>
			</div>
			<div className={styles.item_col_info}>
				<div className='s_13 fw_medium grey_2'>{product.AssociatedProduct.Id}</div>
				<div className={styles.item_name}>{product.AssociatedProduct.Name}</div>
				<div className={styles.item_score}>
					<ProductScore
						value={score}
						displayValue={getFormattedNumber(score)}
						display='block'
					/>
				</div>
			</div>
			<div className={styles.item_col_nb}>
				<div className={styles.item_nb}>
					<div className={getUpliftColor(uplift)}>
						{getFormattedNumber(uplift, 0, '', true)}
					</div>
				</div>
			</div>
		</div>
	);
};
