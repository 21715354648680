import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Confirm from '../../../../Components/Confirm';
import Btn from '../../../../Components/Btn';
import IconBtn from '../../../../Components/IconBtn';
import Modal from '../../../../Components/Modal';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalBody from '../../../../Components/ModalBody';
import ModalFooter from '../../../../Components/ModalFooter';
import NumericUpDown from '../../../../Components/NumericUpDown';
import EmptyState from '../../../../Components/EmptyState';
import Picto from '../../../../Components/Picto';
import SectionMessage from '../../../../Components/SectionMessage';
import { List, ListItem } from '../../../../Components/List';
import { Article } from '../../../../Components/Article/Article';
import RuleComponent from './RuleComponent';
import styles from './RuleComposition.module.css';
import { ComposableGroupItem, CompositionGroup } from '../context/EntityTypes';
import { useRuleCompositionContext } from '../context/RuleCompositionContextProvider';
import { listRulesByType, RuleType } from '../context/RuleType';
import { iconAbandonedCart, iconHistory, iconRecommendation } from '../../../../Components/CustomIcon/CustomIcon';

type RuleCompositionGroupComponentProps = {
	ruleCompositionGroup: CompositionGroup,
	nbCompositionGroups: number,
	groupIndex: number,
	isDragging?: boolean,
	displayMode?: string,
	dragProvided?: any
}

export default function RuleCompositionGroup({
	ruleCompositionGroup,
	nbCompositionGroups,
	groupIndex,
	dragProvided
}: RuleCompositionGroupComponentProps) {

	const {
		handleAddRuleToGroup,
		loadPossibleRules,
		handleRulesOrderChanged,
		handleChangeGroupSize,
		handleDeleteGroup,

	} = useRuleCompositionContext();

	const animatedComponents = makeAnimated();

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;
		const items = Array.from(ruleCompositionGroup.rules);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		handleRulesOrderChanged(ruleCompositionGroup, items);
	};

	const [openModalToAddRule, setOpenModalToAddRule] = useState<boolean>(false);
	const [confirmDeleteGroup, setConfirmDeleteGroup] = useState<boolean>(false);

	const [listOfRules, setListOfRules] = useState<ComposableGroupItem[]>([]);
	const [ruleToAdd, setRuleToAdd] = useState<ComposableGroupItem | null>();
	const [ruleType, setRuleType] = useState<string | undefined>();
	const [loadingListOfRules, setLoadingListOfRules] = useState<boolean>(false);
	const [listRulesTypesStatic, setListRulesTypesStatic] = useState<RuleType[]>([]);
	const [listRulesTypesPerso, setListRulesTypesPerso] = useState<RuleType[]>([]);
	const [modalWidth, setModalWidth] = useState<number>(700);

	const handleProductCountChanged = (e) => {
		const val = e.target.value;
		handleChangeGroupSize(ruleCompositionGroup, val);
	};
	const handleChooseTypeRule = (type: string) => {
		const selectedRuleType = listRulesByType.find(r => r.value === type);
		if (selectedRuleType === undefined) return;
		if (selectedRuleType.isPersoRule) {
			setRuleType(undefined);
			setOpenModalToAddRule(false);
			const ruleN: ComposableGroupItem = {
				actualRuleId: null,
				internalId: uuidv4(),
				persoRuleType: selectedRuleType.PersoRuleType,
				name: selectedRuleType.label,
				ruleLimit: 0,
				isFillUp: false,
				isPersoRule: true,
			};
			handleAddRuleToGroup(ruleCompositionGroup, ruleN);

		} else {
			setRuleType(selectedRuleType.value);
		}
	};
	useEffect(() => {
		if (!openModalToAddRule) return;

		setLoadingListOfRules(true);
		loadPossibleRules(x => {
			setListOfRules(x);
			setLoadingListOfRules(false);
		});

	}, [openModalToAddRule]);

	useEffect(() => {
		if (listRulesByType) {
			const tmpStatic = listRulesByType.filter(t => t.isPersoRule == false);
			const tmpPerso = listRulesByType.filter(t => t.isPersoRule == true);
			setListRulesTypesStatic(tmpStatic);
			setListRulesTypesPerso(tmpPerso);
		}
	}, [listRulesByType]);

	useEffect(() => {
		const width = ruleType ? 500 : 700;
		setModalWidth(width);
	}, [openModalToAddRule, ruleType]);

	function getRuleTypeIcon(iconName) {
		if (iconName === 'abandonedCart') return iconAbandonedCart;
		if (iconName === 'history') return iconHistory;
		return iconRecommendation;
	}

	return (
		<>
			<div className={nbCompositionGroups > 1 ? styles.group_multiple : styles.group_simple}>
				{nbCompositionGroups > 1 &&
					<div className={styles.group_header}>
						<div className={styles.group_header_left}>
							<div className={styles.group_header_title}>Group {groupIndex + 1}</div>
						</div>
						<div className={styles.group_header_center}>
							<span {...dragProvided.dragHandleProps} className={styles.group_header_handle}>
								<svg xmlns="http://www.w3.org/2000/svg" width="25" height="11" viewBox="0 0 25 11" fill="currentColor" className={styles.group_header_handle_svg}>
									<circle cx="2" cy="2" r="2" />
									<circle cx="2" cy="2" r="2" transform="translate(7 0)" />
									<circle cx="2" cy="2" r="2" transform="translate(14 0)" />
									<circle cx="2" cy="2" r="2" transform="translate(21 0)" />
									<circle cx="2" cy="2" r="2" transform="translate(0 7)" />
									<circle cx="2" cy="2" r="2" transform="translate(7 7)" />
									<circle cx="2" cy="2" r="2" transform="translate(14 7)" />
									<circle cx="2" cy="2" r="2" transform="translate(21 7)" />
								</svg>
							</span>
						</div>
						<div className={styles.group_header_right}>
							<IconBtn
								tooltip="Delete group"
								onClick={() => setConfirmDeleteGroup(true)}
								icon="fas fa-trash"
								hoverColor="alert"
								size="s"
							/>
						</div>
					</div>
				}

				<div className={styles.group_body}>
					{ruleCompositionGroup.rules?.length === 0 &&
						<EmptyState
							title="Add a first rule"
							text="Combine multiple strategies within the same group"
							textSize="s"
							verticalSize="s"
							primaryAction={
								<Btn
									message="Add rule"
									onClick={() => setOpenModalToAddRule(true)}
									style="outline"
									icon="fas fa-plus-circle fa-sm"
								/>
							}
						/>
					}
					{ruleCompositionGroup.rules?.length > 0 &&
						<>
							<div className={styles.group_body_head}>
								<div className={styles.group_body_left}>
									Rules
								</div>
								<div className={styles.group_body_right}>
									Nb. of products
								</div>
							</div>
							<DragDropContext onDragEnd={handleOnDragEnd}>
								<Droppable droppableId="rule-id" direction="vertical">
									{(provided) => (
										<div {...provided.droppableProps} ref={provided.innerRef}>
											{ruleCompositionGroup.rules.map((rule, index) =>
												<Draggable key={rule.internalId} draggableId={rule.internalId.toString()} index={index}>
													{(provided, snapshot) => (
														<div ref={provided.innerRef} {...provided.draggableProps}>
															<RuleComponent
																isDragging={snapshot.isDragging}
																rule={rule}
																ruleCompositionGroup={ruleCompositionGroup}
																displayMode='drag'
																dragProvided={provided}
															/>
														</div>
													)}
												</Draggable>
											)}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
							{ruleCompositionGroup.hasFillUpRule() &&
								<div className={styles.group_body_total}>
									<div className="flex">
										<div className='flex_item_full'>
											<div className='fw_medium'>Total number of products</div>
										</div>
										<div className="flex_item_fix ml_20">
											<NumericUpDown
												value={ruleCompositionGroup.size || 0}
												onChange={handleProductCountChanged}
												canEdit={true}
												placeholder="2"
												min={0}
												isAnError={ruleCompositionGroup.error?.message}
											/>
										</div>
									</div>
								</div>
							}
							<div className={styles.group_body_add}>
								<Btn
									message="Add rule"
									onClick={() => setOpenModalToAddRule(true)}
									style="outline"
									icon="fas fa-plus-circle fa-sm"
								/>
							</div>
						</>
					}
				</div>
			</div>
			<Modal
				isOpen={openModalToAddRule}
				width={modalWidth}
				onClose={() => {setRuleType(undefined); setOpenModalToAddRule(false);}}
			>
				<ModalHeader
					title="Add rule"
				/>
				<ModalBody>
					{loadingListOfRules
						&& <div className="mask">
							<span className="wheel"></span>
						</div>
					}

					{!ruleType &&
						<>
							<Article hasPadding={false} title={'Recommendation'}>
								<List size="l" fontSize="l">
									{!ruleType && listRulesTypesStatic.map((ruleType, i) =>
										<ListItem
											picto={
												<Picto
													size="xl"
													iconCustom={getRuleTypeIcon(ruleType.pictoIcon)}
													color={ruleType.pictoColor}
												/>
											}
											pictoHasMargin={true}
											key={i}
											text={ruleType.label}
											description={ruleType.description}
											onClick={() => handleChooseTypeRule(ruleType.value)}
											hasArrow={true}
										/>
									)}
								</List>
							</Article>
							<Article
								hasPadding={false}
								title={'Visitor history'}
								prependMessage={
									<SectionMessage
										text={
											<>
												<p>Rules based on visitor history are available only in the following modes:</p>
												<ul>
													<li>Recommendation by email</li>
													<li>Recommendation by API</li>
												</ul>
											</>
										}
										noMargin={true}
									/>
								}
							>
								<List size="l" fontSize="l">
									{!ruleType && listRulesTypesPerso.map((ruleType, i) =>
										<ListItem
											picto={
												<Picto
													size="xl"
													iconCustom={getRuleTypeIcon(ruleType.pictoIcon)}
													color={ruleType.pictoColor}
												/>
											}
											pictoHasMargin={true}
											key={i}
											text={ruleType.label}
											description={ruleType.description}
											onClick={() => handleChooseTypeRule(ruleType.value)}
											hasArrow={true}
										/>
									)}
								</List>
							</Article>
						</>
					}

					{ruleType && ruleType === 'DynamicCollection' &&
						<>
							<label htmlFor="rule">Choose a rule</label>
							<Select
								closeMenuOnSelect={true}
								components={animatedComponents}
								defaultValue={{
									value: 'choose',
									label: 'Select an option',
								}}
								menuPortalTarget={document.body}
								styles={{ menuPortal: base => ({ ...base, zIndex: 20000 }) }}
								menuPosition={'fixed'}
								options={listOfRules && listOfRules.map(el => {
									return {
										value: el.actualRuleId,
										label: el.name,
										id: el.actualRuleId
									};
								})}
								placeholder={'Search a rule'}
								onChange={(e) => setRuleToAdd({
									actualRuleId: e.id,
									internalId: uuidv4(),
									name: e.label,
									ruleLimit: 0,
									isFillUp: false,
									isPersoRule: false,
									persoRuleType: null
								})}
							/>
						</>
					}
				</ModalBody>

				{ruleType && ruleType === 'DynamicCollection' &&
					<ModalFooter
						primaryAction={
							<Btn
								onClick={ruleToAdd !== null ? () => {
									setOpenModalToAddRule(false);
									setRuleType(undefined);
									handleAddRuleToGroup(ruleCompositionGroup, ruleToAdd!);
								} : null}
								message="Validate"
								disabled={ruleToAdd !== null ? false : true}
							/>
						}
						secondaryAction={
							<Btn
								onClick={() => {
									setOpenModalToAddRule(false);
									setRuleToAdd(null);
									setRuleType(undefined);
								}}
								message="Cancel"
								color="secondary"
								style="ghost"
							/>
						}
					/>
				}
			</Modal>
			<Confirm
				isOpen={confirmDeleteGroup}
				onClose={() => setConfirmDeleteGroup(false)}
				title="Are you sure to delete this group?"
				text="This action is irreversible"
				cancelText="Cancel"
				confirmText="Delete this group"
				confirmCallback={() => handleDeleteGroup(ruleCompositionGroup)}
			/>
		</>
	);
}