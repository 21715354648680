import {Checkbox} from '../../../Components/Checkbox/Checkbox';
import React, {Fragment} from 'react';
import ReactTooltip from 'react-tooltip';
import { formatIndicatorDisplayLabel } from '../IndicatorsUtil';
type Props = {
	handleChangeKpi: ()=>void;
	item: {
		label:string;
		infos?: string;
		key:string;
		isInteraction?:boolean;
	},
	selectedKpis: Array<string>;
	isInteraction:boolean;
}

export const CheckBoxKpi = ({item,selectedKpis,handleChangeKpi,isInteraction}:Props):React.JSX.Element => {
	return (
		<Checkbox
			name="kpi"
			value={item.key}
			checked={selectedKpis.includes(item.key)}
			onChange={handleChangeKpi}
		>
			{isInteraction ? formatIndicatorDisplayLabel(item) : item.label}
			{item.infos &&
				<Fragment>
					<ReactTooltip id='custom-color-no-arrow' backgroundColor='rgba(103, 213, 215, 0.89)' delayHide={100} effect='float' />
					<i data-for='custom-color-no-arrow' className="cb_label_tooltip_target fas fa-info-circle" data-tip={item.infos}></i>
				</Fragment>}
		</Checkbox>
	);
};
