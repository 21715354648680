import React, { useState } from 'react';
import styles from './ProductThumb.module.css';

interface IProductThumb {
	url: string;
	alt?: string;
	className?: string;
	width?: number;
	height?: number | 'auto';
}

function ProductThumb({
	url,
	alt,
	width = 60,
	height = 'auto',
	className,
	...otherProps
}: IProductThumb): JSX.Element {

	const cssClass = [styles.wrapper];
	const [display, setDisplay] = useState(true);
	const finalHeight = height === 'auto' ? width : height;

	if (className) {
		cssClass.push(className);
	}
	
	return (
		<div className={cssClass.join(' ')} style={{width: width, height: finalHeight}} {...otherProps}>
			<img
				className={styles.img}
				src={url}
				alt={alt}
				width={width}
				height={finalHeight}
				style={display ? {} : {display: 'none'}}
				onError={() => setDisplay(false)}
			/>
		</div>
	);
}

export {
	ProductThumb,
};
