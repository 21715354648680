import React, { createContext, useState, useContext, useEffect } from 'react';
import { Product } from '../ProductInsights/Kpi/TypeKpi';
import { Chip, DataStructure, SESSION_STORAGE_KEY, transformToDataStructureArray, getInitialSelectedValues } from '../ProductInsights/utils';
import { useChipManagement } from '../ProductInsights/Chip/chipManagement';
import { processData } from '../ProductInsights/Emerch/api/dataProcessing';
import { useFetchData } from '../ProductInsights/Emerch/api/dataFetching';

type ProductEmerchInsightsContextType = {
	isLoading: boolean;
	$location: any;
	insightsKpiPaylod: InsightsKpiPaylod;
	changeDeviceSelected: (s: Devices) => void;
	deviceType: Devices;
	productsSelections: Product[];
	valueInSearchBar: string;
	handleChange: (st: string) => void;
	fetchExportKpis: (optionsObject, signal) => void;

	visitsAverage: number;
	addToCartRateAverage: number;
	conversionRateAverage: number;

	visitsAverageComparison: number;
	addToCartRateAverageComparison: number;
	conversionRateAverageComparison: number;

	emercheOrdinate: string;
	tagStudiedEl: any;
	ComparisonSelected: boolean;
	handleSelectComparaisonPeriodHandler: (b: boolean) => void;
	onChangeNavFilter: (o: any) => void;
	initialDates: any;
	dateStringToTimestamp: (o: any) => void;
	navFilter: any;
	setNavFilter: React.Dispatch<React.SetStateAction<any>>;
	setisCustomSelected: React.Dispatch<React.SetStateAction<any>>;
	isCustomSelected: boolean;
	customDateRange: any;
	setCustomDateRange: React.Dispatch<React.SetStateAction<any>>;
	updateStorage: any;
	setEmerchOrdinate: React.Dispatch<React.SetStateAction<string>>;
	handleChangeFormData: (data: any) => void;
	handleOpenClosePanelForm: (evt: boolean) => void;
	panelFormIsOpen: boolean;
	filterHierarchies: any;
	selectedValues: any;
	setSelectedValues: React.Dispatch<React.SetStateAction<any>>;
	chipsByFilterSelected: Chip[];
	setFilterSelected: React.Dispatch<React.SetStateAction<DataStructure[]>>;
	categoryTypeSelected: SelectElem | undefined;
	categories: SelectElem[];
	handleSelectNewCategory: (e: SelectElem) => void;
	accountHasNoDatas: boolean;
};
type filter = { op: string; filters: Array<any>; };
type Devices = 'Mobile' | 'Desktop' | 'Tablet';
type InsightsKpiPaylod = {
	context: string;
	sorting: Array<any>;
	tenant: null;
	filtering: filter;
	groupSorting: null;
	transformations: Array<any>;
	name: string;
};
interface ProductEmerchInsightsProviderProps {
	$http: any;
	children?: React.ReactNode;
	$rootScope: any;
	$routeParams: any;
	$timeout: any;
	$location: any;
	AuthServices: any;
	ComparisonSelected: boolean;
	handleSelectComparaisonPeriodHandler: (b: boolean) => void;
	onChangeNavFilter: (o: any) => void;
	initialDates: any;
	dateStringToTimestamp: (o: any) => void;
	navFilter: any;
	setNavFilter: React.Dispatch<React.SetStateAction<any>>;
	setisCustomSelected: React.Dispatch<React.SetStateAction<any>>;
	isCustomSelected: boolean;
	customDateRange: any;
	setCustomDateRange: React.Dispatch<React.SetStateAction<any>>;
	updateStorage: any;
	selectedValues: any;
	setSelectedValues: React.Dispatch<React.SetStateAction<any>>;
	accountHasNoDatas: boolean;
	setAccountHasNoDatas:React.Dispatch<React.SetStateAction<boolean>>;
}

type SelectElem = {
	label: string;
	value: string;
}

const ContextEmerchInsight = createContext<ProductEmerchInsightsContextType | undefined>(undefined);

export const useProductEmerchInsightsContext = () => {
	const contextValue = useContext(ContextEmerchInsight);
	if (contextValue === undefined) {
		throw new Error('must be used within an insights element');
	}
	return contextValue;
};

export const ProductSpecificContextProvider: React.FC<ProductEmerchInsightsProviderProps> = ({
	children,
	$location,
	$rootScope,
	$routeParams,
	AuthServices,
	$http,
	ComparisonSelected,
	handleSelectComparaisonPeriodHandler,
	onChangeNavFilter,
	initialDates,
	dateStringToTimestamp,
	navFilter,
	setNavFilter,
	isCustomSelected,
	setisCustomSelected,
	customDateRange,
	setCustomDateRange,
	updateStorage,
	selectedValues,
	setSelectedValues,
	accountHasNoDatas,
	setAccountHasNoDatas
}) => {
	const [insightsKpiPaylod] = useState<InsightsKpiPaylod>({
		context: 'Global',
		name: 'empty',
		sorting: [],
		tenant: null,
		filtering: { op: 'And', filters: [] },
		groupSorting: null,
		transformations: [],
	});
	const [deviceType, setDeviceType] = useState<Devices>('Desktop');
	const { fetchCatergory, fetchData,fetchExportKpis} = useFetchData($http, $rootScope, $routeParams, AuthServices, navFilter,setAccountHasNoDatas);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [productsSelections] = useState<Product[]>([]);
	const [valueInSearchBar, setValueInSearchBar] = useState<string>('');

	const [visitsAverage, setVisitsAverage] = useState<number>(0);
	const [addToCartRateAverage, setAddtocartRateAverage] = useState<number>(0);
	const [conversionRateAverage, setConversionRateAverage] = useState<number>(0);

	const [visitsAverageComparison, setVisitsAverageComparison] = useState<number>(0);
	const [addToCartRateAverageComparison, setAddtocartRateAverageComparison] = useState<number>(0);
	const [conversionRateAverageComparison, setConversionRateAverageComparison] = useState<number>(0);

	const [tagStudiedEl, setTagStudiedEl] = useState<any>(null);
	const [emercheOrdinate, setEmerchOrdinate] = useState<string>('conversionRate');
	const [categoryTypeSelected, setCategoryTypeSelected] = useState<SelectElem | undefined>({ label: 'Pays de destination', value: 'Pays de destination' });

	const [categories, setCategories] = useState<SelectElem[]>([]);
	const [panelFormIsOpen, setPanelFormIsOpen] = useState(false);
	const [filterHierarchies, setFilterHierarchies] = useState([]);
	const [filterSelected, setFilterSelected] = useState<DataStructure[]>([]);
	const [isFilterSelectedInitialized, setIsFilterSelectedInitialized] = useState(false);
	const [chipsByFilterSelected, setChipsByFilterSelected] = useState<Chip[]>([]);

	useChipManagement(filterSelected, setChipsByFilterSelected);

	function handleOpenClosePanelForm(evt: boolean) {
		setPanelFormIsOpen(evt);
	}

	function handleChangeFormData(data: any) {
		sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(selectedValues));
		setFilterSelected(data);
		setPanelFormIsOpen(false);
	}
	function handleChange(evt: string) {
		setValueInSearchBar(evt);
	}
	function handleSelectNewCategory(value) {
		const elSelected:SelectElem | undefined = categories.find(e=>e.value === value) ?  categories.find(e=>e.value === value) : {
			value: '',
			label: 'Choose a name field',
		} ;
		setCategoryTypeSelected(elSelected);
	}
	function changeDeviceSelected(type: Devices): void {
		setDeviceType(type);
	}
	useEffect(() => {
		const hasData = getInitialSelectedValues();
		const date1 = dateStringToTimestamp(navFilter.fromDate);
		const date2 = dateStringToTimestamp(navFilter.toDate);
		const data3 = dateStringToTimestamp(navFilter.fromDateToCompare);
		const data4 = dateStringToTimestamp(navFilter.toDateToCompare);
		const optionsObject = {
			startDate1: date1,
			endDate1: date2,
			endDate2: data4,
			startDate2: data3,
			rowsperpage: 2,
			blocnumber: 1,
			orderby: 'numberofsessions',
			direction: 'desc',
			pattern: '',
		};
		const controller = new AbortController();
		const signal = controller.signal;
		fetchCatergory(optionsObject, signal, setFilterHierarchies);
		if (hasData) {
			const arrayOfSelectedFilters = transformToDataStructureArray(hasData);
			setFilterSelected(arrayOfSelectedFilters);
			setIsFilterSelectedInitialized(true);
		}
	}, []);

	useEffect(() => {
		
		setIsLoading(true);
		const timer = setTimeout(() => {
			if (isFilterSelectedInitialized && !accountHasNoDatas) {
				const date1 = dateStringToTimestamp(navFilter.fromDate);
				const date2 = dateStringToTimestamp(navFilter.toDate);
				const optionsObject = {
					startDate1: date1,
					endDate1: date2,
					tagtype: categoryTypeSelected ? categoryTypeSelected.value : ''
				};
				const controller = new AbortController();
				const signal = controller.signal;

				if (valueInSearchBar.length > 0) {
					const handler = setTimeout(() => {
						fetchData(optionsObject, filterSelected, setIsLoading, setTagStudiedEl, data => {
							processData({
								data,
								ComparisonSelected,
								setTagStudiedEl,
								setVisitsAverage,
								setAddtocartRateAverage,
								setConversionRateAverage,
								setVisitsAverageComparison,
								setAddtocartRateAverageComparison,
								setConversionRateAverageComparison,
								setIsLoading,
								setCategories,
								emercheOrdinate,
							}
							);
						}, signal,
						);
					}, 800);

					return () => {
						clearTimeout(handler);
					};
				} else {
					const handler = setTimeout(() => {
						fetchData(optionsObject, filterSelected, setIsLoading, setTagStudiedEl, data => {
							processData({
								data,
								ComparisonSelected,
								setTagStudiedEl,
								setVisitsAverage,
								setAddtocartRateAverage,
								setConversionRateAverage,
								setVisitsAverageComparison,
								setAddtocartRateAverageComparison,
								setConversionRateAverageComparison,
								setIsLoading,
								setCategories,
								emercheOrdinate,
							}
							);
						}, signal,
						);
					},800);
					return () => {
						clearTimeout(handler);
					};
				}

			}
		},120);
		return () => {
			if (timer) clearTimeout(timer);
		};
	}, [
		valueInSearchBar,
		navFilter,
		ComparisonSelected,
		emercheOrdinate,
		filterSelected,
		isFilterSelectedInitialized,
		categoryTypeSelected,
		accountHasNoDatas
	]);

	return (
		<ContextEmerchInsight.Provider value={{
			accountHasNoDatas,
			isLoading,
			$location,
			changeDeviceSelected,
			deviceType,
			insightsKpiPaylod,
			productsSelections,
			valueInSearchBar,
			handleChange,
			fetchExportKpis,

			visitsAverage,
			addToCartRateAverage,
			conversionRateAverage,

			visitsAverageComparison,
			addToCartRateAverageComparison,
			conversionRateAverageComparison,

			emercheOrdinate,
			tagStudiedEl,
			ComparisonSelected,
			handleSelectComparaisonPeriodHandler,
			onChangeNavFilter,
			initialDates,
			dateStringToTimestamp,
			navFilter,
			setNavFilter,
			isCustomSelected,
			setisCustomSelected,
			customDateRange,
			setCustomDateRange,
			updateStorage,
			setEmerchOrdinate,
			handleChangeFormData,
			handleOpenClosePanelForm,
			panelFormIsOpen,
			filterHierarchies,
			selectedValues,
			setSelectedValues,
			chipsByFilterSelected,
			setFilterSelected,
			categoryTypeSelected,
			categories,
			handleSelectNewCategory
		}}>
			{children}
		</ContextEmerchInsight.Provider>
	);
};


export default ProductSpecificContextProvider;
