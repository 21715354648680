import HttpServices from './HttpServices';
import {ObjectFunnelApiPost} from '../Auth/Insights/Funnel/NavigationFunnel/NavigationFunnelTypes';

export default class FunnelService {
	HttpServices: HttpServices;
	BO_API_URL: string;
	accountId: string;
	constructor(accId: string,$http:any) {
		this.HttpServices = new HttpServices($http);
		this.accountId = accId;
		if (!window || !(window as any).BEYABLE_env || !(window as any).BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${(window as any).BEYABLE_env.BO_API_URL}/`;//devonly/
			// this.BO_API_URL = `${(window as any).BEYABLE_env.BO_API_URL}`;
		}
	}
	postQueryFunnel(ObjectFunnel: ObjectFunnelApiPost,callbackSuccess, callbackError) {
		const url = `${this.BO_API_URL}${this.accountId}/metrics/summable/query`;
		this.HttpServices.post(url, ObjectFunnel,callbackSuccess, callbackError);
	}
	getAvailableDimensions(culture,startDate,endDate,callbackSuccess, callbackError) {
		const url = `${this.BO_API_URL}${this.accountId}/metrics/summable/available-dimensions?ui-culture=${culture}&startDate=${startDate}&endDate=${endDate}`;
		this.HttpServices.get(url,callbackSuccess, callbackError);
	}
	getAvailableMetrics(culture,startDate,endDate,callbackSuccess, callbackError) {
		const url = `${this.BO_API_URL}${this.accountId}/metrics/summable/available-metrics?ui-culture=${culture}&startDate=${startDate}&endDate=${endDate}`;
		this.HttpServices.get(url,callbackSuccess, callbackError);
	}
}