import React, { useState } from 'react';
import angular from 'angular';
import { react2angular } from 'react2angular';
import TriggerGroupEditorContainer from './TriggerGroupEditorContainer';
import CreateSegmentGroupEditorContextProvider from './context/SegmentGroupEditorContainerProvider';
import SegmentGroupEditor from './SegmentGroupEditor';
import { useSegmentationLoader } from './context/useSegmentationLoader';
import EmptyState from '../../../Components/EmptyState';
import newInstanceI18next from '../../../Util/traductionUtils';
import { I18nextProvider } from 'react-i18next';
import SpinnerWheel from '../../../Components/SpinnerWheel';

export default function SegmentationEditorContainer(props) {
	const [triggers, setTriggers] = useState([]);

	const triggersChangedCallback = (triggers) => setTriggers(triggers);

	const {
		cannotLoad,
		isLoading,
		loadedSegmentation
	} = useSegmentationLoader({
		$rootScope: props.$rootScope,
		$routeParams: props.$routeParams,
		$http: props.$http
	}, props.$routeParams.id);

	return (
		<CreateSegmentGroupEditorContextProvider
			$http={props.$http}
			$rootScope={props.$rootScope}
			$routeParams={props.$routeParams}
			$timeout={props.$timeout}
			triggers={triggers}
			loadedSegmentation={loadedSegmentation}
			isLoading={isLoading}
			UtilCampaignsServices={props.UtilCampaignsServices}
		>
			<I18nextProvider i18n={newInstanceI18next}>
				<SegmentGroupEditor>
					{isLoading &&
						<SpinnerWheel wheelSize="l" />
					}
				
					{!isLoading && cannotLoad &&
						<EmptyState
							verticalSize={'l'}
							textSize={'l'}
							title={'Cannot load segmentation'}
							text={'Cannot load segmentation'}
						/>
					}
					{!isLoading && !cannotLoad &&
						<TriggerGroupEditorContainer
							$http={props.$http}
							$rootScope={props.$rootScope}
							$routeParams={props.$routeParams}
							$timeout={props.$timeout}
							UtilCampaignsServices={props.UtilCampaignsServices}
							triggersChangedCallback={triggersChangedCallback}
							initialTriggers={loadedSegmentation}
						/>
					}
				</SegmentGroupEditor>
			</I18nextProvider>
		</CreateSegmentGroupEditorContextProvider>
	);

}

angular.module('beyableSaasApp.SegmentationEditor', [])
	.component(
		'segmentationeditorcontainer',
		react2angular(
			SegmentationEditorContainer,
			[],
			['$http', '$rootScope', '$routeParams', '$location', '$timeout', 'UtilCampaignsServices']
		)
	);

