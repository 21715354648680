import React, { Fragment } from 'react';
import { useCatalogueEditorContext } from '../Context/CatalogueContextProvider';
//import { Template } from '../../../WysiwygEditor/Types/BespokeTypes';
import EmptyState from '../../../../Components/EmptyState';
import { Spinner } from '../../../../Components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import TemplateDisplayThumbnail from './TemplateDisplayThumbnail';
import {TemplateStyle} from './TemplateStyle';
import styles from '../CatalogueContainer.module.css';
import renderJsxTag from '../../../../Translation/translation-utils';

const EditorTemplate = () => {
	const [t] = useTranslation('common');

	const {
		templateGroupsToMap,
		handleFormatPreviewClicked,
		isLoading,
		mode,
		currentDevice
	} = useCatalogueEditorContext();

	function handleClick (format, style) {
		handleFormatPreviewClicked(format, style);
	}

	function getFinalName (template) {
		if (template.TranslationKey) {
			return t('modules.' + template.TranslationKey + '.label');
		}
		return template.Name;
	}

	if (mode === 'realizations') {
		return null;
	}

	return (
		<>
			{isLoading &&
				<Spinner />
			}
			{!isLoading &&
				<div className={styles.viewBody}>
					<div className={styles.grid_center}>
						{templateGroupsToMap.length == 0 &&
							<EmptyState
								imageUrl="/Assets/empty_custom_template.svg"
								imageWidth={190}
								title={t('designEditor.noAvailableYet')}
								text={
									<>{renderJsxTag(t('designEditor.weCanCreate'))}</>
								}
								textSize="l"
								verticalSize="l"
							/>
						}
						
						{templateGroupsToMap.map((group: any) =>
							<div key={group.id} className={styles.grid_group + ' js-catalog-group-' + group.id}>
								{group.id !== 'default' &&
									<div className={styles.grid_group_title}>
										{t('objectives.' + group.id + '.label')}
									</div>
								}
								<div className={styles.grid}>
									{group.items.map((template: any, j) =>
										<Fragment key={j}>
											<TemplateDisplayThumbnail
												device={currentDevice}
												template={template}
												onClick={() => handleClick(template, undefined)}
												title={getFinalName(template)}
												idStyle={'default'}
											/>

											{template.hasOwnProperty('stylesAssociates') &&
												template.stylesAssociates.map(st =>
													<TemplateStyle
														key={st.id}
														template={template}
														device={currentDevice}
														properties={JSON.parse(JSON.stringify(template.Properties))}
														name={getFinalName(template)}
														styleProperties={JSON.parse(JSON.stringify(st.properties))}
														handleClick={() => handleClick(template, st)}
													/>
												)
											}
										</Fragment>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			}
		</>
	);
};

export default EditorTemplate;