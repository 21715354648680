import React, { useState} from 'react';
import './SideBar.css';
import { CampaignsContext } from '../../context';
import Btn from '../../../../Components/Btn';
import EmptyState from '../../../../Components/EmptyState';
import {useTranslation} from 'react-i18next';
import renderJsxTag from '../../../../Translation/translation-utils';

export default function FormatSelector(props) {
	const [t, i18n] = useTranslation('common');

	const {
		availableFormats,
		editorState,
		handleFormatSelected,        
		handleFormatUnselected,
		handleFormatChosen,
		isAdmin,
		allowCreateCustom,
		handleChangeEditorToLibraryCustom
	} = CampaignsContext();

	const [customIsSelected, setCustomIsSelected] = useState(false);

	function handleFormatClicked(format) {
		const isUnselect = editorState.selectedFormat && editorState.selectedFormat.Identifier === format.Identifier;
		isUnselect ? handleFormatUnselected() : handleFormatSelected(format);
		setCustomIsSelected(false);
	}

	function getFormatDisplayName(name){
		const n = name.toLowerCase();
		if (n == 'inapp') return 'In-page';
		if (n == 'headerbar') return 'Header bar';
		return name;
	}

	return (
		<>
			<div className="flex_item_full vscroll_custom">
				<section className="format_sidebar_section">
					{isAdmin && allowCreateCustom &&
						<>
							<div
								className={customIsSelected ? 'format_sidebar_item active' : 'format_sidebar_item'}
								onClick={() => {handleFormatUnselected(); setCustomIsSelected(!customIsSelected);}}>
								<div className="format_sidebar_item_illu">
									<img src="/Assets/format/format_custom_min.svg" />
								</div>
								<div className="format_sidebar_item_body">
									<div className="format_sidebar_item_type">{t('designEditor.code')}</div>
									<div className="format_sidebar_item_name">{t('designEditor.customCampaign')}</div>
								</div>
							</div>
							<div className='mt_20 format_sidebar_item_group_label'>
								{availableFormats.formats.length <= 1 ?
									<>{t('designEditor.nbTemplate', {n: availableFormats.formats.length})}</>
									:
									<>{t('designEditor.nbTemplates', {n: availableFormats.formats.length})}</>
								}
							</div>
						</>
					}
					{availableFormats.formats.length == 0 &&
						<EmptyState
							icon="far fa-object-group"
							title={t('designEditor.noAvailableYet')}
							hasBorder={isAdmin}
							verticalSize={'s'}
							text={
								<>{renderJsxTag(t('designEditor.weCanCreate'))}</>
							}
						/>
					}
					{availableFormats.formats.map((format, i) => 
						<div key={i}
							onClick={() => handleFormatClicked(format)}
							className={`format_sidebar_item ${editorState.selectedFormat.Identifier === format.Identifier ? 'active' : ''}`}>
							<div className="format_sidebar_item_illu">
								<img src={format.ThumbnailUrl} />
							</div>
							<div className="format_sidebar_item_body">
								<div className="format_sidebar_item_type">{getFormatDisplayName(format.Format)}</div>
								<div className="format_sidebar_item_name">{format.Name}</div>
							</div>
						</div>
					)}
				</section>
			</div>
			<section className="format_sidebar_section format_sidebar_footer flex_item_fix">
				{customIsSelected ?
					<Btn
						onClick={() => handleChangeEditorToLibraryCustom()}
						size="l"
						fullWidth={true}
						message={t('designEditor.chooseCustom')}
						icon="fas fa-chevron-right"
						iconPosition="after"
					/>
					:
					<Btn
						onClick={() => handleFormatChosen()}
						disabled={!editorState.selectedFormat.Identifier}
						size="l"
						fullWidth={true}
						message={t('designEditor.chooseThisTemplate')}
						icon="fas fa-chevron-right"
						iconPosition="after"
					/>
				}
			</section>
		</>            
	);
}
