import React, {useState} from 'react';
import { onSiteContext } from '../context/index';
import Btn from '../../../../Components/Btn';
import Dropdown from '../../../../Components/Dropdown';
import { EmptyState } from '../../../../Components/EmptyState/EmptyState';
import { Section } from '../../../../Components/Section/Section';
import { Article } from '../../../../Components/Article/Article';
import { multiChoice, singleChoice } from '../context/Constants.js';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import renderJsxTag from '../../../../Translation/translation-utils';

import checkListStyles from '../../../NotificationCenter/Dashboard/NotificationCenter.module.css';

export default function StarterOnSite({
	header
}) {

	const {
		$routeParams,
		isAdmin,
		canCreatePalCampaigns,	
		canCreateExternalAppCampaigns,
		canCreateCodePatchCampaigns,
		canCreatePatchCampaigns,
		canCreateClassicCampaigns,
		canCreateSaaSCampaigns,
		permissionsAccount,
		accountIsLoading,
		checkAccountData,
		openInspirations,
		campaignCreationVersion,
		isNotificationMode,
		hasCenter,
		hasNotifs,
	} = onSiteContext();

	const [t] = useTranslation(['common', 'notifications_center']);
	const [createDropdownIsOpen, setCreateDropdownIsOpen] = useState(false);
	const createNotifCampaigns = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'NOTIFICATIONCENTER_CAN_CREATE');
	const canCreateNotificationCampaigns = createNotifCampaigns && createNotifCampaigns.Value == true;

	let title;
	let text;
	let imgUrl;
	if (campaignCreationVersion === singleChoice && canCreateExternalAppCampaigns) {
		// Shopify users
		title = t('staterOnSite.createYourFirstHeader');
		text = <>{t('staterOnSite.useHeaderBar')}</>;
		imgUrl = '/Assets/empty_campaign_shopify.png';
	} else {
		title = <>{t('staterOnSite.optimize')}</>;
		text = <>{renderJsxTag(t('staterOnSite.findOut'))}</>;
		imgUrl = '/Assets/empty_personalization.svg';
	}

	return (
		<>
			{!accountIsLoading && !checkAccountData && !isNotificationMode && (
				<Section width='l'>
					<section className="section no_bottom_pad section_primary">
						<div className="h1">Campaigns</div>
					</section>
					<section className="section">
						<Article>
							<EmptyState
								textSize="xl"
								verticalSize="l"
								title={title}
								text={text}
								imageUrl={imgUrl}
								primaryAction={
									<>
										{campaignCreationVersion === multiChoice && (
											<>
												<Dropdown
													isOpen={createDropdownIsOpen}
													setIsOpen={setCreateDropdownIsOpen}
													onHide={() => setCreateDropdownIsOpen(false)}
													button={
														<Btn
															message={t('staterOnSite.newCampaign')}
															arrow={true}
														/>
													}
												>
													<ul className="listbox">
														{(isAdmin || canCreateSaaSCampaigns) && (
															<li>
																<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}` : '/Campaigns/Editor'}>
																	<span className="flex">
																		<span className="flex_item_fix listbox_item_icon">
																			<span className="icon_32 icon_layout icon_color_turquoise"></span>
																		</span>
																		<span className="flex_item_full">
																			<span className="listbox_item_title">
																				{t('nav.CustomTemplate')}
																			</span>
																			<span className="listbox_item_desc">{t('nav.useCustomTemplate')}</span>
																		</span>
																	</span>
																</a>
															</li>
														)}
														<li className="hr"></li>
														{(isAdmin || canCreatePalCampaigns) && (
															<>
																<li>
																	<a className="listbox_item xl flex" onClick={() => { setCreateDropdownIsOpen(false); openInspirations(); }}>
																		<span className="flex">
																			<span className="flex_item_fix listbox_item_icon">
																				<span className="icon_32 icon_idea icon_color_turquoise"></span>
																			</span>
																			<span className="flex_item_full">
																				<span className="listbox_item_title">{t('nav.inspiration library')}</span>
																				<span className="listbox_item_desc">
																					{t('nav.Browse our library of ready-to-use templates')}
																				</span>
																			</span>
																		</span>
																	</a>
																</li>
															</>
														)}
														<li className="hr"></li>
														{(isAdmin || canCreatePatchCampaigns) && (
															<li>
																<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&graph` : '/Campaigns/Editor?graph'}>
																	<span className="flex">
																		<span className="flex_item_fix listbox_item_icon">
																			<span className="icon_32 icon_color icon_color_turquoise"></span>
																		</span>
																		<span className="flex_item_full">
																			<span className="listbox_item_title">
																				{t('nav.graphicPatch')}
																			</span>
																			<span className="listbox_item_desc">{t('nav.useGraphic')}</span>	
																		</span>
																	</span>
																</a>
															</li>
														)}
														<li className="hr"></li>
														{(isAdmin || canCreateCodePatchCampaigns) && (
															<li>
																<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&patch` : '/Campaigns/Editor?patch'}>
																	<span className="flex">
																		<span className="flex_item_fix listbox_item_icon">
																			<span className="icon_32 icon_code icon_color_turquoise"></span>
																		</span>
																		<span className="flex_item_full">
																			<span className="listbox_item_title">
																				{t('nav.codePatch')}
																			</span>
																			<span className="listbox_item_desc">
																				{t('nav.patchYourWeb')}
																			</span>
																		</span>
																	</span>
																</a>
															</li>
														)}
														<li className="hr"></li>
														{(isAdmin) && (
														// {(isAdmin || canCreateABtest) && (
															<li>
																<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&abtest` : '/Campaigns/Editor?abtest'}>
																	<span className="flex">
																		<span className="flex_item_fix listbox_item_icon">
																			<span className="icon_32 icon_abtest icon_color_turquoise"></span>
																		</span>
																		<span className="flex_item_full">
																			<span className="listbox_item_title">
																				{t('staterOnSite.abTest')}
																			</span>
																			<span className="listbox_item_desc">
																				{t('staterOnSite.makeAbTests')}
																			</span>
																		</span>
																	</span>
																</a>
															</li>
														)}
														<li className="hr"></li>
														{/* Remove false to show Shopify link for admins or users with multiple permissons */}
														{(canCreateExternalAppCampaigns && false) && (
															<li>
																<Link className="listbox_item xl flex"
																	to={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&module=shopify001` : '/Campaigns/Editor?module=shopify001'}>
																	<span className="flex">
																		<span className="flex_item_fix listbox_item_icon">
																			<span className="icon_32 icon_shopify icon_color_turquoise"></span>
																		</span>
																		<span className="flex_item_full">
																			<span className="listbox_item_title">
																				{t('nav.ShopifyCampaign')}
																			</span>
																		</span>
																	</span>
																</Link>
															</li>
														)}
														<li className="hr"></li>
														{isAdmin && (
															<li>
																<Link className="listbox_item xl flex"
																	to={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&appNative` : '/Campaigns/Editor?appNative'}>
																	<span className="flex">
																		<span className="flex_item_fix listbox_item_icon">
																			<span className="icon_32 icon_mobile_app icon_color_turquoise"></span>
																		</span>
																		<span className="flex_item_full">
																			<span className="listbox_item_title">
																				{t('nav.inAppCampaign')}
																			</span>
																			<span className="listbox_item_desc">
																				{t('nav.inAppCampaignDescription')}
																			</span>
																		</span>
																	</span>
																</Link>
															</li>
														)}
														<li className="hr"></li>
														{(isAdmin || canCreateClassicCampaigns) && (
															<li>
																<a className="listbox_item xl" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Create?ka=${$routeParams.ka}&ku=${$routeParams.ku}` : '/Campaigns/Create'}>
																	<span className="flex">
																		<span className="flex_item_fix listbox_item_icon listbox_item_icon_32">
																			<span className="icon_20 icon_idea icon_color_grey"></span>
																		</span>
																		<span className="flex_item_full grey_2">
																			{t('nav.classicCampaign')}
																		</span>
																	</span>
																</a>
															</li>
														)}
													</ul>
												</Dropdown>
											</>
										)}
										{campaignCreationVersion === singleChoice &&
											<>
												{canCreatePalCampaigns && 
													<Btn
														onClick={() => openInspirations()}
														size="l"
														fontSize="l"
														message={t('staterOnSite.newCampaign')}
													/>
												}
												{canCreateExternalAppCampaigns && 
													<Link className="btnUi btn_primary btn_l btn_fs_l"
														to={$routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&module=shopify001` : '/Campaigns/Editor?module=shopify001'}>
														<span className="btn_txt">{t('staterOnSite.newCampaign')}</span>
													</Link>
												}
											</>
										}
									</>
								}
							/>
						</Article>
					</section>
				</Section>
			)}
			{!accountIsLoading && !checkAccountData && isNotificationMode && (!hasCenter || !hasNotifs) &&
				<Section width='l'>
					<section className="section no_bottom_pad section_primary">
						<div className="h1">Notification center</div>
					</section>
					<section className="section">
						{(hasCenter && header) && header}
						<Article hasMargin={false}>
							{!hasCenter &&
								<EmptyState
									textSize="l"
									verticalSize="l"
									title={t('keepYourVisitorsUpToDate', {ns: 'notifications_center'})}
									imageUrl="/Assets/empty_notification_center.svg"
									text={!canCreateNotificationCampaigns && t('cantCreateCampaign', {ns: 'notifications_center'})}
									card={canCreateNotificationCampaigns &&
										<>
											<div className={checkListStyles.checklist__header}>
												<div className='flex'>
													<div className='flex_item_full fw_medium'>{t('onboardingCheckList', {ns: 'notifications_center'})}</div>
													<div className='flex_item_fix'>0/2</div>
												</div>
												<div className={checkListStyles.checklist__progress}>
													<progress max="2" value="0"></progress>
												</div>
											</div>
											<ol className={checkListStyles.checklist__list}>
												<li>
													<a className={[checkListStyles.checklist__link, 'flex'].join(' ')} href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?notificationCenter&from=NotificationCenter/Dashboard&ka=${$routeParams.ka}&ku=${$routeParams.ku}` : '/Campaigns/Editor?notificationCenter&from=NotificationCenter/Dashboard'}>
														<div className='flex_item_fix'>
															<div className={checkListStyles.checklist__check}></div>
														</div>
														<div className='flex_item_full'>
															<div className='fw_medium c_turquoise'>{t('createYourFirstInbox', {ns: 'notifications_center'})}</div>
															<div className='grey_2 s_13'>{t('aContainerWhereNotifications', {ns: 'notifications_center'})}</div>
														</div>
														<div className='flex_item_fix'>
															<i className='fas fa-chevron-right c_turquoise'></i>
														</div>
													</a>
												</li>
												<li>
													<div className={[checkListStyles.checklist__item, 'flex'].join(' ')}>
														<div className='flex_item_fix'>
															<div className={checkListStyles.checklist__check}></div>
														</div>
														<div className='flex_item_full'>
															<div className='fw_medium'>{t('createYourFirstNotification', {ns: 'notifications_center'})}</div>
														</div>
													</div>
												</li>
											</ol>
										</>
									}
									cardWidth='s'
								/>
							}
							{hasCenter && !hasNotifs && 
								<EmptyState
									textSize="l"
									verticalSize="l"
									title={t('yourFirstInboxIsCreated', {ns: 'notifications_center'})}
									imageUrl="/Assets/empty_notification_center.svg"
									text={!canCreateNotificationCampaigns && t('cantCreateCampaign', {ns: 'notifications_center'})}
									card={canCreateNotificationCampaigns &&
										<>
											<div className={checkListStyles.checklist__header}>
												<div className='flex'>
													<div className='flex_item_full fw_medium'>{t('onboardingCheckList', {ns: 'notifications_center'})}</div>
													<div className='flex_item_fix'>1/2</div>
												</div>
												<div className={checkListStyles.checklist__progress}>
													<progress max="2" value="1"></progress>
												</div>
											</div>
											<ol className={checkListStyles.checklist__list}>
												<li>
													<div className={[checkListStyles.checklist__item, 'flex'].join(' ')}>
														<div className='flex_item_fix'>
															<div className={checkListStyles.checklist__checkChecked}>
																<i className='fas fa-check'></i>
															</div>
														</div>
														<div className='flex_item_full'>
															<div className='fw_medium'><s>{t('createYourFirstInbox', {ns: 'notifications_center'})}</s></div>
															<div className='grey_2 s_13'>{t('aContainerWhereNotifications', {ns: 'notifications_center'})}</div>
														</div>
													</div>
												</li>
												<li>
													<a className={[checkListStyles.checklist__link, 'flex'].join(' ')} href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?notificationCenterNotification&from=NotificationCenter/Dashboard&ka=${$routeParams.ka}&ku=${$routeParams.ku}` : '/Campaigns/Editor?notificationCenterNotification&from=NotificationCenter/Dashboard'}>
														<div className='flex_item_fix'>
															<div className={checkListStyles.checklist__check}></div>
														</div>
														<div className='flex_item_full'>
															<div className='fw_medium c_turquoise'>{t('createYourFirstNotification', {ns: 'notifications_center'})}</div>
														</div>
														<div className='flex_item_fix'>
															<i className='fas fa-chevron-right c_turquoise'></i>
														</div>
													</a>
												</li>
											</ol>
										</>
									}
									cardWidth='s'
								/>
							}
						</Article>
					</section>
				</Section>
			}
		</>
	);
}
