import { TriggerGroup } from "../../../../Services/SegmentGroupServicesTypes";
import { EditingTrigger } from "../context/EntityTypes";


export default function setUpSpecifications(utilCampaignsServices) {

	const referenceDataTriggers = utilCampaignsServices.GetTriggers();
	const referenceDataTriggerOperands = utilCampaignsServices.GetTriggerOperands();

	const setUpSpecificationsForSaving = function (dataSpecifications) {
		const specificationsForSaving: any[] = [];
		for (let f = 0; f < dataSpecifications.length; f++) {
			const trigger = dataSpecifications[f];
			const spec: any = {};
			spec.name = trigger.Name;
			spec.OperatorName = trigger.OperatorName;
			spec.ValueToCompare = trigger.ValueToCompare;
			spec.Group = trigger.Group;
			if (trigger.Type === '' && !trigger.ValueToCompare) {
				spec.ValueToCompare = trigger.ValueToCompare;
			}
			if (trigger.Type === 'number' && typeof (trigger.ValueToCompare) !== 'number') {
				spec.ValueToCompare = 0;
			}
			if (trigger.categorie !== 'Hidden' && spec.ValueToCompare !== '' && trigger.ValueToCompare !== 'all') {
				// Format textarea content to be send
				if (trigger.Type === 'textarea') {
					spec.ValueToCompare = trigger.ValueToCompare.split('\n').join(';');
				}
				if (trigger.Type === 'list') {
					spec.ValueToCompare = trigger.ValueToCompare.toString();
				}
				if ((trigger.Type === 'multiplelist' || trigger.Type === 'multiplelistsearch') && trigger.Name !== 'TRGCHECKDEVICE') {
					const separator = trigger.Separator ? trigger.Separator : ',';
					spec.ValueToCompare = trigger.ValueToCompare.join(separator);
				}
				if (spec.Name === 'TRGCHECKDEVICE') {
					spec.ValueToCompare = trigger.ValueToCompare.map(x => x === 'desktop' ? 0 : x === 'mobile' ? 2 : 1);
				}
				if (trigger.Type === 'geoloc') {
					for (let t = 0; t < trigger.ValueToCompare.length; t++) {
						const perimeter = trigger.ValueToCompare[t];
						if (perimeter.LocationRange && perimeter.LocationRange !== 0) {
							perimeter.Position = {};
							perimeter.Position.Latitude = perimeter.Latitude;
							perimeter.Position.Longitude = perimeter.Longitude;
							perimeter.Position.City = perimeter.Address;
							perimeter.Distance = perimeter.LocationRange;
						}
					}
				}
				if (trigger.Type === 'schedule') {
					let s = '';
					for (let index = 0; index < (trigger.ValueToCompare || []).length; index++) {
						s = s + ';' + trigger.ValueToCompare[index];
					}
					spec.ValueToCompare = s.substring(1);
				}
				specificationsForSaving.push(spec);
			}
		}
		return specificationsForSaving;
	};

	const newSetUpSpecificationsForSaving = function (dataSpecifications: EditingTrigger[]): TriggerGroup[] {
		const triggerMap = new Map<string, TriggerGroup>();

		for (let f = 0; f < dataSpecifications.length; f++) {
			const trigger = dataSpecifications[f];
			const spec: any = {};

			const referenceTrigger = referenceDataTriggers.find(x => x.value === trigger.Name);

			const groupId = trigger.Group.Id;
			spec.name = trigger.Name;
			spec.operatorName = trigger.OperatorName;
			spec.value = trigger.ValueToCompare;

			if (referenceTrigger.type === '' && !trigger.ValueToCompare) {
				spec.value = trigger.ValueToCompare;
			}
			if (referenceTrigger.type === 'number' && typeof (trigger.ValueToCompare) !== 'number') {
				spec.value = 0;
			}
			if (referenceTrigger.categorie !== 'Hidden' && spec.value !== '' && trigger.ValueToCompare !== 'all') {
				// Format textarea content to be send
				if (referenceTrigger.type === 'textarea') {
					spec.value = trigger.ValueToCompare.split('\n').join(';');
				}
				if (referenceTrigger.type === 'list') {
					spec.value = trigger.ValueToCompare.toString();
				}
				if ((referenceTrigger.type === 'multiplelist' || referenceTrigger.type === 'multiplelistsearch') && trigger.Name !== 'TRGCHECKDEVICE') {
					const separator = referenceTrigger.Separator ?? ',';
					spec.value = trigger.ValueToCompare.join(separator);
				}
				if (spec.Name === 'TRGCHECKDEVICE') {
					spec.value = trigger.ValueToCompare.map(x => x === 'desktop' ? 0 : x === 'mobile' ? 2 : 1);
				}
				if (referenceTrigger.type === 'geoloc') {
					for (let t = 0; t < trigger.ValueToCompare.length; t++) {
						const perimeter = trigger.ValueToCompare[t];
						if (perimeter.LocationRange && perimeter.LocationRange !== 0) {
							perimeter.Position = {};
							perimeter.Position.Latitude = perimeter.Latitude;
							perimeter.Position.Longitude = perimeter.Longitude;
							perimeter.Position.City = perimeter.Address;
							perimeter.Distance = perimeter.LocationRange;
						}
					}
				}
				if (referenceTrigger.type === 'schedule') {
					let s = '';
					for (let index = 0; index < (trigger.ValueToCompare || []).length; index++) {
						s = s + ';' + trigger.ValueToCompare[index];
					}
					spec.value = s.substring(1);
				}
			}
			
			if (triggerMap.has(groupId)) {
				triggerMap.get(groupId)!.triggers.push(spec);
			} else {
				triggerMap.set(groupId, {
					groupId: groupId,
					groupName: trigger.Group.Label || '',
					triggers: [spec],
				});
			}
		}

		return Array.from(triggerMap.values());
	};


	const setUpSpecificationsForLoading = (campaignSpecifications) => {
		const dataTriggerOperands = referenceDataTriggerOperands;
		const dataTriggers = referenceDataTriggers;
		const specificationsForLoading: any[] = [];
		const tranformToTrigger = campaignSpecifications.reduce((prev, curr) => {
			const triggerArrCurr = curr.triggers.map(trigger => {
				return {
					Group: {
						Id: curr.groupId,
						Label: curr.groupName,
					},
					Name: trigger.name,
					OperatorName: trigger.operatorName,
					ValueToCompare: trigger.value
				};
			});
			return prev = [...prev, ...triggerArrCurr];
		}, []);

		for (let c = 0; c < tranformToTrigger.length; c++) {
			const spec = { ...tranformToTrigger[c] };
			if (spec.Name == 'TRGGEOLOC' || spec.Name == 'TRGEXCLUDEDGEOLOC') {
				if (spec.ValueToCompare) {
					const perimeters = spec.ValueToCompare;
					if (Array.isArray(perimeters)) {
						for (let p = 0; p < perimeters.length; p++) {
							const perimeter = perimeters[p];
							//perimeter.Guid = guid();
							perimeter.LocationRange = perimeter.Distance;
							if (perimeter.Position) {
								perimeter.Latitude = perimeter.Position.Latitude;
								perimeter.Longitude = perimeter.Position.Longitude;
								perimeter.Address = perimeter.Position.city || perimeter.Position.City;
							} else {
								perimeter.Latitude = 0;
								perimeter.Longitude = 0;
								perimeter.Address = '';
							}
						}
					}
				}
			}

			for (let a = 0; a < dataTriggers.length; a++) {
				const trigger = dataTriggers[a];
				if (trigger.value == spec.Name) {
					if ((trigger.type != '' && spec.ValueToCompare !== '' && spec.ValueToCompare != 'all') || (trigger.type == '' && spec.ValueToCompare == trigger.defaultlabel)) {
						spec.DataTriggerOperands = dataTriggerOperands[spec.Name];
						spec.DefaultLabel = trigger.defaultlabel;
						spec.Label = trigger.label;
						spec.Tooltip = trigger.tooltip;
						spec.Type = trigger.type;
						spec.Categorie = trigger.categorie;
						spec.Separator = trigger.separator;
						spec.NeedConfig = trigger.needconfig;
						spec.Saas = trigger.saas;

						if (spec.Type == 'textarea') {
							spec.ValueToCompare = spec.ValueToCompare.replace(/;/gi, '\n');
						}
						if (spec.Type == 'list') {
							spec.ValueToCompare = spec.ValueToCompare.toString();
						}
						if (spec.Name == 'TRGCHECKDEVICE') {
							const uniqueVal = [...new Set(spec.ValueToCompare)];
							const valueDevices = uniqueVal.map(element => {
								switch (element) {
									case 0:
										return 'desktop';
									case 1:
										return 'tablet';
									case 2:
										return 'mobile';
									default: return element;
								}
							});
							spec.ValueToCompare = valueDevices;
						}
						if ((spec.Type == 'multiplelist' || spec.Type == 'multiplelistsearch' || spec.Type == 'schedule') && spec.Name != 'TRGCHECKDEVICE') {
							const separator = spec.Separator ? spec.Separator : ',';
							if (typeof spec.ValueToCompare === 'string') {
								spec.ValueToCompare = spec.ValueToCompare.split(separator);
							}
						}
						specificationsForLoading.push(spec);
					}
				}
			}
		}
		return specificationsForLoading;
	};

	return {
		newSetUpSpecificationsForSaving,
		setUpSpecificationsForSaving,
		setUpSpecificationsForLoading,
		referenceDataTriggerOperands
	};
}