import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../../Components/Dropdown';
import Listbox from '../../../../Components/Listbox';
import ListboxItem from '../../../../Components/ListboxItem';
import './Nav.css';

export default function SelectDevice({
	deviceSelected,
	handleChangeDeviceSelected,
	initialValue,
	resetSource,
	handleChangeDeviceValidated
}) {

	const [t] = useTranslation(['common']);
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [btnClass, setbtnClass] = useState(['custom_input', 'clickable', 'w_auto', 'm', 'bg_white']);
	const [isActive, setisActive] = useState(deviceSelected.length > 0 ? true : false);

	function getLabel (){
		if(!deviceSelected){
			return;
		}
		if(deviceSelected.length === 0){
			return 'All';
		}
		if(deviceSelected.length > 1){
			return deviceSelected[0] + ' +' + (deviceSelected.length -1);
		}
		if(deviceSelected.length === 1){
			return deviceSelected[0] ;
		}

	}

	useEffect(()=>{
		setisActive(deviceSelected.length > 0 ? true : false);
	},[deviceSelected]);

	useEffect(()=>{
		if (isActive) {
			setbtnClass(classeElement => ([
				...classeElement, 'highlighted'
			]));
		}else{
			setbtnClass(classeElement => ([
				...classeElement.filter(el => el !== 'highlighted')
			]));
		}
	}, [isActive]);

	//const activeLabel = getLabel();
	const button = (
		<button className={btnClass.join(' ')}>
			<span className="custom_input_value has_label">
				<span className="custom_input_value_label">Devices</span>
				{deviceSelected ?
					<>{getLabel()}</>
					:
					<span className="custom_input_placeholder">{t('common.none')}</span>
				}
			</span>
			{deviceSelected && deviceSelected.length > 0 && 
				<span className="custom_input_reset"
					onMouseDown={(e) => {e.stopPropagation();}}
					onClick={(e) => {e.stopPropagation(); resetSource();}}>
					<i className="fas fa-times-circle"></i>
				</span>
			}
			<span className="custom_input_arrow"></span>
		</button>
	);

	return (
		<>
			<Dropdown
				isOpen={dropdownIsOpen}
				setIsOpen={() => setDropdownIsOpen(true)}
				onHide={() => { setDropdownIsOpen(false); handleChangeDeviceValidated(); }}
				button={button}
				minWidth={180}
			>
				<Listbox>
					<ListboxItem
						onClick={() => {resetSource(false); setDropdownIsOpen(false); handleChangeDeviceValidated(); }}
						message={<>All <span className='grey_3 s_13'>(no filter)</span></>}
						selected={!isActive}
						hasSelectedIcon={true}
					/>
					<li className='hr' />
					{initialValue && initialValue.map((value) => 
						<ListboxItem
							key={value.value}
							onClick={() => handleChangeDeviceSelected(value.value)}
							message={value.value}
							selected={deviceSelected.includes(value.value)}
							hasCheckbox={true}
						/>
					)}
				</Listbox>
			</Dropdown>
		</>
	);
}