import styles from '../ProductsInsights.module.css';
import TableHeadCol from '../../../../Components/Table/TableHeadCol';
import {ProductItemSpecific} from './ProductitemSpecific';
import PaginationProduct from '../Kpi/PaginationProduct';
import React from 'react';
import TableGridContainer from '../../../../Components/Table/TableGridContainer';
import TableHeadRow from '../../../../Components/Table/TableHeadRow';

const tableRowStyle = {
	gridTemplateColumns: '2fr .5fr 1.5fr 1.5fr',
};
import {useProductSpecificContext} from '../../context/ContextProductSpecific';
export const TableDataProductSpecific = () => {
	const {
		productsSelections,
		paginationOptions,
		currentPageProductList,
		setCurrentPageProductList,
		totalCount,
		handleChangePaginationOptions,
	} = useProductSpecificContext();
	return (
		<>
			<TableGridContainer hasAlternatingBackground={true} specialClasseName={styles.grid}>
				<TableHeadRow style={tableRowStyle} isSticky={true}>
					<TableHeadCol>Product</TableHeadCol>
					<TableHeadCol></TableHeadCol>
					<TableHeadCol
						onClick={()=>handleChangePaginationOptions('orderBy', 'survisits')}
						sortable={true}
						sort={paginationOptions.orderBy === 'survisits' ? paginationOptions.directions : null}
						tooltip={'Visits score compared to products in the same category (distribution in deciles - 1 to 10)'}
					>
						Visits compared to category
					</TableHeadCol>
					<TableHeadCol
						onClick={()=>handleChangePaginationOptions('orderBy', 'surconversions')}
						sortable={true}
						sort={paginationOptions.orderBy === 'surconversions' ? paginationOptions.directions : null}
						tooltip={'Conversion score compared to products in the same category (distribution in deciles - 1 to 10)'}
					>
						Conversion compared to category
					</TableHeadCol>
				</TableHeadRow>

				{productsSelections?.map((s, i) =>
					<ProductItemSpecific
						key={s.AssociatedProduct.Id + '_' + i}
						product={s}
						tableRowStyle={tableRowStyle}
					/>
				)}

			</TableGridContainer>
			<PaginationProduct
				totalCount={totalCount}
				pageSize={paginationOptions.rowsperpage}
				paginate={(e) => {
					handleChangePaginationOptions('poffset', e >= 0 ? e : 0);
				}}
				currentPage={currentPageProductList}
				setCurrentPage={(e) => setCurrentPageProductList(e)}
			/>
		</>
	);
};
