import React from 'react';
import {useCatalogueEditorContext} from './Context/CatalogueContextProvider';
import SelectDropdown from '../../../Components/SelectDropdown';
import Btn from '../../../Components/Btn';
import styles from './CatalogueContainer.module.css';
import {objectives} from '../Inspirations/InspirationData.js';
import {useTranslation} from 'react-i18next';
import { Skeleton } from '../../../Components/Skeleton/Skeleton';
//import {DeviceType} from './Context/TemplateTypes';

type Format = {
	id:string;
	label:string;
}

const formatList:Format[] = [
	{
		'id': 'all',
		'label': 'All formats'
	},
	{
		'id': 'inpage',
		'label': 'In-page'
	},
	{
		'id': 'headerbar',
		'label': 'Header bar'
	},
	{
		'id': 'popin',
		'label': 'Popup'
	},
	{
		'id': 'toaster',
		'label': 'Toaster'
	},
];

const formatListCustom:Format[] = [
	{
		'id': 'all',
		'label': 'All formats'
	},
	{
		'id': 'INAPP',
		'label': 'In-page'
	},
	{
		'id': 'HEADERBAR',
		'label': 'Header bar'
	},
	{
		'id': 'OVERLAY',
		'label': 'Overlay'
	},
	{
		'id': 'CUSTOM',
		'label': 'Custom'
	},
];


export const NavFormat = () => {

	const [t] = useTranslation('inspiration');

	const {
		selectedFormat,
		handleSelectFormat,
		//currentDevice,
		//setCurrentDevice,
		selectedObjective,
		mode,
		handleSelectObjective,
		templateGroupsToMap,
		isLoading
	} = useCatalogueEditorContext();

	if( mode === 'realizations' ){
		return <></>;
	}

	//const needObjectives = (mode === 'templates' || mode === 'library');
	const needObjectives = false;
	const listToMap = mode === 'custom' ? formatListCustom : formatList;
	// const optionsList = listToMap.map((item) => {
	// 	return {
	// 		label: item.label,
	// 		value: item.id
	// 	};
	// });

	let objectiveOptionsList:any[] = [];
	if (needObjectives) {
		objectiveOptionsList = objectives.map((item) => {
			return {
				label: t('objectives.' + item.id + '.label' ),
				value: item.id
			};
		});
		objectiveOptionsList.unshift({
			label: 'All',
			value: ''
		});
	}

	function getTitle(mode) {
		switch (mode) {
			case 'custom': return t('myCustomTemplates');
			case 'templates': return t('templateLibrary');
			case 'library': return t('styleLibrary');
			case 'realizations': return t('realizations');
		}
	}

	function handleToggleFormat(format) {
		if (format !== 'all' && format === selectedFormat) {
			format = 'all';
		}
		handleSelectFormat(format);
	}

	return (
		<div className={styles.viewHeaderLarge}>
			<div className={styles.grid_center}>
				<div className={styles.viewHeaderTitle}>{getTitle(mode)}</div>
				{isLoading &&
					<div className={styles.viewHeaderFilters}>
						<Skeleton
							appearance='tags'
							nb={5}
						/>
					</div>
				}
				{!isLoading && templateGroupsToMap.length > 0 &&
					<div className={styles.viewHeaderFilters}>
						<div className='btn_group'>
							{needObjectives &&
								<SelectDropdown
									label="Objective"
									labelPosition="inner"
									optionsList={objectiveOptionsList}
									onChange={(value) => handleSelectObjective(value, mode)}
									value={selectedObjective}
								/>
							}
							{listToMap.map((item) => 
								<Btn
									message={item.label}
									onClick={() => handleToggleFormat(item.id)}
									isActive={selectedFormat === item.id}
									light={true}
									style='outline'
									color='secondary'
									size='s'
									key={item.id}
								/>
							)}
							{/* <SelectDropdown
								label="Format"
								labelPosition="inner"
								optionsList={optionsList}
								onChange={(value) => handleSelectFormat(value)}
								value={selectedFormat}
							/> */}
							{/* <SelectDropdown
								label="View on"
								labelPosition="inner"
								optionsList={[
									{label: 'Mobile', value: DeviceType.Mobile},
									{label: 'Desktop', value: DeviceType.Desktop}
								]}
								onChange={(value) => setCurrentDevice(value)}
								value={currentDevice}
							/> */}
						</div>
					</div>
				}
			</div>
		</div>
	);
};
