import React from 'react';
import { Skeleton } from '../../Components/Skeleton/Skeleton';
import { KPI } from '../../Components/KPI/KPI';
import styles from './kpi.module.css';
import { getFormattedNumber } from '../../Util/numberFormats';

export function SumUp({
	item,
	size = 'm'
}) {

	const multiLineName = item?.name?.split('<br>');

	return (
		<div className={size == 'l' ? styles.sumup_item_large : styles.sumup_item}>
			{item.isLoading && 
				<Skeleton appearance="sumup" nb={1}/>
			}
			{!item.isLoading &&
				<>
					<div className={styles.sumup_value}>{item.value}</div>
					<div className={styles.sumup_label}>
						{multiLineName.map((line, i) =>
							<span key={i}>{line}</span>
						)}
					</div>
				</>
			}
		</div>
	);
}

export function Percent({
	item,
	showEvolution = true,
	size = 'm'
}) {

	return (
		<KPI
			isLoading={item.isLoading}
			label={item.name}
			value={getFormattedNumber(item.value, item.valueDecimals || 0, item.unit)}
			evolutionRate={showEvolution ? item.rate : null}
			size={size == 'm' || size == 's' ? size : undefined}
		/>
	);
}
