import React, { useState } from 'react';
import './SideBar.css';
import { CampaignsContext } from '../../context';
import Btn from '../../../../Components/Btn';
import Confirm from '../../../../Components/Confirm';
import Switch from '../../../../Components/Switch';
import IconBtn from '../../../../Components/IconBtn';
import EmptyState from '../../../../Components/EmptyState';
import {useTranslation} from 'react-i18next';

export default function SideBar() {

	const { t } = useTranslation(['common']);

	const {
		editorState,
		formatSavedStyles,
		canPublishStyles,
		handleStyleSelected,
		handleStyleChosen,
		handleStyleDeleted,
		handleStylePublished,
		displayBackToFormat,
		handleBackToFormat,
		isModuleEditorMode
	} = CampaignsContext();

	const [deleteStyleConfirmation, setDeleteStyleConfirmation] = useState(false);
	const [publishStyleConfirmation, setPublishStyleConfirmation] = useState(false);
	const [unpublishStyleConfirmation, setUnpublishStyleConfirmation] = useState(false);
	const [loadingAnimationStart, setLoadingAnimationStart] = useState(false);
	const [loadingAnimationEnd, setLoadingAnimationEnd] = useState(false);

	const beyableStyles = formatSavedStyles.filter(s => s.isPublished === true);
	const userStyles = formatSavedStyles.filter(s => s.isPublished !== true);

	const confirmDeleteStyle = () => {
		const { style } = deleteStyleConfirmation;
		if (style) {
			handleStyleDeleted(style);
		}
		setDeleteStyleConfirmation(false);
	};

	const confirmPublishStyle = () => {
		const { style } = publishStyleConfirmation;
		if (style) {
			handleStylePublished(style, true);
		}
		setPublishStyleConfirmation(false);
	};

	const confirmUnpublishStyle = () => {
		const { style } = unpublishStyleConfirmation;
		if (style) {
			handleStylePublished(style, false);
		}
		setUnpublishStyleConfirmation(false);
	};

	const handleStyleBeforeChosen = () => {
		if (!isModuleEditorMode()) {
			handleStyleChosen();
			return;
		}

		// Fake computing animation
		setLoadingAnimationStart(true);
		setTimeout(function(){
			setLoadingAnimationEnd(true);
		}, 2200);
		setTimeout(function(){
			handleStyleChosen();
			setLoadingAnimationEnd(false);
			setLoadingAnimationStart(false);
		}, 3000);
	};

	const handleTogglePublish = (style, wantPublish) => {
		if (wantPublish) {
			setPublishStyleConfirmation({ style });
		} else {
			setUnpublishStyleConfirmation({ style });
		}
	};

	return (
		<>

			<section className="format_sidebar_section flex_item_full vscroll_custom">
				{editorState.currentStyle &&
					<div className='format_sidebar_item_group'>
						<StyleButton
							style={editorState.currentStyle.style}
							text={editorState.currentStyle.isNotSaved ? 'Current style (not saved)' : 'Current style'}
							canBeDeleted={false}
							canBePublished={false}
							onSelect={handleStyleSelected}
							isSelected={editorState.selectedStyle.id === 'current'}
							onDelete={setDeleteStyleConfirmation}
						/>
					</div>
				}

				<div className='format_sidebar_item_group'>
					<div className='format_sidebar_item_group_label'>Style library</div>
					<StyleButton
						style={editorState.defaultStyle}
						text={'Default style'}
						canBeDeleted={false}
						canBePublished={false}
						onSelect={handleStyleSelected}
						onDelete={setDeleteStyleConfirmation}
						isSelected={editorState.selectedStyle.id === 'default'}
					/>
					{beyableStyles && beyableStyles.map(style => 
						<StyleButton
							key={style.id}
							style={style}
							text={style.name}
							canBeDeleted={true}
							canBePublished={canPublishStyles}
							onSelect={handleStyleSelected}
							onDelete={setDeleteStyleConfirmation}
							isSelected={editorState.selectedStyle.id === style.id}
							onTogglePublish={handleTogglePublish}
						/>
					)}
				</div>

				{userStyles && userStyles.length >= 1 &&
					<div className='format_sidebar_item_group'>
						<div className='format_sidebar_item_group_label'>Saved styles</div>
						{userStyles.map(style => 
							<StyleButton
								key={style.id}
								style={style}
								text={style.name}
								canBeDeleted={true}
								canBePublished={canPublishStyles}
								onSelect={handleStyleSelected}
								onDelete={setDeleteStyleConfirmation}
								isSelected={editorState.selectedStyle.id === style.id}
								onTogglePublish={handleTogglePublish}
							/>
						)}
					</div>
				}
			</section>
			<section className="format_sidebar_section format_sidebar_footer flex_item_fix">
				<div className='flex'>
					{displayBackToFormat &&
						<div className='format_sidebar_btn_col'>
							<Btn
								color="secondary"
								style="outline"
								icon="fas fa-chevron-left"
								message={t('designEditor.backToTemplates')}
								size="l"
								fullWidth={true}
								onClick={() => handleBackToFormat()}
							/>
						</div>
					}
					<div className='format_sidebar_btn_col'>
						<Btn
							onClick={() => handleStyleBeforeChosen()}
							disabled={!editorState.selectedStyle.id}
							size="l"
							fullWidth={true}
							message={t('designEditor.chooseThisStyle')}
							icon={displayBackToFormat ? 'fas fa-chevron-right' : ''}
							iconPosition="after"
						/>
					</div>
				</div>
			</section>
			<Confirm
				isOpen={deleteStyleConfirmation}
				onClose={() => setDeleteStyleConfirmation(false)}
				title="Delete style"
				text="Are you sure you want to delete this style?"
				cancelText="Cancel"
				confirmText="Delete"
				confirmColor="alert"
				confirmCallback={confirmDeleteStyle}
			/>
			<Confirm
				isOpen={publishStyleConfirmation}
				onClose={() => setPublishStyleConfirmation(false)}
				title="Publish style"
				text="Are you sure you want to publish this style?"
				cancelText="Cancel"
				confirmText="Publish"
				confirmCallback={confirmPublishStyle}
			/>
			<Confirm
				isOpen={unpublishStyleConfirmation}
				onClose={() => setUnpublishStyleConfirmation(false)}
				title="Unpublish style"
				text="Are you sure you want to unpublish this style?"
				cancelText="Cancel"
				confirmText="Unpublish"
				confirmColor="alert"
				confirmCallback={confirmUnpublishStyle}
			/>
			{loadingAnimationStart &&
				<div className={loadingAnimationEnd ? 'format_loading_wrapper animated bounceOutDown' : 'format_loading_wrapper animated bounceInDown'}>
					<EmptyState
						title="Almost READY!"
						text="Applying selected styles..."
						textSize="xl"
						spinner={!loadingAnimationEnd}
						icon={loadingAnimationEnd ? 'fas fa-check-circle c_green' : null }
					/>
				</div>
			}
		</>
	);
}

const StyleButton = ({
	style,
	text,
	canBeDeleted,
	canBePublished,
	isSelected = false,
	onSelect,
	onDelete,
	onTogglePublish
}) => {

	return (
		<div onClick={() => onSelect(style)} className={`format_sidebar_item ${isSelected ? 'active' : ''}`}>
			<div className="format_sidebar_item_body">
				<div className="format_sidebar_item_name">{text}</div>
			</div>
			<div className='format_sidebar_item_actions'>
				{canBePublished && style.isPublished &&
					<IconBtn
						icon='fas fa-ban'
						onClick={(e) => { e.stopPropagation(); onTogglePublish(style, false); }}
						theme={isSelected ? 'light' : 'medium'}
						hoverColor='alert'
						size="s"
						tooltip="Unpublish"
					/>
				}
				{canBePublished && !style.isPublished &&
					<IconBtn
						icon='fas fa-rocket'
						onClick={(e) => { e.stopPropagation(); onTogglePublish(style, true); }}
						theme={isSelected ? 'light' : 'medium'}
						hoverColor='success'
						size="s"
						tooltip="Publish"
					/>
				}
				{canBeDeleted && !style.isPublished &&
					<IconBtn
						icon='fas fa-trash'
						onClick={(e) => { e.stopPropagation(); onDelete({ style }); }}
						theme={isSelected ? 'light' : 'medium'}
						hoverColor='alert'
						size="s"
						tooltip="Delete"
					/>
				}
			</div>
		</div>
	);
};
