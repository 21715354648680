import React, { createContext, useState, useContext,useEffect } from 'react';
import {findNavLabel, initialDates} from '../config.js';
import moment, { Moment } from 'moment';
import {FunnelBridgeCrud} from '../Funnel/NavigationFunnel/NavigationFunnelContainer/api/CrudFunnel';
const filterToExclude:string[] = ['VisEngLevel', 'Device', 'UtcDate','PageType', 'FirstPageType','LastPageType',];
const filterToExcludeSplit:string[] = ['PageType', 'FirstPageType','LastPageType', 'UtcDate'];
import {
	DeviceType,
	Devices,
	initDates,
	navFilterP,
	engagementLevels,
	EngagementLevel,
	ObjectFunnelApiPost,
	PartsItem,
	ObjectFunnelApiPostResponse,
	ValueElementWithLabelTraduction, OptionnalFilter
} from '../Funnel/NavigationFunnel/NavigationFunnelTypes';
import {useFiltersEncode} from '../Funnel/NavigationFunnel/utils/useFiltersEncode';
import {formatDate} from '../Funnel/NavigationFunnel/config';
import {getColorIdByOrder}  from '../Funnel/NavigationFunnel/utils/utils';

// import {devices} from '../NavigationFunnel/config';
type FunnelContextType = {
	isLoading: boolean;
	navFilter:navFilterP;
	initialFrom:string;
	initialTo:string;
	setNavFilter:  React.Dispatch<React.SetStateAction<navFilterP>>;
	customDateRange: {
		startDate: Moment,
		endDate:Moment
	}
	setCustomDateRange:  React.Dispatch<React.SetStateAction<{
		startDate: Moment,
		endDate:Moment
	}>>;
	initiNavDate:any;
	isCustomSelected: boolean
	setisCustomSelected: React.Dispatch<React.SetStateAction<boolean>>;
	handleChangeDeviceSelected: React.Dispatch<React.SetStateAction<any>>;
	resetDevices:React.Dispatch<React.SetStateAction<any>>;
	deviceSelected:Devices | undefined;
	$location:any;
	initializeFilters:boolean;
	needToRefreshAngularJs: (boolean)=>void;
	visitorEngamentSelected: Array<EngagementLevel>;
	setVisitorEngamentSelected: React.Dispatch<React.SetStateAction<Array<EngagementLevel>>>;
	dataRaw: ObjectFunnelApiPostResponse | undefined;
	rawDimensions: Array<PartsItem> | undefined;
	setNeedToFetchingMetricsAndDimensions: React.Dispatch<React.SetStateAction<boolean>>;
	needToFetchingMetricsAndDimensions:boolean;
	feetchingData:boolean;
	listMetrics:ValueElementWithLabelTraduction[] | undefined;
	handleSelectNewMetric: (str : string) => void;
	metricUserSelected: string | undefined;
	resetMetrics: ()=>void;
	splitSelected:string | undefined;
	handleSelectNewSplit:(str : string) => void;
	resetSplit: ()=>void;
	optionalFiltersSelected: string[];
	handleSelectOrUnSelectFilter: (str: Array<string> ) => void;
	filterOptionnal: OptionnalFilter[] | undefined;
	handleChangeOptionnalKeyOption: (s:any) => void;
	setListOfDimensionsSplit:  React.Dispatch<React.SetStateAction<string[]>>;
	listOfDimensionsSplit: string[];
	detectedErrors:Element[] | undefined;
	typeEnv : string;
	filterToExclude:string[];
	filterToExcludeSplit:string[],
	hasError:boolean
};
type Element = {
	key : string,
	value: any
}
interface NavigationFunnelContextProviderProps {
	$http: any;
	children?: React.ReactNode;
	$rootScope: any;
	$routeParams: any;
	$timeout: any;
	$location: any;
	AuthServices: any;

}
function decodeDatasToObj(d:any){
	let filterString = '';
	d.map((key):any => {
		return filterString += '$$' + `${key.key}=${key.value}`;
	});
	return filterString;
}
const NavigationFunnelContext = createContext<FunnelContextType | undefined>(undefined);

// Custom hook to use the AiInsightsContext
export const useAnavigationFunnelContext = () => {
	const contextValue = useContext(NavigationFunnelContext);
	if (contextValue === undefined) {
		throw new Error('useAiInsightsContext must be used within an funnel element');
	}
	return contextValue;
};

const typeEnv = 'navigationFunnel';
export const NavigationFunnelContextProvider: React.FC<NavigationFunnelContextProviderProps> = ({ children,$location,$rootScope,$routeParams,$http}) => {
	const {devices,datesFilter,engagements,needRefreshAngularJs,metricSelected,split,filterOptionnal} = useFiltersEncode(typeEnv);

	const { postQueryFunnel,getDimensions } = FunnelBridgeCrud($http,$rootScope,$routeParams);
	const [initializeFilters, setInitializeFilters] = useState<boolean>(true);
	const [optionalFiltersSelected, setOptionalFiltersSelected] = useState<string[]>([]);
	const [feetchingData, setFeetchingData] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [hasError, setHasError] = useState<boolean>(false);
	const [needToFetchingMetricsAndDimensions, setNeedToFetchingMetricsAndDimensions] = useState(true);
	const [isCustomSelected, setisCustomSelected] = useState<boolean>(false);
	const [customDateRange, setCustomDateRange] = useState( {
		startDate: moment().subtract(1, 'month'),
		endDate: moment().subtract(1, 'day')
	});
	const { initialFrom, initialTo,initialLabel }:initDates = initialDates;

	const [visitorEngamentSelected, setVisitorEngamentSelected] = useState<Array<EngagementLevel>>([]);
	const [navFilter, setNavFilter] = useState<navFilterP>({
		fromDate: initialFrom,
		toDate: initialTo,
		filters: [],
		label: initialLabel
	});

	const [deviceSelected, setdeviceSelected] = useState<Devices | undefined>();
	const [splitSelected, setSplitSelected] = useState<string >('');

	const [dataRaw, setDataRaw] = useState<ObjectFunnelApiPostResponse | undefined>();
	const [rawDimensions, setRawDimensions] = useState<Array<PartsItem> | undefined>();

	const [metricUserSelected, setMetricUserSelected] = useState<string | undefined>();
	const [listMetrics] = useState<ValueElementWithLabelTraduction[] | undefined>();
	const [listOfDimensionsSplit, setListOfDimensionsSplit] = useState<string[]>([]);
	const [listOfFilteToChecks, setListOfFilteToChecks] = useState<Element[]>([]);
	const [detectedErrors, setDetectedErrors] = useState<Element[] | undefined>();

	function getEngagementsLevels(arrayOfElement) {
		return arrayOfElement.reduce((acc, engagementItem) => {
			const itemIsInArray = engagementLevels.find(item => item.value === engagementItem);
			if (itemIsInArray) {
				acc.push(itemIsInArray.value);
			}
			return acc;
		}, []);
	}

	useEffect(()=>{
		if(needRefreshAngularJs){
			const datesFrom =  formatDate(datesFilter.fromDate);
			const datesTo =  formatDate(datesFilter.toDate);
			$location.search('devices',devices.join(','));
			$location.search('dates',`fromDate=${datesFrom},toDate=${datesTo},label=${datesFilter.label}`);
			$location.search('engagement',engagements.join(','));
			$location.search('metric',metricSelected);
			$location.search('split',split);
			const opts = decodeDatasToObj(filterOptionnal);
			$location.search('optionnal',opts);
			needToRefreshAngularJs(true);

		}else{
			const filtersToInspect:Element[] = [
			];
			if(devices){
				setInitializeFilters(false);
				setdeviceSelected(devices);
				filtersToInspect.push({
					key : 'Device',
					value : devices
				});
			}
			if(datesFilter){
				setNavFilter(datesFilter);
				if(datesFilter.label === 'Custom'){

					setCustomDateRange({
						startDate: moment(datesFilter.fromDate),
						endDate: moment(datesFilter.toDate)
					});
				}
			}
			if(engagements){
				const newEngagements = getEngagementsLevels(engagements);
				setVisitorEngamentSelected(newEngagements);
				filtersToInspect.push({
					key : 'VisEngLevel',
					value : newEngagements
				});
			}

			if(split){
				setSplitSelected(split);
			}
			if(metricSelected){
				setMetricUserSelected(metricSelected);
			}
			if(filterOptionnal){
				const arrayOfKeys = filterOptionnal.map(x => x.key);
				filterOptionnal.forEach(item => filtersToInspect.push(item) );
				setOptionalFiltersSelected(arrayOfKeys);
			}
			setFeetchingData(true);
			setListOfFilteToChecks(filtersToInspect);
		}
	},[devices,datesFilter,engagements,metricSelected,split]);
	const initiNavDate = (navState:navFilterP) => {
		if(navState.label === 'Custom'){
			return {
				initialLabel: findNavLabel(navState.fromDate, navState.toDate),
				initialFrom: moment(navState.fromDate).format('YYYY/MM/DD'),
				initialTo:  moment(navState.toDate).format('YYYY/MM/DD'),
			};
		}else{
			return {
				initialLabel: findNavLabel(navState.fromDate, navState.toDate),
				initialFrom: navState.fromDate,
				initialTo: navState.toDate,
			};
		}
	};

	function handleChangeDeviceSelected(elem:DeviceType){
		if(!deviceSelected) return;

		const newKey = deviceSelected.find(el => el === elem);
		const oldD = [...deviceSelected];
		const indefOfVal = oldD.indexOf(elem);
		if(indefOfVal !== -1){
			setdeviceSelected(oldD.filter(x => x !== newKey));
		}else{
			setdeviceSelected([...oldD,elem]);
		}

		const funnelStorage = localStorage.getItem(typeEnv);

		if(indefOfVal !== -1){
			const devicesS = oldD.filter(x => x !== elem);
			setdeviceSelected(devicesS);
			$location.search('devices', null);
			$location.search('devices', devicesS.join(','));
			if(funnelStorage){
				const newFunnel = JSON.parse(funnelStorage);
				const reset = {...newFunnel, devices: devicesS.join(',')};
				localStorage.setItem(typeEnv,JSON.stringify(reset));
			}else{
				localStorage.setItem(typeEnv,JSON.stringify({ devices: devicesS.join(',') }));
			}
			needToRefreshAngularJs(true);
		}else{
			const newValue = [...oldD,elem];
			setdeviceSelected(newValue);
			$location.search('devices', null);
			$location.search('devices',newValue.join(','));
			if(funnelStorage){
				const newFunnel = JSON.parse(funnelStorage);
				const reset = {...newFunnel, devices: newValue.join(',')};
				localStorage.setItem(typeEnv,JSON.stringify(reset));

			}else{
				localStorage.setItem(typeEnv,JSON.stringify({ devices: newValue.join(',') }));
			}
			needToRefreshAngularJs(true);
		}
	}
	function resetDevices() {
		setdeviceSelected([]);
		const funnelStorage = localStorage.getItem(typeEnv);
		if(funnelStorage){
			const newFunnel = JSON.parse(funnelStorage);
			const reset = {...newFunnel, devices: ''};
			localStorage.setItem(typeEnv,JSON.stringify(reset));

		}
		$location.search('devices', '');
		needToRefreshAngularJs(false);
	}
	function needToRefreshAngularJs(bool){
		$rootScope.$broadcast('pageChanged',bool);
	}
	function fetchMetricsAndDimensions(datesFilter){
		const datesFrom =  formatDate(datesFilter.fromDate);
		const datesTo =  formatDate(datesFilter.toDate);
		getDimensions(datesFrom,datesTo,(data: any) => {
			setRawDimensions(data);
		},
		(err: any) => {
			console.error('Error fetching Insights:', err);
		});
		// getMetrics(datesFrom,datesTo,(data: ValueElementWithLabelTraduction[]) => {
		// 	setListMetrics(data);
		// },
		// (err: any) => {
		// 	console.error('Error fetching Insights:', err);
		// });
		setNeedToFetchingMetricsAndDimensions(false);
	}

	function handleSelectNewMetric(metric : string):void{
		$location.search('metric', metric);
		setMetricUserSelected(metric);
		needToRefreshAngularJs(false);
	}
	function resetMetrics():void{
		setMetricUserSelected('');
	}
	function handleSelectNewSplit(dimension : string):void{
		$location.search('split', dimension);
		setSplitSelected(dimension);
		needToRefreshAngularJs(false);
		const funnelStorage = localStorage.getItem(typeEnv);
		if(funnelStorage){
			const newFunnel = JSON.parse(funnelStorage);
			const reset = {...newFunnel, split: dimension};
			localStorage.setItem(typeEnv,JSON.stringify(reset));

		}else{
			localStorage.setItem(typeEnv,JSON.stringify({ split: dimension }));
		}
	}
	function handleSaveFunnelLocalStorageForOptionnal(filterString:string){
		const funnelStorage = localStorage.getItem(typeEnv);
		if(funnelStorage){
			const newFunnel = JSON.parse(funnelStorage);
			const reset = {...newFunnel, optionnal: filterString};
			localStorage.setItem(typeEnv,JSON.stringify(reset));

		}else{
			localStorage.setItem(typeEnv,JSON.stringify({ optionnal: filterString }));
		}
	}
	function handleSelectOrUnSelectFilter(element:string[]){
		setOptionalFiltersSelected(element);
		const optionnalLocation = $location.$$search;
		const optionnalArrayInUrl:any = [];
		const hasOptionnalFilter = optionnalLocation['optionnal'];
		if(hasOptionnalFilter){
			const valueKeys = hasOptionnalFilter.split('$$');
			valueKeys.forEach(k => {
				if(k !== ''){
					const keyValue = k.split('=');
					optionnalArrayInUrl.push({
						value : keyValue[1],
						key : keyValue[0]
					});
				}

			});
		}
		let filterString = '';
		element.forEach(key => {
			const optionWithValues = optionnalArrayInUrl.find(el => el.key === key);
			if(optionWithValues){
				return filterString += '$$' + `${optionWithValues.key}=${optionWithValues.value}`;
			}else{
				return filterString += '$$' + `${key}=${''}`;
			}
		});
		$location.search('optionnal', filterString);
		handleSaveFunnelLocalStorageForOptionnal(filterString);
		needToRefreshAngularJs(false);
	}

	function handleChangeOptionnalKeyOption(elementAndKey : any):void{
		const optionnalLocation = $location.$$search.optionnal;
		const optionnalArrayInUrl:any = [];
		const valueKeys = optionnalLocation.split('$$');
		valueKeys.forEach(k => {
			if(k !== ''){
				const keyValue = k.split('=');
				optionnalArrayInUrl.push({
					value : keyValue[1],
					key : keyValue[0]
				});
			}

		});
		const findElement = optionnalArrayInUrl.find(el => el.key === elementAndKey.key);

		let filterString = '';
		if(findElement){
			optionnalArrayInUrl.map((key):any => {
				if(key.key === findElement.key){
					return filterString += '$$' + `${key.key}=${elementAndKey.value}`;
				}else{
					return filterString += '$$' + `${key.key}=${key.value}`;
				}
			});
			$location.search('optionnal', filterString);
			needToRefreshAngularJs(false);
		}else{
			optionnalArrayInUrl.push(elementAndKey);
			optionnalArrayInUrl.forEach((key):any => {
				return filterString += '$$' + `${key.key}=${key.value}`;
			});
			$location.search('optionnal', filterString);
			needToRefreshAngularJs(false);
		}
		handleSaveFunnelLocalStorageForOptionnal(filterString);
	}
	function resetSplit():void{
		setSplitSelected('');
	}

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		const timer = setTimeout(() => {
			if (feetchingData ) {
				const dynamicFilters: any = {};
				filterOptionnal?.reduce(
					(acc, filter) => {
						if (filter.value) {
							Object.assign(dynamicFilters, {[filter.key]:
							{
								equals: filter.value
							}});
							// dynamicFilters[filter.key] = {equals: filter.value};
						}
						return acc;
					},
					{}
				);

				Object.assign(dynamicFilters, { UtcDate: {
					greaterThan: moment(datesFilter.fromDate).format('YYYY-MM-DD'),
					lowerThan: moment(datesFilter.toDate).format('YYYY-MM-DD')
				} });

				if(deviceSelected && deviceSelected.length > 0){
					Object.assign(dynamicFilters,
						{
							Device: {
								in: deviceSelected
							}
						});
				}
				const split = splitSelected !== 'none' ? splitSelected : '';
				const ObjectFunnel: ObjectFunnelApiPost = {
					parts: [
						{
							partName: 'SessionFirstPT',
							metric: 'SessionCount', // metricUserSelected,
							'dimensions':
								[
									'FirstPageType',
									...(split ? [split] : [])
								]
						},
						{
							partName: 'PageType',
							metric: 'SessionCount', // metricUserSelected,
							dimensions:
								[
									'PageType',
									...(split ? [split] : [])
								]
						},
						{
							partName: 'SessionLastPT',
							metric: 'SessionCount', // metricUserSelected,
							dimensions:
								[
									'LastPageType',
									...(split ? [split] : [])
								]
						},
					],
					filters: {
						...dynamicFilters
					}
				};
				fetchMetricsAndDimensions(datesFilter);
				postQueryFunnel(
					ObjectFunnel,
					(data: ObjectFunnelApiPostResponse) => {
						// const keyToFilter = ['PageType', 'FirstPageType', 'LastPageType'];
						// const valuesToDelete = ['', 'O', 'G'];
						//
						// function filterData(obj: any) {
						// 	Object.values(obj.parts).forEach(part => {
						// 		// @ts-ignore
						// 		part.items = part.items.filter(item => {
						// 			return !Object.entries(item.dimensions).some(([key, value]) =>
						// 				// @ts-ignore
						// 				keyToFilter.includes(key) && valuesToDelete.includes(value)
						// 			);
						// 		});
						// 	});
						// 	return obj;
						// }
						// const dataFiltered = filterData(data);
						setDataRaw(data);
						setIsLoading(false);
						setHasError(false);
						setFeetchingData(false);
					},
					(err: any) => {
						console.error('Error fetching Insights:', err);
						setIsLoading(false);
						setHasError(true);
						setFeetchingData(false);
					},
					signal
				);

			}
		},150);
		return () => {
			if (timer) clearTimeout(timer);
			controller.abort();
		};
	}, [feetchingData,splitSelected,datesFilter,optionalFiltersSelected]);
	useEffect(()=>{
		if( splitSelected && rawDimensions){
			const isEmpty = (rawDimensions) => Object.keys(rawDimensions).length === 0;
			const isEmptyBool = isEmpty(rawDimensions);
			if(!isEmptyBool){
				if(splitSelected === 'none'){
					setListOfDimensionsSplit([]);
				}else{
					const splitDimensions = rawDimensions
						? rawDimensions[splitSelected].values.map((x,idx) => ({
							label: x.label,
							id: x.id,
							color: getColorIdByOrder(idx)
						}))
						: [];
					setListOfDimensionsSplit(splitDimensions);
				}
			}
		}
	},[splitSelected,rawDimensions]);
	function checkSelectedElementIsInList(selectedElem,rawDimensions){
		const arrayOfErrors:any = [];
		selectedElem.forEach(element => {
			if(rawDimensions.hasOwnProperty(element.key)){
				const valueElement = rawDimensions[element.key].values;
				if(typeof  element.value === 'string'){
					const valueFound = valueElement.some((v) => v.id === element.value);
					if (!valueFound && element.value !== '' ) {
						arrayOfErrors.push({
							key : rawDimensions[element.key].label,
							value: element.value
						});
					}
				}else{
					const allPresent = element.value.every(val =>
						valueElement.some(v => v.id === val)
					);
					if (!allPresent ) {
						arrayOfErrors.push({
							key : rawDimensions[element.key].label,
							value: element.value
						});
					}
				}
			}
			return arrayOfErrors;
		});
		setDetectedErrors(arrayOfErrors);
	}
	useEffect(()=>{
		if(rawDimensions && datesFilter && optionalFiltersSelected && listOfFilteToChecks){
			checkSelectedElementIsInList(listOfFilteToChecks,rawDimensions);
		}
	},[rawDimensions,datesFilter,optionalFiltersSelected,feetchingData,listOfFilteToChecks]);
	
	return (
		<NavigationFunnelContext.Provider
			value={{
				isLoading,
				feetchingData,
				navFilter,
				setNavFilter,
				initialFrom,
				initialTo,
				customDateRange,
				setCustomDateRange,
				initiNavDate,
				isCustomSelected,
				setisCustomSelected,
				handleChangeDeviceSelected,
				resetDevices,
				deviceSelected,
				$location,
				initializeFilters,
				needToRefreshAngularJs,
				visitorEngamentSelected,
				setVisitorEngamentSelected,
				dataRaw,
				rawDimensions,
				setNeedToFetchingMetricsAndDimensions,
				needToFetchingMetricsAndDimensions,
				listMetrics,
				handleSelectNewMetric,
				metricUserSelected,
				resetMetrics,
				splitSelected,
				handleSelectNewSplit,
				resetSplit,
				optionalFiltersSelected,
				handleSelectOrUnSelectFilter,
				filterOptionnal,
				handleChangeOptionnalKeyOption,
				setListOfDimensionsSplit,
				listOfDimensionsSplit,
				detectedErrors,
				typeEnv,
				filterToExclude,
				filterToExcludeSplit,
				hasError,
			}}
		>
			{children}
		</NavigationFunnelContext.Provider>
	);
};

export default NavigationFunnelContextProvider;
