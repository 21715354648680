import React, { useState } from 'react';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';
import { useEmailTemplateListContext } from './context/EmailTemplateListContextProvider';
import { EmailTemplateInListApiType, EmailTemplateStatus } from '../../../Services/EmailTemplateServicesTypes'

type EmailTemplateStatusDropDownType = {
	emailTemplate: EmailTemplateInListApiType
}

export default function EmailTemplateStatusDropDown({ emailTemplate }: EmailTemplateStatusDropDownType) {

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const {
		changeEmailTemplateStatus
	} = useEmailTemplateListContext();

	const handleChange = (status: EmailTemplateStatus) => {
		changeEmailTemplateStatus(emailTemplate.id, status);
		setDropdownIsOpen(false);
	};

	function getFriendlyValue(value: EmailTemplateStatus) {
		if (value === EmailTemplateStatus.Active) {
			return 'Active';
		}
		return 'Inactive';
	}

	const colorClass = emailTemplate.status === EmailTemplateStatus.Active ? 'ongoing' : 'past';

	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={
				<a className="campaign_status_tag has_arrow clickable">
					<span className={'campaign_status_tag_color ' + colorClass}></span>
					<span className="campaign_status_tag_name">{getFriendlyValue(emailTemplate.status)}</span>
				</a>
			}
		>
			<Listbox>
				<ListboxItem
					message={getFriendlyValue(EmailTemplateStatus.Active)}
					onClick={() => handleChange(EmailTemplateStatus.Active)}
					selected={emailTemplate.status === EmailTemplateStatus.Active}
					hasSelectedIcon={true}
				/>
				<ListboxItem
					message={getFriendlyValue(EmailTemplateStatus.Inactive)}
					onClick={() => handleChange(EmailTemplateStatus.Inactive)}
					selected={emailTemplate.status === EmailTemplateStatus.Inactive}
					hasSelectedIcon={true}
				/>
			</Listbox>
		</Dropdown>
	);
}
