
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './NotificationCenter.module.css';

export default function NotificationCenterCreationButton({
	isAdmin,
	$routeParams,
	permissionsAccount
}) {

	const [t] = useTranslation(['common']);
	const createNotifCampaigns = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'NOTIFICATIONCENTER_CAN_CREATE');
	const canCreateNotificationCampaigns = createNotifCampaigns && createNotifCampaigns.Value == true;

	if (!canCreateNotificationCampaigns) return <></>;

	return (
		<div className={styles.centerAddWrapper}>
			<Link className={styles.centerAdd}
				to={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?notificationCenter&from=NotificationCenter/Dashboard&ka=${$routeParams.ka}&ku=${$routeParams.ku}` : '/Campaigns/Editor?notificationCenter&from=NotificationCenter/Dashboard'}>
				<span>
					<span className={styles.centerAddIcon}>
						<i className="fas fa-plus-circle"></i>
					</span>
					<span className={styles.centerAddLabel}>
						{t('nav.newNotificationCenterCenter')}
					</span>
				</span>
			</Link>
		</div>
	);
}