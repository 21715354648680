import React, {useState, useEffect}  from 'react';
import {useProductInsightsContext} from '../../context/ContextProductInsights';
import { KPIArticleList, KPIArticle } from '../../../../Components/KPI/KPI';
import { getFormattedNumber } from '../../../../Util/numberFormats';

import { ProductScore } from '../../../../Components/Product/ProductScore';
import {BestProducts} from './BestProducts';
import {WorstProducts} from './WorstProducts';

export const TopInsights = () => {

	const {
		dataInsights,
		ComparisonSelected,
		isLoadingForInsightsKpis
	} = useProductInsightsContext();

	const [averageScore, setAverageScore]:any = useState({});
	const [bestCategory, setBestCategory]:any = useState({});

	useEffect(() => {
		const averageScore = dataInsights.find((i) => i.key === 'AverageScore');
		setAverageScore(averageScore || {});

		const bestCategory = dataInsights.find((i) => i.key === 'MostAttractiveCategory');
		setBestCategory(bestCategory || {});
	}, [dataInsights]);

	function getFinalScore(score = 0):number {
		return Math.round(score * 100);
	}

	return (
		<>
			{/*/// insights without comparison*/}
			{!ComparisonSelected && 
				<KPIArticleList>
					{averageScore &&
						<KPIArticle
							label={averageScore.label}
							isLoading={isLoadingForInsightsKpis}
						>
							<div className='mb_3'>
								<span className='s_20 fw_medium'>{getFormattedNumber(getFinalScore(averageScore.value))}</span>
								<span className='s_13 grey_2'> / 100</span>
							</div>
							<ProductScore
								value={getFinalScore(averageScore.value)}
								displayValue={getFormattedNumber(getFinalScore(averageScore.value))}
							/>
						</KPIArticle>
					}
					{bestCategory &&
						<KPIArticle
							label={bestCategory.label}
							isLoading={isLoadingForInsightsKpis}
						>
							<div className='mb_3'>
								<span className='s_20 fw_medium'>{bestCategory.value}</span>
							</div>
							<ProductScore
								value={getFinalScore(bestCategory.score)}
								displayValue={getFormattedNumber(getFinalScore(bestCategory.score))}
							/>
						</KPIArticle>
					}
				</KPIArticleList>
			}
			{/*/// insights with comparison*/}
			{ComparisonSelected &&
				<KPIArticleList>
					{averageScore &&
						<KPIArticle
							label={averageScore.label}
							isLoading={isLoadingForInsightsKpis}
						>
							<div className='mb_3'>
								<span className='s_20 fw_medium'>
									<span className={getFinalScore(averageScore.ValueComparison - averageScore.ValueReference) >= 0 ? 'c_success' : 'c_alert'}>
										{getFormattedNumber(getFinalScore(averageScore.ValueComparison - averageScore.ValueReference), 0, '', true)}
									</span>
								</span>
							</div>
							<ProductScore
								value={getFinalScore(averageScore.ValueComparison)}
								displayValue={getFormattedNumber(getFinalScore(averageScore.ValueComparison))}
							/>
						</KPIArticle>
					}
					{bestCategory &&
						<>
							<KPIArticle
								label={bestCategory.label}
								labelDescription={'(Studied period)'}
								isLoading={isLoadingForInsightsKpis}
							>
								<div className='mb_3'>
									<div className='s_20 fw_medium'>{bestCategory.ValueComparison}</div>
								</div>
								<ProductScore
									value={getFinalScore(bestCategory.ValueComparisonScore)}
									displayValue={getFormattedNumber(getFinalScore(bestCategory.ValueComparisonScore))}
								/>
							</KPIArticle>
							<KPIArticle
								label={bestCategory.label}
								labelDescription={'(Previous period)'}
								isLoading={isLoadingForInsightsKpis}
							>
								<div className='mb_3'>
									<div className='s_20 fw_medium'>{bestCategory.ValueReference}</div>
								</div>
								<ProductScore
									value={getFinalScore(bestCategory.ValueReferenceScore)}
									displayValue={getFormattedNumber(getFinalScore(bestCategory.ValueReferenceScore))}
								/>
							</KPIArticle>
						</>
					}
				</KPIArticleList>
			}

			{/*/// best 5 products with comparison*/}
			<BestProducts />
			<WorstProducts/>
		</>
	);
};
