export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
export function getProductsAccountByCategory(arrayOfProducts, category) {
	return arrayOfProducts[category];
}
export const replaceId = '6aa45500-cb4e-4e44-abd6-0d11fea9bdaa';
export const promoteOrDemoteId = '71865d66-e9dd-4c49-ba80-ec728e38bd7c';
export const demoteId = '46a6e88f-6c1c-44b5-8e37-98857c2127dd';
export const promoteId = '71865d66-e9dd-4c49-ba80-ec728e38bd7c';

export const rankingExample = {
	'name': 'My ranking',
	'cintext': 'Product',
	'sorting': [
		// {
		// 	"collationId": "74569936-b08e-4e95-b294-d7ba3cb167b1",
		// 	"Weight": 0.8
		// },

		{
			'collationId': '54c9e8a1-7c7d-44ae-9ebb-aec0549cc844',
			'weight': -0.5
		},
		// {
		// 	"CollationId": "74563936-b08e-4e95-b294-d7ba3cb167b1",
		// 	"Weight": -0.2
		// }
	],
	'transformation': [
		{
			'collationId': '71865d66-e9dd-4c49-ba80-ec728e38bd7c',
			'params': [
				{
					'name': 'Product Id',
					'value': {
						'stringValue': '575868X'
					}
				},
				{
					'name': 'Position',
					'value': {
						'numberValue': 1
					}
				}
			]
		},
		{
			'collationId': '71865d66-e9dd-4c49-ba80-ec728e38bd7c',
			'params': [
				{
					'name': 'product id',
					'value': {
						'stringValue': 'AOS-01-0016'
					}
				},
				{
					'name': 'Position',
					'value': {
						'numberValue': 2
					}
				}
			]
		},
		{
			'collationId': '71865d66-e9dd-4c49-ba80-ec728e38bd7c',
			'params': [
				{
					'name': 'Product Id',
					'value': {
						'stringValue': '545374   X'
					}
				},
				{
					'name': 'position',
					'value': {
						'numberValue': -1
					}
				}
			]
		},
		{
			'collationId': '71865d66-e9dd-4c49-ba80-ec728e38bd7c',
			'params': [
				{
					'name': 'product id',
					'value': {
						'stringValue': 'HAB-97649'
					}
				},
				{
					'name': 'Position',
					'value': {
						'numberValue': -2
					}
				}
			]
		},
		{
			'collationId': '6aa45500-cb4e-4e44-abd6-0d11fea9bdaa',
			'params': [
				{
					'name': 'Old Product Id',
					'value': {
						'stringValue': '09456'
					}
				},
				{
					'name': 'new product id',
					'value': {
						'stringValue': '02684'
					}
				}
			]
		}
	]
};


export function getProductsReplaceInTransformationObject(objectTransformation) {
	const uniqueItem = objectTransformation.reduce((acc, curr) => {
		if (curr.collationId === replaceId) {
			acc = [...acc, curr];
		}
		return acc;
	}, []);
	return uniqueItem;
}
export function getProductsWhithoutReplaceTransformationObject(objectTransformation) {
	const uniqueItem = objectTransformation.reduce((acc, curr) => {
		if (curr.collationId !== replaceId) {
			acc = [...acc, curr];
		}
		return acc;
	}, []);
	return uniqueItem;
}
export const filterJustByReplaceProduct = (arrayToFilter, idOfProduct) => {
	const flatIdOfProduct = idOfProduct.flat();
	const uniqueItem = arrayToFilter.reduce((acc, curr) => {
		const firstProductIDCurr = curr.params.find(el => el.name === 'First Product Id')?.value.stringValues?.[0];
		// const secondProductIDCurr = curr.params.find(el => el.name === 'Second Product Id')?.value.stringValues?.[0];
		if ((!firstProductIDCurr || !flatIdOfProduct.includes(firstProductIDCurr))) {
			acc.push(curr);
		}

		return acc;
	}, []);

	return uniqueItem;
};



export const isReplaceProduct = (objectTransformation, idOfProduct) => {
	const arrayOfReplacesProducts = objectTransformation.filter(p => p.collationId === replaceId);
	const returnObj = {
		isATransformationReplace: false,
		oldProductId: '',
		replaceProductId: ''
	};
	return arrayOfReplacesProducts.reduce((acc, curr) => {
		const paramsOfReplaceProduct = curr.params;
		if (paramsOfReplaceProduct[0].value.stringValues[0] === idOfProduct) {
			acc = {
				isATransformationReplace: true,
				oldProductId: idOfProduct,
				replaceProductId: paramsOfReplaceProduct[1].value.stringValues[0]
			};
		}
		if (paramsOfReplaceProduct[1].value.stringValues[0] === idOfProduct) {
			acc = {
				isATransformationReplace: true,
				oldProductId: paramsOfReplaceProduct[1].value.stringValues[0],
				replaceProductId: paramsOfReplaceProduct[0].value.stringValues[0],
			};
		}
		return acc;
	}, returnObj);

};
