// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import {initDates, OptionnalFilter} from '../NavigationFunnelTypes';
import {initialDates} from '../../../config.js';


export const useFiltersEncode = (dimension:string) => {
	const funnelStorage = localStorage.getItem(dimension);

	const [datesFilter, setDateFilters] = useState<any>(null);
	const [devices, setDevices] = useState<any>();
	const [engagements, setEngagements] = useState([]);
	const [needRefreshAngularJs, setNeedRefreshAngularJs] = useState(false);
	const [metricSelected, setMetricSelected] = useState<string>();
	const [split, setSplit] = useState<string>();
	const [filterOptionnal, setFilterOptionnal] = useState<OptionnalFilter[] | undefined>();
	const { initialFrom, initialTo,initialLabel }:initDates = initialDates;
	function checkFilterMetric (filtersObj){
		if (filtersObj.hasOwnProperty('metric')) {
			try {
				const decodedData = filtersObj.metric;
				if(decodedData.length !== 0){
					setMetricSelected(decodedData);
				}
				else{
					setMetricSelected('SessionCount');
				}
			} catch (error) {
				console.error('Failed to decode funnelFilter from URL:', error);
			}
		}else{
			setNeedRefreshAngularJs(true);
			if(funnelStorage){
				const funnelhasdevices = JSON.parse(funnelStorage);
				if(funnelStorage && funnelhasdevices.hasOwnProperty('metric')){
					const decodedData = funnelhasdevices.metric;
					setMetricSelected(decodedData);
				}else{
					setMetricSelected('SessionCount');
				}
			}else{
				setMetricSelected('SessionCount');
			}

		}
	}
	function checkFilterSplit (filtersObj){
		if (filtersObj.hasOwnProperty('split')) {
			try {
				const decodedData = filtersObj.split;
				if(decodedData.length !== 0){
					setSplit(decodedData);
				}
				else{
					setSplit('Device');
				}
			} catch (error) {
				console.error('Failed to decode funnelFilter from URL:', error);
			}
		}else{
			setNeedRefreshAngularJs(true);
			if(funnelStorage){
				const funnelhasdevices = JSON.parse(funnelStorage);
				if(funnelStorage && funnelhasdevices.hasOwnProperty('split')){
					const decodedData = funnelhasdevices.split;
					setSplit(decodedData);
				}else{
					setSplit('Device');
				}
			}else{
				setSplit('Device');
			}

		}
	}
	function checkFilterDevices (filtersObj){
		if (filtersObj.hasOwnProperty('devices')) {
			try {
				const decodedData = filtersObj.devices;
				if(decodedData.length === 0){
					setDevices([]);
				}else{
					setDevices(decodedData.split(','));
				}
			} catch (error) {
				console.error('Failed to decode funnelFilter from URL:', error);
			}
		}else{
			setNeedRefreshAngularJs(true);
			if(funnelStorage){
				const funnelhasdevices = JSON.parse(funnelStorage);
				if(funnelhasdevices && funnelhasdevices.hasOwnProperty('devices')){
					const decodedData = funnelhasdevices.devices;
					setDevices(decodedData.split(','));
					if(decodedData.length === 0){
						setDevices([]);
					}else{
						setDevices(decodedData.split(','));
					}
				}else{
					setDevices([]);
				}
			}else{
				setDevices([]);
			}

		}
	}

	function checkFilterEngagement (filtersObj){
		if (filtersObj.hasOwnProperty('engagement')) {
			try {
				const decodedData = filtersObj.engagement;
				if(decodedData.length === 0){
					setEngagements([]);
				}else{
					setEngagements(decodedData.split(','));
				}
			} catch (error) {
				console.error('Failed to decode funnelFilter from URL:', error);
			}
		}else{
			setNeedRefreshAngularJs(true);
			if(funnelStorage){
				const funnelhasEngagements = JSON.parse(funnelStorage);
				if(funnelhasEngagements && funnelhasEngagements.hasOwnProperty('engagement')){
					const decodedData = funnelhasEngagements.engagement;
					if(decodedData.length === 0){
						setEngagements([]);
					}else{
						setEngagements(decodedData.split(','));
					}
				}else{
					setEngagements([]);
				}
			}else{
				setEngagements([]);
			}

		}
	}
	function checkFilterDates(filtersObj){
		if (filtersObj.hasOwnProperty('dates')) {
			try {
				const decodedData = filtersObj.dates.split(',');
				const decodedDataSplit =  decodedData.reduce((acc, item) => {
					const [key, value] = item.split('=');
					acc[key] = value;
					return acc;
				}, {});
				setDateFilters(decodedDataSplit);
			} catch (error) {
				console.error('Failed to decode funnelFilter from URL:', error);
			}
		}else{
			setNeedRefreshAngularJs(true);
			if(funnelStorage){
				const funnelhasdevices = JSON.parse(funnelStorage);
				if(funnelhasdevices && funnelhasdevices.hasOwnProperty('dates')){
					const decodedData = funnelhasdevices.dates.split(',');
					const decodedDataSplit =  decodedData.reduce((acc, item) => {
						const [key, value] = item.split('=');
						acc[key] = value;
						return acc;
					}, {});
					setDateFilters(decodedDataSplit);
				}else{
					setDateFilters({
						fromDate: initialFrom,
						toDate: initialTo,
						filters: [],
						label: initialLabel
					});
				}
			}else{
				setDateFilters({
					fromDate: initialFrom,
					toDate: initialTo,
					filters: [],
					label: initialLabel
				});

			}
		}
	}

	// optionnalFilters
	// string optionnalName=tata,tutu,toto&optionnalName2=kekete;
	function checkFilterOptionnal(filtersObj){
		function decodeDatasToObj(d:any){
			const valueKeys = d.split('$$');
			const optionnalArrayInUrl:any = [];
			valueKeys.forEach(k => {
				if(k !== ''){
					const keyValue = k.split('=');
					optionnalArrayInUrl.push({
						value : keyValue[1],
						key : keyValue[0]
					});
				}
			});
			return optionnalArrayInUrl;
		}

		if (filtersObj.hasOwnProperty('optionnal')) {
			const hasOptionnalFilter = filtersObj['optionnal'];
			if(hasOptionnalFilter){
				const opts = decodeDatasToObj(hasOptionnalFilter);
				setFilterOptionnal(opts);
			}

		}

		else{
			setNeedRefreshAngularJs(true);
			if(funnelStorage){
				const funnelhasdevices = JSON.parse(funnelStorage);
				if(funnelStorage && funnelhasdevices.hasOwnProperty('optionnal')){
					const decodedData = funnelhasdevices.optionnal;
					const opts = decodeDatasToObj(decodedData);
					setFilterOptionnal(opts);
				}else{
					// truc
					console.log('bordel de caca');
					setFilterOptionnal([]);
				}
			}else{
				//BIDULE
				console.log('bordel de caca');
				setFilterOptionnal([]);
			}

		}
	}
	useEffect(() => {
		const url = new URL(window.location.href);
		const params = url.searchParams;
		const filtersObj: any = {};

		params.forEach((value, key) => {
			filtersObj[key] = value;
		});

		checkFilterDevices(filtersObj);
		checkFilterDates(filtersObj);
		checkFilterEngagement(filtersObj);
		checkFilterMetric(filtersObj);
		checkFilterSplit(filtersObj);
		checkFilterOptionnal(filtersObj);
	}, []);

	return {
		devices
		,datesFilter,
		engagements,
		needRefreshAngularJs,
		metricSelected,
		split,
		filterOptionnal

	};
};

