import React from 'react';
import {useCatalogueEditorContext} from '../Context/CatalogueContextProvider';

type Props = {
	src: string;
	title?: string;
}

export const IframeContainer = ({
	src,
	title
}:Props) => {

	const {
		mode
	} = useCatalogueEditorContext();

	if(mode !== 'realizations'){
		return null;
	}

	return (
		<iframe
			src={src}
			title={title}
			width="100%"
			height="100%"
			style={{border: 'none', display: 'block'}}
			allowFullScreen
		/>
	);
};
