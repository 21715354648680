import HttpServices from './HttpServices';


export default class WorkflowServices {
	HttpServices: HttpServices;
	BO_API_URL: string;
	accountId: string;
	constructor(accId: string,$http:any) {
		this.HttpServices = new HttpServices($http);
		this.accountId = accId;
		if (!window || !(window as any).BEYABLE_env || !(window as any).BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${(window as any).BEYABLE_env.BO_API_URL}/`;
			// this.BO_API_URL = `${(window as any).BEYABLE_env.BO_API_URL}`;
		}
	}
	getProcesses(callbackSuccess, callbackError) {
		const url = `${this.BO_API_URL}${this.accountId}/workflow-state`;
		this.HttpServices.get(url,callbackSuccess, callbackError);
	}
	accountWorkflowRequest(requests,callbackSuccess, callbackError) {
		const url = `${this.BO_API_URL}${this.accountId}/workflow-request`;
		this.HttpServices.post(url,requests,callbackSuccess, callbackError);
	}

}