// API CALLS 

export const getProductCollation = (category, systemServices, serviceReference, callbackSuccess) => serviceReference.getProductCollation(
	category,
	callbackSuccess,
	error => systemServices.showError());

export const getProductFeedCategory = (accountId,tenant, systemServices, serviceReference, callbackSuccess) => serviceReference.getProductFeedCategory(
	accountId,
	tenant,
	callbackSuccess,
	error => systemServices.showError());

export const getRankingById = (rankingId, systemServices, serviceReference, callbackSuccess, callbackError) => serviceReference.getRankingById(
	rankingId,
	callbackSuccess,
	error => {
		systemServices.showError();
		callbackError(error);
	});

export const getPreview = (options,
	paginationOptions,
	ranking,
	systemServices, serviceReference,
	callbackSuccess, callbackError) => serviceReference.getProductListPreview(
	options,
	paginationOptions,
	ranking,
	callbackSuccess,
	error => {
		systemServices.showError();
		callbackError(error);
	});

export const createRanking = (ranking,
	systemServices, serviceReference,
	callbackSuccess, callbackError) => serviceReference.createRanking(
	ranking,
	callbackSuccess,
	error => {
		systemServices.showError();
		callbackError(error);
	});

export const putRankingById = (ranking, rankingId,
	systemServices, serviceReference,
	callbackSuccess, callbackError) => serviceReference.putRankingById(
	ranking,
	rankingId,
	callbackSuccess,
	error => {
		systemServices.showError();
		callbackError(error);
	});

export const deleteRankingById = (rankingId,
	systemServices, serviceReference,
	callbackSuccess, callbackError) => serviceReference.deleteRanking(
	rankingId,
	Sccess => {
		callbackSuccess();
		systemServices.showSuccess('The rule has been deleted.');
	},
	error => {
		systemServices.showError();
		callbackError(error);
	});

export const duplicateRankingById = (rankingId, routeParams,
	systemServices, serviceReference,
	functionToLoad,
	callbackSuccess, callbackError) => serviceReference.getRankingById(
	rankingId,
	callbackSuccess => {
		const cloneRankingRule = { ...callbackSuccess };
		cloneRankingRule.name = cloneRankingRule.name + ' cloned';
		serviceReference.createRanking(
			cloneRankingRule,
			Success => {
				let ka = '';
				if (routeParams && routeParams.hasOwnProperty('ka')) {
					ka = '&ka=' + routeParams.ka;
				}

				systemServices.showSuccess('The rule has been duplicated, redirect to the editing screen');
				functionToLoad('/ProductSettings/Ranking/Setting?id=' + Success.id + ka);
			},
			error => {
				systemServices.showError();
				callbackError(error);
			});
	},
	error => {
		systemServices.showError();
		callbackError(error);
	});
