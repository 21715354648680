import React,{useEffect,useState} from 'react';
import TableRowItem from '../../../../Components/Table/TableRowItem.js';
import TableColItem from '../../../../Components/Table/TableColItem.js';
import { ProductThumb } from '../../../../Components/Product/ProductThumb';
import { ProductScore } from '../../../../Components/Product/ProductScore';
import { getFormattedNumber, getFormattedPercent } from '../../../../Util/numberFormats';
import styles from '../ProductsInsights.module.css';

export function ProductItemCompared({
	product,
	tableRowStyle
}: any): JSX.Element {

	const [updatedScore, setUpdatedScore] = useState(Math.round(product.Score * 100));
	const [updatedScore2, setUpdatedScore2] = useState(0);

	useEffect(() => {
		setUpdatedScore(Math.round(product.Score.ValueComparison * 100));
		setUpdatedScore2(Math.round(product.Score.ValueReference * 100));
	}, [product]);

	return (
		<TableRowItem styleObj={tableRowStyle}>
			<TableColItem>
				<div className='flex'>
					<div className="flex_item_fix">
						<ProductThumb
							width={60}
							height={'auto'}
							url={product.AssociatedProduct.Thumbnail}
							alt={product.AssociatedProduct.Name}
						/>
					</div>
					<div className="flex_item_full">
						<div className='s_13 fw_medium grey_2'>{product.AssociatedProduct.Id}</div>
						<div className="table_name">{product.AssociatedProduct.Name}</div>
					</div>
				</div>
			</TableColItem>
			<Cell
				oldValue={getFormattedNumber(updatedScore2 || 0)}
				newValue={getFormattedNumber(updatedScore || 0)}
				levelOfImportance={product.Score.LevelOfImportance}
				showSign={true}
				align='left'
			>
				<ProductScore
					value={updatedScore2 || 0}
					displayValue={getFormattedNumber(updatedScore2 || 0)}
					display='block'
					className='s_13 grey_2 mr_5'
				/>
				<ProductScore
					value={updatedScore || 0}
					displayValue={getFormattedNumber(updatedScore || 0)}
					display='block'
					className='mr_5'
				/>
			</Cell>
			<Cell
				oldValue={getFormattedNumber(product.NumberOfVisits.ValueReference)}
				newValue={getFormattedNumber(product.NumberOfVisits.ValueComparison)}
				levelOfImportance={product.NumberOfVisits.LevelOfImportance}
				showSign={false}
			/>
			<Cell
				oldValue={getFormattedNumber(product.NumberOfCartAdded.ValueReference)}
				newValue={getFormattedNumber(product.NumberOfCartAdded.ValueComparison)}
				levelOfImportance={product.NumberOfCartAdded.LevelOfImportance}
				showSign={false}
			/>
			<Cell
				oldValue={getFormattedNumber(product.NumberOfAbandonments.ValueReference)}
				newValue={getFormattedNumber(product.NumberOfAbandonments.ValueComparison)}
				levelOfImportance={product.NumberOfAbandonments.LevelOfImportance}
				showSign={false}
			/>
			<Cell
				oldValue={getFormattedNumber(product.NumberOfObjectives.ValueReference)}
				newValue={getFormattedNumber(product.NumberOfObjectives.ValueComparison)}
				levelOfImportance={product.NumberOfObjectives.LevelOfImportance}
				showSign={false}
			/>
			<Cell
				oldValue={getFormattedPercent((product.AddToCartRate.ValueReference || 0) * 100, 2)}
				newValue={getFormattedPercent((product.AddToCartRate.ValueComparison || 0) * 100, 2)}
				levelOfImportance={product.AddToCartRate.LevelOfImportance}
				showSign={true}
			/>
			<Cell
				oldValue={getFormattedPercent((product.AbandonmentRate.ValueReference || 0) * 100, 2)}
				newValue={getFormattedPercent((product.AbandonmentRate.ValueComparison || 0) * 100, 2)}
				levelOfImportance={product.AbandonmentRate.LevelOfImportance}
				showSign={true}
				normalDirection={false}
			/>
		</TableRowItem>
	);
}

function Cell({
	children,
	oldValue,
	newValue,
	levelOfImportance,
	showSign = false,
	normalDirection = true,
	align = 'right'
}: any): JSX.Element {

	return (
		<TableColItem
			align={align}
			tooltipHTML={
				<table className={styles.grid_cell_tooltip_table}>
					<tbody>
						<tr>
							<td><strong>Previous period</strong></td>
							<td>{oldValue}</td>
							<td rowSpan={2}>
								<Sign
									levelOfImportance={levelOfImportance}
									normalDirection={normalDirection}
								/>
							</td>
						</tr>
						<tr>
							<td><strong>Studied period</strong></td>
							<td>{newValue}</td>
						</tr>
					</tbody>
				</table>
			}>
			{children ?
				<div>{children}</div>
				:
				<div>
					<div className='s_13 grey_2'>{oldValue}</div>
					<div>{newValue}</div>
				</div>
			}
			{showSign && 
				<Sign
					levelOfImportance={levelOfImportance}
					normalDirection={normalDirection}
					className="ml_5"
				/>
			}
		</TableColItem>
	);
}

function Sign({
	levelOfImportance,
	normalDirection = true,
	className
}: any): JSX.Element {

	const cssClass = ['fa'];

	if (levelOfImportance == 'SLI_NORMAL') {
		cssClass.push('fa-equals');
		cssClass.push('c_warning');
	} else if (levelOfImportance == 'SLI_GREEN') {
		cssClass.push('fa-arrow-up');
		cssClass.push(normalDirection ? 'c_success' : 'c_alert');
	} else if (levelOfImportance == 'SLI_RED') {
		cssClass.push('fa-arrow-down');
		cssClass.push(normalDirection ? 'c_alert' : 'c_success');
	}

	if (className) {
		cssClass.push(className);
	}

	return (
		<i className={cssClass.join(' ')}></i>
	);
}

