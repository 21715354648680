import React, { useState } from 'react';
import Btn from '../../../Components/Btn';
import Dropdown from '../../../Components/Dropdown';
import { Listbox, ListboxItem, ListboxSep } from '../../../Components/Listbox/Listbox';

export default function DropdownRanking({
	duplicateActionFunction,
	openModalCampaignDelete,
	element
}) {

	const isUsedInSegment = element.segmentedRankingUsages && element.segmentedRankingUsages.length > 0;
	const [editDropdownIsOpen, setEditDropdownIsOpen] = useState(false);

	return (
		<Dropdown
			isOpen={editDropdownIsOpen}
			setIsOpen={() => setEditDropdownIsOpen(true)}
			onHide={() => setEditDropdownIsOpen(false)}
			button={
				<Btn
					style="ghost_outline"
					color="secondary"
					size="xs"
					icon="fas fa-ellipsis-v"
				/>
			}
		>
			<Listbox>
				<ListboxItem
					icon="fas fa-copy"
					message="Duplicate"
					onClick={() => {
						duplicateActionFunction();
						setEditDropdownIsOpen(false);
					}}
				/>
				<ListboxSep />
				<ListboxItem
					icon="fas fa-trash"
					message="Delete"
					onClick={() => {
						openModalCampaignDelete();
						setEditDropdownIsOpen(false);
					}}
					disabled={isUsedInSegment}
					tooltip={isUsedInSegment === true ? 'This segment cannot be deleted, it is used in segmentation.' : ''}
				/>
			</Listbox>
		</Dropdown>
	);
}
