import React from 'react';

export const iconSegment = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M15.142,5.483v5.7a2.645,2.645,0,0,1-1.308,2.275l-4.95,2.858a2.636,2.636,0,0,1-2.625,0l-4.95-2.858A2.625,2.625,0,0,1,0,11.183v-5.7A2.645,2.645,0,0,1,1.308,3.208L6.258.35a2.636,2.636,0,0,1,2.625,0l4.95,2.858A2.635,2.635,0,0,1,15.142,5.483Z" transform="translate(2.425 1.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<g transform="translate(6.667 5.283)">
			<path d="M3.883,1.942A1.942,1.942,0,1,1,1.942,0,1.942,1.942,0,0,1,3.883,1.942Z" transform="translate(1.392)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			<path d="M6.667,2.717C6.667,1.217,5.175,0,3.333,0S0,1.217,0,2.717" transform="translate(0 5.883)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		</g>
	</svg>
);

export const iconPerformance = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M0,1.725V0" transform="translate(5.733 13.4)" fill="none" strokeLinecap="round" strokeWidth="1.5"/>
		<path d="M0,3.45V0" transform="translate(10 11.675)" fill="none" strokeLinecap="round" strokeWidth="1.5"/>
		<path d="M0,5.183V0" transform="translate(14.267 9.942)" fill="none" strokeLinecap="round" strokeWidth="1.5"/>
		<path d="M8.533,0,8.15.45A15.735,15.735,0,0,1,0,5.483" transform="translate(5.733 4.875)" fill="none" strokeLinecap="round" strokeWidth="1.5"/>
		<path d="M0,0H2.442V2.433" transform="translate(11.825 4.875)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M5.833,16.667h5c4.167,0,5.833-1.667,5.833-5.833v-5C16.667,1.667,15,0,10.833,0h-5C1.667,0,0,1.667,0,5.833v5C0,15,1.667,16.667,5.833,16.667Z" transform="translate(1.667 1.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconEmailTemplate = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M6.35,4.684V1.666C6.35.478,5.872,0,4.684,0H1.666C.478,0,0,.478,0,1.666V4.684C0,5.872.478,6.35,1.666,6.35H4.684C5.872,6.35,6.35,5.872,6.35,4.684Z" transform="translate(11.121 2.53)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M6.35,4.871V1.479C6.35.426,5.872,0,4.684,0H1.666C.478,0,0,.426,0,1.479V4.863C0,5.924.478,6.342,1.666,6.342H4.684C5.872,6.35,6.35,5.924,6.35,4.871Z" transform="translate(2.529 2.53)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M6.35,4.684V1.666C6.35.478,5.872,0,4.684,0H1.666C.478,0,0,.478,0,1.666V4.684C0,5.872.478,6.35,1.666,6.35H4.684C5.872,6.35,6.35,5.872,6.35,4.684Z" transform="translate(2.529 11.121)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0H4.482" transform="translate(11.868 14.109)" fill="none" strokeLinecap="round" strokeWidth="1.5"/>
		<path d="M0,4.482V0" transform="translate(14.109 11.868)" fill="none" strokeLinecap="round" strokeWidth="1.5"/>
	</svg>
);

export const iconAbandonedCart = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M3.774,3.757,0,0" transform="translate(8.131 10.653)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M3.774,0,0,3.774" transform="translate(8.095 10.689)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M4.792,11.667h5c3.35,0,3.95-1.342,4.125-2.975l.625-5C14.767,1.658,14.184,0,10.626,0H3.959C.4,0-.183,1.658.042,3.692l.625,5C.842,10.325,1.442,11.667,4.792,11.667Z" transform="translate(2.708 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,4.718V3.91A3.977,3.977,0,0,1,3.383.018,3.75,3.75,0,0,1,7.5,3.751V4.9" transform="translate(6.25 1.674)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconHistory = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M16.667,8.333A8.333,8.333,0,1,1,8.333,0,8.336,8.336,0,0,1,16.667,8.333Z" transform="translate(1.667 1.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M3.4,6.392.817,4.85A1.846,1.846,0,0,1,0,3.417V0" transform="translate(9.692 6.258)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconThumbUp = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M0,13.085l2.583,2a2.564,2.564,0,0,0,1.583.5H7.333a2.5,2.5,0,0,0,2.333-1.75l2-6.083a1.561,1.561,0,0,0-1.583-2.167H6.75a.841.841,0,0,1-.833-1l.417-2.667A1.644,1.644,0,0,0,5.25.085,1.664,1.664,0,0,0,3.417.668L0,5.752" transform="translate(6.233 2.207)" fill="none" strokeWidth="1.5"/>
		<path d="M0,9.75V1.583C0,.417.5,0,1.667,0H2.5C3.667,0,4.167.417,4.167,1.583V9.75c0,1.167-.5,1.583-1.667,1.583H1.667C.5,11.333,0,10.917,0,9.75Z" transform="translate(1.983 5.542)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconRecommendation = iconThumbUp;

export const iconThumbDown = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M0,2.5,2.583.5A2.564,2.564,0,0,1,4.167,0H7.333A2.5,2.5,0,0,1,9.667,1.75l2,6.083A1.561,1.561,0,0,1,10.083,10H6.75a.841.841,0,0,0-.833,1l.417,2.667A1.644,1.644,0,0,1,5.25,15.5a1.664,1.664,0,0,1-1.833-.583L0,9.833" transform="translate(6.233 2.208)" fill="none" strokeWidth="1.5"/>
		<path d="M0,1.583V9.75c0,1.167.5,1.583,1.667,1.583H2.5c1.167,0,1.667-.417,1.667-1.583V1.583C4.167.417,3.667,0,2.5,0H1.667C.5,0,0,.417,0,1.583Z" transform="translate(1.983 3.125)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconProgressUp = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M11.667,0,0,11.667" transform="translate(4.167 4.167)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M8.558,8.558V0H0" transform="translate(7.275 4.167)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconProgressDown = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M11.667,11.667,0,0" transform="translate(4.167 4.167)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M8.558,0V8.558H0" transform="translate(7.275 7.275)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconScales = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<circle cx="1.667" cy="1.667" r="1.667" transform="translate(8.333 2.5)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M9,5H4m8.333,0h5" transform="translate(-0.667 -0.833)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M15.333,21H7" transform="translate(-1.167 -3.5)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M12,7V18.667" transform="translate(-2 -1.167)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M21,13a2.5,2.5,0,1,1-5,0m5,0L18.917,8h-.833L16,13m5,0H16" transform="translate(-2.667 -1.333)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M7,13a2.5,2.5,0,0,1-5,0m5,0L4.917,8H4.083L2,13m5,0H2" transform="translate(-0.333 -1.333)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconFunnel = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M1.667,0h11a1.672,1.672,0,0,1,1.667,1.667V3.5A3.111,3.111,0,0,1,13.5,5.417L9.917,8.583A2.713,2.713,0,0,0,9.083,10.5v3.583a1.856,1.856,0,0,1-.75,1.417l-1.167.75a1.7,1.7,0,0,1-2.583-1.417V10.417a3.221,3.221,0,0,0-.667-1.75L.75,5.333A2.762,2.762,0,0,1,0,3.667V1.75A1.691,1.691,0,0,1,1.667,0Z" transform="translate(2.833 1.75)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M4.108,0,0,6.583" transform="translate(5 1.75)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconBarCode = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M12.5,14.167H4.167C1.667,14.167,0,12.917,0,10V4.167C0,1.25,1.667,0,4.167,0H12.5c2.5,0,4.167,1.25,4.167,4.167V10C16.667,12.917,15,14.167,12.5,14.167Z" transform="translate(1.667 2.917)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V6.667" transform="translate(5 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V3.333" transform="translate(7.5 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V.833" transform="translate(7.5 12.5)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V.833" transform="translate(12.5 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V6.667" transform="translate(10 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V3.333" transform="translate(12.5 10)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V6.667" transform="translate(15 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconTraffic = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<g transform="translate(-620 -188)">
			<path d="M5.833,16.667h5c4.167,0,5.833-1.667,5.833-5.833v-5C16.667,1.667,15,0,10.833,0h-5C1.667,0,0,1.667,0,5.833v5C0,15,1.667,16.667,5.833,16.667Z" transform="translate(621.642 189.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			<path d="M0,1.762l5-.017A1.815,1.815,0,0,1,6.558,2.8l.95,2.4c.217.542.558.542.775,0L10.192.362c.183-.467.525-.483.758-.042l.867,1.642a1.839,1.839,0,0,0,1.475.892h3.383" transform="translate(621.642 196.822)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		</g>
	</svg>
);

export const iconVariable = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M21,40c-5,0,0,6.875-5,6.875,5,0,0,6.875,5,6.875" transform="translate(-13.75 -36.875)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M176,40c5,0,0,6.875,5,6.875-5,0,0,6.875-5,6.875" transform="translate(-163.25 -36.875)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconNotification = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M11.881,2.177A5.661,5.661,0,0,0,10.532,2a5.548,5.548,0,0,0-5.55,5.547V9.3a4.325,4.325,0,0,1-.525,1.9L3.4,12.967a1.776,1.776,0,0,0,.476,2.467,1.8,1.8,0,0,0,.524.242,19.45,19.45,0,0,0,12.275,0,1.85,1.85,0,0,0,1.169-2.341,1.812,1.812,0,0,0-.169-.367L16.616,11.2a4.359,4.359,0,0,1-.525-1.9V9.226" transform="translate(-0.516 -0.333)" fill="none" strokeLinecap="round" strokeWidth="1.5"/>
		<path d="M14.22,18.82a2.775,2.775,0,0,1-5.55,0" transform="translate(-1.445 -3.137)" fill="none" strokeWidth="1.5"/>
		<circle cx="2.5" cy="2.5" r="2.5" transform="translate(12.423 2.002)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconNotificationMessage = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M5,2.5A2.5,2.5,0,1,1,2.5,0,2.5,2.5,0,0,1,5,2.5Z" transform="translate(13.333 1.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0H4.167" transform="translate(5.833 10.833)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0H7.5" transform="translate(5.833 14.167)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M10,0H5.833C1.667,0,0,1.667,0,5.833v5C0,15,1.667,16.667,5.833,16.667h5c4.167,0,5.833-1.667,5.833-5.833V6.667" transform="translate(1.667 1.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconOnSite = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M3.7,0h9.258c2.967,0,3.708.742,3.708,3.7V8.975c0,2.967-.742,3.7-3.7,3.7H3.7C.742,12.683,0,11.942,0,8.983V3.7C0,.742.742,0,3.7,0Z" transform="translate(1.667 1.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V3.983" transform="translate(10 14.35)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0H7.5" transform="translate(6.25 18.333)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M1.3,0h3.24c1.038,0,1.3.26,1.3,1.3V3.141c0,1.038-.26,1.295-1.3,1.295H1.3C.26,4.439,0,4.18,0,3.144V1.3C0,.26.26,0,1.3,0Z" transform="translate(10 7.405)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconEmr = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M7.916,17.636c.666,0,4.166.017,4.835,0,2.624-.066,3.936-.1,4.878-1.045s.97-2.224,1.024-4.778c.018-.821.018-1.638,0-2.459C18.6,6.8,18.572,5.522,17.629,4.576S15.375,3.6,12.751,3.53c-1.617-.041-3.218-.041-4.835,0-2.624.066-3.936.1-4.878,1.045S2.068,6.8,2.013,9.354a2.131,2.131,0,0,0-.007.4" transform="translate(-0.333 -0.572)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M18.667,6,12.906,9.271c-2.1,1.195-3.04,1.195-5.145,0L2,6" transform="translate(-0.333 -0.988)" fill="none" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M19.932,18.5H14" transform="translate(-10.765 -3.905)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M18.628,18.5H14" transform="translate(-12.333 -6.405)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconEmerch = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path id="Vector" d="M0,4.718V3.91A3.977,3.977,0,0,1,3.383.018,3.75,3.75,0,0,1,7.5,3.751V4.9" transform="translate(6.25 1.674)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M4.792,11.667h5c3.35,0,3.95-1.342,4.125-2.975l.625-5C14.767,1.658,14.184,0,10.626,0H3.959C.4,0-.183,1.658.042,3.692l.625,5C.842,10.325,1.442,11.667,4.792,11.667Z" transform="translate(2.708 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M.495.5H.5" transform="translate(12.418 9.5)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M.495.5H.5" transform="translate(6.584 9.5)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
	</svg>
);

export const iconSimplify = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M8.012.188l4.917,2.183c1.417.625,1.417,1.658,0,2.283L8.012,6.837a2.85,2.85,0,0,1-2.033,0L1.063,4.654C-.354,4.029-.354,3,1.063,2.371L5.979.188A2.85,2.85,0,0,1,8.012.188Z" transform="translate(2.829 2.246)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0A2.135,2.135,0,0,0,1.167,1.792L6.825,4.308a1.657,1.657,0,0,0,1.35,0l5.658-2.517A2.135,2.135,0,0,0,15,0" transform="translate(2.5 9.167)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0A1.964,1.964,0,0,0,1.167,1.792L6.825,4.308a1.657,1.657,0,0,0,1.35,0l5.658-2.517A1.964,1.964,0,0,0,15,0" transform="translate(2.5 13.333)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconABtest = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<g transform="translate(1 1)">
			<path d="M14.019.765h1.232a2,2,0,0,1,2,2v12.5a2,2,0,0,1-2,2H7.344Z" fill="none" strokeMiterlimit="10" strokeWidth="1.5"/>
			<path d="M3.981,17.25H2.749a2,2,0,0,1-2-2V2.749a2,2,0,0,1,2-2h7.907Z" fill="none" strokeMiterlimit="10" strokeWidth="1.5"/>
		</g>
	</svg>
);

export const iconAnalytics = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M0,0H15" transform="translate(2.5 18.333)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<g transform="translate(2.5 1.667)">
			<path d="M2.167,0H.833C.375,0,0,.282,0,.628V6.039c0,.345.375.628.833.628H2.167c.458,0,.833-.282.833-.628V.628C3,.282,2.625,0,2.167,0Z" transform="translate(0 7.5)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			<path d="M2.167,0H.833A.948.948,0,0,0,0,1.026V13.141a.948.948,0,0,0,.833,1.026H2.167A.948.948,0,0,0,3,13.141V1.026A.948.948,0,0,0,2.167,0Z" transform="translate(6)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			<path d="M2.167,0H.833A.758.758,0,0,0,0,.637V10.2a.758.758,0,0,0,.833.637H2.167A.758.758,0,0,0,3,10.2V.637A.758.758,0,0,0,2.167,0Z" transform="translate(12 3.483)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		</g>
	</svg>
);

export const iconProductMonitoring = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M0,0V6.667" transform="translate(5 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V3.333" transform="translate(7.5 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V.833" transform="translate(7.5 12.5)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V.833" transform="translate(12.5 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V6.667" transform="translate(10 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V2" transform="translate(12.5 10)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V3.685" transform="translate(15 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M16.667,9.167V5.833C16.667,1.667,15,0,10.833,0h-5C1.667,0,0,1.667,0,5.833v5C0,15,1.667,16.667,5.833,16.667H9.167" transform="translate(1.667 1.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<g transform="translate(12.496 12.502)">
			<path d="M3.738.681l.233.475a1.043,1.043,0,0,0,.667.5l.317.05c.95.158,1.175.858.492,1.55l-.292.292a1.055,1.055,0,0,0-.242.85l.042.175c.258,1.15-.35,1.592-1.35.992L3.388,5.44a1.024,1.024,0,0,0-.933,0l-.217.125c-1.008.608-1.617.158-1.35-.992L.929,4.4a1.055,1.055,0,0,0-.242-.85L.4,3.256c-.683-.692-.458-1.392.492-1.55l.317-.05a1.036,1.036,0,0,0,.667-.5L2.1.681C2.554-.227,3.288-.227,3.738.681Z" transform="translate(0)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		</g>
	</svg>
);

export const iconScoring = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<g transform="translate(12.775 1.667)">
			<path d="M2.225,4.3a.5.5,0,0,0-.158,0,2.154,2.154,0,1,1,.158,0Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			<path d="M.025,4.117a4.7,4.7,0,0,0,3.283-.6,1.593,1.593,0,0,0,0-2.85A4.725,4.725,0,0,0,0,.075" transform="translate(1.342 6.25)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		</g>
		<g transform="translate(1.669 1.667)">
			<path d="M2.075,4.3a.5.5,0,0,1,.158,0,2.154,2.154,0,1,0-.158,0Z" transform="translate(1.231)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			<path d="M4.165,4.117a4.7,4.7,0,0,1-3.283-.6,1.593,1.593,0,0,1,0-2.85A4.725,4.725,0,0,1,4.19.075" transform="translate(0 6.25)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		</g>
		<g transform="translate(6.694 7.892)">
			<path d="M2.225,4.3a.5.5,0,0,0-.158,0,2.154,2.154,0,1,1,.158,0Z" transform="translate(1.081)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			<path d="M.881.662a1.593,1.593,0,0,0,0,2.85,4.731,4.731,0,0,0,4.85,0,1.593,1.593,0,0,0,0-2.85A4.769,4.769,0,0,0,.881.662Z" transform="translate(0 6.262)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		</g>
	</svg>
);

export const iconGraphicPatch = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor">
		<g transform="translate(-2.565 -642.565)">
			<path d="M2.233,1.489a.745.745,0,0,0-.744.744V17.119a.745.745,0,0,0,.744.744H6.7a.745.745,0,0,0,.744-.744V2.233A.745.745,0,0,0,6.7,1.489H2.233M2.233,0H6.7A2.233,2.233,0,0,1,8.932,2.233V17.119A2.233,2.233,0,0,1,6.7,19.352H2.233A2.233,2.233,0,0,1,0,17.119V2.233A2.233,2.233,0,0,1,2.233,0Z" transform="translate(2.565 648.881) rotate(-45)" />
			<path d="M5.391,18.15A2.218,2.218,0,0,1,3.812,17.5L.654,14.339a2.233,2.233,0,0,1,0-3.158L2.76,9.075l1.053,1.053-2.105,2.1a.745.745,0,0,0,0,1.053l3.157,3.158a.746.746,0,0,0,1.053,0l2.105-2.105,1.053,1.053-2.1,2.1A2.218,2.218,0,0,1,5.391,18.15Zm10-9.075h0L14.339,8.023l2.105-2.105a.747.747,0,0,0,0-1.053L13.286,1.707a.744.744,0,0,0-1.052,0L10.128,3.813,9.075,2.76,11.181.654a2.233,2.233,0,0,1,3.158,0L17.5,3.812a2.236,2.236,0,0,1,0,3.157l-2.1,2.1Z" transform="translate(3.489 643.49)" />
			<circle cx="0.744" cy="0.744" r="0.744" transform="translate(11.076 649.588)" />
			<circle cx="0.744" cy="0.744" r="0.744" transform="translate(12.565 651.076)" />
			<circle cx="0.744" cy="0.744" r="0.744" transform="translate(14.054 652.565)" />
			<circle cx="0.744" cy="0.744" r="0.744" transform="translate(9.588 651.076)" />
			<circle cx="0.744" cy="0.744" r="0.744" transform="translate(11.076 652.565)" />
			<circle cx="0.744" cy="0.744" r="0.744" transform="translate(12.565 654.054)" />
		</g>
	</svg>
);

export const iconCustomTemplate = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M5,20.5H16.953" transform="translate(-0.439 -2.486)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M14.7,15.453H6.378c-.878,0-1.317,0-1.65-.236s-.478-.65-.768-1.479L2.044,8.261A.831.831,0,0,1,2.25,7.4a.861.861,0,0,1,1.075-.1l1.052.691c1.062.7,1.593,1.047,2.13.92S7.362,8.232,8,7.133L9.89,3.871a.752.752,0,0,1,1.3,0l1.891,3.262c.637,1.1.956,1.649,1.493,1.776s1.068-.222,2.13-.92L17.751,7.3a.861.861,0,0,1,1.075.1.831.831,0,0,1,.206.859l-1.917,5.477c-.29.829-.435,1.243-.768,1.479S15.575,15.453,14.7,15.453Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconTemplate = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M6.35,4.684V1.666C6.35.478,5.872,0,4.684,0H1.666C.478,0,0,.478,0,1.666V4.684C0,5.872.478,6.35,1.666,6.35H4.684C5.872,6.35,6.35,5.872,6.35,4.684Z" transform="translate(11.121 2.53)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M6.35,4.684V1.666C6.35.478,5.872,0,4.684,0H1.666C.478,0,0,.478,0,1.666V4.684C0,5.872.478,6.35,1.666,6.35H4.684C5.872,6.35,6.35,5.872,6.35,4.684Z" transform="translate(11.121 11.121)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M6.35,4.871V1.479C6.35.426,5.872,0,4.684,0H1.666C.478,0,0,.426,0,1.479V4.863C0,5.924.478,6.342,1.666,6.342H4.684C5.872,6.35,6.35,5.924,6.35,4.871Z" transform="translate(2.529 2.53)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M6.35,4.684V1.666C6.35.478,5.872,0,4.684,0H1.666C.478,0,0,.478,0,1.666V4.684C0,5.872.478,6.35,1.666,6.35H4.684C5.872,6.35,6.35,5.872,6.35,4.684Z" transform="translate(2.529 11.121)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconLight = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M.977,0,.085,1.55c-.2.342-.033.625.358.625H1.5c.4,0,.558.283.358.625L.977,4.35" transform="translate(9.023 6.575)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M3.492,13.37V12.4A7.149,7.149,0,0,1,0,6.587,6.6,6.6,0,0,1,8.075.162a6.487,6.487,0,0,1,4.392,3.392A6.869,6.869,0,0,1,9.683,12.4v.967c0,.242.092.8-.8.8H4.292C3.375,14.17,3.492,13.812,3.492,13.37Z" transform="translate(3.425 1.663)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,.406a10.673,10.673,0,0,1,5.833,0" transform="translate(7.083 17.927)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);
