import React from 'react';

const Chip = ({
	type = 'Active visitors',
	title = 'title',
	handleDelete,
	size = 'm'
}) => {

	const cssClass = ['custom_input', 'w_auto', 'highlighted'];
	cssClass.push(size);

	return (
		<button
			type="button"
			className={cssClass.join(' ')}
		>
			<span className="custom_input_value has_label">
				<span className="custom_input_value_label">{type}</span>
				{title}
			</span>
			<span className="custom_input_reset" onClick={handleDelete}>
				<i className="fas fa-times-circle"></i>
			</span>
		</button>
	);
};

Chip.displayName = 'Chip';
export default Chip;