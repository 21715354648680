import React from 'react';
import TrafficContainer from './Traffic/TrafficContainer';
import Ecommerce from './Ecommerce/Ecommerce';
import KpiContainer from './KPI/KpiContainer';
import Segments from './Segments/Segments';
import AiInsightsContainer from '../Dashboard/AiInsights/AiInsightsContainer';
import SegmentationListContainer from '../SegmentGroups/List/SegmentationListContainer';

import RouterItems from '../Menu/RouterItems';
import {useInsightsContext} from './context/ContextInsights';
import AuditContainer from './Audit/AuditContainer';
import {NavigationFunnelContainer} from './Funnel/NavigationFunnel/NavigationFunnelContainer/ui/NavigationFunnelContainer';
import {EngagementFunnelContainer} from './Funnel/EngagementFunnel/EngagementFunnelContainer';
import {ProductInsightsContainer} from './ProductInsights/ProductInsightsContainer';

import {ListMappingContainerWrapper} from './ProductInsights/ListMapping/ListMappingContainerWrapper';
import {EmerchContainerWrapper} from './ProductInsights/Emerch/EmerchContainerWrapper';
import {ProductInsightsContainerWrapper} from './ProductInsights/ProductSpecific/ProductSpecificContainerWrapper';

import ProductGlobalContextProvider from './context/ContextGlobalInsight';

export default function RouterInsights(props) {
	
	const {
		modulesActivated,
		isAdmin
	} = useInsightsContext();
	
	return (
		<RouterItems
			title="Insights"
			list={modulesActivated}
			routes={<></>}
		>
			<AiInsightsContainer {...props}/>
			<KpiContainer/>
			<TrafficContainer props={{...props}}/>
			<Ecommerce props={{...props}}/>
			<Segments/>
			<SegmentationListContainer {...props}/>
			<ProductGlobalContextProvider {...props}>
				<NavigationFunnelContainer {...props}/>
				<EngagementFunnelContainer {...props}/>
				<ProductInsightsContainer {...props}/>
				<ProductInsightsContainerWrapper {...props}/>
				<EmerchContainerWrapper {...props} />
				<ListMappingContainerWrapper {...props} />
			</ProductGlobalContextProvider>
			{isAdmin &&
				<AuditContainer props={{...props}}/>
			}
		</RouterItems>
	);
}
