import React from 'react';
import {Tooltip} from '../Tooltip/Tooltip';
import { v4 as uuidv4 } from 'uuid';
import {IListbox, IListboxItem, IListboxGroupLabel} from './types';
import {Checkbox, Radio} from '../Checkbox/Checkbox';
import styles from './Listbox.module.css';

function Listbox({
	children,
	hasBorder = false,
	className = '',
	direction = 'column',
	appearance,
	groupLabel
}: IListbox): JSX.Element {

	let cssClass = [styles.listbox];

	// className
	if (className) {
		cssClass = cssClass.concat(className);
	}

	// Border
	if (hasBorder) {
		cssClass.push(styles.listbox_has_border);
	}

	// Direction
	if (direction && direction === 'row') {
		cssClass.push(styles.listbox_direction_row);
	}

	// Appearance 
	if (appearance && appearance === 'iconSelector') {
		cssClass.push(styles.listbox_appearance_iconSelector);
	}

	return (
		<>
			{groupLabel &&
				<div className={styles.groupLabel}>{groupLabel}</div>
			}
			<ul className={cssClass.join(' ')}>
				{children}
			</ul>
		</>
	);
}

function ListboxItem({
	children,
	message = '',
	description = '',
	imageUrl = '',
	tooltip,
	tooltipHTML,
	onClick,
	onMouseEnter = () => {},
	href,
	target,
	rel,
	selected = false,
	hasSelectedIcon = false,
	hasCheckbox = false,
	hasRadio = false,
	disabled = false,
	size = 'm',
	className = '',
	style,
	icon = '',
	iconCustom = '',
	capitalize = false
}: IListboxItem): JSX.Element {

	const tooltipID = uuidv4();

	const handleClick = (ev:any) => {
		if( disabled ) return;

		if (typeof onClick === 'function') {
			onClick(ev);
		}
	};

	let cssClass = [styles.item];
	const sizeClass:any = {
		m: styles.item_size_m,
		l: styles.item_size_l,
		xl: styles.item_size_xl,
	};

	// className
	if (className) {
		cssClass = cssClass.concat(className);
	}

	// Size
	if (size && size in sizeClass) {
		cssClass.push(sizeClass[size]);
	}

	// Selected
	if (selected) {
		cssClass.push(styles.item_selected);
	}

	// Disabled
	if (disabled) {
		cssClass.push(styles.item_disabled);
	} else {
		cssClass.push(styles.item_enabled);
	}

	// Capitalize
	if (capitalize) {
		cssClass.push(styles.item_capitalize);
	}

	return (
		<li>
			<a
				className={cssClass.join(' ')}
				style={style}
				onClick={(e) => handleClick(e)}
				onMouseEnter={(e) => onMouseEnter(e)}
				target={target}
				rel={rel}
				href={href}
				data-tip={tooltipHTML ? 'a' : tooltip}
				data-for={tooltipID}>
				<span className={styles.item_flex}>
					{hasCheckbox && 
						<span className={styles.item_left}>
							<Checkbox
								forcedChecked={selected}
							/>
						</span>
					}
					{hasRadio && 
						<span className={styles.item_left}>
							<Radio
								forcedChecked={selected}
							/>
						</span>
					}
					{icon &&
						<span className={styles.item_left}>
							<i className={styles.item_icon + ' ' + icon}></i>
						</span>
					}
					{iconCustom &&
						<span className={styles.item_left + ' ' + styles.item_icon}>
							{iconCustom}
						</span>
					}
					<span className={styles.item_center}>
						{message}
						{children}
						{description &&
							<div className={styles.item_desc}>{description}</div>
						}
						{imageUrl &&
							<img className={styles.item_img} src={imageUrl} />
						}
					</span>
					{selected && hasSelectedIcon &&
						<span className={styles.item_check}>
							<i className='fas fa-check'></i>
						</span>
					}
				</span>
			</a>
			{(tooltip || tooltipHTML) &&
				<Tooltip
					text={tooltip}
					html={tooltipHTML}
					id={tooltipID}
				/>
			}
		</li>
	);
}

function ListboxSep(): JSX.Element {
	return (
		<li className={styles.hr}></li>
	);
}

function ListboxGroupLabel({
	children
}: IListboxGroupLabel): JSX.Element {
	return (
		<li className={styles.groupLabel}>{children}</li>
	);
}

export {
	Listbox,
	ListboxItem,
	ListboxSep,
	ListboxGroupLabel
};
