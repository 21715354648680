import React from 'react';
import { Skeleton } from '../Skeleton/Skeleton';
import { Article } from '../Article/Article';
import { getFormattedPercent } from '../../Util/numberFormats';
import styles from './KPI.module.css';

interface IKPI {
	children?: React.ReactNode;
	isLoading?: boolean;
	label?: string;
	labelDescription?: string | React.ReactNode;
	value?: number | string | React.ReactNode;
	rate?: number;
	evolutionRate?: number;
	size?: 'm' | 's';
	className?: string;
}

interface IKPIList {
	children?: React.ReactNode;
	className?: string;
}

function KPI({
	children,
	isLoading,
	label,
	labelDescription,
	value,
	rate,
	evolutionRate,
	size = 'm',
	className
}:IKPI):JSX.Element {

	const cssClass:string[] = [];
	const multiLineLabel = label ? label.split('<br>') : [''];
	const hasValue = typeof value !== 'undefined';

	if (className) {
		cssClass.push(className);
	}

	if (size) {
		cssClass.push(styles['kpi_size_' + size]);
	}

	return (
		<div className={cssClass.join(' ')}>
			{isLoading && 
				<Skeleton appearance="kpi" nb={1} />
			}
			{!isLoading &&
				<>
					<div className={styles.kpi_title}>
						{multiLineLabel.map((line, i) =>
							<span key={i}>{line}</span>
						)}
						{labelDescription &&
							<div className={styles.kpi_description}>{labelDescription}</div>
						}
					</div>
					{hasValue &&
						<div className={styles.kpi_value}>
							{value}
						</div>
					}
					{children &&
						<div>{children}</div>
					}
					{typeof rate === 'number' &&
						<span className={styles.kpi_rate}>{rate}&nbsp;%</span>
					}
					{typeof evolutionRate === 'number' &&
						<>
							{evolutionRate >= 0 ?
								<span className={styles.kpi_evolution_up}>{getFormattedPercent(evolutionRate, 1, true)}</span>
								:
								<span className={styles.kpi_evolution_down}>{getFormattedPercent(evolutionRate, 1, true)}</span>
							}
						</>
					}
				</>
			}
		</div>
	);
}

function KPIArticle({
	...otherProps
}:IKPI):JSX.Element {

	return (
		<div className={styles.list_item_s}>
			<Article hasMargin={false} fullHeight={true}>
				<KPI {...otherProps} />
			</Article>
		</div>
	);
}

// function KPIComparison({
// 	isLoading,
// 	label,
// 	value,
// 	valueDecimals = 0,
// 	unit,
// 	rate,
// 	evolutionRate,
// 	size = 'm',
// 	className
// }:IKPI):JSX.Element {

// 	const cssClass:string[] = [];
// 	const multiLineLabel = label ? label.split('<br>') : [''];

// 	if (className) {
// 		cssClass.push(className);
// 	}

// 	if (size) {
// 		cssClass.push(styles['kpi_size_' + size]);
// 	}

// 	return (
// 		<div className={cssClass.join(' ')}>
// 			{isLoading && 
// 				<Skeleton appearance="kpi" nb={1} />
// 			}
// 			{!isLoading &&
// 				<>
// 					<div className={styles.kpi_title}>
// 						{multiLineLabel.map((line, i) =>
// 							<span key={i}>{line}</span>
// 						)}
// 					</div>
// 					<div className={styles.kpi_value}>
// 						{getFormattedNumber(value, valueDecimals, unit)}
// 					</div>
// 					{typeof rate === 'number' &&
// 						<span className={styles.kpi_rate}>{rate}&nbsp;%</span>
// 					}
// 					{typeof evolutionRate === 'number' &&
// 						<>
// 							{evolutionRate >= 0 ?
// 								<span className={styles.kpi_evolution_up}>{getFormattedPercent(evolutionRate, 1, true)}</span>
// 								:
// 								<span className={styles.kpi_evolution_down}>{getFormattedPercent(evolutionRate, 1, true)}</span>
// 							}
// 						</>
// 					}
// 				</>
// 			}
// 		</div>
// 	);
// }

function KPIArticleList({
	children,
	className
}:IKPIList):JSX.Element {

	const cssClass = [styles.article_list];

	if (className) {
		cssClass.push(className);
	}

	return (
		<div className={cssClass.join(' ')}>
			{children}
		</div>
	);
}

export {
	KPI,
	KPIArticle,
	KPIArticleList
};
