import React, { createContext, useState, useContext, useEffect } from 'react';
import { crudKpiInsights } from '../ProductInsights/Kpi/api/CrudKpiInsights';
import { Product } from '../ProductInsights/Kpi/TypeKpi';
import {
	SESSION_STORAGE_KEY,
	Chip,
	transformToDataStructureArray,
	DataStructure,
	getInitialSelectedValues,
	dateToFormat,
} from '../ProductInsights/utils';
import {useThrottle} from '../../.././Hooks/useThrottle';
import {
	ProductInsightsContextType,
	ProductInsightsProviderProps,
	Devices,
	PaginationOptions,
} from './TypeProductInsights';

const ProductInsightsContext = createContext<ProductInsightsContextType | undefined>(undefined);

export const useProductInsightsContext = () => {
	const contextValue = useContext(ProductInsightsContext);
	if (contextValue === undefined) {
		throw new Error(' must be used within an insights element');
	}
	return contextValue;
};
export const ProductInsightsContextProvider: React.FC<ProductInsightsProviderProps> = ({
	children,
	$location,
	$rootScope,
	$routeParams,
	AuthServices,
	$http,
	ComparisonSelected,
	handleSelectComparaisonPeriodHandler,
	onChangeNavFilter,
	initialDates,
	dateStringToTimestamp,
	navFilter,
	setNavFilter,
	isCustomSelected,
	setisCustomSelected,
	customDateRange,
	setCustomDateRange,
	updateStorage,
	selectedValues,
	setSelectedValues,
	accountHasNoDatas,
	setAccountHasNoDatas
}) => {

	const [insightsKpiPaylod] = useState({
		context: 'Global',
		name: 'empty',
		sorting: [],
		tenant: null,
		filtering: { op: 'And', filters: [] },
		groupSorting: null,
		transformations: [],
	});
	const productPerPage = 10;
	const [deviceType, setDeviceType] = useState<Devices>('Desktop');
	const {
		loadBestProgressScoreForProductsCompare,
		postQueryKpiExportInsightsServices,
		postQueryKpiInsightsServices,
		loadInsightsProductsForAccountComparison,
		loadInsightsProductsForAccountS,
		loadWorstScoreForProduct
	} = crudKpiInsights($http, $rootScope, $routeParams, AuthServices,setAccountHasNoDatas)as any;

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isLoadingForInsightsKpis, setIsLoadingForInsightsKpis] = useState(true);
	const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
	const [paginationOptions, setpaginationOptions] = useState<PaginationOptions>({
		poffset: 1,
		plimit: productPerPage,
		directions: 'desc',
		orderBy: 'numberofsessions',
		rowsperpage: productPerPage,
		totalRows: 0,
	});
	const [currentPageProductList, setCurrentPageProductList] = useState(1);
	const [productsSelections, setProductsSelections] = useState<Product[]>([]);
	const [productsSelectionsType, setProductsSelectionsType] = useState<string>('default');
	const [newPaginationNeed, setNewPaginationNeed] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [panelFormIsOpen, setPanelFormIsOpen] = useState(false);
	const [filterHierarchies, setFilterHierarchies] = useState([]);
	const [filterSelected, setFilterSelected] = useState<DataStructure[]>([]);
	const [isFilterSelectedInitialized, setIsFilterSelectedInitialized] = useState(false);
	const [chipsByFilterSelected, setChipsByFilterSelected] = useState<Chip[]>([]);
	const [dataInsights, setDataInsights] = useState<Array<any>>([]);
	const [dataInsightsBestProducts, setDataInsightsBestProducts] = useState<any>();
	const [dataInsightsWorstProducts, setDataInsightsWorstProducts] = useState<any>();
	const [isExporting, setIsExporting] = useState(false);
	const throttledValueInSearchBar = useThrottle(valueInSearchBar, 800);
	function handleOpenClosePanelForm(evt:boolean){
		setPanelFormIsOpen(evt);
	}
	function handleChangeFormData(data:any){
		sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(selectedValues));
		setFilterSelected(data);
		setPanelFormIsOpen(false);
	}
	function handleChange(evt: string) {
		setValueInSearchBar(evt);
	}
	function changeDeviceSelected(type: Devices): void {
		setDeviceType(type);
	}

	const handleChangePaginationOptions = (elemName: string, elemValue: string | number) => {
		if (elemName === 'orderBy') {
			if (elemValue === paginationOptions.orderBy) {
				setpaginationOptions((pagination) => ({
					...pagination,
					directions: pagination.directions === 'desc' ? 'asc' : 'desc',
				}));
			} else {
				// @ts-ignore
				setpaginationOptions((pagination) => ({
					...pagination,
					orderBy: elemValue,
					directions: 'desc',
				}));
			}
		} else {
			setpaginationOptions((pagination) => ({
				...pagination,
				[elemName]: elemValue,
			}));
		}
		setNewPaginationNeed(true);
	};

	const fetchExportKpis = () => {
		setIsExporting(true);
		const storedData = sessionStorage.getItem('globalProductInsightsComparedPeriode');
		let parsedData = false;
		if (storedData) {
			parsedData = JSON.parse(storedData);
		}
		const date1 = dateStringToTimestamp(navFilter.fromDate);
		const date2 = dateStringToTimestamp(navFilter.toDate);
		const date3 = parsedData ? dateStringToTimestamp(navFilter.fromDateToCompare) : '';
		const date4 = parsedData ? dateStringToTimestamp(navFilter.toDateToCompare) : '';

		const optionsObject = {
			startDate1: ComparisonSelected ? date3 : date1,
			endDate1: ComparisonSelected ? date4 : date2,
			startDate2: ComparisonSelected ? date1 : date3,
			endDate2: ComparisonSelected ? date2 : date4,
			rowsperpage: paginationOptions.plimit,
			orderby: paginationOptions.orderBy,
			direction: paginationOptions.directions,
			pattern: valueInSearchBar,
		};

		const controller = new AbortController();
		const signal = controller.signal;

		postQueryKpiExportInsightsServices(optionsObject, filterSelected,_data => {
			setIsExporting(false);
		}, err => {
			console.error('Error fetching data:', err);
			setIsExporting(false);
		}, signal);
	};

	const fetchData = (blocNb,filters) => {
		setIsLoading(true);
		const storedData = sessionStorage.getItem('globalProductInsightsComparedPeriode');
		let parsedData = false;
		if (storedData) {
			parsedData = JSON.parse(storedData);
		}
		const date1 = dateStringToTimestamp(navFilter.fromDate);
		const date2 = dateStringToTimestamp(navFilter.toDate);
		const date3 = parsedData ? dateStringToTimestamp(navFilter.fromDateToCompare) : '';
		const date4 = parsedData ? dateStringToTimestamp(navFilter.toDateToCompare) : '';

		const optionsObject = {
			startDate1: ComparisonSelected ? date3 : date1,
			endDate1: ComparisonSelected ? date4 : date2,
			startDate2: ComparisonSelected ? date1 : date3,
			endDate2: ComparisonSelected ? date2 : date4,
			rowsperpage: paginationOptions.plimit,
			blocnumber: blocNb !== null ? blocNb : currentPageProductList,
			orderby: paginationOptions.orderBy,
			direction: paginationOptions.directions,
			pattern: valueInSearchBar,
		};

		const controller = new AbortController();
		const signal = controller.signal;

		postQueryKpiInsightsServices(optionsObject, filters, data => {

			setProductsSelections(data.Products);
			setProductsSelectionsType(ComparisonSelected ? 'comparison' : 'default');
			setCurrentPageProductList(data.Pagination.BlocNumber);
			setTotalCount(data.Pagination.TotalRows);
			setIsLoading(false);
			setIsFirstLoading(false);
			setNewPaginationNeed(false);
			const breadcrumb = [];
			const mainHierarchy = [];

			data.Categories.forEach((category:any )=> {
				// @ts-ignore
				if (!mainHierarchy.includes(category.Type) && category.Type !== 'root') {
					// @ts-ignore
					mainHierarchy.push(category.Type);
				}
			});

			mainHierarchy.forEach(type => {
				const hierarchy = {
					name: type,
					categories: data.Categories
						.filter(category => category.Type === type)
						.map(category => ({
							name: category.Name,
							value: category.Value
						}))
				};
				// @ts-ignore
				breadcrumb.push(hierarchy);
			});
			setFilterHierarchies(breadcrumb);

			setpaginationOptions({
				poffset: data.Pagination.BlocNumber,
				plimit: productPerPage,
				directions: data.Pagination.Direction,
				orderBy: data.Pagination.OrderBy,
				rowsperpage: data.Pagination.RowsPerPage,
				totalRows: data.Pagination.TotalRows,
			});
		}, err => {
			setIsLoading(false);
			setIsFirstLoading(false);
			console.error('Error fetching data:', err);
			setNewPaginationNeed(false);
		}, signal);

		return () => {
			controller.abort();
		};
	};
	const fetchDataInsights = () =>{
		setIsLoadingForInsightsKpis(true);

		const storedData = sessionStorage.getItem('globalProductInsightsComparedPeriode');
		let parsedData = false;
		if (storedData) {
			parsedData = JSON.parse(storedData);
		}

		const date1 = dateStringToTimestamp(navFilter.fromDate);
		const date2 = dateStringToTimestamp(navFilter.toDate);
		const date3 = parsedData ? dateStringToTimestamp(navFilter.fromDateToCompare) : '';
		const date4 = parsedData ? dateStringToTimestamp(navFilter.toDateToCompare) : '';

		const controller = new AbortController();
		const signal = controller.signal;
		if(parsedData){
			const optionsObject = {
				startDate1: ComparisonSelected ? date3 : date1,
				endDate1: ComparisonSelected ? date4 : date2,
				startDate2: ComparisonSelected ? date1 : date3,
				endDate2: ComparisonSelected ? date2 : date4,
			};
			loadInsightsProductsForAccountComparison(optionsObject, data => {
				const dataInsightsToMerge = [
					{
						key : 'AverageScore',
						label : 'Averarge score',
						ValueComparison : data.AverageScore.ValueComparison,
						ValueReference : data.AverageScore.ValueReference,
						LevelOfImportance: data.AverageScore.LevelOfImportance,
						type: 'number'
					},
					{
						key : 'MostAttractiveCategory',
						label : 'Most attractive category',
						ValueComparison : data.MostAttractiveCategory.ValueComparison,
						ValueComparisonScore: data.MostAttractiveCategoryScore.ValueComparison,
						ValueReference : data.MostAttractiveCategory.ValueReference,
						ValueReferenceScore: data.MostAttractiveCategoryScore.ValueReference,
						LevelOfImportance: data.MostAttractiveCategory.LevelOfImportance,
						type: 'text'
					}
				];
				setDataInsights(dataInsightsToMerge);
			}, error => {
				console.log('=>(ContextProductInsights.tsx:349) error', error);
			},signal);
			const optionsObject2 = {
				startDate1: ComparisonSelected ? date3 : date1,
				endDate1: ComparisonSelected ? date4 : date2,
				startDate2: ComparisonSelected ? date1 : date3,
				endDate2: ComparisonSelected ? date2 : date4,
				rowsperpage : 5,
				blocnumber: 1,
				orderby:'scorecompare',
				direction:'desc',
				pattern: ''
			};
			const controller2 = new AbortController();
			const signal2 = controller2.signal;
			loadBestProgressScoreForProductsCompare(optionsObject2,data=>{
				setDataInsightsBestProducts({
					products: data.Products,
					dates : {
						reference: {
							start: navFilter.fromDate,
							end: navFilter.toDate
						},
						comparison: {
							start: navFilter.fromDateToCompare,
							end: navFilter.toDateToCompare
						},
						label: dateToFormat(navFilter.fromDateToCompare) + ' - ' + dateToFormat(navFilter.toDateToCompare)+  ' compared to ' + dateToFormat(navFilter.fromDate) + ' - ' + dateToFormat(navFilter.toDate)
					}
				});
				setIsLoadingForInsightsKpis(false);
			}, error => {
				setIsLoadingForInsightsKpis(false);
				console.log('=>(ContextProductInsights.tsx:349) error', error);
			},signal2);
			const optionsObject3 = {
				startDate1: ComparisonSelected ? date3 : date1,
				endDate1: ComparisonSelected ? date4 : date2,
				startDate2: ComparisonSelected ? date1 : date3,
				endDate2: ComparisonSelected ? date2 : date4,
				rowsperpage : 5,
				blocnumber: 1,
				orderby:'scorecompare',
				direction:'asc',
				pattern: ''
			};
			const controller3 = new AbortController();
			const signal3 = controller3.signal;
			loadWorstScoreForProduct(optionsObject3,data=>{
				setDataInsightsWorstProducts({
					products: data.Products,
					dates : {
						...navFilter,
						reference: {
							start: navFilter.fromDate,
							end: navFilter.toDate
						},
						comparison: {
							start: navFilter.fromDateToCompare,
							end: navFilter.toDateToCompare
						},
						label : dateToFormat(navFilter.fromDateToCompare) + ' - ' + dateToFormat(navFilter.toDateToCompare) +  ' compared to ' + dateToFormat(navFilter.fromDate) + ' - ' + dateToFormat(navFilter.toDate)
					}
				});
				setIsLoadingForInsightsKpis(false);
			}, error => {
				setIsLoadingForInsightsKpis(false);
				console.log('=>(ContextProductInsights.tsx:349) error', error);
			},signal3);
		}else{
			const optionsObject = {
				startDate1: date1,
				endDate1: date2,
			};
			loadInsightsProductsForAccountS(optionsObject, data=>{
				const dataInsightsToMerge = [
					{
						key : 'AverageScore',
						label : 'Average attractiveness score',
						value : data.AverageScore,
						type: 'number'
					},
					{
						key : 'MostAttractiveCategory',
						label : 'Most attractive category',
						value : data.MostAttractiveCategory,
						score: data.MostAttracttiveCatgeoryScore,
						type: 'text'
					}
				];
				setDataInsights(dataInsightsToMerge);
			}, error => {
				console.log('=>(ContextProductInsights.tsx:349) error', error);
			},signal);

			const optionsObject2 = {
				startDate1:dateStringToTimestamp(navFilter.fromDateToCompare),
				endDate1: dateStringToTimestamp(navFilter.toDateToCompare),
				startDate2:   dateStringToTimestamp(navFilter.fromDate),
				endDate2:  dateStringToTimestamp(navFilter.toDate) ,
				rowsperpage : 5,
				blocnumber: 1,
				orderby:'scorecompare',
				direction:'desc',
				pattern: ''
			};
			const controller2 = new AbortController();
			const signal2 = controller2.signal;
			loadBestProgressScoreForProductsCompare(optionsObject2,data=>{
				setDataInsightsBestProducts({
					products: data.Products,
					dates : {
						...navFilter,
						reference: {
							start: navFilter.fromDate,
							end: navFilter.toDate
						},
						comparison: {
							start: navFilter.fromDateToCompare,
							end: navFilter.toDateToCompare
						},
						label : dateToFormat(navFilter.fromDateToCompare) + ' - ' + dateToFormat(navFilter.toDateToCompare) +  ' compared to ' + dateToFormat(navFilter.fromDate) + ' - ' + dateToFormat(navFilter.toDate)
					}
				});
				setIsLoadingForInsightsKpis(false);
			}, error => {
				setIsLoadingForInsightsKpis(false);
				console.log('=>(ContextProductInsights.tsx:349) error', error);
			},signal2);

			const optionsObject3 = {
				startDate1:dateStringToTimestamp(navFilter.fromDateToCompare),
				endDate1: dateStringToTimestamp(navFilter.toDateToCompare),
				startDate2:   dateStringToTimestamp(navFilter.fromDate),
				endDate2:  dateStringToTimestamp(navFilter.toDate) ,
				rowsperpage : 5,
				blocnumber: 1,
				orderby:'scorecompare',
				direction:'asc',
				pattern: ''
			};
			const controller3 = new AbortController();
			const signal3 = controller3.signal;
			loadWorstScoreForProduct(optionsObject3,data=>{
				setDataInsightsWorstProducts({
					products: data.Products,
					dates : {
						...navFilter,
						reference: {
							start: navFilter.fromDate,
							end: navFilter.toDate
						},
						comparison: {
							start: navFilter.fromDateToCompare,
							end: navFilter.toDateToCompare
						},
						label : dateToFormat(navFilter.fromDateToCompare) + ' - ' + dateToFormat(navFilter.toDateToCompare) +  ' compared to ' + dateToFormat(navFilter.fromDate) + ' - ' + dateToFormat(navFilter.toDate)
					}
				});
				setIsLoadingForInsightsKpis(false);
			}, error => {
				setIsLoadingForInsightsKpis(false);
				console.log('=>(ContextProductInsights.tsx:349) error', error);
			},signal3);
		}
	};
	useEffect(() => {
		if (newPaginationNeed && isFilterSelectedInitialized) {
			const cleanup = fetchData(null,filterSelected);
			return cleanup();
		}
	}, [newPaginationNeed,filterSelected,isFilterSelectedInitialized]);

	useEffect(() => {
		const  hasData = getInitialSelectedValues();
		if(hasData) {
			const arrayOfSelectedFilters = transformToDataStructureArray(hasData);
			setFilterSelected(arrayOfSelectedFilters);
			setIsFilterSelectedInitialized(true);
		}
	}, []);

	useEffect(() => {
		if (isFilterSelectedInitialized && !accountHasNoDatas) {
			setIsLoading(true);
			if (throttledValueInSearchBar.length > 0) {
				fetchData(1, filterSelected);
			} else if (throttledValueInSearchBar.length === 0 && !newPaginationNeed) {
				fetchData(1, filterSelected);
			}
		}
	}, [
		accountHasNoDatas,
		throttledValueInSearchBar,
		navFilter,
		ComparisonSelected,
		filterSelected,
		isFilterSelectedInitialized]);

	useEffect(() => {
		const newArrayOfFilterChips:Chip[] = [];
		filterSelected.map((filter:DataStructure) => {
			if(filter.value.length > 1){
				newArrayOfFilterChips.push({
					type: filter.key,
					title:filter.value[0].name + ' +' + (filter.value.length - 1),
					handleDelete: ()=> {}
				});
			}else{
				newArrayOfFilterChips.push({
					type: filter.key,
					title:filter.value[0].name,
					handleDelete: ()=> {}
				});
			}
		});
		setChipsByFilterSelected(newArrayOfFilterChips);

	}, [ filterSelected]);

	useEffect(()=>{
		setIsLoadingForInsightsKpis(true);
		const timer = setTimeout(() => {
			if(!accountHasNoDatas){
				fetchDataInsights();
			}
		},600);
		return () => {
			if (timer) clearTimeout(timer);
		};
	},[ComparisonSelected,navFilter,accountHasNoDatas]);

	return (
		<ProductInsightsContext.Provider
			value={{
				accountHasNoDatas,
				isLoading,
				isFirstLoading,
				$location,
				changeDeviceSelected,
				deviceType,
				insightsKpiPaylod,
				productsSelections,
				productsSelectionsType,
				newPaginationNeed,
				setNewPaginationNeed,
				totalCount,
				handleChangePaginationOptions,
				currentPageProductList,
				setCurrentPageProductList,
				paginationOptions,
				valueInSearchBar,
				handleChange,
				fetchExportKpis,
				isExporting,
				ComparisonSelected,
				handleSelectComparaisonPeriodHandler,
				onChangeNavFilter,
				initialDates,
				dateStringToTimestamp,
				navFilter,
				setNavFilter,
				isCustomSelected,
				setisCustomSelected,
				customDateRange,
				setCustomDateRange,
				updateStorage,
				handleChangeFormData,
				handleOpenClosePanelForm,
				panelFormIsOpen,
				filterHierarchies,
				selectedValues,
				setSelectedValues,
				chipsByFilterSelected,
				setFilterSelected,
				dataInsights,
				dataInsightsBestProducts,
				dataInsightsWorstProducts,
				isLoadingForInsightsKpis
			}}
		>
			{children}
		</ProductInsightsContext.Provider>
	);
};
export default ProductInsightsContextProvider;
