import React from 'react';
import styles from './Card.module.css';
import { Tooltip } from '../../../../../Components/Tooltip/Tooltip';
import { v4 as uuidv4 } from 'uuid';

// IconText Component
interface IconTextProps {
    text?: string;
    icon?: string;
    tooltip?: string;
	tooltipHTML?: any;
}

const IconText: React.FC<IconTextProps> = ({
	text,
	icon,
	tooltip,
	tooltipHTML
}) => {

	const tooltipID = uuidv4();

	return (
		<div className={styles.headerTag} data-tip={tooltipHTML ? 'a' : tooltip} data-for={tooltipID}>
			{icon &&
				<div className={styles.headerTagIcon}>{icon}</div>
			}
			{text &&
				<div className={styles.headerTagLabel}>{text}</div>
			}
			{(tooltip || tooltipHTML) &&
				<Tooltip
					text={tooltip}
					html={tooltipHTML}
					id={tooltipID}
				/>
			}
		</div>
	);
};
export default  IconText;