import React, { useState,useEffect } from 'react';
import Dropdown from '../../../../Components/Dropdown.js';
import Btn from '../../../../Components/Btn.js';


type ElementSelected = {
	key: string,
	label: string
}

export default function SelectMetric({
	onSelectMetric,
	metricSelected,
	list,
	label
} ) {

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

	const [elementS, setElementS] = useState<ElementSelected | undefined>({
		key: 'Undefined metric',
		label: 'Undefined metrice'
	});
	useEffect(() => {
		const elem = metricSelected ? list.find(el => el.key === metricSelected) :{
			key: 'Undefined metric',
			label: 'Undefined metrice'
		};
		setElementS(elem);
	}, [metricSelected]);

	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={<Btn
				message={label + elementS?.label}
				style="ghost"
				color="black"
				arrow={true}
				fontSize="l"
				className="btn_reset_margin"
			/>}
		>
			<div className="flex flex_align_start">
				<div className="flex_item_full_fix">
					<ul className="listbox">
						{list.map((value) => {
							const isSelected =
								(metricSelected &&
									metricSelected) === value.key;
							return (
								<li key={value.key}>
									<a
										className={
											isSelected
												? 'listbox_item selected'
												: 'listbox_item'
										}
										onClick={() => {
											onSelectMetric(value), setDropdownIsOpen(false);
										}}
									>
										{value.label}
										{isSelected && (
											<span
												// className={styles.indicator}
											></span>
										)}
									</a>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</Dropdown>
	);
}
