
import { flow } from 'lodash';
import { TemplateObject } from './TemplateTypes';
import { DeviceType, Template } from '../../../WysiwygEditor/Types/BespokeTypes';
import { EditorState, EditorCreateOrUpdateMode } from './EditorStateTypes';
import { v4 as uuidv4 } from 'uuid';
import { propertiesProcessing } from '../../../WysiwygEditor/Utils/TemplateOperations';
import {getFinalIconValue} from '../../../WysiwygEditor/Utils/IconUtils';
const mergeEditablePropertiesIntoFormat = (
	mode: EditorCreateOrUpdateMode,
	f: any,
	variation: TemplateObject,
	initialCss: boolean
): Template => {

	return {
		...f,
		TemplateDescriptorDesktop: {
			...f.TemplateDescriptorDesktop,
			EditableCss:
				mode === EditorCreateOrUpdateMode.Create || initialCss
					?variation.TemplateDescriptorDesktop.Css
					: variation.TemplateDescriptorDesktop.EditableCss,
			EditableTemplate:
				variation.TemplateDescriptorDesktop.EditableTemplate
		},
		TemplateDescriptorMobile: {
			...f.SlideTemplateDescriptorMobile,
			EditableCss:
				mode === EditorCreateOrUpdateMode.Create || initialCss
					?variation.TemplateDescriptorMobile.Css
					: variation.TemplateDescriptorMobile.EditableCss,
			EditableTemplate:
				variation.TemplateDescriptorMobile.EditableTemplate
		},
	};
};


export const initializeFromFormat = (device: DeviceType, mode: EditorCreateOrUpdateMode, format: Template,  cssToInitialize: boolean, template: TemplateObject, formatIsSelected:boolean) => {
	const { updateEditorState: updateEditorStateFromFormat, updateVariation } = handleFormatChosenWith(mode, format, cssToInitialize, template);

	const initializeWysiwyg = (s: EditorState): EditorState => ({
		...s,
		formatIsSelected:formatIsSelected,
		currentWysiwyg:
			device === DeviceType.Desktop
				? format.WysiwygDesktop
				: format.WysiwygMobile,
	});

	const updateEditorState: ((s: EditorState) => EditorState) = flow([initializeWysiwyg, updateEditorStateFromFormat]);
	return {
		updateEditorState,
		updateVariation
	};
};
export const setupFormats = (formats) => {
	const {
		addPropertyKeys,
		formatPropertiesToStyleProperties
	} = propertiesProcessing();
	
	const addFormatsDefaultStyle = (formats) => formats.map(f => ({
		...f,
		Properties: (f.Properties || []).map(addPropertyKeys),
		defaultStyle: { properties: formatPropertiesToStyleProperties(f.Properties || []) }
	}));

	const addSubProperties = (p) => {
		if (p.Editor.TimezoneSelector) {
			return [{
				...p,
				TimezonePropertyName: p.Name + '_TZ'
			}, {
				Editor: { Type: 'Hidden' },
				FriendlyName: '',
				Key: uuidv4(),
				Name: p.Name + '_TZ',
				Value: p.Editor.Timezone
			}];
		}
		if (p.Editor.Type === 'Video'){
			return [{
				...p
			}, {
				Editor: { Type: 'Hidden' },
				FriendlyName: '',
				Key: uuidv4(),
				Name: p.Editor.VideoTypePropertyName,
				Value: p.Editor.VideoType
			}];
		}
		if (p.Editor.Type === 'ProductReferences') {
			return [{...p, Name: 'ProductReferences' }];
		}
		if (p.Editor.Type === 'ProductRecommendations') {
			return [{...p, Name: 'ShelvingRules' }];
		}
		if (p.Editor.Type === 'Icon') {
			p.Value = getFinalIconValue(p);
			return [p];
		}
		return [p];
	};

	const setFormatProperties = (properties) => {
		return properties.flatMap(p => {

			if (p.Editor.Type === 'Data') {
				return [{...p, Content: []}];
			}
			else if (p.Editor.Type === 'Collection' || p.Editor.Type === 'Group') {
				return [{ ...p, Content: p.Content.flatMap(addSubProperties) }];
			} else {
				return addSubProperties(p);
			}
		});
	};

	const formatsWithSetupProperties = formats.map(f => ({ ...f, Properties: setFormatProperties(f.Properties) }));
	const formatsWithDefaultStyle = addFormatsDefaultStyle(formatsWithSetupProperties);

	return formatsWithDefaultStyle;
};
export const handleFormatChosenWith = (mode: EditorCreateOrUpdateMode, format: Template, cssToInitialize: boolean, template: TemplateObject) => {
	const {
		templatePropertyToStyleProperty,
	} = propertiesProcessing();

	const updateVariationFromFormat = (variation: TemplateObject): TemplateObject => {
		const mergedFormat = mergeEditablePropertiesIntoFormat(
			mode,
			format,
			template,
			cssToInitialize
		);

		return {
			...variation,
			TemplateName: mergedFormat.TemplateName,
			TemplateDescriptorDesktop: mergedFormat.TemplateDescriptorDesktop,
			TemplateDescriptorMobile: mergedFormat.TemplateDescriptorMobile,
			Template: {
				...mergedFormat,
				Properties: mergedFormat.Properties,
			},
			Properties: mergedFormat.Properties.map(
				templatePropertyToStyleProperty
			),
		};
	};

	const newEditorStateStyles = (s: EditorState): EditorState => {
		return  s;
	};


	const updateVariation = updateVariationFromFormat;
	const updateEditorState: ((s: EditorState) => EditorState) = flow([newEditorStateStyles]);
	return {
		updateEditorState,
		updateVariation
	};
};


