import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '../../../Components/Link';
export default function NotificationCenterNavReporting({
	$routeParams,
	isAdmin,
	accountId
}) {
	const [t, i18n] = useTranslation('common');
	const back = () => {
		if (isAdmin && $routeParams.ka) {
			return `NotificationCenter/Dashboard?ka=${accountId}&ku=${$routeParams.ku}`;
		} else {
			return 'NotificationCenter/Dashboard';
		}
	};
	return (
		<div className="page_sidebar_title">
			<Link href={back()} icon="fas fa-arrow-left fa-sm">
				Back to notifications list
			</Link>
		</div>
	);
}
