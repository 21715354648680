import React, { Fragment, useEffect, useState } from 'react';
import {
	Panel,
	PanelHeader,
	PanelBody,
	PanelSection,
} from './../../Components/Panel/Panel';
import { ReportGeneratorContext } from './Context/index.js';
import Btn from './../../Components/Btn';
import { getIndicatorByKey } from '../ReportingCampaign/Indicators.js';
import SearchBar from '../../Components/SearchBar';
import ReactTooltip from 'react-tooltip';
import Checkbox from './../../Components/Checkbox';
import { useTranslation } from 'react-i18next';

export default function ModalKpi({ setmodalIsOpen, modalIsOpen }) {
	const [t] = useTranslation('common');

	// State Management
	const [indicatorsToMap, setIndicatorsToMap] = useState([]);
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [categoryData, setCategoryData] = useState({
		Display: [],
		Conversion: [],
		Other: [],
		Target: [],
		Interaction: [],
	});

	const {
		campaignSelected,
		selectedCampaignOptions,
		handleChangeCpgOptions,
		getKpisCampaign,
	} = ReportGeneratorContext();

	// Utility functions
	// const cleanInteractionLabel = (label) => {
	// 	if (!label) return '';
	// 	if (label.startsWith('evtcpg:')) return label.replace('evtcpg:', '');
	// 	if (label.includes('_evtcpg:')) {
	// 		const split = label.split('_evtcpg:');
	// 		const indicator = getIndicatorByKey(split[0]).label;
	// 		return `${split[1]} (${indicator})`;
	// 	}
	// 	return label;
	// };

	// const formatIndicatorDisplayLabel = (indicator:any) => {
	// 	const cleanLabel = (label) =>
	// 		label.includes('evtcpg:') ? cleanInteractionLabel(label) : label.replace('_', ' ');
	// 	return indicator.label ? cleanLabel(indicator.label) : indicator.key;
	// };

	const findElementKeyInListSetup = (key) =>
		selectedCampaignOptions?.indicators?.includes(key);

	const handleSearchFilter = (data, query) => {
		if (!query.trim()) return data;
		const regex = new RegExp(query.trim(), 'i');
		return data.filter((item) => regex.test(item.label));
	};

	const handleCategoryUpdate = () => {
		if (indicatorsToMap.length) {
			const updatedCategories:any = {
				Display: handleSearchFilter(
					indicatorsToMap.filter((item:any) => item.category === 'Display Data'),
					valueInSearchBar
				),
				Conversion: handleSearchFilter(
					indicatorsToMap.filter((item:any) => item.category === 'Conversion Data'),
					valueInSearchBar
				),
				Other: handleSearchFilter(
					indicatorsToMap.filter(
						(item:any) => item.category === 'Other' || item.category === ''
					),
					valueInSearchBar
				),
				Target: handleSearchFilter(
					campaignSelected?.targets?.map((el) => ({
						...getIndicatorByKey(typeof el === 'string' ? el : el.key),
						key: typeof el === 'string' ? el : el.key,
					})) || [],
					valueInSearchBar
				),
				Interaction: handleSearchFilter(
					campaignSelected?.interactionIndicators?.map((el) => ({
						...getIndicatorByKey(typeof el === 'string' ? el : el.key),
						key: typeof el === 'string' ? el : el.key,
					})) || [],
					valueInSearchBar
				),
			};
			setCategoryData(updatedCategories);
		}
	};

	const handleChangeKpi = (e) => {
		const { name, checked } = e.target;
		let updatedIndicators = [...(selectedCampaignOptions.indicators || [])];

		if (checked && !updatedIndicators.includes(name)) {
			updatedIndicators.push(name);
		} else {
			updatedIndicators = updatedIndicators.filter((key) => key !== name);
		}

		handleChangeCpgOptions('indicators', updatedIndicators);
	};

	useEffect(() => {
		if (selectedCampaignOptions) {
			getKpisCampaign(selectedCampaignOptions.id);
		}
	}, [selectedCampaignOptions]);

	useEffect(() => {
		if (campaignSelected?.indicators) {
			const indicators = campaignSelected.indicators.map((el) => ({
				...getIndicatorByKey(typeof el === 'string' ? el : el.key),
				key: typeof el === 'string' ? el : el.key,
			}));
			setIndicatorsToMap(indicators);
		}
	}, [campaignSelected]);

	useEffect(() => {
		handleCategoryUpdate();
	}, [valueInSearchBar, indicatorsToMap, campaignSelected]);

	return (
		<Panel isOpen={modalIsOpen} width={500} onClose={() => setmodalIsOpen(false)}>
			<PanelHeader
				title="Add KPIs"
				desc={selectedCampaignOptions?.name || ''}
				onClose={() => setmodalIsOpen(false)}
				primaryAction={<Btn message="Done" onClick={() => setmodalIsOpen(false)} />}
			/>
			<PanelSection color="grey" size="s">
				<SearchBar
					onChangeValue={(e) => setValueInSearchBar(e.target.value)}
					valueInSearchBar={valueInSearchBar}
					placeholder="Find a KPI"
					fullWidth
					color="white"
				/>
			</PanelSection>
			<PanelBody>
				{Object.entries(categoryData).map(([category, items]) => {
					return <div key={category} className="add_kpi_group">
						{items.length > 0 && (
							<>
								<div className="add_kpi_group_label">{t(`AddKpiComponent.${category}`)}</div>
								<ul className="add_kpi_group_list">
									{items.map((item: any) => (
										<li key={item.key}>
											<Checkbox
												name={item.key}
												label={item.label}
												checked={findElementKeyInListSetup(item.key)}
												onChange={handleChangeKpi}
											>
												{item.infos && (
													<Fragment>
														<ReactTooltip
															id="custom-color-no-arrow"
															backgroundColor="rgba(103, 213, 215, 0.89)"
															delayHide={100}
															effect="float"
														/>
														<i
															data-for="custom-color-no-arrow"
															className="cb_label_tooltip_target fas fa-info-circle"
															data-tip={item.infos}
														></i>
													</Fragment>
												)}
											</Checkbox>
										</li>
									))}
								</ul>
							</>
						)}
					</div>;
				})}
			</PanelBody>
		</Panel>
	);
}
