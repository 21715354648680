
import React, { createContext, useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { checkAccountDataQuery,getCampaignsNotificationsSource } from '../../../../Services/Queries/OnSiteDashboardQueries';
import ExternalAppServices from '../../../../Services/ExternalAppServices';

export const context = createContext();

export function onSiteContext() {
	return useContext(context);
}


export const ContextProvider = (props) => {

	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $http = props.$http;
	const $timeout = props.$timeout;
	const AuthServices = props.AuthServices;

	const tooltipID = uuidv4();
	const systemServices = props.systemServices;
	const labelServices = props.labelServices;
	const accountId = props.accountId;
	const token = props.token;
	const technicalPurposes = props.technicalPurposes;

	const permissionsAccount = props.permissionsAccount;
	const client = props.client;
	const ImpersonatingServices = props.ImpersonatingServices;
	const isNotificationMode = props.isNotificationMode;
	const {
		campaignCreationVersion,
		canCreateInAppCampaigns,
		canCreateCampaigns,
		canCreatePalCampaigns,
		canCreateExternalAppCampaigns,
		canCreateCodePatchCampaigns,
		canCreateABtest,
		canCreatePatchCampaigns,
		canCreateClassicCampaigns,
		canCreateSaaSCampaigns,
		canEditCampaigns
	} = props.campaignsListCreationOptions ?? {
		campaignCreationVersion: '',
		canCreateInAppCampaigns: false,
		canCreateCampaigns: false,
		canCreatePalCampaigns: false,
		canCreateExternalAppCampaigns: false,
		canCreateCodePatchCampaigns: false,
		canCreateABtest: false,
		canCreatePatchCampaigns: false,
		canCreateClassicCampaigns: false,
		canCreateSaaSCampaigns: false,
		canEditCampaigns: false
	};

	const { listPageTitle, searchCampaignPlaceholder } = props.getContextualLabels();

	const IpAdressesServices = props.IpAdressesServices;
	const ReportingCampaignServices = props.ReportingCampaignServices;
	const screenShotServices = props.screenShotServices;
	const isAdmin = $rootScope.$$childHead.IsAnAdmin();
	const ku = $rootScope.$$childHead.IsAnAdmin() ? props.$rootScope.$$childHead.ConnectedUser.Key : props.$rootScope.$$childHead.ConnectedUser.Key;
	const externalAppServices = new ExternalAppServices($http, accountId);
	const [needFetchList, setneedFetchList] = useState(false);
	const [currentIP, setCurrentIP] = useState();
	const [idOfCampaign, setidOfCampaign] = useState('');
	const [showConfiguration, setshowConfiguration] = useState(false);
	const [modalPermissionsIsOppen, setModalPermissionsIsOppen] = useState(false);
	const [modalTestModeIsOpen, setModalTestModeIsOpen] = useState(false);
	const [forcedStatus, setForcedStatus] = useState();
	const [modalLabelsCampaignisOpen, setModalLabelsCampaignIsOpen] = useState(false);
	const [modalScenarioLabelsCampaignisOpen, setModalScenarioLabelsCampaignIsOpen] = useState(false);
	const [modalCampaignUploadImageisOpen, setmodalCampaignUploadImage] = useState(false);
	const [modalCampaignToDeleteIsOpen, setModalCampaignToDelete] = useState(false);
	const [currentCampaign, setCurrentCampaign] = useState({});
	const [modalLabelsAccountisOpen, setmodalLabelsAccountisOpen] = useState(false);
	const [filterStatusMode, setFilterStatusMode] = useState(null);

	const [filterTestMode, setFilterTestMode] = useState(true);
	const [LabelsAccount, setLabelsAccount] = useState([]);
	const [filterLabel, setfilterLabel] = useState();
	const [filterLabelId, setfilterLabelId] = useState();
	const [statusArray, setstatusArray] = useState([
		'onGoing',
		'scheduled',
		'stopped',
	]);
	const [hideFilRouge, setHideFilRouge] = useState(false);
	const [nbOFOnGoingCp, setNbOfOnGoingCp] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [searchBarvalue, setsearchBarvalue] = useState({
		or: [
			{ name: { contains: searchValue } },
			{ description: { contains: searchValue } },
			{ searchId: { contains: searchValue } },
		],
	});
	const [isSimpleModeFilterStatus, setisSimpleModeFilterStatus] = useState(false);

	const getDimensionFromLocation = () => window.location.pathname.contains('Planning') ? 'planning' : 'dashboard';

	const filterByTechnicalPurpose = { technicalPurpose: { in: technicalPurposes } };
	const [filterBylabels, setfilterBylabels] = useState();
	const [dateRange, setdateRange] = useState();
	const [defaultView, setDefaultView] = useState('month');
	const [dimension, setdimension] = useState(getDimensionFromLocation());
	const [loadingCampaign, setLoadingCampaign] = useState(false);
	const [pageTitle, setPageTitle] = useState('');
	const [hasCenter, setHasCenter] = useState(false);
	const [hasNotifs, setHasNotifs] = useState(false);
	const [externalAppSetup, setExternalAppSetup] = useState({
		hasExternalAppSetup: false,
		hasErrorToGetSetupInfo: false,
		installationError: false,
		installationErrorMessage: '',
		actionNeeded: '', //InstallApp or ActivateApp
		actionLink: ''
	});
	const getExternalAppSetupInfo = () => {
		externalAppServices.getExternalAppSetupInfo('custom',
			data => {
				setExternalAppSetup({ ...data, hasExternalAppSetup: true });
			});
	};
	useEffect(() => {
		setdimension(getDimensionFromLocation());
		getExternalAppSetupInfo();
	}, [window.location.pathname]);

	// Get current IP address
	useEffect(() => {
		fetch('https://backoffice.apiv2.beyable.com/api/ip-lookup')
			.then(response => response.json())
			.then(data => setCurrentIP(data.ipAddress));
	}, []);


	useEffect(() => {
		const title = dimension == 'planning' ? 'Planning' : listPageTitle;
		setPageTitle(title);
	}, [dimension]);

	const openAccountConfigPannel = (campaign) => {
		setshowConfiguration(true);
		setidOfCampaign(campaign);
		setCurrentCampaign(campaign.id);
	};
	const closeShowConfiguration = () => {
		setshowConfiguration(false);
	};

	const openCloseModalPermissions = (bool, campaign) => {
		if (bool) {
			setModalPermissionsIsOppen(true);
			setidOfCampaign(campaign.id);
			setCurrentCampaign(campaign);
		} else {
			setModalPermissionsIsOppen(false);
			setidOfCampaign('');
			setCurrentCampaign({});
		}
	};
	const openCloseModalTestMode = (bool, campaign, newStatus) => {
		if (bool) {
			if (newStatus === 'started') {
				setForcedStatus('started');
			}
			setModalTestModeIsOpen(true);
			setidOfCampaign(campaign.id);
			setCurrentCampaign(campaign);
		} else {
			setModalTestModeIsOpen(false);
			setidOfCampaign('');
			setCurrentCampaign({});
			setForcedStatus('');
		}
	};
	const openModalCampaignDelete = (bool, campaign) => {
		setModalCampaignToDelete(bool),
		setidOfCampaign(campaign.id);
		setCurrentCampaign(campaign);
	};
	const closeTheModalOfLabelsCampaign = (needFetchList) => {
		setModalLabelsCampaignIsOpen(false);
		setneedFetchList(needFetchList);
	};
	const closeTheModalOfScenarioLabelsCampaign = (needFetchList) => {
		setModalScenarioLabelsCampaignIsOpen(false);
		setneedFetchList(needFetchList);
	};
	const openModalCampaignLabels = (bool, campaign) => {
		setModalLabelsCampaignIsOpen(bool);
		setidOfCampaign(campaign.id);
		setCurrentCampaign(campaign);
	};
	const openModalCampaignScenarioLabels = (bool, campaign) => {
		setModalScenarioLabelsCampaignIsOpen(bool);
		setidOfCampaign(campaign.id);
		setCurrentCampaign(campaign);
	};
	const openCloseModalUploadImage = (bool, campaign) => {
		setmodalCampaignUploadImage(bool);
		setidOfCampaign(campaign.id);
		setCurrentCampaign(campaign);
	};
	const duplicateACampaign = (campaignId, onSuccess) => {
		setLoadingCampaign(campaignId);
		setreloadTheOperationItem(true);
		props.ReportingCampaignServices.postACopyOfCampaign(props.accountId, null, campaignId,
			success => {
				setLoadingCampaign(false);
				setTimeout(() => {
					setreloadTheOperationItem(false);
					setneedFetchList(true);
				}, 1000);
				let idOfDuplicateCampaing = success.Key;
				onSuccess && onSuccess(idOfDuplicateCampaing);
			},
			error => {
				setLoadingCampaign(false);
				setreloadTheOperationItem(false);
				props.systemServices.showError('An error occured during the duplication of the campaign.');
			});
	};
	const transformToNativeApp = (campaignId, onSuccess) => {
		setLoadingCampaign(campaignId);
		setreloadTheOperationItem(true);
		props.ReportingCampaignServices.postTransformToNativeApp(props.accountId, null, campaignId,
			success => {
				setLoadingCampaign(false);
				setTimeout(() => {
					setreloadTheOperationItem(false);
					setneedFetchList(true);
				}, 1000);
				let idOfDuplicateCampaing = success.CampaignId;
				onSuccess && onSuccess(idOfDuplicateCampaing);
			},
			error => {
				setLoadingCampaign(false);
				setreloadTheOperationItem(false);
				props.systemServices.showError('An error occured during the transformation of the campaign.');
			});
	};

	const changeStatusOfCampaign = (bool, data) => {
		setLoadingCampaign(data.id);
		setreloadTheOperationItem(true);
		props.ReportingCampaignServices.putStatusCampaign(accountId, data.id, bool,
			success => {
				setLoadingCampaign(false);
				setneedFetchList(true);
				setreloadTheOperationItem(false);
				props.systemServices.showSuccess(`The campaign has been successfully ${!bool ? 'activated' : 'desactivated'}`);
			},
			error => {
				setLoadingCampaign(false);
				setreloadTheOperationItem(false);
				props.systemServices.showError(`An error occured while ${!bool ? 'activation of ' : 'desactivation of '} the campaign . `);
			});
	};

	const refreshCampaign = (accountId, campaignId) => {
		setreloadTheOperationItem(true);
		props.ReportingCampaignServices.putRefreshCampaign(accountId, campaignId, success => {
			setreloadTheOperationItem(false);
			props.systemServices.showSuccess('The campaign has been successfully refreshed');
		},
		(error) => {
			setreloadTheOperationItem(false);
			props.systemServices.showError('An error occured during the refresh of the campaign.');
		});
	};

	const [reloadTheOperationItem, setreloadTheOperationItem] = useState(false);
	// request loader graphQl 
	const [accountIsLoading, setAccountIsLoading] = useState(true);
	const [checkAccountData, setcheckAccountData] = useState(false);
	let andFiltersCenter;
	const checkAccountDataFetch = () => {
		if(!isNotificationMode){
			andFiltersCenter = {technicalPurpose: {in: ['ACTUAL_CAMPAIGN', 'ACTUAL_NATIVE_APP_CAMPAIGN']}};
			client.query({
				query: checkAccountDataQuery,
				variables: {
					accountId: accountId,
					skip: 0,
					where: {
						and: andFiltersCenter,
					},
				}
			}).then(response => {
				setAccountIsLoading(false);
				setcheckAccountData(response.data.account.onSiteCampaigns.items.length !== 0 ? true : false);
			}
			).catch(e => {
				setAccountIsLoading(false);
				console.log('err', e);
				systemServices.showError('An error occured');
			});

		}else{
			andFiltersCenter = {technicalPurpose: {in: ['NOTIFICATION_CENTER', 'NOTIFICATION_CENTER_MESSAGE']}};
			const getCenter = ():void => {
				client
					.query({
						query: getCampaignsNotificationsSource,
						variables: {
							accountId: accountId,
							where: {
								and: andFiltersCenter,
							},
						},
					})
					.then((response) => {
						const allCampaigns = response.data.account.onSiteCampaigns.items;
						const centerCampaigns = allCampaigns.filter((c) => c.technicalPurpose === 'NOTIFICATION_CENTER');
						const notifCampaigns = allCampaigns.filter((c) => c.technicalPurpose === 'NOTIFICATION_CENTER_MESSAGE');

						setAccountIsLoading(false);
						setcheckAccountData(notifCampaigns.length > 0);
						setHasCenter(centerCampaigns.length > 0);
						setHasNotifs(notifCampaigns.length > 0);
					})
					.catch((e) => {
						console.log('err', e);
						systemServices.showError('an error occured');
						setAccountIsLoading(false);
					});
			};
			getCenter();
		}
	};
	useEffect(() => {
		checkAccountDataFetch();
	}, []);

	// campaign inspiration
	const openInspirations = (ev) => {
		$rootScope.$broadcast('event:changeStateOfInspirationPanel', true);
	};
	const openCatalogue = (ev) => {
		$rootScope.$broadcast('event:changeStateOfCataloguePanel', true);
	};
	const [createDropdownIsOpen, setCreateDropdownIsOpen] = useState(false);
	const [devMode, setDevMode] = useState(false);
	const [devices, setDevices] = useState([]);
	const resetSource = () => {
		setDevices([]);
	};
	function handleChangeDeviceSelected(device) {
		let newDevices = [...devices];

		const newKey = devices.find(el => el === device);
		const oldD = [...devices];
		const indefOfVal = oldD.indexOf(device);
		if (indefOfVal !== -1) {
			newDevices = oldD.filter(x => x !== newKey);
		} else {
			newDevices = ([...oldD, device]);
		}
		setDevices(newDevices);
	}

	function handleChangeDeviceValidated() {
		setneedFetchList(true);
	}


	//label
	useEffect(() => {
		if (filterLabel) {
			if (filterLabel.length !== 0) {
				setfilterBylabels({
					labels: {
						some: {
							or: filterLabel.map((lab) => ({ name: { eq: lab.name } })),
						},
					},
				});
			} else {
				setfilterBylabels({
					labels: {
						all: {
							or: [{ name: { neq: '' } }],
						},
					},
				});
			}
		}
	}, [filterLabel]);
	const [firstRender, setfirstRender] = useState(true);
	useEffect(() => {
		let search = searchValue.trim();
		if (firstRender) {
			setfirstRender(false);
		} else {
			setsearchBarvalue({
				or: [
					{ name: { contains: search } },
					{ description: { contains: search } },
					{ searchId: { contains: search } },
				],
			});
			const timer = setTimeout(() => {
				sessionStorage.setItem(
					'onSiteSearchFilter',
					JSON.stringify(search)
				);
			}, 1000);
			return () => {
				clearTimeout(timer);
			};
		}

	}, [searchValue]);
	return (
		<context.Provider
			value={{
				// GLOBAL DATA
				accountId,
				token,
				$rootScope,
				$http,
				$routeParams,
				$timeout,
				ku,
				// Apollo client
				client,
				// SERVICES
				AuthServices,
				checkAccountData,
				tooltipID,
				systemServices,
				ImpersonatingServices,
				ReportingCampaignServices,
				screenShotServices,
				labelServices,
				IpAdressesServices,

				// PERMISION STATE
				permissionsAccount,
				currentIP,
				setCurrentIP,
				isAdmin,
				canEditCampaigns,
				canCreateInAppCampaigns,
				canCreateCampaigns,
				canCreatePalCampaigns,
				canCreateExternalAppCampaigns,
				canCreateCodePatchCampaigns,
				canCreateABtest,
				canCreatePatchCampaigns,
				canCreateClassicCampaigns,
				canCreateSaaSCampaigns,
				campaignCreationVersion,
				// GLOBAL MODALS STATE
				modalLabelsAccountisOpen,
				setmodalLabelsAccountisOpen,
				openAccountConfigPannel,
				showConfiguration,
				closeShowConfiguration,

				// CAMPAIGN MODALS STATE
				modalPermissionsIsOppen,
				openCloseModalPermissions,
				modalTestModeIsOpen,
				openCloseModalTestMode,
				forcedStatus,
				setForcedStatus,
				modalLabelsCampaignisOpen,
				modalScenarioLabelsCampaignisOpen,

				closeTheModalOfLabelsCampaign,
				closeTheModalOfScenarioLabelsCampaign,
				openModalCampaignLabels,
				openModalCampaignScenarioLabels,
				openCloseModalUploadImage,
				modalCampaignUploadImageisOpen,
				modalCampaignToDeleteIsOpen,
				setModalCampaignToDelete,
				openModalCampaignDelete,

				loadingCampaign,
				setLoadingCampaign,
				duplicateACampaign,
				transformToNativeApp,
				changeStatusOfCampaign,

				createDropdownIsOpen,
				setCreateDropdownIsOpen,

				currentCampaign,
				setCurrentCampaign,
				idOfCampaign,
				setidOfCampaign,
				// REFRESH CAMPAIGN
				refreshCampaign,
				needFetchList,
				setneedFetchList,
				reloadTheOperationItem,
				setreloadTheOperationItem,
				// OTHER
				openInspirations,
				openCatalogue,
				accountIsLoading, // MAYBE NOT NECESSARY
				setAccountIsLoading, // MAYBE NOT NECESSARY
				devMode,
				setDevMode,
				filterStatusMode,
				setFilterStatusMode,
				filterTestMode,
				setFilterTestMode,
				LabelsAccount,
				setLabelsAccount,
				filterLabel,
				setfilterLabel,
				filterLabelId,
				setfilterLabelId,
				statusArray,
				setstatusArray,
				isSimpleModeFilterStatus,
				setisSimpleModeFilterStatus,

				searchValue,
				setSearchValue,
				dateRange,
				setdateRange,
				defaultView,
				setDefaultView,
				dimension,
				setdimension,
				pageTitle,
				searchCampaignPlaceholder,
				searchBarvalue,
				setsearchBarvalue,
				filterBylabels,
				filterByTechnicalPurpose,
				firstRender,
				setfirstRender,
				hideFilRouge,
				setHideFilRouge,
				nbOFOnGoingCp,
				setNbOfOnGoingCp,
				externalAppSetup,
				devices,
				resetSource,
				handleChangeDeviceSelected,
				handleChangeDeviceValidated,
				hasCenter,
				hasNotifs,
				isNotificationMode
			}}
		>
			{props.children}
		</context.Provider>
	);
};
