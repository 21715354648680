import React, {useState} from 'react';
import { SegmentGroupStatus } from '../../../Services/SegmentGroupServicesTypes';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';
import { useTranslation } from 'react-i18next';

export default function DropDownStatus({segmentGroup,changeSegmentGroupStatus}) {

	const [t] = useTranslation(['segments']);
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);


	const handleChange = (status: SegmentGroupStatus) => {
		changeSegmentGroupStatus(segmentGroup.id, status);
		setDropdownIsOpen(false);
	};

	function getFriendlyValue (value) {
		if (value === 'Live') {
			return t('active', {ns: 'segments'});
		}
		return t('inactive', {ns: 'segments'});
	}

	const colorClass = segmentGroup.status === 'Live' ? 'ongoing' : 'past';
	
	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={
				<a className="campaign_status_tag has_arrow clickable">
					<span className={'campaign_status_tag_color ' + colorClass}></span>
					<span className="campaign_status_tag_name">{getFriendlyValue(segmentGroup.status)}</span>
				</a>
			}
		>
			<Listbox>
				<ListboxItem
					message={getFriendlyValue('Live')}
					onClick={() => handleChange('Live')}
					selected={segmentGroup.status === 'Live'}
					hasSelectedIcon={true}
				/>
				<ListboxItem
					message={getFriendlyValue('Stopped')}
					onClick={() => handleChange('Stopped')}
					selected={segmentGroup.status === 'Stopped'}
					hasSelectedIcon={true}
				/>
			</Listbox>
		</Dropdown>
	);
}
