import React, { useState, useEffect } from 'react';
import {datesOptions} from './config';
import styles from './Nav.module.css';
import moment from 'moment';
import Dropdown from '../../../../Components/Dropdown';
import AddComparisonPeriod from './AddComparisonPeriod';

import CustomPicker from './CustomPicker';

const Nav = ({
	handleClick,
	initialDates,
	navLabel = 'Period',
	showComparisonToggler = false,
	showComparisonRemover = false,
	setComparisonIsEnable,
	comparisonSelected,
	setisCustomSelected,
	isCustomSelected = false,
	customDateRange,
	setCustomDateRange
}) => {

	const [current, setCurrent] = useState(null);
	const labels =  Object.keys(datesOptions);
	const [focusedInput, setFocusedInput] = useState('startDate');
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const handleOnDateChange = ({ startDate, endDate }) => {
		setCustomDateRange({
			startDate: startDate,
			endDate: endDate
		});
	};

	const onFocused = (focused) => {
		if (focused === null) {
			// setDropdownIsOpen(false);
		}
		setFocusedInput(!focused ? 'startDate' : focused);
	};
	const onHandleClick = (label) => {
		if (label === '') return;

		if (label !== 'Custom') {
			setisCustomSelected(false);
			setDropdownIsOpen(false);
			setCurrent({
				...datesOptions[label],
				label: label
			});
			handleClick({
				...datesOptions[label],
				label: label
			});
		}
		if (label === 'Custom') {

			setisCustomSelected(true);
			setCurrent({ label: 'Custom' });
		}
	};
	const handleValidDate = () => {
		setDropdownIsOpen(false);
		const startDate = moment(customDateRange.startDate);
		const endDate = moment(customDateRange.endDate);
		
		handleClick({
			fromDate: startDate.format('YYYY/MM/DD'),
			toDate: endDate.format('YYYY/MM/DD'),
			label: 'Custom'
		});
	};

	const handlePreventToggleDropdown = (e) => {
		e.stopPropagation();
	};

	const handleRemoveComparison = (e) => {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		if (typeof setComparisonIsEnable === 'function') {
			setComparisonIsEnable(false);
		}
	};
	useEffect(()=>{
		if(initialDates && current === null){
			const label =  initialDates.initialLabel;
			const isCustom =  initialDates.isMainCustom;
			const labelNew = moment(initialDates.initialFrom).format('MMM DD, YYYY') + ' - ' + moment(initialDates.initialTo).format('MMM DD, YYYY');
			if (label === 'Custom') {
				setCurrent({ label: labelNew });
				setisCustomSelected(true);
				setCustomDateRange({
					startDate: moment(initialDates.initialFrom),
					endDate: moment( initialDates.initialTo)
				});
			} else {
				setCurrent({ 
					...datesOptions[label || 'Last 7 days'],
					label: label || 'Last 7 days'
				});
				setisCustomSelected(false);
			}
		}
	},[initialDates, navLabel]);

	const button = (
		<button type="button" className="custom_input clickable m w_auto bg_white">
			<span className="custom_input_value has_label">
				<span className="custom_input_value_label">{navLabel}</span>
				{isCustomSelected &&
					<>
						{moment(initialDates.initialFrom).format('MMM DD, YYYY')}
						<i className="analytics_range_arrow fas fa-arrow-right"></i>
						{moment(initialDates.initialTo).format('MMM DD, YYYY')}
					</>
				}
				{!isCustomSelected &&
					<>{current?.label}</>
				}
			</span>
			<i className="custom_input_icon fas fa-calendar-alt"></i>
			{showComparisonRemover &&
				<span className="custom_input_reset"
					onMouseDown={(e) => handlePreventToggleDropdown(e)}
					onClick={(e) => handleRemoveComparison(e)}>
					<i className="fas fa-times-circle"></i>
				</span>
			}
		</button>
	);


	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={button}
			flip={false}
		>
			<div className="flex flex_align_start">
				<div className="flex_item_full_fix">
					<ul className="listbox">
						{labels.map((label) => {
							const isSelected = isCustomSelected ? label === 'Custom' : (current?.label) === label;
							return (
								<li key={label}>
									<a className={isSelected ? 'listbox_item selected' : 'listbox_item'}
										onClick={() => onHandleClick(label)}>
										{label}
										{isSelected && (<span className={styles.indicator}></span>)}
									</a>
								</li>
							);
						})}
						{showComparisonToggler && 
							<>
								<li className="hr" />
								<li>
									<span className="listbox_item">
										<AddComparisonPeriod
											selectHandler={setComparisonIsEnable}
											comparisonSelected={comparisonSelected}
											closeDropdown={()=>setDropdownIsOpen(false)}
										/>
									</span>
								</li>
							</>
						}
					</ul>
				</div>
				<CustomPicker 
					isCustomSelected={isCustomSelected}
					customDateRange={customDateRange}
					onFocused={onFocused}
					focusedInput={focusedInput}
					handleValidDate={handleValidDate}
					handleOnDateChange={handleOnDateChange}
				/>
			</div>
		</Dropdown>
	);
};


Nav.displayName = 'Nav';
export default Nav;