import React  from 'react';

import Nav from '../Nav/Nav';

export default function NavOverview({
	ComparisonSelected,
	setNavFilter,
	initialDates,
	handleSelectComparaisonPeriodHandler,
	setisCustomSelected,
	isCustomSelected,
	customDateRange,
	setCustomDateRange,
	updateStorage
}) {

	function handleClickOriginal(obj) {
		updateStorage( obj.fromDate,obj.toDate );
		setNavFilter(stateInit => ({
			...stateInit,
			fromDate: obj.fromDate,
			toDate: obj.toDate
		}
		))
	}
	function handleClickCompared(obj) {
		setNavFilter(stateInit => ({
			...stateInit,
			fromDateToCompare: obj.fromDate,
			toDateToCompare: obj.toDate
		}
		))
	}
	return (<>
		<Nav
			handleClick={handleClickOriginal}
			initialDates={initialDates}
			showComparisonToggler={false}
			setComparisonIsEnable={handleSelectComparaisonPeriodHandler}
			comparisonSelected={ComparisonSelected}
			setisCustomSelected={setisCustomSelected}
			isCustomSelected={isCustomSelected}
			customDateRange={customDateRange}
			setCustomDateRange={setCustomDateRange}
		/>
		{ComparisonSelected &&
			<Nav
				initialLabel={'initialLabel2'}
				handleClick={handleClickCompared}
				initialDates={initialDates}
				navLabel="Compared to"
				showComparisonRemover={true}
				setComparisonIsEnable={handleSelectComparaisonPeriodHandler}
				comparisonSelected={ComparisonSelected}
			/>
		}
	</>
	)
}
