
import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './TableCatalogueContainer.css';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import styles from './Catalog.module.css';
import Link from '../../../../Components/Link';
import { ProductThumb } from '../../../../Components/Product/ProductThumb';

export default function TableCatalogueContainer({
	id,
	data,
	totalArticles
}) {

	const tooltipID = uuidv4();

	// const pageButtonRenderer = ({
	// 	page,
	// 	active,
	// 	disable,
	// 	title,
	// 	onPageChange,
	// }) => {
	// 	const handleClick = (e) => {
	// 		e.preventDefault();
	// 		onPageChange(page);
	// 	};
	// 	const activeStyle = {};
	// 	if (active) {
	// 		activeStyle.backgroundColor = '#006fff';
	// 		activeStyle.color = 'white';
	// 	} else {
	// 		activeStyle.backgroundColor = '#23527c';
	// 		activeStyle.color = 'white';
	// 	}
	// 	if (typeof page === 'string') {
	// 		activeStyle.backgroundColor = 'white';
	// 		activeStyle.color = 'black';
	// 	}
	// 	return (
	// 		<li className="page-item">
	// 			<a href="#" onClick={handleClick} style={activeStyle}>{page}</a>
	// 		</li>
	// 	);
	// };

	// const customTotal = (from, to, size) => (
	// 	<span className="react-bootstrap-table-pagination-total">
	// 		Showing { from} to { to} of { size} Results
	// 	</span>
	// );

	// const options = {
	// 	pageButtonRenderer,
	// 	paginationSize: 4,
	// 	pageStartIndex: 1,
	// 	// alwaysShowAllBtns: true, // Always show next and previous button
	// 	// withFirstAndLast: false, // Hide the going to First and Last page button
	// 	// hideSizePerPage: true, // Hide the sizePerPage dropdown always
	// 	// hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
	// 	firstPageText: 'First',
	// 	prePageText: 'Back',
	// 	nextPageText: 'Next',
	// 	lastPageText: 'Last',
	// 	nextPageTitle: 'First page',
	// 	prePageTitle: 'Pre page',
	// 	firstPageTitle: 'Next page',
	// 	lastPageTitle: 'Last page',
	// 	showTotal: false,
	// 	totalSize: 999,
	// 	paginationTotalRenderer: customTotal,
	// 	onSizePerPageChange: (sizePerPage, page) => {
	// 		setShowingArticles(sizePerPage);
	// 	},
	// 	// onPageChange: (page, sizePerPage) => {
	// 	//     console.log('Page change!!!');
	// 	//     console.log('Newest size per page:' + sizePerPage);
	// 	//     console.log('Newest page:' + page);
	// 	// },
	// 	disablePageTitle: true,
	// 	buttonsAlwaysShown: ['prev', 'next', 'first'],
	// 	sizePerPageList: [
	// 		{
	// 			text: '10', value: 10
	// 		},
	// 		{
	// 			text: '20', value: 20
	// 		},
	// 		{
	// 			text: '30', value: 30
	// 		},
	// 		{
	// 			text: '40', value: 40
	// 		},

	// 	] // A numeric array is also available. the purpose of above example is custom the text
	// };

	function urlThumbFormatter(cell, row) {
		return (
			<div className="container_image_product_feed">
				<img src={cell} alt="thumbnail" />
			</div>
		);
	}
	function urlFormatter(cell, row) {
		return (
			<p className="url_product_feed">
				<a href={cell} target="_blank" rel="noreferrer">
					<i className="fas fa-external-link-alt"></i>
				</a>
			</p>
		);
	}
	function catFormatter(cell, row) {
		return (
			<div className="categories_product_feed">
				<ul >
					{cell.map((elem, i) =>
						<li key={i}>{elem.type}={elem.name}</li>
					)}
				</ul>
			</div>

		);
	}
	function formatProductVariants(cell, row) {
		return (
			<div className="product_variante_product_feed">
				{cell.map((elem, i) =>
					<ul key={i}>
						<li>Stock Quantity : {elem.stockQuantity}</li>
						<li>Id : {elem.productVariantId}</li>
					</ul>)}
			</div>

		);
	}
	function tagFormatter(cell, row) {
		return (
			<div className="product_tag_product_feed">
				{cell.map((elem, i) =>
					<ul key={i}>
						<li>{elem.tag}={elem.value}</li>
					</ul>)}
			</div>

		);
	}
	function formatText(cell, row) {
		return (
			<div className="product_format_style">
				<p>{cell}</p>
			</div>

		);
	}


	const columns = [
		{
			dataField: 'id',
			text: 'Product ID',
			formatter: formatText,
		},
		{
			dataField: 'name',
			text: 'Product Name',
			formatter: formatText,
		},
		{
			dataField: 'urlThumbnail',
			text: 'Thumbnail',
			formatter: urlThumbFormatter,
			headerStyle: (colum, colIndex) => {
				return { width: '110px' };
			}
		},
		{
			dataField: 'url',
			text: 'Url',
			formatter: urlFormatter
		},
		{
			dataField: 'price',
			text: 'Price',
			headerStyle: (colum, colIndex) => {
				return { width: '70px' };
			}
		},
		{
			dataField: 'unitPriceWithRebate',
			text: 'Price rebate',
			headerStyle: (colum, colIndex) => {
				return { width: '100px' };
			}
		},
		{
			dataField: 'globalStockQuantity',
			text: 'Stock quantity',
			headerStyle: (colum, colIndex) => {
				return { width: '70px' };
			}
		},
		{
			dataField: 'categories',
			text: 'Categories',
			formatter: catFormatter,
			headerStyle: (colum, colIndex) => {
				return { width: '250px' };
			}
		},
		{
			dataField: 'tags',
			text: 'Tags TEST',
			formatter: tagFormatter,
			headerStyle: (colum, colIndex) => {
				return { width: '180px' };
			}
		},
		{
			dataField: 'productVariants',
			text: 'Product variants',
			formatter: formatProductVariants
		},
		{
			dataField: 'description',
			text: 'Description',
		},
	];

	return (
		<>
			<div className={styles.table}>
				<div className="table_flex">
					<div className='table_row table_head_row'>
						<div className='table_col'>Product</div>
						<div className='table_col'>Price</div>
						<div className='table_col'>Price rebate</div>
						<div className='table_col'>Stock</div>
						<div className='table_col'>Categories</div>
						<div className='table_col'>Tags</div>
						<div className='table_col'>Variants</div>
						<div className='table_col'>Description</div>
					</div>
					{data.map((prd, i) => 
						<div key={i + '_' + prd.id} className='table_row table_body_row'>
							<div className='table_col'>
								<ProductThumb
									width={60}
									height={'auto'}
									url={prd.urlThumbnail}
									alt={prd.id}
								/>
								<div className={styles.product_main_data}>
									<div className={styles.product_id}>{prd.id}</div>
									<div className={styles.product_name}>
										{prd.url ?
											<Link target="_blank" href={prd.url}>
												{prd.name}
											</Link>
											:
											<>{prd.name}</>
										}
									</div>
								</div>
							</div>
							<div className='table_col'>{prd.price}</div>
							<div className='table_col'>{prd.unitPriceWithRebate}</div>
							<div className='table_col'>
								{typeof prd.globalStockQuantity == 'number' ? prd.globalStockQuantity : '-'}
							</div>
							<div className='table_col'>
								{prd.categories.length > 0 ?
									<div className={styles.product_category_list}>
										{prd.categories.map((cat, j) =>
											<div key={j}>
												<div
													className={styles.product_category}
													data-for={tooltipID}
													data-tip={cat.type + '=' + cat.name}>
													{cat.type}={cat.name}
												</div>
											</div>
										)}
									</div>
									:
									<>-</>
								}
							</div>
							<div className='table_col'>
								{prd.tags.length > 0 ?
									<div className={styles.product_category_list}>
										{prd.tags.map((tag, j) =>
											<div key={j}>
												<div
													className={styles.product_category}
													data-for={tooltipID}
													data-tip={tag.tag + '=' + tag.value}>
													{tag.tag}={tag.value}
												</div>
											</div>
										)}
									</div>
									:
									<>-</>
								}
							</div>
							<div className='table_col'>
								{prd.productVariants.length > 0 ?
									<div>
										{prd.productVariants.map((elem, j) =>
											<div key={j}>
												{elem.productVariantId}&nbsp;
												<span className='s_12 grey_2'>(Stock: {elem.stockQuantity})</span>
											</div>
										)}
									</div>
									:
									<>-</>
								}
							</div>
							<div className='table_col'>{prd.description || '-'}</div>
						</div>
					)}
				</div>
			</div>
			<ReactTooltip
				id={tooltipID}
				backgroundColor="black"
				effect="solid"
				place="bottom"
				globalEventOff="click"
				delayShow={100}
			/>
			{/* <div className="table_product_feed">
				<BootstrapTable
					keyField='id'
					data={data}
					columns={columns}
					// pagination={paginationFactory(options)}
				/>
			</div> */}
		</>
	);
}