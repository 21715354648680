import React from 'react';
import ReactDOMServer from 'react-dom/server';
import Icon from '../Components/Icon';

export const findIconByName = (name) => {
	return iconList.find(i => i.name === name);
};

export const findIconsByTags = (tags) => {
	if (!tags || tags.length === 0) return iconList;
	return iconList.filter(i => {
		if (!i.tags || i.tags.length === 0) return false;
		const commonTag = tags.find(t => i.tags.includes(t));
		return !!commonTag;
	});
};

export const createIconString = (icon, className) => {
	const newIcon = <Icon name={icon.name} className={className}>{icon.jsx}</Icon>;
	return ReactDOMServer.renderToStaticMarkup(newIcon);
};

export function getFinalIconValue(p) {
	const value = p.Value;
	let newValue = value;

	if (!value) {
		newValue = createIconString(iconList[0], p.Editor.ClassName);
	}

	if (value && !value.includes('<svg')) {
		const tmpIcon = findIconByName(value);
		if (tmpIcon) {
			newValue = createIconString(tmpIcon, p.Editor.ClassName);
		} else {
			newValue = createIconString(iconList[0], p.Editor.ClassName);
		}
	}

	return newValue;
}

export const iconList = [
	{
		name: 'bell_1_regular',
		tags: ['notification'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M96,192a3,3,0,0,0,6,0" transform="translate(-87 -174)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M41.544,38.75a6.75,6.75,0,0,1,13.5,0,16.179,16.179,0,0,0,1.4,7.125A.75.75,0,0,1,55.794,47h-15a.75.75,0,0,1-.645-1.125A16.2,16.2,0,0,0,41.544,38.75Z" transform="translate(-36.294 -29)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			</>
		)
	},
	{
		name: 'bell_2_regular',
		tags: ['notification'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M96,192a3,3,0,0,0,6,0" transform="translate(-87 -174)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M184,24a9.629,9.629,0,0,1,3.4,3.75" transform="translate(-166.75 -21.75)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M35.71,27.75a9.629,9.629,0,0,1,3.4-3.75" transform="translate(-32.362 -21.75)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M41.544,46.75a6.75,6.75,0,1,1,13.5,0,12.444,12.444,0,0,0,1.4,6.375.75.75,0,0,1-.647,1.125h-15a.75.75,0,0,1-.645-1.125A12.462,12.462,0,0,0,41.544,46.75Z" transform="translate(-36.294 -36.25)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			</>
		)
	},
	{
		name: 'bell_3_regular',
		tags: ['notification'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M2.53,14.394a2.6,2.6,0,0,0,1.9,2.76,21.951,21.951,0,0,0,15.136,0,2.6,2.6,0,0,0,1.9-2.76,5.709,5.709,0,0,0-1.256-2.2,4.769,4.769,0,0,1-.689-2.915A7.405,7.405,0,0,0,12,2,7.405,7.405,0,0,0,4.475,9.279a4.769,4.769,0,0,1-.69,2.915A5.709,5.709,0,0,0,2.53,14.394Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M9,21a5,5,0,0,0,6,0" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			</>
		)
	},
	{
		name: 'bell_4_regular',
		tags: ['notification'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M8.046,0a6,6,0,0,0-6,6V8.89a4.778,4.778,0,0,1-.57,2.06L.326,12.86a1.919,1.919,0,0,0,1.08,2.93,20.921,20.921,0,0,0,13.27,0,2,2,0,0,0,1.08-2.93l-1.15-1.91a4.91,4.91,0,0,1-.56-2.06V6A6.018,6.018,0,0,0,8.046,0Z" transform="translate(3.974 2.91)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1.5"/>
				<path d="M3.7,1.26a6.054,6.054,0,0,0-.96-.2A6.754,6.754,0,0,0,0,1.26a1.988,1.988,0,0,1,3.7,0Z" transform="translate(10.17 1.94)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M6,0A3.009,3.009,0,0,1,3,3,3.011,3.011,0,0,1,.88,2.12,3.011,3.011,0,0,1,0,0" transform="translate(9.02 19.06)" fill="none" stroke="currentColor" strokeWidth="1.5"/>
			</>
		)
	},
	{
		name: 'inbox_1_regular',
		tags: ['notification'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M7,20h6c5,0,7-2,7-7V7c0-5-2-7-7-7H7C2,0,0,2,0,7v6C0,18,2,20,7,20Z" transform="translate(2 2)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M0,.01H3.76A2,2,0,0,1,5.55,1.12l.89,1.79a1.919,1.919,0,0,0,1.8,1.1h3.53A2,2,0,0,0,13.56,2.9l.89-1.79A2,2,0,0,1,16.24,0h3.74" transform="translate(2 12.99)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			</>
		)
	},
	{
		name: 'mail_1_regular',
		tags: ['notification'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M15,17H5c-3,0-5-1.5-5-5V5C0,1.5,2,0,5,0H15c3,0,5,1.5,5,5v7C20,15.5,18,17,15,17Z" transform="translate(2 3.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M13.735-3.969,6.87,2.5a3.166,3.166,0,0,1-3.75,0l-7-6.469" transform="translate(7 9)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			</>
		)
	},
	{
		name: 'mail_2_regular',
		tags: ['notification'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M15,17H5c-3,0-5-1.5-5-5V5C0,1.5,2,0,5,0H15c3,0,5,1.5,5,5v7C20,15.5,18,17,15,17Z" transform="translate(2 3.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M10,0,6.87,2.5a3.166,3.166,0,0,1-3.75,0L0,0" transform="translate(7 9)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			</>
		)
	},
	{
		name: 'discuss_1_regular',
		tags: ['notification', 'survey'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M14,0H6Q0,0,0,6V19a1,1,0,0,0,1,1H14q6,0,6-6V6Q20,0,14,0Z" transform="translate(2 2)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<g transform="translate(7 9.5)">
					<path d="M0,0H10" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
					<path d="M0,0H7" transform="translate(0 5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				</g>
			</>
		)
	},
	{
		name: 'discuss_2_regular',
		tags: ['notification', 'survey'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M6.5,17H6c-4,0-6-1-6-6V6Q0,0,6,0h8q6,0,6,6v5q0,6-6,6h-.5a1.014,1.014,0,0,0-.8.4l-1.5,2a1.421,1.421,0,0,1-2.4,0l-1.5-2A1.13,1.13,0,0,0,6.5,17Z" transform="translate(2 2)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M.495.5H.5" transform="translate(15.502 10.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M.495.5H.5" transform="translate(11.501 10.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M.495.5H.5" transform="translate(7.5 10.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'discuss_3_regular',
		tags: ['notification', 'survey'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M0,0H7" transform="translate(8.5 10.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M5,16H9l4.45,2.96A1,1,0,0,0,15,18.13V16a4.724,4.724,0,0,0,5-5V5a4.724,4.724,0,0,0-5-5H5A4.724,4.724,0,0,0,0,5v6A4.724,4.724,0,0,0,5,16Z" transform="translate(2 2.43)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			</>
		)
	},
	{
		name: 'smile_1_regular',
		tags: ['survey'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M11.5,20.5a9,9,0,1,0-9-9A9,9,0,0,0,11.5,20.5Z" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M7.375,9.75A1.125,1.125,0,1,0,6.25,8.625,1.125,1.125,0,0,0,7.375,9.75Z" transform="translate(1.25 1.5)" fill="currentColor"/>
				<path d="M13,9.75a1.125,1.125,0,1,0-1.125-1.125A1.125,1.125,0,0,0,13,9.75Z" transform="translate(2.375 1.5)" fill="currentColor"/>
				<path d="M14.375,11.875a4.25,4.25,0,0,1-7.5,0" transform="translate(1.375 2.375)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			</>
		)
	},
	{
		name: 'smile_2_regular',
		tags: ['survey'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M11.5,20.5a9,9,0,1,0-9-9A9,9,0,0,0,11.5,20.5Z" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M7.375,9.75A1.125,1.125,0,1,0,6.25,8.625,1.125,1.125,0,0,0,7.375,9.75Z" transform="translate(1.25 1.5)" fill="currentColor"/>
				<path d="M11.875,8.438h2.25" transform="translate(2.375 1.688)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M14.375,11.875a4.25,4.25,0,0,1-7.5,0" transform="translate(1.375 2.375)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			</>
		)
	},
	{
		name: 'smile_3_regular',
		tags: ['survey'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M14.375,11.875a4.25,4.25,0,0,1-7.5,0" transform="translate(1.5 2.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M14.143,20.1a8.991,8.991,0,1,1,5.96-5.961Z" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M7.375,9.75A1.125,1.125,0,1,0,6.25,8.625,1.125,1.125,0,0,0,7.375,9.75Z" transform="translate(1.5 1.5)" fill="currentColor"/>
				<path d="M13,9.75a1.125,1.125,0,1,0-1.125-1.125A1.125,1.125,0,0,0,13,9.75Z" transform="translate(2.5 1.5)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'thumb_1_regular',
		tags: ['survey'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M3,10.849A1.949,1.949,0,0,1,4.949,8.9a2.924,2.924,0,0,1,2.924,2.924v3.9a2.924,2.924,0,0,1-2.924,2.924A1.949,1.949,0,0,1,3,16.7Z" transform="translate(-0.746 1.578)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
				<path d="M14.763,8.2l-.26.838c-.213.687-.319,1.03-.237,1.3a.958.958,0,0,0,.409.533,2.812,2.812,0,0,0,1.355.154h.394c2.5,0,3.757,0,4.349.741a1.912,1.912,0,0,1,.18.269c.455.827-.062,1.941-1.1,4.169-.95,2.044-1.424,3.067-2.306,3.668q-.128.087-.263.165c-.928.532-2.078.532-4.378.532h-.5c-2.786,0-4.179,0-5.045-.839S6.5,17.541,6.5,14.841v-.949a7.836,7.836,0,0,1,.252-2.777,8.086,8.086,0,0,1,1.7-2.251l3.988-4.415a2.675,2.675,0,0,1,.194-.2,1.007,1.007,0,0,1,1.406.089,2.656,2.656,0,0,1,.165.227c.132.192.2.287.255.382a3.716,3.716,0,0,1,.434,2.816C14.866,7.866,14.832,7.976,14.763,8.2Z" transform="translate(0.627 -0.344)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			</>
		)
	},
	{
		name: 'star_1_regular',
		tags: ['survey'],
		weight: 'Regular',
		jsx: (
			<>
				<path d="M11.625,17.352l5.13,3.155a.787.787,0,0,0,1.174-.86l-1.4-5.886L21.1,9.823a.793.793,0,0,0-.449-1.386L14.659,7.95,12.35,2.362a.784.784,0,0,0-1.451,0L8.591,7.95,2.6,8.437a.793.793,0,0,0-.449,1.39l4.566,3.938L5.321,19.647a.787.787,0,0,0,1.174.86Z" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			</>
		)
	},
	{
		name: 'bell_1_light',
		tags: ['notification'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M96,192a3,3,0,0,0,6,0" transform="translate(-87 -174)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M41.544,38.75a6.75,6.75,0,0,1,13.5,0,16.179,16.179,0,0,0,1.4,7.125A.75.75,0,0,1,55.794,47h-15a.75.75,0,0,1-.645-1.125A16.2,16.2,0,0,0,41.544,38.75Z" transform="translate(-36.294 -29)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
			</>
		)
	},
	{
		name: 'bell_2_light',
		tags: ['notification'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M96,192a3,3,0,0,0,6,0" transform="translate(-87 -174)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M184,24a9.629,9.629,0,0,1,3.4,3.75" transform="translate(-166.75 -21.75)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M35.71,27.75a9.629,9.629,0,0,1,3.4-3.75" transform="translate(-32.362 -21.75)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M41.544,46.75a6.75,6.75,0,1,1,13.5,0,12.444,12.444,0,0,0,1.4,6.375.75.75,0,0,1-.647,1.125h-15a.75.75,0,0,1-.645-1.125A12.462,12.462,0,0,0,41.544,46.75Z" transform="translate(-36.294 -36.25)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
			</>
		)
	},
	{
		name: 'bell_3_light',
		tags: ['notification'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M2.53,14.394a2.6,2.6,0,0,0,1.9,2.76,21.951,21.951,0,0,0,15.136,0,2.6,2.6,0,0,0,1.9-2.76,5.709,5.709,0,0,0-1.256-2.2,4.769,4.769,0,0,1-.689-2.915A7.405,7.405,0,0,0,12,2,7.405,7.405,0,0,0,4.475,9.279a4.769,4.769,0,0,1-.69,2.915A5.709,5.709,0,0,0,2.53,14.394Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M9,21a5,5,0,0,0,6,0" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
			</>
		)
	},
	{
		name: 'bell_4_light',
		tags: ['notification'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M8.046,0a6,6,0,0,0-6,6V8.89a4.778,4.778,0,0,1-.57,2.06L.326,12.86a1.919,1.919,0,0,0,1.08,2.93,20.921,20.921,0,0,0,13.27,0,2,2,0,0,0,1.08-2.93l-1.15-1.91a4.91,4.91,0,0,1-.56-2.06V6A6.018,6.018,0,0,0,8.046,0Z" transform="translate(3.974 2.91)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1"/>
				<path d="M3.7,1.26a6.054,6.054,0,0,0-.96-.2A6.754,6.754,0,0,0,0,1.26a1.988,1.988,0,0,1,3.7,0Z" transform="translate(10.17 1.94)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M6,0A3.009,3.009,0,0,1,3,3,3.011,3.011,0,0,1,.88,2.12,3.011,3.011,0,0,1,0,0" transform="translate(9.02 19.06)" fill="none" stroke="currentColor" strokeWidth="1"/>
			</>
		)
	},
	{
		name: 'inbox_1_light',
		tags: ['notification'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M7,20h6c5,0,7-2,7-7V7c0-5-2-7-7-7H7C2,0,0,2,0,7v6C0,18,2,20,7,20Z" transform="translate(2 2)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M0,.01H3.76A2,2,0,0,1,5.55,1.12l.89,1.79a1.919,1.919,0,0,0,1.8,1.1h3.53A2,2,0,0,0,13.56,2.9l.89-1.79A2,2,0,0,1,16.24,0h3.74" transform="translate(2 12.99)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
			</>
		)
	},
	{
		name: 'mail_1_light',
		tags: ['notification'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M15,17H5c-3,0-5-1.5-5-5V5C0,1.5,2,0,5,0H15c3,0,5,1.5,5,5v7C20,15.5,18,17,15,17Z" transform="translate(2 3.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M13.735-3.969,6.87,2.5a3.166,3.166,0,0,1-3.75,0l-7-6.469" transform="translate(7 9)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
			</>
		)
	},
	{
		name: 'mail_2_light',
		tags: ['notification'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M15,17H5c-3,0-5-1.5-5-5V5C0,1.5,2,0,5,0H15c3,0,5,1.5,5,5v7C20,15.5,18,17,15,17Z" transform="translate(2 3.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M10,0,6.87,2.5a3.166,3.166,0,0,1-3.75,0L0,0" transform="translate(7 9)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
			</>
		)
	},
	{
		name: 'discuss_1_light',
		tags: ['notification', 'survey'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M14,0H6Q0,0,0,6V19a1,1,0,0,0,1,1H14q6,0,6-6V6Q20,0,14,0Z" transform="translate(2 2)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<g transform="translate(7 9.5)">
					<path d="M0,0H10" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
					<path d="M0,0H7" transform="translate(0 5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				</g>
			</>
		)
	},
	{
		name: 'discuss_2_light',
		tags: ['notification', 'survey'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M6.5,17H6c-4,0-6-1-6-6V6Q0,0,6,0h8q6,0,6,6v5q0,6-6,6h-.5a1.014,1.014,0,0,0-.8.4l-1.5,2a1.421,1.421,0,0,1-2.4,0l-1.5-2A1.13,1.13,0,0,0,6.5,17Z" transform="translate(2 2)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M.495.5H.5" transform="translate(15.502 10.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M.495.5H.5" transform="translate(11.501 10.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M.495.5H.5" transform="translate(7.5 10.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'discuss_3_light',
		tags: ['notification', 'survey'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M0,0H7" transform="translate(8.5 10.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M5,16H9l4.45,2.96A1,1,0,0,0,15,18.13V16a4.724,4.724,0,0,0,5-5V5a4.724,4.724,0,0,0-5-5H5A4.724,4.724,0,0,0,0,5v6A4.724,4.724,0,0,0,5,16Z" transform="translate(2 2.43)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
			</>
		)
	},
	{
		name: 'smile_1_light',
		tags: ['survey'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M11.5,20.5a9,9,0,1,0-9-9A9,9,0,0,0,11.5,20.5Z" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M7.375,9.75A1.125,1.125,0,1,0,6.25,8.625,1.125,1.125,0,0,0,7.375,9.75Z" transform="translate(1.25 1.5)" fill="currentColor"/>
				<path d="M13,9.75a1.125,1.125,0,1,0-1.125-1.125A1.125,1.125,0,0,0,13,9.75Z" transform="translate(2.375 1.5)" fill="currentColor"/>
				<path d="M14.375,11.875a4.25,4.25,0,0,1-7.5,0" transform="translate(1.375 2.375)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
			</>
		)
	},
	{
		name: 'smile_2_light',
		tags: ['survey'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M11.5,20.5a9,9,0,1,0-9-9A9,9,0,0,0,11.5,20.5Z" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M7.375,9.75A1.125,1.125,0,1,0,6.25,8.625,1.125,1.125,0,0,0,7.375,9.75Z" transform="translate(1.25 1.5)" fill="currentColor"/>
				<path d="M11.875,8.438h2.25" transform="translate(2.375 1.688)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M14.375,11.875a4.25,4.25,0,0,1-7.5,0" transform="translate(1.375 2.375)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
			</>
		)
	},
	{
		name: 'smile_3_light',
		tags: ['survey'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M14.375,11.875a4.25,4.25,0,0,1-7.5,0" transform="translate(1.5 2.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M14.143,20.1a8.991,8.991,0,1,1,5.96-5.961Z" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M7.375,9.75A1.125,1.125,0,1,0,6.25,8.625,1.125,1.125,0,0,0,7.375,9.75Z" transform="translate(1.5 1.5)" fill="currentColor"/>
				<path d="M13,9.75a1.125,1.125,0,1,0-1.125-1.125A1.125,1.125,0,0,0,13,9.75Z" transform="translate(2.5 1.5)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'thumb_1_light',
		tags: ['survey'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M3,10.849A1.949,1.949,0,0,1,4.949,8.9a2.924,2.924,0,0,1,2.924,2.924v3.9a2.924,2.924,0,0,1-2.924,2.924A1.949,1.949,0,0,1,3,16.7Z" transform="translate(-0.746 1.578)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
				<path d="M14.763,8.2l-.26.838c-.213.687-.319,1.03-.237,1.3a.958.958,0,0,0,.409.533,2.812,2.812,0,0,0,1.355.154h.394c2.5,0,3.757,0,4.349.741a1.912,1.912,0,0,1,.18.269c.455.827-.062,1.941-1.1,4.169-.95,2.044-1.424,3.067-2.306,3.668q-.128.087-.263.165c-.928.532-2.078.532-4.378.532h-.5c-2.786,0-4.179,0-5.045-.839S6.5,17.541,6.5,14.841v-.949a7.836,7.836,0,0,1,.252-2.777,8.086,8.086,0,0,1,1.7-2.251l3.988-4.415a2.675,2.675,0,0,1,.194-.2,1.007,1.007,0,0,1,1.406.089,2.656,2.656,0,0,1,.165.227c.132.192.2.287.255.382a3.716,3.716,0,0,1,.434,2.816C14.866,7.866,14.832,7.976,14.763,8.2Z" transform="translate(0.627 -0.344)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
			</>
		)
	},
	{
		name: 'star_1_light',
		tags: ['survey'],
		weight: 'Light',
		jsx: (
			<>
				<path d="M11.625,17.352l5.13,3.155a.787.787,0,0,0,1.174-.86l-1.4-5.886L21.1,9.823a.793.793,0,0,0-.449-1.386L14.659,7.95,12.35,2.362a.784.784,0,0,0-1.451,0L8.591,7.95,2.6,8.437a.793.793,0,0,0-.449,1.39l4.566,3.938L5.321,19.647a.787.787,0,0,0,1.174.86Z" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
			</>
		)
	},
	{
		name: 'bell_1_bold',
		tags: ['notification'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M96,192a3,3,0,0,0,6,0" transform="translate(-87 -174)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M41.544,38.75a6.75,6.75,0,0,1,13.5,0,16.179,16.179,0,0,0,1.4,7.125A.75.75,0,0,1,55.794,47h-15a.75.75,0,0,1-.645-1.125A16.2,16.2,0,0,0,41.544,38.75Z" transform="translate(-36.294 -29)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'bell_2_bold',
		tags: ['notification'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M96,192a3,3,0,0,0,6,0" transform="translate(-87 -174)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M184,24a9.629,9.629,0,0,1,3.4,3.75" transform="translate(-166.75 -21.75)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M35.71,27.75a9.629,9.629,0,0,1,3.4-3.75" transform="translate(-32.362 -21.75)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M41.544,46.75a6.75,6.75,0,1,1,13.5,0,12.444,12.444,0,0,0,1.4,6.375.75.75,0,0,1-.647,1.125h-15a.75.75,0,0,1-.645-1.125A12.462,12.462,0,0,0,41.544,46.75Z" transform="translate(-36.294 -36.25)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'bell_3_bold',
		tags: ['notification'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M2.53,14.394a2.6,2.6,0,0,0,1.9,2.76,21.951,21.951,0,0,0,15.136,0,2.6,2.6,0,0,0,1.9-2.76,5.709,5.709,0,0,0-1.256-2.2,4.769,4.769,0,0,1-.689-2.915A7.405,7.405,0,0,0,12,2,7.405,7.405,0,0,0,4.475,9.279a4.769,4.769,0,0,1-.69,2.915A5.709,5.709,0,0,0,2.53,14.394Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M9,21a5,5,0,0,0,6,0" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'bell_4_bold',
		tags: ['notification'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M8.046,0a6,6,0,0,0-6,6V8.89a4.778,4.778,0,0,1-.57,2.06L.326,12.86a1.919,1.919,0,0,0,1.08,2.93,20.921,20.921,0,0,0,13.27,0,2,2,0,0,0,1.08-2.93l-1.15-1.91a4.91,4.91,0,0,1-.56-2.06V6A6.018,6.018,0,0,0,8.046,0Z" transform="translate(3.974 2.91)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2"/>
				<path d="M3.7,1.26a6.054,6.054,0,0,0-.96-.2A6.754,6.754,0,0,0,0,1.26a1.988,1.988,0,0,1,3.7,0Z" transform="translate(10.17 1.94)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M6,0A3.009,3.009,0,0,1,3,3,3.011,3.011,0,0,1,.88,2.12,3.011,3.011,0,0,1,0,0" transform="translate(9.02 19.06)" fill="none" stroke="currentColor" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'inbox_1_bold',
		tags: ['notification'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M7,20h6c5,0,7-2,7-7V7c0-5-2-7-7-7H7C2,0,0,2,0,7v6C0,18,2,20,7,20Z" transform="translate(2 2)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M0,.01H3.76A2,2,0,0,1,5.55,1.12l.89,1.79a1.919,1.919,0,0,0,1.8,1.1h3.53A2,2,0,0,0,13.56,2.9l.89-1.79A2,2,0,0,1,16.24,0h3.74" transform="translate(2 12.99)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'mail_1_bold',
		tags: ['notification'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M15,17H5c-3,0-5-1.5-5-5V5C0,1.5,2,0,5,0H15c3,0,5,1.5,5,5v7C20,15.5,18,17,15,17Z" transform="translate(2 3.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M13.735-3.969,6.87,2.5a3.166,3.166,0,0,1-3.75,0l-7-6.469" transform="translate(7 9)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'mail_2_bold',
		tags: ['notification'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M15,17H5c-3,0-5-1.5-5-5V5C0,1.5,2,0,5,0H15c3,0,5,1.5,5,5v7C20,15.5,18,17,15,17Z" transform="translate(2 3.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M10,0,6.87,2.5a3.166,3.166,0,0,1-3.75,0L0,0" transform="translate(7 9)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'discuss_1_bold',
		tags: ['notification', 'survey'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M14,0H6Q0,0,0,6V19a1,1,0,0,0,1,1H14q6,0,6-6V6Q20,0,14,0Z" transform="translate(2 2)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<g transform="translate(7 9.5)">
					<path d="M0,0H10" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
					<path d="M0,0H7" transform="translate(0 5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				</g>
			</>
		)
	},
	{
		name: 'discuss_2_bold',
		tags: ['notification', 'survey'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M6.5,17H6c-4,0-6-1-6-6V6Q0,0,6,0h8q6,0,6,6v5q0,6-6,6h-.5a1.014,1.014,0,0,0-.8.4l-1.5,2a1.421,1.421,0,0,1-2.4,0l-1.5-2A1.13,1.13,0,0,0,6.5,17Z" transform="translate(2 2)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M.495.5H.5" transform="translate(15.502 10.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M.495.5H.5" transform="translate(11.501 10.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M.495.5H.5" transform="translate(7.5 10.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'discuss_3_bold',
		tags: ['notification', 'survey'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M0,0H7" transform="translate(8.5 10.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M5,16H9l4.45,2.96A1,1,0,0,0,15,18.13V16a4.724,4.724,0,0,0,5-5V5a4.724,4.724,0,0,0-5-5H5A4.724,4.724,0,0,0,0,5v6A4.724,4.724,0,0,0,5,16Z" transform="translate(2 2.43)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'smile_1_bold',
		tags: ['survey'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M11.5,20.5a9,9,0,1,0-9-9A9,9,0,0,0,11.5,20.5Z" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M7.375,9.75A1.125,1.125,0,1,0,6.25,8.625,1.125,1.125,0,0,0,7.375,9.75Z" transform="translate(1.25 1.5)" fill="currentColor"/>
				<path d="M13,9.75a1.125,1.125,0,1,0-1.125-1.125A1.125,1.125,0,0,0,13,9.75Z" transform="translate(2.375 1.5)" fill="currentColor"/>
				<path d="M14.375,11.875a4.25,4.25,0,0,1-7.5,0" transform="translate(1.375 2.375)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'smile_2_bold',
		tags: ['survey'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M11.5,20.5a9,9,0,1,0-9-9A9,9,0,0,0,11.5,20.5Z" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M7.375,9.75A1.125,1.125,0,1,0,6.25,8.625,1.125,1.125,0,0,0,7.375,9.75Z" transform="translate(1.25 1.5)" fill="currentColor"/>
				<path d="M11.875,8.438h2.25" transform="translate(2.375 1.688)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M14.375,11.875a4.25,4.25,0,0,1-7.5,0" transform="translate(1.375 2.375)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'smile_3_bold',
		tags: ['survey'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M14.375,11.875a4.25,4.25,0,0,1-7.5,0" transform="translate(1.5 2.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M14.143,20.1a8.991,8.991,0,1,1,5.96-5.961Z" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M7.375,9.75A1.125,1.125,0,1,0,6.25,8.625,1.125,1.125,0,0,0,7.375,9.75Z" transform="translate(1.5 1.5)" fill="currentColor"/>
				<path d="M13,9.75a1.125,1.125,0,1,0-1.125-1.125A1.125,1.125,0,0,0,13,9.75Z" transform="translate(2.5 1.5)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'thumb_1_bold',
		tags: ['survey'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M3,10.849A1.949,1.949,0,0,1,4.949,8.9a2.924,2.924,0,0,1,2.924,2.924v3.9a2.924,2.924,0,0,1-2.924,2.924A1.949,1.949,0,0,1,3,16.7Z" transform="translate(-0.746 1.578)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
				<path d="M14.763,8.2l-.26.838c-.213.687-.319,1.03-.237,1.3a.958.958,0,0,0,.409.533,2.812,2.812,0,0,0,1.355.154h.394c2.5,0,3.757,0,4.349.741a1.912,1.912,0,0,1,.18.269c.455.827-.062,1.941-1.1,4.169-.95,2.044-1.424,3.067-2.306,3.668q-.128.087-.263.165c-.928.532-2.078.532-4.378.532h-.5c-2.786,0-4.179,0-5.045-.839S6.5,17.541,6.5,14.841v-.949a7.836,7.836,0,0,1,.252-2.777,8.086,8.086,0,0,1,1.7-2.251l3.988-4.415a2.675,2.675,0,0,1,.194-.2,1.007,1.007,0,0,1,1.406.089,2.656,2.656,0,0,1,.165.227c.132.192.2.287.255.382a3.716,3.716,0,0,1,.434,2.816C14.866,7.866,14.832,7.976,14.763,8.2Z" transform="translate(0.627 -0.344)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'star_1_bold',
		tags: ['survey'],
		weight: 'Bold',
		jsx: (
			<>
				<path d="M11.625,17.352l5.13,3.155a.787.787,0,0,0,1.174-.86l-1.4-5.886L21.1,9.823a.793.793,0,0,0-.449-1.386L14.659,7.95,12.35,2.362a.784.784,0,0,0-1.451,0L8.591,7.95,2.6,8.437a.793.793,0,0,0-.449,1.39l4.566,3.938L5.321,19.647a.787.787,0,0,0,1.174.86Z" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
			</>
		)
	},
	{
		name: 'bell_1_fill',
		tags: ['notification'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M49.8,38.244A15.472,15.472,0,0,1,48.511,31.5a7.5,7.5,0,1,0-15,0,15.464,15.464,0,0,1-1.295,6.744A1.5,1.5,0,0,0,33.511,40.5h3.826a3.75,3.75,0,0,0,7.348,0h3.826A1.5,1.5,0,0,0,49.8,38.244ZM41.011,42a2.25,2.25,0,0,1-2.121-1.5h4.241A2.25,2.25,0,0,1,41.011,42Z" transform="translate(-29.011 -21.75)" fill="currentColor" />
			</>
		)
	},
	{
		name: 'bell_2_fill',
		tags: ['notification'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M46.115,21.161A.75.75,0,0,1,45.1,20.84a8.825,8.825,0,0,0-3.137-3.46.75.75,0,0,1,.8-1.269,10.449,10.449,0,0,1,3.668,4.04A.75.75,0,0,1,46.115,21.161Zm-17.652.084a.75.75,0,0,0,.666-.405,8.825,8.825,0,0,1,3.137-3.46.75.75,0,0,0-.8-1.269A10.449,10.449,0,0,0,27.8,20.15a.75.75,0,0,0,.666,1.1ZM45.91,30.99a1.5,1.5,0,0,1-1.295,2.256H40.79a3.75,3.75,0,0,1-7.35,0H29.615a1.5,1.5,0,0,1-1.293-2.256A12.16,12.16,0,0,0,29.615,25a7.5,7.5,0,1,1,15,0A12.143,12.143,0,0,0,45.91,30.99Zm-6.674,2.256H34.995a2.25,2.25,0,0,0,4.241,0Z" fill="currentColor" transform="translate(-25.115 -14.495)"/>
			</>
		)
	},
	{
		name: 'bell_3_fill',
		tags: ['notification'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M2.53,14.394a2.6,2.6,0,0,0,1.9,2.76,21.951,21.951,0,0,0,15.136,0,2.6,2.6,0,0,0,1.9-2.76,5.709,5.709,0,0,0-1.256-2.2,4.769,4.769,0,0,1-.689-2.915A7.405,7.405,0,0,0,12,2,7.405,7.405,0,0,0,4.475,9.279a4.769,4.769,0,0,1-.69,2.915A5.709,5.709,0,0,0,2.53,14.394Z" fill="currentColor"/>
				<path d="M12,22.75a5.636,5.636,0,0,1-3.462-1.159.75.75,0,1,1,.923-1.182,4.251,4.251,0,0,0,5.077,0,.75.75,0,0,1,.923,1.182A5.636,5.636,0,0,1,12,22.75Z" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'bell_4_fill',
		tags: ['notification'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M15.029,12.49l-1-1.66a3.715,3.715,0,0,1-.4-1.48V6.82a5.909,5.909,0,0,0-3.37-5.33A2.926,2.926,0,0,0,7.679,0a2.961,2.961,0,0,0-2.59,1.52,5.928,5.928,0,0,0-3.3,5.3V9.35a3.626,3.626,0,0,1-.4,1.47L.379,12.49a2.337,2.337,0,0,0,1.31,3.53,18.7,18.7,0,0,0,6.02.98,18.969,18.969,0,0,0,6.02-.97,2.411,2.411,0,0,0,1.3-3.54Z" transform="translate(4.311 2)" fill="currentColor"/>
				<path d="M5.65.01A3.014,3.014,0,0,1,2.82,2,2.965,2.965,0,0,1,.7,1.11,2.761,2.761,0,0,1,0,0C.13.02.26.03.4.05c.23.03.47.06.71.08.57.05,1.15.08,1.73.08S3.98.18,4.54.13c.21-.02.42-.03.62-.06Z" transform="translate(9.18 20)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'inbox_4_fill',
		tags: ['notification'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M19.3,0H15.82a2.538,2.538,0,0,0-2.29,1.42l-.84,1.66a1.162,1.162,0,0,1-1.04.65H8.37a1.066,1.066,0,0,1-1.04-.65L6.49,1.43A2.567,2.567,0,0,0,4.2.01H.7a.7.7,0,0,0-.7.7V3.97c0,3.63,2.18,5.8,5.82,5.8H14.2c3.43,0,5.54-1.88,5.8-5.22V.7A.7.7,0,0,0,19.3,0Z" transform="translate(2 12.23)" fill="currentColor"/>
				<path d="M20,5.81V8.85a2.06,2.06,0,0,0-.7-.12H15.82a4.035,4.035,0,0,0-3.63,2.24l-.75,1.48H8.58l-.75-1.47A4.026,4.026,0,0,0,4.2,8.73H.7a2.06,2.06,0,0,0-.7.12V5.81C0,2.17,2.17,0,5.81,0h8.38C17.83,0,20,2.17,20,5.81Z" transform="translate(2 2)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'mail_1_fill',
		tags: ['notification'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M15,0H5C2,0,0,1.5,0,5v7c0,3.5,2,5,5,5H15c3,0,5-1.5,5-5V5C20,1.5,18,0,15,0Zm2.47,4.09-5.13,4.5a3.861,3.861,0,0,1-4.68,0L2.53,4.09a.769.769,0,0,1-.12-1.06.748.748,0,0,1,1.05-.12l5.13,4.5a2.386,2.386,0,0,0,2.81,0l5.13-4.5a.738.738,0,0,1,1.05.12A.759.759,0,0,1,17.47,4.09Z" transform="translate(2 4)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'mail_2_fill',
		tags: ['notification'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M15,0H5C2,0,0,1.5,0,5v7c0,3.5,2,5,5,5H15c3,0,5-1.5,5-5V5C20,1.5,18,0,15,0Zm.47,6.09-3.13,2.5a3.861,3.861,0,0,1-4.68,0L4.53,6.09a.769.769,0,0,1-.12-1.06.748.748,0,0,1,1.05-.12l3.13,2.5a2.386,2.386,0,0,0,2.81,0l3.13-2.5a.738.738,0,0,1,1.05.12A.759.759,0,0,1,15.47,6.09Z" transform="translate(1 4)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'discuss_1_fill',
		tags: ['notification', 'survey'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M14,0H6Q0,0,0,6V19a1,1,0,0,0,1,1H14q6,0,6-6V6Q20,0,14,0ZM12,13.25H5a.75.75,0,0,1,0-1.5h7a.75.75,0,1,1,0,1.5Zm3-5H5a.75.75,0,1,1,0-1.5H15a.75.75,0,1,1,0,1.5Z" transform="translate(2 2)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'discuss_2_fill',
		tags: ['notification', 'survey'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M15,0H5A4.991,4.991,0,0,0,0,4.98v6.98a4.991,4.991,0,0,0,5,4.98H6.5a1.149,1.149,0,0,1,.8.4l1.5,1.99a1.421,1.421,0,0,0,2.4,0l1.5-1.99a1.014,1.014,0,0,1,.8-.4H15a4.991,4.991,0,0,0,5-4.98V4.98A4.991,4.991,0,0,0,15,0ZM6,10A1,1,0,1,1,7,9,1,1,0,0,1,6,10Zm4,0a1,1,0,1,1,1-1A1,1,0,0,1,10,10Zm4,0a1,1,0,1,1,1-1A1,1,0,0,1,14,10Z" transform="translate(2 2)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'discuss_3_fill',
		tags: ['notification', 'survey'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M15,0H5A4.724,4.724,0,0,0,0,5v6a4.724,4.724,0,0,0,5,5H9l4.45,2.96A1,1,0,0,0,15,18.13V16a4.724,4.724,0,0,0,5-5V5A4.724,4.724,0,0,0,15,0ZM13.5,8.82h-7a.75.75,0,0,1,0-1.5h7a.75.75,0,0,1,0,1.5Z" transform="translate(2 2.43)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'smile_1_fill',
		tags: ['survey'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M-1873,572a9.01,9.01,0,0,1-9-9,9.01,9.01,0,0,1,9-9,9.01,9.01,0,0,1,9,9A9.01,9.01,0,0,1-1873,572Zm-3.75-7.5a.753.753,0,0,0-.375.1.752.752,0,0,0-.274,1.025,5.013,5.013,0,0,0,4.4,2.624,5.011,5.011,0,0,0,4.4-2.624.746.746,0,0,0,.076-.57.744.744,0,0,0-.349-.455.75.75,0,0,0-.374-.1.752.752,0,0,0-.65.374,3.506,3.506,0,0,1-3.1,1.876,3.5,3.5,0,0,1-3.1-1.876A.753.753,0,0,0-1876.75,564.5Zm7.125-4.5a1.126,1.126,0,0,0-1.125,1.125,1.126,1.126,0,0,0,1.125,1.125,1.126,1.126,0,0,0,1.125-1.125A1.126,1.126,0,0,0-1869.625,560Zm-6.75,0a1.126,1.126,0,0,0-1.125,1.125,1.126,1.126,0,0,0,1.125,1.125,1.126,1.126,0,0,0,1.125-1.125A1.126,1.126,0,0,0-1876.375,560Z" transform="translate(1885 -551)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'smile_2_fill',
		tags: ['survey'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M-1907,572a9.01,9.01,0,0,1-9-9,9.01,9.01,0,0,1,9-9,9.01,9.01,0,0,1,9,9A9.01,9.01,0,0,1-1907,572Zm-3.75-7.5a.753.753,0,0,0-.375.1.752.752,0,0,0-.274,1.025,5.013,5.013,0,0,0,4.4,2.624,5.011,5.011,0,0,0,4.4-2.624.746.746,0,0,0,.076-.57.744.744,0,0,0-.349-.455.75.75,0,0,0-.375-.1.752.752,0,0,0-.65.374,3.506,3.506,0,0,1-3.1,1.876,3.5,3.5,0,0,1-3.1-1.876A.753.753,0,0,0-1910.751,564.5Zm.375-4.5a1.126,1.126,0,0,0-1.125,1.125,1.126,1.126,0,0,0,1.125,1.125,1.126,1.126,0,0,0,1.125-1.125A1.126,1.126,0,0,0-1910.375,560Zm5.625.375a.751.751,0,0,0-.75.75.751.751,0,0,0,.75.75h2.25a.751.751,0,0,0,.751-.75.751.751,0,0,0-.751-.75Z" transform="translate(1919 -551)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'smile_3_fill',
		tags: ['survey'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M-1939.994,572a8.953,8.953,0,0,1-3.216-.6,9.031,9.031,0,0,1-4.488-3.761,9.032,9.032,0,0,1-1.219-5.728,9.034,9.034,0,0,1,2.566-5.263,9.03,9.03,0,0,1,5.263-2.567,9.01,9.01,0,0,1,1.1-.067,8.99,8.99,0,0,1,4.632,1.286,9.026,9.026,0,0,1,3.762,4.487,9.03,9.03,0,0,1,.2,5.853l-5.959,5.96A8.982,8.982,0,0,1-1939.994,572Zm-3.761-7.5a.749.749,0,0,0-.374.1.751.751,0,0,0-.274,1.025,5.013,5.013,0,0,0,4.4,2.624,5.013,5.013,0,0,0,4.4-2.624.752.752,0,0,0-.274-1.025.752.752,0,0,0-.375-.1.754.754,0,0,0-.65.375,3.5,3.5,0,0,1-3.1,1.875,3.506,3.506,0,0,1-3.1-1.875A.752.752,0,0,0-1943.755,564.5Zm7.126-4.5a1.126,1.126,0,0,0-1.125,1.125,1.126,1.126,0,0,0,1.125,1.125,1.126,1.126,0,0,0,1.125-1.125A1.126,1.126,0,0,0-1936.628,560Zm-6.75,0a1.126,1.126,0,0,0-1.125,1.125,1.126,1.126,0,0,0,1.125,1.125,1.126,1.126,0,0,0,1.125-1.125A1.126,1.126,0,0,0-1943.378,560Z" transform="translate(1952 -551)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'thumb_1_fill',
		tags: ['survey'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M3,10.846A1.946,1.946,0,0,1,4.946,8.9a2.92,2.92,0,0,1,2.92,2.92v3.893a2.92,2.92,0,0,1-2.92,2.92A1.946,1.946,0,0,1,3,16.686Z" transform="translate(-1.811 1.58)" fill="currentColor"/>
				<path d="M14.751,8.191l-.259.837c-.212.686-.319,1.029-.237,1.3a.956.956,0,0,0,.409.532,2.808,2.808,0,0,0,1.353.154h.393c2.5,0,3.752,0,4.342.74a1.909,1.909,0,0,1,.179.269c.454.826-.062,1.938-1.1,4.163-.948,2.041-1.422,3.062-2.3,3.663q-.128.087-.262.164c-.927.531-2.075.531-4.371.531h-.5c-2.782,0-4.173,0-5.038-.838S6.5,17.521,6.5,14.826v-.947A7.825,7.825,0,0,1,6.751,11.1a8.075,8.075,0,0,1,1.7-2.248l3.982-4.409a2.672,2.672,0,0,1,.194-.2,1.006,1.006,0,0,1,1.4.089,2.651,2.651,0,0,1,.165.227c.132.191.2.287.255.382a3.71,3.71,0,0,1,.433,2.812C14.854,7.861,14.82,7.971,14.751,8.191Z" transform="translate(0.713 -0.332)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'star_1_fill',
		tags: ['survey'],
		weight: 'Fill',
		jsx: (
			<>
				<path d="M11.625,17.352l5.13,3.155a.787.787,0,0,0,1.174-.86l-1.4-5.886L21.1,9.823a.793.793,0,0,0-.449-1.386L14.659,7.95,12.35,2.362a.784.784,0,0,0-1.451,0L8.591,7.95,2.6,8.437a.793.793,0,0,0-.449,1.39l4.566,3.938L5.321,19.647a.787.787,0,0,0,1.174.86Z" transform="translate(0.5 0.5)" fill="currentColor"/>
			</>
		)
	},
];


