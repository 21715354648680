import React, { useState, Fragment } from 'react';
import Dropdown from '../../../../Components/Dropdown';
import Btn from '../../../../Components/Btn';
import { useTranslation } from 'react-i18next';

export default function PreviewLink({
	campaign,
	hasIcon = false,
	size = 'xs'
}) {

	const [t] = useTranslation('common');
	const [ddIsOpen, setDdIsOpen] = useState(false);

	return (
		<>
			{!campaign.campaignDebugLinks ||
				(campaign.campaignDebugLinks.length == 0 && (
					<Btn
						message={t('CampaignItem.Preview')}
						target="_blank"
						style="outline"
						color="secondary"
						size={size}
						disabled={true}
						icon={hasIcon ? 'far fa-eye' : ''}
					/>
				))}
			{campaign.campaignDebugLinks &&
				campaign.campaignDebugLinks.length == 1 && (
				<Btn
					message={t('CampaignItem.Preview')}
					href={campaign.campaignDebugLinks[0].debugLink}
					target="_blank"
					style="outline"
					color="secondary"
					size={size}
					icon={hasIcon ? 'far fa-eye' : ''}
				/>
			)}
			{campaign.campaignDebugLinks &&
				campaign.campaignDebugLinks.length > 1 && (
				<Dropdown
					isOpen={ddIsOpen}
					setIsOpen={() => setDdIsOpen(true)}
					onHide={() => setDdIsOpen(false)}
					button={
						<Btn
							style="outline"
							color="secondary"
							arrow="true"
							size={size}
							message={t('CampaignItem.Preview')}
							icon={hasIcon ? 'far fa-eye' : ''}
						/>
					}
				>
					<ul className="listbox">
						{campaign.campaignDebugLinks
							.sort((v1, v2) =>
								v1.variation.localeCompare(v2.variation)
							)
							.map((debug, i) => (
								<Fragment key={i}>
									<li>
										<a
											href={debug.debugLink}
											target="_blank"
											className="listbox_item" rel="noreferrer"
										>
											Variation{' '}
											{
												[
													'A',
													'B',
													'C',
													'D',
													'E',
													'F',
													'G',
													'H',
													'I',
													'J',
												][i]
											}
										</a>
									</li>
									<li className="hr"></li>
								</Fragment>
							))}
					</ul>
				</Dropdown>
			)}        
		</>
	);
}
