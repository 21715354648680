import React from 'react';
import { Skeleton } from '../../../Components/Skeleton/Skeleton';
import  EmptyState from '../../../Components/EmptyState';
import styles from './Campaigns.module.css';
import {ICampaignList} from './types';
import { useTranslation } from 'react-i18next';
import { IconTooltip } from '../../../Components/Tooltip/IconTooltip';

function getDateFromNow(date) {
	// @ts-ignore
	return moment(date).fromNow();
}

function CampaignList({
	isLoading,
	title,
	tooltip,
	emptyText,
	list,
	max = 5,
	campaignPropertyAccessor = c => getDateFromNow(c.startDate),
	emptyTextCondition = () => true,
	type
}: ICampaignList): JSX.Element {
	
	if (isLoading) {
		return (
			<div className={styles.wrapper}>
				<Skeleton appearance="paragraphe" />
			</div>
		);
	}

	const [t] = useTranslation('kpi');
	const isEmpty = list.length <= 0;
	const bulletClass = type == 'scheduled' ? styles.item_bullet_scheduled : styles.item_bullet_ongoing;
	const filteredList:any = list.slice(0, max);
	const nbFiltered = list.length > max ? list.length - max : 0;
	
	if (isEmpty || !emptyTextCondition()) {
		return (
			<EmptyState
				imageUrl="/Assets/empty_viewed_campaign.svg"
				imageWidth={56}
				title={emptyText}
				titleBold={false}
				verticalSize="xs"
				textSize="s"
			/>
		);
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>
				{title}
				{tooltip &&
					<IconTooltip
						tooltip={tooltip}
						size="s"
					/>
				}
			</div>
			<div className={styles.list}>
				{filteredList.map(cpg =>
					<div key={cpg.id} className={styles.item}>
						<div className={bulletClass}></div>
						<div className={styles.item_name}><a href={cpg.urlToCampaign}>{cpg.name}</a></div>
						<div className={styles.item_sep}></div>
						
						<div className={styles.item_date_or_kpi}>
							{campaignPropertyAccessor(cpg)}
						</div>

					</div>
				)}
				{nbFiltered > 0 &&
					<div className='grey_3'>
						{nbFiltered == 1 ?
							<>{t('common.And1Other')}</>
							:
							<>{t('common.AndNOthers', {nb: nbFiltered})}</>
						}
					</div>
				}
			</div>
		</div>
	);
}

export {
	CampaignList
};
