import React , {useState, useEffect} from 'react';
import kpiStyle from '../kpi.module.css';
import tabStyle from '../tabs.module.css';
import {
	commerceKpis
} from './commerceTypes';
import { useEcommerceContext } from '../context/ContextEcommerce';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import EmptyState from '../../../Components/EmptyState';
import moment from 'moment';
import { Section } from '../../../Components/Section/Section';
import {
	devices,
	pageTypes,
	engagementLevel,
	FORMAT
} from './config';
import EcommerceFilters from './EcommerceFilters';
import Chart from '../Chart';
import SelectSplitDimension from './SelectSplitDimension';
import {CardEcommerceContainer} from './CardEcommerceContainer';
import { Article } from '../../../Components/Article/Article';
import { v4 as uuidv4 } from 'uuid';
import { Percent } from '../SumUp';
const colors = [
	'#4278F0',
	'#FFB320',
	'#D7AB67',
	'#002453',
	'#7E67D7',
	'#9E9E9E',
	'#3FAA58',
	'#0068EF',
	'#E89700'
];
import { useTranslation } from 'react-i18next';
export default function EcommerceSection() {
	const [t] = useTranslation(['kpi']);
	function getTranslatedValue(config, value, translationParentKey) {
		const valueConfig = config.find(el => el.value.toLowerCase() === value.toLowerCase());
		if (!valueConfig) return value; 

		if (valueConfig.translation) {
			return t(valueConfig.translation.key, valueConfig.translation.vars);
		} else {
			return t(translationParentKey + '.' + valueConfig.label, {ns: 'kpi'});
		}
	}
	function getLabelByType (type, element) {
		if(type[0] === 'Device'){
			return getTranslatedValue(devices, element, 'device');
		}
		if(type[0] == 'PageType'){
			return getTranslatedValue(pageTypes, element, 'pageType');
		}
		if(type[0] == 'EngagementLevel'){
			return getTranslatedValue(engagementLevel, element, 'engagementLevel');
		}
	}
	const {
		accountId,
		systemServices,
		insightsServices,
		navFilter,
		deviceSelected,
		pageTypeSelected,
		intentionSelected,
		metricTypeSelected,
		utmDimensionsSelected
	} = useEcommerceContext();
	const [hasErrorOnTraffic, sethasErrorOnTraffic] = useState<boolean>(false);
	const [isLoadingData, setisLoadingData] = useState(false);
	const [noData, setnoData] = useState(false);
	const [firstLoading, setfirstLoading] = useState(true);
	const [selectedTab, setSelectedTab] = useState<string>('turnover');
	const [ecommerce, setEcommerce] = useState<commerceKpis>({
		turnover : {
			name: t('ecommerce.turnover'),
			isLoading : true,
			value: 0,
			valueDecimals: 2
		},
		conversion : {
			name: t('ecommerce.conversionRate'),
			isLoading : true,
			value: 0,
			valueDecimals: 2
		},
		averagenCartValue : {
			name: t('ecommerce.averageCartValue'),
			isLoading : true,
			value: 0,
			valueDecimals: 2
		},
		nbOfTransactions : {
			name: t('ecommerce.transactionCount'),
			isLoading : true,
			value: 0,
			valueDecimals: 0
		},
		abandonedCart : {
			name: t('ecommerce.abandonedCartsCount'),
			isLoading : true,
			value: 0,
			valueDecimals: 0
		},
	}
	);
	
	const [chartData, setChartData] = useState<any>({
		charts: [{ datasets: [], labels: [], title: '' }],
		isLoading: true,
		isError: false,
	});

	const objwebEcommerce = Object.keys(ecommerce);
	useEffect(()=>{
		// Get KPIS datas
		const payloadData = {
			'filters': [
				{dimension: 'Device',
					values: [...deviceSelected]
				},
				{dimension: 'PageType',
					values: [
						...pageTypeSelected
					]  },
				{dimension: 'EngagementLevel',
					values: [
						...intentionSelected
					]} 
			],
			'fromDate': navFilter.fromDate,
			'toDate': navFilter.toDate,
			'utmSource': utmDimensionsSelected.source,
			'utmCampaign': utmDimensionsSelected.campaign,
			'utmContent': utmDimensionsSelected.content,
			'utmMedium':  utmDimensionsSelected.medium,
			'metrics': [
				'Turnover',
				'ConversionRate',
				'AverageCartValue',
				'TransactionsCount',
				'AbandonedCartsCount'
			]
		};
		insightsServices.getEcommerceSumOvertime(accountId,payloadData,(success)=> {
			const result  = {
				turnover : {
					name: t('ecommerce.turnover'),
					isLoading : false,
					value: success.turnover,
					unit: '€',
					valueDecimals: 2
				},
				conversion : {
					name: t('ecommerce.conversionRate'),
					isLoading : false,
					value: success.conversionRate,
					unit: '%',
					valueDecimals: 2
				},
				averagenCartValue : {
					name: t('ecommerce.averageCartValue'),
					isLoading : false,
					value: success.averageCartValue,
					unit: '€',
					valueDecimals: 2
				},
				nbOfTransactions : {
					name: t('ecommerce.transactionCount'),
					isLoading : false,
					value: success.transactionsCount,
					valueDecimals: 0
				},
				abandonedCart : {
					name: t('ecommerce.abandonedCartsCount'),
					isLoading : false,
					value: success.abandonedCartsCount,
					valueDecimals: 0
				},
			};

			setEcommerce(result);


		}, (_err,errData) => {

			if(errData.status === 404){
				sethasErrorOnTraffic(true);
				setEcommerce( {
					turnover : {
						name: t('ecommerce.turnover'),
						isLoading : false,
						value: 0,
						valueDecimals: 2
					},
					conversion : {
						name: t('ecommerce.conversionRate'),
						isLoading : false,
						value: 0,
						valueDecimals: 2
					},
					averagenCartValue : {
						name: t('ecommerce.averageCartValue'),
						isLoading : false,
						value: 0,
						valueDecimals: 2
					},
					nbOfTransactions : {
						name: t('ecommerce.transactionCount'),
						isLoading : false,
						value: 0,
						valueDecimals: 0
					},
					abandonedCart : {
						name: t('ecommerce.abandonedCartsCount'),
						isLoading : false,
						value: 0,
						valueDecimals: 0
					},
				});
			}
			systemServices.showError('An error occurred while trying to get data');
		});
	},[navFilter,deviceSelected,pageTypeSelected,intentionSelected,utmDimensionsSelected]);
	
	const controllerKPI = new AbortController();
	const signalAbTKPI = controllerKPI.signal;
	useEffect(()=>{
		let selectedTabValue;
		switch (selectedTab) {
			case 'turnover':
				selectedTabValue = 'Turnover';
				break;
			case 'conversion':
				selectedTabValue = 'ConversionRate';
				break;
			case 'averagenCartValue':
				selectedTabValue = 'AverageCartValue';
				break;
			case 'nbOfTransactions':
				selectedTabValue = 'TransactionsCount';
				break;
			case 'abandonedCart':
				selectedTabValue = 'AbandonedCartsCount';
				break;
			default:
				break;
		}
		
		const setupTimeGranularityOptions = () => {
			if (!navFilter) return;
			const dateRange = moment(navFilter.toDate, FORMAT).diff(
				moment(navFilter.fromDate, FORMAT),
				'days'
			);
			let granularityOptions;
		
			if (dateRange < 14) {
				granularityOptions = 'Day';
			} else if (dateRange < 32) {
				granularityOptions = 'MondayWeek';
			} else if (dateRange < 64) {
				granularityOptions = 'Month';
			} else if (dateRange <= 426) {
				granularityOptions = 'Month';
			} else {
				granularityOptions = 'Year';
			}
			
			return granularityOptions;
		};
		const selectedTimeGranularity = setupTimeGranularityOptions();
		const dimensionSelected = metricTypeSelected.value !== 'none' ? metricTypeSelected.value : '';
		const payloadData = {
			'filters': [
				{dimension: 'Device',
					values: [...deviceSelected]
				},
				{dimension: 'PageType',
					values: [
						...pageTypeSelected
					] },
				{dimension: 'EngagementLevel',
					values: [
						...intentionSelected
					]
				}
			],
			granularity : selectedTimeGranularity,
			'fromDate': navFilter.fromDate,
			'toDate': navFilter.toDate,
			'utmSource': utmDimensionsSelected.source,
			'utmCampaign': utmDimensionsSelected.campaign,
			'utmContent': utmDimensionsSelected.content,
			'utmMedium':  utmDimensionsSelected.medium,
			dimension : metricTypeSelected.value !== 'none' ? metricTypeSelected.value : '' ,
			'metric': selectedTabValue};
		setisLoadingData(true);
		setChartData(d => ({
			... d,
			isLoading: true,
		}));
		insightsServices.getEcommerceMetricMetricovertime(accountId,payloadData,signalAbTKPI).then(success => {
			setisLoadingData(false);
			setfirstLoading(false);
			function toDateLookup(values) {
				return values.reduce((acc, val) => {
					if (acc.hasOwnProperty(val.date)) {
						return { ...acc, [moment(val.date).format('MM DD YYYY')]: [...acc[moment(val.date).format('MM DD YYYY')], val] };
					}
					return { ...acc, [moment(val.date).format('MM DD YYYY')]: [val] };
				}, {});
			}
			function getNameVal (){
				const n:string = selectedTab || '';
				switch (n) {
					case 'turnover':
						return 'turnover';
					case 'conversion':
						return 'conversionRate';
				
					case 'averagenCartValue':
						return 'averageCartValue';
	
					case 'nbOfTransactions':
						return'transactionsCount';
	
					case 'abandonedCart':
						return 'abandonedCartsCount';

					default:
						return '';
				}
			}
			function getDimensionName (selectedTab){
				const n:string = selectedTab || '';
				switch (n) {
					case 'turnover':
						return t('ecommerce.turnover');
						
					case 'conversion':
						return t('ecommerce.conversionRate');
					
					case 'averagenCartValue':
						return t('ecommerce.averageCartValue');
					
					case 'nbOfTransactions':
						return t('ecommerce.transactionCount');
						
					case 'abandonedCart':
						return t('ecommerce.abandonedCartsCount');
						
					default:
						return '';
				}
			}
			function transformValue(elem:number) {
				if(!elem) return 0;
				if(elem === 0) return elem;
				return  parseFloat(elem.toFixed(2));
			}
	
			if(dimensionSelected === ''){
				function getDataSets() {
					return datasetsNoDimensions();
				}
				if(success.data.length === 0){
					setChartData({
						charts: [{ datasets: [], labels: [], title: '' }],
						isLoading: true,
						isError: false,
					});
					return;
				}
				const data = success.data[0].dates;
				const dataLookup = toDateLookup(data);
				const datasetsNoDimensions = () => {
					let series:any= [];
					const series1 = {
						name: getDimensionName(selectedTab),
						data: [...dates].map(
							(date:any) => {
								const dateData = dataLookup[date];
								const name = getNameVal();
								if(dateData && dateData[0].hasOwnProperty(name)){
									const value = transformValue(dateData[0][name]);
									return value;
								}
								else{
									return 0;
								}
							}
						),
						type: 'areaspline',
						color: colors[0],
					};
					series = [series1];
					return series;
				};
				
				const dates = data.reduce((st, d) => st.add(moment(d.date).format('MM DD YYYY')), new Set());		
		
				const datasets = getDataSets();

				const labels = getLabels();
		
				// eslint-disable-next-line no-inner-declarations
				function getLabels() {
					return (dates &&
							[...dates].map((d) =>
								moment(d).format('MMM DD YYYY')
							)) ||
							[];
				}
				setChartData({
					charts: [
						{
							labels:labels,
							datasets,
							comparedPeriode: [],
							title: '',
							key: 0,
						},
					],
					isLoading: false,
					isError: false,
					isErrorEcommerce : false
				});

			}else{
				if(success.data.length === 0){
					setChartData({
						charts: [{ datasets: [], labels: [], title: '' }],
						isLoading: true,
						isError: false,
					});
					return;
				}

				let labels:any[] = [];
				const arrayOfDates = () => success.data.reduce((acc,curr) => {
					const dates = curr.dates.reduce((st, d) => st.add(moment(d.date).format('MM DD YYYY')), new Set());		
					const dataLookup = toDateLookup( curr.dates);
					const elementFormated = {
						name : getLabelByType(Object.keys(curr.dimension), curr.dimension[metricTypeSelected.value]),
						data : [...dates].map(
							(date:any) => {
								const dateData = dataLookup[date];
								const name = getNameVal();
								if(dateData && dateData[0].hasOwnProperty(name)){
									const value = transformValue(dateData[0][name]);
									return value;
								}
								else{
									return 0;
								}
							}
						),
						color: colors[acc.length],
						type: 'spline',
					};
				
					labels = getLabels();
					function getLabels() {
						return (dates &&
								[...dates].map((d) =>
									moment(d).format('MMM DD YYYY')
								)) ||
								[];
					}
					return acc = [...acc, elementFormated];

				}, []);
				const datasets = arrayOfDates();
				setChartData({
					charts: [
						{
							labels:labels,
							datasets,
							title: '',
							key: 0,
						},
					],
					isLoading: false,
					isError: false,
					isErrorEcommerce : false
				});
			}
		}).catch((err,) => {
			console.log('🚀--** ~ file: KpiContainer.tsx:396 ~ insightsServices.getInsightsKpis ~ err:', err);
			setisLoadingData(false);
			setfirstLoading(false);
			if(err.status === 404){
				sethasErrorOnTraffic(true);
				setChartData({
					charts: [{ datasets: [], labels: [], title: '' }],
					isLoading: true,
					isError: false,
				});				
			}
			systemServices.showError('An error occurred while trying to get data');
		});
		return () => {
			if(controllerKPI){
				controllerKPI.abort();				
			}
		};
	},[selectedTab, navFilter,deviceSelected,pageTypeSelected,intentionSelected,metricTypeSelected,utmDimensionsSelected]);

	useEffect(()=>{
		setnoData(chartData.charts.length == 0 || chartData.charts[0].datasets.length == 0);
	}, [chartData]);

	return (
		<Section width='m'>
			<section className="section no_bottom_pad section_primary">
				<div className="h1">{t('ecommerce.ecommerce')}</div>
			</section>
			<section className="section">
				<div className={kpiStyle.section_header}>
					<EcommerceFilters />
				</div>
				<div className={kpiStyle.section_part}>
					<Article innerSize='l' hasMargin={false}>
						<div className={tabStyle.tabs_border}>
							<div className='flex'>
								<div className='flex_item_full'>
									<div className={tabStyle.tabs}>
										
										{!hasErrorOnTraffic && ecommerce &&  <>
											{objwebEcommerce.map(item =>
												<div
													key={uuidv4()}
													className={selectedTab == item ? tabStyle.tab_active : tabStyle.tab}
													onClick={() => setSelectedTab(item)}
												>
													<Percent
														item={ecommerce[item]}
														showEvolution={false}
													/>
												</div>
											)}
										</>}
										
									</div>
								</div>
								<div className='flex_item_fix ml_10'>
									<SelectSplitDimension />
								</div>
							</div>
						</div>
						{firstLoading &&
							<EmptyState
								spinner={true}
								title={t('common.loadingData')}
							/>
						}
						{!firstLoading && isLoadingData &&
							<SpinnerWheel />
						}
						{!firstLoading && noData && (
							<EmptyState
								imageUrl="/Assets/empty_no_data.svg"
								title={t('common.noDataForThisPeriod')}
								text={t('common.tryWithAnotherPeriod')}
							/>
						)}
						{!noData &&
							<Chart
								data={chartData.charts.find(arr => arr.key === 0)}
							/>
						}
					</Article>
				</div>
				<div className={kpiStyle.section_part}>
					<CardEcommerceContainer
						metricTypeSessionsSelected={selectedTab}
					/>
				</div>
			</section>
		</Section>
	);
}
