import React from 'react';
import EmptyState from './../../../../Components/EmptyState.js';
import { Article } from './../../../../Components/Article/Article';
import { Section } from './../../../../Components/Section/Section';
import { PageBreadcrumb } from './../../../../Components/Page/PageBreadcrumb';
import { useTranslation } from 'react-i18next';

export const AccountHasNoData = () => {

	const [t] = useTranslation('kpi');

	return (
		<Section hasMargin={false} width="l">
			<section className='section no_bottom_pad section_primary'>
				<PageBreadcrumb>
					<span className='h1'>{t('productInsights.productInsights')}</span>
				</PageBreadcrumb>
			</section>
			<section className='section'>
				<Article innerSize='l'>
					<EmptyState
						textSize="xl"
						verticalSize="l"
						title={'This feature is not configured for this account'}
						text={'Please contact an administrator for more information'}
						imageUrl={'/Assets/empty_products_insights.svg'}
					/>
				</Article>
			</section>
		</Section>
	);
};
