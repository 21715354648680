import React, { Fragment } from 'react';
import { AiInsight } from '../../context/AiInsightsApiTypesServices';
import styles from './Card.module.css';
import IconText from './IconText';
import { useTranslation } from 'react-i18next';
import { getFormattedNumber } from '../../../../../Util/numberFormats';

import {
	iconFunnel,
	iconAbandonedCart,
	iconBarCode,
	iconTraffic,
	iconMobile,
	iconDesktop,
	iconTablet,
	iconPage,
	iconTrafficSource,
	iconUserRecurring
} from '../../../../../Components/CustomIcon/CustomIcon.js';

type Locale = 'en-EN' | 'fr-FR';

// CardHeader Component
interface CardHeaderProps {
	insight: AiInsight;
}

function getLocale(): Locale {
	const localeString = localStorage.getItem('i18nL');
	if (localeString && localeString.startsWith('fr-FR')) {
		return 'fr-FR';
	} else {
		return 'en-EN';
	}
}
const locale = getLocale();

const CARD_HEADER_CONFIGS = {
	WebsiteTraffic: {
		linkText: {
			'en-EN': 'Website Traffic Dashboard',
			'fr-FR': 'Tableau de bord Trafic',
		},
		title: {
			'en-EN': 'Website Traffic',
			'fr-FR': 'Trafic du Site Web',
		},
		icon: iconTraffic,
		linkUrl: '/Insight/traffic',
	},
	Product: {
		linkText: {
			'en-EN': 'Products Dashboard',
			'fr-FR': 'Tableau de bord Produits',
		},
		title: {
			'en-EN': 'Products',
			'fr-FR': 'Produits',
		},
		icon: iconBarCode,
		linkUrl: '/Insight/ecommerce',
	},
	AbandonedBasket: {
		linkText: {
			'en-EN': 'E-commerce Dashboard',
			'fr-FR': 'Tableau de bord E-commerce',
		},
		title: {
			'en-EN': 'Abandoned Baskets',
			'fr-FR': 'Paniers Abandonnés',
		},
		icon: iconAbandonedCart,
		linkUrl: '/Insight/ecommerce',
	},
	Conversion: {
		linkText: {
			'en-EN': 'E-commerce Dashboard',
			'fr-FR': 'Tableau de bord E-commerce',
		},
		title: {
			'en-EN': 'Conversions',
			'fr-FR': 'Conversions',
		},
		icon: iconFunnel,
		linkUrl: '/Insight/ecommerce',
	},
};

const DIMENSION_ICON = {
	Device: {
		Mobile: iconMobile,
		Desktop: iconDesktop,
		Tablet: iconTablet,
	},
	PageType: {
		'*': iconPage,
	},
	FirstPageType: {
		'*': iconPage,
	},
	LastPageType: {
		'*': iconPage,
	},
	TrafficSource: {
		'*': iconTrafficSource,
	},
	VisEngLevel: {
		'*': iconUserRecurring,
	},
	FirstVisEngLevel: {
		'*': iconUserRecurring,
	},
	LastVisEngLevel: {
		'*': iconUserRecurring,
	},
};

function getTagsFromDimensions(dimensions):any[] {
	const tags = Object.entries(dimensions).map(([key, value]) => {
		let icon = '';
		if (DIMENSION_ICON[key] ) {
			if (DIMENSION_ICON[key]['*']) {
				icon = DIMENSION_ICON[key]['*'];
			} else if (DIMENSION_ICON[key][value]) {
				icon = DIMENSION_ICON[key][value];
			}
		}

		let translationKey = '';
		switch (key) {
			case 'VisEngLevel':
			case 'FirstVisEngLevel':
			case 'LastVisEngLevel':
				translationKey = 'engagementLevelCode';
				break;
			case 'PageType':
			case 'FirstPageType':
			case 'LastPageType':
				translationKey = 'pageTypeCode';
				break;
			case 'TrafficSource':
				translationKey = 'trafficSource';
				break;
		}
		
		return {
			key: key,
			value: value,
			icon: icon,
			translationKey: translationKey
		};
	});
	return tags;
}

const CardHeader: React.FC<CardHeaderProps> = ({ insight }) => {

	const category = CARD_HEADER_CONFIGS[insight.category];
	const tags = getTagsFromDimensions(insight.dimensions);
	const [t] = useTranslation('ai_insights');

	return (
		<div className={styles.header}>
			<div className="flex">
				<div className='flex_item_full'>
					<div className={styles.headerTagList}>
						{category &&
							<IconText
								text={category.title[locale]}
								icon={category.icon}
								tooltip={'Insight type: ' + category.title[locale]}
							/>
						}
						{tags.map((tag) =>
							<Fragment key={tag.key}>
								<span className=''>
									<i className='fas fa-chevron-right s_11 grey_4'></i>
								</span>
								<IconText
									tooltip={t('dimensions.' + tag.key) + ': ' + (tag.translationKey ? t(tag.translationKey + '.' + tag.value) : tag.value)}
									icon={tag.icon}
									text={tag.translationKey ? t(tag.translationKey + '.' + tag.value) : tag.value}
								/>
							</Fragment>
						)}
					</div>
				</div>
				<div className='flex_item_fix ml_20'>
					{insight.score && 
						<span className='grey_3 s_12'>
							Score:{' '}
							{getFormattedNumber(insight.score, 1)}
						</span>
					}
				</div>
				{/* <Link
					href={config.linkUrl}
					icon="fas fa-arrow-right"
					iconPosition="after"
					className="s_14"
				>
					{config.linkText[locale]}
				</Link> */}
			</div>
		</div>
	);
};

export default CardHeader;
