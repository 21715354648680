import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from '../Tooltip/Tooltip';

export default function TableColItem({
	specialClasseName,
	onClick = () => { },
	children,
	style = {},
	refs = {
		setNodeRef: React.createRef(),
		props: {

		},
		attributes: {

		},
		listeners: {

		}
	},
	align = 'left',
	tooltip,
	tooltipHTML,
}) {

	const tooltipID = uuidv4();
	const cssClass = ['table_col'];

	if (specialClasseName) {
		cssClass.push(specialClasseName);
	}

	if (align == 'right') {
		cssClass.push('flex_justify_end al_right');
	} else if (align == 'center') {
		cssClass.push('flex_justify_center al_center');
	}

	return (
		<div
			ref={refs.setNodeRef}
			{...refs.props}
			{...refs.attributes}
			{...refs.listeners}
			className={cssClass.join(' ')}
			style={{ ...style }}
			onClick={onClick}
			data-tip={tooltipHTML ? 'a' : tooltip}
			data-for={tooltipID}>
			{children}
			{(tooltip || tooltipHTML) &&
				<Tooltip
					text={tooltip}
					html={tooltipHTML}
					id={tooltipID}
				/>
			}
		</div>
	);
}
