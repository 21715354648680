import React, {useState, useCallback} from 'react';
import { useCatalogueEditorContext } from '../Context/CatalogueContextProvider';
import {TemplateStyle} from './TemplateStyle';
import {Template} from '../Context/TemplateTypes';
import styles from '../CatalogueContainer.module.css';

const slideWidth = 218;

export const StylesGallery = () => {

	const {
		styleSelected,
		formatSavedStyles = [],
		handleStyleSelected,
		template,
		currentDevice
	} = useCatalogueEditorContext();

	const templateS:Template | undefined= template ? template : undefined;
	const [needNext, setNeedNext] = useState(false);
	const [needPrevious, setNeedPrevious] = useState(false);
	const [scrollBar, setScrollBar] = useState<HTMLDivElement>();

	const handleScroll = (target) => {
		const scrollEnd = target.offsetWidth + target.scrollLeft >= target.scrollWidth;
		setNeedPrevious(target.scrollLeft > 0);
		setNeedNext(!scrollEnd);
	};

	const scrollRef = useCallback((node: HTMLDivElement) => {
		if (node !== null) {
			handleScroll(node);
			setScrollBar(node);
		}
	}, []);

	const goPrev = () => {
		if (scrollBar) {
			scrollBar.scrollLeft -= (slideWidth * 2);
		}
	};

	const goNext = () => {
		if (scrollBar) {
			scrollBar.scrollLeft += (slideWidth * 2);
		}
	};

	if (formatSavedStyles.length <= 1) return <></>;
	if (!templateS) return <></>;

	return (
		<div className={styles.templatePageSection}>
			<div className={[styles.templatePageSectionTitle, 'h3'].join(' ')}>Available styles</div>
			<div className={[styles.gallery].join(' ')}>
				{needPrevious &&
					<button className={styles.galleryPrev} onClick={goPrev}>
						<i className="fas fa-chevron-left" />
					</button>
				}
				<div className={styles.gallery_track} ref={scrollRef} onScroll={(ev) => handleScroll(ev.target)}>
					{formatSavedStyles.map((style, idx) => {
						return <TemplateStyle
							key={idx}
							idStyle={style.id}
							template={templateS}
							device={currentDevice}
							properties={JSON.parse(JSON.stringify(template?.Properties))}
							name={style.name}
							styleProperties={style.properties}
							style={style}
							isSelected={styleSelected?.id === style.id}
							isMinimized={true}
							handleClick={()=>handleStyleSelected(style)}
							hasPlaceholder={true}
						/>;
					}
					)}
				</div>
				{needNext &&
					<button className={styles.galleryNext} onClick={goNext}>
						<i className="fas fa-chevron-right" />
					</button>
				}
			</div>
		</div>
	);
};



