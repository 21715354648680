import { useTranslation } from 'react-i18next';

export default function NotificationCenterContextualLabels() {

	const [t] = useTranslation(['common']);

	const contextualLabels = {
		searchCampaignPlaceholder:t('search.searchNotificationCenterNotification'),
		listPageTitle:'Notification center'
	};

	return contextualLabels;
} 
