import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../Components/Dropdown';
import Listbox from '../../Components/Listbox';
import ListboxItem from '../../Components/ListboxItem';
import EmptyState from '../../Components/EmptyState';

export default function LabelSelectDropdown({
	selectableLabels,
	selectedLabelIds,
	onLabelSelected,
	resetFilterLabels,
	showEditLabels = false,
	onEditRequested
}) {

	const [t] = useTranslation(['common']);
	const [isOpen, setIsOpen] = useState(false);

	const btnClass = ['custom_input', 'clickable', 'w_auto', 'm', 'bg_white'];
	if (selectedLabelIds && selectedLabelIds.length >= 1) {
		btnClass.push('highlighted');
	}

	const getSelectedLabelName = () => {
		if (!selectedLabelIds || selectedLabelIds.length == 0) {
			return '';
		}
		const selectedLabels = selectableLabels.filter(l => selectedLabelIds.includes(l.id));
		const first = selectedLabels[0];
		let name = first.name;
		if (selectedLabels.length > 1) {
			name += ' +' + (selectedLabels.length - 1);
		}
		return name;
	};

	const selectedLabelName = getSelectedLabelName();
	const isEmpty = !selectableLabels || selectableLabels.length == 0;
	const selectableLabelsRes = selectableLabels && selectableLabels.length > 0 ? t('labels.EditLabels') : t('labels.AddLabels');

	return (
		<>
			<Dropdown
				isOpen={isOpen}
				toggle={(s) => setIsOpen(s)}
				scrollbar={true}
				button={
					<button className={btnClass.join(' ')}>
						<span className="custom_input_value has_label">
							<span className="custom_input_value_label">{t('labels.labels')}</span>
							{selectedLabelName ?
								<>{selectedLabelName}</>
								:
								<span className="custom_input_placeholder">{t('common.none')}</span>
							}
						</span>
						{selectedLabelName &&
							<span className="custom_input_reset"
								onMouseDown={(e) => {e.stopPropagation();}}
								onClick={(e) => {e.stopPropagation(); resetFilterLabels();}}>
								<i className="fas fa-times-circle"></i>
							</span>
						}
						<span className="custom_input_arrow"></span>
					</button>
				}
				minWidth={isEmpty ? 250 : 0}
			>
				{!isEmpty &&
					<Listbox>
						{selectableLabels && selectableLabels.map(label =>
							<ListboxItem
								key={label.id}
								onClick={() => onLabelSelected(label)}
								selected={selectedLabelIds && selectedLabelIds.includes(label.id)}
								hasCheckbox={true}
							>
								<span className={'campaign_label l selected campaign_label_'+label.color.toLowerCase()}>
									{label.name ? label.name : t('labels.noName')}
								</span>
							</ListboxItem>
						)}
					</Listbox>
				}
				{isEmpty &&
					<EmptyState
						icon='fas fa-tag'
						textSize='s'
						title={t('labels.noLabelYet')}
					/>
				}
			</Dropdown>
			{showEditLabels &&
				<a className="campaign_label_action ml_15" onClick={onEditRequested}>
					{selectableLabelsRes}
				</a>
			}
		</>
	);
}