import gql from 'graphql-tag';
const getCampaigns = gql`
query($accountId: String, $skip: Int, $take: Int, $where:OnSiteCampaignFilterInput, $order:[OnSiteCampaignSortInput!]){
  account(id: $accountId){
    onSiteCampaigns(take:$take, skip:$skip, where:$where, order:$order){
         pageInfo{
            hasNextPage,
            hasPreviousPage,
        },
        totalCount,
        items{
           id,
           searchId,
           name,
           description
           isStopped,
           objective,
           startDate,
           endDate, 
           modificationDate,
           executionMode,
           trackingMode,
           technicalPurpose,
           editor,
           impressionsLast30DaysValueDisplayedAbsolute,
           impressionsLastLast30DaysValueDisplayedAbsolute,
           labels{
            name,
            color,
            labelId
           },
           campaignDebugLinks{
            debugLink,
            variation
           },
           screenShots {
            id,
            screenShotUrl
           },          
           testModeIpAddresses{
            ipAddress,
            label,
            isDisabled
           },
           devices{
            deviceId
           },
           defaultPermissions{
            permission,
            isAllowed
           },
           currentUserPermissions{
            permission,
            isAllowed
           }
       }
    }
  }
}
`;
const getCampaignsForReportGenerator = gql`
query($accountId: String, $skip: Int, $take: Int, $where:OnSiteCampaignFilterInput,$order:[OnSiteCampaignSortInput!]){
  account(id: $accountId){
    onSiteCampaigns(take:$take, skip:$skip, where:$where, order:$order){
         pageInfo{
            hasNextPage,
            hasPreviousPage,
        },
        totalCount,
        items{
          id,
          name,
          startDate,
          endDate,
          modificationDate,
          description,
          isStopped,
          technicalPurpose,
          executionMode
       }
    }
  }
}
`;
const getCampaignsByRangeDate = gql`
query($accountId: String, $skip: Int, $take: Int, $where:OnSiteCampaignFilterInput,$order:[OnSiteCampaignSortInput!]){
  account(id: $accountId){
    onSiteCampaigns(take:$take , skip:$skip,where:$where, order:$order){
      pageInfo{
         hasNextPage,
         hasPreviousPage,
     },
     totalCount,
     items{
        id,
        searchId,
        name,
        description
        isStopped,
        objective,
        startDate,
        endDate, 
        modificationDate,
        executionMode,
        trackingMode,
        technicalPurpose,
        editor,
        testModeIpAddresses{
          ipAddress,
          label,
          isDisabled
         },
        labels{
         name,
         color,
         labelId
        },
        campaignDebugLinks{
         debugLink,
         variation
        },
        devices{
         deviceId
        },
        screenShots {
          id,
          screenShotUrl
        },
        defaultPermissions{
         permission,
         isAllowed
        },
        currentUserPermissions{
         permission,
         isAllowed
        }
    }
 }
}
}
`;
const checkAccountDataQuery = gql`
query($accountId: String, $skip: Int, $where:OnSiteCampaignFilterInput){
  account(id: $accountId){
    onSiteCampaigns(skip:$skip, where:$where){
        totalCount,
       items{
           id,
           technicalPurpose
       }
    }
  }
}
`;
const getCampaignsNotificationsSource = gql`
query($accountId: String, $where:OnSiteCampaignFilterInput,){
  account(id: $accountId){
    onSiteCampaigns(where:$where,){
          pageInfo{
            hasNextPage,
            hasPreviousPage,
        },
        totalCount,
        items{
           id,
           technicalPurpose,
          
       }
    }
  }
}
`;
const getEmailRemarketingCampaigns = gql`
query($accountId: String, $skip: Int, $take: Int, $where:EmailRemarketingCampaignFilterInput,$order:[EmailRemarketingCampaignSortInput!]){
  account(id: $accountId){
    emailRemarketingCampaigns(skip: $skip, take: $take, where:$where, order:$order ){
         pageInfo{
            hasNextPage,
            hasPreviousPage,
        },
        totalCount,
       items{
           id,
           searchId,
           name,
           description
           isStopped,
           startDate,
           endDate, 
           modificationDate,
           labels{
            name,
            color,
            labelId
           },
       }
    }
  }
}
`;
const getEmailRemarketingCampaignsSimple = gql`query($accountId: String,$where:EmailRemarketingCampaignFilterInput) {
	account(id: $accountId) {
		emailRemarketingCampaigns(where:$where) {
		pageInfo{
            hasNextPage,
            hasPreviousPage,
        },
        totalCount,
			items {
				id
				name
				description
	 			searchId,
			   isStopped,
			   startDate, 
			   endDate,
			   creationDate
           statistics {
                total {
                    numberOfEmailsSent,
                    numberOfEmailsDelivered,
                    numberOfEmailsOpened,
                    numberOfEmailsClicked
                },
                byDate {
                    date,
                    numberOfEmailsSent,
                    numberOfEmailsDelivered,
                    numberOfEmailsOpened,
                    numberOfEmailsClicked
                }
            },
			}
		}
	}
}`;
const INTROSPECTION_QUERY = gql`
  {
    __type(name: "EmailRemarketingCampaign") {
      name
      fields {
        name
      }
    }
  }
`;

const getBusinessOperations = gql`
query($accountId: String, $skip: Int, $take: Int, $where:BusinessOperationFilterInput,$order:[BusinessOperationSortInput!]){
  account(id: $accountId){
    businessOperations(skip: $skip, take: $take, where:$where, order:$order ){
         pageInfo{
            hasNextPage,
            hasPreviousPage,
        },
        totalCount,
        items{
          name,
          startDate,
          endDate,
          isStopped
        }
    }
  }
}
`;



export { getCampaigns, getCampaignsForReportGenerator, checkAccountDataQuery, getCampaignsByRangeDate, getEmailRemarketingCampaigns, getBusinessOperations ,getCampaignsNotificationsSource,getEmailRemarketingCampaignsSimple,
	INTROSPECTION_QUERY
};

