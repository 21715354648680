/* eslint-disable no-case-declarations */
import React, { createContext, useState, useEffect, useContext } from 'react';
import CampaignCreatingServices from '../../../Services/CampaignCreatingServices';
import ReportingCampaignServices from '../../../Services/ReportingCampaignServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import CloudImageServices from '../../../Services/CloudImageServices';
import FormAndSurveysServices from '../../../Services/FormSurveysServices';
import { ProductReferencesValue } from '../../WysiwygEditor/Utils/TemplateOperations';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import SystemServices from '../../../Services/SystemServices';
import { dateUtils, validate, propertiesProcessing, groupBy, fontUtil } from '../util';
import { services, campaignImageServices } from '../services';
import { cloneDeep, flow, isEqual } from 'lodash';
import { guessTimeZone, getDateInTimeZone } from '../../../Util/TimezoneUtil';
import { handlePatchCss, handlePatchJs,htmlTypeVariationAbTestRedirection} from '../components/GraphEditor/utils';
import EventTrackingServices from '../../../Services/EventTrackingServices';
import SetupAccountServices from '../../../Services/SetupAccountServices';
import i18next from 'i18next';
import {designPropertiesAbTestRedirection, dividePercent,getElementInteractionName} from '../util';
import { I18nextProvider } from 'react-i18next';
import campaign_react_fr from '../../../Translation/jsonfiles/fr/campaign_react_fr.json';
import campaign_react_en from '../../../Translation/jsonfiles/en/campaign_react_en.json';
import ExternalAppServices from '../../../Services/ExternalAppServices';
import useEventBroker from '../../../Hooks/useEventBroker';

export const context = createContext();

export function CampaignsContext() {
	return useContext(context);
}

const trackingModeToId = (trackingMode) => trackingMode === 'Marketing' ? 2 : trackingMode === 'NoTracking' ? 1 : trackingMode;

export const ContextProvider = (props) => {
	const { addDays } = dateUtils();
	const {
		validateFormatData,
		validateEditorState,
		validateInformationsData,
		validateTriggersData,
		validateCustomData,
		validateFormatDataAbtest
	} = validate();
	const {
		templatePropertyToStyleProperty,
		areSameStyles,
		setActiveItemIntoCollection,
	} = propertiesProcessing();

	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $http = props.$http;
	const $timeout = props.$timeout;
	const UtilCampaignsServices = props.UtilCampaignsServices;
	const authServices = props.AuthServices;
	const editEntryPoint = props.$location && props.$location.$$search.edit;
	const module = props.$location && props.$location.$$search.module;

	const mode = !$routeParams.key ? 'creating' : 'edition';


	const [wysiwygSurveyTarget, setWysiwygSurveyTarget] = useState();
	const tooltipID = uuidv4();
	const impersonatingServices = new ImpersonatingServices(
		$rootScope,
		$routeParams
	);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || ($rootScope && $rootScope.User.Account.Key);
	//const userRole = $rootScope.User.Role;
	const userID = $rootScope.User.Key;
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const currentIpAddress = props.$rootScope.$$childHead.currentIP;

	const isAdmin = impersonatingServices.isAnAdmin();
	const canSwitchToNoTracking = (isAdmin || $rootScope.User.Permissions.find(p => p.Name === 'CAMPAIGNS_CAN_SWITCH_NOTRACKING_MODE').Value);
	const formAndSurveysServices = new FormAndSurveysServices($http, accountId);
	const externalAppServices = new ExternalAppServices($http, accountId);

	const { subscribe } = useEventBroker();

	const defaultTrackingMode = trackingModeToId(canSwitchToNoTracking && !!$routeParams.patch && mode === 'creating' ? 'NoTracking' : 'Marketing');
	const defaultObjective = !!$routeParams.patch && mode === 'creating' ? 'PatchWebSite' : 'ImproveConversion';
	const campaignCreatingServices = new CampaignCreatingServices(
		$http,
		$routeParams,
		authServices
	);
	const reportingCampagneServices = new ReportingCampaignServices($http, $routeParams);
	const getAccessToken = authServices && authServices.getAccessToken;
	const cloudImageServices = new CloudImageServices(accountId, $http, getAccessToken);
	const imageService = campaignImageServices(cloudImageServices);
	const setupAccountServices = new SetupAccountServices($http);

	const { trackEvent } = EventTrackingServices($rootScope.User);

	const {
		saveStyle,
		deleteSavedStyle,
		publishSavedStyle,
		launchPreview,
		getCampaignRendering,
		getCampaignAndFormats,
		saveCampaign,
		getAvailableFormats,
		getModuleFormats,
		getFormatSavedStyles,
		processPatches,
		getFontData,
		getExternalAppSetupInfo,
	} = services(campaignCreatingServices, accountId, userID, imageService, externalAppServices);

	const defaultEditor = module
		? 'targetedMessage/module/' + module
		: 'targetedMessage/library';

	const editEntryPointToStep = (defaultStep) => {
		if (editEntryPoint === 'format') return 1;
		if (editEntryPoint === 'triggers') return 2;
		if (editEntryPoint === 'info') return 3;
		if (editEntryPoint === 'infoAbTest') return 3;
		return defaultStep || 3;
	};

	const getDefaultCampaignName = () => {
		if ($routeParams.patch) return 'New full code campaign';
		if ($routeParams.graph) return 'New graphic patch';
		if ($routeParams.abtest) return 'New redirect test';
		if ($routeParams.notificationCenter) return 'New inbox';
		if ($routeParams.notificationCenterNotification) return 'New notification';

		// TODO : adapter quand on aura le type de format dans l'URL (ex: toaster, in-page, popup)
		if ($routeParams.builder) return 'New popup';

		return 'New campaign';
	};

	const defaultHttpConnector = [{
		action: 'callHttp',
		isActive: false,
		identifier: '',
		url: '',
		method: 'Post',
		content: '{\n\t"email": "{{Email}}",\n\t"name": "{{Name}}"\n}',
		authorizationType: 'NoAuth',
		authorizationScheme: 'Bearer',
		authorizationCredentials: '',
		apiKey: '',
		apiKeyHeader: ''
	}];

	const defaultCampaignFeatures = {
		HttpConnector: defaultHttpConnector
	};

	const variationItemDefault = {
		NameOrTag: '',
		DisplayIdentifier: 'INT1',
		VariationType: 'Range',
		RangeSize: 0,
		SlideFormat: '',
		DesignProperties: [],
		SlideType: 'CallToAction',
		Properties: [],
		Answer: {
			ItemType: 'CallToAction',
			CallToAction: {
				Text: null,
				Link: null,
			},
			PromoCode: null,
		},
		TemplateType: 'BESPOKE',
		TemplateName: '',
		InCollection: null,
		InPage: null,
		TemplateDescriptorDesktop: {
			EditableCss: '',
			HtmlPreview: '',
			EditableJavascript: '',
			EditableDisplayCondition:null,
			EditableTemplate: {
				ContentTemplateType: 'Fluid',
				Content: '',
			},
		},
		TemplateDescriptorMobile: {
			EditableCss: '',
			HtmlPreview: '',
			EditableJavascript: '',
			EditableDisplayCondition:null,
			EditableTemplate: {
				ContentTemplateType: 'Fluid',
				Content: '',
			},
		},
		SavedStyle: '',
		Template: {},
	};
	const variationItemAbTestRedirectionDefault = {
		NameOrTag: '',
		DisplayIdentifier: 'INT1',
		VariationType: 'Range',
		RangeSize: 0,
		SlideFormat: '',
		DesignProperties: [designPropertiesAbTestRedirection],
		SlideType: 'Patch',
		Properties: [],
		Answer: {
			ItemType: 'CallToAction',
			CallToAction: {
				Text: null,
				Link: null,
			},
			PromoCode: null,
		},
		TemplateType: 'CUSTOM',
		TemplateName: '',
		InPage: null,
		InCollection: null,
		TemplateDescriptorDesktop: {
			EditableCss: '',
			HtmlPreview: '',
			EditableJavascript: '',
			EditableDisplayCondition:null,
		},
		TemplateDescriptorMobile: {
			EditableCss: '',
			HtmlPreview: '',
			EditableJavascript: '',
			EditableDisplayCondition:null,
		},
		SavedStyle: '',
		Template: {},
	};

	const defaultTimezone = guessTimeZone();
	const defaultStartDate = getDateInTimeZone(new Date(), defaultTimezone);
	const defaultEndDate = addDays(defaultStartDate, 10);

	const idForTriggerGroupDefault = uuidv4();
	const campaignDefault = {
		CampaignId: null,
		Name: getDefaultCampaignName(),
		Description: null,
		CampaignType: 'CallToAction',
		StartDateUtc: defaultStartDate,
		StartDateInTimezone: defaultStartDate,
		EndDateUtc: defaultEndDate.setHours(23,59,59),
		EndDateInTimezone: defaultEndDate.setHours(23,59,59),
		IanaTimezone: defaultTimezone,
		HasEndDate: true,
		IsStopped: false,
		HasControlGroup: false,
		ControlGroupSplitValue: 0,
		IdleDuration: 0,
		Objective: defaultObjective,
		ContributionMode: 'Unknown',
		ExecutionMode: 'Test',
		TrackingMode: defaultTrackingMode,
		UrlDebug: '',
		Specifications: [
			{
				Name: 'TRGCHECKDEVICE',
				ValueToCompare: [0, 1, 2],
				Group: {
					Id: idForTriggerGroupDefault,
					Label: ''
				}
			},
			{
				Name: 'TRGMARKPRESSURE',
				ValueToCompare: -1,
				Group: {
					Id: idForTriggerGroupDefault,
					Label: ''
				}
			},
		],
		Slide: {
			UId: uuidv4(),
			RangeSize: 100,
			SlideId: null,
			SlideType: 'CallToAction',
			SlideFormat: '',
			DesignProperties: [],
			TemplateType: 'BESPOKE',
			TemplateName: '',
			Template: {},
			InPage: null,
			InCollection: null,
			Answer: {
				ItemType: 'CallToAction',
				CallToAction: {
					Text: null,
					Link: null,
				},
				PromoCode: null,
			},
			Variants: [],
			Variations: [],
			Properties: [],
			TemplateDescriptorDesktop: {
				EditableCss: '',
				HtmlPreview: '',
				EditableJavascript: '',
				EditableDisplayCondition:null,
				EditableTemplate: {
					ContentTemplateType: 'Fluid',
					Content: '',
				},
			},
			TemplateDescriptorMobile: {
				EditableCss: '',
				HtmlPreview: '',
				EditableJavascript: '',
				EditableDisplayCondition:null,
				EditableTemplate: {
					ContentTemplateType: 'Fluid',
					Content: '',
				},
			},
			SavedStyle: '',
		},
		DefaultPermissions: {
			CAMPAIGNS_CAN_VIEW: true,
			CAMPAIGNS_CAN_CREATE: true,
			CAMPAIGNS_CAN_DELETE: false,
			CAMPAIGNS_CAN_SUSPEND: true,
		},
		TestModeIpAddresses: [],
		TechnicalPurpose: 0,
		Editor: defaultEditor,
		Features: defaultCampaignFeatures // Features is not a server-side property
	};

	// reference data
	const [referenceData, setReferenceData] = useState({
		accountIpAddresses: [],
		accountIpAddressesLoaded: false,
	});

	const [availableFormats, setAvailableFormats] = useState({
		formats: [],
		loaded: false,
	});
	const [fontData, setFontData] = useState(null);
	const [noAvailableFormat, setNoAvailableFormat] = useState(false);

	// nav
	const [isReady, setIsReady] = useState(false);
	const [canSaveEdition, setCanSaveEdition] = useState(false);
	const [canGoNextOrSave, setCanGoNextOrSave] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [step, setStep] = useState(editEntryPointToStep(1));
	const [saveError, setSaveError] = useState([]);
	const [variationsProperlySet, setVariationsProperlySet] = useState(false);
	const [triggersProperlySet, setTriggersProperlySet] = useState(false);
	const [informationsProperlySet, setInformationsProperlySet] = useState(false);
	const [saveStyleModalOpen, setSaveStyleModalOpen] = useState(false);
	const [showCSSEditor, setShowCSSEditor] = useState(false);
	const [isABTestingActive, setIsABTestingActive] = useState(false);
	const [displayAbTestSettings, setDisplayAbTestSettings] = useState(false);
	const [displayControlGroupSettings, setDisplayControlGroupSettings] = useState(false);

	const [toggleResponsiveValue, setToggleResponsiveValue] = useState(false);
	const [currentDevice, setCurrentDevice] = useState('mobile');
	const [modalUploadPicture, setModalUploadPicture] = useState(false);
	const [displayBackToFormat, setDisplayBackToFormat] = useState(false);
	const [displayBackToStyle, setDisplayBackToStyle] = useState(false);

	const [canPublishStyles, setCanPublishStyles] = useState(false);
	const [i18nContext] = useState();

	// state
	const [campaign, setCampaign] = useState(campaignDefault);
	const [isLoadingCurrentVariationRendering, setisLoadingCurrentVariationRendering] = useState(false);
	const [memoInitialCampaignState, setMemoInitialCampaignState] = useState();
	const [memoInitialHttpConnector, setMemoInitialHttpConnector] = useState(defaultHttpConnector);

	const [editorMode, setEditorMode] = useState();

	const isModuleEditorMode = () => editorMode && editorMode.split('/')[0] === 'module';

	const [variations, setVariations] = useState([]);

	const [currentVariationId, setCurrentVariationId] = useState();

	const [externalAppSetup, setExternalAppSetup] = useState({
		hasExternalAppSetup: false,
		hasErrorToGetSetupInfo: false,
		installationError: false,
		installationErrorMessage: '',
		actionNeeded: '', //InstallApp or ActivateApp
		actionLink: '' });

	const features = campaign.Features || {};

	// state - variation dependent
	const hasCustomKey = $routeParams.custom;
	const hasStyleId = $routeParams.styleId;
	const [formatSavedStyles, setFormatSavedStyles] = useState([]);
	const [matchedExistingStyle, setMatchedExistingStyle] = useState();
	const [firstRenderAndChoiceOfStyle, setfirstRenderAndChoiceOfStyle] = useState(true);
	const [styleSelectWithoutClick, setstyleSelectWithoutClick] = useState(false);
	const [editorState, setEditorState] = useState({
		sideBarStep: 1,
		selectedFormat: {},
		selectedStyle: {},
		defaultStyle: {},
		currentStyle: null,
		currentWysiwyg: {},
	});

	const [variationError, setVariationError] = useState(null);
	// campaign edition state
	const [testGroupPercent, setTestGroupPercent] = useState(100 - campaignDefault.ControlGroupSplitValue);
	const [initialTriggers, setInitialExistingTriggers] = useState();
	const [trackingMode, setTrackingMode] = useState(trackingModeToId(campaignDefault.TrackingMode));
	const [sidebarIsOpen, setSidebarIsOpen] = useState(true);

	// event tracking
	const [trackEventFeature, setTrackEventFeature] = useState('');
	const [pannelFeaturesIsOpen, setpannelFeaturesIsOpen] = useState(false);
	const [panelFormIsOpen, setPanelFormIsOpen] = useState(false);
	const [variationType, setvariationType] = useState('uniform');
	const [variationRangeIsEqual, setvariationRangeIsEqual] = useState(false);
	const [variationAbTestDraft, setvariationAbTestDraft] = useState();
	const [selectedStyleCustomId, setSelectedStyleCustomId] = useState();
	const getCurrentVariation = () =>
		currentVariationId &&
		variations.find((x) => x.UId === currentVariationId);
	// initialization
	const loadReferenceData = () => {
		const getAccountIpAddresses = () =>
			campaignCreatingServices
				.getAccountIPAddresses(accountId)
				.then((result) => {
					const testModeIpAddressesToMap = result.data.account.settings.testModeIpAddresses  !== null ? [...result.data.account.settings.testModeIpAddresses]: null;
					if(testModeIpAddressesToMap){
						return testModeIpAddressesToMap.map(
							(a) => ({ ...a, isDisabled: true })
						);
					}else{
						return [];
					}
				});

		getAccountIpAddresses().then((result) => {
			setReferenceData((data) => ({
				...data,
				accountIpAddresses: result,
				accountIpAddressesLoaded: true,
			}));
		}
		);
	};

	// translation
	const i18nL= localStorage.getItem('i18nL');
	const newInstanceI18next = i18next.createInstance();
	newInstanceI18next.init({
		lng: 'en-EN',
		resources: {
			'en-US': {
				common: campaign_react_en
			},
			'en-EN': {
				common: campaign_react_en
			},
			'fr-FR': {
				common: campaign_react_fr
			},
		}},
	(err) => {
		if (err) return console.log('something went wrong loading', err);
		newInstanceI18next.changeLanguage(JSON.parse(i18nL));
	});


	const refreshExternalAppSetupInfo = () => {
		getExternalAppSetupInfo(editorMode, setExternalAppSetup, () => setExternalAppSetup({hasErrorToGetSetupInfo: true}));
	};

	const applyUpdateCurrentVariation = (newVariation) =>
		updateCurrentVariation(() => ({ ...newVariation }));
	// font management

	// Fetch beyable fonts
	useEffect(() => {

		const set_fonts = async () => {
			if(fontData == null){
				const fonts = await getFontData();
				setFontData(fonts);
			}
		};
		set_fonts();
	},[]);


	// font management: set FontCss properties
	useEffect(() => {
		if (!fontData) return;

		const util = fontUtil(fontData);
		const difference = (a, b) => new Set([...a].filter(x => !b.has(x)));

		const findFontCss = (property) => {
			return property.Name === 'FontCss' ? property.Value.split(';') : [];
		};

		const extractFonts = (property) => {
			let valuesToProcess;
			if (property.IsGroup) {
				valuesToProcess = Object.values(property.Value);
			} else if (property.IsCollection) {
				valuesToProcess = property.Value.flatMap((item) => Object.values(item));
			} else {
				return util.extractFontImport(property.Value);
			}
			return valuesToProcess.flatMap(util.extractFontImport);
		};

		const getFontCssUpdates = (variation) => {
			const allFontCss = new Set(variation.Properties.flatMap(findFontCss).filter(x => !!x));
			const allExtractedFonts = new Set(variation.Properties.flatMap(extractFonts).filter(x => !!x));
			const notInFontCss = difference(allExtractedFonts, allFontCss);
			const notInExtractedFonts = difference(allFontCss, allExtractedFonts);

			if (notInExtractedFonts.size > 0 || notInFontCss.size > 0) {
				return [(function(v) {
					if (v.UId !== variation.UId) return v;
					const propToUpdate = [...v.Properties];
					const p = propToUpdate.find(x => x.Name === 'FontCss');
					if (p) {
						const alreadySetFonts = new Set(p.Value ? p.Value.split(';') : null);
						[...notInFontCss].forEach(item => alreadySetFonts.add(item));
						[...notInExtractedFonts].forEach(item => alreadySetFonts.delete(item));
						p.Value = [...alreadySetFonts].join(';');
						return {...v, Properties: propToUpdate};
					} else {
						const newProperties = [...propToUpdate, { Name: 'FontCss', Value: [...notInFontCss].join(';') }];
						return {...v, Properties: newProperties};
					}
				})];
			} else {
				return [];
			}
		};

		const fontCssUpdates = variations.flatMap(getFontCssUpdates);
		if (fontCssUpdates.length > 0){
			const tmpVariations = variations.map(v => fontCssUpdates.reduce((acc, x) => x(acc), v));
			setVariations(tmpVariations);
		}
	}, [variations]);


	useEffect(() => {
		loadReferenceData();
	}, []);

	const specialAccounts = ['0000000a-0000-0000-0000-000000000000', '0000000b-0000-0000-0000-000000000000', '0000000c-0000-0000-0000-000000000000'];
	useEffect(() => {
		setCanPublishStyles(specialAccounts.includes(accountId.toLowerCase()));
	}, [accountId]);

	// Custom mode
	function handleChangeEditorToLibraryCustom (){
		setEditorMode('custom');
		setEditorState((s) => ({
			...s,
			sideBarStep: 4,
		}));
		setCampaign(() => ({
			...campaign,
			Editor : 'targetedMessage/custom'
		}));
	}
	// Custom mode
	function handleChangeEditorToLibraryMobile(){
		// setEditorMode('appNative/patch/code');
		setEditorState((s) => ({
			...s,
			sideBarStep: 4,
		}));
	}
	useEffect(()=>{
		if(editorMode === 'custom'){
			const currentVariation = getCurrentVariation();
			if(currentVariation && currentVariation.TemplateType !== 'CUSTOM'){
				currentVariation.TemplateType = 'CUSTOM';
				applyUpdateCurrentVariation(currentVariation);
			}
		}
	},[currentVariationId,editorMode]);
	function handleChangePermissions (permissionsObjet) {
		setCampaign((s) => ({
			...campaign,
			DefaultPermissions : permissionsObjet
		}));
	}

	function handleCreateInPageForNativeApp(){
		const currentVariation = getCurrentVariation();
		currentVariation.TemplateDescriptorMobile.EditableTemplate.Content = '';
		currentVariation.TemplateDescriptorMobile.EditableTemplate.ContentTemplateType  = 'Fluid';
		currentVariation.InPage = {'InPageElementSelector': '',
			'InPagePosition': 'Above',};
		applyUpdateCurrentVariation(currentVariation);
	}

	function handleChangeElementsInCollection(elementToChange){
		const currentVariation = getCurrentVariation();
		currentVariation.InCollection.TargetedCollectionElementIds = elementToChange;
		applyUpdateCurrentVariation(currentVariation);
	}
	const handleUpdateJsonTemplate = (value) => {
		const currentVariation = getCurrentVariation();
		currentVariation.TemplateDescriptorMobile.EditableTemplate.Content = value;
		currentVariation.TemplateDescriptorMobile.EditableTemplate.ContentTemplateType  = 'Json';
		applyUpdateCurrentVariation(currentVariation);
	};
	function handleCreateInCollectionForNativeApp(){
		const currentVariation = getCurrentVariation();
		currentVariation.TemplateDescriptorMobile.EditableTemplate.Content = '';
		currentVariation.TemplateDescriptorMobile.EditableTemplate.ContentTemplateType  = 'Json';
		currentVariation.InCollection = {
			'PlacementSelector': '',
			'RelativePlacement': 'Above', // "Replace", "Above", "Right", "Left", "Below"
			'TargetedCollectionElementIds': []
		};
		applyUpdateCurrentVariation(currentVariation);
	}
	function handleChangeInCollection(key,elementToChange){
		const currentVariation = getCurrentVariation();
		if(currentVariation.SlideFormat === 'InCollection'){
			currentVariation.InCollection = currentVariation.InCollection ?? {};
			currentVariation.InCollection[key] = elementToChange;}
		else{
			currentVariation.InPage = currentVariation.InPage ?? {};
			currentVariation.InPage[key] = elementToChange;
		}
		applyUpdateCurrentVariation(currentVariation);
	}
	function handleChangeInPageOrientation(orientation){
		const currentVariation = getCurrentVariation();
		if(currentVariation.SlideFormat === 'InCollection'){
			currentVariation.InCollection = currentVariation.InCollection ?? {};
			currentVariation.InCollection.Orientation = orientation;}
		else{
			currentVariation.InPage = currentVariation.InPage ?? {};
			currentVariation.InPage.Orientation = orientation;
		}
		applyUpdateCurrentVariation(currentVariation);
	}
	function handleChooseCampaignFormat(format){
		const currentVariation = getCurrentVariation();
		if(format === 'InPage' && campaign.Editor === 'nativeApp/patch/code'){
			handleCreateInPageForNativeApp();
			currentVariation.InCollection = null;
		}
		if(format !== 'InPage'){
			handleUpdateInpage(null , '');
		}
		if(format === 'InCollection' && campaign.Editor === 'nativeApp/patch/code'){
			handleCreateInCollectionForNativeApp();
			currentVariation.InPage = null;
		}
		currentVariation.SlideFormat = format;
		applyUpdateCurrentVariation(currentVariation);
	}
	function handleChangeCallToAction(CallToAction){
		const currentVariation = getCurrentVariation();
		if(!currentVariation) return;
		currentVariation.Answer = {...currentVariation.Answer,
			CallToAction : CallToAction
		};
		applyUpdateCurrentVariation(currentVariation);
	}
	function handleDeleteFeature(typeDelete){

		const currentVariation = getCurrentVariation();
		if(!currentVariation) return;

		function applyUpdate (){
			applyUpdateCurrentVariation(currentVariation);
			// setpannelFeaturesIsOpen(false);
		}

		switch (typeDelete) {
			case 'reco':
				const Without_ShelvingRules = currentVariation.Properties.filter(el => el.Name !== 'ShelvingRules');
				currentVariation.Properties = [
					...Without_ShelvingRules,
					{IsCollection: 	false,
						IsGroup : false,
						Name: 'ShelvingRules',
						Value : ''
					}
				];
				return applyUpdate();
			case 'redirection':
				currentVariation.Answer = {...currentVariation.Answer,
					CallToAction : {
						Link: '',
						Text: ''
					}
				};
				return applyUpdate();
			case 'promoCode':
				currentVariation.Answer = {...currentVariation.Answer,
					ItemType: 'CallToAction',
					PromoCode: null,
				};
				currentVariation.Answer.ItemType = 'CallToAction';
				return applyUpdate();
			case 'productList':
				const WITHOUT_PRODUCTS = currentVariation.Properties.filter(el => el.Name !== 'PRODUCTS');
				currentVariation.Properties = [
					...WITHOUT_PRODUCTS,
					{
						IsGroup : false,
						Key:'5dad6ce2-af11-4b35-952c-09fd5a3e54e3',
						Name: 'PRODUCTS',
						Value : new ProductReferencesValue()}
				];

				return applyUpdate();
			case 'form':
				const Without_FormCustom = currentVariation.Properties.filter(el => el.Name !== 'FormCustom');
				currentVariation.Properties = [
					...Without_FormCustom
				];
				return applyUpdate();
			case 'formSurvey':
				const Without_FormSurveyCustom = currentVariation.Properties.filter(el => el.Name !== 'FormSurveyCustom');
				currentVariation.Properties = [
					...Without_FormSurveyCustom
				];
				applyUpdate();

		}

	}
	function handleOpenClosePanelFeatures(value){
		setpannelFeaturesIsOpen(value);
	}

	function handleOpenClosePanelForm(bool){
		setPanelFormIsOpen(bool);
	}
	function confirmFormData(data){
		handleOpenClosePanelForm(false);
		setFormCustomData(data);
	}
	function setFormCustomData(data){
		const currentVariation = getCurrentVariation();
		function applyUpdate (){
			applyUpdateCurrentVariation(currentVariation);
		}
		const Without_FormCustom = currentVariation.Properties.filter(el => el.Name !== 'FormCustom');
		currentVariation.Properties = [
			...Without_FormCustom,
			{
				IsCollection: 	false,
				IsGroup	: false,
				Name: 'FormCustom',
				Value : data
			}
		];
		applyUpdate();
	}
	function setFormSurveyCustomData(data){
		const currentVariation = getCurrentVariation();
		function applyUpdate (){
			applyUpdateCurrentVariation(currentVariation);
		}
		const Without_FormSurveyCustom = currentVariation.Properties.filter(el => el.Name !== 'FormSurveyCustom');
		currentVariation.Properties = [
			...Without_FormSurveyCustom,
			{
				IsCollection: 	false,
				IsGroup	: false,
				Name: 'FormSurveyCustom',
				Value : data
			}
		];
		applyUpdate();
	}
	useEffect(() => {
		if (!referenceData.accountIpAddressesLoaded) return;
		if ($routeParams.key) {
			getCampaignAndFormats(
				$routeParams.key,
				referenceData.accountIpAddresses,
				(campaignFromServer, formats) => {
					const campaign = { ...campaignFromServer, Features: defaultCampaignFeatures };
					const isCodePatch = campaign.Editor === 'patch/code';
					const isGraphPatch = campaign.Editor === 'patch/graph';
					const isBuilder = campaign.Editor === 'campaign/builder';
					const isModule = campaign.Editor.split('/')[1] === 'module';
					const moduleId = isModule && campaign.Editor.split('/')[2];
					const isLibrary = campaign.Editor.split('/')[1] === 'library';
					const isCustom = campaign.Editor === 'targetedMessage/custom';
					const isAbTest = campaign.Editor === 'abtest/redirection';
					const isMobile =  campaign.Editor === 'nativeApp/patch/code';
					const isNotificationCenter = campaign.Editor === 'notificationCenter/center';
					const isNotificationCenterNotification = campaign.Editor ==='notificationCenter/notification';

					const mode =
						isGraphPatch && 'patch/graph' ||
						isCodePatch && 'patch/code' ||
						isModule && 'module/' + moduleId ||
						isLibrary && 'library' ||
						isBuilder && 'campaign/builder' ||
						isCustom && 'custom' ||
						isAbTest && 'abTest' ||
						isMobile && 'appNative/patch/code' ||
						isNotificationCenter && 'notificationCenter/center' ||
						isNotificationCenterNotification && 'notificationCenter/notification';
					'unknown';

					setEditorMode(mode);
					setTrackEventFeature(isGraphPatch && 'patch' || isCodePatch && 'code-patch' || isModule && 'ready' || isLibrary && 'saas-library' || isBuilder && 'campaign/builder' || 'campaign-other');

					if (isModule || isLibrary || isNotificationCenter || isNotificationCenterNotification) {
						setAvailableFormats({ formats: formats, loaded: true });
					} else setAvailableFormats({ formats: [], loaded: true });

					setCampaign(campaign);
					setMemoInitialCampaignState(cloneDeep(campaign));

					const slide = {
						...campaign.Slide,
						IsOriginalVariation: true,
						VariationType:
							campaign.Slide.Variations.length > 0
								? campaign.Slide.Variations[0].VariationType
								: variationItemDefault.VariationType,
					};
					if(slide.VariationType === 'Range'){
						setvariationType('Range');
					}else if(slide.VariationType === 'PageTag'){
						setvariationType('PageTag');
					}
					const getVariations = () => {
						let initialVariations = [
							slide,
							...campaign.Slide.Variations,
						];
						if (initialVariations.length > 1) {
							setIsABTestingActive(true);
							initialVariations =
								slide.VariationType === 'Range'
									? initOriginalRangeSize(initialVariations)
									: initialVariations;
						}
						return initialVariations;
					};
					const tmpVariations = getVariations();

					setVariations(tmpVariations);

					if(isAbTest){
						checkVariationType([...tmpVariations]);

					}
					setCurrentVariationId(slide.UId);

					setTestGroupPercent(100 - campaign.ControlGroupSplitValue);
					setTrackingMode(trackingModeToId(campaign.TrackingMode));
					setInitialExistingTriggers(campaign.Specifications);
				}
			);
		} else {

			const isModule = !!$routeParams.module;
			const isCodePatch = !!$routeParams.patch;
			const isGraphPatch = !!$routeParams.graph;
			const isBuilder = !!$routeParams.builder;
			const moduleId = $routeParams.module;
			const isAbTest = $routeParams.abtest;
			const isMobile = $routeParams.appNative;
			const isNotificationCenter = $routeParams.notificationCenter;
			const isNotificationCenterNotification = $routeParams.notificationCenterNotification;

			handleChangeEditorToLibraryMobile();

			setEditorMode(isGraphPatch && 'patch/graph' ||
				isCodePatch && 'patch/code' ||
				isBuilder && 'campaign/builder' ||
				isModule && 'module/' + moduleId ||
				isAbTest && 'abTest' ||
				isMobile && 'appNative/patch/code' ||
				isNotificationCenter && 'notificationCenter/center' ||
				isNotificationCenterNotification && 'notificationCenter/notification' ||
				'library'
			);
			setTrackEventFeature(isGraphPatch && 'patch' ||
				isCodePatch && 'code-patch' ||
				isBuilder && 'builder' ||
				isModule && 'ready' ||
				isAbTest && 'abTest' ||
				isMobile && 'appName' ||
				(isNotificationCenter || isNotificationCenterNotification) && 'notificationCenter' ||
				'saas-library'
			);

			const mergeTriggers = (t1s, t2s) =>
				t2s.reduce(
					(acc, cur) => {
						if (!t1s.find((x) => x.Name === cur.Name)) {
							return [...acc, cur];
						}
						return acc;
					},
					[...t1s]
				);

			const addDefaultTriggers = (triggers, defaultTriggers) => {
				if (triggers.length == 0) {
					return defaultTriggers.map((x) => ({ ...x, Group: {} }));
				}
				const triggersByGroup = groupBy(
					triggers,
					(t) => (t.Group || {}).Id
				);
				const newTriggers = Object.keys(triggersByGroup).flatMap(
					function (key) {
						const triggersInGroup = triggersByGroup[key];
						const group = triggersInGroup[0].Group;
						return mergeTriggers(
							triggersInGroup,
							defaultTriggers.map((x) => ({ ...x, Group: group }))
						);
					}
				);
				return newTriggers;
			};

			const setupCampaignAndVariations = (module,cpg) => {
				const campaignToMerge = cpg ? cpg : campaign;
				const newCampaign = {
					...campaignToMerge,
					TestModeIpAddresses: referenceData.accountIpAddresses.map(
						(x) => ({ ...x, id: uuidv4() })
					),
					CampaignType: isGraphPatch || isCodePatch ? 'Patch' : cpg.CampaignType,
					Editor: isCodePatch ? 'patch/code' : isGraphPatch ? 'patch/graph' : isBuilder ? 'campaign/builder' : cpg.Editor,
				};

				let editableTemplateDescriptorDesktop = newCampaign.Slide.TemplateDescriptorDesktop;
				let editableTemplateDescriptorMobile = newCampaign.Slide.TemplateDescriptorMobile;
				let slideFormat = newCampaign.Slide.SlideFormat;
				if (isGraphPatch || isCodePatch) {
					editableTemplateDescriptorDesktop.EditableTemplate.ContentTemplateType = 'Fluid';
					editableTemplateDescriptorDesktop.EditableTemplate.Content = '<style>{{Css}}</style>';
					editableTemplateDescriptorMobile.EditableTemplate.ContentTemplateType = 'Fluid';
					editableTemplateDescriptorMobile.EditableTemplate.Content = '<style>{{Css}}</style>';
					slideFormat = 'Overlay';
				}

				let slideType = campaign.Slide.SlideType;
				if (isGraphPatch || isCodePatch) slideType = 'Patch';
				if (isBuilder) slideType = 'Builder';

				let templateType = campaign.Slide.TemplateType;
				if (isBuilder || isAbTest) templateType = 'CUSTOM';

				const slide = {
					...newCampaign.Slide,
					SlideFormat: slideFormat,
					TemplateDescriptorDesktop: editableTemplateDescriptorDesktop,
					TemplateDescriptorMobile: editableTemplateDescriptorMobile,
					IsOriginalVariation: true,
					VariationType: variationItemDefault.VariationType,
					TemplateType : templateType,
					SlideType: slideType,
				};

				if(isAbTest){
					slide.DesignProperties =  [designPropertiesAbTestRedirection];
					newCampaign.Editor = 'abtest/redirection';
					//newCampaign.UrlDebug = 'Enter a page rerefence';
					slide.SlideType = 'Patch';
					const TemplateDescriptorDesktop = {
						EditableCss : '',
						EditableJavascript: '',
						EditableDisplayCondition:null,
						EditableTemplate : {
							Content : htmlTypeVariationAbTestRedirection,
							ContentTemplateType : 'Fluid'
						},
					};
					const TemplateDescriptorMobile = {
						EditableCss : '',
						EditableJavascript: '',
						EditableDisplayCondition:null,
						EditableTemplate : {
							Content : htmlTypeVariationAbTestRedirection,
							ContentTemplateType : 'Fluid'
						}
					};
					//newCampaign.UrlDebug = 'Enter a page rerefence';
					slide.TemplateDescriptorDesktop = TemplateDescriptorDesktop;
					slide.TemplateDescriptorMobile = TemplateDescriptorMobile;
				}
				setCampaign(newCampaign);
				setVariations([slide]);
				setCurrentVariationId(slide.UId);
				const triggers = module
					? addDefaultTriggers(
						module.triggers,
						newCampaign.Specifications
					)
					: newCampaign.Specifications;
				setInitialExistingTriggers(triggers);
			};
			if (isModule) {
				const loadFormats = (successCallback) =>
					getModuleFormats(moduleId, successCallback, (err) => {
						console.log(err);
						systemServices.showError('an error occured');
					});
				loadFormats((result) => {
					const module = result.find((x) => x.id === moduleId);
					setAvailableFormats({
						formats: module ? module.formats : [],
						loaded: true,
					});
					setupCampaignAndVariations(module,campaign);
				});
			} else if(isMobile){

				const newCampaign = {
					...campaign,
					TestModeIpAddresses: referenceData.accountIpAddresses.map(
						(x) => ({ ...x, id: uuidv4() })
					),
					CampaignType:  campaign.CampaignType,
					Editor: 'nativeApp/patch/code',
					TechnicalPurpose : 4,

				};
				let slideFormat = newCampaign.Slide.SlideFormat;
				let slideType = 'PatchNativeApp';
				let templateType = 'CUSTOM';
				let editableTemplateDescriptorDesktop = newCampaign.Slide.TemplateDescriptorDesktop;
				let editableTemplateDescriptorMobile = newCampaign.Slide.TemplateDescriptorMobile;
				const slide = {
					...newCampaign.Slide,
					SlideFormat: slideFormat,
					TemplateDescriptorDesktop: editableTemplateDescriptorDesktop,
					TemplateDescriptorMobile: editableTemplateDescriptorMobile,
					IsOriginalVariation: true,
					VariationType: variationItemDefault.VariationType,
					TemplateType : templateType,
					SlideType: slideType,
				};
				setVariations([slide]);
				const loadFormats = (successCallback) =>
					getAvailableFormats(
						{ editor: 'nativeApp/patch/code' },
						successCallback,
						(err) => {
							console.log(err);
							systemServices.showError('an error occured');
						}
					);
				loadFormats((result) => {
					setAvailableFormats({ formats: result, loaded: true });

				});
				setCampaign(newCampaign);
				setCurrentVariationId(slide.UId);
				handleChangeEditorToLibraryMobile();
				const triggers = module
					? addDefaultTriggers(
						module.triggers,
						newCampaign.Specifications
					)
					: newCampaign.Specifications;
				setInitialExistingTriggers(triggers);

			} else if (isNotificationCenter) {
				const newCampaign = {
					...campaign,
					TestModeIpAddresses: referenceData.accountIpAddresses.map(
						(x) => ({ ...x, id: uuidv4() })
					),
					CampaignType: campaign.CampaignType,
					Editor: 'notificationCenter/center',

					TechnicalPurpose: 5,
					EndDateUtc: null,
					EndDateInTimezone: null,
					HasEndDate: false,
				};
				setCampaign(newCampaign);
				const loadFormats = (successCallback) =>
					getAvailableFormats(
						{ editor: newCampaign.Editor },
						successCallback,
						(err) => {
							console.log(err);
							systemServices.showError('an error occured');
						}
					);
				loadFormats((result) => {
					setAvailableFormats({ formats: result, loaded: true });
					setupCampaignAndVariations(null,newCampaign);
				});
			} else if (isNotificationCenterNotification) {

				const newCampaign = {
					...campaign,
					TestModeIpAddresses: referenceData.accountIpAddresses.map(
						(x) => ({ ...x, id: uuidv4() })
					),
					CampaignType: campaign.CampaignType,
					Editor: 'notificationCenter/notification',
					TechnicalPurpose: 6,
				};
				setCampaign(newCampaign);
				const loadFormats = (successCallback) =>
					getAvailableFormats(
						{ editor: newCampaign.Editor },
						successCallback,
						(err) => {
							console.log(err);
							systemServices.showError('an error occured');
						}
					);
				loadFormats((result) => {
					setAvailableFormats({ formats: result, loaded: true });
					setupCampaignAndVariations(null,newCampaign);
				});
			} else {

				const styleId = $routeParams.styleId;
				if(hasCustomKey ){
					getAvailableFormats(
						{ editor: defaultEditor },
						(result)=>{
							setupCampaignAndVariations(module,campaign);
							const availableF = [...result];
							const findTemplate = availableF.find(t => t.Key === hasCustomKey);
							if(findTemplate){
								handleFormatSelected(findTemplate);
								getFormatSavedStyles(
									hasCustomKey,
									findTemplate,
									result=>{

										const styleSelected = styleId !== 'default' ? result.find(st => st.id == styleId) : {
											id : 'default',
											properties: findTemplate.defaultStyle.properties
										};

										setSelectedStyleCustomId(styleSelected);
										setEditorState((s) => ({
											...s,
											defaultStyle: {
												id : 'default',
												properties: findTemplate.defaultStyle.properties
											},
											findTemplate: {
												isNotSaved :true,
												style: {
													id: 'current',
													properties:styleSelected.properties
												}
											},
											selectedFormat:findTemplate,
											selectedStyle:styleSelected,
											sideBarStep: 2,
										}));

									}
								);
							}
							setAvailableFormats({ formats: result, loaded: true });
						},
						(err) => {
							console.log(err);
							systemServices.showError('an error occured');
						});

				}else{
					const loadFormats = (successCallback) =>
						getAvailableFormats(
							{ editor: defaultEditor },
							successCallback,
							(err) => {
								console.log(err);
								systemServices.showError('an error occured');
							}
						);
					loadFormats((result) => {
						setAvailableFormats({ formats: result, loaded: true });
						setupCampaignAndVariations(module,campaign);
					});
					
				}
			}
		}

		setIsReady(true);
	}, [referenceData]);

	useEffect(() => {
		refreshExternalAppSetupInfo();
	}, [editorMode]); // should know which module we are working with, that is why this effect is used

	useEffect(() => {
		const shouldDisplayBackToFormat = !isModuleEditorMode();
		setDisplayBackToFormat(shouldDisplayBackToFormat);

		const shouldDisplayBackToStyle = !isModuleEditorMode() || (formatSavedStyles && formatSavedStyles.length > 0);
		setDisplayBackToStyle(shouldDisplayBackToStyle);

	}, [editorMode, formatSavedStyles]);

	useEffect(() => {
		setNoAvailableFormat(
			availableFormats.loaded &&
			availableFormats.formats.length === 0 &&
			editorMode !== 'patch/code' &&
			editorMode !== 'patch/graph'
		);
	}, [availableFormats]);

	// change variation
	useEffect(() => {
		const currentVariation = getCurrentVariation();

		if (!currentVariation) return;

		const setDefaultEditorStateWithNoFormat = () => {
			setEditorState((s) => ({
				...s,
				selectedStyle: {},
				currentWysiwyg: {},
				selectedFormat: {},
				sideBarStep: 1,
			}));
		};

		const setInitialEditorStateForFormat = (format) => {
			const mergeProperties = format.defaultStyle.properties.map(
				(p) => currentVariation.Properties.find((x) => x.Name === p.Name) || p
			);
			const style = {
				id: 'current',
				properties: mergeProperties,
			};

			const newFormat = {
				...format,
				defaultStyle: {
					...format.defaultStyle,
					properties: mergeProperties,
				},
			};

			const update = (s) => ({
				...s,
				currentWysiwyg:
					currentDevice === 'desktop'
						? newFormat.WysiwygDesktop
						: newFormat.WysiwygMobile,
				selectedFormat: newFormat,
			});

			const {
				updateEditorState: updateEditorFromFormat,
				updateVariation: updateVariationFromFormat,
			} = handleFormatChosenWith(format, true);

			const isCreationFromModule = mode === 'creating' && isModuleEditorMode();
			const shouldSkipStyle = !isCreationFromModule;

			const {
				updateEditorState: updateEditorFromStyle,
				updateVariation: updateVariationFromStyle,
			} = handleStyleChosenWith(style, shouldSkipStyle);

			updateCurrentVariation(
				flow([
					(s) => ({ ...s, CssToInitialize: false }),
					updateVariationFromFormat,
					updateVariationFromStyle,
				])
			);
			setEditorState(
				flow([update, updateEditorFromFormat, updateEditorFromStyle])
			);
		};

		const restoreEditorState = (editorState) => {
			const format = editorState.selectedFormat;
			if (!format || !format.defaultStyle)
				return setDefaultEditorStateWithNoFormat();

			const update = (s) => ({
				...s,
				currentWysiwyg:
					currentDevice === 'desktop'
						? format.WysiwygDesktop
						: format.WysiwygMobile,
				selectedFormat: format,
				sideBarStep: editorState.sideBarStep || 1,
			});

			const noUpdate = {
				updateEditorState: (s) => s,
				updateVariation: (s) => s,
			};
			const {
				updateEditorState: updateEditorFromFormat,
				updateVariation: updateVariationFromFormat,
			} = handleFormatChosenWith(format, false);
			const {
				updateEditorState: updateEditorFromStyle,
				updateVariation: updateVariationFromStyle,
			} = (editorState.selectedStyle || {}).id
				? handleStyleChosenWith(editorState.selectedStyle, false)
				: noUpdate;
			updateCurrentVariation(
				flow([updateVariationFromFormat, updateVariationFromStyle])
			);
			setEditorState(
				flow([update, updateEditorFromFormat, updateEditorFromStyle])
			);
		};

		const currentVariationEditorState = currentVariation.EditorState;
		if (currentVariationEditorState) {
			restoreEditorState(currentVariationEditorState);
			return;
		}

		const format = availableFormats.formats.find(
			(item) => item.Identifier === currentVariation.TemplateName
		);
		if (format) {
			setInitialEditorStateForFormat(format);
			return;
		}

		if (isModuleEditorMode()) {
			const moduleFormat = availableFormats.formats[0]; // only one format for now.
			if (moduleFormat) {
				setInitialEditorStateForFormat(moduleFormat);
				return;
			}
		}

		setDefaultEditorStateWithNoFormat();
	}, [currentVariationId]);

	useEffect(() => {
		setEditorState((s) => ({
			...s,
			currentWysiwyg:
				currentDevice === 'desktop'
					? editorState.selectedFormat.WysiwygDesktop
					: editorState.selectedFormat.WysiwygMobile,
		}));
	}, [currentDevice]);

	useEffect(() => {
		if (editorState.sideBarStep === 2 ) {
			loadSavedStylesForFormat(
				editorState.selectedFormat.Key,
				(result) => {

					if (editorState.currentStyle && result.length < 1) {
						// skip step 2 if no style and is module editor
						if (isModuleEditorMode()) {
							setEditorState((s) => ({ ...s, sideBarStep: 3 }));
						}
					} else if (editorState.currentStyle && editorState.currentStyle.style) {
						// warning: by default, tinymce rich text editor wraps content in a <p></p> if the content has no root.
						// so it is possible this logic won't work in some cases. Hence, existing style will not be restored as selected.

						if(hasStyleId && firstRenderAndChoiceOfStyle){
							const styleSelected = result.find(st => st.id == hasStyleId);

							if(styleSelected){
								setEditorState((s) => ({
									...s,
									currentStyle: null,
									selectedStyle: styleSelected,
								}));
								setfirstRenderAndChoiceOfStyle(false);
								setstyleSelectWithoutClick(true);
							}else{
								const sameStyle = [
									editorState.defaultStyle,
									...result,
								].find((x) =>
									areSameStyles(
										x.properties,
										editorState.currentStyle.style.properties
									)
								);
								setEditorState((s) => ({
									...s,
									currentStyle: null,
									selectedStyle: sameStyle,
									sideBarStep: 3
								}));
								setstyleSelectWithoutClick(true);
								setfirstRenderAndChoiceOfStyle(false);
							}
						}
						else{
							const sameStyle = [
								editorState.defaultStyle,
								...result,
							].find((x) =>
								areSameStyles(
									x.properties,
									editorState.currentStyle.style.properties
								)
							);
							if (
								sameStyle &&
								editorState.selectedStyle.id !== sameStyle.id
							) {
								setEditorState((s) => ({
									...s,
									currentStyle: null,
									selectedStyle: sameStyle,
								}));
							}

						}
					}else{

						if(hasStyleId && firstRenderAndChoiceOfStyle){

							//
							// const { updateEditorState, updateVariation } = handleStyleChosenWith(
							// 	properties,
							// 	true
							// );
							// updateCurrentVariation(updateVariation);
							// setEditorState((s) => updateEditorState(s));
							handleFormatChosen();
							setstyleSelectWithoutClick(true);
							setfirstRenderAndChoiceOfStyle(false);
							
						}

					}
				}
			);
		}
		if (editorState.sideBarStep === 3 ) {
			loadSavedStylesForFormat(
				editorState.selectedFormat.Key,
				null
			);
		}
	}, [editorState.sideBarStep]);
	function handleStyleChosenCustomId() {
		if (!selectedStyleCustomId.id)
			return;

		const { updateEditorState, updateVariation } = handleStyleChosenWith(
			selectedStyleCustomId,
			true
		);
		updateCurrentVariation(updateVariation);
		setEditorState((s) => updateEditorState(s));
	}
	useEffect(() => {
		if(styleSelectWithoutClick && hasStyleId && hasCustomKey){
			setstyleSelectWithoutClick(false);
			handleStyleChosenCustomId();
		}else if(styleSelectWithoutClick){
			setstyleSelectWithoutClick(false);
			handleStyleChosen();
		}
	}, [styleSelectWithoutClick]);
	useEffect(() => {
		const currentVariation = getCurrentVariation();
		if (!currentVariation) return;

		if (editorState.sideBarStep === 3) {
			refreshMatchedExistingStyle(currentVariation.Properties);
		}
	}, [editorState, variations]);


	useEffect(() => {
		const variationsToTest =  variations;
		if(editorMode === 'custom'){
			const { isValid: isFormatValid } = validateCustomData(variationsToTest, []);
			setVariationsProperlySet(isFormatValid);
			return;
		}
		if(editorMode === 'abTest'){
			const { isValid } = validateFormatDataAbtest(variationsToTest, []);
			const urlIsOk = campaign.UrlDebug !== '';
			setVariationsProperlySet(isValid && urlIsOk, variations.length > 1);
			return;
		}
		if (editorMode === 'patch/graph' || editorMode === 'patch/code' || editorMode === 'campaign/builder') {
			setVariationsProperlySet(true);
			return;
		}
		if(editorMode !== undefined && editorMode !== 'abTest' && editorMode !== 'custom'){
			const { isValid: isFormatValid } = validateFormatData(variationsToTest, []);
			const { isValid: isEditorStateValid } = mode === 'creating' ? validateEditorState(variationsToTest, []) : { isValid: true };
			setVariationsProperlySet(isFormatValid && isEditorStateValid);
		}
	}, [variations, editorMode, campaign]);

	// useEffect(() => {
	// 	if (step === 2) {
	// 		verifyTriggersData().then((isValid) =>
	// 			setTriggersProperlySet(isValid)
	// 		);
	// 	}
	// 	if (step === 3) {
	// 		verifyInformationsData().then((isValid) =>
	// 			setInformationsProperlySet(isValid)
	// 		);
	// 	}
	// }, [campaign, step]);
	useEffect(() => {
		// for validation in all mode
		if(editorMode !== 'custom' && editorMode !== 'abTest'){

			setTriggersProperlySet(verifyTriggersData());
			setInformationsProperlySet(verifyInformationsData());
			setVariationsProperlySet(verifyFormatData());
		}
	}, [campaign, step]);

	useEffect(() => {
		const notNotification = !['notificationCenter/notification', 'notificationCenter/center'].includes(editorMode);
		setDisplayAbTestSettings(notNotification);
		setDisplayControlGroupSettings(notNotification);
	}, [campaign]);

	useEffect(() => {
		updateCurrentVariationEditorState(editorState);
	}, [editorState]);

	useEffect(() => {

		if (mode === 'edition') {

			if(editorMode !== 'abTest' && editorMode !== 'appNative/patch/code'){
				setCanSaveEdition(variationsProperlySet && triggersProperlySet && informationsProperlySet);
			}

			else{
				if(editorMode !== 'appNative/patch/code'){
					setCanSaveEdition(variationsProperlySet && triggersProperlySet && informationsProperlySet && variations.length > 1);
				}
			}
		} else {
			const canGo = getCanGoNextOrSave();
			setCanGoNextOrSave(canGo);
		}
	}, [variationsProperlySet, triggersProperlySet, informationsProperlySet, variations]);
	const getCanGoNextOrSave = () => {
		if (editorMode === 'patch/graph' || editorMode === 'patch/code' || editorMode === 'campaign/builder' || editorMode === 'appNative/patch/code' ) {
			return true;
		} else {
			if(editorMode !== 'abTest'){
				return variationsProperlySet && triggersProperlySet && informationsProperlySet;
			}else{
				return variationsProperlySet && triggersProperlySet && informationsProperlySet && variations.length > 1;
			}
		}
	};



	const updateCurrentVariation = (updateFunction) => {
		const newVariations = variations.map((x) =>
			x.UId === currentVariationId ? updateFunction(x) : x
		);
		setVariations(newVariations);
	};

	const isNotVariationSavedStyle = (style) => {
		if (!memoInitialCampaignState) return true;
		const currentVariation = getCurrentVariation();
		if (!currentVariation) return console.log('Current variation not set');

		const isOriginal = currentVariation.IsOriginalVariation;
		if (isOriginal)
			return areSameStyles(
				style.properties,
				memoInitialCampaignState.Slide.Properties
			);

		const initialVariation = (
			memoInitialCampaignState.Slide.Variations || []
		).find((v) => v.Identifier === currentVariation.Identifier); // TODO to Test
		return (
			initialVariation &&
			areSameStyles(style.properties, initialVariation.Properties)
		);
	};

	const refreshMatchedExistingStyle = (properties, savedStyles) => {
		const matchStyle = [
			editorState.defaultStyle,
			...(savedStyles || formatSavedStyles || []),
		].find((x) => areSameStyles(x.properties, properties));
		setMatchedExistingStyle(matchStyle);
	};

	const updateCurrentVariationEditorState = (value) => {
		const currentVariation = getCurrentVariation();
		if (!currentVariation) return;
		const newVariation = {
			...currentVariation,
			EditorState: cloneDeep(value) || {},
		};
		updateCurrentVariation(() => newVariation);
	};

	const handleFormatSelected = (format) => {
		setEditorState((s) => ({
			...s,
			currentWysiwyg:
				currentDevice === 'desktop'
					? format.WysiwygDesktop
					: format.WysiwygMobile,
			selectedFormat: format,
		}));
	};

	const handleFormatUnselected = () => {
		setEditorState((s) => ({
			...s,
			currentWysiwyg: {},
			selectedFormat: {},
		}));
	};

	const handleFormatChosen = () => {
		if (!editorState.selectedFormat.Identifier)
			return console.log('Format not set');

		const { updateEditorState, updateVariation } = handleFormatChosenWith(
			editorState.selectedFormat,
			true,
			true
		);
		updateCurrentVariation(updateVariation);
		setEditorState(updateEditorState);
	};

	const mergeEditablePropertiesIntoFormat = (
		f,
		variation,
		cssToInitialize
	) => {
		return {
			...f,
			SlideTemplateDescriptorDesktop: {
				...f.SlideTemplateDescriptorDesktop,
				EditableCss:
					mode === 'creating' ||
						variation.CssToInitialize ||
						cssToInitialize
						? f.SlideTemplateDescriptorDesktop.Css
						: variation.TemplateDescriptorDesktop.EditableCss,
				EditableTemplate:
					variation.TemplateDescriptorDesktop.EditableTemplate,
				EditableJavascript: editorMode === 'patch/code'
					? variation.TemplateDescriptorDesktop.EditableJavascript
					: '',
			},
			SlideTemplateDescriptorMobile: {
				...f.SlideTemplateDescriptorMobile,
				EditableCss:
					mode === 'creating' ||
						variation.CssToInitialize ||
						cssToInitialize
						? f.SlideTemplateDescriptorMobile.Css
						: variation.TemplateDescriptorMobile.EditableCss,
				EditableTemplate:
					variation.TemplateDescriptorMobile.EditableTemplate,
				EditableJavascript: editorMode === 'patch/code'
					? variation.TemplateDescriptorMobile.EditableJavascript
					: '',
			},
		};
	};

	const handleFormatChosenWith = (format, goToNext, cssToInitialize) => {
		const updateVariationFromFormat = (variation) => {
			const mergedFormat = mergeEditablePropertiesIntoFormat(
				format,
				currentVariation,
				cssToInitialize
			);
			return {
				...variation,
				TemplateName: mergedFormat.Identifier,
				SlideFormat: mergedFormat.Format,
				TemplateDescriptorDesktop:
					mergedFormat.SlideTemplateDescriptorDesktop,
				TemplateDescriptorMobile:
					mergedFormat.SlideTemplateDescriptorMobile,
				Template: {
					...mergedFormat,
					Properties: mergedFormat.Properties,
				},
				Properties: mergedFormat.Properties.map(
					templatePropertyToStyleProperty
				),
			};
		};

		const currentVariation = getCurrentVariation();
		const formatHasNotChanged =
			currentVariation.TemplateName === format.Identifier;
		const shouldRestoreCurrentStyle =
			formatHasNotChanged && !!(editorState.selectedStyle || {}).id;

		const newDefaultStyle = {
			id: 'default',
			properties: format.defaultStyle.properties,
		};
		const newEditorStateStyles = (s) =>
			shouldRestoreCurrentStyle
				? s
				: {
					...s,
					defaultStyle: newDefaultStyle,
					selectedStyle: newDefaultStyle,
					currentStyle: null,
				};
		const newStep = (s) => (goToNext ? { ...s, sideBarStep: 2 } : s);
		const updateVariation = formatHasNotChanged
			? (s) => s
			: updateVariationFromFormat;

		return {
			updateEditorState: flow([newEditorStateStyles, newStep]),
			updateVariation,
		};
	};

	function handleStyleSelected(style) {
		setEditorState((s) => ({ ...s, selectedStyle: style }));
	}

	function handleStyleDeleted(style) {
		const currentVariation = getCurrentVariation();
		const templateId = currentVariation.Template.Key;

		deleteSavedStyle(templateId, style.id, () => {
			setFormatSavedStyles(styles => styles.filter(x => x.id !== style.id));
		}, error => {
			console.log(error); systemServices.showError('An error occured while deleting the style.');
		});
	}

	function handleStylePublished(style, isPublished) {
		const currentVariation = getCurrentVariation();
		const templateId = currentVariation.Template.Key;

		publishSavedStyle(templateId, style.id, isPublished, () => {
			setFormatSavedStyles(styles => styles.map(s => s.id === style.id ? { ...s, isPublished: isPublished } : s));
		}, error => {
			console.log(error); systemServices.showError('An error occured while publishing the style.');
		});
	}

	function handleStyleChosen() {
		if (!editorState.selectedStyle.id)
			return;

		const { updateEditorState, updateVariation } = handleStyleChosenWith(
			editorState.selectedStyle,
			true
		);
		updateCurrentVariation(updateVariation);
		setEditorState((s) => updateEditorState(s));
	}
	function handleStyleChosenWith(style, goToNext) {
		const properties = cloneDeep(style.properties);
		const updateVariation = (variation) => ({
			...variation,
			SavedStyle: style.id,
			Properties: properties,
		});

		const newCurrentStyle = {
			...style,
			id: 'current',
			properties: properties,
		};
		const newEditorStateStyles = (s) => ({
			...s,
			currentStyle: {
				isNotSaved: isNotVariationSavedStyle(style),
				style: { ...newCurrentStyle },
			},
			selectedStyle: newCurrentStyle,
		});

		const newStep = (s) => (goToNext ? { ...s, sideBarStep: 3 } : s);
		return {
			updateEditorState: flow([newEditorStateStyles, newStep]),
			updateVariation,
		};
	}

	function handleChangeVariation(id) {
		setCurrentVariationId(id);
	}
	const handleUpdateCssResponsive = (value) => {
		const currentVariation = getCurrentVariation();
		if (!toggleResponsiveValue) return;
		currentVariation.TemplateDescriptorDesktop.EditableCss = value;
		currentVariation.TemplateDescriptorMobile.EditableCss = value;
		applyUpdateCurrentVariation(currentVariation);
	};
	const handleUpdateHtmlResponsive = (value) => {
		const currentVariation = getCurrentVariation();
		if (!toggleResponsiveValue) return;
		currentVariation.TemplateDescriptorDesktop.EditableTemplate.Content = value;
		currentVariation.TemplateDescriptorMobile.EditableTemplate.Content = value;
		applyUpdateCurrentVariation(currentVariation);
	};


	const handleUpdateJsResponsive = (value) => {
		const currentVariation = getCurrentVariation();
		if (!toggleResponsiveValue) return;
		currentVariation.TemplateDescriptorDesktop.EditableJavascript = value;
		currentVariation.TemplateDescriptorMobile.EditableJavascript = value;
		applyUpdateCurrentVariation(currentVariation);
	};
	const handleUpdateDisplayConditionResponsive = (value) => {
		const currentVariation = getCurrentVariation();
		if (!toggleResponsiveValue) return;
		currentVariation.TemplateDescriptorDesktop.EditableDisplayCondition = value;
		currentVariation.TemplateDescriptorMobile.EditableDisplayCondition = value;
		applyUpdateCurrentVariation(currentVariation);
	};

	const handleSwitchResponsive = (value) => {
		const currentVariation = getCurrentVariation();
		if (!value) return;
		let desktop = currentVariation.TemplateDescriptorDesktop;
		let mobile = currentVariation.TemplateDescriptorMobile;
		mobile.EditableCss = desktop.EditableCss;
		mobile.EditableJavascript = desktop.EditableJavascript;
		mobile.EditableTemplate.Content = desktop.EditableTemplate.Content;
		applyUpdateCurrentVariation(currentVariation);
	};

	const handleResetResponsive = (cssValue, jsValue,htmlValue) => {
		const currentVariation = getCurrentVariation();
		if (!currentVariation) return;
		if (toggleResponsiveValue) return;
		const descriptor = currentVariation.TemplateDescriptorMobile;
		descriptor.EditableCss = cssValue;
		descriptor.EditableJavascript = jsValue;
		descriptor.EditableTemplate.Content = htmlValue;
		applyUpdateCurrentVariation(currentVariation);

	};
	const handleUpdateCss = (value) => {
		const currentVariation = getCurrentVariation();
		if (!currentVariation) return;
		const descriptor =
			currentDevice === 'desktop'
				? currentVariation.TemplateDescriptorDesktop
				: currentVariation.TemplateDescriptorMobile;
		descriptor.EditableCss = value;
		applyUpdateCurrentVariation(currentVariation);
	};
	const handleUpdateHtml = (value) => {
		const currentVariation = getCurrentVariation();
		if (!currentVariation) return;
		const descriptor =
			currentDevice === 'desktop'
				? currentVariation.TemplateDescriptorDesktop.EditableTemplate
				: currentVariation.TemplateDescriptorMobile.EditableTemplate;
		// const key = currentDevice === 'desktop' ? 'TemplateDescriptorDesktop' : 'TemplateDescriptorMobile';
		descriptor.Content = value;
		applyUpdateCurrentVariation(currentVariation);
	};
	const handleUpdateDesktopDisplayConditions = (value) => {
		const currentVariation = getCurrentVariation();
		if (!currentVariation) return;
		const descriptor =
			currentDevice === 'desktop'
				? currentVariation.TemplateDescriptorDesktop
				: currentVariation.TemplateDescriptorMobile;
		// const key = currentDevice === 'desktop' ? 'TemplateDescriptorDesktop' : 'TemplateDescriptorMobile';
		descriptor.EditableDisplayCondition = value;
		applyUpdateCurrentVariation(currentVariation);
	};

	const handleUpdateInpage = (value, urlDebug, inPageEditor) => {
		const currentVariation = getCurrentVariation();
		if (!currentVariation) return;

		if (urlDebug !== null) {
			const oldurlDebugProp = currentVariation.Properties.find(p => p.Name === 'InpageUrl');
			if (oldurlDebugProp) {
				oldurlDebugProp.Value = urlDebug;
			} else {
				currentVariation.Properties.push({
					Name: 'InpageUrl',
					Value: urlDebug
				});
			}
		}

		if (inPageEditor !== null) {
			const oldinPageEditorProp = currentVariation.Properties.find(p => p.Name === 'InPageEditor');
			if (oldinPageEditorProp) {
				oldinPageEditorProp.Value = inPageEditor;
			} else {
				currentVariation.Properties.push({
					Name: 'InPageEditor',
					Value: inPageEditor
				});
			}
		}
		currentVariation.SlideFormat = 'InPage';
		currentVariation.InPage = value;
		applyUpdateCurrentVariation(currentVariation);
		const urlDebugNew = urlDebug !== '' ? urlDebug : campaign.UrlDebug;
		let newCampaign = { ...campaign, Slide: currentVariation, UrlDebug: urlDebugNew };
		setCampaign(newCampaign);

	};
	const handleUpdateJs = (value) => {
		const currentVariation = getCurrentVariation();
		if (!currentVariation) return;
		const descriptor =
			currentDevice === 'desktop'
				? currentVariation.TemplateDescriptorDesktop
				: currentVariation.TemplateDescriptorMobile;
		descriptor.EditableJavascript = value;
		applyUpdateCurrentVariation(currentVariation);
	};

	const applyUpdateProperties = (updateProperties) => {
		updateCurrentVariation((v) => {
			let newVariation = { ...v };
			newVariation.Properties = updateProperties(newVariation.Properties);
			return newVariation;
		});

		// Apply to editor state to keep values when switching between variations
		setEditorState((e) => {
			let newState = { ...e };
			newState.selectedStyle.properties = updateProperties(
				newState.selectedStyle.properties
			);
			return newState;
		});
	};

	function handleGroupPropertyValueChange(value, editorPropertyName, item) {
		const updateProperties = (propToUpdate) => {
			const p = propToUpdate.find((x) => x.Name === editorPropertyName);
			if (p) {
				const groupItem = p.Value;
				groupItem[item.Name] = value;
			}
			return propToUpdate;
		};

		applyUpdateProperties(updateProperties);
	}

	function handleCollectionPropertyValueChange(
		value,
		editorPropertyName,
		item,
		collectionItemIndex
	) {
		const updateProperties = (propToUpdate) => {
			const p = propToUpdate.find((x) => x.Name === editorPropertyName);
			if (p) {
				const collectionItem = p.Value[collectionItemIndex];
				collectionItem[item.Name] = value;
			}
			return propToUpdate;
		};
		applyUpdateProperties(updateProperties);
	}

	function handleChangeActiveCollectionItem(
		editorProperty,
		collectionItemIndex
	) {
		const updateProperties = (propToUpdate) => {
			const p = propToUpdate.find((x) => x.Name === editorProperty.Name);
			if (p) {
				setActiveItemIntoCollection(
					editorProperty,
					p.Value,
					collectionItemIndex
				);
			}
			return propToUpdate;
		};
		applyUpdateProperties(updateProperties);
	}

	function reorderCollectionItems(list, startIndex, endIndex, property) {
		const reorder = () => {
			const result = Array.from(list);
			const [removed] = result.splice(startIndex, 1);
			result.splice(endIndex, 0, removed);
			return result;
		};
		const updateProperties = (propToUpdate) => {
			const activeVariation = getCurrentVariation();
			const index = activeVariation.Template.Properties.findIndex(x => x.Key === property.Key);
			propToUpdate[index].Value = reorder();
			return propToUpdate;
		};
		applyUpdateProperties(updateProperties);
	}

	function handleAddOrUpdateProperty(value, editorPropertyName, isCollection, isGroup) {
		const updateProperties = (propToUpdate) => {
			if(!propToUpdate){
				return;
			}
			return propToUpdate
				.filter(p => p.Name !== editorPropertyName)
				.concat({Name: editorPropertyName, Value: value, IsGroup: isGroup, IsCollection: isCollection, Key: uuidv4()});
		};
		applyUpdateProperties(updateProperties);
	}

	function handleDeleteProperty(editorPropertyName) {
		const updateProperties = (propToUpdate) => {
			if(!propToUpdate){
				return;
			}
			return propToUpdate
				.filter(p => p.Name !== editorPropertyName);
		};
		applyUpdateProperties(updateProperties);
	}

	function handlePropertyValueChange(value, editorPropertyName) {

		const updateProperties = (propToUpdate) => {
			if(!propToUpdate){
				return;
			}
			const p = propToUpdate.find((x) => x.Name === editorPropertyName);
			if (p) {
				p.Value = value;
			}
			return propToUpdate;
		};
		applyUpdateProperties(updateProperties);
	}

	function handleAddToCollection(collectionName, property) {
		const newItem = templatePropertyToStyleProperty(property).Value;
		//let newLength = 0;

		const insertElement = (x) => {
			let arr = [];
			if (x.Value.length === 0) {
				arr = [...newItem];
				//newLength = arr.length;
				return arr;
			}
			arr = [...x.Value, ...newItem];
			//newLength = arr.length;
			return arr;
		};

		const mapInsert = (x) =>
			x.Name === collectionName ? { ...x, Value: insertElement(x) } : x;

		const updateProperties = (propToUpdate) => propToUpdate.map(mapInsert);
		applyUpdateProperties(updateProperties);
	}

	function handleDeleteFromCollection(key, collectionName) {
		const mapDelete = (x) =>
			x.Name === collectionName
				? { ...x, Value: x.Value.filter((v) => v.Key !== key) }
				: x;

		const updateProperties = (propToUpdate) => propToUpdate.map(mapDelete);
		applyUpdateProperties(updateProperties);
	}

	function handlePreviewClicked(
		actualCampaign,
		callbackSuccess,
		callbackError
	) {
		const currentVariation = getCurrentVariation();
		if (!currentVariation)
			return console.log('Current variation is not set');

		const slide = { ...currentVariation };
		delete slide.EditorState;

		const campaignToPreview = { ...actualCampaign, Slide: slide };
		launchPreview(campaignToPreview, callbackSuccess, callbackError);
	}

	function getCurrentVariationRendering(callbackSuccess, callbackError) {
		const currentVariation = getCurrentVariation();
		if (!currentVariation)
			return console.log('Current variation is not set');

		const slide = { ...currentVariation };
		delete slide.EditorState;

		const campaignToRender = { ...campaign, Slide: slide };

		getCampaignRendering(campaignToRender, callbackSuccess, callbackError);
	}

	function handleSaveStylesForFormat(name, successCallback, errorCallback) {
		const currentVariation = getCurrentVariation();
		const callBack = (data) => {
			successCallback(data);
			loadSavedStylesForFormat(
				currentVariation.Template.Key,
				(styles) => {
					refreshMatchedExistingStyle(
						currentVariation.Properties,
						styles
					);
				}
			);
		};
		saveStyle(
			currentVariation.Template.Key,
			name,
			currentVariation.Properties,
			callBack,
			errorCallback
		);
	}

	function loadSavedStylesForFormat(templateId, successCallback) {

		if (!editorState.selectedFormat) return;
		getFormatSavedStyles(
			templateId,
			editorState.selectedFormat,
			(data) => {
				setFormatSavedStyles(data);
				if(typeof successCallback === 'function'){
					successCallback(data);
				}
			},
			(err) => {
				console.log(err), systemServices.showError('an error occured');
			}
		);
	}

	function verifyFormatData() {
		if (editorMode === 'patch/graph' || editorMode === 'patch/code' || editorMode === 'campaign/builder') {
			return true;
		}
		const variationsToTest = isABTestingActive
			? variations
			: variations.filter((x) => x.IsOriginalVariation);
		const { isValid: isFormatDataValid, newSaveError: formatDataSaveError } = validateFormatData(variationsToTest, saveError);
		const { isValid: isEditorStateValid, newSaveError: editorStateSaveError } = mode === 'creating'
			? validateEditorState(variationsToTest, formatDataSaveError)
			: { isValid: true, newSaveError: formatDataSaveError };
		setSaveError(editorStateSaveError);
		return isFormatDataValid && isEditorStateValid;
	}

	function verifyInformationsData() {
		const { isValid, newSaveError } = validateInformationsData(
			campaign,
			saveError
		);
		setSaveError(newSaveError);
		return isValid;
	}

	function verifyTriggersData() {
		const { isValid, newSaveError } = validateTriggersData(
			campaign.Specifications,
			saveError
		);
		setSaveError(newSaveError);
		return isValid;
	}

	async function handleSaveCampaign() {
		if (step === 3) {
			const isValid = verifyInformationsData();
			if (!isValid) return;
		}

		setIsSaving(true);

		const original = (variations || []).find((x) => x.IsOriginalVariation);
		const variationsToSave = isABTestingActive ? variations : [original];

		trackEvent(`${trackEventFeature}/${mode === 'creating' ? 'new-campaign-saved' : 'existing-campaign-saved'}`);

		let updatedCampaign = campaign;

		if (mode === 'creating') {
			const ipOfCreatorIsAlreadyInCampaign = campaign.TestModeIpAddresses.find(ip => ip.ipAddress === currentIpAddress);

			if (ipOfCreatorIsAlreadyInCampaign) {
				if (ipOfCreatorIsAlreadyInCampaign.isDisabled) {
					updatedCampaign = createCampaignWithReactivatedIp(campaign, ipOfCreatorIsAlreadyInCampaign);
				}
			} else {
				const newIp = {
					label: 'Campaign creator',
					ipAddress: currentIpAddress,
					isDisabled: false,
					id: uuidv4()
				};

				const newIPAddresses = [newIp, ...campaign.TestModeIpAddresses];
				updatedCampaign = {
					...campaign,
					TestModeIpAddresses: newIPAddresses
				};
			}
		}

		saveCampaign(
			updatedCampaign,
			variationsToSave,
			(success) => {
				if (updatedCampaign.hasOwnProperty('Features') && updatedCampaign.Features.hasOwnProperty('HttpConnector')) {
					const needSavingHttpConnector = !isEqual(memoInitialHttpConnector, updatedCampaign.Features.HttpConnector);
					if (!needSavingHttpConnector) {
						setIsSaving(false);
						handleCloseEditor();
					} else {
						const idOfCampaign = success.CampaignId;
						saveNewValueOfHttpConnector(idOfCampaign, () => {
							setIsSaving(false);
							handleCloseEditor();
						}, () => setIsSaving(false));
					}
				} else {
					setIsSaving(false);
					handleCloseEditor();
				}
			},
			(validationError) => {
				systemServices.showError(validationError.message);
				setIsSaving(false);
			},
			() => {
				systemServices.showError('an error occurred');
				setIsSaving(false);
			}
		);
	}

	function createCampaignWithReactivatedIp(campaign, ipOfCreatorIsAlreadyInCampaign) {
		const newIPAddresses = campaign.TestModeIpAddresses.map(ip =>
			ip.id === ipOfCreatorIsAlreadyInCampaign.id ? { ...ip, isDisabled: false } : ip
		);
		return { ...campaign, TestModeIpAddresses: newIPAddresses };
	}


	function handleCloseEditor() {
		let returnFrom = props.$routeParams?.from ? decodeURIComponent(props.$routeParams.from) : 'Onsite/Dashboard';
		const sep = returnFrom.includes('?') ? '&' : '?';
		if(isAdmin && props.$routeParams.ka ){
			window.location.href = `${returnFrom}${sep}ka=${props.$routeParams.ka}&ku=${props.$routeParams.ku}`;
		}else {
			window.location.href = returnFrom;
		}
	}

	function calculateNewEndDate() {
		const getDate = () => {
			if (typeof (campaign.StartDateInTimezone) === 'string') {
				return new Date(campaign.StartDateInTimezone);
			}
			if (campaign.StartDateInTimezone instanceof Date) {
				return campaign.StartDateInTimezone;
			}
			return new Date();
		};
		const date = getDate();
		return addDays(date, 10);
	}

	async function handleNextStep(nextStep) {
		if (nextStep === 2) {
			const isValid = verifyFormatData();
			if (isValid === true) setStep(nextStep);
		} else if (nextStep === 3) {
			const isValid = verifyTriggersData();
			if (isValid === true) setStep(nextStep);
		}
	}

	function handleTagChanged(value, id) {

		let isUnique = true;
		variations.forEach((variation) => {
			if (variation.NameOrTag === value ||variation.Tag === value ) {
				isUnique = false;
			}
		});

		setVariationError(isUnique === false ? id : null);
		const newVariations = variations.map((x) => {
			if (x.UId === id) return { ...x, NameOrTag: value , Tag: value};
			else return x;
		});
		setVariations(newVariations);
	}

	function handleTag(val) {
		const newVariations = variations.map((v) => ({
			...v,
			VariationType: val,
		}));
		setVariationError(null);
		setVariations(newVariations);
	}

	function handleVariationRangeChanged(value, id) {
		value = parseInt(value);
		if (value > 100) return;
		if (!value) value = 0;

		let count = 0;
		variations
			.filter((x) => !x.IsOriginalVariation)
			.forEach((variation) => {
				if (variation.UId !== id) {
					count += parseInt(variation.RangeSize);
				}
			});
		setVariationError(count + value > 100 ? id : null);

		const newOriginalSize = Math.max(100 - (count + value), 0);
		const newVariations = variations.map((x) => {
			if (x.IsOriginalVariation)
				return { ...x, RangeSize: newOriginalSize };
			else if (x.UId === id) return { ...x, RangeSize: value };
			else return x;
		});

		setVariations(newVariations);
	}

	const initOriginalRangeSize = (initialVariations) => {
		//call this function only when original variation type == Range
		const count = initialVariations
			.filter((x) => !x.IsOriginalVariation)
			.reduce((prev, variation) => {
				return prev + parseInt(variation.RangeSize);
			}, 0);
		const newOriginalSize = Math.max(100 - count, 0);

		let prevState = [...initialVariations];
		const index = prevState.findIndex((x) => x.IsOriginalVariation);
		prevState[index].RangeSize = newOriginalSize;
		return prevState;
	};

	function handleDeleteVariation(id) {
		let count = 0;
		variations
			.filter((x) => !x.IsOriginalVariation)
			.forEach((variation) => {
				if (variation.UId !== id) {
					count += parseInt(variation.RangeSize);
				}
			});

		const newOriginalSize = Math.max(100 - count, 0);
		let newVariations = variations
			.filter((x) => id !== x.UId)
			.map((x) => {
				if (x.IsOriginalVariation)
					return { ...x, RangeSize: newOriginalSize };
				else return x;
			});

		const original = variations.find((x) => x.IsOriginalVariation);
		setCurrentVariationId(original.UId);
		if(newVariations.length === 1 ){
			setIsABTestingActive(false);
			setCampaign((s) => ({
				...campaign,
				CustomMainTargetEvent : null,
				Objective : 'ImproveConversion'
			}));
			const hasDesignProperties = newVariations[0].hasOwnProperty('DesignProperties');
			if(hasDesignProperties){
				newVariations[0].DesignProperties =  newVariations[0].DesignProperties.filter(el => el.Name !== 'PatchPrincipalElement');
			}
		}
		setVariations(newVariations);
	}

	function handleAddVariation() {
		if (variations.length > 5) return;
		let original = variations.find((x) => x.IsOriginalVariation);
		const realVariations = variations.filter((x) => !x.IsOriginalVariation);

		const findIdentifier = function (getProp, prefix) {
			var varWithIndentifier = realVariations.filter(
				(x) => getProp(x) && getProp(x) !== ''
			);
			var maxId = varWithIndentifier
				.map((x) => parseInt(getProp(x).replace(prefix, '') || 0))
				.reduce((p, v) => (p > v ? p : v), 0);
			return prefix + (maxId + 1);
		};
		const findVariationDisplayIdentifier = () =>
			findIdentifier((x) => x.DisplayIdentifier, 'INT');
		const findNameIdentifier = () =>
			findIdentifier((x) => x.NameIdentifier, 'VAR');
		const isBuilder = campaign.Editor === 'campaign/builder';
		if(campaign.Editor !== 	'patch/graph'){
			let newVariation = {
				...variationItemDefault,
				UId: uuidv4(),
				DesignProperties :[{
					Name: 'PatchDefinitions',
					Value: {data : [ ] , }
				}],
				VariationType: original.VariationType,
				DisplayIdentifier: findVariationDisplayIdentifier(),
				NameIdentifier: findNameIdentifier(),
				NameOrTag: '',
				CssToInitialize: true, // bof ! :/
				TemplateType: isBuilder ? 'CUSTOM' : 'BESPOKE'
			}; // TODO: setup DisplayIdentifier + NameOrTag

			if (variations.filter((x) => !x.IsOriginalVariation).length === 0) {
				newVariation.RangeSize = 50;
				original.RangeSize = 50;
				setCurrentVariationId(original.UId);
			} else {
				// select created variation => do not change for now
				setCurrentVariationId(newVariation.UId);
			}
			const tmpVariations = [...variations, newVariation];

			setVariations(tmpVariations);
		}else{
			cloneVariationPatch();
		}

	}

	function handleBackToFormat() {
		setEditorState((s) => ({ ...s, sideBarStep: 1 }));
	}

	function handleBackToStyle() {
		loadSavedStylesForFormat(editorState.selectedFormat.Key, (result) => {
			/*if (result < 1 && !isModuleEditorMode()) {
				setEditorState((s) => ({ ...s, sideBarStep: 1 }));
			} else {
				setEditorState((s) => ({ ...s, sideBarStep: 2 }));
			}*/
			// Since UI update, always go to step 2
			setEditorState((s) => ({ ...s, sideBarStep: 2 }));
		});
	}

	function handleChangeIsActivePromoCode(isActive) {

		const currentVariation = getCurrentVariation();
		if (!currentVariation) return;
		currentVariation.Answer.ItemType = 'CallToActionWithPromoCode';
		function getDefaultUniqueCodeGenerationOptions() {
			return {
				CodeSize: 8,
				Prefix: 'BY',
				ExpirationDelayInDays: 2
			};
		}
		if(isActive && !currentVariation?.Answer?.PromoCode?.UniqueCodeGenerationOptions){
			if(currentVariation.Answer.PromoCode !== null){
				currentVariation.Answer.PromoCode.UniqueCodeGenerationOptions = getDefaultUniqueCodeGenerationOptions();
			}
		}
		if (!isActive && !currentVariation.Answer.CallToAction) {
			currentVariation.Answer.CallToAction = {
				...currentVariation.Answer.CallToAction,
				Text: null,
				Link: null
			};
		}
		applyUpdateCurrentVariation(currentVariation);
	}

	function handleSetPromoCode(promoCode) {
		const currentVariation = getCurrentVariation();
		if (!currentVariation) return;
		currentVariation.Answer.ItemType = 'CallToActionWithPromoCode';
		currentVariation.Answer.PromoCode = promoCode;
		applyUpdateCurrentVariation(currentVariation);
	}

	function handleSpecificationsChange(specifications) {
		let newCampaign = { ...campaign };

		newCampaign.Specifications = specifications.map((e) => ({
			Group: e.Group,
			Name: e.Name,
			OperatorName: e.OperatorName,
			ValueToCompare: e.ValueToCompare,
		}));

		setCampaign(newCampaign);
	}

	function handleTrackingModeChange(newTrackingMode) {
		let newCampaign = { ...campaign };
		newCampaign.TrackingMode = newTrackingMode;
		setTrackingMode(newTrackingMode);
		if (trackingModeToId('NoTracking') === newTrackingMode) {
			newCampaign.HasControlGroup = false;
		}
		if(newTrackingMode === 1) {
			setIsABTestingActive(false);
		}
		setCampaign(newCampaign);
	}

	const handleSetHasEndDate = async (hasEndDate) => {
		let newCampaign = { ...campaign };
		newCampaign.HasEndDate = hasEndDate;
		if (!newCampaign.EndDateInTimezone) {
			newCampaign.EndDateInTimezone = calculateNewEndDate();
		}
		setCampaign(newCampaign);
	};

	const cloneVariationPatch = () => {
		const currentVariation = JSON.parse(JSON.stringify(getCurrentVariation()));

		const copyOfVariationDefault =  JSON.parse(JSON.stringify(variationItemDefault));
		let variationToClone = {
			...copyOfVariationDefault,

			UId: uuidv4(),
			VariationType: currentVariation.VariationType,
			CssToInitialize: true, // bof ! :/
			TemplateType: currentVariation.TemplateType,
			DisplayIdentifier :'INT'+ variations.length ,
			NameIdentifier : 'VAR' + variations.length ,
			NameOrTag :'tag' + variations.length  ,
			VariationId : uuidv4(),
			SlideId : uuidv4(),
			RangeSize : 0
		}; // TODO: setup DisplayIdentifier + NameOrTag
		variationToClone.TemplateDescriptorDesktop.EditableTemplate = currentVariation.TemplateDescriptorDesktop.EditableTemplate;
		variationToClone.TemplateDescriptorMobile.EditableTemplate = currentVariation.TemplateDescriptorMobile.EditableTemplate;

		const oldPropertyArray = JSON.parse(JSON.stringify(currentVariation.DesignProperties));
		let firstElementPatch = null;
		if (oldPropertyArray && oldPropertyArray.length > 0) {

			let oldProperty = JSON.parse(JSON.stringify(currentVariation.DesignProperties.find(p => p.Name === 'PatchDefinitions')));
			const copyOldPropertyVariation = JSON.parse(JSON.stringify(oldProperty));
			firstElementPatch = copyOldPropertyVariation.Value.data[0];
			oldProperty.Value = {...oldProperty.Value ,
				data : [...JSON.parse(JSON.stringify(oldProperty)).Value.data.map((currentV) => {
					const id = currentV.uuid;

					const hasKeyProperty = copyOldPropertyVariation.Value.data.find(el => el.uuid === id);
					if(hasKeyProperty){
						return {
							...currentV ,
							modifications :  {
								attributes:false,
								deletion:false,
								style: false,
								text: false,
								uploadImage: false
							},
							uploadImage : {},
							editableCss : {},
							attributesMod : {
								addedClass: [],
								currentClass: [],
								deletedClass: [],
								id: '',
								placeholder: null
							},
							textContent: {
								hasText: true,
								value : currentV.reset.resetText.value
							}
						};
					}})]};
			variationToClone.DesignProperties = [...currentVariation.DesignProperties.filter(el => el.Name !== 'PatchDefinitions'),oldProperty];
			const jsInsertPatch = handlePatchJs(oldProperty.Value.data);
			const cssInsertPatch = handlePatchCss(oldProperty.Value.data);
			variationToClone.TemplateDescriptorDesktop.EditableCss = cssInsertPatch;
			variationToClone.TemplateDescriptorDesktop.EditableJavascript = jsInsertPatch;

			variationToClone.TemplateDescriptorMobile.EditableCss = cssInsertPatch;
			variationToClone.TemplateDescriptorMobile.EditableJavascript = jsInsertPatch;

		}	

		let tmpVariations = [...variations, variationToClone];

		if(firstElementPatch && campaign.Objective === 'CustomInteraction' ){
			const elementDesignProperty = variationToClone.hasOwnProperty('DesignProperties');
			const PatchPrincipalElement = variationToClone.DesignProperties.find(el => el.Name === 'PatchPrincipalElement');
			if(elementDesignProperty && !PatchPrincipalElement){
				tmpVariations = handleChangePrincipalElementAbtestPatch(firstElementPatch,false,tmpVariations);
			}
		}
		setVariations(tmpVariations);
		
	};
	const setPatchCurrentVariation = async (patch) => {
		patch = await processPatches(patch);
		const currentVariation = JSON.parse(JSON.stringify(getCurrentVariation()));
		if (!currentVariation) return;

		const oldPropertyArray = currentVariation.hasOwnProperty('DesignProperties');
		if (oldPropertyArray ) {
			let oldProperty = currentVariation.DesignProperties.find(p => p.Name === 'PatchDefinitions');
			if(oldProperty){
				oldProperty.Value = patch;
			}else {
				currentVariation.DesignProperties = [{
					Name: 'PatchDefinitions',
					Value: patch
				}];
			}
			if(campaign.Objective === 'CustomInteraction'){
				const PatchPrincipalElement = currentVariation.DesignProperties.find(p => p.Name === 'PatchPrincipalElement');
				if(PatchPrincipalElement){
					const newPatchElement = patch.data.filter(el =>el.uuid === PatchPrincipalElement.Value.uuid);
					const newPatchElementValue = currentVariation.DesignProperties.find(p => p.Name === 'PatchPrincipalElement');
					newPatchElementValue.Value = {...newPatchElement[0]};
					const newObjective = getElementInteractionName({...newPatchElement[0]});
					setCampaign((s) => ({
						...campaign,
						CustomMainTargetEvent : newObjective
					}));
				}
			}
		}
		
		else {
			currentVariation.DesignProperties = [{
				Name: 'PatchDefinitions',
				Value: patch
			}];
		}
		const jsInsertPatch = handlePatchJs(patch.data);
		const cssInsertPatch = handlePatchCss(patch.data);

		currentVariation.TemplateDescriptorDesktop.EditableCss = cssInsertPatch;
		currentVariation.TemplateDescriptorDesktop.EditableJavascript = jsInsertPatch;
		currentVariation.TemplateDescriptorMobile.EditableCss = cssInsertPatch;
		currentVariation.TemplateDescriptorMobile.EditableJavascript = jsInsertPatch;
		currentVariation.SlideFormat = 'Overlay';				
		//updateCurrentVariation(() => ({ ...currentVariation }));
		setOtherVariationPatch(patch, currentVariation,variations);

		let urlDebug = patch.urlPatch.replace('&by_debug=1', '');
		urlDebug = urlDebug.replace('?by_debug=1&', '?');
		urlDebug = urlDebug.replace('?by_debug=1', '');

		handleChangeUrlDebug(urlDebug);
	};
	const setOtherVariationPatch = async (patch,currentVariation,oldVariations) => {
		patch = await processPatches(patch);
		if (!currentVariation) return;

		const allVariationsWithoutCurrent = oldVariations.filter(v => v.UId !== currentVariation.UId);

		const variationsNewWithModifications = allVariationsWithoutCurrent.map(variation => 
		{
			{
				const oldPropertyArray = JSON.parse(JSON.stringify(variation.DesignProperties));
				if (oldPropertyArray && oldPropertyArray.length > 0) {
					let oldProperty = variation.DesignProperties.find(p => p.Name === 'PatchDefinitions');
					const copyOldPropertyVariation = JSON.parse(JSON.stringify(oldProperty));
					oldProperty.Value = {
						urlPatch: copyOldPropertyVariation.Value.urlPatch,
						data : [...patch.data.map((currentV) => {
							const id = currentV.uuid;
							const hasKeyProperty = copyOldPropertyVariation.Value.data.find(el => el.uuid === id);

							if(hasKeyProperty){
								return {
									...hasKeyProperty,
									// ...currentV ,
									selectorName: currentV.selectorName,
									initCssSelector: currentV.initCssSelector,
									cssSelector: currentV.cssSelector,
									cssParentSelector: currentV.cssParentSelector,
									////
									modifications : hasKeyProperty.modifications,
									textContent: hasKeyProperty.textContent,
									uploadImage : hasKeyProperty.uploadImage,
									editableCss : hasKeyProperty.editableCss,
									attributesMod : hasKeyProperty.attributesMod,
									tag:  hasKeyProperty.tag,
									uuid:  hasKeyProperty.uuid

								};
							}else{
								return {
									...currentV ,
									modifications :  {
										attributes:false,
										deletion:false,
										style: false,
										text: false,
										uploadImage: false

									},
									textContent: currentV.reset.resetText.value,
									uploadImage : {},
									editableCss : {},
									attributesMod : {
										addedClass: [],
										currentClass: [],
										deletedClass: [],
										id: '',
										placeholder: null
									},
									tag:  currentV.tag,
									uuid:  currentV.uuid
								};

							}
						})
						]};
					const jsInsertPatch = handlePatchJs(oldProperty.Value.data);
					const cssInsertPatch = handlePatchCss(oldProperty.Value.data);
					let newDesignProperties = [oldProperty];
					if(campaign.Objective === 'CustomInteraction'){
						const PatchPrincipalElement = variation.DesignProperties.find(p => p.Name === 'PatchPrincipalElement');
						if(PatchPrincipalElement){
							const newPatchElement = oldProperty.Value.data.filter(el =>el.uuid === PatchPrincipalElement.Value.uuid);
							PatchPrincipalElement.Value = {...newPatchElement[0]};
							newDesignProperties.push({
								Name: 'PatchPrincipalElement',
								Value: {...newPatchElement[0]}
							});
						}
					}
					return {
						...variation,
						DesignProperties : [...newDesignProperties],
						TemplateDescriptorDesktop : {
							EditableCss :cssInsertPatch,
							EditableJavascript : jsInsertPatch,
							EditableTemplate : {...variation.TemplateDescriptorDesktop.EditableTemplate}

						} ,
						TemplateDescriptorMobile : {
							EditableTemplate : {...variation.TemplateDescriptorMobile.EditableTemplate},
							EditableCss : cssInsertPatch,
							EditableJavascript : jsInsertPatch
						}
					};
				} 
				else {
					return {
						...variation, 
						DesignProperties : [{
							Name: 'PatchDefinitions',
							Value: patch
						}]
					};

				}
			}}
		);
		const arrayOfVariationsUuid = variations.map(x => x.UId);
		const variationsMerged = [currentVariation,...variationsNewWithModifications];
		const newVariationsSorted = arrayOfVariationsUuid.map(vId => {
			const vToReturn = variationsMerged.find(el => el.UId === vId);
			return vToReturn;
		});
		setVariations(newVariationsSorted);
	};
	function handleChangeObjectiveAbTest(objectiveNew){
		const objectiveNewValue = objectiveNew === 'interaction' ? 'CustomInteraction' : 'ImproveConversion';
		let newObjective = null;
		if(objectiveNewValue === 'CustomInteraction' && variations.length > 1){
			const el = variations[0].DesignProperties.find(ele => ele.Name === 'PatchDefinitions').Value.data[0];
			newObjective = getElementInteractionName(el);
			handleChangePrincipalElementAbtestPatch(el,true );
		}else{
			const variationsToChange = variations.map(v => {
				return {...v,
					DesignProperties: v.DesignProperties.filter(d=> d.Name !== 'PatchPrincipalElement')};
			});
			setVariations(variationsToChange);
		}
		setCampaign((s) => ({
			...campaign,
			Objective : objectiveNewValue,
			CustomMainTargetEvent : newObjective
		}));
	}
	const handleSetPatchDefinitions = async (patch) => {
		setPatchCurrentVariation(patch);
	};

	const loadHttpConnectorConfigurationCallback = (result) => {
		handleChangeValueHttpConnector(result);
		setMemoInitialHttpConnector(result);
	};

	subscribe('evt/campaign/httpConnectorUpdated', e => {
		handleChangeValueHttpConnector(e.detail);
	});

	function handleChangeValueHttpConnector(value) {
		setCampaign(x => ({...x,
			Features: { ...x.Features, HttpConnector: value }
		}));
	}

	function saveNewValueOfHttpConnector(campaignId, successCallback, errorCallback)  {
		formAndSurveysServices.configureCampaignHttpConnector(campaignId,
			campaign.Features.HttpConnector,
			success => {
				successCallback();
			},
			error => {
				errorCallback();
			}
		);
	}

	const handleSetBuilderPropertyOnCampaign = (projectData, frame, variationId) => {
		const builderProperty = {
			projectData: projectData,
			frame: frame
		};

		const variation = variations.find(v => v.UId === variationId);

		let newVariation = {...variation};

		const oldPropertyArray = newVariation.DesignProperties;
		if (oldPropertyArray && oldPropertyArray.length > 0) {
			const builderEditorProperty = newVariation.DesignProperties.find(p => p.Name === 'BuilderEditor');
			if (builderEditorProperty) {
				builderEditorProperty.Value = builderProperty;
			}
		} else {
			newVariation.DesignProperties.push({
				Name:'BuilderEditor',
				Value: builderProperty
			});
		}
	};
	/// Ab test Redirection

	const handleSetBuilderDefinitions = (htmlPreview, cssPreview, js) => {
		const completeJS = `
			(function(SId, CId) {
				var timer;
				var outer;
				var obs;
				var cpg = {};

				function checks(){
					outer = window['by_r_'+SId];
					if (!outer) return;
			
					clearInterval(timer);
					
					obs = new MutationObserver(wait_outer_visibility);
					obs.observe(outer, { attributes: true });
					wait_outer_visibility();
				}

				function wait_outer_visibility(ml) {
					if (outer.style.display == 'none') return;
					obs.disconnect();
					outer.style.zIndex = '';
					outer.style.position = '';
					init();
				}

				function init(){
					${js}
				}

				timer = setInterval(checks, 50);
				checks();

			}(SId, CId));
		`;

		let newCampaign = {...campaign};
		newCampaign.Slide.TemplateDescriptorDesktop.EditableCss = cssPreview;
		newCampaign.Slide.TemplateDescriptorDesktop.EditableTemplate.Content = htmlPreview;
		newCampaign.Slide.TemplateDescriptorDesktop.EditableJavascript = completeJS;
		newCampaign.Slide.TemplateDescriptorMobile.EditableCss = cssPreview;
		newCampaign.Slide.TemplateDescriptorMobile.EditableTemplate.Content = htmlPreview;
		newCampaign.Slide.TemplateDescriptorMobile.EditableJavascript = completeJS;
		setCampaign(newCampaign);
	};

	function createNewVariationAbTest(){
		if (variations.length > 5) return;
		let original = variations.find((x) => x.IsOriginalVariation);
		const realVariations = variations.filter((x) => !x.IsOriginalVariation);

		const findIdentifier = function (getProp, prefix) {
			var varWithIndentifier = realVariations.filter(
				(x) => getProp(x) && getProp(x) !== ''
			);
			var maxId = varWithIndentifier
				.map((x) => parseInt(getProp(x).replace(prefix, '') || 0))
				.reduce((p, v) => (p > v ? p : v), 0);
			return prefix + (maxId + 1);
		};
		const findVariationDisplayIdentifier = () =>
			findIdentifier((x) => x.DisplayIdentifier, 'INT');
		const findNameIdentifier = () =>
			findIdentifier((x) => x.NameIdentifier, 'VAR');

		const clone = cloneDeep(variationItemAbTestRedirectionDefault);
		clone.DesignProperties[0].Value.Name = 'Variation' + ' ' + variations.length;
		let newVariation = {
			...clone,
			UId: uuidv4(),
			VariationType: original.VariationType,
			DisplayIdentifier: 'INT'+ variations.length ,
			NameIdentifier: 'VAR' + variations.length ,
			NameOrTag: 'tag' + variations.length  ,
			CssToInitialize: true, // bof ! :/
			TemplateType: 'CUSTOM'
		}; // TODO: setup DisplayIdentifier + NameOrTag
		newVariation.DesignProperties[0].Name = 'Variation ' + variations.length;
		let cloneVariation = [...variations];
		// select created variation => do not change for now
		setCurrentVariationId(newVariation.UId);
		if(variationType === 'uniform'){
			const numberVar = variations.map(x => x.UId);
			numberVar.push(newVariation.UId);
			const equalPartition = dividePercent(100, numberVar.length);
			newVariation.RangeSize = equalPartition;
			cloneVariation = variations.map((x,i) => {
				return ({
					...x,
					RangeSize :equalPartition[i]
				});}
			);

		}else{
			newVariation.RangeSize = 0;
		}
		
		const tmpVariations = [...cloneVariation, newVariation];
		
		// setVariations(tmpVariations);
		setIsABTestingActive(true);
		setvariationAbTestDraft(newVariation);		
	}
	function handleTagOrRangeChoosed (key){
				
		if(key === 'Range'){
			setvariationType('Range');
		}else if(key === 'PageTag'){
			setvariationType('PageTag');
		}

		const realVariations = variations.map((x) =>  {
			x.VariationType = key;  
			return x;
		});
		setVariations(realVariations);
	}
	function checkVariationType(variationsToCkeck){

		const originalVar = variationsToCkeck ? variationsToCkeck.find(variation => variation.IsOriginalVariation) : variations.find(variation => variation.IsOriginalVariation) ;

		const variationsCheck = variationsToCkeck ? variationsToCkeck : variations ;
		const variationsResults = variationsCheck.map(x => {
			return x.RangeSize;
		});	
		if(variationsCheck.length === 0){
			return;
		}
		const sumWithInitial = variationsResults.reduce(
			(accumulator, currentValue) => accumulator + currentValue,
			0,
		);

		const variationsIsAllEqual = isEqualRepartition(sumWithInitial,variationsResults);
		setvariationRangeIsEqual(variationsIsAllEqual);
		if(originalVar.VariationType === 'Range' && variationsIsAllEqual){
			setvariationType('uniform');
		}
		else if(originalVar.VariationType === 'Range' && !variationsIsAllEqual){
			setvariationType('Manual');
		}else if(originalVar.VariationType === 'PageTag'){
			setvariationType('PageTag');
		}
	}
	function handleChangePrincipalElementAbtestPatch(element, needRefreshList,variationNew){
		const variationClones = JSON.parse(JSON.stringify(!needRefreshList ? variationNew : variations));
		let newObjective = null;
		variationClones.map(variationElement => {
			const filterElementWithoutPrincipalElement = variationElement.DesignProperties.filter(ele => ele.Name !== 'PatchPrincipalElement');
			const patchElement = variationElement.DesignProperties.find(ele => ele.Name === 'PatchDefinitions').Value.data;
			newObjective = getElementInteractionName(patchElement.find(el =>el.uuid === element.uuid));
			variationElement.DesignProperties  = [...filterElementWithoutPrincipalElement,{
				Name: 'PatchPrincipalElement',
				Value: patchElement.find(el =>el.uuid === element.uuid)
			}];
			return variationElement;
		});
		if(needRefreshList){
			setVariations([...variationClones]);
		}else{
			return [...variationClones];
		}
		setCampaign((s) => ({
			...campaign,
			CustomMainTargetEvent : newObjective
		}));
	}
	function handleChangeUrlDebug (value) {
		setCampaign(currentCampaign => ({
			...currentCampaign, 
			UrlDebug : value
		}));
	}

	function isEqualRepartition(chiffre, montants) {
		let somme = 0;
		let premierMontant = montants[0]; // Le premier montant
		let chiffrePrecedent = null;
	
		for (const montant of montants) {
			// Vérifier si le montant est égal à zéro
			if (montant === 0) return false;
	
			// Vérifier si la différence entre le montant et le chiffre précédent est supérieure à 1
			if (chiffrePrecedent !== null && Math.abs(montant - chiffrePrecedent) > 1) return false;
	
			// Ajouter le montant à la somme
			somme += montant;
	
			// Mettre à jour le chiffre précédent
			chiffrePrecedent = montant;
		}
	
		// Vérifier si la somme est égale au chiffre
		return somme === chiffre && premierMontant !== chiffre;
	}
	
	const handleUpdateObjetVariation = (id,value) => {
		const variationsClone = cloneDeep(variations);
		if(!variationAbTestDraft){
			const currentVariation = variationsClone.filter(variation => variation.UId === id);
			const indexOfElement = variationsClone.indexOf(currentVariation[0]);
			currentVariation[0].DesignProperties[0] = value;
			variationsClone.splice(indexOfElement, 1,currentVariation[0] );
			setVariations(variationsClone);
		}else{
			setvariationAbTestDraft();
			let newVariation = {...variationAbTestDraft};
			newVariation.DesignProperties[0] = value;
			let cloneVariation = [...variationsClone];
			// select created variation => do not change for now
			if(variationType === 'uniform'){
				const numberVar = variations.map(x => x.UId);
				numberVar.push(newVariation.UId);
				const equalPartition = dividePercent(100, numberVar.length);
				newVariation.RangeSize = equalPartition[numberVar.length - 1];
				cloneVariation = variations.map((x,i) => {
					return ({
						...x,
						RangeSize :equalPartition[i]
					});});
			}else{
				newVariation.RangeSize = 0;
			}
			setVariations([...cloneVariation,newVariation]);
		}
	};

	function deleteVariation (variationToDelete){

		let variationDelete = variations.filter(x => x.UId !== variationToDelete.UId);
		if(variationType === 'uniform'){
			const numberVar = variationDelete.map(x => x.UId);
			const equalPartition = dividePercent(100, numberVar.length);
			variationDelete = variationDelete.map((x,i) => {
				return ({
					...x,
					RangeSize :equalPartition[i]
				});}
			);
		}
		
		setVariations(variationDelete);
	}
	function cloneVariation (variationToClone){
		const clone = cloneDeep(variationToClone);
		clone.DesignProperties[0].Name = clone.DesignProperties[0].Name  + ' Copy';
		clone.DesignProperties[0].Value.Name = clone.DesignProperties[0].Value.Name  + ' Copy';
		clone.DesignProperties[0].Value.RedirectionUrl = clone.DesignProperties[0].Value.RedirectionUrl  + '-v2';
		clone.UId = uuidv4();
		clone.SlideId = uuidv4();
		clone.VariationId = uuidv4();
		clone.Answer.AnswerId = uuidv4();
		clone.DisplayIdentifier = 'INT'+ variations.length ;
		clone.NameIdentifier = 'VAR' + variations.length ;
		// clone.NameOrTag = 'tag' + variations.length  ;
		let newVariations = [...variations,clone ];
		if(variationType !== 'uniform'){
			clone.RangeSize = 0;
		}
		newVariations =	newVariations.map((x,i) => {

			if(variationType === 'uniform'){
				const numberVar = newVariations.map(x => x.UId);
				const equalPartition = dividePercent(100, numberVar.length);
				return { ...x, RangeSize: equalPartition[i], VariationType: 'Range' };
			}else{
				return {...x};
			}
		});
		setVariations(newVariations);
	}
	function handleChangeRangeUniform () {
		const numberVar = variations.map(x => x.UId);
		const percentEqual = dividePercent(100,numberVar.length);
		const copyVariations = cloneDeep(variations);
		const newVariations = copyVariations.map((x,i) => {
			return { ...x, RangeSize: percentEqual[i], VariationType: 'Range' };
		});
		setVariations(newVariations);
	}
	function handleChangeRange () {

		const newVariations = variations.map((x) => {
			if(x.IsOriginalVariation){
				return { ...x, RangeSize: 100, VariationType: 'Range' };
			}else{
				return { ...x, RangeSize: 0, VariationType: 'Range' };
			}
		});
		setVariations(newVariations);
	}

	return (
		<I18nextProvider i18n={newInstanceI18next}>
			<context.Provider
				value={{
					$rootScope,
					$routeParams,
					$http,
					$timeout,
					isAdmin,
					currentIpAddress,
					UtilCampaignsServices,
					setupAccountServices,
					accountId,
					tooltipID,
					isReady,
					mode,
					saveError,
					variationError,
					isSaving,
					canSaveEdition,
					canGoNextOrSave,
					setCanGoNextOrSave,
					step,
					setStep,
					isABTestingActive,
					setIsABTestingActive,
					testGroupPercent,
					setTestGroupPercent,
					campaign,
					setCampaign,
					features,
					showCSSEditor,
					setShowCSSEditor,
					currentDevice,
					setCurrentDevice,
					saveStyleModalOpen,
					setSaveStyleModalOpen,
					modalUploadPicture,
					setModalUploadPicture,
					currentVariationId,
					variations,
					setVariations,
					getCurrentVariationRendering,
					editorState,
					editorMode,
					isNativeApp: editorMode == 'appNative/patch/code',
					allowCreateCustom: !editorMode?.startsWith('notificationCenter'),
					isModuleEditorMode,
					formatSavedStyles,
					availableFormats,
					noAvailableFormat,
					initialTriggers,
					trackingMode,
					systemServices,
					matchedExistingStyle,
					displayBackToFormat,
					displayBackToStyle,
					canPublishStyles,
					canSwitchToNoTracking,
					displayAbTestSettings,
					displayControlGroupSettings,
					loadHttpConnectorConfigurationCallback,
					calculateNewEndDate,
					getCurrentVariation,
					handleSetHasEndDate,
					handleChangeVariation,
					handleSaveCampaign,
					handleCloseEditor,
					handleNextStep,
					handleSaveStylesForFormat,
					handlePreviewClicked,
					handleBackToFormat,
					handleBackToStyle,
					handleUpdateCss,
					handleUpdateJs,
					handleUpdateHtml,
					handleUpdateDesktopDisplayConditions,
					handleResetResponsive,
					handleUpdateCssResponsive,
					handleUpdateJsResponsive,
					handleUpdateHtmlResponsive,
					handleUpdateDisplayConditionResponsive,
					handleSwitchResponsive,
					handleFormatSelected,
					handleFormatUnselected,
					handleStyleSelected,
					handleFormatChosen,
					handleStyleChosen,
					handleStyleDeleted,
					handleStylePublished,
					handleAddOrUpdateProperty,
					handleDeleteProperty,
					handlePropertyValueChange,
					handleGroupPropertyValueChange,
					handleCollectionPropertyValueChange,
					handleAddToCollection,
					handleDeleteFromCollection,
					handleChangeActiveCollectionItem,
					handleVariationRangeChanged,
					handleTagChanged,
					handleAddVariation,
					handleDeleteVariation,
					handleTag,
					handleSetPromoCode,
					handleSpecificationsChange,
					handleTrackingModeChange,
					authServices,
					cloudImageServices,
					toggleResponsiveValue,
					setToggleResponsiveValue,
					handleSetPatchDefinitions,
					handleUpdateInpage,
					reorderCollectionItems,
					handleChangeValueHttpConnector,
					applyUpdateCurrentVariation,
					getModuleFormats,
					handleSetBuilderPropertyOnCampaign,
					handleSetBuilderDefinitions,
					impersonatingServices,
					fontData,	
					externalAppSetup,		
					refreshExternalAppSetupInfo,
					handleChangeIsActivePromoCode,
					i18nContext,
					sidebarIsOpen,
					setSidebarIsOpen,
					handleChangePermissions,
					reportingCampagneServices,
					// Custom Campaign 
					handleChangeEditorToLibraryCustom,
					handleChooseCampaignFormat,
					pannelFeaturesIsOpen,
					handleOpenClosePanelFeatures,
					handleChangeCallToAction,
					handleDeleteFeature,
					setFormCustomData,
					setFormSurveyCustomData,
					panelFormIsOpen,
					setPanelFormIsOpen,
					handleOpenClosePanelForm,
					confirmFormData,
					isLoadingCurrentVariationRendering,
					setisLoadingCurrentVariationRendering,
					// Ab Test Redirection 
					createNewVariationAbTest,
					handleChangeUrlDebug,
					handleUpdateObjetVariation,
					deleteVariation,
					cloneVariation,
					variationType,
					setvariationType,
					handleChangeRangeUniform,
					handleChangeRange,
					handleTagOrRangeChoosed,
					variationRangeIsEqual,
					setvariationRangeIsEqual,
					checkVariationType,
					variationAbTestDraft,
					handleChangeObjectiveAbTest,
					handleChangePrincipalElementAbtestPatch,
					handleChangeInCollection,
					handleChangeInPageOrientation,
					handleChangeElementsInCollection,
					handleUpdateJsonTemplate,
					wysiwygSurveyTarget,
					setWysiwygSurveyTarget
				}}
			>
				<ReactTooltip
					id={tooltipID}
					backgroundColor="black"
					effect="solid"
					place="bottom"
					eventOff="click"
					delayShow={600}
				/>
				{props.children}
			</context.Provider>
		</I18nextProvider>
	);
};
