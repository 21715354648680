import React from 'react';
import SelectDropdown from '../../../../Components/SelectDropdown';

export const listMetric =  [
	{
		value: 'conversionRate',
		label: 'Conversion rate mapping'
	},
	{
		value: 'addToCartRate',
		label: 'Add-to-cart rate mapping'
	},
];

export default function SelectMetric({
	onSelectMetric,
	metricSelected
} ) {

	return (
		<SelectDropdown
			value={metricSelected}
			optionsList={listMetric}
			onChange={(v) => onSelectMetric(v)}
			appearance='ghost'
			className="h3"
		/>
	);
}
