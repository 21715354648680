import HttpServices from './HttpServices';

export default class EmailRemarkettingServices {
	HttpServices: HttpServices;
	BO_API_URL: string;
	accountId: string;

	constructor(accountId: string, $http: any) {
		this.HttpServices = new HttpServices($http);
		if (!window || !(window as any).BEYABLE_env || !(window as any).BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${(window as any).BEYABLE_env.BO_API_URL}`;
		}
		this.accountId = accountId;
	}
	LoadCampaignStatsForAnAccount(key, startDate, endDate) {
		const url = '/users/' + key + '/accountstatistics/';
		return this.HttpServices.get(url + key + '?sdate=' + startDate+ '&edate=' + endDate  + '&nature=ACCOUNTCAMPAIGNCRMONSITE');
	}

	StopGoCampaignCRM(key, stoporgo) {
		const url = '/users/8504aaaa-aaaa-9999-8888-8885d94b896e/campaignscrmonsite';
		return this.HttpServices.put(url + '/' + key + '?stoporgo=' + stoporgo, 'stoporgo');
	}

	DuplicateCampaign(campaign) {
		const account = campaign.AssociatedAccount;
		const url = '/users/' + account.Key + '/campaignscrmonsite';
		return this.HttpServices.post(url + '/' + campaign.Key, 'duplicate');
	}

	DestroyCampaignCRM(campaign) {
		const account = campaign.AssociatedAccount.Key;
		const url = '/users/' + account.Key + '/campaignscrmonsite';
		return this.HttpServices.delete(url, campaign.Key);
	}

	GetCampaignState(campaign) {

		const now = Date.now();
		const start_date = new Date(campaign.StartDate).getTime();
		const end_date = new Date(campaign.EndDate).getTime();

		if (start_date > now)
			return 'scheduled';
		if (end_date < now)
			return 'past';
		if (start_date < now && end_date > now)
			return 'ongoing';
	}

}
