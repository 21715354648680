import React, { createContext, useState, useContext, useEffect } from 'react';
import { crudListMappingProduct } from '../ProductInsights/ListMapping/api/CrudListMappingProduct';
import { Product } from '../ProductInsights/Kpi/TypeKpi';
import {
	Chip,
	DataStructure,
	getInitialSelectedValues,
	SESSION_STORAGE_KEY,
	transformToDataStructureArray
} from '../ProductInsights/utils';

type ProductInsightsContextType = {
  isLoading: boolean;
  $location: any;
  insightsKpiPaylod: InsightsKpiPaylod;
  productsSelections: Product[];
  fetchExportKpis: () => void;
  visitsAverage?: number;
  addtocartAverage?: number;
  emercheOrdinate?: string;
  tagStudiedEl: any;
  currentTab: number;
  updateMappingData: (cat:string) => void;
  ComparisonSelected: boolean;
  handleSelectComparaisonPeriodHandler: (b: boolean) => void;
  onChangeNavFilter: (o: any) => void;
  initialDates: any;
  dateStringToTimestamp: (o: any) => void;
  navFilter: any;
  setNavFilter: React.Dispatch<React.SetStateAction<any>>;
  setisCustomSelected: React.Dispatch<React.SetStateAction<any>>;
  isCustomSelected: boolean;
  customDateRange: any;
  setCustomDateRange: React.Dispatch<React.SetStateAction<any>>;
  updateStorage: any;
  xUnitLabel: string;
  yUnitLabel: string;
	mappingUnits:any;
	handleXAxisChange:any;
	handleYAxisChange:any;
	handleChangeFormData: (data:any)=>void;
	handleOpenClosePanelForm: (evt:boolean)=>void;
	panelFormIsOpen:boolean;
	filterHierarchies:any;
	selectedValues:any;
	setSelectedValues:React.Dispatch<React.SetStateAction<any>>;
	chipsByFilterSelected:Chip[];
	setFilterSelected:React.Dispatch<React.SetStateAction<DataStructure[]>>;
	categoryTypeSelected: SelectElem | undefined;
	categories: SelectElem[];
	handleSelectNewCategory: (e: SelectElem) => void;
	accountHasNoDatas: boolean;
};

type filter = {
  op: string;
  filters: Array<any>;
};

type InsightsKpiPaylod = {
  context: string;
  sorting: Array<any>;
  tenant: null;
  filtering: filter;
  groupSorting: null;
  transformations: Array<any>;
  name: string;
};
type SelectElem = {
	label: string;
	value : string;
}
interface ListMappingProviderProps {
  $http: any;
  children?: React.ReactNode;
  $rootScope: any;
  $routeParams: any;
  $timeout: any;
  $location: any;
  AuthServices: any;
  ComparisonSelected: boolean;
  handleSelectComparaisonPeriodHandler: (b: boolean) => void;
  onChangeNavFilter: (o: any) => void;
  initialDates: any;
  dateStringToTimestamp: (o: any) => void;
  navFilter: any;
  setNavFilter: React.Dispatch<React.SetStateAction<any>>;
  setisCustomSelected: React.Dispatch<React.SetStateAction<any>>;
  isCustomSelected: boolean;
  customDateRange: any;
  setCustomDateRange: React.Dispatch<React.SetStateAction<any>>;
  updateStorage: any;
	selectedValues:any;
	setSelectedValues:React.Dispatch<React.SetStateAction<any>>;
	accountHasNoDatas: boolean;
	setAccountHasNoDatas:React.Dispatch<React.SetStateAction<boolean>>;
}

const ListMappingContext = createContext<ProductInsightsContextType | undefined>(undefined);

export const useListMappingContext = () => {
	const contextValue = useContext(ListMappingContext);
	if (contextValue === undefined) {
		throw new Error(' must be used within an insights element');
	}
	return contextValue;
};

export const ListMappingContextProvider: React.FC<ListMappingProviderProps> = ({
	children,
	$location,
	$rootScope,
	$routeParams,
	AuthServices,
	$http,
	ComparisonSelected,
	handleSelectComparaisonPeriodHandler,
	onChangeNavFilter,
	initialDates,
	dateStringToTimestamp,
	navFilter,
	setNavFilter,
	isCustomSelected,
	setisCustomSelected,
	customDateRange,
	setCustomDateRange,
	updateStorage,
	selectedValues,
	setSelectedValues,
	accountHasNoDatas,
	setAccountHasNoDatas,
}) => {
	const [insightsKpiPaylod] = useState({
		context: 'Global',
		name: 'empty',
		sorting: [],
		tenant: null,
		filtering: { op: 'And', filters: [] },
		groupSorting: null,
		transformations: [],
	});

	const { postQuerySpeficifProductExportInsightsServices,
		loadInsightsProducts,
		loadInsightsProductsForCategoriesComparison,
		loadInsightsProductsSimple
	} = crudListMappingProduct($http, $rootScope, $routeParams, AuthServices,setAccountHasNoDatas);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [productsSelections] = useState<Product[]>([]);
	const [tagStudiedEl, setTagStudiedEl] = useState<any>();
	const [currentTab, setCurrentTab] = useState<number>(0);
	const [emercheOrdinate] = useState('conversionRate');
	const [xUnitLabel, setXUnitLabel] = useState<string>('');
	const [yUnitLabel, setYUnitLabel] = useState<string>('');
	const [visitsAverage, setVisitsAverage] = useState<number | undefined>(undefined);
	const [addtocartAverage, setAddtocartAverage] = useState<number | undefined>(undefined);
	const [mappingUnits, setMappingUnits] = useState({
		x: 'numberofobjectives',
		y: 'numberofsessions'
	});
	const [categoryTypeSelected, setCategoryTypeSelected] = useState<SelectElem | undefined>({label :'Pays de destination', value: 'Pays de destination'});

	const [categories, setCategories] = useState<SelectElem[]>([]);
	const [panelFormIsOpen, setPanelFormIsOpen] = useState(false);
	const [filterHierarchies, setFilterHierarchies] = useState([]);
	const [filterSelected, setFilterSelected] = useState<DataStructure[]>([]);
	const [isFilterSelectedInitialized, setIsFilterSelectedInitialized] = useState(false);

	const [chipsByFilterSelected, setChipsByFilterSelected] = useState<Chip[]>([]);
	function handleChangeFormData(data:any){
		sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(selectedValues));
		setFilterSelected(data);
		setPanelFormIsOpen(false);
	}
	function handleOpenClosePanelForm(evt:boolean){
		setPanelFormIsOpen(evt);
	}
	function handleSelectNewCategory(value) {
		const elSelected:SelectElem | undefined = categories.find(e=>e.value === value) ?  categories.find(e=>e.value === value) : {
			value: '',
			label: 'Choose a name field',
		} ;
		setCategoryTypeSelected(elSelected);
	}
	const updateMappingAxis = () => {
		updateMappingData(categoryTypeSelected);
	};

	const handleXAxisChange = (value: string) => {
		setMappingUnits(prev => ({ ...prev, x: value }));
		updateMappingAxis();
	};

	const handleYAxisChange = (value: string) => {
		setMappingUnits(prev => ({ ...prev, y: value }));
		updateMappingAxis();
	};
	function fetchCatergory ()  {
		const breadcrumb = [];
		const date1 = dateStringToTimestamp(navFilter.fromDate);
		const date2 = dateStringToTimestamp(navFilter.toDate);
		const data3 = dateStringToTimestamp(navFilter.fromDateToCompare);
		const data4 = dateStringToTimestamp(navFilter.toDateToCompare);
		const optionsObject = {
			startDate1: date1,
			endDate1: date2,
			endDate2: data4,
			startDate2: data3,
			rowsperpage: 2,
			blocnumber: 1,
			orderby: 'numberofsessions',
			direction: 'desc',
			pattern: '',
		};
		const controller = new AbortController();
		const signal = controller.signal;
		loadInsightsProductsSimple(optionsObject, d => {
			if (signal.aborted) return;
			const mainHierarchy = [];
			d.Categories.forEach((category:any )=> {
				// @ts-ignore
				if (!mainHierarchy.includes(category.Type) && category.Type !== 'root') {
					// @ts-ignore
					mainHierarchy.push(category.Type);
				}
			});

			mainHierarchy.forEach(type => {
				const hierarchy = {
					name: type,
					categories: d.Categories
						.filter(category => category.Type === type)
						.map(category => ({
							name: category.Name,
							value: category.Value
						}))
				};
				// @ts-ignore
				breadcrumb.push(hierarchy);
			});
			setFilterHierarchies(breadcrumb);
		}, err => {
			if (!signal.aborted) {
				console.error('Error fetching data:', err);

			}
		}, signal);
	}
	const fetchExportKpis = () => {
		const date1 = dateStringToTimestamp(navFilter.fromDate);
		const date2 = dateStringToTimestamp(navFilter.toDate);
		const optionsObject = {
			startDate1: date1,
			endDate1: date2,
			endDate2: '',
			startDate2: '',
		};
		const controller = new AbortController();
		const signal = controller.signal;
		postQuerySpeficifProductExportInsightsServices(optionsObject, _data => {
			return;
		}, err => {
			console.error('Error fetching data:', err);
		}, signal);
	};

	const updateMappingData = (cat) => {
		const date1 = dateStringToTimestamp(navFilter.fromDate);
		const date2 = dateStringToTimestamp(navFilter.toDate);
		const storedData = sessionStorage.getItem('globalProductInsightsComparedPeriode');
		const parsedData = storedData ? JSON.parse(storedData) : null;
		const options = {
			tagtype: cat ? cat.value : '',
			dimnamx: mappingUnits.x,
			dimnamy: mappingUnits.y,
			startDate1: date1,
			endDate1: date2,
		};

		const controller = new AbortController();
		const signal = controller.signal;

		if (!parsedData) {
			loadInsightsProducts(options,filterSelected, response => {
				const categoriesData = response;
				const hierarchies = categoriesData.Hierarchies;
				setCurrentTab(hierarchies[0]);

				const tagsStudied: any = {
					data: [],
					marker: { fillColor: '#006fff', lineWidth: 0 },
				};
				categoriesData.Tags.forEach(value => {
					const tagStudied:any = {
						x: value.Vx,
						y: value.Vy,
						z: value.NumberOfCartAdded,
						visitReference: value.Vy,
						convReference: value.Vx,
						addToCartRateReference: value.NumberOfCartAdded,
						visitComparison: value.Vy,
						convComparison: value.Vx,
						addToCartRateComparison: value.NumberOfCartAdded,
						nbProduct: 0,
						tagType: value.Tag.Type,
						tagName: value.Tag.Name,
						tagValue: value.Tag.Value,
					};
					tagsStudied.data.push(tagStudied);
				});
				const categoryData = response.Hierarchies.map(categorie => {
					return {
						value: categorie.Type,
						label: categorie.Type,
					};
				});
				setCategories(categoryData);

				setTagStudiedEl(tagsStudied);
				setIsLoading(false);
			}, err => {
				console.error(err);
				setIsLoading(false);
			}, signal);
		} else {
			const data3 = dateStringToTimestamp(navFilter.fromDateToCompare);
			const data4 = dateStringToTimestamp(navFilter.toDateToCompare);
			options['startDate2'] = data3;
			options['endDate2'] = data4;
			options['dimnamx'] = 'numberofobjectives';
			options['dimnamy'] = 'numberofsessions';

			loadInsightsProductsForCategoriesComparison(options,filterSelected, response => {
				const categoriesData = response;
				const hierarchies = categoriesData.Hierarchies;
				setCurrentTab(hierarchies[0]);

				const tagsStudied: any = {
					data: [],
					marker: { fillColor: '#006fff', lineWidth: 0 },
				};

				categoriesData.Tags.forEach(value => {
					const tagStudied: any = {
						x: value.Vx.ValueReference,
						y: value.Vy.ValueReference,
						z: value.NumberOfCartAdded.ValueReference,
						visitReference: value.Vy.ValueReference,
						convReference: value.Vx.ValueReference,
						addToCartRateReference: value.NumberOfCartAdded.ValueReference,
						visitComparison: value.Vy.ValueComparison,
						convComparison: value.Vx.ValueComparison,
						addToCartRateComparison: value.NumberOfCartAdded.ValueComparison,
						nbProduct: 0,
						tagType: value.Tag.Type,
						tagName: value.Tag.Name,
						tagValue: value.Tag.Value,
					};
					tagsStudied.data.push(tagStudied);
				});
				const categoryData = response.Hierarchies.map(categorie => {
					return {
						value: categorie.Type,
						label: categorie.Type,
					};
				});
				setCategories(categoryData);
				setTagStudiedEl(tagsStudied);
				setIsLoading(false);
			}, err => {
				console.error(err);
				setIsLoading(false);
			}, signal);
		}

		// Set xUnitLabel and yUnitLabel based on mappingUnits
		if (mappingUnits.x === 'numberofobjectives') {
			setXUnitLabel('Conversions');
		} else {
			setXUnitLabel('Abandonments');
		}

		if (mappingUnits.y === 'numberofsessions') {
			setYUnitLabel('Sessions');
		} else {
			setYUnitLabel('Pages views');
		}

		// Set visitsAverage and addtocartAverage (example values, replace with actual logic)
		setVisitsAverage(100); // Replace with actual logic to calculate visitsAverage
		setAddtocartAverage(50); // Replace with actual logic to calculate addtocartAverage
	};


	useEffect(() => {
		setIsLoading(true);
		if (isFilterSelectedInitialized && !accountHasNoDatas) {
			updateMappingData(categoryTypeSelected);
		}
	}, [ComparisonSelected,
		navFilter,
		mappingUnits,
		filterSelected,
		isFilterSelectedInitialized,
		categoryTypeSelected,
		accountHasNoDatas
	]);
	useEffect(() => {
		const  hasData = getInitialSelectedValues();
		fetchCatergory();
		if(hasData) {
			const arrayOfSelectedFilters = transformToDataStructureArray(hasData);
			setFilterSelected(arrayOfSelectedFilters);
			setIsFilterSelectedInitialized(true);
		}
	}, []);
	useEffect(() => {
		const newArrayOfFilterChips:Chip[] = [];
		filterSelected.map((filter:DataStructure) => {
			if(filter.value.length > 1){
				newArrayOfFilterChips.push({
					type: filter.key,
					title:filter.value[0].name + ' +' + (filter.value.length - 1),
					handleDelete: ()=> {}
				});
			}else{
				newArrayOfFilterChips.push({
					type: filter.key,
					title:filter.value[0].name,
					handleDelete: ()=> {}
				});
			}
		});
		setChipsByFilterSelected(newArrayOfFilterChips);

	}, [ filterSelected]);
	return (
		<ListMappingContext.Provider
			value={{
				accountHasNoDatas,
				isLoading,
				$location,
				insightsKpiPaylod,
				productsSelections,
				fetchExportKpis,
				emercheOrdinate,
				tagStudiedEl,
				currentTab,
				updateMappingData,
				ComparisonSelected,
				handleSelectComparaisonPeriodHandler,
				onChangeNavFilter,
				initialDates,
				dateStringToTimestamp,
				navFilter,
				setNavFilter,
				isCustomSelected,
				setisCustomSelected,
				customDateRange,
				setCustomDateRange,
				updateStorage,
				xUnitLabel,
				yUnitLabel,
				visitsAverage,
				addtocartAverage,
				mappingUnits,
				handleXAxisChange,
				handleYAxisChange,
				handleChangeFormData,
				handleOpenClosePanelForm,
				panelFormIsOpen,
				filterHierarchies,
				selectedValues,
				setSelectedValues,
				chipsByFilterSelected,
				setFilterSelected,
				categoryTypeSelected,
				categories,
				handleSelectNewCategory
			}}
		>
			{children}
		</ListMappingContext.Provider>
	);
};

export default ListMappingContextProvider;