

import { useState, useEffect } from 'react';

type AsyncApiFunction<T> = () => Promise<T>;

export function useApiPollingAsync<T>(apiFunction: AsyncApiFunction<T>, delay: number): T | null {
	const [data, setData] = useState<T | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await apiFunction();
				setData(response);
			} catch (error) {
				console.error(error);
			}
		};

		const intervalId = setInterval(fetchData, delay);

		// Clear the interval on unmount
		return () => clearInterval(intervalId);
	}, [apiFunction, delay]);

	return data;
}


type CallbackSuccessFunction<T> = (data: T) => void;
type CallbackErrorFunction = (error: any) => void;
type CallbackApiFunction<T> = (callbackSuccess: CallbackSuccessFunction<T>, callbackError: CallbackErrorFunction) => void;


export function useCallbackApiPolling<T>(apiFunction: CallbackApiFunction<T>, callbackSuccess : CallbackSuccessFunction<T> | null, callbackError : CallbackErrorFunction | null, delay: number): T | null {
	const [data, setData] = useState<T | null>(null);

	useEffect(() => {
		const fetchData = () => {
			try {
				const setDataCallback = d => {
					setData(d);
					if (callbackSuccess != null) callbackSuccess(d);
				};
				apiFunction(setDataCallback, callbackError || (() => undefined));
			} catch (error) {
				console.error(error);
			}
		};

		const intervalId = setInterval(fetchData, delay);

		// Clear the interval on unmount
		return () => clearInterval(intervalId);
	}, [apiFunction, delay]);

	return data;
}
