import React, { useEffect, useState } from 'react';
import Btn from '../../../Components/Btn';
import { Panel, PanelHeader, PanelBody, PanelSection } from '../../../Components/Panel/Panel';
import Avatar from '../../../Components/Avatar';
import Switch from '../../../Components/Switch';
import { List, ListItem } from '../../../Components/List';
import TabBar from '../../../Components/TabBar';
import TabItem from '../../../Components/TabItem';
import { useAnalyticsConfigContext } from './userAnalyticsConfigContext';
import SearchBar from '../../../Components/SearchBar';

	export default function TenantConfigModal() {
	const {
		handleOpenCloseModalTenant,
		tenantModalIsOpen,
		tenantSelectedToModify,
		referenceData,
		handleChangeTenant,
		isCreation,
	} = useAnalyticsConfigContext();

	const [arrayOfUserSelected, setArrayOfUserSelected] = useState({});
	const [nb, setNb] = useState(0);
	const [oldNameTenant, setOldNameTenant] = useState('');
	const [showAll, setShowAll] = useState(true);
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [usersFiltered, setUsersFiltered] = useState([]);
	const [listJsx, setListJsx] = useState(<></>);

	const handleSelectOrUnSelectAccount = (tenantToChange, user, event) => {
		const value = event.target.checked;
		let newReferenceData = { ...arrayOfUserSelected };
		if (value) {
			const arrayOfTenantFromElem = [...(newReferenceData[tenantToChange] || []), user.id];
			newReferenceData[tenantToChange] = arrayOfTenantFromElem;
		} else {
			const arrayOfTenantFromElem = [...(newReferenceData[tenantToChange] || [])];
			const newArrayOfTenant = arrayOfTenantFromElem.filter(el => el !== user.id);
			newReferenceData[tenantToChange] = newArrayOfTenant;
		}
		setArrayOfUserSelected(newReferenceData);
	};

	useEffect(() => {
		if (tenantSelectedToModify?.users) {
			setArrayOfUserSelected({ ...referenceData?.tenant });
		}
	}, [tenantSelectedToModify]);

	useEffect(() => {
		if (arrayOfUserSelected) {
			getNbUsersInList();
		}
	}, [arrayOfUserSelected, tenantSelectedToModify]);

	useEffect(() => {
		if (isCreation) {
			setOldNameTenant(tenantSelectedToModify.name);
		}
	}, [isCreation]);

	useEffect(() => {
		if (referenceData.users) {
			setUsersFiltered(referenceData.users);
		}
	}, [referenceData.users]);

	useEffect(() => {
		if (referenceData.users) {
			const filteredUsers = referenceData.users.filter(user =>
				user.displayName.toLowerCase().includes(valueInSearchBar.toLowerCase())
			);
			setUsersFiltered(filteredUsers);
		}
	}, [valueInSearchBar, referenceData.users]);
	useEffect(()=>{
		if(usersFiltered && tenantSelectedToModify){
			const list = usersFiltered.map((user, i) => {
				const isSelected = arrayOfUserSelected[tenantSelectedToModify?.name]?.includes(user.id);
				if (showAll || isSelected) {
					return (
						<ListItem key={i}>
							<Switch
								name="role"
								// checked={isSelected}
								// value={isSelected}
								forcedChecked={isSelected}
								onChange={(e) => handleSelectOrUnSelectAccount(tenantSelectedToModify?.name, user, e)}
								size='s'
								position='right'
								align='middle'
							>
								<div className='flex'>
									<div className='flex_item_fix mr_15'>
										<Avatar id={user.id} name={user.displayName} />
									</div>
									<div className="flex_item_full">
										<div className="s_14 fw_medium grey_0">{user.displayName}</div>
										<div className="s_13 grey_2">{user.email}</div>
									</div>
								</div>
							</Switch>
						</ListItem>
					);
				}
				return null;
			});
			setListJsx(list);
		}
	},[usersFiltered,tenantSelectedToModify,showAll,arrayOfUserSelected]);

	function getNbUsersInList() {
		let nbN = 0;
		const users = arrayOfUserSelected[tenantSelectedToModify?.name];
		if (users?.length) {
			nbN = users?.length;
		}
		setNb(nbN > 1 ? nbN + ' users' : nbN + ' user');
	}

	function handleEraseTenant() {
		let newReferenceData = { ...arrayOfUserSelected };
		const arrayOfTenantFromElem = newReferenceData[oldNameTenant];
		delete newReferenceData[oldNameTenant];
		newReferenceData[tenantSelectedToModify.name] = arrayOfTenantFromElem;
		handleChangeTenant(newReferenceData);
	}

	function handleChangeShowAll(b) {
		setShowAll(b);
	}

	return (
		<Panel width={680} isOpen={tenantModalIsOpen} hasClose={false}>
			<PanelHeader
				title="Who has access to this tenant?"
				intro={<span className='text_flag up_case'>{tenantSelectedToModify?.name}</span>}
				onClose={() => handleOpenCloseModalTenant(false)}
				primaryAction={
					<Btn message="Confirm" color="primary" onClick={() => handleChangeTenant(arrayOfUserSelected)} />
				}
			/>
			<PanelSection size="s">
				<div className='flex'>
					<div className='flex_item_full'>
						<TabBar size='l' appearance='rounded'>
							<TabItem message={'All users'} onClick={() => handleChangeShowAll(true)} selected={showAll} />
							<TabItem message={'Users with access'} onClick={() => handleChangeShowAll(false)} selected={!showAll} />
						</TabBar>
					</div>
					<div className='flex_item_fix ml_10'>
						<SearchBar
							placeholder="Search for a user"
							valueInSearchBar={valueInSearchBar}
							onChange={(v) => setValueInSearchBar(v)}
							clearable={true}
						/>
					</div>
				</div>
			</PanelSection>
			<PanelBody>
				{tenantSelectedToModify && (
					<List size='s'>
						{listJsx}
					</List>
				)}
			</PanelBody>
		</Panel>
	);
}