import React, { memo,useState, useEffect, useCallback,useRef} from 'react';
import Modal from '../../../../../Components/Modal';
import ModalHeader from '../../../../../Components/ModalHeader';
import ModalBody from '../../../../../Components/ModalBody';
import ModalFooter from '../../../../../Components/ModalFooter';
// import InputCustom from '../../../../../Components/InputCustom';
// import Checkbox from '../../../../../Components/Checkbox';
import Btn from '../../../../../Components/Btn';
import IconBtn from '../../../../../Components/IconBtn';
import {List, ListItem} from '../../../../../Components/List';
import { v4 as uuidv4 } from 'uuid';
import {TransformationType} from './transformationTypes';
import FieldTransformation from './FieldTransformation';
import FieldTransformationCheckBox from './FieldTransformationCheckBox';
import TypeNumberDictionary from './TypeNumberDictionary';

function getObjectFromReference (objectIdenfifier:TransformationType, uuid){
	const objId = JSON.parse(JSON.stringify(objectIdenfifier));
	const parametersValue = objId.parameters.map(x => {
		return {
			name :x.name,
			isRequired: x.isRequired,
			valueType: x.valueType,
			description: x.description
		};
	});
	const newParams = {};
	for (const key of parametersValue) {
		newParams[key.name] = key.valueType === 'Boolean' ? false : '';
	}
	const newObjectType = {
		name : objId.name,
		uuid:uuid ? uuid :  uuidv4(),
		params : newParams
	};
	return newObjectType;
}

type ErrorOnItem = {
	text: string,
	name: string
}

type TransformationElementLabels = {
	name?: string,
	description?: string
}

function getListParametersByIdTransformation(listArg, typeTransformation){
	return listArg.find(el => el.name ==  typeTransformation);
}

export default memo(function TransformationModal({
	modalIsOpen,
	setModalIsOpen,
	list,
	addTransformation,
	isExistingKey,
	objectTransformationToEdit,
	transformationTypeSelected,
	settransformationTypeSelected,
	getListEmptyFormNewForm,
	setgetListEmptyFormNewForm,

}: any) {

	const [parametersField, setparametersField] = useState<React.JSX.Element[] | undefined>();

	const [selectedTransformationLabels, setSelectedTransformationLabels] = useState<TransformationElementLabels>({});
	const [valueOfParameters, setvalueOfParameters] = useState<any>();

	const [hasErrorsOnFields, sethasErrorsOnFields] = useState<ErrorOnItem[]>([]);

	const [needToRefresh, setNeedToRefresh] = useState(false);
	const [step, setStep] = useState(1);

	const [listOpenTransformationWithEmptyParams, setlistOpenTransformationWithEmptyParams] = useState({});
	const previousValueOfParameters = useRef(valueOfParameters);

	function getFormByTypeOfTransformation (transformationType, valueParam,hasErrorsOnFields,params = null) {
		if(transformationType){
			const parameters = 	getTransformationsFields(transformationType, valueParam,hasErrorsOnFields, params);
			return parameters;
		}
	}
	// Reset useRef when modalIsOpen is true
	useEffect(() => {
		if (modalIsOpen) {
			previousValueOfParameters.current = null;
			sethasErrorsOnFields([]);
			setvalueOfParameters(undefined);
			setlistOpenTransformationWithEmptyParams({});
			setparametersField(undefined);
		}
	}, [modalIsOpen]);
	const handleChange = useCallback((value: any) => {
		settransformationTypeSelected(value);
		setStep(2);
		setlistOpenTransformationWithEmptyParams({});
		setgetListEmptyFormNewForm(true);
	},[]);

	function handleAddOrDeleteParam(paramId: string, bool: boolean) {

		const newList = { ...listOpenTransformationWithEmptyParams };
		
		if (bool) {
			newList[paramId] = '';
		} else {
			delete newList[paramId];
		}

		setNeedToRefresh(true);
		setlistOpenTransformationWithEmptyParams(newList);
	}
	function handleChangeParameters(name, value, valueParam){
		const elementValueClone = JSON.parse(JSON.stringify(valueParam));
		elementValueClone.params[name] = value;
		setvalueOfParameters(elementValueClone);
		setNeedToRefresh(true);
	}
	function handleChangeParametersCheck(event: React.SyntheticEvent, valueParam){

		event.persist();
		const target = event.target as HTMLInputElement;
		const elementValueClone = JSON.parse(JSON.stringify(valueParam));
		const name = target.name;
		const value = target.checked;
		elementValueClone.params[name] = value;
		setvalueOfParameters(elementValueClone);
		setNeedToRefresh(true);
		handleAddOrDeleteParam(name, value);
	}

	function removeCamelCase(str) {
		return str[0] + str.slice(1, str.length).replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`);
	}

	function getTransformationsFields(transformationType: TransformationType, valueParam, hasErrorsOnFields, params){

		return transformationType.parameters.map((x,i) => {

			const isOpen = params && params.hasOwnProperty(x.name);
			if(x.valueType !== 'Boolean' && x.valueType !== 'NumberDictionary'){
				const hasError = hasErrorsOnFields && hasErrorsOnFields.find(d => d.name ===x.name );

				return (
					<ListItem key={x.name + '_' + i}>
						<FieldTransformation
							modalIsOpen={modalIsOpen}
							x={x}
							isOpen={isOpen}
							// valueIsNotUndefined={valueParam.params[x.name] !== null}
							hasError={hasError}
							valueParam={valueParam}
							handleChangeParameters={handleChangeParameters}
							handleAddOrDeleteParam={handleAddOrDeleteParam}
						/>
					</ListItem>
				);
			}
			else if(x.valueType === 'NumberDictionary'){
				return <div key={x.name + '_' + i}>
					<TypeNumberDictionary
						// valueIsNotUndefined={valueParam.params[x.name] !== null}
						modalIsOpen={modalIsOpen}
						valueOfParameters={valueParam}
						handleChangeParameters={handleChangeParameters}

					/>

				</div>;
			}
			else{
				const hasError = hasErrorsOnFields && hasErrorsOnFields.find(d => d.name ===x.name );
				const defaultValue = x.defaultValue !== null ? (x.defaultValue === 'False' ? false : x.defaultValue === 'True' ? true : x.defaultValue) : null;

				const value = valueParam.params[x.name] !== undefined ? valueParam.params[x.name] : defaultValue;

				let checked = (value === 'False') ? false : (value === 'True') ? true : !!value;

				if (valueParam.params !== null) {

					checked = valueParam.params[x.name] === 'True' ? true : valueParam.params[x.name] === 'False' ? false : !!valueParam.params[x.name];
				}

				return (
					<ListItem key={x.name + '_' + i}>

						<FieldTransformationCheckBox
							x={x}
							isOpen={isOpen}
							// valueIsNotUndefined={valueParam.params[x.name] !== null}
							modalIsOpen={modalIsOpen}
							checked={checked}
							hasError={hasError}
							valueParam={valueParam}
							handleChangeParametersCheck={handleChangeParametersCheck}
							handleAddOrDeleteParam={handleAddOrDeleteParam}
						/>
						{hasError && <> Please check this element</>}
					</ListItem>
				);
			}
		});
	}

	function addToTransformationsElement() {
		if (!valueOfParameters) {
			console.error('Les paramètres de la transformation ne sont pas définis.');
			return;
		}

		const elementValidationOfTransformationSelected = list.find(transform => transform.name === transformationTypeSelected);
		const errorsOnItems: ErrorOnItem[] = [];

		const copyOfElementValue = { ...valueOfParameters, params: { ...valueOfParameters.params } };

		if (elementValidationOfTransformationSelected) {
			elementValidationOfTransformationSelected.parameters.forEach(param => {
				const valueOfParam = previousValueOfParameters?.current ? previousValueOfParameters.current.params[param.name] : valueOfParameters.params[param.name];

				if (param?.isRequired) {

					if (valueOfParam === undefined || valueOfParam.length === 0) {
						errorsOnItems.push({
							text: 'This field is required',
							name: param.name
						});
					} else {
						copyOfElementValue.params[param.name] = valueOfParam;
					}
				} else {
					if (listOpenTransformationWithEmptyParams.hasOwnProperty(param.name)) {
						copyOfElementValue.params[param.name] = valueOfParam || (param.valueType === 'Boolean' ? false : '');
					} else {
						delete copyOfElementValue.params[param.name];
					}
				}
			});

			sethasErrorsOnFields(errorsOnItems);
		}


		if (errorsOnItems.length === 0) {
			addTransformation(copyOfElementValue);
		} else {
			const elmentWithParameters = getListParametersByIdTransformation(list, transformationTypeSelected);
			if (elmentWithParameters) {
				const valueFieldsJsx = getFormByTypeOfTransformation(elmentWithParameters, valueOfParameters, errorsOnItems);

				setparametersField(valueFieldsJsx);
			}
		}
	}

	useEffect(()=> {
		setStep(transformationTypeSelected ? 2 : 1);
		setgetListEmptyFormNewForm(false);

		if(transformationTypeSelected && !isExistingKey){
			const elementWithParameters = getListParametersByIdTransformation(list, transformationTypeSelected);
			if(elementWithParameters){
				if(previousValueOfParameters.current === null || !previousValueOfParameters.current){
					const valueParam = getObjectFromReference(elementWithParameters, null);
					const valueFieldsJsx = getFormByTypeOfTransformation(elementWithParameters, valueParam, hasErrorsOnFields);
					if(!valueOfParameters){
						setvalueOfParameters(valueParam);
					}
					setSelectedTransformationLabels({
						name: removeCamelCase(elementWithParameters.name),
						description: elementWithParameters.description
					});
					setvalueOfParameters(valueParam);
					setparametersField(valueFieldsJsx);
				}else{
					const elementWithParameters = getListParametersByIdTransformation(list, previousValueOfParameters.current.name);
					const valueFieldsJsx = getFormByTypeOfTransformation(elementWithParameters, previousValueOfParameters.current,hasErrorsOnFields,previousValueOfParameters.current.params);

					if(!elementWithParameters)return;
					setSelectedTransformationLabels({
						name: removeCamelCase(elementWithParameters.name),
						description: elementWithParameters.description
					});

					if(getListEmptyFormNewForm){
						setlistOpenTransformationWithEmptyParams({...previousValueOfParameters.current.params});
						setvalueOfParameters(previousValueOfParameters.current);
					}
					setparametersField(valueFieldsJsx);

				}
			}
			else{
				setparametersField(undefined);
				setSelectedTransformationLabels({});
				setvalueOfParameters(undefined);
			}
		}
		else if(isExistingKey){
			if(transformationTypeSelected !== objectTransformationToEdit.name){

				const elementWithParameters = getListParametersByIdTransformation(list, transformationTypeSelected);
				const valueParam = getObjectFromReference(elementWithParameters, objectTransformationToEdit.uuid);
				valueParam.uuid = objectTransformationToEdit.uuid;
				const valueFieldsJsx = getFormByTypeOfTransformation(elementWithParameters, valueParam,hasErrorsOnFields,objectTransformationToEdit.params);
				setSelectedTransformationLabels({
					name: removeCamelCase(elementWithParameters.name),
					description: elementWithParameters.description
				});
				setparametersField(valueFieldsJsx);
				if(getListEmptyFormNewForm){
					setvalueOfParameters(valueParam);
					setlistOpenTransformationWithEmptyParams({...objectTransformationToEdit.params});
				}
			}
			else{

				const elementWithParameters = getListParametersByIdTransformation(list, objectTransformationToEdit.name);
				const valueFieldsJsx = getFormByTypeOfTransformation(elementWithParameters, objectTransformationToEdit,hasErrorsOnFields,objectTransformationToEdit.params);

				if(!elementWithParameters)return;
				setSelectedTransformationLabels({
					name: removeCamelCase(elementWithParameters.name),
					description: elementWithParameters.description
				});

				if(getListEmptyFormNewForm){
					setlistOpenTransformationWithEmptyParams({...objectTransformationToEdit.params});
					setvalueOfParameters(objectTransformationToEdit);
				}
				setparametersField(valueFieldsJsx);
			}
		}

	},[list,transformationTypeSelected,isExistingKey, objectTransformationToEdit,modalIsOpen,getListEmptyFormNewForm, listOpenTransformationWithEmptyParams ]);



	useEffect(() => {
		if (needToRefresh && valueOfParameters) {
		  setNeedToRefresh(false);

		  const elmentWithParameters = getListParametersByIdTransformation(list, transformationTypeSelected);
		  if (elmentWithParameters) {
				const valueFieldsJsx = getFormByTypeOfTransformation(elmentWithParameters, valueOfParameters, hasErrorsOnFields);

				if (JSON.stringify(previousValueOfParameters.current) !== JSON.stringify(valueOfParameters)) {
			  setparametersField(valueFieldsJsx);
			  previousValueOfParameters.current = valueOfParameters;
				}
		  }

		  sethasErrorsOnFields([]);
		}
	  }, [needToRefresh, transformationTypeSelected, hasErrorsOnFields, valueOfParameters]);


	return (
		<>
			<Modal
				isOpen={modalIsOpen}
				width={600}
				onClose={() => {setModalIsOpen(false); sethasErrorsOnFields([]);}}
			>

				{step === 1 &&
					<>
						<ModalHeader title="Available transformations" />
						<ModalBody>
							<List size="s" horizontalAreaOffset={true}>
								{list.map((t) =>
									<ListItem
										key={t.name}
										text={removeCamelCase(t.name)}
										description={t.description}
										onClick={() => handleChange(t.name)}
										hasArrow={true}
									/>
								)}
							</List>
						</ModalBody>
					</>
				}
				{step === 2 &&
					<>
						<ModalHeader title="Transformation settings" />
						<div className='modal_section modal_section_grey flex_item_fix'>
							<div className='flex'>
								<div className='flex_item_fix'>
									<IconBtn
										icon="fas fa-arrow-left"
										onClick={() => setStep(1)}
										size="xxl"
										className="modal_header_icon"
										isActive={true}
									/>
								</div>
								<div className='flex_item_full'>
									{selectedTransformationLabels && selectedTransformationLabels.name &&
										<div className='s_17 fw_medium'>{selectedTransformationLabels.name}</div>
									}
									{selectedTransformationLabels && selectedTransformationLabels.description &&
										<div className='s_14 grey_2 mt_2'>{selectedTransformationLabels.description}</div>
									}
								</div>
							</div>
						</div>
						<ModalBody>
							{parametersField &&
								<List size="l">{parametersField}</List>
							}
							{parametersField && parametersField.length === 0 &&
								<>This transformation does not require any configuration.</>
							}
						</ModalBody>
						<ModalFooter
							hasBorder={true}
							primaryAction={
								<Btn
									message={'Add'}
									onClick={() => addToTransformationsElement()}
								/>
							}
							secondaryAction={
								<Btn
									color="secondary"
									style="ghost"
									message={'Cancel'}
									onClick={() => {setModalIsOpen(false);sethasErrorsOnFields([]);}}
								/>
							}
						/>
					</>
				}
			</Modal>
		</>
	);
});


