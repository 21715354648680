import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

export const SelectMultiples = ({ list, label, onChange, initialSelected }) => {
	const animatedComponents = makeAnimated();
	const [valueForCategorie, setValueForCategorie] = useState(initialSelected);

	const handleSelect = (selected) => {
		setValueForCategorie(selected);
		if (onChange) {
			onChange(selected);
		}
	};

	useEffect(() => {
		setValueForCategorie(initialSelected);
	}, [initialSelected]);

	return (
		<div className='mb_15'>
			<label className='fw_medium'>{label}</label>
			<Select
				closeMenuOnSelect={true}
				components={animatedComponents}
				value={valueForCategorie}
				menuPortalTarget={document.body}
				styles={{ menuPortal: base => ({ ...base, zIndex: 20000 }) }}
				menuPosition={'fixed'}
				isMulti
				options={list}
				placeholder={'Select tags'}
				onChange={handleSelect}
			/>
		</div>
	);
};
