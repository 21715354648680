import React, { Fragment } from 'react';
import styles from './Skeleton.module.css';
import {
	ISkeleton,
	IBar,
	IBox,
	IInline,
	IFlex,
	ISquare,
	IRect
} from './types';

function Skeleton({
	appearance = 'paragraphe',
	nb = 1
}: ISkeleton): JSX.Element {

	const repeat = (jsx:React.ReactNode, nb:number) => {
		const result:React.ReactNode[] = [];
		for (let i = 0; i < nb; i++) {
			result.push(
				<Fragment key={i}>{jsx}</Fragment>
			);
		}
		return result;
	};

	switch (appearance) {
		case 'paragraphe':
			return (
				<>
					<Stack>
						<Bar />
						<Bar />
						<Bar width='s' />
					</Stack>
				</>
			);
		case 'title':
			return (
				<>
					<Stack>
						<Bar width='s' height='l' />
						<Bar width='xs' height='s' />
					</Stack>
				</>
			);
		case 'entity':
			return (
				<>
					<Flex>
						<Round width='l' />
						<Box>
							<Bar height="m" width="l" />
							<Bar height="m" width="s" />
						</Box>
					</Flex>
				</>
			);
		case 'emptyState':
			return (
				<>
					TODO
				</>
			);
		case 'kpi':
			return (
				<>
					<Inline>
						{repeat(
							<Box>
								<Bar height='s' width='m' />
								<Bar height="l" width="l" />
							</Box>,
							nb
						)}
					</Inline>
				</>
			);
		case 'sumup':
			return (
				<>
					<Inline>
						{repeat(
							<Flex>
								<Square width='l' />
								<Box>
									<Bar height="m" width="l" />
									<Bar height="m" width="s" />
								</Box>
							</Flex>,
							nb
						)}
					</Inline>
				</>
			);
		case 'tags':
			return (
				<>
					<Inline>
						{repeat(
							<Rect height='s' width='m' />,
							nb
						)}
					</Inline>
				</>
			);

		case 'table':
			return (
				<>
					TODO
				</>
			);
		default: 
			return <></>;
	}
}

function Inline({
	children
}: IInline): JSX.Element {

	return (
		<div className={styles.inline}>
			{children}
		</div>
	);
}

function Flex({
	children,
	alignItems = 'center'
}: IFlex): JSX.Element {

	const style:React.CSSProperties = {
		alignItems: alignItems
	};

	return (
		<div className={styles.flex} style={style}>
			{children}
		</div>
	);
}

function Box({
	children
}: IBox): JSX.Element {

	return (
		<div className={styles.box}>
			{children}
		</div>
	);
}

function Stack({
	children
}: IBox): JSX.Element {

	return (
		<div className={styles.stack}>
			{children}
		</div>
	);
}

function Bar({
	width,
	height = 'm'
}: IBar): JSX.Element {

	const cssClass = [styles.bar];

	if (height) {
		const heightClass = 'bar_height_' + height;
		if (heightClass in styles) {
			cssClass.push(styles[heightClass]);
		}
	}

	if (width) {
		const widthClass = 'bar_width_' + width;
		if (widthClass in styles) {
			cssClass.push(styles[widthClass]);
		}
	}

	return (
		<div className={cssClass.join(' ')}>
			<div className={styles.item_inner}></div>
		</div>
	);
}

function Square({
	width = 'm',
}: ISquare): JSX.Element {

	const cssClass = [styles.square];

	if (width) {
		const widthClass = 'square_width_' + width;
		if (widthClass in styles) {
			cssClass.push(styles[widthClass]);
		}
	}

	return (
		<div className={cssClass.join(' ')}>
			<div className={styles.item_inner}></div>
		</div>
	);
}

function Round({
	width = 'm',
}: ISquare): JSX.Element {

	const cssClass = [styles.round];

	if (width) {
		const widthClass = 'round_width_' + width;
		if (widthClass in styles) {
			cssClass.push(styles[widthClass]);
		}
	}

	return (
		<div className={cssClass.join(' ')}>
			<div className={styles.item_inner}></div>
		</div>
	);
}

function Rect({
	width = 'l',
	height = 'm',
}: IRect): JSX.Element {

	const cssClass = [styles.rect];
	const style:React.CSSProperties = {};

	if (width && typeof width === 'string') {
		const widthClass = 'rect_width_' + width;
		if (widthClass in styles) {
			cssClass.push(styles[widthClass]);
		}
	}

	if (height && typeof height === 'string') {
		const heightClass = 'rect_height_' + height;
		if (heightClass in styles) {
			cssClass.push(styles[heightClass]);
		}
	}

	if (width && typeof width === 'number') {
		style.width = width + 'px';
	}

	if (height && typeof height === 'number') {
		style.height = height + 'px';
	}

	return (
		<div className={cssClass.join(' ')} style={style}>
			<div className={styles.item_inner}></div>
		</div>
	);
}

export {
	Skeleton,
	Bar as SkeletonBar,
	Box as SkeletonBox,
	Inline as SkeletonInline,
	Flex as SkeletonFlex,
	Stack as SkeletonStack,
	Square as SkeletonSquare,
	Round as SkeletonRound,
	Rect as SkeletonRect
};
