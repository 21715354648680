import React from 'react';
import SelectDropdown from '../../../../../../Components/SelectDropdown';
import styles from '../../funnel.module.css';

export const SplitBySelect = ({context}:any):React.ReactElement => {

	const {
		rawDimensions,
		splitSelected,
		handleSelectNewSplit,
		filterToExcludeSplit
	} = context;

	if(!rawDimensions){
		return <></>;
	}
	const dimensionsFilterList = Object.keys(rawDimensions).map(dimension => {
		return {
			value : rawDimensions[dimension].id,
			label : rawDimensions[dimension].label
		};
	});
	dimensionsFilterList.unshift({
		value : 'none',
		label : 'None'
	});

	return (
		<>
			<span className={styles.headerSplitLabel}>split by</span>
			<SelectDropdown
				optionsList={dimensionsFilterList.filter(x => !filterToExcludeSplit.includes(x.value))}
				value={splitSelected}
				onChange={(v) =>handleSelectNewSplit(v)}
				color={'white'}
				appearance="ghost_underline"
			/>
		</>
	);
};
