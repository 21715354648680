import React from 'react';
import moment from 'moment';
import { CSSProperties } from 'react';
import { ProcessModel } from '../context/typesWorkflow';
import Checkbox from '../../../../Components/Checkbox';

const requestStyle: CSSProperties = {
	marginBottom: '8px',
	padding: '4px',
	border: '1px solid #ccc',
	borderRadius: '4px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
};

const selectedStyle: CSSProperties = {
	border: '2px solid #00f', // Bordure bleue légère
};

interface RequestItemProps {
  states: ProcessModel;
  isSelected: boolean;
  onSelectionChange: () => void;
}

const RequestItemSelection: React.FC<RequestItemProps> = ({ states, isSelected, onSelectionChange }) => {
	return (
		<div style={{ ...requestStyle, ...(isSelected ? selectedStyle : {}) }}>
			<Checkbox
				name="restart"
				forcedChecked={isSelected}
				value={isSelected}
				onChange={onSelectionChange}
			>
			</Checkbox>
			<div>
				<strong>Status:</strong> {states.processState}
			</div>
			<div>
				<strong>Date:</strong> {moment(states.stateDateUtc).format('dddd, MMMM Do YYYY')}
			</div>
			<div>
				<strong>Message:</strong> {states.message}
			</div>
			<div>
				<strong>Process Identifier:</strong> {states.processIdentifier}
			</div>
			<div>
				<strong>Last Success Date:</strong> {states.lastSuccessDateUtc ? moment(states.lastSuccessDateUtc).format('dddd, MMMM Do YYYY') : 'N/A'}
			</div>
		</div>
	);
};

export default RequestItemSelection;