import React, { useState, useEffect } from 'react';
import {datesOptions, findNavLabel} from './config';
import styles from './Nav.module.css';
import moment from 'moment';
import Dropdown from '../../Components/Dropdown';
import Listbox from '../../Components/Listbox';
import ListboxItem from '../../Components/ListboxItem';
import CustomPicker from './CustomPicker';
import { useTranslation } from 'react-i18next';

// TODO @Romain : Move into a generic component

const Nav = ({
	handleClick,
	initialDates,
	navLabel,
	showComparisonRemover = false,
	setComparisonIsEnable,
	setisCustomSelected,
	isCustomSelected = false,
	customDateRange,
	setCustomDateRange,
	handleSaveDate = ()=>null
}) => {

	const [t] = useTranslation(['utils']);
	const [current, setCurrent] = useState(null);
	const [focusedInput, setFocusedInput] = useState('startDate');
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const labels =  Object.keys(datesOptions);

	const handleOnDateChange = ({ startDate, endDate }) => {
		setCustomDateRange({
			startDate: startDate,
			endDate: endDate
		});
	};

	const onFocused = (focused) => {
		if (focused === null) {
			// setDropdownIsOpen(false);
		}
		setFocusedInput(!focused ? 'startDate' : focused);
	};
	const onHandleClick = (label) => {
		if (label === '') return;
		
		setCurrent({ ...datesOptions[label], label: label });
		if (label !== 'Custom') {		
			setisCustomSelected(false);
			setDropdownIsOpen(false);
			handleClick(datesOptions[label]);
		}
		if (label === 'Custom') {
			setisCustomSelected(true);
		}
	};
	const handleValidDate = () => {
		setDropdownIsOpen(false);
		handleClick(
			{
				fromDate: customDateRange.startDate,
				toDate: customDateRange.endDate
			});
	};

	const handlePreventToggleDropdown = (e) => {
		e.stopPropagation();
	};

	const handleRemoveComparison = (e) => {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		if (typeof setComparisonIsEnable === 'function') {
			setComparisonIsEnable(false);
		}
	};
	useEffect(()=>{
		if(initialDates && current === null){
			setCurrent(
				{ ...datesOptions[initialDates.initialLabel],
					label: findNavLabel(initialDates.initialFrom,initialDates.initialTo ) });
		}
	},[initialDates]);
	useEffect(()=>{
		handleSaveDate(current);
	},[current]);
	if (!navLabel) {
		navLabel = t('date.Period');
	}

	const button = (
		<button type="button" className="custom_input clickable m w_auto bg_white">
			<span className="custom_input_value has_label">
				<span className="custom_input_value_label">{navLabel}</span>
				{isCustomSelected &&
					<>
						{moment(customDateRange.startDate).format('MMM DD, YYYY')}
						<i className="analytics_range_arrow fas fa-arrow-right"></i>
						{moment(customDateRange.endDate).format('MMM DD, YYYY')}
					</>
				}
				{!isCustomSelected && current && current.translation &&
					<>{t(current.translation.key, current.translation.vars)}</>
				}
			</span>
			<i className="custom_input_icon fas fa-calendar-alt"></i>
			{showComparisonRemover &&
				<div className="flex_item_fix analytics_range_remove"
					onMouseDown={(e) => handlePreventToggleDropdown(e)}onClick={(e) => handleRemoveComparison(e)}>
					<i className="fas fa-times"></i>
				</div>
			}
		</button>
	);


	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={(e) => setDropdownIsOpen(true)}
			onHide={(e) => setDropdownIsOpen(false)}
			button={button}
			flip={false}
		>
			<div className="flex flex_align_start">
				<div className="flex_item_full_fix">
					<Listbox>
						{labels.map((label) => {
							const isSelected = isCustomSelected ? label === 'Custom' : (current?.label) === label;
							const item = datesOptions[label];
							return (
								<ListboxItem
									key={label}
									onClick={() => onHandleClick(label)}
									selected={isSelected}
								>
									{item.translation &&
										<>{t(item.translation.key, item.translation.vars)}</>
									}
								</ListboxItem>
							);
						})}
					</Listbox>
				</div>
				<CustomPicker 
					isCustomSelected={isCustomSelected}
					customDateRange={customDateRange}
					onFocused={onFocused}
					focusedInput={focusedInput}
					handleValidDate={handleValidDate}
					handleOnDateChange={handleOnDateChange}
				/>
			</div>
		</Dropdown>
	);
};


Nav.displayName = 'Nav';
export default Nav;