import React from 'react';
import Modal from '../../../Components/Modal';
import Btn from '../../../Components/Btn';
import AutocompleteTagContainer from '../Edition/Mapping/AutocompleteTagContainer';
import InputCustom from '../../../Components/InputCustom';

export default function ModalSelectedTags({
	selectedTagModalIsOpen,
	setSelectedTagModalIsOpen,
	tagSelected,
	isExistingObj,
	handleChangeTag,
	submitNewTag,
	setupAccountServices,
	accountId,
	tenant,
	useAutoComplete
}) {

	return (
		<Modal
			isOpen={selectedTagModalIsOpen}
			onClose={() => setSelectedTagModalIsOpen(false)}
		>
			<div className="modal_header has_border">
				<div>
					{isExistingObj ? 'Edit ' + tagSelected : 'New tag'}
				</div>
			</div>
			<div className="modal_body">
				<div>
					{useAutoComplete &&
						<AutocompleteTagContainer
							label="Tag name"
							tenant={tenant}
							setupAccountServices={setupAccountServices}
							accountId={accountId}
							referenceDataType={'pagetag'}
							value={tagSelected}
							onChange={(value) => handleChangeTag(value)}
						/>
					}
					{!useAutoComplete &&
						<InputCustom
							label='Tab name'
							value={tagSelected}
							onChange={(e) => {
								e.preventDefault();
								const value = e.target.value;
								handleChangeTag(value);
							}}
						></InputCustom>
					}
				</div>
			</div>
			<div className="modal_footer has_border al_right">
				<Btn
					message={'Cancel'}
					style={'ghost'}
					color={'secondary'}
					onClick={(e) => setSelectedTagModalIsOpen(false)}
				/>
				<Btn
					message={'Ok'}
					onClick={() => submitNewTag()}
					disabled={tagSelected === ''}
				/>
			</div>

		</Modal>
	);
}
