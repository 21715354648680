import React from 'react';
import CreateFilterContextProvider from './contextFilters';
import FiltersList from './FiltersList';
export default function ProviderFilter({
	userfiltersList,
	operator,
	ListFilterAvailable,
	onAddFilter,
	onRemoveFilter,
	onEditFilter,
	handleChangeOperator,
	setupAccountServices,
	accountId,
	tenantSelected,
	previewTenant,
	needTenantForSearch,
	getCheckIfIsAValidUrlFetch
}) {

	return (
		<CreateFilterContextProvider
			ListFilterAvailable={ListFilterAvailable}
			userfiltersList={userfiltersList}
			getCheckIfIsAValidUrlFetch={getCheckIfIsAValidUrlFetch}
		>
			<FiltersList
				previewTenant={previewTenant}
				tenantSelected={tenantSelected}
				needTenantForSearch={needTenantForSearch}
				setupAccountServices={setupAccountServices}
				accountId={accountId}
				ListFilterAvailable={ListFilterAvailable}
				userfiltersList={userfiltersList}
				operator={operator}
				onAddFilter={onAddFilter}
				onRemoveFilter={onRemoveFilter}
				onEditFilter={onEditFilter}
				handleChangeOperator={handleChangeOperator}
			/>
		</CreateFilterContextProvider>
	);
}
