import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Dropdown from './Dropdown';
import { HexColorPicker, RgbaColorPicker } from 'react-colorful';
import Fieldset from './Fieldset';

export default function ColorPicker({
	value = '#ffffff',
	onChange,
	placeholder = '',
	label = '',
	labelTooltip = '',
	description = '',
	isAnError,
	fullWidth = false,
	size = 'm',
	className = '',
	labelClassName = '',
	blockClassName = '',
	blockSize,
	style,
	selectOnFocus = true,
	autoFocus = false,
	disabled = false
}) {

	const inputId = uuidv4();
	const inputClass = ['custom_input'];
	const [ddIsOpen, setddIsOpen] = useState(false);
	const ref = useRef(null);
	const colorFormat = (value.indexOf('rgba') > -1) ? 'rgba' : 'hexa';
	const defaultValue = colorFormat == 'rgba' ? 'rgba(0,0,0,1)' : '#000000';
	const [rgbaValue, setRgbaValue] = useState({});

	useEffect(() => {
		if( colorFormat == 'rgba' ){
			let v = value;
			v = v.replace('rgba(', '');
			v = v.replace(')', '');
			const split = v.split(',');
			setRgbaValue({
				'r': parseInt(split[0], 10),
				'g': parseInt(split[1], 10),
				'b': parseInt(split[2], 10),
				'a': parseFloat(split[3])
			});
		}
	}, [ddIsOpen]);

	if( fullWidth ){
		inputClass.push('w_full');
	} else {
		inputClass.push('w_' + size);
	}
	if( style === 'ghost' ){
		inputClass.push('ghost');
	}
	if( className ){
		inputClass.push(className);
	}
	if( disabled ){
		inputClass.push('disabled');
	}

	const rgbaChange = (v) => {
		let rgba = 'rgba(' + Object.values(v).join(',') + ')';
		change(rgba);
	};

	const change = (v) => {
		if (disabled) return;
		if (typeof onChange === 'function') {
			onChange(v || defaultValue);
		}
	};

	const onFocus = (e) => {
		if( selectOnFocus ){
			e.target.select();
		}
		setddIsOpen(true);
	};

	const onClick = (e) => {
		e.stopPropagation();
		e.nativeEvent.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
	};

	const input = (
		<>
			<label className={inputClass.join(' ')} ref={ref} onClick={(e) => onClick(e)}>
				<span className="input_color" style={{'backgroundColor': value}}></span>
				<input
					type="text"
					id={inputId}
					onChange={(e) => change(e.target.value)}
					value={value}
					placeholder={placeholder ? placeholder : ''}
					onFocus={(e) => onFocus(e)}
					autoFocus={autoFocus}
					disabled={disabled}
				/>
			</label>
			{isAnError &&
                <div className="custom_input_sub_error">{isAnError}</div>
			}
			{description &&
                <div className="custom_input_sub_desc">{description}</div>
			}
			<Dropdown
				isOpen={ddIsOpen}
				onHide={() => setddIsOpen(false)}
				setIsOpen={() => setddIsOpen(true)}
				buttonRef={ref}
			>
				{colorFormat == 'hexa' &&
					<HexColorPicker
						color={value}
						onChange={(e) => change(e)}
						disabled={disabled}
					/>
				}
				{colorFormat == 'rgba' &&
					<RgbaColorPicker
						color={rgbaValue}
						onChange={(e) => rgbaChange(e)}
						disabled={disabled}
					/>
				}
			</Dropdown>
		</>
	);

	if( label ){
		return (
			<>
				<Fieldset
					inputID={inputId}
					label={label}
					description={description}
					labelTooltip={labelTooltip}
					labelClassName={labelClassName}
					blockClassName={blockClassName}
					blockSize={blockSize}
				>
					{input}
				</Fieldset>
			</>
		);
	}

	return input;
}
