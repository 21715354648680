import React from 'react';
import Modal from '../../Components/Modal';
import { ReportGeneratorContextProvider } from './Context';
import ReportGeneratorSteps from './ReportGeneratorSteps';
import './ReportGeneratorModal.css';
import {onSiteContext} from '../Dashboard/OnSite/context';
import SearchBar from '../../Components/SearchBar';
import LabelSelectDropdown from '../Labels/LabelSelectDropdown';
import StatusFilterSelector from '../Dashboard/OnSite/List/StatusFilterSelector';
import {useTranslation} from 'react-i18next';

export default function ReportGeneratorModal({
	isOpen,
	onClose,
	toggleFilterLabels,
	resetFilterLabels
}) {

	const {
		accountId,
		$rootScope,
		$routeParams,
		labelServices,
		filterStatusMode,
		filterTestMode,
		AuthServices,
		$http,
		systemServices,
		devices,
		needFetchList,
		setneedFetchList,
		client,
		searchBarvalue,
		filterBylabels,
		setLoadingCampaign,
		setSearchValue,
		searchValue,
		filterLabelId,
		isAdmin,
		LabelsAccount,
		canEditCampaigns,
		statusArray,
		setstatusArray,
		setFilterTestMode,
		setFilterStatusMode,
		setmodalLabelsAccountisOpen
	} = onSiteContext();
	const [t] = useTranslation(['common']);
	return (
		<ReportGeneratorContextProvider
			accountId={accountId}
			systemServices={systemServices}
			$http={$http}
			AuthServices={AuthServices}
			$routeParams={$routeParams}
			$rootScope={$rootScope}
			needFetchList={needFetchList}
			setneedFetchList={setneedFetchList}
			client={client}
			labelServices={labelServices}
			filterStatusMode={filterStatusMode}
			filterTestMode={filterTestMode}
			searchBarvalue={searchBarvalue}
			filterBylabels={filterBylabels}
			devices={devices}
			setLoadingCampaig={setLoadingCampaign}
		>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				height={900}
				width={1200}
			>
				<ReportGeneratorSteps filterCampaigns={<div className="flex_item_full">
					<div className="btn_group_l">
						<SearchBar
							onChange={(value) => {
								setSearchValue(value);
							}}
							valueInSearchBar={searchValue}
							placeholder={t('search.searchCampaign')}
							fullWidth={false}
							size="l"
							width="l"
						/>
						<LabelSelectDropdown
							selectableLabels={LabelsAccount || []}
							selectedLabelIds={filterLabelId || []}
							onLabelSelected={l => toggleFilterLabels(l)}
							resetFilterLabels={resetFilterLabels}
							canEdit={(isAdmin || canEditCampaigns)}
							onEditRequested={() => setmodalLabelsAccountisOpen(true)}
							showEditLabels={false}
						/>
						<StatusFilterSelector
							filterStatusMode={filterStatusMode}
							setFilterStatusMode={(e) => setFilterStatusMode(e)}
							filterTestMode={filterTestMode}
							setFilterTestMode={(e) => setFilterTestMode(e)}
							statusArray={statusArray}
							setstatusArray={(e) => setstatusArray(e)}
						/>
					</div>
				</div>} />
			</Modal>
		</ReportGeneratorContextProvider>
	);

}