import React, { useState } from 'react';
import { useEmailRemarketing } from '../context/contextEmailRemarketing';

export const HeaderDashboard = () => {
	const { campaigns } = useEmailRemarketing();
	const [dateRange, setDateRange] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [filterState, setFilterState] = useState('');

	const handleRefresh = () => {
		console.log('Refreshing campaign stats...');
	};

	return (
		<>
			<div className="modules-header">
				<h2>Email Remarketing</h2>
				{campaigns.length > 0 && (
					<div className="header_inbox">
						<a className="btnUi btn_primary" href="/CampaignsCrmOnSite/Create">
							<i className="fa fa-plus"></i>
							<span className="btn_txt">Create a remarketing campaign</span>
						</a>
						<div className="fromto-wrapper">
							<p className="fromto_date">
								<input
									type="text"
									value={dateRange}
									onChange={(e) => setDateRange(e.target.value)}
									placeholder="Select date range"
								/>
							</p>
							<button onClick={handleRefresh}>
								<i className="fa fa-sync-alt"></i>
							</button>
						</div>
					</div>
				)}
			</div>
			{campaigns.length > 0 && (
				<aside>
					<input
						type="text"
						placeholder="Search for a campaign remarketing"
						className="b-bottom"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
					<label className="label-select">
						<select
							className="select-dropdown"
							value={filterState}
							onChange={(e) => setFilterState(e.target.value)}
						>
							<option value="">On going or Stopped</option>
							<option value="ongoing">On going</option>
							<option value="stopped">Stopped</option>
							<option value="past">Past</option>
						</select>
					</label>
				</aside>
			)}
		</>
	);
};

export default HeaderDashboard;