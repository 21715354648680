import React, {useState, useEffect} from 'react';
import { ReactLiquid } from 'react-liquid';
import Iframe from '../../../Components/Iframe.js';
import { getCSS } from './mockupCSS.js';

enum DeviceType {
	Desktop = 'desktop',
	Mobile = 'mobile',
}

type Props = {
	device: DeviceType;
	formatType?: string,
	html: string;
	className?: string;
	liquidModel: any;
	inCatalog?: boolean;
	refAttr?:any;
	needMockup?: boolean;
}

export default function TemplateRender({
	device,
	formatType,
	html,
	className,
	liquidModel = {},
	inCatalog = false,
	refAttr,
	needMockup = true
}:Props) {

	const [mockup, setMockup] = useState('');

	const getMockup = (formatID) => {
		if (!needMockup) return '';
		if (formatID === 'inpage' || formatID === 'INAPP' || formatID === 'CUSTOM') return 'inpage';
		if (formatID === 'headerbar' || formatID === 'HEADERBAR') return 'headerbar';
		if (formatID === 'popin' || formatID === 'toaster' || formatID === 'OVERLAY') return 'overlay';
		return '';
	};

	useEffect(() => {
		if (formatType) {
			setMockup(getMockup(formatType));
		}
	}, [formatType]);

	return (
		<ReactLiquid
			template={html}
			data={liquidModel}
			render={(renderedTemplate) => {
				return (
					<Iframe className={className}>
						<style>@import url('https://tag.beyable.com/fonts/_v1/all.min.css');</style>
						<style>{getCSS(mockup)}</style>
						{mockup === 'inpage' &&
							<div className="mockup_wrapper mockup_wrapper_inpage">
								{device === 'desktop' ?
									<>
										<div className="mockup_row mockup_row_full">
											<div className="mockup_col mockup_col_full"></div>
										</div>
										<div className="mockup_row mockup_row_fix">
											<div className="mockup_col mockup_col_full"></div>
											<div className="mockup_col mockup_col_fix">
												<span className={[device, inCatalog ? 'in_catalog' : ''].join(' ')} ref={refAttr} dangerouslySetInnerHTML={renderedTemplate} />
											</div>
											<div className="mockup_col mockup_col_full"></div>
										</div>
										<div className="mockup_row mockup_row_full">
											<div className="mockup_col mockup_col_third"></div>
											<div className="mockup_col mockup_col_third"></div>
											<div className="mockup_col mockup_col_third"></div>
										</div>
									</>
									:
									<>
										<div className="mockup_row mockup_row_full">
											<div className="mockup_col mockup_col_full"></div>
										</div>
										<div className="mockup_row mockup_row_fix">
											<div className="mockup_col mockup_col_full">
												<span className={[device, inCatalog ? 'in_catalog' : ''].join(' ')} ref={refAttr} dangerouslySetInnerHTML={renderedTemplate} />
											</div>
										</div>
										<div className="mockup_row mockup_row_full">
											<div className="mockup_col mockup_col_full"></div>
										</div>
									</>
								}
							</div>
						}
						{mockup === 'headerbar' &&
							<div className="mockup_wrapper">
								<div className="mockup_row mockup_row_fix mockup_row_no_margin">
									<div className="mockup_col mockup_col_full">
										<span className={[device, inCatalog ? 'in_catalog' : ''].join(' ')} ref={refAttr} dangerouslySetInnerHTML={renderedTemplate} />		
									</div>
								</div>
								<div className="mockup_row mockup_row_fix" style={{height: 40}}>
									<div className="mockup_col mockup_col_full"></div>
								</div>
								<div className="mockup_row mockup_row_fix" style={{height: 120}}>
									<div className="mockup_col mockup_col_full"></div>
								</div>
								<div className="mockup_row mockup_row_full">
									{device === 'desktop' ?
										<>
											<div className="mockup_col mockup_col_quarter"></div>
											<div className="mockup_col mockup_col_half"></div>
											<div className="mockup_col mockup_col_quarter"></div>
										</>
										:
										<>
											<div className="mockup_col mockup_col_full"></div>
										</>
									}
								</div>
							</div>
						}
						{mockup === 'overlay' &&
							<>
								<div className="mockup_wrapper mockup_wrapper_overlay">
									<div className="mockup_row mockup_row_fix" style={{height: 40}}>
										<div className="mockup_col mockup_col_full"></div>
									</div>
									<div className="mockup_row mockup_row_fix" style={{height: 120}}>
										<div className="mockup_col mockup_col_full"></div>
									</div>
									<div className="mockup_row mockup_row_full">
										{device === 'desktop' ?
											<>
												<div className="mockup_col mockup_col_quarter"></div>
												<div className="mockup_col mockup_col_half"></div>
												<div className="mockup_col mockup_col_quarter"></div>
											</>
											:
											<>
												<div className="mockup_col mockup_col_full"></div>
											</>
										}
									</div>
									<div className="mockup_row mockup_row_fix" style={{height: 40}}>
										<div className="mockup_col mockup_col_full"></div>
									</div>
								</div>
								<span className={[device, inCatalog ? 'in_catalog' : ''].join(' ')} ref={refAttr} dangerouslySetInnerHTML={renderedTemplate} />
							</>
						}
						{!mockup &&
							<span className={[device, inCatalog ? 'in_catalog' : ''].join(' ')} ref={refAttr} dangerouslySetInnerHTML={renderedTemplate} />
						}
					</Iframe>
				);
			}}
		/>
	);
}

