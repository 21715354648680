import React, { createContext, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import SystemServices from '../../../../Services/SystemServices';
import CrossSellUpSellServices from '../../../../Services/CrossSellUpSellServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import ProductEMerchServices from '../../../../Services/ProductEMerchServices';
import SetupAccountServices from '../../../../Services/SetupAccountServices';
import AccountTenantServices from '../../../../Services/AccountTenantServices';

import {
	getProductCollation,
	getProductCollationContexts,
	getRecommendation,
	createReco,
	putRecoById,
	getPreview, getCheckIfIsAValidUrl
} from '../services/recommendationsServices';
import { capitalizeFirstLetter } from '../utils';
import { ContextList, ContextListWithoutCategory } from '../../utils/types';
import EventTrackingServices from '../../../../Services/EventTrackingServices';
function getUniqueSortingFactors(arrayToFilter) {
	const uniqueItem = arrayToFilter.reduce((acc, curr) => {
		if (curr.collationId === 
			'cfe9fc4b-ee69-4359-82ee-496f787d9414') {
			acc = [...acc, curr.params[0].value.stringValues[0]];
		}
		return acc;
	}, []);

	return [...new Set(uniqueItem)];
}
const RecommendationContext = createContext();

function useRecommendationContext() {
	return useContext(RecommendationContext);
}

const CreateRecommendationsContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;
	const AuthServices = props.AuthServices;

	const crossSellUpSellServices = new CrossSellUpSellServices($http);
	const systemServices = new SystemServices($rootScope, $timeout);
	const setupAccountServices = new SetupAccountServices(props.$http);
	const impersonatingServices = new ImpersonatingServices(
		props.$rootScope,
		$routeParams
	);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const accountTenantServices = new AccountTenantServices(props.$http,
		AuthServices, accountId
	);
	const { trackEvent } = EventTrackingServices(props.$rootScope.User);

	const getProductFeedCategory = (
		accountId,
		tenant,
		callbackSuccess,
		callbackError
	) =>
		crossSellUpSellServices.getProductFeedCategory(
			accountId,
			tenant,
			callbackSuccess,
			(error) => systemServices.showError()
		);
	const recoServices = new ProductEMerchServices(
		$http,
		AuthServices,
		accountId
	);
	const [mode, setMode] = useState('creation');
	const [accountWithoutCategories, setAccountWithoutCategories] = useState(false);
	const [loadingProductList, setloadingProductList] = useState(false);
	const [errorNoConfiguration, setErrorNoConfiguration] = useState(false);
	const [loadingSaving, setloadingSaving] = useState(false);
	const [loadingSettings, setloadingSettings] = useState(true);

	const [productList, setproductList] = useState([]);

	const [allCategoriesData, setallCategoriesData] = useState();
	const [typeCategories, setTypeCategories] = useState();
	const [categorySelected, setCategorySelected] = useState();
	const [typeCategoriesSecondLevel, settypeCategoriesSecondLevel] =
		useState();
	const [
		typeCategoriesSecondLevelSelected,
		setTypeCategoriesSecondLevelSelected,
	] = useState();
	const [recommendationProduct, setrecommendationProduct] = useState({
		name: 'New recommendation rule',
		context: 'Global',
		sorting: [],
		tenant: null,
		filtering: {
			op: 'And',
			filters: [],
		},
		groupSorting: null,
		transformations: [],
	});

	const [previewTenant, setpreviewTenant] = useState({
		label: 'Default',
		value: ''
	});
	const [tenantsCatalog, setTenantsCatalog] = useState([]);

	const [inputProductBasedOn, setinputProductBasedOn] = useState('');
	const [listIdProducts, setlistIdProducts] = useState([]);
	const [autocompleteListIsLoading, setautocompleteListIsLoading] = useState(false);
	const [valueOfSelectedproduct, setvalueOfSelectedproduct] = useState({
		label: 'Choose',
		value: ''
	});
	const [inputTagBasedOn, setinputTagBasedOn] = useState('');
	const [listTags, setlistTags] = useState([]);
	const [valueOfSelectedTag, setvalueOfSelectedTag] = useState({
		label: 'Choose',
		value: ''
	});
	const [autocompleteTagListIsLoading, setautocompleteTagListIsLoading] = useState(false);

	const [recoName, setrecoName] = useState('');
	const [originalRecoRule, setOriginamRecoRule] = useState();
	const [modalConfirmIsOpen, setmodalConfirmIsOpen] = useState(false);
	const [modalCancelActions, setmodalCancelActions] = useState(false);

	const [newPaginationNeed, setNewPaginationNeed] = useState(false);
	const [contextsBasedOn, setContextsBasedOn] = useState([]);
	const [collationContexts, setCollationContexts] = useState([]);

	const [ListFilter, setListFilter] = useState();
	const [listTenantAccount, setlistTenantAccount] = useState([
		{
			label: 'Default',
			value: '',
		},
	]);

	const [currentPageProductList, setCurrentPageProductList] = useState(1);
	const [paginationOptions, setpaginationOptions] = useState({
		poffset: 0,
		plimit: 20,
	});
	const [deviceType, setdeviceType] = useState('Mobile');
	const [needRefreshPagination, setneedRefreshPagination] = useState(false);
	const [idInClipBoard, setidInClipBoard] = useState('');
	const [descriptionRule, setdescriptionRule] = useState('');
	const [needToRefreshTenantCategory, setneedToRefreshTenantCategory] = useState(false);
	const [isFirstRender, setisFirstRender] = useState(true);
	const [arrayOfValuetags, setarrayOfValuetags] = useState([]);

	function camelCaseToDisplay(str) {
		const result = str.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
		const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
		return finalResult;
	}

	const addDisplayNameToCollation = (coll) => {
		coll.forEach(group => {
			if (!('itemList' in group)) return;

			var itemList = group.itemList;
			itemList.forEach(item => {
				item.name = camelCaseToDisplay(item.name);
			});
		});
	};

	const GetProductCollation = (context) => {
		getProductCollation(context, systemServices, recoServices,
			(newData) => {
				addDisplayNameToCollation(newData.forFiltering);
				addDisplayNameToCollation(newData.forSorting);
				addDisplayNameToCollation(newData.transformations);
				setloadingSettings(false);
				setListFilter(newData);
			},
			(error) => {
				setloadingSettings(false);
			}
		);
	};
	const getCollationContexts = () => {
		getProductCollationContexts(systemServices, recoServices, data => {
			setCollationContexts(data);
		});
	};
	const getCheckIfIsAValidUrlFetch = async (url) => {
		return new Promise((resolve) => {
			getCheckIfIsAValidUrl(
				url,
				systemServices,
				recoServices,
				(data) => {
					resolve({
						boolean :true,
						errorMessage : null
					});
				},
				(error ,dataErr) => {

					resolve({
						boolean : false,
						errorMessage : dataErr.data
					});
				}
			);
		});
	};

	function hasErrorNoConfiguration(data){
		if(data.status == '404'){
			// systemServices.showError(data.data);
			setErrorNoConfiguration(true);
			setloadingProductList(false);
			setloadingSettings(false);
		}else{
			systemServices.showError();
			setloadingProductList(false);
			setloadingSettings(false);
		}
	}
	const sortByAsc = (a, b) => {

		if (a.label.toUpperCase() < b.label.toUpperCase()) return -1;
		if (a.label.toUpperCase() > b.label.toUpperCase()) return 1;
		return 0;
	
	};
	const GetTenant = () => {
		accountTenantServices.getTenantsAccount(
			tenantL => {
				const listOfTenant = tenantL.map((tenant) => {
					if (tenant === '') {
						return {
							label: 'Default',
							value: tenant,
						};
					} else {
						return {
							label: tenant,
							value: tenant,
						};
					}
				});
				if (listOfTenant.length == 0){
					listOfTenant.push({
						label: 'Default',
						value: '',
					});
				}
				else if (listOfTenant.length > 1) {
					listOfTenant.unshift({
						label: 'All',
						value: 'all'
					});
				}
				const listOfTenantSort = listOfTenant.sort(sortByAsc);
				if (listOfTenantSort.length == 0){
					listOfTenant.unshift({
						label: 'Default',
						value: ''
					});
				}
				setpreviewTenant(listOfTenantSort[0]);
				setlistTenantAccount(listOfTenantSort);
			},
			(Error,errMessage) => {
				hasErrorNoConfiguration(errMessage);

			}
		);
	};

	const getTenantsCatalogTenant = () => {
		accountTenantServices.getTenantsCatalogTenant(
			(tenantL) => {
				const listOfTenant = tenantL.map((tenant) => {
					if (tenant === '') {
						return {
							label: 'Default',
							value: tenant,
						};
					} else {
						return {
							label: tenant,
							value: tenant,
						};
					}
				});
				if (listOfTenant.length > 1) {
					listOfTenant.unshift({
						label: 'All',
						value: null
					});
				}
				const listOfTenantSort = listOfTenant.sort(sortByAsc);
				setTenantsCatalog(listOfTenantSort);
				handleSelectMode(listOfTenantSort);
			},
			(Error,errMessage) => {
				hasErrorNoConfiguration(errMessage);

			}
		);
	};
	function deleteIdFilterFromFilteringObject(filtergingObjToTranform) {
		return filtergingObjToTranform.map(
			(curr) => {
				const obj = { ...curr };
				if (obj.hasOwnProperty('expression') && obj['expression'] !== null) {
					return {
						expression: {
							op: obj['expression']['op'],
							filters:
								obj.expression.filters.map(function (el) {
									let newObj = { ...el.value };
									Object.keys(newObj).forEach(key => {
										if (newObj[key] === null) {
											delete newObj[key];
										}
									});
									return {
										collationId: el.collationId,
										op: el.op,
										params: el.params,
										value: newObj
									};
								}
								)
						}
					};
				}
				else {
					Object.keys(curr.value).forEach(key => {
						if (curr.value[key] === null) {
							delete curr.value[key];
						}
					});
					delete curr.idFilter;
					return curr;
				}
			}
		);
	}
	const CreateReco = () => {
		setloadingSaving(true);
		// Get new value for name of ranking
		const newReco = JSON.parse(JSON.stringify(recommendationProduct));
		if((newReco.tenant == null && tenantsCatalog.length === 1 ) &&
		listTenantAccount.length > 0){
			newReco.tenant = '';
		}
		newReco.name = recoName;
		newReco['filtering']['filters'] = deleteIdFilterFromFilteringObject(newReco['filtering']['filters']);

		newReco.description = descriptionRule;
		createReco(
			newReco,
			systemServices,
			recoServices,
			(newData) => {
				trackEvent('recommendations/new-rule-saved');
				systemServices.showSuccess(
					'Recommendation created successfully!'
				);
				let url =
					'/ProductSettings/Recommendations/Setting?id=' + newData.id;
				if ($routeParams && $routeParams.ka) {
					url += '&ka=' + $routeParams.ka;
				}
				setTimeout(() => {
					window.location.href = url;
				}, 1000);
			},
			(error) => {
				console.log(
					'🚀 ~ file: RecommendationsContextProvider.js ~ line 214 ~ CreateReco ~ error',
					error
				);
				setloadingSaving(false);
			}
		);
	};
	const PutRecoById = () => {
		const newReco = JSON.parse(JSON.stringify(recommendationProduct));
		newReco.name = recoName;
		newReco['filtering']['filters'] = deleteIdFilterFromFilteringObject(newReco['filtering']['filters']);
		newReco.description = descriptionRule;
		putRecoById(
			newReco,
			recommendationProduct.id,
			systemServices,
			recoServices,
			(newData) => {
				trackEvent('recommendations/existing-rule-saved');
				systemServices.showSuccess('Changes successfully saved !');
				// change original ranking rule with new ranking data for the comparison

				setOriginamRecoRule(JSON.stringify(newReco));
			},
			(error) => {
				console.log(
					'🚀 ~ file: RecommendationsContextProvider.js ~ line 234 ~ PutRecoById ~ error',
					error
				);
			}
		);
	};
	function handleSelectMode(tenantsList) {
		// Check mode of editing of creating
		if ($routeParams && $routeParams.id !== undefined) {
			setMode('edition');
			setisFirstRender(false);
			trackEvent('recommendations/existing-rule-opened');
			getRecommendation(
				$routeParams.id,
				systemServices,
				recoServices,
				(newData) => {
					const ruleObj = { ...newData };

					if (
						!ruleObj.hasOwnProperty('filtering') ||
						ruleObj.filtering === null
					) {
						const filteringObj = {
							op: 'And',
							filters: [],
						};
						ruleObj.filtering = filteringObj;
					}
					if (ruleObj.hasOwnProperty('param')) {
						if (ruleObj.context === 'ProductTag' && ruleObj.param !== null) {

							setvalueOfSelectedTag({
								label: ruleObj.param,
								value: ruleObj.param
							});
						}
						if (ruleObj.context === 'ProductCategory' && ruleObj.param !== null) {
							setCategorySelected({
								name: ruleObj.param,
								label: ruleObj.param
							});
						}
					}
					if(tenantsList){
						const tenantObj  = {};
						tenantObj.tenantSelected = newData.tenant;
						tenantObj.listTenant = tenantsList; 
						const arayOfValueTag = getUniqueSortingFactors(newData.sorting);
						setarrayOfValuetags(arayOfValueTag.map(x => ({...x, id: uuidv4()})));
						getProductFeedCategory(accountId,
							tenantObj,
							newData => {
								setneedToRefreshTenantCategory(false);
								// Get categories of product feed and transform object to list
								const backendCompleteObject = [...newData];
								if (backendCompleteObject.length === 0) {
									setAccountWithoutCategories(true);
									settypeCategoriesSecondLevel([{
										name: '',
										label: ''
									}]);
									setTypeCategoriesSecondLevelSelected(
										{
											name: '',
											label: ''
										}
									);
									setTypeCategories([]);
									if(ruleObj.context !== 'ProductCategory'){
										setCategorySelected({
											name: '',
											label: ''
										});

									}
								}
								setallCategoriesData(backendCompleteObject);
								const arrayOfCategories = backendCompleteObject.map(el => ({ name: el.type, label: capitalizeFirstLetter(el.type) }));
								if (arrayOfCategories.length > 0) {
									setTypeCategories(arrayOfCategories);
									if(ruleObj.context !== 'ProductCategory' ) {
										setCategorySelected(arrayOfCategories[0]);
									}
									// Transform list of second category
									const valueOfSecondCategorySelect = backendCompleteObject.find(el => el.type === arrayOfCategories[0].name).knownNames.map(el => ({ name: el, label: el }));
									settypeCategoriesSecondLevel(valueOfSecondCategorySelect);
									setTypeCategoriesSecondLevelSelected(valueOfSecondCategorySelect[0]);
			
								}
							});
					}
					setrecommendationProduct(ruleObj);
					setrecoName(ruleObj.name);
					setdescriptionRule(newData.description !== null ? newData.description : '');
					GetProductCollation(ruleObj.context);
					const originToTransform = { ...ruleObj };
					originToTransform['filtering']['filters'] = deleteIdFilterFromFilteringObject(ruleObj['filtering']['filters']);
					setOriginamRecoRule(JSON.stringify(originToTransform));
				},
				(error) => { }
			);
		} else if ($routeParams && $routeParams.id === undefined) {
			setMode('creation');
			setisFirstRender(false);
			trackEvent('recommendations/new-rule-opened');
			GetProductCollation('Global');
			let tenant = null;
			let tenantObj  = {};
			tenantObj.listTenant = tenantsList; 
			const tenantsListFilter = tenantsList.filter(x=> x.value !== null);
			if(tenantsListFilter.length !== 0){

				tenantObj.tenantSelected = tenantsListFilter[0].value;
				tenant = tenantsListFilter[0].value;
			}else{
				tenantObj.tenantSelected = '';
				tenant = '';
			}
			const arayOfValueTag = getUniqueSortingFactors(recommendationProduct.sorting);
			setarrayOfValuetags(arayOfValueTag.map(x => ({...x, id: uuidv4()})));
			getProductFeedCategory(accountId,tenantObj, (newData) => {
				const backendCompleteObject = [...newData];
				setneedToRefreshTenantCategory(false);
				if (backendCompleteObject.length === 0) {
					setAccountWithoutCategories(true);
					settypeCategoriesSecondLevel([{
						name: '',
						label: ''
					}]);
					setTypeCategoriesSecondLevelSelected(
						{
							name: '',
							label: ''
						}
					);
					setTypeCategories([]);
					setCategorySelected({
						name: '',
						label: ''
					});
				} else {
					setallCategoriesData(backendCompleteObject);
					setAccountWithoutCategories(false);
					const arrayOfCategories = backendCompleteObject.map((el) => ({
						name: el.type,
						label: capitalizeFirstLetter(el.type),
					}));
					setTypeCategories(arrayOfCategories);
					setCategorySelected(arrayOfCategories[0]);

					const valueOfSecondCategorySelect = backendCompleteObject
						.find((el) => el.type === arrayOfCategories[0].name)
						.knownNames.map((el) => ({ name: el, label: el }));
					settypeCategoriesSecondLevel(valueOfSecondCategorySelect);
					setTypeCategoriesSecondLevelSelected(
						valueOfSecondCategorySelect[0]
					);
				}
			}
			);
			const newRecommendationObject = {
				name: 'New recommendation rule',
				context: 'Global',
				description: '',
				sorting: [],
				filtering: {
					op: 'And',
					filters: [],
				},
				transformations: [],
				tenant: tenant
			};
			const originToTransform = { ...newRecommendationObject };
			originToTransform['filtering']['filters'] = deleteIdFilterFromFilteringObject(newRecommendationObject['filtering']['filters']);
			setOriginamRecoRule(JSON.stringify(originToTransform));
			setrecommendationProduct(newRecommendationObject);
			setrecoName('New recommendation rule');
		}
	}

	useEffect(()=>{
		if (needToRefreshTenantCategory && !isFirstRender ){
			const tenantObj  = {};
			tenantObj.tenantSelected = recommendationProduct.tenant;
			tenantObj.listTenant = tenantsCatalog; 
			getProductFeedCategory(accountId,tenantObj, (newData) => {
				const backendCompleteObject = [...newData];
				setneedToRefreshTenantCategory(false);
				if (backendCompleteObject.length === 0) {
					setAccountWithoutCategories(true);
					settypeCategoriesSecondLevel([{
						name: '',
						label: ''
					}]);
					setTypeCategoriesSecondLevelSelected(
						{
							name: '',
							label: ''
						}
					);
					setTypeCategories([]);
					setCategorySelected({
						name: '',
						label: ''
					});
				} else {
					setallCategoriesData(backendCompleteObject);
					setAccountWithoutCategories(false);
					const arrayOfCategories = backendCompleteObject.map((el) => ({
						name: el.type,
						label: capitalizeFirstLetter(el.type),
					}));
					setTypeCategories(arrayOfCategories);
					setCategorySelected(arrayOfCategories[0]);
	
					const valueOfSecondCategorySelect = backendCompleteObject
						.find((el) => el.type === arrayOfCategories[0].name)
						.knownNames.map((el) => ({ name: el, label: el }));
					settypeCategoriesSecondLevel(valueOfSecondCategorySelect);
					setTypeCategoriesSecondLevelSelected(
						valueOfSecondCategorySelect[0]
					);
				}				
			}
			);
		}
	},[tenantsCatalog, needToRefreshTenantCategory]);
	
	useEffect(() => {
		getTenantsCatalogTenant();
		GetTenant();
		getCollationContexts();
		setneedToRefreshTenantCategory(true);
	}, []);

	useEffect(() => {
		if (accountWithoutCategories){
			setContextsBasedOn(ContextListWithoutCategory.map(x => collationContexts.some(c => c == x.value) ? {...x, isDisabled: false, icon: null} : {...x, isDisabled: true, icon: 'fa fa-lock'}));
		} else {
			setContextsBasedOn(ContextList.map(x => collationContexts.some(c => c == x.value) ? {...x, isDisabled: false, icon: null} : {...x, isDisabled: true, icon: 'fa fa-lock'}));
		}
	}, [collationContexts, accountWithoutCategories]);

	useEffect(() => {
		if (recommendationProduct.context === 'ProductGlobal' || recommendationProduct.context === 'ProductCategory') {
			if (valueOfSelectedproduct.value.length === 0) {
				setproductList([]);
				return;
			}
		}
		if (recommendationProduct.context === 'Tag' || recommendationProduct.context === 'ProductTag') {
			if (valueOfSelectedTag.value.length === 0) {
				setlistTags([]);
				return;
			}
		}
		if (recommendationProduct.context === 'ProductTag') {
			if (valueOfSelectedTag.value.length === 0) {
				setlistTags([]);
				return;
			}
			if (valueOfSelectedproduct.value.length === 0) {
				setproductList([]);
				return;
			}
		}
		const timer = setTimeout(() => {
			// getProducts With the preview API
			setTimeout(() => {
				if (categorySelected && typeCategoriesSecondLevelSelected
				) {
					setloadingProductList(true);
					const optionsObject = {
						deviceType: deviceType,
					};
					if (recommendationProduct.context === 'Category') {
						optionsObject['categoryType'] = categorySelected.name;
						optionsObject['categoryName'] = typeCategoriesSecondLevelSelected.name;
					}
					if (recommendationProduct.context === 'ProductCategory') {
						optionsObject['referenceProductId'] = valueOfSelectedproduct.value;
					}
					if (recommendationProduct.context === 'ProductGlobal' || recommendationProduct.context === 'ProductTag' || recommendationProduct.context === 'ProductCategory') {
						optionsObject['referenceProductId'] = valueOfSelectedproduct.value;
					}
					if (recommendationProduct.context === 'Tag') {
						optionsObject['tagName'] = valueOfSelectedTag.value;
					}
					const shouldNotUsePreviewTenant = recommendationProduct.tenant !== null && tenantsCatalog.length > 1;
					if (!shouldNotUsePreviewTenant) {
						optionsObject['previewTenant'] = previewTenant.value;
					}
					const newReco = JSON.parse(JSON.stringify(recommendationProduct));
					newReco['filtering']['filters'] = deleteIdFilterFromFilteringObject(newReco['filtering']['filters']);
					getPreview(
						optionsObject,
						needRefreshPagination ? {
							poffset: 0,
							plimit: 20,

						} : paginationOptions,
						newReco,
						systemServices,
						recoServices,
						newData => {
							setloadingProductList(false);
							setproductList(newData.products);
							needRefreshPagination && resetPagination();
							setneedRefreshPagination(false);
							// setidInClipBoard('')
						},
						error => {
							setloadingProductList(false);
							setneedRefreshPagination(false);
							setproductList([]);
						});
				}
			}, 500);
		}, 1500);

		return () => clearTimeout(timer);
	}, [recommendationProduct,
		categorySelected,
		typeCategoriesSecondLevelSelected,
		deviceType,
		previewTenant,
		valueOfSelectedproduct,
		valueOfSelectedTag
	]);

	useEffect(() => {
		if (newPaginationNeed) {
			if (recommendationProduct.context === 'ProductGlobal') {
				if (valueOfSelectedproduct.value.length === 0) {
					setproductList([]);
					return;
				}
			}
			if (recommendationProduct.context === 'Tag' || recommendationProduct.context === 'ProductTag') {
				if (valueOfSelectedTag.value.length === 0) {
					setlistTags([]);
					return;
				}
			}
			if (recommendationProduct.context === 'ProductTag') {
				if (valueOfSelectedTag.value.length === 0) {
					setlistTags([]);
					return;
				}
				if (valueOfSelectedproduct.value.length === 0) {
					setproductList([]);
					return;
				}
			}
			setloadingProductList(true);
			if (categorySelected &&
				typeCategoriesSecondLevelSelected) {
				const optionsObject = {
					deviceType: deviceType,
				};
				if (recommendationProduct.context === 'Category') {
					optionsObject['categoryType'] = categorySelected.name;
					optionsObject['categoryName'] = typeCategoriesSecondLevelSelected.name;
				}
				if (recommendationProduct.context === 'ProductCategory') {
					optionsObject['referenceProductId'] = valueOfSelectedproduct.value;
				}
				if (recommendationProduct.context === 'ProductGlobal' || recommendationProduct.context === 'ProductTag' || recommendationProduct.context === 'ProductCategory') {
					optionsObject['referenceProductId'] = valueOfSelectedproduct.value;
				}
				if (recommendationProduct.context === 'Tag') {
					optionsObject['tagName'] = valueOfSelectedTag.value;
				}
				const shouldNotUsePreviewTenant = recommendationProduct.tenant !== null && tenantsCatalog.length > 1;
				if (!shouldNotUsePreviewTenant) {
					optionsObject['previewTenant'] = previewTenant.value;
				}
				const newReco = JSON.parse(JSON.stringify(recommendationProduct));
				newReco['filtering']['filters'] = deleteIdFilterFromFilteringObject(newReco['filtering']['filters']);
				getPreview(
					optionsObject,
					paginationOptions,
					newReco,
					systemServices,
					recoServices,
					newData => {
						setloadingProductList(false);
						setproductList(newData.products);
						setNewPaginationNeed(false);
						// setidInClipBoard('')
					},
					error => {
						setloadingProductList(false);
						setNewPaginationNeed(false);
						setproductList([]);
					});
			}
		}
	}, [paginationOptions]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (inputProductBasedOn) {
				onGetProductsByIdList(inputProductBasedOn);
			}
		}, 500);

		return () => clearTimeout(timer);
	}, [inputProductBasedOn]);
	useEffect(() => {
		const timer = setTimeout(() => {
			if (inputTagBasedOn) {
				onGetTagsByIdList(inputTagBasedOn);
			}
		}, 500);

		return () => clearTimeout(timer);
	}, [inputTagBasedOn]);


	// quit or save
	let urlReturn = '/ProductSettings/Reco';
	if ($routeParams && $routeParams.ka) {
		urlReturn += '?ka=' + $routeParams.ka;
	}
	function checkIfCanQuitWithoutSave() {
		const stringifyNewObj = { ...recommendationProduct };
		stringifyNewObj.name = recoName;
		stringifyNewObj.description = descriptionRule;
		stringifyNewObj['filtering']['filters'] = deleteIdFilterFromFilteringObject(recommendationProduct['filtering']['filters']);
		const compareIfIsSave =
			originalRecoRule === JSON.stringify(stringifyNewObj);

		if (compareIfIsSave) {
			window.location.href = urlReturn;
		} else {
			setmodalConfirmIsOpen(true);
		}
	}
	function cancelActions() {
		const stringifyNewObj = { ...recommendationProduct };
		stringifyNewObj.name = recoName;
		stringifyNewObj.description = descriptionRule;
		stringifyNewObj['filtering']['filters'] = deleteIdFilterFromFilteringObject(recommendationProduct['filtering']['filters']);
		const compareIfIsSave =
			originalRecoRule === JSON.stringify(stringifyNewObj);

		if (compareIfIsSave) {
			return;
		} else {
			setmodalCancelActions(true);
		}
	}
	function returnToDashboard() {
		window.location.href = urlReturn;
	}

	function resetPagination() {
		setpaginationOptions({
			poffset: 0,
			plimit: 20
		});
		setCurrentPageProductList(1);
	}

	function handleCloseConfirm() {
		setmodalConfirmIsOpen(false);
	}
	function handleCloseCancelActions() {
		setmodalCancelActions(false);
	}
	function returnToFirstStateOfReco() {
		const parseOriginalRecoRule = JSON.parse(originalRecoRule);
		setrecoName(parseOriginalRecoRule.name);
		setdescriptionRule(parseOriginalRecoRule.description !== null ? parseOriginalRecoRule.description : '');
		setrecommendationProduct(parseOriginalRecoRule);
		handleCloseCancelActions();
	}

	// Change recommendation object
	function handleChangeRecommendationName(target) {
		setrecoName(target);
	}
	function transformRuleToGetReferenceTagOrCategory(type) {
		if (type === 'ProductTag') {
			const referenceTag = valueOfSelectedTag.value;
			setrecommendationProduct((reco) => ({
				...reco,
				param: referenceTag,
				context: type,
			}));
		}
		if (type === 'ProductCategory') {
			const referenceCatecoryType = categorySelected.name;
			setrecommendationProduct((reco) => ({
				...reco,
				param: referenceCatecoryType,
				context: type,
			}));

		}
		setpreviewTenant(listTenantAccount[0]);
		setinputProductBasedOn('');
		setautocompleteListIsLoading(false);
		setvalueOfSelectedproduct({
			label: 'Choose',
			value: ''
		});
		setvalueOfSelectedTag({
			label: 'Choose',
			value: ''
		});
		setproductList([]);
		resetPagination();
		GetProductCollation(type);

	}
	function handleChangeContext(target) {

		if (target === 'ProductTag') {
			transformRuleToGetReferenceTagOrCategory('ProductTag');
		}
		if (target === 'ProductCategory') {
			transformRuleToGetReferenceTagOrCategory('ProductCategory');
		}
		if (target !== 'ProductTag' || target !== 'ProductCategory') {
			setrecommendationProduct((reco) => ({
				...reco,
				context: target,
				param: null
			}));
			setpreviewTenant(listTenantAccount[0]);
			setinputProductBasedOn('');
			setautocompleteListIsLoading(false);
			setvalueOfSelectedproduct({
				label: 'Choose',
				value: ''
			});
			setvalueOfSelectedTag({
				label: 'Choose',
				value: ''
			});
			setproductList([]);
			resetPagination();
			GetProductCollation(target);
		}
	}

	function handleChangePaginationOptions(elemName, elemValue) {
		setpaginationOptions(pagination => ({
			...pagination,
			[elemName]: elemValue
		}));
	}
	function handeChangeFilterSorting(target) {
		const newCollation = {
			collationId: target.id,
			weight: -0.5,
		};
		const newObjectOfSorting = [
			...recommendationProduct.sorting,
			newCollation,
		];
		setrecommendationProduct((reco) => ({
			...reco,
			sorting: newObjectOfSorting,
		}));
	}
	// Sorting Tag 

	function handleChangeSortingFactors(target, add){
		const filterToAdd = Object.fromEntries(
			Object.entries({
				collationId: target.collationId,
				params: target.params,
				period: target.period,
				weight: target.weight
			}).filter(([key, value]) => key !== 'period' || value !== null)
		);
		const newSorting = add ? [...recommendationProduct.sorting, filterToAdd] : recommendationProduct.sorting.map(x => x.collationId === filterToAdd.collationId ? filterToAdd : x);
		const arayOfValueTag = getUniqueSortingFactors(newSorting);
		setarrayOfValuetags(arayOfValueTag);
		setrecommendationProduct((reco) => ({
			...reco,
			sorting: newSorting,
		}));
	}
	function removeAttribute(isTag, target) {
		if(isTag){
			const indexOfItem = recommendationProduct.sorting.indexOf(target);
			const newSorting =  [...recommendationProduct.sorting];
			newSorting.splice(indexOfItem,1);
			const arayOfValueTag = getUniqueSortingFactors(newSorting);
			setarrayOfValuetags(arayOfValueTag);
			setrecommendationProduct((reco) => ({
				...reco,
				sorting: newSorting,
			}));
		}else{
			const newObjectOfSorting = recommendationProduct.sorting.filter(
				(el) => el.collationId !== target
			);
			setrecommendationProduct((reco) => ({
				...reco,
				sorting: newObjectOfSorting,
			}));
		}
	}
	function updapteAttribute(collation, target) {
		const indexOfItem = recommendationProduct.sorting.indexOf(collation);
		function replaceAt(array, index, value) {
			const ret = array.slice(0);
			ret[index] = value;
			return ret;
		}
		const ToChange = recommendationProduct.sorting[indexOfItem];
		if (Math.sign(collation.weight) === 1) {
			ToChange.weight = parseFloat(target);
		} else {
			ToChange.weight = parseFloat(-target);
		}
		const newArrayOfSorting = replaceAt(
			recommendationProduct.sorting,
			indexOfItem,
			ToChange
		);
		setrecommendationProduct((reco) => ({
			...reco,
			sorting: newArrayOfSorting,
		}));
	}
	function onChangeAttributeOfSign(collation) {
		const indexOfItem = recommendationProduct.sorting.indexOf(collation);
		function replaceAt(array, index, value) {
			const ret = array.slice(0);
			ret[index] = value;
			return ret;
		}
		const ToChange = recommendationProduct.sorting[indexOfItem];
		if (Math.sign(collation.weight) === 1) {
			ToChange.weight = parseFloat(-ToChange.weight);
		} else {
			ToChange.weight = Math.abs(ToChange.weight);
		}
		const newArrayOfSorting = replaceAt(
			recommendationProduct.sorting,
			indexOfItem,
			ToChange
		);
		setrecommendationProduct((ranking) => ({
			...ranking,
			sorting: newArrayOfSorting,
		}));
	}
	function changeCategorySelected(elem) {
		const nextCategory = typeCategories.find((el) => el.name === elem);
		setCategorySelected(nextCategory);
		if (recommendationProduct.context === 'ProductCategory') {
			const referenceCatecoryType = nextCategory.name;
			setrecommendationProduct((reco) => ({
				...reco,
				param: referenceCatecoryType,
			}));

		}
		const valueOfSecondCategorySelect = allCategoriesData
			.find((el) => el.type === nextCategory.name)
			.knownNames.map((el) => ({ name: el, label: el }));
		settypeCategoriesSecondLevel(valueOfSecondCategorySelect);
		setTypeCategoriesSecondLevelSelected(valueOfSecondCategorySelect[0]);
		setneedRefreshPagination(true);
	}
	function changeSecondCategorySelected(elem) {
		const newCategory = typeCategoriesSecondLevel.find(
			(el) => el.name === elem
		);
		setTypeCategoriesSecondLevelSelected(newCategory);
		setneedRefreshPagination(true);
	}
	function changeDeviceSelected(deviceName) {
		setdeviceType(deviceName);
		setneedRefreshPagination(true);
	}

	/// Filters
	const formatSelectedFilter = (selectedFilterList, filterSelectedExpression) => {
		if (selectedFilterList.length > 1) {
			const listFilterToAdd = selectedFilterList.map((elem) => {

				const filterToAdd = {
					collationId: elem.id,
					op: elem.op,
					value: elem.value,
					params: elem.params,
					...(elem.period !== null && { period: elem.period }),
					// period: elem.period
				};
				return filterToAdd;
			});
			const expressionFilterToAdd = {
				expression: {
					op: filterSelectedExpression.op,
					filters: listFilterToAdd
				},
			};

			return expressionFilterToAdd;
		} else if (selectedFilterList.length === 1) {
			const filterToAdd = {
				collationId: selectedFilterList[0].id,
				op: selectedFilterList[0].op,
				value: selectedFilterList[0].value,
				params: selectedFilterList[0].params,
				...(selectedFilterList[0].period !== null && { period: selectedFilterList[0].period }),
				// period: selectedFilterList[0].period
			};

			return filterToAdd;
		}
	};

	function onAddFilter(selectedFilterList, filterSelectedExpression) {

		let newFilter = formatSelectedFilter(selectedFilterList, filterSelectedExpression);
		let updateList = [
			...recommendationProduct.filtering.filters,
			newFilter,
		];
		setrecommendationProduct((reco) => ({
			...reco,
			filtering: {
				op: reco.filtering.op,
				filters: updateList
			},
		}));
	}

	function onEditFilter(idFilterEdit, currentListWithId, selectedFilterList, filterSelectedExpression) {

		const newFilter = formatSelectedFilter(selectedFilterList, filterSelectedExpression);
		const itemToManipulate = currentListWithId.find(
			(elem) => elem.idFilter === idFilterEdit
		);
		function replaceAt(array, index, value) {
			const ret = array.slice(0);
			ret[index] = value;
			return ret;
		}
		const indexOfItem = currentListWithId.indexOf(itemToManipulate);
		const updatedListWithId = replaceAt(
			[...currentListWithId],
			indexOfItem,
			newFilter
		);
		setrecommendationProduct((reco) => ({
			...reco,
			filtering: {
				op: reco.filtering.op,
				filters: updatedListWithId
			},
		}));
	}

	function onRemoveFilter(filterList, id) {
		const newList = filterList.filter((elem) => elem.idFilter != id);
		setrecommendationProduct((reco) => ({
			...reco,
			filtering: {
				op: reco.filtering.op,
				filters: newList
			},
		}));
	}

	function onRemoveGroupSorting() {
		setrecommendationProduct((reco) => ({
			...reco,
			groupSorting : null
		}));
	}

	function onSetGroupSorting(groupSorting) {
		setrecommendationProduct((reco) => ({
			...reco,
			groupSorting : groupSorting
		}));
	}

	function handleChangeOperatorOfFilters(elem) {
		setrecommendationProduct((reco) => ({
			...reco,
			filtering: {
				op: elem,
				filters: reco.filtering.filters
			},
		}));

	}
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setidInClipBoard(value);
	};

	function handleChangeTenant(target) {
		const targetIsDefault = target === '';
		const targetIsAll = target === 'All' || target === null;

		const newTenant = targetIsDefault ? '' : targetIsAll ? null : tenantsCatalog.find(el => el.label === target).value;
		setneedToRefreshTenantCategory(true);
		setrecommendationProduct((reco) => ({
			...reco, tenant: newTenant
		}));
	}
	function handleChangeTenantPreview(target) {
		const newTenant = listTenantAccount.find(el => el.value == target);
		setpreviewTenant(newTenant);
	}
	function handleChangenemOfProductbasedOn(value) {
		setinputProductBasedOn(value);
		if (value === '') {
			setautocompleteListIsLoading(false);
		} else {
			setautocompleteListIsLoading(true);
		}
		setlistIdProducts([]);
	}
	function handleSelectidOfProduct(value) {
		setvalueOfSelectedproduct(value);
		resetPagination();
	}
	function checkIfNeedTenantPreviewOrTenantCatalog(needTenantForSearch){
		// Si tenant context === All alors mettre tenant preview
		// Si c’est un precis c’est lui
		// Si all dans les 2 appeler sans tenant
		// si mono tenant => tenant
		if(needTenantForSearch){
			if(recommendationProduct.tenant === null ||recommendationProduct.tenant=== 'All' ){
				if(previewTenant && previewTenant.value === 'all'){
					return null;
				}else if(previewTenant &&previewTenant.value !== 'all' ){
					return previewTenant.value;
				}
			}else{
				return recommendationProduct.tenant;
			}
		}else{
			return null;
		}
	}
	function onGetProductsByIdList(str) {
		const needTenantForSearch= tenantsCatalog.length > 1;
		const tenant = checkIfNeedTenantPreviewOrTenantCatalog(needTenantForSearch);
		setupAccountServices.getAccountReferenceData(accountId,
			'product',
			str,
			tenant,
			newData => {
				setlistIdProducts(newData);
				setautocompleteListIsLoading(false);
			},
			error => {
				setautocompleteListIsLoading(false);
			}

		);
	}
	function onGetTagsByIdList(str) {
		const needTenantForSearch= tenantsCatalog.length > 1;
		const tenant = checkIfNeedTenantPreviewOrTenantCatalog(needTenantForSearch);
		setupAccountServices.getAccountReferenceData(accountId,
			recommendationProduct.context === 'Tag' ? 'productRawTag' : 'productTagKey',
			str,
			tenant,
			newData => {
				setlistTags(newData);
				setautocompleteTagListIsLoading(false);
			},
			error => {
				setautocompleteTagListIsLoading(false);
			}

		);
	}
	function handleChangenemOfTagbasedOn(value) {
		setinputTagBasedOn(value);
		if (value === '') {
			setautocompleteTagListIsLoading(false);
		} else {
			setautocompleteTagListIsLoading(true);
		}
		setlistTags([]);
	}
	function handleSelectidOfTag(value) {
		if (recommendationProduct.context === 'ProductTag') {
			const referenceTag = value.value;
			setrecommendationProduct((reco) => ({
				...reco,
				param: referenceTag,
			}));
		}
		if (recommendationProduct.context === 'ProductCategory') {
			const referenceCatecoryType = value.name;
			setrecommendationProduct((reco) => ({
				...reco,
				param: referenceCatecoryType,
			}));

		}
		setvalueOfSelectedTag(value);
		resetPagination();
	}
	function handleChangeRecoDescription(target) {
		setdescriptionRule(target);
	}
	return (
		<RecommendationContext.Provider
			value={{
				// config mode
				errorNoConfiguration,
				contextsBasedOn,
				accountWithoutCategories,
				handleChangeTenant,
				previewTenant,
				listTenantAccount,
				tenantsCatalog,
				mode,
				productList,
				checkIfCanQuitWithoutSave,
				modalConfirmIsOpen,
				modalCancelActions,
				cancelActions,
				returnToDashboard,
				handleCloseConfirm,
				handleCloseCancelActions,
				returnToFirstStateOfReco,
				loadingProductList,
				loadingSaving,
				loadingSettings,
				idInClipBoard,
				copyToClipBoard,
				paginationOptions,
				currentPageProductList,
				setCurrentPageProductList,
				handleChangePaginationOptions,
				setNewPaginationNeed,
				deviceType,
				changeDeviceSelected,
				handleChangeTenantPreview,

				// recommendation object
				handleChangeRecoDescription,
				descriptionRule,
				recoName,
				recommendationProduct,
				handleChangeRecommendationName,
				CreateReco,
				PutRecoById,
				//Filters 1
				handleChangeContext,
				updapteAttribute,
				removeAttribute,
				onChangeAttributeOfSign,
				// filters
				ListFilter,
				handeChangeFilterSorting,
				categorySelected,
				typeCategories,
				changeCategorySelected,
				typeCategoriesSecondLevel,
				typeCategoriesSecondLevelSelected,
				changeSecondCategorySelected,
				onAddFilter,
				onEditFilter,
				onRemoveFilter,
				handleChangeOperatorOfFilters,
				getCheckIfIsAValidUrlFetch,

				setupAccountServices,
				accountId,
				// group sorting
				onSetGroupSorting,
				onRemoveGroupSorting,

				// inputIdProduct 
				listIdProducts,
				handleChangenemOfProductbasedOn,
				inputProductBasedOn,
				handleSelectidOfProduct,
				valueOfSelectedproduct,
				autocompleteListIsLoading,
				// taging 
				listTags,
				valueOfSelectedTag,
				autocompleteTagListIsLoading,
				handleChangenemOfTagbasedOn,
				inputTagBasedOn,
				handleSelectidOfTag,

				handleChangeSortingFactors,
				arrayOfValuetags
			}}
		>
			{props.children}
		</RecommendationContext.Provider>
	);
};

export default CreateRecommendationsContextProvider;

export { useRecommendationContext };
