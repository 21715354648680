import React, { useState, useEffect } from 'react';
import { react2angular } from 'react2angular';
import SystemServices from '../../../Services/SystemServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import ProductEMerchServices from '../../../Services/ProductEMerchServices';
import { Checkbox, CheckboxList } from '../../../Components/Checkbox/Checkbox';
import { Article } from '../../../Components/Article/Article';
import { Section } from '../../../Components/Section/Section';
import Btn from '../../../Components/Btn';
import { ContextList } from '../utils/types';

export default function EMerchConfiguration(props) {
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatingServices = new ImpersonatingServices(
		props.$rootScope,
		props.$routeParams
	);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;

	const [collationContexts, setCollationContexts] = useState<string[]>([]);

	const emerchServices = new ProductEMerchServices(props.$http, props.AuthServices, accountId);

	useEffect(() => {
		emerchServices.getProductCollationContexts(
			setCollationContexts,
			() => systemServices.showError('Cannot get emerch configuration')
		);
	}, []);

	const submitChanges = () => {
		emerchServices.setProductCollationContexts(collationContexts,
			() => systemServices.showSuccess('Changes saved'),
			() => systemServices.showError('Cannot save emerch configuration')
		);
	};

	const isActive = (value: string): boolean => {
		return collationContexts.includes(value);
	};

	const handleChange = (value: string): void => {
		collationContexts.includes(value) ? setCollationContexts(c => c.filter(x => x != value)) : setCollationContexts(c => [...c, value]);
	};

	return (<Section width='xs'>
		<div className='h2'>Select active emerch contexts</div>
		<Article innerSize='l'>
			<CheckboxList size="s" hasBorder={false}>
				{ContextList.map(elem =>
					<Checkbox
						key={elem.value}
						forcedChecked={isActive(elem.value)}
						onChange={() => handleChange(elem.value)}
						label={elem.label}
					/>
				)}
			</CheckboxList>
			<div className="mt_20">
				<Btn color="primary" onClick={() => submitChanges()}>
					Save
				</Btn>
			</div>
		</Article>
	</Section>);
}

// @ts-ignore
angular
	.module('beyableSaasApp.EMerch.Configuration', [])
	.component('emerchSetup', react2angular(EMerchConfiguration, [], ['$http', '$rootScope', '$timeout', '$routeParams', 'AuthServices', '$scope']));
