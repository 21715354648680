import React, { useState } from 'react';
import Btn from '../../../../Components/Btn';
import { Panel, PanelHeader, PanelBody, PanelFooter } from '../../../../Components/Panel/Panel';
import { List } from '../../../../Components/List';
import { Article } from '../../../../Components/Article/Article';
import PopinConfirm from '../../../../Components/PopinConfirm';
import { useRankingContext } from '../../Ranking/context/RankingContextProvider';
import DeleteItem from './DeleteItem';
import {
	replaceId,
	demoteId,
	promoteId
} from '../../Ranking/utils';

export default function PanelManualSorting({
	dimension,
	subtitle,
	handleClose = () => { },
	panelIsOpen,
}) {

	const {
		rankingObject,
		deletePromotedProduct,
		deleteDemotedProduct,
		deleteReplaceProductItem,
	} = useRankingContext();
	const [name, setname] = useState('');
	const [arraOfProductId, setNewArrayOfProductId] = useState([]);
	const [productToDelete, setproductToDelete] = useState([]);
	const [openCancelPopin, setopenCancelPopin] = useState(false);

	const handleClosePanel = () => {
		if (productToDelete.length !== 0) {
			setopenCancelPopin(true);
		} else {
			handleClose();
		}
	};
	const filterBySign = (arrayToFilter, idToFilter) => {
		const itemTOtransform = arrayToFilter.filter(el => {
			return el.collationId === idToFilter;
		});
		let elementsToReturn = [];
		if (itemTOtransform.length !== 0) {
			elementsToReturn = [...itemTOtransform[0]['params'][0]['value']['stringValues']];
		}
		return elementsToReturn;
	};
	function getReplacesObj(arrayToFilter) {
		const uniqueItem = arrayToFilter.reduce((acc, curr) => {
			if (curr.collationId === replaceId) {
				const newObj = {
					...curr,
					idOld: curr.params.find(el => el.name === 'First Product Id').value.stringValues,
					idReplace: curr.params.find(el => el.name === 'Second Product Id').value.stringValues,
				};
				acc = [...acc, newObj];
			}

			return acc;
		}, []);

		return [...new Set(uniqueItem)];
	}
	function getNameOfDimension() {
		switch (dimension) {
			case promoteId:
				return 'Promoted products';
			case demoteId:
				return 'Demoted products';
			case replaceId:
				return 'Swapped products';
		}
	}
	useState(() => {
		if (dimension === replaceId) {
			setNewArrayOfProductId(getReplacesObj(rankingObject.transformations));
		} else {
			const newObjectOfTransformation = filterBySign(rankingObject.transformations, dimension);
			setNewArrayOfProductId(newObjectOfTransformation);
		}
		setname(getNameOfDimension());
	}, []);
	function deleteProduct(idOfProduct) {
		if (dimension === replaceId) {
			const newArray = arraOfProductId.filter(el => el.idOld !== idOfProduct);
			setNewArrayOfProductId(newArray);
			setproductToDelete(arr => [...arr, idOfProduct]);
		} else {
			const newArray = arraOfProductId.filter(el => el !== idOfProduct);
			setNewArrayOfProductId(newArray);
			setproductToDelete(arr => [...arr, idOfProduct]);
		}
	}
	function deleteProductAll() {
		if (dimension === replaceId) {
			setNewArrayOfProductId([]);
			setproductToDelete([]);
		} else {
			setNewArrayOfProductId([]);
			setproductToDelete([]);
		}
	}
	function ValidDeletingAction() {
		if (dimension === promoteId) {
			deletePromotedProduct(arraOfProductId);
			handleClose();
		}
		if (dimension === demoteId) {
			deleteDemotedProduct(arraOfProductId);
			handleClose();
		}
		if (dimension === replaceId) {
			deleteReplaceProductItem(productToDelete);
			handleClose();
		}
	}
	return (
		<Panel
			isOpen={panelIsOpen}
			width={500}
		>
			{openCancelPopin &&
				<PopinConfirm
					cancel={() => handleClose()}
					title={'Are you sure you want to cancel your changes?'}
				>
					<Btn
						onClickFunction={() => { setopenCancelPopin(false); }}
						message="No"
						color="primary"
					/>
					<Btn
						onClickFunction={() => {
							setopenCancelPopin(false);
							handleClose();
						}}
						message="Yes"
						color="alert"
					/>

				</PopinConfirm>
			}
			<PanelHeader
				title={name}
				desc={subtitle}
				hasClose={false}
				secondaryAction={
					<Btn
						onClick={() => handleClosePanel()}
						message="Cancel"
						color="secondary"
						style="ghost"
					/>
				}
				primaryAction={
					<Btn
						onClick={() => ValidDeletingAction()}
						message="Ok"
						color="primary"
					/>
				}
			/>
			<PanelBody color='grey'>
				<Article>
					{arraOfProductId && arraOfProductId.length > 0 &&
						<List size="s" removeVerticalMargin={true}>
							{dimension !== replaceId && arraOfProductId.map(product =>
								<DeleteItem
									key={product}
									onClickFn={() => deleteProduct(product)}
								>
									<span className="fw_medium grey_0">{product}</span>
								</DeleteItem>
							)}
							{dimension === replaceId && arraOfProductId.map(product =>
								<DeleteItem
									key={product.idOld}
									onClickFn={() => deleteProduct(product.idOld)}
								>
									{product.idOld}
									<i className='fas fa-exchange-alt grey_3 ml_10 mr_10'></i>
									{product.idReplace}
								</DeleteItem>
							)}
						</List>
					}
					
					{arraOfProductId.length === 0 && arraOfProductId.length === 0 &&
						<div className='flex flex_align_center flex_justify_center s_25 mt_25'
							style={{
								color: 'grey',
								padding: '15px',
							}}>
							<p className='s_15'> &#9432; No products added.</p>
						</div>
					}
				</Article>
			</PanelBody>
			<PanelFooter
				primaryAction={
					<Btn
						onClick={() => deleteProductAll()}
						message="Remove all products"
						color="secondary"
						size="xs"
						style="ghost"
						icon="fas fa-trash fa-sm"
					/>
				}
			/>
		</Panel>
	);
}
