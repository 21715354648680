import React from 'react';
import { useWorkFlowContext } from '../context/WorkflowProcessContext';
import { WorkflowProcessContainer } from '../Processes/WorkflowProcessContainer';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
export const ProcessContainer = () => {
	const { isLoading } = useWorkFlowContext();
	return (
		<section>
			{isLoading && <SpinnerWheel wheelSize="l" />}

			<WorkflowProcessContainer />

		</section>
	);
};
