import React, { createContext, useContext, useEffect, useState, Dispatch, SetStateAction } from 'react';
import EmailTemplateServices from '../../../../Services/EmailTemplateServices';
import { EmailTemplateInListApiType, EmailTemplateStatus } from '../../../../Services/EmailTemplateServicesTypes';
import SystemServices from '../../../../Services/SystemServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import { EmailTemplateRenderingModel } from './EmailTemplateRenderingModel';
// import EventTrackingServices from '../../../../Services/EventTrackingServices';


const EmailTemplateListContext = createContext<EmailTemplateListContextType | undefined>(undefined);

function useEmailTemplateListContext() {
	const context = useContext(EmailTemplateListContext);
	if (!context) throw Error('useEmailTemplateListContext can only be used inside an EmailTemplateListContextProvider');
	return context;
}

const CreateEmailTemplateListContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;

	const systemServices = new SystemServices($rootScope, $timeout);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);

	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const isAdmin = impersonatingServices.isAnAdmin();

	//const { trackEvent } = EventTrackingServices(props.$rootScope.User);

	const crud = new EmailTemplateServices(accountId, $http);

	const [idTpl, setIdTpl] = useState<string | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [templates, setTemplates] = useState<EmailTemplateInListApiType[]>([]);
	const [modalViewWidgetHtmlIsOpen, setModalViewWidgetHtmlIsOpen] = useState<boolean>(false);
	const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState<boolean>(false);
	const [emailTemplateRenderingModel, setEmailTemplateRenderingModel] = useState<EmailTemplateRenderingModel | undefined>();

	const handleDeleteCampaign = () => {
		crud.deleteEmailTemplate(idTpl!, _success => {
			getEmailTemplates();
		}, error => {
			console.error(error);
			setIsLoading(false);
			systemServices.showError('An error occured while delete email template');
		});
	};

	const getEmailTemplates = () => {
		crud.getEmailTemplates(
			result => {
				result.sort((a, b) => {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				});
				setTemplates(result);
				setIsLoading(false);
			},
			err => {
				console.error(err);
				setIsLoading(false);
				systemServices.showError('An error occured while loading email templates');
			}
		);
	};

	useEffect(() => {
		getEmailTemplates();
	}, []);

	const getEditLink = (emailTemplateId: string): string => {
		const ka = isAdmin && props.$routeParams?.ka ? `&ka=${accountId}` : '';
		const url = `/EmailTemplate/Editor?id=${emailTemplateId}${ka}`;
		return url;
	};
	const getCreationLink = (): string => {
		const ka = isAdmin && props.$routeParams?.ka ? `?ka=${accountId}` : '';
		const url = `/EmailTemplate/Editor${ka}`;
		return url;
	};

	const changeEmailTemplateStatus = (emailTemplateId: string, status: EmailTemplateStatus) => {
		crud.updateEmailTemplateStatus(emailTemplateId, status, () => {
			systemServices.showSuccess(status === EmailTemplateStatus.Active ? 'The personalized email has been enabled' : 'The personalized email has been disabled');
			getEmailTemplates();
		}, err => {
			console.log(err);
			systemServices.showError(status === EmailTemplateStatus.Active ? 'An error occured while starting the personalized email' : 'An error occured while stopping the personalized email');
		});
	};

	const handleGenerateEmailTemplateError = err => {
		console.log(err);
		setEmailTemplateRenderingModel(undefined);
		systemServices.showError('An error occured while rendering the personalized email');
	};

	const generateEmailTemplateOutput = (emailTemplateId: string, tenant: string | null | undefined, renderingModel: EmailTemplateRenderingModel) => {
		crud.generateEmailTemplateOutput(emailTemplateId, tenant, output => {
			setEmailTemplateRenderingModel({ EmailTemplateId: emailTemplateId, IsReadyForRendering: true, RenderingResult: output, AvailableTenants: renderingModel.AvailableTenants, NeedsSelectTenant: false, SelectedTenant: renderingModel.SelectedTenant });
		}, handleGenerateEmailTemplateError);
	};

	const handleOpenViewWidgetModal = (shouldGetWidgetHtml: boolean, emailTemplateId: (string | null)) => {
		setModalViewWidgetHtmlIsOpen(shouldGetWidgetHtml);
		if (!shouldGetWidgetHtml){
			setEmailTemplateRenderingModel(undefined);
			return;
		}
		if (emailTemplateId != null) {
			crud.getEmailTemplateGenerationMetadata(emailTemplateId, res => {
				if (!res.isReadyForRendering) {
					setEmailTemplateRenderingModel({ EmailTemplateId: emailTemplateId, IsReadyForRendering: false, RenderingResult: null, AvailableTenants: null, NeedsSelectTenant: false, SelectedTenant: null });
				} else {
					const tenantSelectionIsNeeded = res.availableTenants != null;
					const tenantSelectionIsNeededAndCanBeAutomatic = res.availableTenants?.length == 1;
					const tenantSelectionIsNeededButNotDoneYet = tenantSelectionIsNeeded && !tenantSelectionIsNeededAndCanBeAutomatic && emailTemplateRenderingModel?.SelectedTenant == null;
					if (tenantSelectionIsNeededButNotDoneYet) {
						setEmailTemplateRenderingModel({ EmailTemplateId: emailTemplateId, IsReadyForRendering: true, RenderingResult: null, AvailableTenants: res.availableTenants, NeedsSelectTenant: true, SelectedTenant: res.availableTenants != null && res.availableTenants.length > 0 ? res.availableTenants[0] : null });
					} else {
						const automaticTenant = tenantSelectionIsNeededAndCanBeAutomatic ? (res.availableTenants != null && res.availableTenants.length == 1 ? res.availableTenants[0] : null) : null;
						const tenant = tenantSelectionIsNeeded
							? (automaticTenant ?? emailTemplateRenderingModel?.SelectedTenant ?? null)
							: null;
						const renderingModel = { EmailTemplateId: emailTemplateId, IsReadyForRendering: true, RenderingResult: null, AvailableTenants: res.availableTenants, NeedsSelectTenant: false, SelectedTenant: tenant };
						setEmailTemplateRenderingModel(renderingModel);
						generateEmailTemplateOutput(emailTemplateId, tenant, renderingModel);
					}
				}
			}, handleGenerateEmailTemplateError);
		}
	};

	const handleSelectTenantForRendering = (tenant: string, fromRendering: boolean) => {
		if (emailTemplateRenderingModel === undefined)
			return;
		if (fromRendering){
			const renderingModel = {...emailTemplateRenderingModel, IsReadyForRendering: true, RenderingResult: null, NeedsSelectTenant: false, SelectedTenant: tenant};
			setEmailTemplateRenderingModel(renderingModel);
			generateEmailTemplateOutput(emailTemplateRenderingModel.EmailTemplateId, emailTemplateRenderingModel.SelectedTenant, renderingModel);
		} else {
			const renderingModel = {...emailTemplateRenderingModel, IsReadyForRendering: true, RenderingResult: null, NeedsSelectTenant: true, SelectedTenant: tenant};
			setEmailTemplateRenderingModel(renderingModel);
		}
	};

	const handleValidateSelectedTenantForRendering = () => {
		if (emailTemplateRenderingModel === undefined)
			return;
		const renderingModel = {...emailTemplateRenderingModel, NeedsSelectTenant: false};
		generateEmailTemplateOutput(emailTemplateRenderingModel.EmailTemplateId, emailTemplateRenderingModel.SelectedTenant, renderingModel);
	};

	const goBackToTenantSelection = () => {
		if (emailTemplateRenderingModel === undefined)
			return;
		const renderingModel = { ...emailTemplateRenderingModel, NeedsSelectTenant: true };
		setEmailTemplateRenderingModel(renderingModel);
	};

	const context: EmailTemplateListContextType = {
		isLoading,
		templates,
		changeEmailTemplateStatus,
		getEditLink,
		getCreationLink,
		modaHtmlIsOpen: modalViewWidgetHtmlIsOpen,
		handleOpenViewWidgetModal,
		handleDeleteCampaign,
		idTpl,
		setIdTpl,
		deleteConfirmIsOpen,
		setDeleteConfirmIsOpen,
		emailTemplateRenderingModel,
		handleSelectTenantForRendering,
		handleValidateSelectedTenantForRendering,
		goBackToTenantSelection
	};

	return (
		<EmailTemplateListContext.Provider
			value={context}
		>
			{props.children}
		</EmailTemplateListContext.Provider>
	);
};

export default CreateEmailTemplateListContextProvider;

export { useEmailTemplateListContext };

type EmailTemplateListContextType = {
	isLoading: boolean;
	templates: EmailTemplateInListApiType[];
	changeEmailTemplateStatus: (emailTemplateId: string, status: EmailTemplateStatus) => void;
	getEditLink: (emailTemplateId: string) => string;
	getCreationLink: () => string;
	modaHtmlIsOpen: boolean;
	handleOpenViewWidgetModal: (shouldGetWidgetHtml: boolean, templateId: string | null) => void;
	handleDeleteCampaign: () => void;
	idTpl: string | undefined;
	setIdTpl: Dispatch<SetStateAction<string | undefined>>;
	deleteConfirmIsOpen: boolean;
	setDeleteConfirmIsOpen: Dispatch<SetStateAction<boolean>>;
	emailTemplateRenderingModel: EmailTemplateRenderingModel | undefined;
	handleSelectTenantForRendering: (tenant: string, fromRendering: boolean) => void;
	handleValidateSelectedTenantForRendering: () => void;
	goBackToTenantSelection: () => void;
}