import React,{useEffect,useState} from 'react';
import ConnectionContainer from './Connection/ConnectionContainer';
import MappingContainer from './Mapping/MappingContainer';
import ProductFeedHeader from './ProductFeedHeader';
import {useLocation } from 'react-router-dom';
import {useProductFeedEditionContext} from '../context/ProductFeedEditionContext';
import {transformConfigToList,BO_API_URL} from '../utils';
import Confirm from '../../../Components/Confirm';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import ConnectionInfosContainer from './Mapping/ConnectionInfosContainer';
import editionStyles from './ProductFeedEdition.module.css';
import PrestashopContainerMapping from './Mapping/PrestashopContainerMapping';
import SelectedFields from './Mapping/SelectedFields';
import SelectedFieldsMaz from './Mapping/SelectFieldsMaz';
import SelectedFieldsShopify from './Mapping/SelectedFieldsShopify';
import SelectedTags from './Mapping/SelectedTags';
import SelectedCategories from './Mapping/SelectedCategories';
import CategoryHierarchie from './Mapping/CategoryHierarchie';
import PanelRawFeed from './Mapping/PanelRawFeed';
import { v4 as uuidv4 } from 'uuid';
import Mappingcustom from './Mapping/Mappingcustom';
export default function EditorContainer(props) {
	
	let location = useLocation();

	const {step,
		accountId,
		setTenant,
		setMode,
		setStep,
		setisLoading,
		isLoading,
		services,
		systemServices,
		setCrudLink,
		handleChangeProductFeedConfig,
		setneedBreadCrumb,
		modalConfirmIsOpen,
		handleCloseConfirm,
		returnToDashboard,
		setNeedToFetchMetaDatas,
		handleOpenClosePanelRawfeed,
		handleCreateNewEmptyProductFeed,
		setsourceDetectionCrud,
		productFeedObject,
		handleChangeTenantConfig,
		setTagConfig,
		panelRawFeedIsOpen,rawFeed
	} = useProductFeedEditionContext();
	

	function getDatasFeed(objetWithKeys){
		services.call(`${BO_API_URL}feature/${objetWithKeys.id}/productfeedconfig/summary`,'get',null,
			success => {
				const tenantsAndFeeds = transformConfigToList(success?.tenantConfigs);
				handleChangeTenantConfig(success?.tenantConfigs[objetWithKeys.tenant]);
				const tagsAndCategoriesFlagsLink = success?.tenantConfigs[objetWithKeys.tenant]._links.tagsAndCategoriesFlags;
				const elementLink = tenantsAndFeeds.reduce((acc, curr) => {
					if(curr.key === objetWithKeys.tenant){
						const feedData = curr.feeds.find(feed => feed.id === objetWithKeys.key);
						acc = feedData._links.detail?.href;
					}
					return acc;
				}, '');

				services.call(tagsAndCategoriesFlagsLink.href,'get',null,
					success => {
						const obj = {
							privateTags : success.privateTags || [],
							excludedTags : success.excludedTags || [],
							excludedCategories : success.excludedCategories || [],
						};
						setTagConfig(obj);
					},
					error =>{
						systemServices.showError();
						console.error(error);
						setisLoading(false);
					}
				);
				services.call(elementLink,'get',null,
					successCurrFeed => {
						if(successCurrFeed.type !== 'OnSiteData' && successCurrFeed.type !== 'PrestaShop' && successCurrFeed.type !== 'Mazeberry' && successCurrFeed.type !== 'Shopify'){
							const mappings = {};
					
							for (const property in successCurrFeed.fieldMappingInfos.mappings) {
								const valueElem = successCurrFeed.fieldMappingInfos.mappings[property];

								if(valueElem.length === 1){
									if(valueElem[0].transformations !== null && valueElem[0].transformations.list !== null){
					
										const elementCurrent = 	valueElem[0].transformations.list.map(ele => {
											return {...ele,
												uuid: uuidv4()
											};
										});
										const objN = {
											...valueElem[0],
											transformations : {
												list : [...elementCurrent]
											}
										};
										mappings[property] = [objN] ;
									}else{
		
										mappings[property] = valueElem;
									}
	
								}else{
	
									let oldValue = [];
									valueElem.map(x => {
										if(x.transformations !== null){
											if(x.transformations.hasOwnProperty('list') && x.transformations.list !== null){
												const elementCurrent = 	x.transformations.list.map(ele => {
													return {...ele,
														uuid: uuidv4()
													};
												});
												const objN = {
													...x,
													transformations : {
														list : [...elementCurrent]
													}
												};
												oldValue = [...oldValue,objN];
											}else{
												oldValue = [...oldValue,x];
											}
										}else{
											oldValue = [...oldValue,x];
										}
									});
									mappings[property] = [...oldValue];
								}
							}
							const successCurrFeedTransformFormatted = {
								...successCurrFeed,
								fieldMappingInfos: {...successCurrFeed.fieldMappingInfos,
									mappings : mappings
								}
							};
							/// get la config et afficher les composants d'éditions
							setisLoading(false);
							setCrudLink(successCurrFeedTransformFormatted._links);
							
							handleChangeProductFeedConfig(successCurrFeedTransformFormatted);
							if(successCurrFeedTransformFormatted?.type === 'GenericUri' || successCurrFeedTransformFormatted?.type === 'Http'){
								setNeedToFetchMetaDatas(true);
							}
						}else{
							if(successCurrFeed?.type === 'GenericUri' || successCurrFeed?.type === 'Http'){
								setNeedToFetchMetaDatas(true);
							}
							handleChangeProductFeedConfig(successCurrFeed);
							setisLoading(false);
							setCrudLink(successCurrFeed._links);
						}
					},
					error =>{
						systemServices.showError();
						console.error(error);
						setisLoading(false);
					}
				);
		
			},
			error =>{
				services.call(`${window.BEYABLE_env.BO_API_URL}feature/${accountId}/productfeedconfig/`,'put', {},
					sucess =>{
						getDatasFeed();
					},
					error =>{

					}
				);
				setisLoading(false);
				systemServices.showError();
				console.error(error);
			}
		);
	}



	function getLinksCrud(objetWithKeys){
		services.call(`${BO_API_URL}feature/${objetWithKeys.id}/productfeedconfig/summary`,'get',null,
			success => {
				const tenantsAndFeeds = transformConfigToList(success?.tenantConfigs);
				const elementLink = tenantsAndFeeds.reduce((acc, curr) => {
					if(curr.key === decodeURIComponent(objetWithKeys.tenant) || ''){					
						acc = curr.links;
					}
					return acc;
				}, {});
				// if(!elementLink.hasOwnProperty('addSource')){
					
				// }
				elementLink.sourceMetadata = success?._links?.sourceMetadata;
				setsourceDetectionCrud(success?._links?.sourceDetection?.href);
				setCrudLink(elementLink);
			},
			error =>{
				setisLoading(false);
				systemServices.showError('This product feed service cannot be reached, we cannot retrieve the available columns. Please check the feed accessibility');

				console.error(error);
			}
		);
	}
	



	useEffect(()=>{
		if(!location.search.includes('key')){

			// Check mode of edition
			// if key does not exist we are in creation
			setMode('creation');
			setStep(1);
			setisLoading(false);
			setneedBreadCrumb(true);
			handleCreateNewEmptyProductFeed();
			if(location.search.includes('ka')){
				//  sometimes id is ka
				const idOfTenant =location.search.split('?ka=');
				const tenant = idOfTenant[1].split('&tenant=');
				let splitItWithoutKu = tenant[0];
				if(tenant[0].includes('&ku=')){
					const splitItWithoutKuTest =  tenant[0].split('&ku=');
					splitItWithoutKu = splitItWithoutKuTest[0];
				}
				const objetWithKeys = {
					id : splitItWithoutKu,
					tenant:  decodeURI(tenant[1])
				};
				getLinksCrud(objetWithKeys);	
				setTenant(decodeURI(tenant[1]))	;	
			}else{
				//  sometimes id is id
				const idOfTenant =location.search.split('?id=');
				const tenant = idOfTenant[1].split('&tenant=');
				
				const objetWithKeys = {
					id : tenant[0],
					tenant:  decodeURI(tenant[1])
				};

				getLinksCrud(objetWithKeys);
				setTenant(decodeURI(tenant[1]))	;	
			}
		}else{
			// if key exist we are in edition
			if(location.search.includes('ka')){
				//  sometimes id is ka
				const idOfTenant =location.search.split('?ka=');
				
				let splitId = idOfTenant[1].split('&key=');
				let splitItWithoutKu = splitId[0];
				if(splitId[0].includes('&ku=')){
					const splitItWithoutKuTest =  splitId[0].split('&ku=');
					splitItWithoutKu = splitItWithoutKuTest[0];
				}
				const splitKey = splitId[1].split('&tenant=');
				
				const objetWithKeys = {
					id : splitItWithoutKu,
					key:  splitKey[0], 
					tenant:  decodeURI(splitKey[1]) 
				};
				if(splitId){
					setMode('edition');
				}
				getDatasFeed(objetWithKeys);
				setTenant(decodeURI(splitKey[1]))	;	
			}else{
				//  sometimes id is id
				const idOfTenant =location.search.split('?id=');
				const splitId = idOfTenant[1].split('&key=') ;
				const splitKey = splitId[1].split('&tenant=');
				
				const objetWithKeys = {
					id : splitId[0],
					key:  splitKey[0], 
					tenant:  decodeURI(splitKey[1])
				};
				if(splitId){
					setMode('edition');
				}
				getDatasFeed(objetWithKeys);
				setTenant(decodeURI(splitKey[1]))	;	
			}
			setStep(2);
		}
		
	},[location]);

	if (isLoading) return <SpinnerWheel wheelSize='l' />;
	
	return (
		<div className='page_full flex flex_v'>
			{panelRawFeedIsOpen &&
			<PanelRawFeed
				rawFeed={rawFeed}
				panelRawFeedIsOpen={panelRawFeedIsOpen}
				handleOpenClosePanelRawfeed={handleOpenClosePanelRawfeed}
			/>
			}
			<Confirm
				isOpen={modalConfirmIsOpen}
				onClose={(e) => handleCloseConfirm()}
				title="Close feed editor"
				text="Are you sure to close without saving?"
				cancelText="Stay"
				confirmText="Quit without saving"
				confirmCallback={(e) => returnToDashboard()}
			/>
			<ProductFeedHeader
				title='Product Feed'
				returnUrl='/ProductFeed/Config'
			/>
			<div className='flex_item_full vscroll'>
				<ConnectionContainer />
				{productFeedObject?.type === 'PrestaShop' && step === 2 &&
					<div className={editionStyles.wrapper_outer}>
						<div className={editionStyles.wrapper_inner}>
							<ConnectionInfosContainer />
							<div className={editionStyles.header}>
								<div className={editionStyles.header_title}>Field settings</div>
								<div className={editionStyles.header_desc}>Help us set up your prestashop feed.</div>
							</div>
							<div className={editionStyles.card_group}>
								<PrestashopContainerMapping/>
								<CategoryHierarchie/>
							</div>
						</div>
					</div>
				}
				{productFeedObject?.type !== 'PrestaShop' && productFeedObject?.type !== 'OnSiteData' && productFeedObject?.type !== 'Mazeberry' && productFeedObject?.type !== 'Shopify' &&
					<MappingContainer />
				}
				{productFeedObject?.type === 'OnSiteData'&& step  &&
					<div className={editionStyles.wrapper_outer}>
						<div className={editionStyles.wrapper_inner}>
							<ConnectionInfosContainer />
							<SelectedFields />
							<div className={editionStyles.card_group}>
								<SelectedTags useAutoComplete={true} />
								<SelectedCategories/>
							</div>
						</div>
					</div>
				}
				{productFeedObject?.type === 'Mazeberry'&& step === 2 &&
					<div className={editionStyles.wrapper_outer}>
						<div className={editionStyles.wrapper_inner}>
							<ConnectionInfosContainer />
							<SelectedFieldsMaz />
							<div className={editionStyles.card_group}>
								<SelectedTags useAutoComplete={false}/>
							</div>
						</div>
					</div>
				}
				{productFeedObject?.type === 'Shopify'&& step === 2 &&
					<div className={editionStyles.wrapper_outer}>
						<div className={editionStyles.wrapper_inner}>
							<ConnectionInfosContainer />
							<SelectedFieldsShopify />
							<div className={editionStyles.card_group}>
								<SelectedTags useAutoComplete={false}/>
								<Mappingcustom/>
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	);
}
