
import ReactCrop, {
	centerCrop,
	makeAspectCrop,
} from 'react-image-crop';
export function checkIfNbIsANumber(nbs){
	const arrayOfResponse = nbs.map(nb => {
		if(isNaN(nb)){
			return false;
		}else{
			return true;
		}
	});
	return arrayOfResponse;
}
export function getAspectRatio(width, height ) {
	let ratio = width / height;
	let threshold = 0.1;
	if (Math.abs(ratio - (16 / 9)) < threshold) {
		return (16 / 9);
	} else if (Math.abs(ratio - 1) < threshold) {
		return 1;
	} else if (Math.abs(ratio - (4 / 3)) < threshold) {
		return 1;
	} else {
		// preserve the aspect ratio by adjusting the larger dimension
		if (ratio > (16 / 9)) {
			return (16 / 9);
		} else {
			return 1;
		}
	}
}
// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
export function centerAspectCrop(
	mediaWidth,
	mediaHeight,
	aspect,
) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 100,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	);
}
export function centerAspectCropLocked(
	mediaWidth,
	mediaHeight,
	aspect,
	widthLocked,
	heightLocked,
) {
	return centerCrop(
		
		makeAspectCrop(
			{
				unit: 'px',
				width: widthLocked,
				height: heightLocked
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	);
}
export const listOfFormat = [
	{
		label : 'Jpeg',
		key: 'jpeg',
	},
	{
		label : 'Png',
		key : 'png'
	},
	{
		label : 'Webp',
		key : 'webp'
	},
	{
		label : 'SVG',
		key : 'svg'
	},
];
export const listOfQuality = [
	{
		label : 'Auto',
		key: 'auto',
		quality : 100,
		qualityMax : 100
	},
	{
		label : 'Best',
		key: 'best',
		quality : 100,
		qualityMax : 100
	},
	{
		label : 'Good',
		key: 'good',
		quality : 100,
		qualityMax : 70
	},
	{
		label : 'Eco',
		key: 'eco',
		quality : 50,
		qualityMax : 40
	},
];