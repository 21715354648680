import moment from 'moment';

export const FORMAT = 'YYYY/MM/DD';

export const FORMAT_WITH_HMS = 'YYYY/MM/DD HH:mm:ss';

export const cardFetchOptions = {
	'fromDate': '',
	'toDate': '',
	'metrics': [
		'NumberOfSessions'
	],
	'timeGranularity': 'None',
	'dimensions': [],
	'limit': 5,
	'offset': 0,
	'filters': [],
	'search': null
};


const formatToday = moment().format(FORMAT);

let template = { fromDate: '', toDate: formatToday };

/*
	Day => "this week"
	MondayWeek => "last 30",
	SundayWeek,
	Month => "year",
*/

export const findNavLabel = (fromDate, toDate) => {
	const compareFromDate = moment(fromDate).format(FORMAT);
	const compareToDate = moment(toDate).format(FORMAT);
	const today =  moment().format(FORMAT);
	// format initNavState
	var last7days = moment(today).subtract(7, 'day').format(FORMAT);
	var last30days = moment(today).subtract(30, 'day').format(FORMAT);
	var lastYear = 	moment(compareToDate).subtract(1, 'year').format(FORMAT);
	if (fromDate == compareToDate) return 'Today';
	if (last7days == compareFromDate) return 'Last 7 days';
	if (last30days == compareFromDate) return 'Last 30 days';
	if (lastYear == compareFromDate) return 'One year';
	else return 'Custom';
};

export const datesOptions = {
	'Today': {
		...template,
		fromDate: moment().format(FORMAT),
		translation: {
			key: 'date.today'
		}
	},
	'Last 7 days': {
		...template,
		fromDate: moment().subtract(7, 'days').format(FORMAT),
		translation: {
			key: 'date.lastNDays',
			vars: {
				days: 7
			}
		}
	},
	'Last 30 days': {
		...template,
		fromDate: moment().subtract(30, 'days').format(FORMAT),
		translation: {
			key: 'date.lastNDays',
			vars: {
				days: 30
			}
		}
	},
	'One year':{
		...template,
		fromDate: moment().subtract(1, 'year').format(FORMAT),
		translation: {
			key: 'date.lastNMonths',
			vars: {
				months: 12
			}
		}
	},
	'Custom': {
		...template,
		formatDate: '', toDate: '',
		translation: {
			key: 'date.custom'
		}
	},
};
export const compareDatesOptions = {
	'Last day': {
		...template,
		fromDate: moment().subtract(1, 'days').format(FORMAT)
	},
	'Last 14 days': {
		...template,
		fromDate: moment().subtract(14, 'days').format(FORMAT)
	},
	'Last 60 days': {
		...template,
		fromDate: moment().subtract(60, 'days').format(FORMAT)
	},
	'Custom': {
		...template,
		formatDate: '', toDate: ''
	},
	'One year':{
		...template,
		fromDate: moment().subtract(1, 'year').format(FORMAT)
	},
};

const intialOption = Object.entries(datesOptions)[1];
const fromDateFirstValue = moment(formatToday).subtract(14, 'day').format(FORMAT);


export const initialDates = {
	initialFrom: intialOption[1].fromDate,
	initialTo: intialOption[1].toDate,
	initialLabel: intialOption[0],
	initialLabel2: 'Custom',
	fromDateToCompare: fromDateFirstValue,
	toDateToCompare: intialOption[1].fromDate,
};
