import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from './Tooltip';
import styles from './IconTooltip.module.css';

interface IIconTooltip {
	icon?: string;
	fixedWidth?: boolean;
	size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl',
	className?: string,
	theme?: 'light' | 'medium',
	tooltip?: string | null,
	tooltipHTML?: any,
	delayShow?: number,
}

function IconTooltip ({
	icon = 'fas fa-info-circle',
	fixedWidth = true,
	size = 'm',
	className = '',
	theme = 'medium',
	tooltip,
	tooltipHTML,
	delayShow = 300,
	...otherProps
}:IIconTooltip) {

	const tooltipID = uuidv4();

	const btnClass = [styles.btn];
	const iconClass = [styles.icon];

	if (className) {
		btnClass.push(className);
	}

	if (size) {
		btnClass.push(styles['btn_' + size]);
	}

	if (theme) {
		btnClass.push(styles['btn_theme_' + theme]);
	}

	if (fixedWidth) {
		iconClass.push('fa-fw');
	}

	if (icon) {
		iconClass.push(icon);
	}

	return (
		<>
			<Tooltip
				id={tooltipID}
				text={tooltip}
				html={tooltipHTML}
				delayShow={delayShow}
				globalEventOff={''}
			/>
			<span
				className={btnClass.join(' ')}
				data-tip={tooltipHTML ? 'a' : tooltip}
				data-for={tooltipID}
				{...otherProps}>
				<i className={iconClass.join(' ')}></i>
			</span>
		</>
	);
}

export {
	IconTooltip
};
