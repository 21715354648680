import React, { useState, useEffect } from 'react';
import SearchBar from '../../../Components/SearchBar';
import Btn from '../../../Components/Btn';
import { useAnalyticsConfigContext } from './userAnalyticsConfigContext';
import EmptyState from '../../../Components/EmptyState';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';
import TenantAnalyticsItem from './TenantAnalyticsItem';

export default function TenantAnalyticsContainer() {
	const {
		referenceData,
		setHasUsersByTenantConfig,
		dataFiltered,
		setDataFiltered,
		handleOpenCloseModalTenant,
		handleSelectTenant,
		setisCreation,
		isLoading,
	} = useAnalyticsConfigContext();

	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [noData, setNoData] = useState(null);
	const [sortData, setSortData] = useState({ property: 'name', order: 'desc' });
	const [cssClass, setCssClass] = useState({ name: '' });

	useEffect(() => {
		if (referenceData?.tenant) {
			// Column css class
			const tmpCssClass = { ...cssClass };
			const keys = Object.keys(tmpCssClass);
			for (let i = 0; i < keys.length; i++) {
				const k = keys[i];
				if (k === sortData.property) {
					tmpCssClass[k] = 'user_grid_sort is_active is_' + sortData.order;
				} else {
					tmpCssClass[k] = 'user_grid_sort';
				}
			}
			setCssClass(tmpCssClass);
			search({ ...referenceData?.tenant }, valueInSearchBar, referenceData);
		}
	}, [sortData, referenceData?.tenant, referenceData]);

	const getUserInfos = (u) => {
		const userInfos = referenceData.users.find(user => user.id === u);
		return userInfos ? userInfos : { id: u, firstName: 'unknown' };
	};

	const sortUserList = (list) => {
		if (!list) {
			return;
		}
		const tmpList = Object.keys(list);
		tmpList && tmpList.sort((a, b) => {
			if (sortData.property === 'name') {
				const order = (sortData.order === 'desc') ? 1 : -1;
				if (a?.toUpperCase() < b?.toUpperCase()) return -order;
				if (a?.toUpperCase() > b?.toUpperCase()) return order;
				return 0;
			}
		});
		const uniqueItem = tmpList.reduce((acc, curr) => {
			const newObj = { [curr]: list[curr] };
			return { ...acc, ...newObj };
		}, {});
		setDataFiltered(uniqueItem);
	};

	const changeSortData = (property, order) => {
		if (property !== sortData.property) {
			setSortData({ property: property, order: order });
			return;
		}
		if (sortData.order === 'desc') {
			setSortData({ property: sortData.property, order: 'asc' });
			return;
		}
		setSortData({ property: sortData.property, order: 'desc' });
	};

	const search = (table, word) => {
		const mergedUsers = {};
		for (const tenant in table) {
			table[tenant].forEach(userId => {
				const userInfo = referenceData?.users.find(user => user.id === userId);
				if (userInfo) {
					if (!mergedUsers[userId]) {
						mergedUsers[userId] = { tenant: [tenant], info: userInfo };
					} else {
						mergedUsers[userId].tenant.push(tenant);
					}
				}
			});
		}

		const tableList = Object.keys(table);
		setNoData(null);

		if (word.length > 0) {
			let resultName = tableList.filter(line => line.toUpperCase().includes(word.toUpperCase()));
			let resultUsers = Object.values(mergedUsers).filter(user =>
				user.info.displayName.toUpperCase().includes(word.toUpperCase()) ||
				user.info.email.toUpperCase().includes(word.toUpperCase())
			);

			let finalArray = [...new Set([...resultName, ...resultUsers.flatMap(user => user.tenant)])];
			console.log('Final Array:', finalArray);

			const items = finalArray.reduce((acc, curr) => {
				const newObj = { [curr]: table[curr] };
				return { ...acc, ...newObj };
			}, {});

			if (finalArray.length === 0) {
				setNoData('No tenant or user found for this search');
			} else {
				sortUserList(items);
			}
		} else {
			sortUserList({ ...referenceData?.tenant });
		}
	};

	const handleChange = (event) => {
		event.persist();
		setValueInSearchBar(event.target.value);
		if (dataFiltered) {
			search(dataFiltered, event.target.value);
		}
	};

	const handleSelect = (e, elem, list) => {
		e.stopPropagation();
		handleSelectTenant({
			name: elem,
			users: list.map(x => getUserInfos(x))
		});
		handleOpenCloseModalTenant(true);
		setisCreation(false);
	};

	return (
		<div>
			{isLoading && <SpinnerWheel wheelSize="l" />}
			{!isLoading && !referenceData.usersLoaded && !referenceData.tenantLoaded && <SpinnerWheel wheelSize="l" />}
			{referenceData && (
				<>
					<section className="flex">
						<div className="flex_item_full">
							<SearchBar
								placeholder="Search for a tenant or a user"
								valueInSearchBar={valueInSearchBar}
								onChangeValue={(e) => handleChange(e)}
								width={'xl'}
							/>
						</div>
						<div className='flex_item_fix ml_30'>
							<Dropdown
								isOpen={dropdownIsOpen}
								toggle={(s) => setDropdownIsOpen(s)}
								button={
									<Btn
										tooltip="Toggle settings"
										icon="fas fa-cog"
										style="ghost"
										color="secondary"
									/>
								}
							>
								<Listbox>
									{referenceData.HasUsersByTenantConfig && (
										<ListboxItem
											message="Disable permissions by tenant"
											icon="fas fa-ban"
											onClick={() => {
												setHasUsersByTenantConfig(false);
												setDropdownIsOpen(false);
											}}
										/>
									)}
									{!referenceData.HasUsersByTenantConfig && (
										<ListboxItem
											message="Enable permissions by tenant"
											icon="fas fa-user"
											onClick={() => {
												setHasUsersByTenantConfig(true);
												setDropdownIsOpen(false);
											}}
										/>
									)}
								</Listbox>
							</Dropdown>
						</div>
					</section>
					{!noData && (
						<section className="mt_20">
							<div className="table_grid">
								<div className="table_row table_head_row" style={{ 'gridTemplateColumns': '1fr 4fr 180px' }}>
									<div className="table_col">
										<a onClick={() => changeSortData('name', 'desc')} className={cssClass.name}>
											Tenant Name
											{sortData.property === 'name' && sortData.order === 'desc' && <i className="fas fa-arrow-down"></i>}
											{sortData.property === 'name' && sortData.order === 'asc' && <i className="fas fa-arrow-up"></i>}
										</a>
									</div>
									{referenceData.HasUsersByTenantConfig && <div className="table_col">Users</div>}
									<div className="table_col"></div>
								</div>
								{dataFiltered && Object.keys(dataFiltered).map((tenant) => (
									<TenantAnalyticsItem
										key={tenant}
										HasUsersByTenantConfig={referenceData.HasUsersByTenantConfig}
										tenant={tenant}
										data={dataFiltered[tenant]}
										handleSelect={handleSelect}
									/>
								))}
							</div>
						</section>
					)}
					{noData && (
						<EmptyState
							title='No matching tenant'
							text='Please try another search'
							textSize='l'
							verticalSize='xl'
							icon='fas fa-search'
						/>
					)}
				</>
			)}
			{!isLoading && !referenceData && (
				<EmptyState
					title='No tenant yet'
					textSize='xl'
					verticalSize='xl'
				/>
			)}
		</div>
	);
}
