import React, {useEffect, useState,ReactElement} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import boost from 'highcharts/modules/boost';
import hcMap from 'highcharts/modules/map';
import proj4 from 'proj4';
import {GeolocalisationObject} from './GeolocalisationType';
import { useGeoContext } from '../../context/GeolocContext.js';
hcMap(Highcharts);
boost(Highcharts);
declare global {
	interface Window {
		proj4: typeof proj4;
	}
}

window.proj4 = proj4; // Assign the proj4 library to the window object
export const ChartByPoints = ({countryCode,isoName,dataToManipulate,metricGeolocalisationSelected}) :ReactElement=> {
	if (typeof window !== 'undefined') {
		window.proj4 = window.proj4 || proj4;
	}

	const [worldMapChart, setWorldMap] = useState();
	const {
		setSelectedCountryName
	} = useGeoContext();

	useEffect(() => {
		const dataWithValues = dataToManipulate.filter(x => x.iso === isoName ).map((el: GeolocalisationObject) => ({
			...el,
			value: el[metricGeolocalisationSelected.key],
		}));
		const countryData = dataWithValues.reduce((acc, point) => {
			if (point.iso) {
				acc[point.iso] = (acc[point.iso] || 0) + point.value;
			}
			return acc;
		}, {});
		const formattedCountryData = Object.entries(countryData).map(
			([iso, value]) => [iso, value]
		);
		const allValues = dataWithValues.map(o => o.value);
		const maxValue: number = Math.max(...allValues);
		const minValue: number = Math.min(...allValues);
		const range = maxValue - minValue;
		const minRadius = 4;
		const maxRadius = 50;

		const getRadius = (value) => {
			const radius = ((value - minValue) / range) * (maxRadius - minRadius) + minRadius;
			return radius;
		};
		if(!countryCode)return;
		fetch(countryCode).then((response) => {
			if (!response.ok) {
				throw new Error(`Erreur HTTP : ${response.status}`);
			}
			return response.json();
		}).then((mapData) => {
			setSelectedCountryName(mapData.title);
			const options:any = {
				boost: {
					useGPUTranslations: true,
					usePreAllocated: true,
					seriesThreshold: 5
				},
				title: {
					text: mapData.title
				},
				chart: {
					// backgroundColor: 'transparent',
					map: mapData,
					animation: false,
					height: 500
				},

				credits: {
					enabled: false,
				},
				legend: {
					enabled: false
				},
				mapNavigation: {
					enabled: true,
					buttonOptions: {
						alignTo: 'spacingBox',
					},
				},
				colorAxis: {
					min: 0,
					max: maxValue,
					minColor: '#E0E9FD',
					maxColor: '#0032A0',
					stops: [
						[0, '#E0E9FD'],
						[1, '#0032A0'],
					],
					nullColor: '#f0f0f0',
				},
				tooltip: {
					shared: true,
					useHTML: true,
					formatter: function (this:any) {
						if (!this.point) return '';
						const s = `<b>${this.point.name || 'Unknown Name'}</b>: ${
							this.point.value || 'Loading ...'
						}<br/>`;
						return s;
					},
					backgroundColor: '#FFF',
					borderWidth: 0,
					opacity: 1,
					borderRadius: 10,
					style: {
						color: '#151E39',
						fontWeight: 'normal',
						fontSize: '12px',
						fontFamily: 'Roboto',
					}
				},

				series:  [
					{
						name: metricGeolocalisationSelected.label,
						data: formattedCountryData,
						mapData: mapData,
						dataLabels: {
							enabled: true,
							color: '#FFFFFF',
							style: {
								fontWeight: 'bold'
							},

						},
						joinBy: ['iso-a2', 'hc-key'],
						states: {
							hover: {
								color: '#2E90FA',
							},
						},
						showInLegend: false,
					},
					{
						type: 'mappoint',
						name: 'Cities',
						turboThreshold: 10000,
						data: dataWithValues.map((point) => ({
							name: point.name,
							lat: point.lat,
							lon: point.lon,
							value: point.value,
							marker: {
								radius: getRadius(point.value || 0),
								lineWidth: 1,
								lineColor: '#81A8FF',
								fillColor: '#4378F070',
							},
							dataLabels: {
								enabled: true,
								format: '{point.name}',
								style: {
									color: '#151E39',
									textOutline: 'none',
									fontWeight: 'normal',
									fontSize: '12px',
									fontFamily: 'Roboto',
									align: 'center',
								},
								allowOverlap: false,
							},
						})),
						tooltip: {
							pointFormat: '{point.name}: {point.value} (Value)',
						},
					}

				]
			};


			setWorldMap(options);

		}).catch((error) => {
			console.error('Erreur lors du chargement de la carte:', error);

		});

	}, [metricGeolocalisationSelected,dataToManipulate,countryCode]);

	return (
		<>
			<HighchartsReact
				key={555}
				highcharts={Highcharts}
				constructorType={'mapChart'}
				options={worldMapChart}
			/></>
	);
};



