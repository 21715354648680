import { v4 as uuidv4 } from 'uuid';
import RecommendationRuleCompositionServices,
{
	CompositionApiType,
	ProductRecommendationRulesCompositionGroup,
	ProductRecommendationRulesCompositionGroupItem,
	UpdateOrCreateProductRecommendationRulesComposition
} from '../../../../Services/RecommendationRuleCompositionServices';
import { ComposableGroupItem, Composition, CompositionGroup, validateForSaving } from './EntityTypes';
import { listRulesByType } from './RuleType';

function convertRuleForSaving(rule: ComposableGroupItem): ProductRecommendationRulesCompositionGroupItem {
	return {
		ruleId: rule.actualRuleId,
		type: rule.persoRuleType,
		ruleLimit: rule.isFillUp ? null : rule.ruleLimit
	};
}

function convertCompositionGroupForSaving(compositionGroup: CompositionGroup): ProductRecommendationRulesCompositionGroup {
	return {
		rules: compositionGroup.rules.map(convertRuleForSaving),
		size: compositionGroup.size ?? 0
	};
}

function convertCompositionForSaving(composition: Composition): UpdateOrCreateProductRecommendationRulesComposition {
	return {
		description: composition.description,
		name: composition.name,
		groups: composition.groups.map(convertCompositionGroupForSaving)
	};
}

function convertRuleFromApiType(rule: ProductRecommendationRulesCompositionGroupItem): ComposableGroupItem {
	const ruleName = rule.type === null ? rule.name : listRulesByType.find(x => x.PersoRuleType === rule.type)?.label;
	return {
		actualRuleId: rule.ruleId,
		internalId: uuidv4(),
		name: ruleName ?? '',
		ruleLimit: rule.ruleLimit,
		isFillUp: rule.ruleLimit === null,
		isPersoRule: rule.type !== null,
		persoRuleType: rule.type
	};
}

function convertCompositionGroupFromApiType(group: ProductRecommendationRulesCompositionGroup): CompositionGroup {
	return new CompositionGroup(uuidv4(), group.rules.map(convertRuleFromApiType), group.size, null);
}


export function apiAdapter(crud: RecommendationRuleCompositionServices) {

	const getComposition = (
		compositionId: string,
		callbackSuccess: (composition: Composition) => void,
		callbackError: (error: string) => any
	) => {
		crud.getComposition(compositionId, (result: CompositionApiType) => {
			callbackSuccess(new Composition(result.id, result.name, result.description ?? '', result.groups.map(convertCompositionGroupFromApiType), null));
		}, callbackError);
	};

	const updateComposition = (
		composition: Composition,
		callbackSuccess: () => void,
		callbackValidationError: (compositionWithError: Composition) => void,
		callbackError: (error: string) => any
	) => {

		const { hasError, composition: validated } = validateForSaving(composition);
		if (hasError) {
			callbackValidationError(validated);
			return;
		}

		if (validated.id === null) {
			console.error('composition id is missing');
			return callbackError('An error occurred');
		}

		const result = convertCompositionForSaving(validated);
		crud.updateComposition(validated.id, result, callbackSuccess, callbackError);
	};

	const createComposition = (
		composition: Composition,
		callbackSuccess: () => void,
		callbackValidationError: (compositionWithError: Composition) => void,
		callbackError: (error: string) => any) => {
		const { hasError, composition: validated } = validateForSaving(composition);
		if (hasError) {
			callbackValidationError(validated);
			return;
		}

		const result = convertCompositionForSaving(validated);
		crud.createComposition(result, callbackSuccess, callbackError);
	};

	const getPossibleRules = (
		composition: Composition,
		callbackSuccess: (result: { id: string; name: string; }[]) => void,
		callbackError: (error: string) => any) => {

		const empty: string[] = [];
		const allRules = composition.groups.map(x => x.rules).reduce((acc, val) => {
			const ids = val.filter(x => x.actualRuleId !== null).map(x => x.actualRuleId!);
			return [...new Set([...acc, ...ids])];
		}, empty); // cannot use flatMap.. (es2019)

		crud.getPossibleRules(allRules, (result: { composableRules: { id: string; name: string; }[]; }) => callbackSuccess(result.composableRules), callbackError);
	};

	return {
		updateComposition,
		createComposition,
		getPossibleRules,
		getComposition
	};
}