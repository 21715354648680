import React, { useEffect, useState } from 'react';
import {Section} from '../../../../Components/Section/Section';
import {Article} from '../../../../Components/Article/Article';

import { Spinner } from '../../../../Components/Spinner/Spinner';
import SearchBar from '../../../../Components/SearchBar';
import Btn from '../../../../Components/Btn';
import { PageBreadcrumb } from '../../../../Components/Page/PageBreadcrumb';
import { EmptyState } from '../../../../Components/EmptyState/EmptyState';
import {useProductSpecificContext} from '../../context/ContextProductSpecific';
import NavGlobal from '../Nav/NavGlobal';

import {FilterProductInsightsPanel} from '../FilterPanel/FilterProductInsightsPanel';
import {SESSION_STORAGE_KEY, transformToDataStructureArray} from '../utils';
import Chip from '../../ProductInsights/Chip/Chip.js';
import styles from '../ProductsInsights.module.css';
import {AccountHasNoData} from '../EmptyStates/AccountHasNoData';
import {TableDataProductSpecific} from './TableDataProductSpecific';
import TabBar from '../../../../Components/TabBar';
import TabItem from '../../../../Components/TabItem';
import SectionMessage from '../../../../Components/SectionMessage';
import { useTranslation } from 'react-i18next';

export const ProductSpecificContainer = ():React.ReactElement => {

	const {
		isLoading,
		isFirstLoading,
		productsSelections,
		fetchExportKpis,
		isExporting,
		valueInSearchBar,
		handleChange,
		navFilter,
		setNavFilter,
		isCustomSelected,
		setisCustomSelected,
		customDateRange,
		setCustomDateRange,
		updateStorage,
		ComparisonSelected,
		handleSelectComparaisonPeriodHandler,
		handleChangeFormData,
		handleOpenClosePanelForm,
		panelFormIsOpen,
		filterHierarchies,
		selectedValues,
		setSelectedValues,
		chipsByFilterSelected,
		setFilterSelected,
		initialDates,
		accountHasNoDatas,
		tipSelected,
		setTipSelected
	} = useProductSpecificContext();

	const [t] = useTranslation('kpi');

	function handleDeleteChip(id){
		const newObj = JSON.parse((JSON.stringify(selectedValues)));
		delete newObj[id];
		const newFilters = transformToDataStructureArray(newObj);
		sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(newObj));
		setFilterSelected(newFilters);
		setSelectedValues(newObj);
	}

	const [isEmpty, setIsEmpty] = useState(false);
	
	useEffect(() => {
		setIsEmpty(productsSelections.length === 0);
	}, [productsSelections]);

	return (
		<>
			{accountHasNoDatas && <AccountHasNoData/>}
			{!accountHasNoDatas &&	<>	<FilterProductInsightsPanel
				selectedValues={selectedValues}
				setSelectedValues={setSelectedValues}
				filterHierarchies={filterHierarchies}
				panelFormIsOpen={panelFormIsOpen}
				handleOpenClosePanelForm={handleOpenClosePanelForm}
				handleChangeFormData={handleChangeFormData}
			/>
			<Section hasMargin={false} width="l">
				<section className='section no_bottom_pad section_primary'>
					<PageBreadcrumb>
						<span className='h1'>{t('productInsights.productInsights')}</span>
						<span className='h1'>{t('productInsights.catalogAudit')}</span>
					</PageBreadcrumb>
				</section>
				<section className='section'>
					<div className={styles.filters_line}>
						<div className='btn_group_l'>
							<NavGlobal
								showComparedPeriod={false}
								ComparisonSelected={ComparisonSelected}
								setNavFilter={setNavFilter}
								initialDates={initialDates}
								navFilter={navFilter}
								handleSelectComparaisonPeriodHandler={handleSelectComparaisonPeriodHandler}
								setisCustomSelected={setisCustomSelected}
								isCustomSelected={isCustomSelected}
								customDateRange={customDateRange}
								setCustomDateRange={setCustomDateRange}
								updateStorage={updateStorage}
							/>
						</div>
					</div>
					<Article innerSize='l' hasMargin={false}>
						{isFirstLoading &&
							<Spinner display="block" verticalSize="xl" />
						}
						{!isFirstLoading &&
							<>
								<div className="flex_item_full">
									<TabBar size="l">
										<TabItem
											message={'Top products'}
											onClick={() => setTipSelected('RECOMMENDATION_SIMPLE_01')}
											selected={tipSelected == 'RECOMMENDATION_SIMPLE_01'}
										/>
										<TabItem
											message={'Improve product page'}
											onClick={() => setTipSelected('RECOMMENDATION_SIMPLE_02')}
											selected={tipSelected =='RECOMMENDATION_SIMPLE_02'}
										/>
										<TabItem
											message={'Increase visibility'}
											onClick={() => setTipSelected('RECOMMENDATION_SIMPLE_03')}
											selected={tipSelected == 'RECOMMENDATION_SIMPLE_03'}
										/>
										<TabItem
											message={'Poor visibility'}
											onClick={() => setTipSelected('RECOMMENDATION_SIMPLE_04')}
											selected={tipSelected == 'RECOMMENDATION_SIMPLE_04'}
										/>
									</TabBar>
								</div>

								<div className='flex flex_align_start mb_5 mt_20'>
									<div className="flex_item_full">
										{tipSelected == 'RECOMMENDATION_SIMPLE_01' &&
											<SectionMessage
												title="Top products"
												appearance="outline"
												type="success"
												text={<>
													<div className='s_14'>Products viewed more than those in their category.</div>
													<div className='s_14'>Products purchased more than those in their category.</div>
												</>}
											/>
										}
										{tipSelected == 'RECOMMENDATION_SIMPLE_02' &&
											<SectionMessage
												title="Improve product page"
												appearance="outline"
												type="warning"
												text={<>
													<div className='s_14'>Products viewed more than those in their category.</div>
													<div className='s_14'>Products purchased less than those in their category.</div>
												</>}
											/>
										}
										{tipSelected == 'RECOMMENDATION_SIMPLE_03' &&
											<SectionMessage
												title="These products need more visibility"
												appearance="outline"
												type="warning"
												text={<>
													<div className='s_14'>Products viewed less than those in their category.</div>
													<div className='s_14'>Products purchased more than those in their category.</div>
												</>}
											/>
										}
										{tipSelected == 'RECOMMENDATION_SIMPLE_04' &&
											<SectionMessage
												title="Poor visibility"
												appearance="outline"
												color="alert"
												type="warning"
												text={<>
													<div className='s_14'>Products viewed less than those in their category.</div>
													<div className='s_14'>Products purchased less than those in their category.</div>
												</>}
											/>
										}
									</div>
									<div className="flex_item_fix ml_30">
										<div className='btn_group'>
											{chipsByFilterSelected && chipsByFilterSelected.map(ch =>
												<Chip
													key={ch.type}
													type={ch.type}
													title={ch.title}
													handleDelete={() => handleDeleteChip(ch.type)}
													size="s"
												/>
											)}
											<Btn
												style={'outline'}
												color={'secondary'}
												icon={'fa fa-filter fa-sm'}
												tooltip="Filter by category"
												horizontalSize="s"
												onClick={()=>handleOpenClosePanelForm(true)}
												disabled={isLoading}
											/>
										</div>
									</div>
									<div className='flex_item_fix ml_10'>
										<SearchBar
											placeholder={'Search for a product'}
											valueInSearchBar={valueInSearchBar}
											onChange={(value) => handleChange(value)}
											width="l"
											disabled={isLoading}
											color="white"
										/>
									</div>
									<div className='flex_item_fix ml_10'>
										<Btn
											onClick={() => fetchExportKpis()}
											tooltip={'Export data'}
											icon={'fa fa-download fa-sm'}
											color="secondary"
											style="outline"
											disabled={isEmpty || isLoading}
											isLoading={isExporting}
										/>
									</div>
								</div>
								<div className='pos_rel'>
									{isLoading &&
										<Spinner display="overlay" />
									}
									{isEmpty &&
										<EmptyState
											title="No products match these filters"
											text="Maybe try with other filters"
											imageUrl='/Assets/empty_no_data.svg'
											verticalSize='xl'
											textSize='l'
										/>
									}
									{!isEmpty && <TableDataProductSpecific />}
								</div>
							</>}
					</Article>
				</section>
			</Section>
			</>}
		</>
	);
};
