import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ProductCard from '../ProductCard/ProductCard';
import TableRowItem from '../TableRowItem';
import TableColItem from '../TableColItem';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
import { ProductThumb } from '../../../../Components/Product/ProductThumb';

const optionCssList = {
	gridTemplateColumns: '6fr 2fr 2f 2fr',
	userSelect: 'none',
};
const idTooltipTrash = uuidv4();
const idTooltipDrag = uuidv4();

const elementRanking = (elem,
	dimension,
	deletePromotedProduct,
	deleteDemotedProduct,
	refs) => {

	const Url = elem.url;

	return (
		<>
			<TableColItem>
				<div className="flex">
					<div className="flex_item_fix">
						<a className="emerch_avatar emerch_avatar_margin"
							href={Url}
							target="_blank"
							style={{ userSelect: 'none' }}
						>
							<ProductThumb
								alt={elem.id}
								url={elem.urlThumbnail}
								width={40}
							/>
						</a>
					</div>
					<div style={{display: 'flex'}}
						{...refs.listeners}
						data-for={idTooltipDrag}
						data-tip={'Drag to sort'}
					>
						<i className="fas fa-grip-vertical"
							style={{
								cursor: 'grab',
								flexGrow: '4',
								display: 'flex',
								alignItems: 'start',
								marginRight: '2px'
							}}
						/>
						<p className="table_row_name" style={{cursor: 'grab', textTransform: 'none'}}>
							{elem.name}
						</p>
					</div>
				</div>
			</TableColItem>
			<TableColItem refs={refs} style={{cursor: 'grab'}}>
				<p>{elem.price}€</p>
			</TableColItem>

			<TableColItem style={{cursor: 'grab'}}>
				<p>{elem.id}</p>
			</TableColItem>
			<TableColItem>
				<ReactTooltip
					id={idTooltipTrash}
					backgroundColor='black'
					effect='solid'
					place="bottom"
					globalEventOff="click" 
				/>
				<a className="flex_item_fix ml_10 icon_btn s"
					style={{ textTransform: 'none' }}
					data-for={idTooltipTrash}
					data-tip="Remove this product"
					onClick={dimension === 'promoted' ? () => deletePromotedProduct(elem.id) :
						() => deleteDemotedProduct(elem.id)
					}>
					<i className="fas fa-times"></i>
				</a>
			</TableColItem>
		</>
	);
};

export const SortableCard = (props) => {

	const {
		idInClipBoard,
		copyToClipBoard,
		dimension,
		deletePromotedProduct,
		product,
		type,
		deleteDemotedProduct,
	} = props;

	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		transform,
		transition
	} = useSortable({ id: product.id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		zIndex: isDragging ? '1000' : 'auto',
		opacity: isDragging ? 0.5 : 1,
		color: isDragging && 'var(--btn-color-primary)',
		borderTop: isDragging && '1px solid var(--btn-color-primary)',
		borderBottom: isDragging && '1px solid var(--btn-color-primary)',
	};

	if (!product) {
		return <></>;
	}

	if (type === 'grid') {
		return (
			<div
				style={{ ...style }}
				ref={setNodeRef}
				// {...props}
				{...attributes}
			>
				<ProductCard
					idInClipBoard={idInClipBoard}
					copyToClipBoard={(e) => copyToClipBoard(e)}
					deleteItemFunction={dimension === 'promoted' ? () => deletePromotedProduct(product.id) :
						() => deleteDemotedProduct(product.id)
					}
					isDraggableItem={true}
					key={product.id}
					product={product}
					dimension={dimension}
				/>
			</div>
		);
	}

	const refs = {
		setNodeRef: setNodeRef,
		// props: props,
		attributes: attributes,
		listeners: listeners
	};

	return (
		<TableRowItem
			key={product.id}
			specialClasseName={'table_body_row'}
			styleObj={{ ...optionCssList, ...style }}
			isDraggableItem={true}
			refs={refs}
		>
			{elementRanking(product, dimension,
				deletePromotedProduct,
				deleteDemotedProduct,
				refs
			)}
		</TableRowItem>
	);
};
