import React from 'react';
import {dataInfos, ValueElementWithLabelTraduction} from '../NavigationFunnelTypes';
import styles from '../funnel.module.css';
import {  getFormattedNumber } from '../../../Utils';

import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

const stackHeight = 160;
//const stackWidth = 400;

interface FunnelItemCollection {
	[key:string] : dataInfos[]
}

type ItemPartProps = {
	name :string;
	data: dataInfos[];
	pageType:ValueElementWithLabelTraduction;
	totalPageType?: number;
	middleBlock: FunnelItemCollection | undefined;
	splitSelected: string | undefined;
	maximumValue?: number;
	typeEnv: string;
	context:any;
	stepNumber?: number;
}

type StackProps = {
	items: dataInfos[];
	total: number;
	height: number;
	splitSelected: string;
	listOfDimensionsSplit:any;
}

type SideProps = {
	items: dataInfos[];
	total: number;
	name: string;
	splitSelected: string;
	listOfDimensionsSplit:any;
	side: 'exit' | 'entrance';
}

type StackTooltipProps = {
	items: dataInfos[];
	total: number;
	splitSelected: string;
	listOfDimensionsSplit:any;
}

type StackItemProps = {
	item?:dataInfos;
	order?: number;
	total: number;
	value: number;
	splitSelected:string;
	listOfDimensionsSplit:any
}

type TransitionProps = {
	leftHeight: number;
	rightHeight: number;
	percent: number;
	side: 'exit' | 'entrance';
}

export const ItemPart = ({
	name,
	data,
	pageType,
	totalPageType,
	middleBlock,
	splitSelected,
	maximumValue,
	context,
	stepNumber
}:ItemPartProps):React.ReactElement => {

	const labelPageType: string = pageType.label !== '' ? pageType.label : 'Empty';

	const {
		listOfDimensionsSplit
	} = context;

	if (!splitSelected) {
		return <></>;
	}
	
	let total = 0;
	if (data) {
		total = data.reduce((acc: number, item:dataInfos) :number=> {
			acc += item.value;
			return acc;
		}, 0);
	}

	if (name === 'Middle') {

		const ratio = maximumValue ? total / maximumValue : 1;
		const percentTotal = totalPageType ? total / totalPageType * 100 : 0;

		return (
			<div className={styles.colMain} style={{'--width-ratio': ratio } as React.CSSProperties}>
				<div className={styles.stackLabel}>
					{stepNumber}. {labelPageType}
					{' '}<span className={styles.stackLabel_value}>{percentTotal.toFixed(1)} %</span>
					{' '}<span className={styles.stackLabel_subValue}>({getFormattedNumber(total)})</span>
				</div>
				<Stack
					total={total}
					height={stackHeight}
					items={data || []}
					splitSelected={splitSelected}
					listOfDimensionsSplit={listOfDimensionsSplit}
				/>
			</div>
		);
	} else {

		const id:any = pageType.id;
		const dataToReduce = middleBlock ? middleBlock[id] : null;
		const dataMiddleBlock = dataToReduce ? dataToReduce.reduce((acc: number, item:dataInfos) :number=> {
			acc += item.value;
			return acc;
		}, 0 ) : 0;
		const percentInComparisonToMiddle = dataMiddleBlock ? (total / dataMiddleBlock) * 100 : 0;

		return (
			<>
				{name === 'Exits' &&
					<Transition
						rightHeight={stackHeight * percentInComparisonToMiddle / 100}
						leftHeight={stackHeight}
						percent={percentInComparisonToMiddle}
						side="exit"
					/>
				}
				<div className={styles.colIO}>
					<Side
						name={name}
						side={name === 'Exits' ? 'exit' : 'entrance'}
						total={total}
						items={data || []}
						splitSelected={splitSelected}
						listOfDimensionsSplit={listOfDimensionsSplit}
					/>
				</div>
				{name === 'Entrances' &&
					<Transition
						leftHeight={stackHeight * percentInComparisonToMiddle / 100}
						rightHeight={stackHeight}
						percent={percentInComparisonToMiddle}
						side="entrance"
					/>
				}
			</>
		);
	}
};

export const Stack = ({
	items,
	total,
	height,
	splitSelected,
	listOfDimensionsSplit
}:StackProps):React.ReactElement => {

	const tooltipID = uuidv4();

	return (
		<div className={styles.stack} style={{height: height}} data-tip="a" data-for={tooltipID}>
			{items.map((item, idx) => 
				<StackItem
					item={item}
					key={idx}
					order={idx}
					total={total}
					value={item.value}
					listOfDimensionsSplit={listOfDimensionsSplit}
					splitSelected={splitSelected}
				/>
			)}
			{items.length > 0 &&
				<ReactTooltip
					id={tooltipID}
					backgroundColor="white"
					className={styles.stackTooltip}
					textColor="black"
					effect="solid"
					place="bottom"
					globalEventOff="mouseup"
					delayShow={100}
					getContent={() => {
						return (
							<StackTooltip
								items={items}
								total={total}
								splitSelected={splitSelected}
								listOfDimensionsSplit={listOfDimensionsSplit}
							/>
						);
					}}
				/>
			}
		</div>
	);
};

export const Side = ({
	name,
	items,
	total,
	splitSelected,
	listOfDimensionsSplit,
	side
}:SideProps):React.ReactElement => {

	const tooltipID = uuidv4();

	return (
		<div className={side === 'exit' ? styles.sideExit : styles.sideEntrance} data-tip="a" data-for={tooltipID}>
			{total > 0 &&
				<>
					<div className={styles.sideLabel}>
						{name}
						<div className={styles.sideLabel_value}>
							{side !== 'exit' &&
								<i className='fas fa-list grey_3 s_11 mr_3'></i>
							}
							{getFormattedNumber(total)}
							{side === 'exit' &&
								<i className='fas fa-list grey_3 s_11 ml_3'></i>
							}
						</div>
					</div>
					<ReactTooltip
						id={tooltipID}
						backgroundColor="white"
						className={styles.stackTooltip}
						textColor="black"
						effect="solid"
						place="bottom"
						globalEventOff="mouseup"
						delayShow={100}
						getContent={() => {
							return (
								<StackTooltip
									items={items}
									total={total}
									splitSelected={splitSelected}
									listOfDimensionsSplit={listOfDimensionsSplit}
								/>
							);
						}}
					/>
				</>
			}
		</div>
	);
};

export const StackTooltip = ({
	items,
	total,
	splitSelected,
	listOfDimensionsSplit
}:StackTooltipProps):React.ReactElement => {
	function getPercent(value) {
		return (value / total * 100).toFixed(1);
	}

	return (
		<>
			{items.map((item, idx) =>  {
				const getColorBySplit = item?.dimensions?.[splitSelected];
				const colorElement = getColorBySplit
					? listOfDimensionsSplit.find(c => c.id === getColorBySplit)?.color || listOfDimensionsSplit[0]?.color
					: listOfDimensionsSplit[0]?.color;
				const labelElement = getColorBySplit
					? listOfDimensionsSplit.find(c => c.id === getColorBySplit)?.label || listOfDimensionsSplit[0]?.label
					: '';
				return <div className={styles.stackTooltipItem} key={idx}>
					<div className={styles.legendItem}>
						<div className={[styles.legendItemColor, styles['color_' + colorElement]].join(' ')}></div>
						<div className={styles.legendItemItem}>
							<span className='fw_medium'>{labelElement}:</span>
							{' '}{getPercent(item.value)}&nbsp;%
							{' '}({getFormattedNumber(item.value)})
						</div>
					</div>
				</div>;

			}
			)}
		</>
	);
};

export const StackItem = ({
	total,
	value,
	listOfDimensionsSplit,
	splitSelected,
	item
}:StackItemProps):React.ReactElement => {
	const getColorBySplit = item?.dimensions?.[splitSelected];

	const colorElement = getColorBySplit
		? listOfDimensionsSplit.find(c => c.id === getColorBySplit)?.color || listOfDimensionsSplit[0]?.color
		: listOfDimensionsSplit[0]?.color;

	const height = value / total * 100;


	return (
		<div
			className={[styles.stackItem, styles['color_' + colorElement]].join(' ')}
			style={{height: height + '%'}}
		/>
	);
};

export const Transition = ({
	leftHeight,
	rightHeight,
	percent,
	side
}:TransitionProps):React.ReactElement => {

	const h = Math.max(leftHeight, rightHeight);
	const w = 115;

	const tl = h - leftHeight;
	const tr = h - rightHeight;
	const points = `0,${h} ${w},${h} ${w},${tr} 0,${tl}`;
	const viewBox = `0 0 ${w} ${h}`;

	return (
		<div className={styles.colTransition} style={{height: h}}>
			{percent > 0 &&
				<>
					<div className={side === 'exit' ? styles.transitionValueExit : styles.transitionValueEntrance}>
						<span className={styles.transitionValueText}>{percent.toFixed(1)} %</span>
					</div>
					<svg viewBox={viewBox} className={styles.transitionSVG}>
						<polygon points={points} fill='url(#linearGradient)'></polygon>
						<linearGradient id="linearGradient">
							<stop offset="0" stopColor="#F1F2F4" />
							<stop offset="1" stopColor="#F7F7F8" />
						</linearGradient>
					</svg>
				</>
			}
		</div>
	);
};
