import React from 'react';
import { CategoryType } from '../../context/AiInsightsApiTypesServices';
//import Link from '../../../../../Components/Link';
import {iconFunnel, iconAbandonedCart, iconBarCode, iconTraffic } from '../../../../../Components/CustomIcon/CustomIcon.js';

type Locale = 'en-EN' | 'fr-FR';

import IconText from './IconText';

// CardHeader Component
interface CardHeaderProps {
  category: CategoryType;
}

const CARD_HEADER_CONFIGS = {
	WebsiteTraffic: {
		linkText: {
			'en-EN': 'Website Traffic Dashboard',
			'fr-FR': 'Tableau de bord Trafic',
		},
		title: {
			'en-EN': 'Website Traffic',
			'fr-FR': 'Trafic du Site Web',
		},
		icon: iconTraffic,
		linkUrl: '/Insight/traffic',
	},
	Product: {
		linkText: {
			'en-EN': 'Products Dashboard',
			'fr-FR': 'Tableau de bord Produits',
		},
		title: {
			'en-EN': 'Products',
			'fr-FR': 'Produits',
		},
		icon: iconBarCode,
		linkUrl: '/Insight/ecommerce',
	},
	AbandonedBasket: {
		linkText: {
			'en-EN': 'E-commerce Dashboard',
			'fr-FR': 'Tableau de bord E-commerce',
		},
		title: {
			'en-EN': 'Abandoned Baskets',
			'fr-FR': 'Paniers Abandonnés',
		},
		icon: iconAbandonedCart,
		linkUrl: '/Insight/ecommerce',
	},
	Conversion: {
		linkText: {
			'en-EN': 'E-commerce Dashboard',
			'fr-FR': 'Tableau de bord E-commerce',
		},
		title: {
			'en-EN': 'Conversions',
			'fr-FR': 'Conversions',
		},
		icon: iconFunnel,
		linkUrl: '/Insight/ecommerce',
	},
};

function getLocale(): Locale {
	const localeString = localStorage.getItem('i18nL');
	if (localeString && localeString.startsWith('fr-FR')) {
		return 'fr-FR';
	} else {
		return 'en-EN';
	}
}

const CardHeader: React.FC<CardHeaderProps> = ({ category }) => {
	const locale = getLocale();
	const config = CARD_HEADER_CONFIGS[category];
	if (!config) return null;
	return (
		<div className="flex flex_justify_between mb_30">
			<IconText title={config.title[locale]} icon={config.icon} />
			{/* <Link
				href={config.linkUrl}
				icon="fas fa-arrow-right"
				iconPosition="after"
				className="s_14"
			>
				{config.linkText[locale]}
			</Link> */}
		</div>
	);
};

export default CardHeader;
