import FunnelService from '../../../../../../Services/FunnelServices';
import ImpersonatingServices from '../../../../../../Services/ImpersonatingServices';
import { ObjectFunnelApiPost } from '../../NavigationFunnelTypes';
import SystemServices from '../../../../../../Services/SystemServices';

let i18nL = localStorage.getItem('i18nL') || '';
i18nL = i18nL.replace(/"/g, '');

export function FunnelBridgeCrud(httpAngularJs,$rootScope,$routeParams) {
	const impersonatingServices = new ImpersonatingServices($rootScope,$routeParams );
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;
	const funnelService = new FunnelService(accountId,httpAngularJs);
	const systemServices = new SystemServices($rootScope);

	function postQueryFunnel(data,callbackSuccess, callbackError,signal) {
		if (signal?.aborted) {
			console.warn('Requête annulée');
			return;
		}
		funnelService.postQueryFunnel(
			data,
			(data: ObjectFunnelApiPost[]) => {
				callbackSuccess(data);
			},
			(err: any, dataError: any) => {
				console.warn('error', err);
				console.warn('=>(dataError', dataError);
				systemServices.showError('An Error occured while fetching insights data');

				callbackError(false);

			}
		);
	}

	function getDimensions(startDate,endDate,callbackSuccess, callbackError):void {
		funnelService.getAvailableDimensions(
			i18nL, startDate, endDate,
			(data: any) => {
				callbackSuccess(data);
			},
			(err: any, dataError: any) => {
				console.warn('error', err);
				console.warn('=>(dataError', dataError);
				systemServices.showError('An Error occured while fetching insights dimensions');

				callbackError(false);

			}
		);
	}
	function getMetrics(startDate,endDate,callbackSuccess, callbackError):void {
		funnelService.getAvailableMetrics(
			i18nL, startDate, endDate,
			(data: any) => {
				callbackSuccess(data);
			},
			(err: any, dataError: any) => {
				console.log('error', err);
				console.log('=>(dataError', dataError);
				systemServices.showError('An Error occured while fetching insights dimensions');
				callbackError(false);
			}
		);
	}
	
	return {
		postQueryFunnel,
		getDimensions,
		getMetrics
	};
}



