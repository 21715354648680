import React, {ReactElement} from 'react';
import angular from 'angular';
import { react2angular } from 'react2angular';

import {ListMappingContainer} from './ListMappingContainer';
import ListMappingContext from '../../context/ContextListMapping';
import {useProductInsightsGlobalContext} from '../../context/ContextGlobalInsight';

type Props = {
	$http: any
	$rootScope: any
	$routeParams:any
	AuthServices:any
	$location:any
	$timeout:any
	children: React.ReactNode
}
export const ListMappingContainerWrapper = (props:Props):ReactElement => {

	if(location.pathname !== '/Insight/productlistmapping'){
		return <></>;
	}
	const {
		ComparisonSelected,
		handleSelectComparaisonPeriodHandler,
		onChangeNavFilter,
		initialDates,
		dateStringToTimestamp,
		navFilter,
		setNavFilter,
		isCustomSelected,
		setisCustomSelected,
		customDateRange,
		setCustomDateRange,
		updateStorage,
		selectedValues,
		setSelectedValues,
		accountHasNoDatas,
		setAccountHasNoDatas
	} =useProductInsightsGlobalContext();
	return (
		<ListMappingContext
			$http={props.$http}
			$routeParams={props.$routeParams}
			AuthServices={props.AuthServices}
			$timeout={props.$timeout}
			$location={props.$location}
			$rootScope={props.$rootScope}
			ComparisonSelected={ComparisonSelected}
			handleSelectComparaisonPeriodHandler={handleSelectComparaisonPeriodHandler}
			onChangeNavFilter={onChangeNavFilter}
			initialDates={initialDates}
			dateStringToTimestamp={dateStringToTimestamp}
			navFilter={navFilter}
			setNavFilter={setNavFilter}
			setisCustomSelected={setisCustomSelected}
			isCustomSelected={isCustomSelected}
			customDateRange={customDateRange}
			setCustomDateRange={setCustomDateRange}
			updateStorage={updateStorage}
			selectedValues={selectedValues}
			setSelectedValues={setSelectedValues}
			accountHasNoDatas={accountHasNoDatas}
			setAccountHasNoDatas={setAccountHasNoDatas}
		>
			<ListMappingContainer />
		</ListMappingContext>
	);
};
angular.module('beyableSaasApp.ProductInsightsProductListMapping', [])
	.component(
		'productlistmapping',
		react2angular(
			ListMappingContainerWrapper,
			[],
			['$http', '$rootScope', '$routeParams', '$location', '$timeout', '$scope','AuthServices']
		)
	);