import React, {useEffect, useState} from 'react';
import Nav from '../../../Nav';

import  {navFilterP} from '../../NavigationFunnel/NavigationFunnelTypes';
import {formatDate} from '../config.js';
export default function NavNavigation({context}:any) {
	const {
		setNavFilter,
		setisCustomSelected,
		isCustomSelected,
		customDateRange,
		initiNavDate,
		navFilter,
		$location,
		needToRefreshAngularJs,
		setCustomDateRange,
		setNeedToFetchingMetricsAndDimensions,
		typeEnv
	} = context;
	const [initDate, setInitDate] = useState();

	useEffect(()=>{
		const initialDates = initiNavDate(navFilter);
		if(initialDates){
			setInitDate(initialDates);
			if(initialDates.initialLabel === 'Custom'){
				setisCustomSelected(true);
			}
		}
	},[]);


	function handleClickOriginal(obj:navFilterP) {
		const datesFrom =  formatDate(obj.fromDate);
		const datesTo =  formatDate(obj.toDate);
		$location.search('dates', null); // Supprime la clé 'devices'
		$location.search('dates',`fromDate=${datesFrom},toDate=${datesTo},label=Custom`);
		needToRefreshAngularJs(false);
		setNavFilter(stateInit => ({
			...stateInit,
			fromDate: obj.fromDate,
			toDate: obj.toDate,
			label : 'Custom'
		}
		));
		const funnelStorage = localStorage.getItem(typeEnv);
		if(funnelStorage){
			const newFunnel = JSON.parse(funnelStorage);
			const reset = {...newFunnel, dates: `fromDate=${datesFrom},toDate=${datesTo},label=Custom` };
			localStorage.setItem(typeEnv,JSON.stringify(reset));
		}else{
			localStorage.setItem(typeEnv,JSON.stringify({ dates: `fromDate=${datesFrom},toDate=${datesTo},label=Custom` }));
		}
		setNeedToFetchingMetricsAndDimensions(true);
	}
	function handleChangeCustomDateRange (d){
		setCustomDateRange(d);
		const datesFrom =  formatDate(d.fromDate);
		const datesTo =  formatDate(d.toDate);
		$location.search('dates', null); // Supprime la clé 'devices'
		$location.search('dates',`fromDate=${datesFrom},toDate=${datesTo},label=Custom`);
		// needToRefreshAngularJs(true);
		setNavFilter(stateInit => ({
			...stateInit,
			fromDate: d.fromDate,
			toDate: d.toDate,
			label : 'Custom'
		}
		));
		const funnelStorage = localStorage.getItem(typeEnv);
		if(funnelStorage){
			const newFunnel = JSON.parse(funnelStorage);
			const reset = {...newFunnel, dates: `fromDate=${datesFrom},toDate=${datesTo},label=Custom` };
			localStorage.setItem(typeEnv,JSON.stringify(reset));
		}else{
			localStorage.setItem(typeEnv,JSON.stringify({ dates: `fromDate=${datesFrom},toDate=${datesTo},label=Custom` }));
		}
		// setNeedToFetchingMetricsAndDimensions(true);
	}
	function handleSaveDate(c){
		if(c && c.label !== 'Custom'){
			$location.search('dates', null); // Supprime la clé 'devices'
			$location.search('dates',`fromDate=${c.fromDate},toDate=${c.toDate},label=${c.label}`);
			needToRefreshAngularJs(false);
			setNavFilter(stateInit => ({
				...stateInit,
				fromDate: c.fromDate,
				toDate: c.toDate,
				label:c.label
			}
			));
			const funnelStorage = localStorage.getItem(typeEnv);
			if(funnelStorage){
				const newFunnel = JSON.parse(funnelStorage);
				const reset = {...newFunnel, dates: `fromDate=${c.fromDate},toDate=${c.toDate},label=${c.label}` };
				localStorage.setItem(typeEnv,JSON.stringify(reset));
			}else{
				localStorage.setItem(typeEnv,JSON.stringify({ dates: `fromDate=${c.fromDate},toDate=${c.toDate},label=${c.label}` }));
			}
			setNeedToFetchingMetricsAndDimensions(true);
		}
	}
	return (
		<Nav
			handleClick={handleClickOriginal}
			initialDates={initDate}
			showComparisonToggler={true}
			setComparisonIsEnable={()=>null}
			comparisonSelected={false}
			setisCustomSelected={setisCustomSelected}
			isCustomSelected={isCustomSelected}
			customDateRange={customDateRange}
			setCustomDateRange={handleChangeCustomDateRange}
			handleSaveDate={handleSaveDate}
		/>
	);
}
