import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './Table.module.css';

export default function TableHeadCol({
	className,
	onClick = () => { },
	children,
	style = {},
	refs = {
		setNodeRef: React.createRef(),
		props: {

		},
		attributes: {

		},
		listeners: {

		}
	},
	align = 'left',
	sortable = false,
	sort = null,
	tooltip,
	tooltipHTML,
	upperCase = false,
	fontSize = 'm'
}) {

	const tooltipID = uuidv4();
	const cssClass = ['table_col', styles.col_outer];
	const innerClass = [styles.col_inner];

	if (className) {
		cssClass.push(className);
	}

	if (upperCase) {
		innerClass.push(styles.col_inner_upper_case);
	}

	if (fontSize) {
		innerClass.push(styles['col_inner_font_size_' + fontSize]);
	}

	if (tooltip || tooltipHTML) {
		innerClass.push(styles.col_inner_has_tooltip);
	}

	if (align == 'right') {
		cssClass.push('flex_justify_end al_right');
	} else if (align == 'center') {
		cssClass.push('flex_justify_center al_center');
	}

	const sortArrow = (
		<span className={styles.col_arrow}>
			{sort === 'asc' && (
				<i className="fas fa-arrow-up"></i>
			)}
			{sort === 'desc' && (
				<i className="fas fa-arrow-down"></i>
			)}
		</span>
	);

	return (
		<div
			ref={refs.setNodeRef}
			{...refs.props}
			{...refs.attributes}
			{...refs.listeners}
			className={cssClass.join(' ')}
			style={{ ...style }}
			onClick={sortable ? null : onClick}>
			<div className={innerClass.join(' ')} data-tip={tooltipHTML ? 'a' : tooltip} data-for={tooltipID}>
				{sortable &&
					<a className={styles.col_link} onClick={onClick}>
						{align === 'left' && sortArrow}
						<span className={styles.col_text}>{children}</span>
						{align === 'right' && sortArrow}
					</a>
				}
				<span className={styles.col_text}>{!sortable && children}</span>
			</div>
			{(tooltip || tooltipHTML) &&
				<Tooltip
					text={tooltip}
					html={tooltipHTML}
					id={tooltipID}
					delayShow={0}
				/>
			}
		</div>
	);
}
