import React, { createContext } from 'react';

import SystemServices from '../../../Services/SystemServices';
import SetupAccountServices from '../../../Services/SetupAccountServices';
import ProductTagServices from '../../../Services/ProductTagServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';

export const TriggerContext = createContext();

const TriggerContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;

	const setupAccountServices = new SetupAccountServices(props.$http);
	const productTagServices = new ProductTagServices(props.$http);
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const currentAccount = impersonatedAccount || props.$rootScope.User.Account.Key;

	return (
		<TriggerContext.Provider value={{
			$http,
			$rootScope,
			$routeParams,
			setupAccountServices,
			productTagServices,
			systemServices,
			currentAccount
		}}>
			{props.children}
		</TriggerContext.Provider>
	);
};


export default TriggerContextProvider;