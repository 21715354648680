import React,{useEffect, useState} from 'react';
import { useGeoContext } from '../../context/GeolocContext.js';
import {useAnalyticsContext} from '../../context/AnalyticsContextProvider';
import SelectMetricGeolocation from './SelectMetricGeolocation';
import {GeolocalisationObject, OptionsApiGeoloc,Dimensions}  from './GeolocalisationType';
import moment from 'moment';
import GeolocFilterContainer from './GeolocFilters.js';
import HighchartMapPointGeoloc from './HighchartMapPointGeoloc';
//import styles from '../../WebAnalyticsContainer.module.css';
import styles from '../../components/ChartContainer/ChartContainer.module.css';
// import {dataTest} from './config.js';

type Filter = {
	dimension : string;
	values: [string]
}

export default function GeolocationTabContainer({sessionService}) {
	const {
		navFilter,
		deviceSelected,
		metricGeolocalisationSelected,
		sourceType,
		utmDimensionsSelected
	} = useGeoContext();
	const {selectedTenant} = useAnalyticsContext();
	const [isLoading, setisLoading] = useState(true);
	const [data, setdata] = useState<GeolocalisationObject[] | []>([]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (navFilter.fromDate && navFilter.toDate && metricGeolocalisationSelected) {
				setisLoading(true);
				const filters:Filter[] = [];
				if(deviceSelected && deviceSelected.length > 0){
					filters.push({
						dimension : 'Device',
						values: deviceSelected
					});
				}
				if(sourceType && sourceType.length > 0){
					filters.push({
						dimension : 'TrafficSource',
						values: sourceType
					});
				}
				const utmsFilters = {};
				if(utmDimensionsSelected.medium.length > 0){
					utmsFilters['utmMedium'] = utmDimensionsSelected.medium;
				}
				if(utmDimensionsSelected.campaign.length > 0){
					utmsFilters['utmContent'] = utmDimensionsSelected.content;
				}
				if(utmDimensionsSelected.content.length > 0){
					utmsFilters['utmContent'] = utmDimensionsSelected.content;
				}
				if(utmDimensionsSelected.source.length > 0){
					utmsFilters['utmSource'] = utmDimensionsSelected.source;
				}
				Object.keys(utmsFilters).map(f => {
					const element:[string] = utmsFilters[f].map(x=>x.name);
					filters.push({
						dimension: f,
						values: element,
					});
				});

				const options:OptionsApiGeoloc = { 
					'metrics': [
						metricGeolocalisationSelected.key],
					filters : filters,
					'dimensions': [
						Dimensions.CountryCode,
						Dimensions.subdivision

					],
					
					fromDate : moment(navFilter.fromDate),
					toDate : moment(navFilter.toDate),
				};
				sessionService.getSessionsGeolocalisation(options, (d:GeolocalisationObject[]) => {
					setdata(d);
					setisLoading(false);
				},
				(err)=>{
					console.error(err);
					setisLoading(false);
				});

			}}, 200);
		return () => {
			clearTimeout(timer);
		};
	}, [navFilter,metricGeolocalisationSelected,deviceSelected,sourceType,utmDimensionsSelected,selectedTenant]);

	return (
		<>
			<GeolocFilterContainer sessionService={sessionService} />
			<div className={styles.containerLarge}>
				<SelectMetricGeolocation />
				<HighchartMapPointGeoloc data={data} isLoading={isLoading}/>
			</div>
		</>
	);
}
