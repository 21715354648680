import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import EmailRemarkettingServices from '../../../../Services/EmailRemarkettingServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import { setContext } from 'apollo-link-context';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { getEmailRemarketingCampaignsSimple } from '../../../../Services/Queries/OnSiteDashboardQueries.js';

type Remarketing = {
  isLoading: boolean;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  stopCampaignCRM: (key: string) => void;
  goCampaignCRM: (key: string) => void;
  duplicateCampaign: (campaignToDuplicate: any) => void;
  removeCampaign: (type: string, campaign: any, campaignName: string) => void;
	showConfigurationEmr: (id: string, name: string,bool:boolean) => void;
  idOfTheCampaign: string;
	showPopinConfigurationEmr:boolean;
  campaignName:string;
	handleActionsIsOpen: (bool:boolean)=>void;
	actionsIsOpen:boolean;
  campaigns: any[];
};

const EmailRemarketing = createContext<Remarketing | undefined>(undefined);

export const useEmailRemarketing = () => {
	const contextValue = useContext(EmailRemarketing);
	if (contextValue === undefined) {
		throw new Error('useEmailRemarketing must be used within a EmailRemarketingProvider');
	}
	return contextValue;
};

interface EmailRemarketingProviderProps {
  children: ReactNode;
  $http: any;
  $rootScope: any;
  $routeParams: any;
  AuthServices: any;
  $location: any;
  $timeout: any;
}

export const EmailRemarketingProvider: React.FC<EmailRemarketingProviderProps> = ({
	children,
	$http,
	$rootScope,
	$routeParams,
	AuthServices,
}) => {
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;
	const crud = new EmailRemarkettingServices(accountId, $http);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [value, setValue] = useState<string>('');
	const [campaigns, setCampaigns] = useState<any[]>([]);
	const [showPopinConfigurationEmr, setShowPopinConfigurationEmr] = useState(false);
	const [idOfTheCampaign, setIdOfTheCampaign] = useState('');
	const [campaignName, setCampaignName] = useState('');
	const [actionsIsOpen, setActionsIsOpen] = useState(false);
	const token = AuthServices && AuthServices.getAccessToken();
	const httpLink = createHttpLink({
	// @ts-ignore
		uri: window.BEYABLE_env.URI_GRAPHQL,
	});

	const authLink = setContext((_, { headers }) => {
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : '',
			},
		};
	});

	const defaultOptions = {
		watchQuery: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'ignore',
		},
		query: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'all',
		},
	};

	const client = new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache(),
	// @ts-ignore
		defaultOptions: defaultOptions,
	});

	function fetchElement() {
		client
			.query({
				query: getEmailRemarketingCampaignsSimple,
				variables: {
					accountId: accountId,
				},
			})
			.then((response) => {
				const fetchedCampaigns = response.data.account.emailRemarketingCampaigns.items;
				setCampaigns(fetchedCampaigns);
				setIsLoading(false);
			})
			.catch((e) => {
				console.log('err', e);
				setIsLoading(false);
			});
	}

	useEffect(() => {
		fetchElement();
	}, []);

	const duplicateCampaign = (campaignToDuplicate: any) => {
		crud.DuplicateCampaign(campaignToDuplicate.Key).then((response: any) => {
			if (response.status === 201) {
				const campaign = {
					AssociatedCampaign: response.data,
					TargetedObjectives: '-1',
					TargetedUnique: '0',
					Target1: '-1',
					Actions: '-1',
				};
				$rootScope.data.CampaignAccount.Campaigns.push(campaign);
			} else {
				$rootScope.data.error = response.error;
			}
		});
	};

	const stopCampaignCRM = (key: string) => {
		crud.StopGoCampaignCRM(key, true).then((response: any) => {
			if (response.status === 202) {
				const length = $rootScope.dataCRM.CampaignAccount.Campaigns.length;
				for (let i = 0; i < length; i++) {
					const k = $rootScope.dataCRM.CampaignAccount.Campaigns[i].AssociatedCampaign.Key;
					if (k === key) {
						$rootScope.dataCRM.CampaignAccount.Campaigns[i].AssociatedCampaign.IsStopped = true;
						$rootScope.dataCRM.CampaignAccount.Campaigns[i].Status = 'stopped';
						$rootScope.dataCRM.CampaignAccount.Campaigns[i].AssociatedCampaign.Status = 'stopped';
						break;
					}
				}
			} else {
				$rootScope.data.error = response.error;
			}
		});
	};

	const removeCampaign = (type: string, campaign: any, campaignName: string) => {
		$rootScope.showPopinDeleteCampaign = true;
		$rootScope.campaignTypeToDelete = type;
		$rootScope.campaignToDelete = campaign;
		$rootScope.campaignNameToDelete = campaignName;
	};

	const showConfigurationEmr = (id: string, name: string, bool:boolean) => {
		setShowPopinConfigurationEmr(bool);
		setIdOfTheCampaign(id);
		setCampaignName(name);
	};
	const handleActionsIsOpen = (bool:boolean) => {
		setActionsIsOpen(bool);
	};

	const goCampaignCRM = (key: string) => {
		crud.StopGoCampaignCRM(key, false).then((response: any) => {
			if (response.status === 202) {
				const length = $rootScope.dataCRM.CampaignAccount.Campaigns.length;
				for (let i = 0; i < length; i++) {
					const k = $rootScope.dataCRM.CampaignAccount.Campaigns[i].AssociatedCampaign.Key;
					if (k === key) {
						$rootScope.dataCRM.CampaignAccount.Campaigns[i].AssociatedCampaign.IsStopped = false;
						$rootScope.dataCRM.CampaignAccount.Campaigns[i].Status = crud.GetCampaignState($rootScope.dataCRM.CampaignAccount.Campaigns[i].AssociatedCampaign);
						$rootScope.dataCRM.CampaignAccount.Campaigns[i].AssociatedCampaign.Status = crud.GetCampaignState($rootScope.dataCRM.CampaignAccount.Campaigns[i].AssociatedCampaign);
						break;
					}
				}
			} else {
				$rootScope.dataCRM.error = response.error;
			}
		});
	};

	return (
		<EmailRemarketing.Provider
			value={{
				isLoading,
				value,
				setValue,
				stopCampaignCRM,
				goCampaignCRM,
				duplicateCampaign,
				removeCampaign,
				showConfigurationEmr,
				campaigns,
				showPopinConfigurationEmr,
				idOfTheCampaign,
				campaignName,
				handleActionsIsOpen,
				actionsIsOpen

			}}
		>
			{children}
		</EmailRemarketing.Provider>
	);
};

export default EmailRemarketingProvider;