import './TriggersContainer.css';
import React, { useState, useEffect, Fragment } from 'react';
import { groupBy, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
import PersonaTrigger from './PersonaTrigger';
import TriggerSelector from './TriggerSelector';
import TriggerContextProvider from './TriggerContext';
import useEventBroker from '../../../Hooks/useEventBroker';
import PersonaBoard from './PersonaBoard';
import SavePersonaModal from './SavePersonaModal';
import RenamePersonaModal from './RenamePersonaModal';
import Btn from '../../../Components/Btn';
import PersonaServices from '../../../Services/PersonaServices';
import TriggerUtil from './TriggerUtil';


export default function TriggersContainer({
	saveTriggersCallback,
	saveTrackingModeCallback,
	dataTriggers,
	dataTriggerOperands,
	accountId,
	isNativeApp,
	setUpSpecificationsForLoading,
	setUpSpecificationsForSaving,
	initialTrackingMode,
	initialTriggers,
	userRoleAndPermissions,
	$http,
	$timeout,
	$rootScope,
	$routeParams,
	includeDeviceSelector,
	isCreation
}) {

	const [trackingMode, setTrackingMode] = useState(initialTrackingMode);
	const [personaList, setPersonaList] = useState([]);
	const [selectedPersona, setSelectedPersona] = useState();
	const personaService = new PersonaServices($http);
	const { getNewTriggerFromDataTrigger } = TriggerUtil();
	const { publish, subscribe } = useEventBroker();
	const [renameModalIsOpen, setRenameModalIsOpen] = useState(false);

	const { getDefaultOperator } = TriggerUtil();

	const tooltipID = uuidv4();

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	const getPersonasList = (accountId, callbackSuccess) => personaService.getPersonasList(accountId, isNativeApp ? 'NativeApp' : 'OnSite', callbackSuccess, error => console.log(error));
	const getDefaultTriggers = () => includeDeviceSelector // should be always true now, remove this condition
		? [
			getNewTriggerFromDataTrigger(dataTriggers.find(t => t.value === 'TRGMARKPRESSURE'), dataTriggerOperands),
			getNewTriggerFromDataTrigger(dataTriggers.find(t => t.value === 'TRGCHECKDEVICE'), dataTriggerOperands),
		] : [
			getNewTriggerFromDataTrigger(dataTriggers.find(t => t.value === 'TRGMARKPRESSURE'), dataTriggerOperands),
		];

	const getInitialTriggers = () => {
		if (!initialTriggers.shouldLoadDefault) {
			return initialTriggers.triggers.map(i => {
				let newTrigger = getNewTriggerFromDataTrigger(dataTriggers.find(t => t.value === i.Name), dataTriggerOperands);
				newTrigger.ValueToCompare = i.ValueToCompare;
				newTrigger.Group = i.Group;
				return newTrigger;
			});
		} else {
			return getDefaultTriggers();
		}
	};

	useEffect(() => {
		if (personaList.length > 0) {
			return;
		}
		if (isCreation) {
			if (initialTriggers.isInitialized) {
				const initTriggers = getInitialTriggers();
				const triggersWithGroups = initializeTriggerGroups(initTriggers);
				initializePersonas(triggersWithGroups);
				saveTriggersCallback(triggersWithGroups);
			}
		}
		else if (initialTriggers.isInitialized && initialTriggers.triggers.length > 0) {
			const triggersWithGroups = initializeTriggerGroups(initialTriggers.triggers);
			initializePersonas(triggersWithGroups);
		}
	}, [initialTriggers]);


	useEffect(() => {
		setTrackingMode(initialTrackingMode);
	}, [initialTrackingMode]);

	useEffect(() => {
		if (!selectedPersona && personaList[0]) {
			setSelectedPersona(personaList[0]);
		}
		if (selectedPersona && personaList) {
			const indexPersona = personaList.findIndex(e => e.Id == selectedPersona.Id);
			setSelectedPersona(indexPersona !== -1 ? personaList[indexPersona] : personaList[0]);
		}
	}, [personaList]);


	const initializePersonas = (triggers) => {
		getPersonasList(accountId, e => {
			const existingPersona = e.map(x => ({
				GroupName: x.groupName,
				GroupId: x.groupId,
				Description: x.description,
				Triggers: setUpSpecificationsForLoading(
					(x.triggers.map(t => ({ Name: t.name, OperatorName: t.operatorName, ValueToCompare: t.value }))))
			})
			);
			const existingGroupId = existingPersona.map(x => x.GroupId);
			const groupByTriggerGroupLabel = groupBy(triggers, e => [e.Group.Label, e.Group.Id, e.Group.Index]);
			setPersonaList(Object.keys(groupByTriggerGroupLabel)
				.map(e => {
					const [label, id, index] = e.split(',');
					const persona = existingPersona.find(x => x.GroupId === id);
					return {
						Label: label,
						Id: id,
						Index: parseInt(index),
						Data: groupByTriggerGroupLabel[e],
						IsSavedPersona: existingGroupId.includes(id) ? true : false,
						SavedPersonaInfo: existingGroupId.includes(id) ? { Data: groupByTriggerGroupLabel[e], Id: id } : null,
						Description: persona ? persona.Description : ''
					};
				}).sort((e1, e2) => { return e1.Index - e2.Index; }));
		});
	};


	const saveTriggerList = (triggerList) => {
		if (selectedPersona && selectedPersona.SavedPersonaInfo) { // ≡ if persona was added from a SavedPersona
			const isSameData = compareAllTriggers(triggerList, selectedPersona.SavedPersonaInfo.Data);
			const newPersonaId = uuidv4();
			const selectedIsSavedPersona = containsSavedPersona(personaList.map(p => p.Id), selectedPersona.SavedPersonaInfo.Id);
			if (isSameData) {
				setSelectedPersona({
					...selectedPersona,
					Data: triggerList,
					IsSavedPersona: true,
					Id: selectedIsSavedPersona ? selectedPersona.Id : selectedPersona.SavedPersonaInfo.Id
				});
			} else {
				setSelectedPersona({
					...selectedPersona,
					Data: triggerList,
					IsSavedPersona: false,
					Id: selectedPersona.Id === selectedPersona.SavedPersonaInfo.Id ? newPersonaId : selectedPersona.Id
				});
			}
			let newPersonas;
			if (isSameData) {
				newPersonas = personaList.map(persona => persona.Id === selectedPersona.Id ? {
					...persona,
					Data: triggerList,
					IsSavedPersona: true,
					Id: selectedIsSavedPersona ? selectedPersona.Id : selectedPersona.SavedPersonaInfo.Id
				}
					: persona);
			} else {
				newPersonas = personaList.map(persona => persona.Id === selectedPersona.Id ? {
					...persona,
					Data: triggerList,
					IsSavedPersona: false,
					Id: selectedPersona.Id === selectedPersona.SavedPersonaInfo.Id ? newPersonaId : selectedPersona.Id
				}
					: persona);
			}
			setPersonaList(newPersonas);
			let newTriggers = newPersonas.flatMap(x => x.Data);
			saveTriggersCallback(newTriggers);
		}
		else if (selectedPersona) {
			setSelectedPersona({ ...selectedPersona, Data: triggerList });
			const newPersonas = personaList.map(p => p.Id === selectedPersona.Id ? { ...p, Data: triggerList } : p);
			setPersonaList(newPersonas);
			const newTriggers = newPersonas.flatMap(x => x.Data);
			saveTriggersCallback(newTriggers);
		}

	};

	const containsSavedPersona = (arr, val) => {
		let count = arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
		count > 0 ? count = true : count = false;
		return count;
	};

	const compareAllTriggers = (triggerList, initData) => {
		let temp = false;
		if (triggerList.length === initData.length) {
			temp = 0;
			for (let i = 0; i < triggerList.length; i++) {
				for (let j = 0; j < initData.length; j++) {
					if (isEqual(filterTriggerId(triggerList[i]), filterTriggerId(initData[j]))) {
						temp++;
					}
				}
			}
			temp === triggerList.length ? temp = true : temp = false;
		}
		return temp;
	};

	const filterTriggerId = (trigger) => {
		const filteredTrigger = {
			Categorie: trigger.Categorie,
			DataTriggerOperands: trigger.DataTriggerOperands,
			Label: trigger.Label,
			Name: trigger.Name,
			OperatorName: getDefaultOperator(trigger),
			Separator: trigger.Separator,
			Tooltip: trigger.Tooltip,
			Type: trigger.Type,
			Unit: trigger.Unit,
			ValueToCompare: trigger.ValueToCompare
		};

		return filteredTrigger;
	};

	const initializeTriggerGroups = (triggers) => {
		const idForGroupNull = uuidv4();

		let index = 0;
		const groupIndexes = {};
		const getGroupIndex = (groupId) => {
			if (!groupIndexes.hasOwnProperty(groupId)) {
				groupIndexes[groupId] = index;
				index++;
			}
			return groupIndexes[groupId];
		};

		return triggers
			.map(e => ({ ...e, Id: uuidv4() }))
			.map(e =>
				e.Group == null
					? { ...e, Group: { Id: idForGroupNull, Label: 'Persona 1', Index: getGroupIndex(idForGroupNull), IsSavedPersona: false } }
					: { ...e, Group: { ...e.Group, Index: getGroupIndex(e.Group.Id) } }
			);
	};

	subscribe('evt/triggers/addCustomPersona', e => {
		const persona = e.detail;
		addPersona(persona, false);
	});

	subscribe('evt/triggers/addSavedPersona', e => {
		const persona = e.detail;
		addPersona(persona, true);
	});

	subscribe('evt/triggers/personaSaved', e => {
		const personaSaved = e.detail;
		let newPersonas = personaList.map(p => p.Id === personaSaved.Id
			? { ...personaSaved, IsSavedPersona: true, SavedPersonaInfo: { Data: personaSaved.Data, Id: personaSaved.Id } }
			: p);
		setPersonaList(newPersonas);
	});

	const addPersona = (personaToAdd, isSavedPersona) => {
		let newPersonas = [...personaList];
		const index = Math.max(...newPersonas.map(o => o.Index), 0) + 1;

		let newPersona;
		if (isSavedPersona) {
			const isDeviceTriggerPersona = personaToAdd.data.find(x => x.Name === 'TRGCHECKDEVICE');
			if (!isDeviceTriggerPersona) {
				const defaultDeviceTrigger = getNewTriggerFromDataTrigger(dataTriggers.find(t => t.value === 'TRGCHECKDEVICE'), dataTriggerOperands);
				defaultDeviceTrigger.Group = { Id: personaToAdd.id, Label: personaToAdd.name };
				personaToAdd.data.push(defaultDeviceTrigger);
			}

			const dataPersonaToAdd = personaToAdd.data.map(p => ({
				...p,
				Id: uuidv4(),
				Group: {
					Id: personaToAdd.id,
					Label: p.Group.Label,
					IsSavedPersona: true,
					Index: index
				}
			}));

			newPersona = {
				Label: personaToAdd.name,
				Data: dataPersonaToAdd,
				Id: personaToAdd.id,
				Index: index,
				IsSavedPersona: true,
				SavedPersonaInfo: { Data: dataPersonaToAdd, Id: personaToAdd.id },
				Description: personaToAdd.description
			};
		} else {
			const idNewPersona = uuidv4();
			const dataPersonaToAdd = getDefaultTriggers().map(p => ({
				...p,
				Id: uuidv4(),
				Group: {
					Id: idNewPersona,
					IsSavedPersona: false,
					Index: index,
					Label: personaToAdd.name
				}
			}));
			newPersona = {
				Label: personaToAdd.name,
				Data: dataPersonaToAdd,
				Id: idNewPersona,
				Index: index,
				IsSavedPersona: false
			};
		}
		newPersonas.push(newPersona);
		setPersonaList(newPersonas);
		setSelectedPersona(newPersona);
		const newTriggers = newPersonas.flatMap(x => x.Data);
		saveTriggersCallback(newTriggers);
	};

	const deletePersona = (id) => {
		let newPersonas = [...personaList.filter(p => p.Id !== id)];
		setPersonaList(newPersonas);
		const newTriggers = newPersonas.flatMap(x => x.Data);
		saveTriggersCallback(newTriggers);
	};

	const openPersonaBoard = () => {
		publish('evt/triggers/openPersonaBoardRequested');
	};
	const handleSaveRenamePersona = () => {
		let prevState = { ...selectedPersona };
		const newId = uuidv4();
		prevState.Id = newId;
		prevState.Data.map(trigger => trigger.Group.Id = newId);
		saveTriggerList(prevState.Data);
		setSelectedPersona(prevState);
		updatePersonaList(prevState);
	};
	const handleRenameSelectedPersona = (value) => {
		let prevState = { ...selectedPersona };
		prevState.Label = value;
		prevState.Data.map(trigger => trigger.Group.Label = value);
		saveTriggerList(prevState.Data);
		setSelectedPersona(prevState);
		updatePersonaList(prevState);
	};
	const handleChangeSelectedPersonaDescription = (e) => {
		let prevState = { ...selectedPersona };
		prevState.Description = e;
		setSelectedPersona(prevState);
		updatePersonaList(prevState);
	};

	const updatePersonaList = (updatedPersona) => {
		let prevState = [...personaList];
		const index = prevState.findIndex(e => e.Id === selectedPersona.Id);
		prevState[index] = updatedPersona;
		setPersonaList(prevState);
	};

	const canSwitchToNoTracking = userRoleAndPermissions
		&& (userRoleAndPermissions.isAdmin || userRoleAndPermissions.permissions.CAMPAIGNS_CAN_SWITCH_NOTRACKING_MODE);

	const getTriggersToSelect = () => {
		const hasExitIntent = selectedPersona && selectedPersona.Data.find(x => x.Name === 'TRGEXITINTENT' && x.ValueToCompare === true);
		const triggersToSelect = dataTriggers.filter(x => x.value !== 'TRGEXITONFOCUS' && (!hasExitIntent || x.value !== 'TRGEXITCOMEBACK'));
		return triggersToSelect;
	};

	const updateTrackingMode = (mode) => {
		setTrackingMode(mode);
		saveTrackingModeCallback(mode);
	};

	return (
		<TriggerContextProvider
			$http={$http}
			$timeout={$timeout}
			$rootScope={$rootScope}
			$routeParams={$routeParams}
		>

			<div className="trigger_outer">
				{canSwitchToNoTracking &&
					<div className="trigger_header">
						<section className="trigger-wrapper">
							<div className="mb_15">
								<div className="mb_5 fw_medium">Tracking mode</div>
								<div className="btn_switch">
									<Btn
										style="outline"
										color="secondary"
										message="Functional"
										onClick={() => updateTrackingMode(1)}
										className={trackingMode == 1 ? 'active' : ''}
									/>
									<Btn
										style="outline"
										color="secondary"
										message="Marketing"
										onClick={() => updateTrackingMode(2)}
										className={trackingMode == 2 ? 'active' : ''}
									/>
								</div>
							</div>
						</section>
					</div>
				}
				<div className="trigger_body">
					<div className="trigger_side">
						{personaList && personaList.length > 1 &&
							<ul className="trigger_persona_menu">
								{personaList.map(e =>
									<li key={e.Id}>
										<a className={(selectedPersona && selectedPersona.Id == e.Id) ? 'trigger_persona_menu_item active ellips' : 'trigger_persona_menu_item ellips'}
											onClick={() => setSelectedPersona(e)}>
											<i className="fas fa-user-circle"></i>
											{e.Label}
										</a>
									</li>
								)}
							</ul>
						}
						<div className="trigger_persona_empty">
							<div className="empty_state">
								<div className="empty_state_title">Multiple personas</div>
								<div className="empty_state_text">
									Do you want to target one persona <strong>OR</strong> more?
								</div>
								<div className="empty_state_text">
									Do you want to use a favorite persona?
								</div>
								<div className="empty_state_btn">
									<Btn
										onClickFunction={() => openPersonaBoard()}
										message="Add Persona"
										size="1"
										color="primary"
										style="outline"
										icon="fas fa-plus"
									/>
								</div>
							</div>
						</div>

					</div>
					<div className="trigger_middle">
						{selectedPersona &&
							<Fragment>
								<ReactTooltip
									backgroundColor="black"
									effect="solid"
									place="bottom"
									globalEventOff="click"
									delayShow={600}
								/>
								<section className="trigger_persona_header flex">
									{selectedPersona.IsSavedPersona &&
										<div className="persona_item_icon flex_item_fix mr_10"
											data-tip={'Favorite persona'}>
											<i className="fas fa-star"></i>
										</div>
									}
									<div className="trigger_persona_info flex_item_full">
										<div className="trigger_persona_title">{selectedPersona.Label}</div>
										{selectedPersona.Description &&
											<div className="trigger_persona_desc">
												{selectedPersona.Description}
											</div>
										}
									</div>
									<div className="flex_item_fix ml_20">
										<a className="icon_btn s"
											onClick={(e) => { setRenameModalIsOpen(true); }}
											data-tip={'Rename persona'}>
											<i className="fas fa-pen"></i>
										</a>
										<SavePersonaModal
											isNativeApp={isNativeApp}
											setupTriggersForSaving={setUpSpecificationsForSaving}
											selectedPersona={selectedPersona}
											handleChangeSelectedPersonaLabel={(e) => handleRenameSelectedPersona(e)}
											handleChangeSelectedPersonaDescription={(e) => handleChangeSelectedPersonaDescription(e)}
										/>
										{personaList && personaList.length > 1 &&
											<a className="icon_btn s"
												onClick={() => deletePersona(selectedPersona.Id)}
												data-tip={'Remove persona from this campaign'}>
												<i className="fas fa-trash-alt"></i>
											</a>
										}
									</div>
								</section>
								<PersonaTrigger
									personaTriggers={selectedPersona ? selectedPersona.Data : []}
									dataTriggers={dataTriggers}
									dataTriggerOperands={dataTriggerOperands}
									personaInfo={selectedPersona ? {
										Id: selectedPersona.Id,
										Label: selectedPersona.Label,
										Index: selectedPersona.Index,
										IsSavedPersona: selectedPersona.IsSavedPersona
									} : ''}
									saveTriggerListCallback={saveTriggerList}
									includeDeviceSelector={includeDeviceSelector}
								/>
								<TriggerSelector
									dataTriggers={getTriggersToSelect()}
									trackingModeSelector={trackingMode}
								/>
								<RenamePersonaModal
									modalIsOpen={renameModalIsOpen}
									setModalIsOpen={(e) => setRenameModalIsOpen(e)}
									label={selectedPersona.Label}
									handleRenameSelectedPersona={(e) => handleRenameSelectedPersona(e)}
									handleSaveRenamePersona={handleSaveRenamePersona}
								/>
							</Fragment>
						}
					</div>
				</div>
			</div>

			<PersonaBoard
				setupTriggersForLoading={setUpSpecificationsForLoading}
				campaignPersonas={personaList ? personaList : []}
				isNativeApp={isNativeApp}
			/>

		</TriggerContextProvider>
	);
}