import React, { useState } from 'react';
import { ProcessModel, ProcessType } from '../context/typesWorkflow';
import Panel from '../../../../Components/Panel';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalBody from '../../../../Components/ModalBody';
import ModalFooter from '../../../../Components/ModalFooter';
import { List } from '../../../../Components/List';
import RequestItemSelection from './RequestSelection';
import Btn from '../../../../Components/Btn';
import SectionMessage from '../../../../Components/SectionMessage';

type PanelProcessesByIdentifiersType = {
	handleOpenPanel: any,
	isOpen: boolean,
	processByIdentifiers: [ProcessType | null, ProcessModel[]],
	handleRefreshByItemsSelected: any
};

export default function PanelProcessesByIdentifiers({
	handleOpenPanel,
	isOpen,
	processByIdentifiers,
	handleRefreshByItemsSelected }: PanelProcessesByIdentifiersType) {
	const [selectedProcessByIdentifiers, setSelectedProcessByIdentifiers] = useState<any>([]);

	const handleSelectionChange = (requestId) => {
		setSelectedProcessByIdentifiers((prevSelected:any) =>
			prevSelected.includes(requestId)
				? prevSelected.filter((id:any) => id !== requestId)
				: [...prevSelected, requestId]
		);
	};

	return (
		<Panel width="560" isOpen={isOpen} onClose={() => handleOpenPanel(false)} noClose={true}>
			<ModalHeader>
				<div className='flex'>
					<div className='flex_item_fix'>
						<a className='panel_close panel_close_left' onClick={() => handleOpenPanel(false)}></a>
					</div>
					<div className='flex_item_full'>Processes by identifier</div>
				</div>
			</ModalHeader>
			<ModalBody color="grey">
				<SectionMessage
					appearance="background"
					type="information"
					title={'Launch'}
					fontSize="l"
					iconSize="xl"
					text={
						<p>
							Please select the process you want to launch
							<br/>
							by checking the boxes next to them.
						</p>
					}
				/>
				<List removeVerticalMargin={true}>
					{processByIdentifiers[1].map((r, id) => (
						<RequestItemSelection
							states={r}
							key={id}
							isSelected={selectedProcessByIdentifiers.includes(r.processIdentifier)}
							onSelectionChange={() => handleSelectionChange(r.processIdentifier)}
						/>
					))}
				</List>
			</ModalBody>
			<ModalFooter>
				<Btn
					disabled={selectedProcessByIdentifiers.length === 0}
					message={`Launch ${selectedProcessByIdentifiers.length === 1 ? 'process' : 'processes'}`}
					onClick={() => handleRefreshByItemsSelected(processByIdentifiers[0], selectedProcessByIdentifiers)}
				/>
			</ModalFooter>
		</Panel>
	);
}