import { crudEmerchInsights } from './CrudEmerchInsights';
import {dateStringToTimestamp} from '../../utils';
type CategoryType = {
	Name: string;
	Type:string;
	Value: string;
};

export const useFetchData = ($http, $rootScope, $routeParams, AuthServices,navFilter,setAccountHasNoDatas) => {

	const { postQueryKpiExportInsightsServices,
		loadInsightsProductsForCategoriesEmerch,
		loadInsightsProductsForCategoriesEmerchComparison,
		loadInsightsProductsSimple } = crudEmerchInsights($http, $rootScope, $routeParams, AuthServices,setAccountHasNoDatas);

	const fetchExportKpis = (optionsObject, signal) => {
		postQueryKpiExportInsightsServices(optionsObject, [], _data => {
		}, err => {
			console.error('Error fetching data:', err);
		}, signal);
	};

	const fetchCatergory = (optionsObject, signal, setFilterHierarchies) => {
		loadInsightsProductsSimple(optionsObject, d => {
			if (signal.aborted) return;
			const mainHierarchy:string[] = [];
			d.Categories.forEach((category: CategoryType) => {
				if (!mainHierarchy.includes(category.Type) && category.Type !== 'root') {
					mainHierarchy.push(category.Type);
				}
			});

			const breadcrumb = mainHierarchy.map(type => ({
				name: type,
				categories: d.Categories
					.filter(category => category.Type === type)
					.map(category => ({
						name: category.Name,
						value: category.Value
					}))
			}));

			setFilterHierarchies(breadcrumb);
		}, err => {
			if (!signal.aborted) {
				console.error('Error fetching data:', err);
			}
		}, signal);
	};

	const fetchData = (optionsObject, filters, setIsLoading, setTagStudiedEl, process, signal) => {
		const storedData = sessionStorage.getItem('globalProductInsightsComparedPeriode');
		const parsedData = storedData ? JSON.parse(storedData) : null;

		if (!parsedData) {
			loadInsightsProductsForCategoriesEmerch(optionsObject, filters, data => {
				if (signal.aborted) return;
				process(data);
			}, err => {
				if (!signal.aborted) {
					console.error('Error fetching data:', err);
					setIsLoading(false);
					setTagStudiedEl(null);
				}
			}, signal);
		} else {
			const data3 = dateStringToTimestamp(navFilter.fromDateToCompare);
			const data4 = dateStringToTimestamp(navFilter.toDateToCompare);
			optionsObject['startDate2'] = data3;
			optionsObject['endDate2'] = data4;
			loadInsightsProductsForCategoriesEmerchComparison(optionsObject, filters, data => {
				if (signal.aborted) return;
				process(data);
			}, err => {
				if (!signal.aborted) {
					console.error('Error fetching data:', err);
					setIsLoading(false);
					setTagStudiedEl(null);
				}
			}, signal);
		}
	};

	return { fetchExportKpis, fetchCatergory, fetchData };
};
