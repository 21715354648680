import React, {useState,useEffect} from 'react';
import Btn from '../../../../../../Components/Btn.js';
import Modal from '../../../../../../Components/Modal.js';
import ModalHeader from '../../../../../../Components/ModalHeader.js';
import ModalBody from '../../../../../../Components/ModalBody.js';
import ModalFooter from '../../../../../../Components/ModalFooter.js';
import { Checkbox, CheckboxList } from '../../../../../../Components/Checkbox/Checkbox.js';

const MoreFilter = ({context}:any) => {
	const {
		optionalFiltersSelected,
		rawDimensions,
		handleSelectOrUnSelectFilter,
		filterToExclude
	} = context;

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [listOptionnalSelectedInModal, setListOptionnalSelectedInModal] = useState<string[]>([]);
	function handleSelectOrUnSelectFilterLocal(element:string){
		const optionlaListHasElement = listOptionnalSelectedInModal.find(c => c === element);
		if(optionlaListHasElement){
			const newOptionalFilters = listOptionnalSelectedInModal.filter(x => x !== element);
			setListOptionnalSelectedInModal(newOptionalFilters);
		}else{
			setListOptionnalSelectedInModal([...listOptionnalSelectedInModal,element]);
		}
	}
	const dimensionsFilterList = rawDimensions && Object.keys(rawDimensions)
		.filter(dimension => rawDimensions[dimension]?.values?.length > 1) 
		.map(dimension => ({
			value: rawDimensions[dimension].id,
			label: rawDimensions[dimension].label
		}));

	function validFilters(){
		handleSelectOrUnSelectFilter(listOptionnalSelectedInModal);
		setModalIsOpen(false);
	}
	useEffect(() => {
		setListOptionnalSelectedInModal(optionalFiltersSelected);
	}, [optionalFiltersSelected]);

	if(!rawDimensions){
		return <></>;
	}
	return (
		<>
			<Btn
				message={'More filters'}
				icon="fas fa-plus fa-xs"
				size="s"
				style="link"
				onClick={() => setModalIsOpen(true)}
			/>
			<Modal
				isOpen={modalIsOpen}
				width={440}
				onClose={() => setModalIsOpen(false)}
			>
				<ModalHeader title='More filters' />
				<ModalBody>
					{listOptionnalSelectedInModal &&
						<CheckboxList hasBorder={false} size="xs">
							{dimensionsFilterList &&
								dimensionsFilterList.filter(x => !filterToExclude.includes(x.value)).map(filter => {
									return <Checkbox
										key={filter.value}
										forcedChecked={listOptionnalSelectedInModal.includes(filter.value)}
										onChange={() => handleSelectOrUnSelectFilterLocal(filter.value)}
										label={filter.label}
									/>;
								}

								)
							}
						</CheckboxList>
					}
				</ModalBody>
				<ModalFooter
					primaryAction={
						<Btn
							message={'Confirm'}
							onClick={() => validFilters()}
						/>
					}
					secondaryAction={
						<Btn
							message={'Cancel'}
							style="ghost"
							color="secondary"
							onClick={() => setModalIsOpen(false)}
						/>
					}
				/>
			</Modal>
		</>
	);
};


export default MoreFilter;