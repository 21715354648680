interface Tag {
	Type: string;
	Name: string;
	Value: string;
}

interface Value {
	NumberOfSessions: number;
	Amount: number;
	NumberOfCartAdded: number;
	NumberOfConversions: number;
	NumberOfAbandonments: number;
	Tag: Tag;
}

interface TagStudied {
	x: number;
	y: number;
	z: number;
	visits: number;
	addToCart: number;
	conversions: number;
	abandonments: number;
	conversionsRate: number;
	abandonmentsRate: number;
	tagType: string;
	tagName: string;
	tagValue: string;
	amount?: number;
}

interface TagCompared extends TagStudied {
	visitsComparison: number;
	addToCartComparison: number;
	conversionsComparison: number;
	abandonmentsComparison: number;
	conversionsRateComparison: number;
	abandonmentsRateComparison: number;
	amount:number;
	amountComparison:number;
}

interface Data {
	Tags: Value[];
	Hierarchies: { Type: string }[];
}

interface ProcessDataParams {
	data: Data;
	ComparisonSelected?: boolean;
	setTagStudiedEl: (tags: any[]) => void;
	setVisitsAverage: (average: number) => void;
	setAddtocartRateAverage: (average: number) => void;
	setConversionRateAverage: (average: number) => void;
	setVisitsAverageComparison: (average: number) => void;
	setAddtocartRateAverageComparison: (average: number) => void;
	setConversionRateAverageComparison: (average: number) => void;
	setIsLoading: (isLoading: boolean) => void;
	setCategories: (categories: { value: string; label: string }[]) => void;
	emercheOrdinate: string;
}

export const processData = ({
	data,
	ComparisonSelected,
	setTagStudiedEl,
	setVisitsAverage,
	setAddtocartRateAverage,
	setConversionRateAverage,
	setVisitsAverageComparison,
	setAddtocartRateAverageComparison,
	setConversionRateAverageComparison,
	setIsLoading,
	setCategories,
	emercheOrdinate,
}: ProcessDataParams) => {
	if (!data || !data.Tags) {
		console.error('Invalid data received:', data);
		setIsLoading(false);
		return;
	}

	const tagsStudied: { data: TagStudied[], marker: { fillColor: string, lineWidth: number } } = { data: [], marker: { fillColor: '#006fff', lineWidth: 0 } };
	const tagsCompared: { data: TagCompared[], marker: { symbol: string } } = { data: [], marker: { symbol: 'url(Assets/stripe.png)' } };
	let visitsSum = 0;
	let addtocartRateSum = 0;
	let conversionRateSum = 0;
	let visitsSumComparison = 0;
	let addtocartRateSumComparison = 0;
	let conversionRateSumComparison = 0;

	if (!ComparisonSelected) {
		data.Tags.forEach((value: Value) => {
			const tagStudied: TagStudied = {
				x: value.NumberOfSessions,
				y: 0,
				z: value.Amount,
				amount: +value.Amount.toFixed(2) || 0,
				visits: value.NumberOfSessions,
				addToCart: value.NumberOfCartAdded,
				conversions: value.NumberOfConversions,
				abandonments: value.NumberOfAbandonments,
				conversionsRate: parseFloat(((value.NumberOfConversions / value.NumberOfSessions) * 100).toFixed(2)),
				abandonmentsRate: parseFloat(((value.NumberOfAbandonments / value.NumberOfSessions) * 100).toFixed(2)),
				tagType: value.Tag.Type,
				tagName: value.Tag.Name,
				tagValue: value.Tag.Value,
			};

			if (emercheOrdinate === 'addToCartRate') {
				tagStudied.y = parseFloat(((value.NumberOfCartAdded / value.NumberOfSessions) * 100).toFixed(2));
			} else if (emercheOrdinate === 'conversions') {
				tagStudied.y = value.NumberOfConversions;
			} else if (emercheOrdinate === 'conversionRate') {
				tagStudied.y = parseFloat(((value.NumberOfConversions / value.NumberOfSessions) * 100).toFixed(2));
			}

			visitsSum += tagStudied.x;

			if (value.NumberOfCartAdded && value.NumberOfSessions) {
				addtocartRateSum += value.NumberOfCartAdded / value.NumberOfSessions;
			}
			if (value.NumberOfConversions && value.NumberOfSessions) {
				conversionRateSum += value.NumberOfConversions / value.NumberOfSessions;
			}

			tagsStudied.data.push(tagStudied);
		});
	}

	if (ComparisonSelected) {
		data.Tags.forEach((value: any) => {
			const tagStudied: TagCompared = {
				x: value.NumberOfSessions.ValueReference || 0,
				y: 0,
				z: value.Amount.ValueReference || 0,
				amount: value.Amount.ValueReference || 0,
				amountComparison: value.Amount.ValueComparison || 0,
				visits: value.NumberOfSessions.ValueReference || 0,
				addToCart: value.NumberOfCartAdded.ValueReference || 0,
				conversions: value.NumberOfConversions.ValueReference || 0,
				abandonments: value.NumberOfAbandonments.ValueReference || 0,
				conversionsRate: (value.NumberOfConversions.ValueReference / (value.NumberOfSessions.ValueReference || 1)) * 100,
				abandonmentsRate: (value.NumberOfAbandonments.ValueReference / (value.NumberOfSessions.ValueReference || 1)) * 100,
				visitsComparison: value.NumberOfSessions.ValueComparison || 0,
				addToCartComparison: value.NumberOfCartAdded.ValueComparison || 0,
				conversionsComparison: value.NumberOfConversions.ValueComparison || 0,
				abandonmentsComparison: value.NumberOfAbandonments.ValueComparison || 0,
				conversionsRateComparison: (value.NumberOfConversions.ValueComparison / (value.NumberOfSessions.ValueComparison || 1)) * 100,
				abandonmentsRateComparison: (value.NumberOfAbandonments.ValueComparison / (value.NumberOfSessions.ValueComparison || 1)) * 100,
				tagType: value.Tag.Type,
				tagName: value.Tag.Name,
				tagValue: value.Tag.Value,
			};

			if (emercheOrdinate === 'addToCartRate') {
				tagStudied.y = parseFloat(((value.NumberOfCartAdded.ValueReference / (value.NumberOfSessions.ValueReference || 1)) * 100).toFixed(2));
			} else if (emercheOrdinate === 'conversions') {
				tagStudied.y = value.NumberOfConversions.ValueReference || 0;
			} else if (emercheOrdinate === 'conversionRate') {
				tagStudied.y = parseFloat(((value.NumberOfConversions.ValueReference / (value.NumberOfSessions.ValueReference || 1)) * 100).toFixed(2));
			}

			visitsSum += tagStudied.x;

			if (value.NumberOfCartAdded.ValueReference && value.NumberOfSessions.ValueReference) {
				addtocartRateSum += value.NumberOfCartAdded.ValueReference / value.NumberOfSessions.ValueReference;
			}
			if (value.NumberOfConversions.ValueReference && value.NumberOfSessions.ValueReference) {
				conversionRateSum += value.NumberOfConversions.ValueReference / value.NumberOfSessions.ValueReference;
			}

			tagsStudied.data.push(tagStudied);

			const tagCompared: TagCompared = {
				x: value.NumberOfSessions.ValueComparison || 0,
				y: 0,
				z: value.Amount.ValueComparison || 0,
				amount: value.Amount.ValueReference || 0,
				amountComparison: value.Amount.ValueComparison || 0,
				visits: value.NumberOfSessions.ValueReference || 0,
				addToCart: value.NumberOfCartAdded.ValueReference || 0,
				conversions: value.NumberOfConversions.ValueReference || 0,
				abandonments: value.NumberOfAbandonments.ValueReference || 0,
				conversionsRate: (value.NumberOfConversions.ValueReference / (value.NumberOfSessions.ValueReference || 1)) * 100,
				abandonmentsRate: (value.NumberOfAbandonments.ValueReference / (value.NumberOfSessions.ValueReference || 1)) * 100,
				visitsComparison: value.NumberOfSessions.ValueComparison || 0,
				addToCartComparison: value.NumberOfCartAdded.ValueComparison || 0,
				conversionsComparison: value.NumberOfConversions.ValueComparison || 0,
				abandonmentsComparison: value.NumberOfAbandonments.ValueComparison || 0,
				conversionsRateComparison: (value.NumberOfConversions.ValueComparison / (value.NumberOfSessions.ValueComparison || 1)) * 100,
				abandonmentsRateComparison: (value.NumberOfAbandonments.ValueComparison / (value.NumberOfSessions.ValueComparison || 1)) * 100,
				tagType: value.Tag.Type,
				tagName: value.Tag.Name,
				tagValue: value.Tag.Value,
			};

			if (emercheOrdinate === 'addToCartRate') {
				tagCompared.y = parseFloat(((value.NumberOfCartAdded.ValueComparison / (value.NumberOfSessions.ValueComparison || 1)) * 100).toFixed(2));
			} else if (emercheOrdinate === 'conversions') {
				tagCompared.y = value.NumberOfConversions.ValueComparison || 0;
			} else if (emercheOrdinate === 'conversionRate') {
				tagCompared.y = parseFloat(((value.NumberOfConversions.ValueComparison / (value.NumberOfSessions.ValueComparison || 1)) * 100).toFixed(2));
			}

			visitsSumComparison += tagCompared.x;

			if (value.NumberOfCartAdded.ValueComparison && value.NumberOfSessions.ValueComparison) {
				addtocartRateSumComparison += value.NumberOfCartAdded.ValueComparison / value.NumberOfSessions.ValueComparison;
			}
			if (value.NumberOfConversions.ValueComparison && value.NumberOfSessions.ValueComparison) {
				conversionRateSumComparison += value.NumberOfConversions.ValueComparison / value.NumberOfSessions.ValueComparison;
			}
			
			tagsCompared.data.push(tagCompared);
		});
	}

	const categoryData = data.Hierarchies.map(categorie => ({
		value: categorie.Type,
		label: categorie.Type,
	}));
	setCategories(categoryData);

	const visitAverage = visitsSum / data.Tags.length;
	const visitAverageComparison = visitsSumComparison / data.Tags.length;

	const addtocartRateAverage = addtocartRateSum / data.Tags.length * 100;
	const addtocartRateAverageComparison = addtocartRateSumComparison / data.Tags.length * 100;

	const conversionRateAverage = conversionRateSum / data.Tags.length * 100;
	const conversionRateAverageComparison = conversionRateSumComparison / data.Tags.length * 100;

	setTagStudiedEl(ComparisonSelected ? [tagsStudied, tagsCompared] : [tagsStudied]);
	setVisitsAverage(visitAverage);
	setAddtocartRateAverage(addtocartRateAverage);
	setConversionRateAverage(conversionRateAverage);
	setVisitsAverageComparison(visitAverageComparison);
	setAddtocartRateAverageComparison(addtocartRateAverageComparison);
	setConversionRateAverageComparison(conversionRateAverageComparison);
	setIsLoading(false);
};
