
import React from 'react';
import moment from 'moment';
import { CSSProperties } from 'react';
import { ProcessRequestModel,RequestState} from '../context/typesWorkflow';

const requestStyle: CSSProperties = {
	marginBottom: '8px',
	padding: '4px',
	borderRadius: '4px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
};
interface RequestItemProps {
	states: ProcessRequestModel | RequestState;
	onRefresh?: () => void;
	processType?:string;
}

const RequestItem: React.FC<RequestItemProps> = ({ states }) => {
	return (
		<div style={requestStyle}>
			<div>
				<strong>Status:</strong> {states.requestStatus}
			</div>
			<div>
				<strong>Date:</strong> {moment(states.requestDateUtc).format('dddd, MMMM Do YYYY')}
			</div>
			<div>
				<strong>Originator:</strong> {states.originatorUserEmail}
			</div>
		</div>
	);
};

export default RequestItem;