import moment from 'moment';


const initType = [
	{ key: 'Page', label: 'Page' }, 
	{ key: 'PageType', label: 'Page Type' }, 
	{ key: 'Tag1', label: 'Custom Dimension 1' }, 
	{ key: 'Tag2', label: 'Custom Dimension 2' }, 
	{ key: 'Tag3', label: 'Custom Dimension 3' }, 
	{ key: 'Tag4', label: 'Custom Dimension 4' }, 
	{ key: 'Tag5', label: 'Custom Dimension 5' }];
    
export const metricsTypes = [
	{
		value: 'none',
		translation: {
			key: 'common.none',
			vars: {
				ns: 'utils'
			}
		}
	},
	{
		value: 'Device',
		translation: {
			key: 'data.device',
			vars: {
				ns: 'kpi'
			}
		}
	},
	{
		value: 'EngagementLevel',
		translation: {
			key: 'data.engagementLevel',
			vars: {
				ns: 'kpi'
			}
		}
	},
	{
		value: 'PageType',
		translation: {
			key: 'data.pageType',
			vars: {
				ns: 'kpi'
			}
		}
	}
];

export const sourceTypes = [
	// 'All',
	'Direct',
	'Organic',
	'Referral',
	'Social',
	'Paid',
	'Email',
	'Display',
	'Other',
];
export default initType;

export const initialUtmFilter = {
	source: {
		name: '',
		label: ''
	},
	medium: {
		name: '',
		label: ''
	},
	campaign: {
		name: '',
		label: ''
	},
	content: {
		name: '',
		label: ''
	}
};
export const initialUtmFilterV2 = {
	source: '',
	medium: '',
	campaign: '',
	content: ''
};
export const cardFetchOptions = {
	'fromDate': '',
	'toDate': '',
	'metrics': [
		'NumberOfSessions'
	],
	'timeGranularity': 'None',
	'dimensions': [],
	'limit': 15,
	'offset': 0,
	'filters': [],
	'search': null
};
export const search = (fnSetter,table, word,selectList) => {
	if (word.length > 0) {
		fnSetter([]);
		let resultName = table.filter(
			line =>
				line.toUpperCase().match(`.*${word.toUpperCase()}.*`)
		);
		const res = resultName.map(i => {
			return {
				value: i,
				label: i
			};
		});
		fnSetter(res);
	}
	else {
		fnSetter(selectList);
	}
};
export const devices = [
// 	{
// 	value : 'all',
// 	label : 'All'
// },
	{
		value : 'Desktop',
		label : 'Desktop'
	},
	{
		value : 'Mobile',
		label : 'Mobile'
	},
	{
		value : 'Tablet',
		label : 'Tablet'
	},
];
export const pageTypes = [
	{
		value : 'H',
		label : 'Home'
	},
	{
		value : 'C',
		label : 'Category page'
	},
	{
		value : 'FP',
		label : 'Product page'
	},
	{
		value : 'B',
		label : 'Basket'
	},
	{
		value : 'T',
		label : 'Transaction'
	},
	{
		value : 'G',
		label : 'Generic'
	},
	{
		value : 'O',
		label : 'Other'
	},
	{
		value : '',
		translation: {
			key: 'common.undefined',
			vars: {
				ns: 'utils'
			}
		}
	},
];
export const engagementLevel = [
	{
		value : 'X',
		label : 'Lost'
	},
	{
		value : 'C',
		label : 'Consideration'
	},
	{
		value : 'D',
		label : 'Discover'
	},
	{
		value : 'I',
		label : 'Intention'
	},
	{
		value : 'A',
		label : 'Purchase'
	},
	{
		value : 'E',
		label : 'Experience'
	},
	{
		value : 'R',
		label : 'Recurring purchase'
	},
	{
		value : '',
		translation: {
			key: 'common.undefined',
			vars: {
				ns: 'utils'
			}
		}
	},
];
export const FORMAT = 'YYYY/MM/DD';
export const today = moment();
export const monday = moment().startOf('isoWeek').format(FORMAT);
export const formatToday = moment().format(FORMAT);
export function formatDate(date){
	return moment(date).format('YYYY/MM/DD');
}