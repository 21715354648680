import moment from 'moment/moment';

export const SESSION_STORAGE_KEY = 'filterSelectionsProductInsights';
export const SESSION_STORAGE_KEY_NAV = 'globalProductInsights';
export const SESSION_STORAGE_KEY_COMPARISON = 'globalProductInsightsComparedPeriode';
export type Chip = {
	type: string;
	title: string;
	handleDelete: ()=>void ;

}
export interface Destination {
	type: string;
	name: string;
	value: string;
}

export interface DataStructure {
	key: string;
	value: Destination[];
}
export function transformToDataStructureArray(data: { [key: string]: Destination[] }): DataStructure[] {
	const resultArray: DataStructure[] = [];

	for (const key in data) {
		if (Array.isArray(data[key])) {
			resultArray.push({
				key: key,
				value: data[key]
			});
		}
	}

	return resultArray;
}
export const getInitialSelectedValues = () => {
	const storedValues = sessionStorage.getItem(SESSION_STORAGE_KEY);
	if(storedValues !== null){
		return  JSON.parse(storedValues);
	}else return {} ;
};
export function dateStringToTimestamp(dateString: any): number {
	if (typeof dateString === 'string') {
		const [year, month, day] = dateString.split('/').map(Number);
		const date = new Date(year, month - 1, day);
		return date.getTime();
	} else {
		// Utiliser moment.js pour formater la date
		const formattedDate = moment(dateString).format('YYYY/MM/DD');
		const [year, month, day] = formattedDate.split('/').map(Number);
		const date = new Date(year, month - 1, day);
		return date.getTime();
	}
}
export type SelectElem = {
	label: string;
	value : string;
}


export 	function getDayInRespectOfOtherDateRange(initialFrom,initialTo) {
	const oldStartDate = moment(initialFrom);
	const oldEndDate = moment(initialTo);
	const diffBetweenToDateToCompare = oldEndDate.diff(oldStartDate, 'days');
	const newStartDate = moment(oldEndDate).subtract(diffBetweenToDateToCompare, 'days').format('YYYY/MM/DD');
	const newEndDate = moment(newStartDate).add(diffBetweenToDateToCompare, 'days').format('YYYY/MM/DD');
	return { newStartDate, newEndDate };
}
export 	function getDayInRespectOfOtherDateRangeForComparedPeriod(initialFrom,initialTo) {
	const oldStartDate = moment(initialFrom);
	const oldEndDate = moment(initialTo);
	const diffBetweenToDateToCompare = oldEndDate.diff(oldStartDate, 'days');
	const newStartDate = moment(oldStartDate).subtract(diffBetweenToDateToCompare, 'days').format('YYYY/MM/DD');
	const newEndDate = moment(newStartDate).add(diffBetweenToDateToCompare, 'days').format('YYYY/MM/DD');
	return { newStartDate, newEndDate };
}
const FORMAT = 'YYYY/MM/DD';

export const dateToFormat = (date) =>{

	date = moment(date);
	const six_months_ago = moment().subtract(6, 'months');
	const in_six_months = moment().add(6, 'months');
	let format = 'D MMM';
	if (date.isBefore(six_months_ago)) {
		format = 'D MMM YYYY';
	}
	else if (date.isAfter(in_six_months)) {
		format = 'D MMM YYYY';
	}

	return moment(date).startOf('day').format(format);
	//return moment(date).startOf('day').format('MMM DD, YYYY');
};
export function findNavLabel (fromDate, toDate) {
	const compareFromDate = moment(fromDate).format(FORMAT);
	const compareToDate = moment(toDate).format(FORMAT);
	const today =  moment().format(FORMAT);
	// format initNavState
	const last7days = moment(today).subtract(7, 'day').format(FORMAT);
	const last30days = moment(today).subtract(30, 'day').format(FORMAT);
	const lastYear = 	moment(compareToDate).subtract(1, 'year').format(FORMAT);
	if (fromDate == compareToDate) return 'Today';
	if (last7days == compareFromDate) return 'Last 7 days';
	if (last30days == compareFromDate) return 'Last 30 days';
	if (lastYear == compareFromDate) return 'One year';
	else return 'Custom';
}

export function getDateForComparisonLast7Days(){
	const last7Days =  moment().subtract(7, 'day').format(FORMAT);
	const last7daysFromBegining = moment().subtract(14, 'day').format(FORMAT);
	const today = moment().format(FORMAT);


	return {
		fromDate: last7Days,
		toDate: today,
		label: 'Last 7 days',
		fromDateCompare: last7daysFromBegining,
		toDateCompare: last7Days
	};
}

export function handlerCatch (err){
	return err.status === 500;
}