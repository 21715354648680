import React , {useEffect,useContext, useState} from 'react';
import TableGridContainer from '../../../../Components/Table/TableGridContainer';
import TableRowItem from '../../../../Components/Table/TableRowItem';
import TableColItem from '../../../../Components/Table/TableColItem';
import SelectObjectiveContainer from './SelectObjectiveContainer';
import SelectAttributionContainer from './SelectAttributionContainer';
import SelectedInteractions from './SelectedInteractions';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import EmptyState from '../../../../Components/EmptyState';
import { reportingCampaignContext } from '../../Context/ContextReportingCampaign';
import MomentConstants from '../../../../Constants/MomentConstants';
import {getIndicatorInTableByKey} from '../../Indicators';
import tooltipStyles from './Tooltip.module.css';

import ReactTooltip from 'react-tooltip';

type valueList = {
    value: string,
    label : string
};

function getFormattedNumber(n, tofixed) {
	if (typeof n == 'undefined' || typeof n == null) return '';

	if (tofixed && typeof n == 'number') n = n.toFixed(2);
	const parts = n.toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	return parts.join('.');
}

function getObjectiveTargetName(target) {
	const name = target.replace('event$b$', '');
	return 'Improve: ' + name;
}

function getObjectiveName(obj) {
	obj = obj.toLowerCase();
	if (obj == 'improveconversion') return 'Improve transactions';
	if (obj == 'custominteraction') return 'Improve interactions';
	return obj;
}

function getAttributionName(att) {
	att = att.toLowerCase();
	if (att == 'postclick')  return 'Post click';
	if (att == 'postview')  return 'Post view';
	if (att == 'postinteraction')  return 'Post interaction';
	if (att == 'postpromocode')  return 'Post promo code';
	return att;
}

function getFrByArrayLength (length){
	const columnsWidth:string[] = [];
	const nbFr = 7;
	const minFr = 1.6;
	const mainColFr = Math.max(nbFr - length, minFr);

	columnsWidth.push(mainColFr + 'fr');
	for (let i = 0; i < length - 1; i++) {
		columnsWidth.push('1fr');
	}
	return {
		gridTemplateColumns: columnsWidth.join(' ')
	};
}

function getValueUplift(kpi){
	if(  kpi === null){ 
		return {
			hasUpLift : false,
			value: {
				unit : '',
				type: '',
				value : 0
			}
		};
	}
	else if ( kpi !== null){
		const newValue = {
			unit: kpi.unit,
			type: kpi.kpiValueType,
			value: kpi.value
		};
		if(kpi.kpiValueType === 'Rate' || kpi.kpiValueType === 'Percent'){
			newValue.unit = '%';
		}
		return {
			hasUpLift : true,
			value: {...newValue}
		};
	}

}
function getKpiElementOptions(kpi){
	const indicator = getIndicator(kpi.kpiIdentifier, kpi.value.kpiValueType);
	const upLift = kpi.upliftValue !== null ? getValueUplift(kpi.upliftValue) : false;

	return {
		label : indicator.label,
		infos : indicator.infos,
		type : kpi.value.kpiValueType,
		unit: kpi.value.unit,
		value: kpi.value.value,
		upLift: upLift
	};
}
function getIndicator(idKpi, unit) {
	const indicator = getIndicatorInTableByKey(idKpi);
	const final = {
		label: idKpi,
		infos: ''
	};
	if (indicator) {
		final.label = unit === 'Rate' ? indicator.rateLabel : indicator.label;
		final.infos = unit === 'Rate' ? indicator.rateInfos : indicator.infos;
	}
	return final;
}
function AnalysisSection(props) {

	const {
		device,
		// hasControlGroup,
		momentStartDate,
		momentEndDate,
		datainformation
	} = props;

	const indicatorsInteractions:any[] = [];
	datainformation?.interactions?.map(indicator => {
		//const labelInteraction = getInteractionName(indicator);
		indicatorsInteractions.push({
			label :  indicator,
			value : indicator,
			istarget: true,
		});
	});

	if(!momentEndDate && !momentEndDate){
		return null;
	}

	const {
		reportingCampagneServices,
		campaignId,
		accountId,
		systemServices,
		objectiveSelected,
		objectiveSelected2,
		setobjectiveSelected,
		setobjectiveSelected2,
		setlistAllowedObjectiveAndAttributions,
		interactionSelected,
		setinteractionSelected,
		isInteractionMode,
		setInteractionMode,
		targetSelected,
		setobjectiveSelectedForDropDown,
		settargetSelected,
		isAdmin,
		handleSaveObjectiveAndAttribution
	} = useContext<any>(reportingCampaignContext);

	const [headerList, setheaderList] = useState(<></>);
	const [noData, setNoData] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [testLines, setTestLines] = useState<Array<any>>([]);
	const [variationLines, setVariationLines] = useState<Array<any>>([]);

	const [controlLines, setControlLines] = useState<Array<any>>([]);

	const [optionsCssTable, setoptionsCssTable] = useState({});
	const [defaultListObjective, setdefaultListObjective] = useState<valueList[]>([]);
	const [defaultListAttribution, setdefaultListAttribution] = useState<valueList[]>([]);
	const [isLoading, setisLoading] = useState(false);

	function getAttributionModeDefaultList(selectedElement){
		const elem = datainformation.allowedObjectiveAndAttributions.find(x => x.objective === selectedElement.campaignObjective );
		if(elem){
			const arrayOfAttribution = elem.attributionMode.map(x => {
				return {
					label : getAttributionName(x),
					value: x
				};}
			);
			setdefaultListAttribution(arrayOfAttribution);
		}
	}
	useEffect(()=> {
		if(datainformation){
			
			const selectedElement = datainformation.selectedObjectiveAndAttribution;
			let arrayOfObjective = datainformation.allowedObjectiveAndAttributions.map(x => {
				return {
					label : getObjectiveName(x.objective),
					value: x.objective,
					istarget : false
				};}
			);

			datainformation.targets.map(indicator => {
				arrayOfObjective.push({
					label :  getObjectiveTargetName(indicator),
					value : indicator,
					istarget: true,
				});
			});
			arrayOfObjective = arrayOfObjective.filter(x => x.value !== 'CustomTarget');
			if(selectedElement !== null){
				getAttributionModeDefaultList(selectedElement);
				if(selectedElement.targetOrInteraction !== null){
					if(selectedElement.campaignObjective === 'ImproveConversion' && selectedElement.attributionMode !== 'PostInteraction'){
						const newSelectedElement = datainformation.allowedObjectiveAndAttributions[0];
						if(newSelectedElement) {
							const arrayOfAttribution = newSelectedElement.attributionMode.map(x => {
								return {
									label: getAttributionName(x),
									value: x
								};
							});
							setdefaultListObjective(arrayOfObjective);
							setdefaultListAttribution(arrayOfAttribution);
							setobjectiveSelected(newSelectedElement.objective);
							setobjectiveSelectedForDropDown(newSelectedElement.objective);
							const elemSelected = newSelectedElement;

							handleSaveObjectiveAndAttribution('ImproveConversion', newSelectedElement.attributionMode[0], null);
							setInteractionMode(false);
							setobjectiveSelected2(elemSelected.attributionMode[0]);
						}
					}
					else if(selectedElement.attributionMode === 'PostInteraction'){
						setInteractionMode(false);
						setinteractionSelected(selectedElement.targetOrInteraction);
						setobjectiveSelected(selectedElement.campaignObjective);
						setobjectiveSelectedForDropDown(selectedElement.campaignObjective);
						setobjectiveSelected2(selectedElement.attributionMode);
					}
					else if(selectedElement.campaignObjective !== 'CustomTarget'){
						setInteractionMode(true);
						setinteractionSelected(selectedElement.targetOrInteraction);
						setobjectiveSelected(selectedElement.campaignObjective);
						setobjectiveSelectedForDropDown(selectedElement.campaignObjective);
					}
					else{
						setInteractionMode(false);
						setobjectiveSelected(selectedElement.campaignObjective);
						setobjectiveSelectedForDropDown(selectedElement.targetOrInteraction);
						settargetSelected(selectedElement.targetOrInteraction);
						setobjectiveSelected2(selectedElement.attributionMode);
					}
				}else{
					setInteractionMode(false);
					setobjectiveSelected2(selectedElement.attributionMode);
					setobjectiveSelected(selectedElement.campaignObjective);
					setobjectiveSelectedForDropDown(selectedElement.campaignObjective);
				}
				setdefaultListObjective(arrayOfObjective);
				setlistAllowedObjectiveAndAttributions(datainformation.allowedObjectiveAndAttributions);

			}else{
				const newSelectedElement = datainformation.allowedObjectiveAndAttributions[0];
				if(newSelectedElement){					
					const arrayOfAttribution = newSelectedElement.attributionMode.map(x => {
						return {
							label : getAttributionName(x),
							value: x
						};}
					);
					setdefaultListObjective(arrayOfObjective);
					setdefaultListAttribution(arrayOfAttribution);
					setobjectiveSelected(newSelectedElement.objective);
					setobjectiveSelectedForDropDown(newSelectedElement.objective);
					const elemSelected = newSelectedElement;

					if(elemSelected.objective  === 'CustomInteraction'){
						setInteractionMode(true);
						const el = indicatorsInteractions[0];
						if(el){
							setinteractionSelected(el.value);
						}
					}else if(elemSelected.objective === 'CustomTarget'){
						setInteractionMode(false);
						settargetSelected(datainformation.targets[0]);
						setobjectiveSelected2(selectedElement.attributionMode);
					}
					else{
						setInteractionMode(false);
						setobjectiveSelected2(elemSelected.attributionMode[0]);
					}
				}

			}

		}
	},[datainformation]);

	const controller = new AbortController();
	const signal = controller.signal;
	useEffect(()=>{
		const timer = setTimeout(() => {
			if(objectiveSelected && objectiveSelected2 || objectiveSelected  && interactionSelected || objectiveSelected  && targetSelected){

				const fakeContext = null;
				//const fakeContext = 'no-controlgroup_abtests';
				setisLoading(true);
				getAttributionModeDefaultList({
					campaignObjective : objectiveSelected
				});
				reportingCampagneServices.getCampaignPerformance(accountId, campaignId, momentStartDate.format(MomentConstants.MOMENT_API_FORMAT), momentEndDate.format(MomentConstants.MOMENT_API_FORMAT),objectiveSelected,objectiveSelected2,interactionSelected ? interactionSelected : targetSelected,device,fakeContext,
					data => {
						setHasError(false);
						setisLoading(false);
						if(data.slideKpiSyntheses.length === 0 ){
							setheaderList(<></>);
							setNoData(true);
						}else{

							const groupKpiSyntheses = data.slideKpiSyntheses[0].groupKpiSyntheses;
							if (!groupKpiSyntheses || !groupKpiSyntheses[0]) {
								console.log('No data in', data.slideKpiSyntheses[0]);
								setheaderList(<></>);
								setNoData(true);
								return;
							}

							const headerTable = groupKpiSyntheses[0].slideKpis.map((kpi,idx) => {
								const optionsKpi =  getKpiElementOptions(kpi);					
								return (
									<React.Fragment key={idx} >
										<TableColItem  align="right">
											{optionsKpi.label}
											{optionsKpi.infos &&
											<>
												<i
													className='fas fa-info-circle' 
													data-for={'col_' + idx}
													data-tip={optionsKpi.infos}
												/>
												<ReactTooltip key={'tooltip'} backgroundColor='black' effect='solid' id={'col_' + idx} />
											</>
											}
										</TableColItem>
									</React.Fragment>
								);
							});

							headerTable.unshift(<TableColItem key={'firstKey'}>Group</TableColItem>);
							setheaderList(headerTable);
							setoptionsCssTable(getFrByArrayLength(groupKpiSyntheses[0].slideKpis.length + 1));

							setTestLines(groupKpiSyntheses.filter((line) => line.visitorGroupType === 'Test' || line.visitorGroupType === 'TestNoControl'));
							setVariationLines(groupKpiSyntheses.filter((line) => {
								return line.visitorGroupType === 'Variation' || line.visitorGroupType === 'BaselineVariation';
							}));
							setControlLines(groupKpiSyntheses.filter((line) => line.visitorGroupType === 'Control'));
							setNoData(false);
						}
					},
					error => {
						console.error('error', error);
						setisLoading(false);
						systemServices.showError('An error occured while fetching data');
						setNoData(true);
						setHasError(true);
					},signal);
			}
		}, 500);
		return () => {
			if(controller){
				controller.abort();				
			}
			clearTimeout(timer);
		};
	},[objectiveSelected, objectiveSelected2, interactionSelected,isInteractionMode, targetSelected,momentStartDate,
		momentEndDate,device]);

	return (
		<>
			{isLoading && (
				<SpinnerWheel />
			)}

			<div className='reporting_part'>
				<div className="reporting_part_title flex_item_full">
					Analysis
				</div>
				<div className='reporting_part_filters'>
					<div className='btn_group_l'>
						<SelectObjectiveContainer
							datainformation={datainformation}
							defaultList={defaultListObjective}
							defaultListInteractions={indicatorsInteractions}
						/>
						{(!isInteractionMode || objectiveSelected2 === 'PostInteraction') && 
							<SelectAttributionContainer
								defaultListInteractions={indicatorsInteractions}
								defaultList={defaultListAttribution}
							/>
						}
						{(isInteractionMode || objectiveSelected2 === 'PostInteraction') &&
							<SelectedInteractions
								defaultList={indicatorsInteractions}
							/>
						}
				
					</div>
				</div>
				<div className='reporting_part_body'>
					{noData && !hasError &&
						<EmptyState
							imageUrl='/Assets/empty_no_data.svg'
							title="No data for this period"
							text="Maybe try with another period"
							hasBorder={true}
						/>
					}
					{hasError &&
						<EmptyState
							icon='fas fa-exclamation-circle'
							title="An error has occurred"
							text="Please contact us"
							hasBorder={true}
						/>
					}
					{!noData &&
						<TableGridContainer specialClasseName="table_grid_reporting s" >
							<TableRowItem specialClasseName={'table_head_row'} 
								styleObj={optionsCssTable}>
								{headerList}
							</TableRowItem>
							{testLines.map((line) => 
								<TableRow
									isAdmin={isAdmin}
									key={line.groupId}
									row={line}
									style={optionsCssTable}
								/>
							)}
							{variationLines.length > 0 &&
								<div className='table_body_row_span'>
									{variationLines.map((line) => 
										<TableRow
											isAdmin={isAdmin}
											key={line.groupId}
											row={line}
											style={optionsCssTable}
										/>
									)}
								</div>
							}
							{controlLines.map((line) => 
								<TableRow
									isAdmin={isAdmin}
									key={line.groupId}
									row={line}
									style={optionsCssTable}
								/>
							)}
						</TableGridContainer>
					}
				</div>
			</div>
		</>
	);
}

function TableRow({
	row,
	style,
	isAdmin
}) {
	const handleClickAndCopyToCLipBoard = (v) => {
		if (isAdmin) {

			if (typeof v === 'number') {
				v = Math.round((v + Number.EPSILON) * 100) / 100;
			}

			v = v.toString().replace('.', ',');

			navigator.clipboard.writeText(v)
				.then(() => {
					const tooltip = document.createElement('div');
					tooltip.classList.add(tooltipStyles.tooltip);
					tooltip.innerText = 'Copied to clipboard: "' + v + '"';
					document.body.appendChild(tooltip);

					setTimeout(() => {
						tooltip.classList.add(tooltipStyles.hide);
						setTimeout(() => {
							tooltip.remove();
						}, 150);
					}, 3000);
				})
				.catch((err) => {
					console.error('Erreur lors de la copie :', err);
				});
		}
	};
	const getUpliftClass = (rate) => {
		const classes = ['s_13 mt_2 fw_medium'];
		if (rate > 0) classes.push('c_green');
		if (rate < 0) classes.push('grey_2');
		return classes.join(' ');
	};

	const getGroupName = (name) => {
		if (!(name.includes('VAR'))) return name;
		const split = name.split(' VAR');
		return split[0].replace('Variation', 'Variation ');
	};

	return (
		<TableRowItem 
			specialClasseName={'table_body_row no_hover'}
			styleObj={style}
		>
			<TableColItem>
				<div>
					<div className='fw_medium'>{getGroupName(row.groupName)}</div>
					<div className='grey_2 s_13 mt_2' onClick={()=>handleClickAndCopyToCLipBoard(row.visitorGroupSize)} style={{cursor : isAdmin ? 'pointer' : 'default'}}>
						{getFormattedNumber(row.visitorGroupSize, false)} sessions
					</div>
				</div>
			</TableColItem>
			{row.slideKpis.map((kpi,k) => {

				const optionsKpi: any =  getKpiElementOptions(kpi);
				const value = optionsKpi.value !== null ? optionsKpi.value : 0;

				const hasUpLift = optionsKpi?.upLift?.hasUpLift && optionsKpi.upLift?.value?.value !== null;
				const upLift = hasUpLift ? optionsKpi.upLift.value : null;
				const upLiftValue = hasUpLift ? getFormattedNumber(upLift.value, upLift.type !== 'Integer') : '-';
				const upLiftSign = hasUpLift && upLift.value > 0 ? '+' : '';
				const upLiftClass = hasUpLift ? getUpliftClass(upLift.value) : '';

				return (
					<TableColItem key={k} align="right">
						<div>
							<div onClick={()=>handleClickAndCopyToCLipBoard(value)} style={{cursor : isAdmin ? 'pointer' : 'default'}}>
								{getFormattedNumber(value, optionsKpi.type !== 'Integer')} {optionsKpi.type === 'Rate' && ' %'} {optionsKpi.unit}
							</div>
							{optionsKpi && hasUpLift &&
								<div className={upLiftClass} onClick={()=>handleClickAndCopyToCLipBoard(upLift.value)} style={{cursor : isAdmin ? 'pointer' : 'default'}}>
									{upLiftSign}{upLiftValue} {upLift.unit}
								</div>
							}
						</div>
					</TableColItem>
				);
			})}
		</TableRowItem>
	);
}

const MemoizedComponent = React.memo(AnalysisSection);
export default MemoizedComponent;