import React, { createContext, useContext, useEffect, useState, SetStateAction, Dispatch } from 'react';
import SystemServices from '../../../../Services/SystemServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import EmailTemplateServices from '../../../../Services/EmailTemplateServices';
// import EventTrackingServices from '../../../../Services/EventTrackingServices';
// import { v4 as uuidv4 } from 'uuid';
// import {ProductRecommendationsValue} from '../../../WysiwygEditor/Utils/TemplateOperations';
import { EmailTemplateObject, emptyTemplate } from './EmailTemplateTypes';
import { initializeFromFormat, validate, ValidationError } from './EditorOperations';
import { TemplateProperty, DeviceType } from '../../../WysiwygEditor/Types/BespokeTypes';
import { convertSaaSFormatsFromApiType, convertSaaSFormatFromApiType, convertEmailTemplateForSaving, convertEmailTemplateForUpdate } from './TypeAdapters';
import { EditorState, EditorCreateOrUpdateMode, EditorStep } from './EditorStateTypes';
// import {updateEmailTemplateApiType} from '../../../../Services/EmailTemplateServicesTypes';
import { Style } from './StyleTypes';
import { EmailProvider, EmailTemplateSaaSFormat } from '../../../../Services/EmailTemplateServicesTypes';
import { ProductRecommendationsValue } from '../../../WysiwygEditor/Utils/TemplateOperations';

const EmailTemplateEditorContext = createContext<EmailTemplateEditorContextType | undefined>(undefined);

function useEmailTemplateEditorContext() {
	const context = useContext(EmailTemplateEditorContext);
	if (!context) throw Error('useEmailTemplateEditorContext can only be used inside an EmailTemplateEditorContextProvider');
	return context;
}

const CreateEmailTemplateEditorContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;

	// const authServices = props.AuthServices;
	// const getAccessToken = authServices && authServices.getAccessToken;
	const systemServices = new SystemServices($rootScope, $timeout);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const isAdmin = impersonatingServices.isAnAdmin();

	//const { trackEvent } = EventTrackingServices(props.$rootScope.User);
	const crud = new EmailTemplateServices(accountId, $http);
	// let urlReturn = '/EmailTemplate/Dashboard';
	// if ($routeParams && $routeParams.ka && isAdmin) {
	// 	urlReturn += '?ka=' + $routeParams.ka;
	// }

	const mode: EditorCreateOrUpdateMode = !$routeParams.id ? EditorCreateOrUpdateMode.Create : EditorCreateOrUpdateMode.Update;

	const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(true);

	const [currentDevice, setCurrentDevice] = useState<DeviceType>(DeviceType.Desktop);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [emailTemplate, setEmailTemplate] = useState<EmailTemplateObject>(emptyTemplate);

	const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

	const [formatSavedStyles] = useState<Style[]>([]);
	const [matchedExistingStyle] = useState<Style | null>(null);

	const [saasFormats, setSaasFormats] = useState<EmailTemplateSaaSFormat[]>([]);

	const [templateSelected, setTemplateSelected] = useState<EmailTemplateSaaSFormat>();

	const [editorState, setEditorState] = useState<EditorState>({
		sideBarStep: EditorStep.One,
		formatIsSelected: false,
		selectedFormat: null,
		selectedStyle: { Id: 'default', Properties: [] },
		defaultStyle: null,
		currentStyle: null,
		currentWysiwyg: null,
	});
	const [modelProperties, setModelProperties] = useState<TemplateProperty[]>([]);

	const getTemplateByIdAndTransformToEditor = (id: string) => {
		setIsLoading(true);
		crud.getEmailTemplateById(id,
			result => {
				const selectedSaaSFormat: EmailTemplateSaaSFormat = convertSaaSFormatFromApiType(result.saaSFormat);
				setTemplateSelected(selectedSaaSFormat);
				const { updateVariation } = initializeFromFormat(
					currentDevice,
					mode,
					selectedSaaSFormat.template,
					true,
					true,
					editorState,
					emailTemplate,
					true,
				);

				const emailTemplateWithEditorId = updateVariation(emailTemplate);
				const mergeElementEditorEmailTemplate = emailTemplateWithEditorId.Properties.map((property: TemplateProperty, idx) => {
					if (property.Editor.Type === 'ProductRecommendations') {
						const editorValue = result.templateProperties[idx].value;

						if (editorValue) {
							try {
								return { ...property, Value: ProductRecommendationsValue.fromValue(JSON.parse(editorValue)) };
							} catch (e) {
								console.error("=>(EmailTemplateEditorContextProvider.tsx:99) JSON.parse error", e);
								return { ...property, Value: '' };
							}
						} else {
							return { ...property, Value: '' };
						}
					} else {
						const editorValue = result.templateProperties[idx].value;
						return { ...property, Value: editorValue };
					}
				});

				emailTemplateWithEditorId.Properties = mergeElementEditorEmailTemplate;
				const mergeElementTemplate = emailTemplateWithEditorId?.Template?.Properties.map((property: TemplateProperty, idx) => {
					if (property.Editor.Type === 'ProductRecommendations') {
						const editorValue = result.templateProperties[idx].value;

						if (editorValue) {
							try {
								return { ...property, Value: ProductRecommendationsValue.fromValue(JSON.parse(editorValue)) };
							} catch (e) {
								console.error("=>(EmailTemplateEditorContextProvider.tsx:123) JSON.parse error", e);
								return { ...property, Value: '' };
							}
						} else {
							return { ...property, Value: '' };
						}
					} else {
						const editorValue = result.templateProperties[idx].value;
						return { ...property, Value: editorValue };
					}
				});

				if (emailTemplateWithEditorId?.Template?.Properties && mergeElementTemplate) {
					emailTemplateWithEditorId.Template.Properties = mergeElementTemplate;
				}

				setEmailTemplate((s): EmailTemplateObject => ({
					...s,
					Properties: emailTemplateWithEditorId.Properties,
					Template: emailTemplateWithEditorId.Template,
					TemplateDescriptorDesktop: emailTemplateWithEditorId.TemplateDescriptorDesktop,
					TemplateDescriptorMobile: emailTemplateWithEditorId.TemplateDescriptorMobile,
					PersonalisationParameterName: result.recommendationConfiguration?.personalisationParameterName ?? null,
					Status: result.status,
					Name: result.name,
					Description: result.description,
					Id: result.id,
					ProviderConfiguration: result.providerConfiguration
				}));

				setEditorState((s) => ({
					...s,
					formatIsSelected: true,
					currentWysiwyg: emailTemplateWithEditorId.Template !== null ? emailTemplateWithEditorId.Template.WysiwygDesktop : null
				}));

			}, err => {
				console.log(err);
				systemServices.showError('An error occurred while getting templates.');
			});
	};

	const loadSaaSFormats = () => {
		crud.getEmailTemplateSaaSFormats(formats => {
			const sass = convertSaaSFormatsFromApiType(formats);
			setSaasFormats(sass);
		}, err => {
			console.log(err);
			systemServices.showError('An error occured while getting templates.');
		});
	};

	const initializeFromSelectedFormat = (format: EmailTemplateSaaSFormat): void => {
		const { updateEditorState, updateVariation } = initializeFromFormat(
			currentDevice,
			mode,
			format.template,
			true,
			true,
			editorState,
			emailTemplate,
			true
		);

		setEmailTemplate(updateVariation);
		setEditorState(updateEditorState);
	};

	const checkValidationErrors = (emailTemplate: EmailTemplateObject): boolean => {
		const { validateEditorState, validateInformationsData, validateFormatData } = validate();
		const { isValid: isValid0, errors: errors0 } = validateEditorState(editorState, validationErrors);
		const { isValid: isValid1, errors: errors1 } = validateInformationsData(emailTemplate, errors0);
		const { isValid: isValid2, errors: errors2 } = validateFormatData(emailTemplate, errors1);
		setValidationErrors(errors2);
		return isValid0 && isValid1 && isValid2;
	};


	useEffect(() => {

		setEditorState((s) => ({
			...s,
			currentWysiwyg:
				editorState.selectedFormat == null ? s.currentWysiwyg :
					(currentDevice === DeviceType.Desktop
						? editorState.selectedFormat.WysiwygDesktop
						: editorState.selectedFormat.WysiwygMobile),
		}));
	}, [currentDevice]);


	useEffect(() => {
		if (mode !== EditorCreateOrUpdateMode.Create) {
			const idTemplate: string = $routeParams.id;
			getTemplateByIdAndTransformToEditor(idTemplate);
		} else {
			loadSaaSFormats();
			setIsLoading(true);
		}

	}, []);

	// events

	const handleChangeStep = (step: EditorStep) => {
		setEditorState(x => ({ ...x, sideBarStep: step }));
	};

	const handleFormatPreviewClicked = (format: EmailTemplateSaaSFormat) => {

		setTemplateSelected(format);
		const { updateEditorState, updateVariation } = initializeFromFormat(
			currentDevice,
			mode,
			format.template,
			false,
			false,
			editorState,
			emailTemplate,
			false,
		);
		const formWithId = updateVariation(emailTemplate);
		setEmailTemplate(s => ({
			...s,
			...formWithId,
			Id: format.template.Key
		}));
		setEditorState(updateEditorState);
	};

	const handleFormatChosen = () => {
		if (templateSelected) {
			// handleChangeStep(EditorStep.Three);
			initializeFromSelectedFormat(templateSelected);
		}
	};

	function handleCloseEditor() {
		if (isAdmin && props.$routeParams.ka) {
			window.location.href = `${'EmailTemplate/Dashboard'}?ka=${props.$routeParams.ka}&ku=${props.$routeParams.ku}`;
		} else {
			window.location.href = window.location.href = `${'EmailTemplate/Dashboard'}`;
		}
	}

	const handleSaveEmailTemplate = (template: EmailTemplateObject) => {
		const emailToCheck = { ...template };
		emailToCheck.Properties = [...modelProperties];
		if (!checkValidationErrors(emailToCheck)) {
			return;
		}
		const onSuccess = () => {
			systemServices.showSuccess('Your email template has been saved successfully');
			handleCloseEditor();
		};
		const onError = () => systemServices.showError('An error occured while saving your email template');

		if (templateSelected === null || templateSelected === undefined) {
			console.log('Error, SaaS Format is missing');
			return onError();
		}

		if (mode === EditorCreateOrUpdateMode.Create) {

			crud.createEmailTemplate(convertEmailTemplateForSaving(emailToCheck, templateSelected), onSuccess, onError);
		} else {
			if (emailToCheck.Id === null) {
				console.log('Error, template id is missing');
				return onError();
			}
			crud.updateEmailTemplate(emailToCheck.Id, convertEmailTemplateForUpdate(emailToCheck, templateSelected), onSuccess, onError);
		}
	};


	// callbacks

	const applyUpdateProperties = (updateProperties: (p: TemplateProperty[]) => TemplateProperty[]) => {
		setEmailTemplate(v => ({ ...v, Properties: updateProperties(v.Properties) }));
	};

	const handleChangeName = (name: string) => {
		setEmailTemplate(v => ({ ...v, Name: name }));
	};

	const handleChangeDescription = (description: string) => {
		setEmailTemplate(v => ({ ...v, Description: description }));
	};

	const handleSetPersonalisationParameterName = (parameterName: string) => {
		setEmailTemplate(v => ({ ...v, PersonalisationParameterName: parameterName }));
	};

	const handleChangeProvider = (provider: EmailProvider) => {
		setEmailTemplate(v => ({ ...v, ProviderConfiguration: { emailProvider: provider } }));
	};


	const context: EmailTemplateEditorContextType = {
		isLoading,
		emailTemplate,
		saasFormats,
		editorState,
		formatSavedStyles,
		matchedExistingStyle,
		currentDevice,
		mode,
		provider: emailTemplate.ProviderConfiguration?.emailProvider ?? EmailProvider.Brevo,
		handleChangeProvider,
		personalisationParameterName: emailTemplate.PersonalisationParameterName,
		handleSetPersonalisationParameterName,
		setCurrentDevice,
		handleFormatPreviewClicked,
		handleFormatChosen,
		handleChangeStep,
		handleSaveEmailTemplate,
		applyUpdateProperties,
		templateSelected,
		initializeFromSelectedFormat,
		handleChangeName,
		handleChangeDescription,
		handleCloseEditor,
		modelProperties,
		setModelProperties,
		sidebarIsOpen,
		setSidebarIsOpen
	};

	return (
		<EmailTemplateEditorContext.Provider
			value={context}
		>
			{props.children}
		</EmailTemplateEditorContext.Provider>
	);
};

export default CreateEmailTemplateEditorContextProvider;

export { useEmailTemplateEditorContext };

type EmailTemplateEditorContextType = {
	isLoading: boolean;
	emailTemplate: EmailTemplateObject;
	saasFormats: EmailTemplateSaaSFormat[];
	editorState: EditorState;
	formatSavedStyles: Style[];
	matchedExistingStyle: Style | null;
	currentDevice: DeviceType;
	mode: EditorCreateOrUpdateMode;
	provider: EmailProvider;
	handleChangeProvider: (provider: EmailProvider) => void;
	personalisationParameterName: string | null;
	handleSetPersonalisationParameterName: (parameterName: string) => void;
	handleFormatPreviewClicked: (format: EmailTemplateSaaSFormat) => void;
	handleFormatChosen: () => void;
	setCurrentDevice: Dispatch<SetStateAction<DeviceType>>;
	handleChangeStep: (step: EditorStep) => void;
	handleSaveEmailTemplate: (tp: EmailTemplateObject) => void;
	applyUpdateProperties: (arg0: (propsToUpdate: TemplateProperty[]) => TemplateProperty[]) => void;
	templateSelected: any,
	initializeFromSelectedFormat: (any) => void;
	handleChangeName: (name: string) => void;
	handleChangeDescription: (description: string) => void;
	handleCloseEditor: () => void;
	modelProperties: TemplateProperty[];
	setModelProperties: Dispatch<SetStateAction<TemplateProperty[]>>;
	sidebarIsOpen: boolean,
	setSidebarIsOpen: (boolean) => void;
}
