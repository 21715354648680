import React from 'react';

export default function TableHeadRow({
	className = '',
	children,
	isSticky,
	...otherProps
}) {

	const cssClass = ['table_row', 'table_head_row'];

	if (className) {
		cssClass.push(className);
	}

	if (isSticky) {
		cssClass.push('table_head_row_sticky');
	}

	return (
		<div
			className={cssClass.join(' ')}
			{...otherProps}
		>
			{children}
		</div>
	);
}