import React, { useState, useEffect } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../ReportingCampaign/ReportingCampaignGlobal.css';
import moment from 'moment';
import InformationsOnSiteCampaign from './InformationsOnSiteCampaign';
import ResultsCampaignContainer from './Results/ResultsCampaignContainer';
import MomentConstants from '../../Constants/MomentConstants';
import ReportingCampaignServices from '../../Services/ReportingCampaignServices';
import SystemServices from '../../Services/SystemServices';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import FilterDevices from './FilterDevices';
import ReportingCampaignProvider from './Context/ContextReportingCampaign';
import formatMoment from '../../Constants/FormatMoment';
import FormCustomReportContainerHooks from '../Dashboard/FormCustomReports/FormCustomReportContainerHooks';
import NavigationReporting from './Navigation/NavigationReporting';
import OnSiteContainer from './OnSiteContainer';
import MailStatsContainer from './EmailStats/MailStatsContainer';
import EventTrackingServices from '../../Services/EventTrackingServices';
import { I18nextProvider } from 'react-i18next';
import SearchCampaign from './SearchCampaign';
import { Article } from '../../Components/Article/Article';
import newInstanceI18next from '../../Util/traductionUtils';
import CampaignGraphServices from '../../Services/CampaignGraphServices';

const FORMAT = 'YYYY/MM/DD';
const findNavLabel = (fromDate, toDate) => {
	const compareFromDate = moment(fromDate).format(FORMAT);
	const compareToDate = moment(toDate).format(FORMAT);
	const today = moment().format(FORMAT);
	// format initNavState
	var last7days = moment(today).subtract(7, 'day').format(FORMAT);
	var last30days = moment(today).subtract(30, 'day').format(FORMAT);
	var lastYear = moment(compareToDate).subtract(1, 'year').format(FORMAT);
	if (fromDate == compareToDate) return 'Today';
	if (last7days == compareFromDate) return 'Last 7 days';
	if (last30days == compareFromDate) return 'Last 30 days';
	if (lastYear == compareFromDate) return 'One year';
	else return 'Custom';
};
const formatDateToLocal = (dateToFormat) => {
	return moment(moment.utc(dateToFormat).toDate()).local();
};

export default function ReportingCampaign({
	$location,
	AuthServices,
	$http,
	$routeParams,
	$rootScope,
	initialCampaignId,
	initialDimension,
	loadCampaignFunction,
	navReporting,
	getEditorFunction,
	shouldDisplayEditorLink,
	shouldDisplayStatus,
	shouldDisplayDates
}) {

	const [dataObjectiveCampaign, setDataObjectiveCampaign] = useState();

	const [dataInteractionIndicators, setDataInteractionIndicators] = useState();
	const [DataSecondaryIndicators, setDataSecondaryIndicators] = useState();
	const [DataPrimaryIndicators, setDataPrimaryIndicators] = useState();
	const [momentStartDate, setMomentStartDate] = useState();
	const [momentEndDate, setMomentEndDate] = useState();
	const [granularity, setGranularity] = useState('day');
	const [device, setDevice] = useState();
	const [summaryFirstIndicators, setSummaryFirstIndicators] = useState();
	const [interactionsIndicators, setInteractionsIndicators] = useState([]);
	const [targetIndicators, setTargetIndicators] = useState([]);
	const [newArrayOfIndicators, setNewArrayOfIndicators] = useState([]);
	const [hasVariations, setHasVariations] = useState(false);
	const [arrayOfVariations, setArrayOfVariations] = useState([]);
	const [variationSelected, setVariationSelected] = useState();
	const [variationSelectedLabel, setVariationSelectedLabel] = useState('');
	const [isLoadingInformationsCampaign, setIsLoadingInformationsCampaign] = useState(true);
	const [abtestDropdownIsOpen, setAbtestDropdownIsOpen] = useState(false);
	const [frequencyDropdownIsOpen, setFrequencyDropdownIsOpen] = useState(false);
	const [listOfCampaignsAccount, setListOfCampaignsAccount] = useState();
	const [filteredType, setFilteredType] = useState();
	const [initialDates, setinitialDates] = useState('');

	const [typesConfigOfCampaignSelected, settypesConfigOfCampaignSelected] = useState({
		onSite: true,
		sendPromoCode: false,
		sendVisitorCart: false,
		stockAlert: false,
	});
	const initiNavDate = (navState) => {
		// if (!navState || !navState.fromDate || !navState.toDate)
		// 	return initialDates;
		return {
			initialLabel: findNavLabel(navState.fromDate, navState.toDate),
			initialFrom: navState.fromDate,
			initialTo: navState.toDate,
		};
	};


	const [customDateRange, setCustomDateRange] = useState({
		startDate: moment().subtract(1, 'month'),
		endDate: moment().subtract(1, 'day')
	});

	const pickerStorage = JSON.parse(sessionStorage.getItem('reportingRangeDatePicker'));

	useEffect(() => {

		if (pickerStorage) {
			setinitialDates(initiNavDate({
				fromDate: moment(pickerStorage.startDate).format(FORMAT),
				toDate: moment(pickerStorage.endDate).format(FORMAT),
			}));

			setCustomDateRange({
				startDate: formatDateToLocal(moment(pickerStorage.startDate)),
				endDate: formatDateToLocal(moment(pickerStorage.endDate))
			});
		} else {
			setinitialDates(initiNavDate({
				fromDate: moment().subtract(7, 'day').format(FORMAT),
				toDate: moment().format(FORMAT),
			}));
		}
	}, []);


	const [activeLink, setactiveLink] = useState(initialDimension);
	const [campaignHasForm, setcampaignHasForm] = useState(false);
	const { formatDateToLocal } = formatMoment;
	const [dateRange, setdateRange] = useState({
		startDate: null,
		endDate: null,
		initialLabel: ''
	});

	const [isCustomSelected, setisCustomSelected] = useState(false);

	const { startDate, endDate, initialLabel } = dateRange;

	const handleOnDateChange = (startDate, endDate) => {
		const validateDate = (date, property) => {
			if (date !== null) {
				return date;
			} else {
				const dateNew = document.getElementById(property);
				const dateVal = moment(dateNew.value, 'DD/MM/YYYY');
				if (dateVal && dateVal.isValid()) {
					return dateVal;
				} else {
					const pickerStorage = JSON.parse(sessionStorage.getItem('reportingRangeDatePicker'));
					if (!pickerStorage) {
						return;
					} else {
						return pickerStorage[property];
					}
				}
			}
		};
		const labelInit = findNavLabel(startDate.startDate, startDate.endDate);
		const pickerValidate = {
			startDate: validateDate(startDate.startDate, 'startDate'),
			endDate: validateDate(startDate.endDate, 'endDate'),
			initialLabel: labelInit
		};
		sessionStorage.setItem('reportingRangeDatePicker', JSON.stringify(pickerValidate));
		setdateRange({
			startDate: startDate.startDate,
			endDate: startDate.endDate,
			initialLabel: labelInit
		});
	};

	// Fetch Api Need Services
	//const AuthServices = props.AuthServices;
	const reportingCampagneServices = new ReportingCampaignServices($http, $routeParams);
	const systemServices = new SystemServices($rootScope);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;
	const isAdmin = impersonatingServices.isAnAdmin();


	const campaignGraphServices = new CampaignGraphServices(AuthServices);
	const getEditor = (campaignId, callbackSuccess, callbackError) => {
		getEditorFunction(campaignGraphServices)(campaignId, callbackSuccess, callbackError);
	};

	const { trackEvent } = EventTrackingServices($rootScope.User);

	const [campaignId, setCampaignId] = useState(initialCampaignId);
	const [TestModeIpAddressesOfCurrentCampaign, setTestModeIpAdressesOfCurrentCampaign] = useState([]);
	const [dataFiltered, setDataFiltered] = useState();
	const [noData, setNoData] = useState();
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	/**
   * Function for fetch the data of indicator of campaign
   * @param {the indicators to fetch} indicators
   * @param {*} callbackSuccess
   * @param {*} callbackError
   */
	const getIndicators = (indicators, device, callbackSuccess, callbackError) => reportingCampagneServices.getIndicators(accountId, campaignId, indicators, startDate, endDate, granularity, device, hasVariations, callbackSuccess, callbackError);

	/**
   * function for fetch second indicator of campaign
   * @param { the indicator to fetch} indicator
   * @param {*} callbackSuccess
   * @param {*} callbackError
   */
	const loadSecondIndicator = (indicator, device, callbackSuccess, callbackError) => reportingCampagneServices.getSecondaryCampaignIndicator(accountId, campaignId, indicator, startDate, endDate, device, hasVariations, callbackSuccess, callbackError);
	const getAllSecondaryIndicators = (indicators, device, callbackSuccess, callbackError) => reportingCampagneServices.getAllSecondaryIndicators(accountId, campaignId, indicators, startDate, endDate, device, hasVariations, success => { callbackSuccess(success); }, error => systemServices.showError('an error occurred'));
	/**
   *  function for update the interaction indicators
   * @param {the indicator to fetch} interaction
   * @param {*} callbackSuccess
   * @param {*} callbackError
   */

	const updateInteractionIndicator = (interaction, callbackSuccess, callbackError) => reportingCampagneServices.updateInteractionIndicators(accountId, campaignId, interaction, callbackSuccess, callbackError);

	/**
   * Function for update the Summary Indicators
   * @param {*} callbackSuccess
   * @param {*} callbackError
   */
	const updateSummaryIndicators = (summaryIndicators) => reportingCampagneServices.updateSummaryIndicators(accountId, campaignId, summaryIndicators);
	/**
   * Function for reload all information of campaign
   * @param {id of Account accountId
   * @param {id of campaign} campaignId
   * @param {*} callbackSuccess
   * @param {*} callbackError
   */
	const loadAllInformationCampaign = (callbackSuccess, callbackError) => reportingCampagneServices.getCampaignInformations(accountId, campaignId, callbackSuccess, callbackError);

	const searchIndicators = (searchTerm, offset, callbackSuccess, callbackError) => reportingCampagneServices.searchIndicators(accountId, campaignId, searchTerm, offset, callbackSuccess, callbackError);

	const today = moment();
	const changeRangeDate = (event) => {
		setMomentStartDate(moment(startDate, MomentConstants.MOMENT_API_FORMAT));
		setMomentEndDate(moment(endDate, MomentConstants.MOMENT_API_FORMAT));
	};

	const changeGranularity = () => {
		const storeGranularity = JSON.parse(sessionStorage.getItem('reportingGranularity'));
		const pickerStorage = JSON.parse(sessionStorage.getItem('reportingRangeDatePicker'));
		// const startD = moment(startDate).startOf('day');
		// const endD = moment(endDate).startOf('day');
		const startD = moment(startDate);
		const endD = moment(endDate);
		if (!endD.isValid() || !startD.isValid()) {
			return;
		}
		if (storeGranularity && pickerStorage) {
			setGranularity(storeGranularity);
			changeRangeDate();
		} else {
			let nbDays = moment.duration(endD.diff(startD)).asDays() + 1;
			if (nbDays >= 90) {
				setGranularity('month');
				changeRangeDate();
			}
			else if (nbDays >= 29) {
				setGranularity('week');
				changeRangeDate();
			}
			else {
				setGranularity('day');
				changeRangeDate();
			}
		}
	};
	useEffect(() => {
		const timer = setTimeout(() => {
			changeGranularity();
		}, 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [startDate, endDate]);

	const isOutsideRange = (date) => {
		if (date.isBefore(moment(dataObjectiveCampaign.startDate))) {
			return true;
		}
		if (dataObjectiveCampaign.endDate && (moment(dataObjectiveCampaign.endDate) > today ? date.isAfter(today) : date.isAfter(dataObjectiveCampaign.endDate))) {
			return true;
		}
	};

	const fetchAllCampaignsWithMainData = () => {
		trackEvent('reporting/campaign-viewed');
		loadCampaignFunction(reportingCampagneServices)(accountId,
			success => {

				let cpgs = [];
				if (isAdmin) {
					cpgs = success;
				} else {
					for (let i = 0; i < success.length; i++) {
						const cpg = success[i];
						if (cpg.DefaultPermissions && cpg.DefaultPermissions.CAMPAIGNS_CAN_VIEW) {
							cpgs.push(cpg);
						}
					}
				}

				setListOfCampaignsAccount(cpgs);
				setTestModeIpAdressesOfCurrentCampaign(cpgs.find(c => c.Key === campaignId).TestModeIpAddresses);
			},
			error => {
				systemServices.showError('an error occurred');
			});
	};
	useEffect(() => {
		fetchAllCampaignsWithMainData();
	}, []);

	const handleOnClickCampaignIdToLoad = (id) => {
		setCampaignId(id);
		$location.search('id', id);
		$location.search('dimension', 'onSite');
		setactiveLink('onSite');
	};
	const controllerInformationData = new AbortController();
	const signalInformationData = controllerInformationData.signal;

	useEffect(() => {
		loadInformationForCampaignWhenChangeId();
		setHasVariations(false);
		getCampaignInformationsType();
		getCampaignHasForm();
		return () => {
			if (controllerInformationData) {
				controllerInformationData.abort();
			}
		};
	}, [campaignId, initialDates,]);
	const getCampaignInformationsType = () => {
		reportingCampagneServices.getCampaignInformationsType(accountId, campaignId,
			success => {
				const types = {
					onSite: true,
					sendPromoCode: success.hasSendPromoCode,
					sendVisitorCart: success.hasSendVisitorCart,
					stockAlert: success.hasStockAlert
				};
				settypesConfigOfCampaignSelected(types);
			},
			error => {
				systemServices.showError('An error occurred while fetching campaign reporting');
			});
	};
	const getCampaignHasForm = () => {
		reportingCampagneServices.getCampaignHasForm(accountId, campaignId,
			success => {
				if (success.anyAnswersExist) {
					setcampaignHasForm(success);
				} else {
					setcampaignHasForm();
				}
			},
			error => {
				systemServices.showError('An error occurred while fetching campaign forms');
			});
	};

	const loadInformationForCampaignWhenChangeId = () => {
		setIsLoadingInformationsCampaign(true);
		reportingCampagneServices.getCampaignInformations(accountId, campaignId,
			newData => {
				setDataObjectiveCampaign(newData);
				const chooseTheDeviceToGetFirst = () => {
					let deviceToSelect = '';
					if (newData.devices.length !== 1) {
						deviceToSelect = 'all';
					} else {
						if (newData.devices.includes('mobile')) {
							deviceToSelect = 'mobile';
						} else {
							deviceToSelect = 'desktop';
						}
					} return deviceToSelect;
				};
				setDevice(FilterDevices[chooseTheDeviceToGetFirst()]);
				setDataInteractionIndicators(newData.interactionIndicators);
				setDataPrimaryIndicators(newData.primaryIndicators);
				setDataSecondaryIndicators(newData.secondaryIndicators);
				setSummaryFirstIndicators(newData.primaryIndicators.length > 0 ? newData.primaryIndicators[0].key : '');
				setInteractionsIndicators(newData.indicators.filter(item => item.isInteractionIndicator === true));
				setTargetIndicators(newData.indicators.filter(item => item.isTargetIndicator === true));

				if (newData.allVariations.length !== 0) {
					setHasVariations(true);
					const arraySelectOfVariations = [];
					arraySelectOfVariations.push({
						value: 'all',
						label: 'All'
					});
					arraySelectOfVariations.push({
						value: 'ORIGINAL',
						label: 'Original A'
					});
					const labelMapping = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

					for (var i = 0; i < newData.allVariations.length; i++) {
						const newVar = newData.allVariations[i];

						let variationLabel = 'Variation ' + labelMapping[i] || 'Variation X';
						if (newData.slides.length > 1) {
							arraySelectOfVariations.push({
								value: newVar.key,
								label: variationLabel + ' - ' + newVar.slideName
							});
						} else {
							arraySelectOfVariations.push({
								value: newVar.key,
								label: variationLabel
							});
						}
					}

					setVariationSelected(arraySelectOfVariations[0].value);
					setVariationSelectedLabel(arraySelectOfVariations[0].label);
					setArrayOfVariations(arraySelectOfVariations);
				}

				// if (newData.allVariations.length !== 0) {
				// 	setHasVariations(true);
				// 	const arraySelectOfVariations = [];
				// 	arraySelectOfVariations.push({
				// 		value: 'all',
				// 		label: 'All'
				// 	});
				// 	arraySelectOfVariations.push({
				// 		value: 'ORIGINAL',
				// 		label: 'Original A'
				// 	});
				// 	let variationsSorted = newData.allVariations.sort((v1, v2) => v1.variation.localeCompare(v2.variation));
				// 	console.log("=>(ReportingCampaign.js:408) variationsSorted", variationsSorted);
				// 	// let variationsSorted = newData.allVariations.sort((v1, v2) => v1.groupName.localeCompare(v2.groupName));
				//
				// 	for (var i = 0; i < variationsSorted.length; i++) {
				// 		const newVar = variationsSorted[i];
				// 		console.log("=>(ReportingCampaign.js:413) newVar variationsSorted", newVar);
				// 		let variationLabel = 'Variation ' + ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'][i];
				// 		if (newData.slides.length > 1) {
				// 			arraySelectOfVariations.push({
				// 				value: newVar.key,
				// 				label: variationLabel + ' - ' + newVar.slideName
				// 			});
				// 		} else {
				// 			arraySelectOfVariations.push({
				// 				value: newVar.key,
				// 				label: variationLabel
				// 			});
				// 		}
				// 	}
				// 	setVariationSelected(arraySelectOfVariations[0].value);
				// 	setVariationSelectedLabel(arraySelectOfVariations[0].label);
				// 	setArrayOfVariations(arraySelectOfVariations);
				// }
				const storeGranularity = JSON.parse(sessionStorage.getItem('reportingGranularity'));
				const pickerStorage = JSON.parse(sessionStorage.getItem('reportingRangeDatePicker'));
				if (pickerStorage !== null) {
					const labelInit = findNavLabel(pickerStorage.startDate, pickerStorage.endDate);
					const fromBeginningStorage = JSON.parse(sessionStorage.getItem('fromBeginning'));

					if (fromBeginningStorage && newData) {
						setdateRange({
							startDate: moment(newData.startDate),
							endDate: today,
							initialLabel: findNavLabel(moment(newData.startDate), today)
						});
					} else {
						setdateRange({
							startDate: moment(pickerStorage.startDate),
							endDate: moment(pickerStorage.endDate),
							initialLabel: labelInit
						});
					}
				} else {
					setdateRange({
						startDate: moment(initialDates.initialFrom),
						endDate: moment(initialDates.initialTo),
						initialLabel: findNavLabel(initialDates.initialFrom, initialDates.initialTo)
					});
				}
				setIsLoadingInformationsCampaign(false);
			}, errorMessage => {
				systemServices.showError(errorMessage);
			}, signalInformationData);
	};

	// fetch first all informations of campaign
	const loadDataInformationsCampaign = () => {
		setIsLoadingInformationsCampaign(true);
		reportingCampagneServices.getCampaignInformations(accountId, campaignId,
			newData => {
				setDataObjectiveCampaign(newData);
				const chooseTheDeviceToGetFirst = () => {
					let deviceToSelect = '';
					if (newData.devices.length !== 1) {
						deviceToSelect = 'all';
					} else {
						if (newData.devices.includes('mobile')) {
							deviceToSelect = 'mobile';
						} else {
							deviceToSelect = 'desktop';
						}
					} return deviceToSelect;
				};
				setDevice(FilterDevices[chooseTheDeviceToGetFirst()]);
				setDataInteractionIndicators(newData.interactionIndicators);
				setDataPrimaryIndicators(newData.primaryIndicators);
				setDataSecondaryIndicators(newData.secondaryIndicators);
				setSummaryFirstIndicators(newData.primaryIndicators.length > 0 ? newData.primaryIndicators[0].key : '');
				setInteractionsIndicators(newData.indicators.filter(item => item.isInteractionIndicator === true));
				setTargetIndicators(newData.indicators.filter(item => item.isTargetIndicator === true));
				if (newData.allVariations.length !== 0) {
					setHasVariations(true);
					const arraySelectOfVariations = [];
					arraySelectOfVariations.push({
						value: 'all',
						label: 'All'
					});
					arraySelectOfVariations.push({
						value: 'ORIGINAL',
						label: 'Original A'
					});
					let variationsSorted = newData.allVariations.sort((v1, v2) => v1.variation.localeCompare(v2.variation));
					for (var i = 0; i < variationsSorted.length; i++) {
						const newVar = variationsSorted[i];
						let variationLabel = 'Variation ' + ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'][i];
						if (newData.slides.length > 1) {
							arraySelectOfVariations.push({
								value: newVar.key,
								label: variationLabel + ' - ' + newVar.slideName
							});
						} else {
							arraySelectOfVariations.push({
								value: newVar.key,
								label: variationLabel
							});
						}
					}
					if (!variationSelected) {
						setVariationSelected(arraySelectOfVariations[0].value);
						setVariationSelectedLabel(arraySelectOfVariations[0].label);
						setArrayOfVariations(arraySelectOfVariations);
					}
				}
				if (!variationSelected) {

					const storeGranularity = JSON.parse(sessionStorage.getItem('reportingGranularity'));
					const pickerStorage = JSON.parse(sessionStorage.getItem('reportingRangeDatePicker'));
					if (pickerStorage !== null) {
						const labelInit = findNavLabel(pickerStorage.startDate, pickerStorage.endDate);
						const fromBeginningStorage = JSON.parse(sessionStorage.getItem('fromBeginning'));
						if (fromBeginningStorage && newData) {
							setdateRange({
								startDate: moment(newData.startDate),
								endDate: today,
								initialLabel: findNavLabel(moment(newData.startDate), today)
							});
						} else {
							setdateRange({
								startDate: moment(pickerStorage.startDate),
								endDate: moment(pickerStorage.endDate),
								initialLabel: labelInit
							});
						}
					} else {
						const labelInit = findNavLabel(initialDates.fromDate, initialDates.toDate);
						setdateRange({
							startDate: moment(initialDates.initialFrom),
							endDate: moment(initialDates.initialTo),
							initialLabel: labelInit
						});
					}
				}
				setIsLoadingInformationsCampaign(false);
			}, errorMessage => {
				systemServices.showError(errorMessage);
			}, signalInformationData);
	};

	const constructArrayOfIndicators = () => {
		if (dataObjectiveCampaign.indicators) {
			const arr = dataObjectiveCampaign.indicators.map(element => element.key);
			setNewArrayOfIndicators(arr);
		}
	};
	// run function when the dataobjectiveCampaign change
	useEffect(() => {
		if (dataObjectiveCampaign) {
			constructArrayOfIndicators();
		}
	}, [dataObjectiveCampaign]);

	const changeDevice = (filter) => {
		if (filter !== device) {
			setDevice(filter);
		}
	};

	const handleChange = (e, item) => {
		setVariationSelected(item.value);
		setVariationSelectedLabel(item.label);
	};

	let searchStorageTimeout;
	const handleChangeCampaigns = (value) => {
		setValueInSearchBar(value);
		if (searchStorageTimeout) clearTimeout(searchStorageTimeout);
		searchStorageTimeout = setTimeout(() => {
			sessionStorage.setItem(
				'onSiteSearchFilter',
				JSON.stringify(value)
			);
		}, 1000);
	};

	useEffect(() => {
		if (listOfCampaignsAccount) {
			if (filteredType) {
				if (filteredType === 'ongoing') {
					search(listOfCampaignsAccount.filter(el => el.Status === 'ongoing'), valueInSearchBar);
				}
				if (filteredType === 'past') {
					search(listOfCampaignsAccount.filter(el => el.Status === 'past'), valueInSearchBar);
				}
				if (filteredType === 'stopped') {
					search(listOfCampaignsAccount.filter(el => el.Status === 'stopped'), valueInSearchBar);
				}
			} else {
				search(listOfCampaignsAccount, valueInSearchBar);
			}
		}
	}, [valueInSearchBar, listOfCampaignsAccount, filteredType]);

	useEffect(() => {
		const searchStorage = JSON.parse(
			sessionStorage.getItem('onSiteSearchFilter')
		);
		if (searchStorage) {
			setValueInSearchBar(searchStorage);
		}
	}, []);

	const search = (table, word) => {
		let newData = [];

		if (!word && !filteredType) {
			newData = listOfCampaignsAccount;
		} else if (word) {
			word = word.trim();
			let resultName = table.filter(line => {
				if (line.Key !== null && line.Key.toUpperCase().includes(word.toUpperCase())) return true;
				if (line.Name !== null && line.Name.toUpperCase().includes(word.toUpperCase())) return true;
				return false;
			});
			let finalArray = [...resultName];
			let uniqueItem = [...new Set(finalArray)];
			newData = uniqueItem;
		} else {
			newData = table;
		}

		setNoData(newData.length == 0 ? 'No campaigns matches with this search' : '');
		setDataFiltered(newData);
	};

	const changeActiveLink = (elem) => {
		$location.search('dimension', elem);
		setactiveLink(elem);
	};

	function sortByLastUpdate(a, b) {
		if (a && b) {
			if (moment(a.UpdateDate) > moment(b.UpdateDate)) return -1;
			if (moment(a.UpdateDate) < moment(b.UpdateDate)) return 1;
		}
		return 0;
	}
	const changeGranularityManually = (granularityToSave) => {
		const newGranularity = granularityToSave;
		sessionStorage.setItem('reportingGranularity', JSON.stringify(newGranularity));
		changeRangeDate();
		setFrequencyDropdownIsOpen(false);
		setGranularity(granularityToSave);
	};
	const controllerCampaignConversion = new AbortController();
	const signalCampaignConversion = controllerCampaignConversion.signal;
	function handleGetCampaignConversion() {
		reportingCampagneServices.getCampaignConversion(
			accountId, campaignId, startDate,
			endDate, success => {
				const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(success);
				const link = document.createElement('a');
				link.setAttribute('href', csvContent);
				link.setAttribute('download', 'transactions.csv');
				document.body.appendChild(link);
				link.click();

			}, error => { }, signalCampaignConversion

		);
	}

	return (
		<I18nextProvider i18n={newInstanceI18next}>
			<ReportingCampaignProvider
				systemServices={systemServices}
				reportingCampagneServices={reportingCampagneServices}
				campaignId={campaignId}
				accountId={accountId}
				isAdmin={isAdmin}
			>
				<div className="page_full hide_subaccount_nav section_root flex flex_v">
					<div className="flex_item_full flex flex_align_stretch overflow_hidden">
						<SearchCampaign
							filteredType={filteredType}
							setFilteredType={setFilteredType}
							noData={noData}
							valueInSearchBar={valueInSearchBar}
							dataFiltered={dataFiltered}
							sortByLastUpdate={sortByLastUpdate}
							handleChangeCampaigns={handleChangeCampaigns}
							campaignId={campaignId}
							handleOnClickCampaignIdToLoad={handleOnClickCampaignIdToLoad}
							setTestModeIpAdressesOfCurrentCampaign={setTestModeIpAdressesOfCurrentCampaign}
							isAdmin={isAdmin}
							routeParams={$routeParams}
							navReporting={navReporting}
							shouldDisplayStatus={shouldDisplayStatus}
						/>
						<div className="flex_item_full vscroll">
							<div className="page_center pos_rel">
								<section className="section xl">
									<InformationsOnSiteCampaign
										isLoading={isLoadingInformationsCampaign}
										datainformation={dataObjectiveCampaign && dataObjectiveCampaign}
										systemServices={systemServices}
										AuthServices={AuthServices}
										reportingCampagneServices={reportingCampagneServices}
										accountId={accountId}
										$http={$http}
										ReportingCampaignServices={ReportingCampaignServices}
										campaignId={campaignId}
										loadInformationForCampaignWhenChangeId={() => { loadInformationForCampaignWhenChangeId(); fetchAllCampaignsWithMainData(); }}
										setIsLoadingInformationsCampaign={(e) => setIsLoadingInformationsCampaign(e)}
										routeParams={$routeParams}
										TestModeIpAddressesOfCurrentCampaign={TestModeIpAddressesOfCurrentCampaign}
										getEditor={getEditor}
										shouldDisplayEditorLink={shouldDisplayEditorLink}
										shouldDisplayStatus={shouldDisplayStatus}
										shouldDisplayDates={shouldDisplayDates}
									/>
									<NavigationReporting
										dimensions={typesConfigOfCampaignSelected}
										form={campaignHasForm}
										activeLink={activeLink}
										setActiveLink={(e) => changeActiveLink(e)}
									/>
									<Article innerSize='l'>
										<div className="pos_rel">

											<OnSiteContainer
												handleGetCampaignConversion={handleGetCampaignConversion}
												isAdmin={isAdmin}
												$http={$http}
												datainformation={dataObjectiveCampaign && dataObjectiveCampaign}
												changeDevice={changeDevice}
												loadAllInformationCampaign={loadAllInformationCampaign}
												loadSecondIndicator={loadSecondIndicator}
												device={device}
												dataObjectiveCampaign={dataObjectiveCampaign}
												hasVariations={hasVariations}
												abtestDropdownIsOpen={abtestDropdownIsOpen}
												setAbtestDropdownIsOpen={(e) => setAbtestDropdownIsOpen(e)}
												variationSelectedLabel={variationSelectedLabel}
												arrayOfVariations={arrayOfVariations}
												handleChange={(e, x) => handleChange(e, x)}
												frequencyDropdownIsOpen={frequencyDropdownIsOpen}
												setFrequencyDropdownIsOpen={(e) => setFrequencyDropdownIsOpen(e)}
												granularity={granularity}
												changeGranularityManually={(e) => changeGranularityManually(e)}
												ResultsCampaignContainer={ResultsCampaignContainer}
												loadIndicators={getIndicators}
												updateInteractionIndicator={updateInteractionIndicator}
												systemServices={systemServices}
												dataInteractionIndicators={dataInteractionIndicators && dataInteractionIndicators}
												setDataInteractionIndicators={(e) => setDataInteractionIndicators(e)}
												interactionsIndicators={interactionsIndicators && interactionsIndicators}
												momentStartDate={momentStartDate}
												momentEndDate={momentEndDate}
												isCustomSelected={isCustomSelected}
												setisCustomSelected={setisCustomSelected}
												customDateRange={customDateRange}
												setCustomDateRange={setCustomDateRange}
												initialLabel={initialLabel}
												startDate={startDate}
												endDate={endDate}
												loadDataInformationsCampaign={loadDataInformationsCampaign}
												changeRangeDate={changeRangeDate}
												isOutsideRange={isOutsideRange}
												handleOnDateChange={handleOnDateChange}
												changeGranularity={changeGranularity}

												variationSelected={variationSelected}
												newArrayOfIndicators={newArrayOfIndicators}
												campaignId={campaignId}
												searchIndicators={searchIndicators}
												getAllSecondaryIndicators={getAllSecondaryIndicators}
												updateSummaryIndicators={updateSummaryIndicators}
												SummaryFirstIndicators={summaryFirstIndicators}
												DataPrimaryIndicators={DataPrimaryIndicators}
												DataSecondaryIndicators={DataSecondaryIndicators}
												accountId={accountId}
												targetIndicators={targetIndicators}
												isLoadingInformationsCampaign={isLoadingInformationsCampaign}
												activeLink={activeLink}
											/>
											
											{activeLink === 'formsAndSurveys' && campaignHasForm && campaignHasForm.anyAnswersExist &&
												<FormCustomReportContainerHooks
													campaignId={campaignId}
													accountId={accountId}
													startD={campaignHasForm.minDate}
													endD={campaignHasForm.maxDate}
													isLoading={isLoadingInformationsCampaign}
													propS={{
														$http: $http,
														$rootScope: $rootScope,
														$routeParams: $routeParams
													}}
												/>
											}
											{activeLink === 'stockAlert' &&
												<MailStatsContainer
													campaignId={campaignId}
													accountId={accountId}
													frequencyDropdownIsOpen={frequencyDropdownIsOpen}
													setFrequencyDropdownIsOpen={(e) => setFrequencyDropdownIsOpen(e)}
													granularity={granularity}
													changeGranularityManually={(e) => changeGranularityManually(e)}
													reportingCampagneServices={reportingCampagneServices}
													momentStartDate={momentStartDate}
													momentEndDate={momentEndDate}
													systemServices={systemServices}
													statsType={'stockalert'}
													dataObjectiveCampaign={dataObjectiveCampaign}
													activeLink={activeLink}
													startDate={startDate}
													endDate={endDate}
													loadDataInformationsCampaign={loadDataInformationsCampaign}
													changeRangeDate={changeRangeDate}
													isOutsideRange={isOutsideRange}
													handleOnDateChange={handleOnDateChange}
													changeGranularity={changeGranularity}
												/>
											}
											{activeLink === 'sendVisitorCart' &&
												<MailStatsContainer
													campaignId={campaignId}
													accountId={accountId}
													frequencyDropdownIsOpen={frequencyDropdownIsOpen}
													setFrequencyDropdownIsOpen={(e) => setFrequencyDropdownIsOpen(e)}
													granularity={granularity}
													changeGranularityManually={(e) => changeGranularityManually(e)}
													reportingCampagneServices={reportingCampagneServices}
													momentStartDate={momentStartDate}
													momentEndDate={momentEndDate}
													systemServices={systemServices}
													statsType={'sendvisitorCart'}
													dataObjectiveCampaign={dataObjectiveCampaign}
													activeLink={activeLink}
													startDate={startDate}
													endDate={endDate}
													loadDataInformationsCampaign={loadDataInformationsCampaign}
													changeRangeDate={changeRangeDate}
													isOutsideRange={isOutsideRange}
													handleOnDateChange={handleOnDateChange}
													changeGranularity={changeGranularity}
												/>
											}
											{activeLink === 'sendPromoCode' &&
												<MailStatsContainer
													campaignId={campaignId}
													accountId={accountId}
													frequencyDropdownIsOpen={frequencyDropdownIsOpen}
													setFrequencyDropdownIsOpen={(e) => setFrequencyDropdownIsOpen(e)}
													granularity={granularity}
													changeGranularityManually={(e) => changeGranularityManually(e)}
													reportingCampagneServices={reportingCampagneServices}
													momentStartDate={momentStartDate}
													momentEndDate={momentEndDate}
													systemServices={systemServices}
													statsType={'sendpromocode'}
													dataObjectiveCampaign={dataObjectiveCampaign}
													startDate={startDate}
													endDate={endDate}
													loadDataInformationsCampaign={loadDataInformationsCampaign}
													changeRangeDate={changeRangeDate}
													isOutsideRange={isOutsideRange}
													handleOnDateChange={handleOnDateChange}
													changeGranularity={changeGranularity}
													activeLink={activeLink}
												/>
											}
										</div>
									</Article>
								</section>
							</div>

						</div>
					</div>
				</div>
			</ReportingCampaignProvider>
		</I18nextProvider>

	);
}