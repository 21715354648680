import { EmailProvider, EmailProviderConfiguration, EmailTemplateStatus } from '../../../../Services/EmailTemplateServicesTypes';
import { Template, TemplateDescriptor, TemplateProperty } from '../../../WysiwygEditor/Types/BespokeTypes';

export type EmailTemplateObject = {
	//SlideFormat: string;
	Id: string | null;
	Name: string;
	Description: string | null;
	DesignProperties: [];
	Properties: TemplateProperty[];
	TemplateType: string;
	TemplateName: string;
	TemplateDescriptorDesktop: TemplateDescriptor;
	TemplateDescriptorMobile: TemplateDescriptor;
	SavedStyle: string;
	Template: Template | null;
	Status: EmailTemplateStatus;
	PersonalisationParameterName: string | null;
	ProviderConfiguration: EmailProviderConfiguration | null;
}

export const emptyTemplate: EmailTemplateObject = {
	//SlideFormat: '',
	Id: null,
	Name: 'New personalized email',
	Description: '',
	PersonalisationParameterName: null,
	DesignProperties: [],
	Properties: [],
	TemplateType: '',
	TemplateName: '',
	TemplateDescriptorDesktop: {
		Css: '',
		EditableCss: '',
		EditableTemplate: {
			ContentTemplateType: 'Fluid',
			Content: '',
		},
	},
	TemplateDescriptorMobile: {
		Css: '',
		EditableCss: '',
		EditableTemplate: {
			ContentTemplateType: 'Fluid',
			Content: '',
		},
	},
	SavedStyle: '',
	Template: null,
	Status: EmailTemplateStatus.Inactive,
	ProviderConfiguration: {
		emailProvider: EmailProvider.Brevo
	}
};
