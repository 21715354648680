import React, { useState, useEffect, Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import indicators, { impressionIndicator, nbOfClicksIndicator } from '../ReportingCampaign/Indicators';
import IndicatorsButtonComponent from '../ReportingCampaign/IndicatorsButtonComponent';
import { cleanInteractionLabel } from './IndicatorsUtil';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
import { Trans, useTranslation } from 'react-i18next';

export default function ChartPerformCampaign(props) {
	const [t, i18n] = useTranslation('common');
	const [firstInteractionIndicator, setFirstInteractionIndicator] = useState();
	const [valueFirstInteraction, setValueFirstInteraction] = useState();
	const [secondInteractionIndicator, setSecondInteractionIndicator] = useState();
	const [valueSecondInteraction, setValueSecondInteraction] = useState();
	const [rateSecondInteractionIndicator, setRateSecondInteractionIndicator] = useState();
	const [dayPeriode, setdayPeriode] = useState([]);
	const [dataClicksIndicator, setdataClicksIndicator] = useState();
	const [valueClicksIndicator, setvalueClicksIndicator] = useState();
	const [labelClicksIndicator, setlabelClicksIndicator] = useState();

	const [firstLabelInteractionIndicator, setFirstLabelInteractionIndicator] = useState();
	const [secondLabelInteractionIndicator, setSecondLabelInteractionIndicator] = useState();
	const [indicatorsForChart, setIndicatorsForChart] = useState();
	const [isOnCharg, setisOnCharg] = useState(false);
	const [noData, setNoData] = useState(false);
	const [warningData, setWarningData] = useState();

	const updateNewIndicators = (newKey) => {
		if (!indicatorsForChart)
			return;
		let newIndicatorsForChart = indicatorsForChart.filter(item => item.key === impressionIndicator);
		newIndicatorsForChart.push({ key: newKey });
		setIndicatorsForChart(newIndicatorsForChart);
		props.setDataInteractionIndicators(newIndicatorsForChart);
	};
	// const checkIfCampaignHasInteractionsSecondaryIndicators = (Data) => {
	//     if (props.interactionsIndicators.length === 0) {
	//         getFirstIndicatorOnly(Data)
	//     } else {
	//         loadDataSecondIndicator()
	//     }
	// }

	const loadDataFirstIndicator = () => {
		if (props.allIndicatorsofCampaign && props.allIndicatorsofCampaign.length > 0) {
			setNoData(false);
			loadDataWithClicksIndicators();
		} else {
			setNoData(true);
		}
	};
	const loadDataWithClicksIndicators = () => {
		let nbClicksIndicator = props.allIndicatorsofCampaign.find(item => item === nbOfClicksIndicator);
		let dataClicks = [];
		function getFirstIndicator(hasClicks) {
			//let firstIndicator = props.allIndicatorsofCampaign.find(item => item === impressionIndicator);
			props.loadIndicators(impressionIndicator, props.device && props.device,
				data => {
					setFirstInteractionIndicator(data[0]);
					if (props.interactionsIndicators.length === 0) {
						if (hasClicks) {
							getFirstIndicatorAndClicksIndicators(data[0], dataClicks);
						} else {
							getFirstIndicatorOnly(data[0]);
						}
					} else {
						loadDataSecondIndicator();
					}
				}, errorMessage => {
					props.systemServices.showError(errorMessage);
				},
			);
		}
		if (nbClicksIndicator) {
			props.loadIndicators(nbClicksIndicator, props.device && props.device,
				data => {
					dataClicks = data[0];
					getFirstIndicator(true);
					setdataClicksIndicator(data[0]);
				}, errorMessage => {
					getFirstIndicator(false);
					props.systemServices.showError(errorMessage);
				},
			);
		} else {
			getFirstIndicator(false);
		}
	};

	const loadDataSecondIndicator = () => {
		if (props.dataInteractionIndicators && props.dataInteractionIndicators.length > 0) {
			setisOnCharg(true);
			let secondIndicator = props.dataInteractionIndicators.filter(item => item.key !== impressionIndicator)[0].key;
			props.dataInteractionIndicators && props.loadIndicators(secondIndicator, props.device && props.device,
				data => {
					setSecondInteractionIndicator(data[0]);
				}, errorMessage => {
					props.systemServices.showError(errorMessage);
					setisOnCharg(false);
				},
			);
		} else if (props.activeInteraction === undefined && props.interactionsIndicators.length !== 0) {
			setisOnCharg(true);
			let secondIndicator = props.interactionsIndicators.filter(item => item.key !== impressionIndicator)[0].key;
			if (!props.dataInteractionIndicators || !secondIndicator) setisOnCharg(false);
			props.dataInteractionIndicators && secondIndicator && props.loadIndicators(secondIndicator, props.device && props.device,
				data => {
					setSecondInteractionIndicator(data[0]);
					let interaction = { Keys: [impressionIndicator, secondIndicator] };
					props.updateInteractionIndicator(interaction);
					let secondLabel = props.interactionsIndicators.filter(item => item.key !== impressionIndicator)[0].key;
					if (secondLabel) {
						for (var key in indicators) {
							if (secondLabel === key.key) {
								secondLabel = key;
							}
						}
						if (typeof secondLabel === 'string') {
							secondLabel = {
								label: secondLabel,
								rateLabel: secondLabel + ' ' + '%'
							};
						}
						setSecondLabelInteractionIndicator(secondLabel);
					}
				}, errorMessage => {
					props.systemServices.showError(errorMessage);
					setisOnCharg(false);
				},
			);
		}
	};

	useEffect(() => {
		if (props.momentEndDate && props.dataInteractionIndicators) {
			loadDataFirstIndicator();
			setisOnCharg(true);
			if (props.interactionsIndicators.length === 0) {
				setIndicatorsForChart([props.allIndicatorsofCampaign.find(item => item === impressionIndicator)]);
			} else {
				setIndicatorsForChart(props.dataInteractionIndicators);
			}
		}
	}, [props.momentEndDate, props.dataInteractionIndicators, props.device, props.variationSelected]);

	const loadLabels = () => {
		if (indicatorsForChart) {
			let secondLabel = indicatorsForChart.length && indicatorsForChart.filter(item => item.key !== impressionIndicator)[0].key;
			if (secondLabel) {
				for (var key in indicators) {
					if (secondLabel === key.key) {
						secondLabel = key;
					}
				}
				if (typeof secondLabel === 'string') {
					secondLabel = {
						label: secondLabel,
						rateLabel: secondLabel + ' ' + '%'
					};
				}
				setSecondLabelInteractionIndicator(secondLabel);
			}
		}
	};
	const checkDataRangeDate = () => {
		setWarningData();
		if (props.granularity === 'week') {
			const startDateToCheck = moment(props.momentStartDate).startOf('day');
			const endDateToCheck = moment(props.momentEndDate).startOf('day');
			let dayWeekStart = startDateToCheck.weekday();
			let dayWeekEnd = endDateToCheck.weekday();
			let warning = [];
			if (dayWeekStart && dayWeekStart >= 1) {
				warning.push(
					t('ChartPerformCampaign.incomplete',
						{
							time: 'week',
							timeFr: 'semaine',
						}));
			}
			if (dayWeekEnd && dayWeekEnd !== 6) {
				warning.push(t('ChartPerformCampaign.incomplete',
					{
						time: 'week',
						timeFr: 'semaine',
					}));
			}
			if (warning.length !== 0) {
				setWarningData(warning);
			}
		}
		if (props.granularity === 'month') {
			const startDateToCheck = moment(props.momentStartDate).startOf('day');
			const endDateToCheck = moment(props.momentEndDate).startOf('day');
			let firstDayOfMonth = moment(startDateToCheck).startOf('month').startOf('day');
			let lastDayOfMonth = moment(endDateToCheck).endOf('month').startOf('day');
			let warning = [];
			if (!startDateToCheck.isSame(firstDayOfMonth)) {
				warning.push(warning.push(t('ChartPerformCampaign.incompleteLast',
					{
						time: 'month',
						timeFr: 'mois',
					})
				));
			}
			if (!endDateToCheck.isSame(lastDayOfMonth)) {
				warning.push(t('ChartPerformCampaign.incompleteLast',
					{
						time: 'month',
						timeFr: 'mois',
					}));
			}
			if (warning.length !== 0) {
				setWarningData(warning);
			}
		}
	};
	const getFirstIndicatorAndClicksIndicators = (dataFirstIndicator, dataClicksIndicator) => {
		if (dataFirstIndicator && dataClicksIndicator) {
			// for the firstIndicator
			setvalueClicksIndicator();
			const datesRange = dataFirstIndicator.dateRange.map(x => x);
			const dateLocal = dataFirstIndicator.dateRange.map(x => moment(x).format('D MMM'));
			checkDataRangeDate();
			let firstIndicatorseriesToMap;
			if (props.hasVariations) {
				firstIndicatorseriesToMap = dataFirstIndicator.expandedSeries.find(serie => serie.key.variation === props.variationSelected);
			} else {
				firstIndicatorseriesToMap = dataFirstIndicator;
			}
			const valuesFirstIndicator = datesRange.map(element => {
				if (!firstIndicatorseriesToMap) {
					setNoData(true);
				} else {
					const elementWithData = firstIndicatorseriesToMap.series.find(d => d.date === element);
					if (elementWithData) {
						return elementWithData.valueDisplayedAbsolute;
					} else {
						return 0;
					}
				}
			});
			const newLabel = indicators.filter(item => item.key === impressionIndicator);
			setdayPeriode(dateLocal);
			setValueFirstInteraction(valuesFirstIndicator);
			setFirstLabelInteractionIndicator(newLabel);

			// for the secondIndicator

			let clicksIndicatorseriesToMap;
			if (props.hasVariations) {
				clicksIndicatorseriesToMap = dataClicksIndicator.expandedSeries.find(serie => serie.key.variation === props.variationSelected);
			} else {
				clicksIndicatorseriesToMap = dataClicksIndicator;
			}
			const valuesClicksIndicator = datesRange.map(element => {
				if (!clicksIndicatorseriesToMap) {
					//    setNoData(true)
				} else {
					const elementWithData = clicksIndicatorseriesToMap.series.find(d => d.date === element);
					if (elementWithData) {
						return elementWithData.valueDisplayedAbsolute;
					} else {
						return 0;
					}
				}
			});
			const newLabelToClicks = indicators.filter(item => item.key === nbOfClicksIndicator);
			setvalueClicksIndicator(valuesClicksIndicator);
			setlabelClicksIndicator(newLabelToClicks);
			setisOnCharg(false);
		}
	};

	const getFirstIndicatorOnly = (Data) => {
		if (Data) {
			const datesRange = Data.dateRange.map(x => x);
			const dateLocal = Data.dateRange.map(x => moment(x).format('D MMM'));
			checkDataRangeDate();
			let firstIndicatorseriesToMap;
			if (props.hasVariations) {
				firstIndicatorseriesToMap = Data.expandedSeries.find(serie => serie.key.variation === props.variationSelected);
			} else {
				firstIndicatorseriesToMap = Data;
			}
			const valuesFirstIndicator = datesRange.map(element => {
				if (!firstIndicatorseriesToMap) {
					setNoData(true);
				} else {
					const elementWithData = firstIndicatorseriesToMap.series.find(d => d.date === element);
					if (elementWithData) {
						return elementWithData.valueDisplayedAbsolute;
					} else {
						return 0;
					}
				}
			});
			const newLabel = indicators.filter(item => item.key === impressionIndicator);
			setdayPeriode(dateLocal);
			setValueFirstInteraction(valuesFirstIndicator);
			setFirstLabelInteractionIndicator(newLabel);
			setisOnCharg(false);
			setvalueClicksIndicator();
		}
	};
	// Modify values of values interactions 
	let valuesOnChart = () => {
		if (firstInteractionIndicator && secondInteractionIndicator) {
			const datesRange = firstInteractionIndicator.dateRange.map(x => x);
			const dateLocal = firstInteractionIndicator.dateRange.map(x => moment(x).format('D MMM'));
			checkDataRangeDate();
			let firstIndicatorseriesToMap;
			if (props.hasVariations) {
				firstIndicatorseriesToMap = firstInteractionIndicator.expandedSeries.find(serie => serie.key.variation === props.variationSelected);
			} else {
				firstIndicatorseriesToMap = firstInteractionIndicator;
			}
			const valuesFirstIndicator = datesRange.map(element => {
				if (!firstIndicatorseriesToMap) {
					setNoData(true);
				} else {
					const elementWithData = firstIndicatorseriesToMap.series.find(d => d.date === element);
					if (elementWithData) {
						return elementWithData.valueDisplayedAbsolute;
					} else {
						return 0;
					}
				}
			});
			let secondIndicatorseriesToMap;
			if (props.hasVariations) {
				secondIndicatorseriesToMap = secondInteractionIndicator.expandedSeries.find(serie => serie.key.variation === props.variationSelected);
			}
			else {
				secondIndicatorseriesToMap = secondInteractionIndicator;
			}
			const valuesSecondIndicator = datesRange.map(element => {
				if (!secondIndicatorseriesToMap) {
					//setNoData(true)
				} else {
					const elementWithData = secondIndicatorseriesToMap.series.find(d => d.date === element);
					if (elementWithData) {
						return elementWithData.valueDisplayedAbsolute;
					} else {
						return 0;
					}
				}
			});
			let secondIndicatorseriesRateToMap;
			if (props.hasVariations) {
				secondIndicatorseriesRateToMap = secondInteractionIndicator.expandedSeries.find(serie => serie.key.variation === props.variationSelected);
			} else {
				secondIndicatorseriesRateToMap = secondInteractionIndicator;
			}
			const valuesSecondIndicatorRate = datesRange.map(element => {
				if (!secondIndicatorseriesToMap) {
					//setNoData(true)
				} else {
					const elementWithData = secondIndicatorseriesRateToMap.series.find(d => d.date === element);
					if (elementWithData) {
						return elementWithData.valueDisplayedRate;
					} else {
						return 0;
					}
				}
			});
			let clicksIndicatorseriesToMap;
			let nbClicksIndicator = props.allIndicatorsofCampaign.find(item => item === nbOfClicksIndicator);
			if (nbClicksIndicator) {
				if (props.hasVariations) {
					clicksIndicatorseriesToMap = dataClicksIndicator.expandedSeries.find(serie => serie.key.variation === props.variationSelected);
				} else {
					clicksIndicatorseriesToMap = dataClicksIndicator;
				}
				const valuesClicksIndicator = datesRange.map(element => {
					if (!clicksIndicatorseriesToMap) {
						//    setNoData(true)
					} else {
						const elementWithData = clicksIndicatorseriesToMap.series.find(d => d.date === element);
						if (elementWithData) {
							return elementWithData.valueDisplayedAbsolute;
						} else {
							return 0;
						}
					}
				});
				const newLabelToClicks = indicators.filter(item => item.key === nbOfClicksIndicator);
				setvalueClicksIndicator(valuesClicksIndicator);
				setlabelClicksIndicator(newLabelToClicks);
			} else {
				setvalueClicksIndicator();
			}
			const newLabel = indicators.filter(item => item.key === impressionIndicator);
			setdayPeriode(dateLocal);
			setValueFirstInteraction(valuesFirstIndicator);
			setValueSecondInteraction(valuesSecondIndicator);
			setRateSecondInteractionIndicator(valuesSecondIndicatorRate);
			setFirstLabelInteractionIndicator(newLabel);
			setisOnCharg(false);
		}
	};

	useEffect(() => {
		if (secondInteractionIndicator) {
			valuesOnChart();
			loadLabels();
		}
	}, [secondInteractionIndicator]);

	const options = {
		chart: {
			height: 350,
			type: 'column',
			zoomType: 'x',
		},
		title: {
			text: '',
			align: 'left',
			marginLeft: 50
		},
		legend: {
			symbolRadius: 0,
			symbolWidth: 40,
			itemStyle: {
				fontFamily: 'IBM Plex Sans',
				fontWeight: 'Light',
				left: '100px',
				top: '100px',
			},
			//backgroundColor: 'rgba(224, 216, 216, 0.185)',
			borderRadius: 3,
		},
		credits: {
			enabled: false
		},
		xAxis: {
			gridLineWidth: 0,
			lineColor: '#9E9E9E',
			lineWidth: 1,
			categories: dayPeriode,
		},
		yAxis: [
			{
				// Primary yAxis
				gridLineWidth: 0,
				labels: {
					format: '{value}%',
					style: {

						color: 'black',
					},
				},
				plotBorderWidth: 1,
				plotBorderColor: '#9E9E9E',
				title: {
					text: secondLabelInteractionIndicator && cleanInteractionLabel(secondLabelInteractionIndicator.rateLabel),
					style: {
						color: 'black',
					}
				},
				opposite: true
			},
			{
				// Secondary yAxis
				gridLineWidth: 0,
				title: {
					text: firstLabelInteractionIndicator && firstLabelInteractionIndicator.length > 0 && firstLabelInteractionIndicator[0].label,
					style: {
						color: 'black'
					}
				},
				labels: {
					style: {
						color: '#293133',
						format: '{value}',
					}
				}
			}
		],
		plotOptions: {
			column: {
				shadow: false,
				//pointWidth: 30
				pointPadding: 0.1,
				groupPadding: 0.1,
			},
			line: {
				shadow: false,
				dashStyle: 'long',
			}
			,
			series: {
				marker: {
					fillColor: '#FFF',
					lineWidth: 1,
					lineColor: null // inherit from series
				}
			},

		},
		opposite: true,
		tooltip: {
			shared: true,
			backgroundColor: '#38444A',
			color: '#FFF',
			style: {
				color: '#FFFFFF',
			},
		},
		series: valueSecondInteraction ?
			valueClicksIndicator ?
				[
					{
						name: firstLabelInteractionIndicator && firstLabelInteractionIndicator.length > 0 && firstLabelInteractionIndicator[0].label,
						data: valueFirstInteraction,
						color: '#4278F0',
						type: 'column',
						yAxis: 1,
					},
					{
						name: secondLabelInteractionIndicator ? cleanInteractionLabel(secondLabelInteractionIndicator.label) : 'no data',
						data: valueSecondInteraction,
						color: '#C6D8FF',
						type: 'column',
						yAxis: 1,
					},
					{
						name: secondLabelInteractionIndicator ? cleanInteractionLabel(secondLabelInteractionIndicator.rateLabel) : 'no data',
						data: rateSecondInteractionIndicator,
						color: '#FCAF45',
						type: 'line',
						yAxis: 0,
						tooltip: {
							valueDecimals: 2,
							valueSuffix: ' %'
						},
					},
					{
						name: labelClicksIndicator ? 'Number of clicks' : 'no data',
						data: valueClicksIndicator,
						color: '#69B472',
						type: 'line',
						yAxis: 0,
					},
				]
				:
				[
					{
						name: firstLabelInteractionIndicator && firstLabelInteractionIndicator.length > 0 && firstLabelInteractionIndicator[0].label,
						data: valueFirstInteraction,
						color: '#4278F0',
						type: 'column',
						yAxis: 1,
					},
					{
						name: secondLabelInteractionIndicator ? cleanInteractionLabel(secondLabelInteractionIndicator.label) : 'no data',
						data: valueSecondInteraction,
						color: '#C6D8FF',
						type: 'column',
						yAxis: 1,
					},
					{
						name: secondLabelInteractionIndicator ? cleanInteractionLabel(secondLabelInteractionIndicator.rateLabel) : 'no data',
						data: rateSecondInteractionIndicator,
						color: '#FCAF45',
						type: 'line',
						yAxis: 0,
						tooltip: {
							valueDecimals: 2,
							valueSuffix: ' %'
						},
					},
				]
			:
			valueClicksIndicator ? [
				{
					name: firstLabelInteractionIndicator && firstLabelInteractionIndicator.length > 0 && firstLabelInteractionIndicator[0].label,
					data: valueFirstInteraction,
					color: '#4278F0',
					type: 'column',
					yAxis: 1,
				},
				{
					name: labelClicksIndicator ? 'Number of clicks' : 'no data',
					data: valueClicksIndicator,
					color: '#69B472',
					type: 'line',
					yAxis: 0,
				}
			] :
				[
					{
						name: firstLabelInteractionIndicator && firstLabelInteractionIndicator.length > 0 && firstLabelInteractionIndicator[0].label,
						data: valueFirstInteraction,
						color: '#4278F0',
						type: 'column',
						yAxis: 1,
					},
				]
	};

	return (
		<Fragment>
			{!noData ?
				<div className="reporting_chart">
					{!isOnCharg &&
						<HighchartsReact
							className='chart'
							highcharts={Highcharts}
							options={options}
							constructorType={'chart'}
						/>
					}
					{isOnCharg &&
						<div className="spinner_overlay">
							<div className="spinner">
								<div className="rect1"></div>
								<div className="rect2"></div>
								<div className="rect3"></div>
								<div className="rect4"></div>
								<div className="rect5"></div>
							</div>
						</div>
					}
				</div>
				:
				<div className="flex  flex_justify_center flex_align_center" style={{ height: '300px', width: '100%' }}>
					<div className="nodata">
						<p className="title">{t('ChartPerformCampaign.NoData')}</p>
					</div>
				</div>
			}
			<IndicatorsButtonComponent
				updateInteractionIndicator={props.updateInteractionIndicator && props.updateInteractionIndicator}
				systemServices={props.systemServices && props.systemServices}
				interactionsIndicators={props.interactionsIndicators && props.interactionsIndicators}
				activeInteraction={secondLabelInteractionIndicator && secondLabelInteractionIndicator.label}
				searchIndicators={props.searchIndicators}
				updateNewIndicators={updateNewIndicators}
			/>
			{warningData &&
				<div className="reporting_chart_info">
					<div className="reporting_chart_info_label">
						INFO
					</div>
					<div className="reporting_chart_info_content">
						{warningData.filter(x => x !== 1).map((warn, i) =>
							<p key={i}>{warn}</p>
						)}
					</div>
				</div>
			}
		</Fragment>
	);
}
