import React from 'react';
import {Article} from '../../../../Components/Article/Article';
import {Section} from '../../../../Components/Section/Section';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import { PageBreadcrumb } from '../../../../Components/Page/PageBreadcrumb';
import {useListMappingContext} from '../../context/ContextListMapping';
import ChartComponent from './ChartListMapping';
import NavGlobal from '../Nav/NavGlobal.js';
import ChartListMappingComparison from './ChartListMappingComparison';
import {FilterProductInsightsPanel} from '../FilterPanel/FilterProductInsightsPanel';
// import SelectCustom from '../../../../Components/SelectCustom';
import Chip from '../Chip/Chip';
import Btn from '../../../../Components/Btn';
import SelectDropdown from '../../../../Components/SelectDropdown.js';
import {SESSION_STORAGE_KEY, transformToDataStructureArray} from '../utils';
import SelectMetric from './SelectListMappingAxis';
import styles from '../ProductsInsights.module.css';
import {AccountHasNoData} from '../EmptyStates/AccountHasNoData';


export const ListMappingContainer = ():React.ReactElement => {

	const {
		isLoading,
		visitsAverage,
		addtocartAverage,
		emercheOrdinate,
		tagStudiedEl,
		navFilter,
		setNavFilter,
		isCustomSelected,
		setisCustomSelected,
		customDateRange,
		setCustomDateRange,
		updateStorage,
		ComparisonSelected,
		handleSelectComparaisonPeriodHandler,
		xUnitLabel,
		yUnitLabel,
		mappingUnits,
		handleXAxisChange,
		handleYAxisChange,
		handleChangeFormData,
		handleOpenClosePanelForm,
		panelFormIsOpen,
		filterHierarchies,
		selectedValues,
		setSelectedValues,
		chipsByFilterSelected,
		setFilterSelected,
		categoryTypeSelected,
		categories,
		handleSelectNewCategory,
		initialDates,
		accountHasNoDatas
	} =useListMappingContext();
	function handleDeleteChip(id){
		const newObj = JSON.parse((JSON.stringify(selectedValues)));
		delete newObj[id];
		const newFilters = transformToDataStructureArray(newObj);
		sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(newObj));
		setFilterSelected(newFilters);
		setSelectedValues(newObj);
	}
	const listMetricX =  [
		{
			key: 'numberofobjectives',
			label: 'Conversions'
		},
		{
			key: 'numberofabandonments',
			label: 'Abandonments'
		},
	];
	const listMetricY =  [
		{
			key: 'numberofsessions',
			label: 'Sessions'
		},
		{
			key: 'numberofpageviews',
			label: 'Pages views'
		},
	];
	function handleOnChangeX(key) {
		handleXAxisChange(key.key);
	}
	function handleOnChangeY(key) {
		handleYAxisChange(key.key);
	}
	return (
		<>
			{accountHasNoDatas && <AccountHasNoData/>}
			{!accountHasNoDatas &&	<> <FilterProductInsightsPanel
				selectedValues={selectedValues}
				setSelectedValues={setSelectedValues}
				filterHierarchies={filterHierarchies}
				panelFormIsOpen={panelFormIsOpen}
				handleOpenClosePanelForm={handleOpenClosePanelForm}
				handleChangeFormData={handleChangeFormData}
			/>
			<Section hasMargin={false} width="l">
				<section className='section no_bottom_pad section_primary'>
					<PageBreadcrumb>
						<span className='h1'>Product insights</span>
						<span className='h1'>List mapping</span>
					</PageBreadcrumb>
				</section>
				<section className='section'>
					<div className={styles.filters_line}>
						<div className='btn_group_l'>
							<NavGlobal
								ComparisonSelected={ComparisonSelected}
								setNavFilter={setNavFilter}
								initialDates={initialDates}
								navFilter={navFilter}
								handleSelectComparaisonPeriodHandler={handleSelectComparaisonPeriodHandler}
								setisCustomSelected={setisCustomSelected}
								isCustomSelected={isCustomSelected}
								customDateRange={customDateRange}
								setCustomDateRange={setCustomDateRange}
								updateStorage={updateStorage}
							/>
							<SelectDropdown
								label={'Category type'}
								labelPosition="inner"
								color="white"
								optionsList={categories}
								value={categoryTypeSelected?.value}
								onChange={(v) => handleSelectNewCategory(v)}
							/>
							<Btn
								style={'outline'}
								color={'secondary'}
								icon={'fa fa-filter'}
								tooltip="Filter by category"
								size="l"
								horizontalSize="xs"
								onClick={()=>handleOpenClosePanelForm(true)}
							/>
							{chipsByFilterSelected.map(ch => {
								return <Chip
									key={ch.type}
									type={ch.type}
									title={ch.title}
									handleDelete={() => handleDeleteChip(ch.type)}
								/>;
							})}
						</div>
					</div>
					<Article innerSize='l' hasMargin={false}>
						{isLoading &&
							<SpinnerWheel wheelSize="l" display="block" verticalSize="xl"/>
						}

						<SelectMetric
							label={'X : '}
							list={listMetricX}
							onSelectMetric={handleOnChangeX}
							metricSelected={mappingUnits.x}
						/>
						<SelectMetric
							label={'Y : '}
							list={listMetricY}
							onSelectMetric={handleOnChangeY}
							metricSelected={mappingUnits.y}
						/>

						{!ComparisonSelected && !isLoading &&
							<ChartComponent
								visitsAverage={visitsAverage}
								addtocartAverage={addtocartAverage}
								emercheOrdinate={emercheOrdinate}
								tagsStudied={tagStudiedEl}
								xUnitLabel={xUnitLabel}
								yUnitLabel={yUnitLabel}
							/>
						}
						{ComparisonSelected && !isLoading &&
							<ChartListMappingComparison
								visitsAverage={visitsAverage}
								addtocartAverage={addtocartAverage}
								emercheOrdinate={emercheOrdinate}
								tagStudiedEl={tagStudiedEl}
								xUnitLabel={xUnitLabel}
								yUnitLabel={yUnitLabel}
							/>
						}
					</Article>
				</section>
			</Section>
			</>}
		</>
	);
};
