import React, {useState} from 'react';
import {Article} from '../../../../Components/Article/Article';
import topStyles from './TopProductsMinify.module.css';
import {IconTooltip} from '../../../../Components/Tooltip/IconTooltip';
import {dateToFormat} from '../utils';
import {TopProductsMinify} from './TopProductsMinify';
import styles from '../ProductsInsights.module.css';
import {ProductItemCompared} from './ProductItemCompared';
import {useProductInsightsContext} from '../../context/ContextProductInsights';
import TableGridContainer from '../../../../Components/Table/TableGridContainer';
import TableHeadRow from '../../../../Components/Table/TableHeadRow';
import TableHeadCol from '../../../../Components/Table/TableHeadCol';
import Btn from '../../../../Components/Btn';
const tableRowStyle = {
	gridTemplateColumns: '2.7fr 140px 1fr 1fr 1fr 1fr 1fr 1fr',
};
export const BestProducts = () => {
	const {
		dataInsightsBestProducts,
		isLoadingForInsightsKpis
	} = useProductInsightsContext();
	const [productsExpanded, setProductsExpanded] = useState(false);
	return (
		<div style={{marginBottom: 12}}>
			<Article innerSize='l' hasMargin={false}>
				<div>
					<div className={topStyles.title}>
						Best score progression
						<IconTooltip
							icon="fas fa-info-circle"
							tooltip={'Products with the best attractiveness score progression'}
							size="s"
						/>
					</div>
					{!isLoadingForInsightsKpis &&
						<div className={topStyles.description}>
							<span className={topStyles.date}>
								{dateToFormat(dataInsightsBestProducts?.dates?.reference?.start)}
								{' - '}
								{dateToFormat(dataInsightsBestProducts?.dates?.reference?.end)}
							</span>

							<span className={topStyles.date_sep}>compared to</span>

							<span className={topStyles.date}>
								{dateToFormat(dataInsightsBestProducts?.dates?.comparison?.start)}
								{' - '}
								{dateToFormat(dataInsightsBestProducts?.dates?.comparison?.end)}
							</span>
						</div>
					}
				</div>

				<TopProductsMinify
					products={dataInsightsBestProducts?.products}
					isLoading={isLoadingForInsightsKpis}
					onClick={() => setProductsExpanded(!productsExpanded)}
				/>

				<div className={topStyles.more_btn}>
					<Btn
						onClick={() => setProductsExpanded(!productsExpanded)}
						icon={productsExpanded ? 'fas fa-minus-circle fa-sm' : 'fas fa-plus-circle fa-sm'}
						message={productsExpanded ? 'hide all KPIs' : 'Show all KPIs'}
						style='ghost'
						disabled={isLoadingForInsightsKpis}
					/>
				</div>

				{productsExpanded &&
					<TableGridContainer hasAlternatingBackground={true} specialClasseName={styles.grid}>
						<TableHeadRow style={tableRowStyle} isSticky={true}>
							<TableHeadCol fontSize='s'>Product</TableHeadCol>
							<TableHeadCol
								sortable={false}
								tooltip="Attractiveness score from 0 to 100"
								fontSize='s'>
								Attractiveness score
							</TableHeadCol>
							<TableHeadCol
								sortable={false}
								align="right"
								tooltip="Number of visits"
								fontSize='s'>
								Visits
							</TableHeadCol>
							<TableHeadCol
								sortable={false}
								align="right"
								tooltip="Number of add-to-cart"
								fontSize='s'>
								Add to cart
							</TableHeadCol>
							<TableHeadCol
								sortable={false}
								align="right"
								tooltip="Number of abandoned carts"
								fontSize='s'>
								Abandoned cart
							</TableHeadCol>
							<TableHeadCol
								sortable={false}
								align="right"
								tooltip="Number of transactions"
								fontSize='s'>
								Transactions
							</TableHeadCol>
							<TableHeadCol
								sortable={false}
								align="right"
								fontSize='s'
								tooltip="Number of add-to-cart / Number of visits">
								Add to cart rate
							</TableHeadCol>
							<TableHeadCol
								sortable={false}
								align="right"
								tooltip="Number of abandoned carts / Number of visits"
								fontSize='s'>
								Abandoned carts rate
							</TableHeadCol>
						</TableHeadRow>
						{dataInsightsBestProducts?.products.map((s, i) => (
							<ProductItemCompared
								key={s.AssociatedProduct.Id + '_' + i}
								product={s}
								tableRowStyle={tableRowStyle}
							/>
						))}
					</TableGridContainer>
				}
			</Article>
		</div>
	);
};
