import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Btn from '../../../../Components/Btn';
import SearchBar from '../../../../Components/SearchBar';
import { onSiteContext } from '../context';
import EditImageCampaign from '../UI/EditImageCampaign';
import FilterListAction from '../List/FilterListAction';
import StatusFilterSelector from '../List/StatusFilterSelector';
import EditCampaignDelete from '../UI/EditCampaignDelete';
import EditCampaignPermissions from '../UI/EditCampaignPermissions';
import EditCampaignTestMode from '../UI/EditCampaignTestMode';
import EditLabelsCampaign from '../UI/EditLabelsCampaign';
import EditScenarioLabelsCampaign from '../UI/EditScenarioLabelsCampaign';
import EditLabels from '../../../Labels/EditLabels';
import StarterOnSite from '../UI/StarterOnSite';
import FilterCalendarOnSite from '../Planning/FilterCalendarOnSite';
import LabelSelectDropdown from '../../../Labels/LabelSelectDropdown';
import RouterWrapper from '../RouterWrapper';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import { Article } from '../../../../Components/Article/Article';
import SectionMessage from '../../../../Components/SectionMessage';
import SelectorDevices from './SelectorDevices';
import { devicesNav } from './SelectorDevicesModel';
import ReportGeneratorModal from '../../../ReportGenerator/ReportGeneratorModal';

const OnSiteLayout = ({
	primaryAction,
	secondaryAction,
	actionPosition = 'outer',
	header,
	children,
	minimalStatusSelector = false
}) => {

	const {
		accountId,
		isAdmin,
		accountIsLoading,
		$rootScope,
		checkAccountData,
		modalPermissionsIsOppen,
		modalTestModeIsOpen,
		modalLabelsCampaignisOpen,
		modalScenarioLabelsCampaignisOpen,
		modalCampaignToDeleteIsOpen,
		modalCampaignUploadImageisOpen,
		modalLabelsAccountisOpen,
		setmodalLabelsAccountisOpen,
		$routeParams,
		canEditCampaigns,
		canCreateInAppCampaigns,
		devMode,
		labelServices,
		filterStatusMode,
		setFilterStatusMode,
		filterTestMode,
		setFilterTestMode,
		LabelsAccount,
		setLabelsAccount,
		filterLabel,
		setfilterLabel,
		filterLabelId,
		setfilterLabelId,
		statusArray,
		setstatusArray,
		searchValue,
		setSearchValue,
		dateRange,
		setdateRange,
		setDefaultView,
		dimension,
		setDevMode,
		systemServices,
		externalAppSetup,
		devices,
		resetSource,
		handleChangeDeviceSelected,
		handleChangeDeviceValidated,
		pageTitle,
		searchCampaignPlaceholder
	} = onSiteContext();


	const [modalReportingIsOpen, setModalReportingIsOpen] = useState(false);
	const [dateOfTheSingleDatePicker, setDateOfTheSingleDatePicker] = useState();

	const [t] = useTranslation(['common']);

	useEffect(() => {
		const filterStatusStorage = JSON.parse(
			sessionStorage.getItem('onSiteStatusFilter')
		);
		if (filterStatusStorage) {
			setstatusArray(filterStatusStorage);
		}

		const filterTestStorage = sessionStorage.getItem('onSiteTestFilter');
		if (filterTestStorage) {
			setFilterTestMode(filterTestStorage === 'true');
		}

		const filterSearchStorage = JSON.parse(
			sessionStorage.getItem('onSiteSearchFilter')
		);
		if (filterSearchStorage) {
			setSearchValue(filterSearchStorage);
		}
		getLabels();

		let devModeStorage = localStorage.getItem('campaign_list_devMode');
		if (devModeStorage) {
			setDevMode(true);
		}
	}, []);

	useEffect(() => {
		sessionStorage.setItem('dashboardOnSiteType', JSON.stringify(dimension || 'dashboard'));
	}, [dimension]);

	const getLabels = () => {
		labelServices.getLabels(
			accountId,
			'campaign',
			(data) => {
				const filterLabelsStorage = JSON.parse(
					sessionStorage.getItem('onSitelabelsFilter')
				);
				if (filterLabelsStorage) {
					setLabelsAccount(data);
					let filterLabelSelected = [];
					let filterLabelSelectedByIds = [];
					for (let i = 0; i < data.length; i++) {
						if (filterLabelsStorage.includes(data[i].id)) {
							filterLabelSelected.push(data[i]);
							filterLabelSelectedByIds.push(data[i].id);
						}
					}
					setfilterLabel(filterLabelSelected);
					setfilterLabelId(filterLabelSelectedByIds);
				} else {
					setLabelsAccount(data);
					setfilterLabel([]);
					setfilterLabelId([]);
				}
			},
			(err) => {
				console.log('err', err);
				systemServices.showError(t('labels.Error'));
			}
		);
	};

	const toggleFilterLabels = (e) => {
		if (filterLabelId.includes(e.id)) {
			setfilterLabel([...filterLabel.filter((el) => el.id !== e.id)]);
			setfilterLabelId([...filterLabelId.filter((el) => el !== e.id)]);
			sessionStorage.setItem(
				'onSitelabelsFilter',
				JSON.stringify([...filterLabelId.filter((el) => el !== e.id)])
			);
		} else {
			setfilterLabel([...filterLabel, e]);
			setfilterLabelId([...filterLabelId, e.id]);
			sessionStorage.setItem(
				'onSitelabelsFilter',
				JSON.stringify([...filterLabelId, e.id])
			);
		}
	};

	const resetFilterLabels = () => {
		setfilterLabel([]);
		setfilterLabelId([]);
		sessionStorage.removeItem('onSitelabelsFilter');
	};

	const toggleDevMode = () => {
		const newMode = !devMode;
		setDevMode(newMode);
		if (newMode) {
			localStorage.setItem('campaign_list_devMode', newMode);
		} else {
			localStorage.removeItem('campaign_list_devMode');
		}
	};

	// planning date filter

	useEffect(() => {
		const pickerStorage = JSON.parse(
			sessionStorage.getItem('onSitePlanningPicker')
		);
		if (pickerStorage) {
			checkTheDayOfWeek(moment(pickerStorage).startOf('day'));
			setDateOfTheSingleDatePicker(moment(pickerStorage).startOf('day'));
		} else {
			checkTheDayOfWeek(moment().startOf('day'));
			setDateOfTheSingleDatePicker(moment().startOf('day'));
			const newPicker = moment().startOf('day');
			sessionStorage.setItem('onSitePlanningPicker', JSON.stringify(newPicker));
		}
	}, []);

	useEffect(() => {
		if (dateOfTheSingleDatePicker) {
			const pickerStorage = dateOfTheSingleDatePicker;
			sessionStorage.setItem(
				'onSitePlanningPicker',
				JSON.stringify(pickerStorage)
			);
		}
	}, [dateOfTheSingleDatePicker]);
	const checkTheDayOfWeek = (dateToCheck, operation) => {
		let firstDateOfCalendar = dateToCheck;
		let dayWeek = firstDateOfCalendar.weekday();
		if (!operation) {
			if (dayWeek >= 1) {
				firstDateOfCalendar = firstDateOfCalendar.weekday(0);
				setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
			} else {
				setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
			}
		}
		if (operation === 'today') {
			if (dayWeek >= 1) {
				firstDateOfCalendar = firstDateOfCalendar.weekday(0);
				setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
				setDateOfTheSingleDatePicker(moment().startOf('day'));
			} else {
				setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
				setDateOfTheSingleDatePicker(moment().startOf('day'));
			}
		}
		if (operation === 'after') {
			firstDateOfCalendar = firstDateOfCalendar.add(1, 'week');
			setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
			setDateOfTheSingleDatePicker(firstDateOfCalendar.startOf('day'));
		}
		if (operation === 'before') {
			firstDateOfCalendar = firstDateOfCalendar.subtract(1, 'week');
			setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
			setDateOfTheSingleDatePicker(firstDateOfCalendar.startOf('day'));
		}
	};

	return (
		<>
			{modalPermissionsIsOppen && <EditCampaignPermissions />}
			{modalTestModeIsOpen && <EditCampaignTestMode />}
			{modalLabelsCampaignisOpen && <EditLabelsCampaign labelService={labelServices} />}
			{modalScenarioLabelsCampaignisOpen && <EditScenarioLabelsCampaign labelService={labelServices} />}
			{modalCampaignToDeleteIsOpen && <EditCampaignDelete />}
			{modalCampaignUploadImageisOpen && <EditImageCampaign />}
			{modalLabelsAccountisOpen && <EditLabels
				accountId={accountId}
				modalLabelsAccountisOppen={modalLabelsAccountisOpen}
				setmodalLabelsAccountisOppen={setmodalLabelsAccountisOpen}
				systemServices={systemServices}
				labelService={labelServices}
				onClose={getLabels}
				objectType={'campaign'}
			/>
			}
			{modalReportingIsOpen && (
				<ReportGeneratorModal
					onClose={() => setModalReportingIsOpen(false)}
					isOpen={modalReportingIsOpen}
					toggleFilterLabels={toggleFilterLabels}
					resetFilterLabels={resetFilterLabels}
				></ReportGeneratorModal>
			)}

			<RouterWrapper props={{$routeParams: $routeParams, $rootScope: $rootScope}}>
				<StarterOnSite header={header} />
				{!accountIsLoading && (
					<>
						{checkAccountData && (
							<OnSitePageWrapper dimension={dimension}>
								<section className="section no_bottom_pad section_primary">
									<div className="flex">
										<div className="flex_item_full h1">
											{pageTitle}
										</div>
										{actionPosition === 'outer' &&
											<div className="flex_item_fix ml_30">
												<span className="btn_group_l">
													{secondaryAction}
													{primaryAction}
												</span>
											</div>
										}
									</div>
								</section>
								<section className="section">
									{header && header}
									<Article size="l" hasMargin={false}>
										<div className='flex mb_20'>
											<div className='flex_item_full'>
												<div className='btn_group_l'>
													<SearchBar
														onChange={(value) => {
															setSearchValue(value);
														}}
														valueInSearchBar={searchValue}
														placeholder={searchCampaignPlaceholder}
														fullWidth={false}
														size="l"
														width="l"
													/>
													{canCreateInAppCampaigns &&
														<SelectorDevices
															resetSource={resetSource}
															deviceSelected={devices || []}
															initialValue={devicesNav}
															handleChangeDeviceValidated={handleChangeDeviceValidated}
															handleChangeDeviceSelected={handleChangeDeviceSelected}
														/>
													}
													{minimalStatusSelector &&
														<StatusFilterSelector
															filterStatusMode={filterStatusMode}
															setFilterStatusMode={(e) => setFilterStatusMode(e)}
															filterTestMode={filterTestMode}
															setFilterTestMode={(e) => setFilterTestMode(e)}
															statusArray={statusArray}
															setstatusArray={(e) => setstatusArray(e)}
														/>
													}
													<LabelSelectDropdown
														selectableLabels={LabelsAccount || []}
														selectedLabelIds={filterLabelId || []}
														onLabelSelected={l => toggleFilterLabels(l)}
														resetFilterLabels={resetFilterLabels}
														canEdit={(isAdmin || canEditCampaigns)}
														onEditRequested={() => setmodalLabelsAccountisOpen(true)}
														showEditLabels={true}
													/>
												</div>
											</div>
											<div className='flex_item_fix ml_20'>
												{dimension !== 'planning' && (
													<Btn
														icon="fas fa-code"
														style="outline"
														color="secondary"
														size="xs"
														className={devMode ? 'active mr_10' : 'mr_10'}
														onClick={() => toggleDevMode()}
														tooltip={devMode ? 'Hide campaign IDs' : 'Show campaign IDs'}
													/>
												)}
												{!minimalStatusSelector &&
													<FilterListAction
														filterStatusMode={filterStatusMode}
														setFilterStatusMode={(e) => setFilterStatusMode(e)}
														filterTestMode={filterTestMode}
														setFilterTestMode={(e) => setFilterTestMode(e)}
														statusArray={statusArray}
														setstatusArray={(e) => setstatusArray(e)}
													/>
												}
												{actionPosition === 'inner' &&
													<>
														{secondaryAction}
														{primaryAction}
													</>
												}
											</div>
										</div>

										{externalAppSetup?.installationStatus == 'Uninstalled' &&
											<section className='section no_bottom_pad'>
												<SectionMessage
													type="alert"
													title="The BEYABLE subscription is canceled"
													text="To continue using BEYABLE, please subscribe to a plan from your Prestashop back office."
												/>
											</section>
										}
										{dimension === 'planning' && (
											<section className="mt_10">
												{dateRange && (
													<>
														<FilterCalendarOnSite
															setDefaultView={(e) => setDefaultView(e)}
															dateOfTheSingleDatePicker={
																dateOfTheSingleDatePicker
															}
															setDateOfTheSingleDatePicker={(e) =>
																setDateOfTheSingleDatePicker(e)
															}
															dateRange={dateRange}
															setdateRange={(e) => setdateRange(e)}
															checkTheDayOfWeek={(e, op) =>
																checkTheDayOfWeek(e, op)
															}
														/>
													</>
												)}
											</section>
										)}
										{children}
									</Article>
								</section>
							</OnSitePageWrapper>
						)}
					</>
				)}
			</RouterWrapper>

			{accountIsLoading && (
				<SpinnerWheel wheelSize="l" />
			)}
		</>
	);
};



export default OnSiteLayout;

const OnSitePageWrapper = ({ dimension, children }) => {
	if (dimension == 'planning') return (
		<div className="page_full section_root flex flex_v">
			{children}
		</div>
	);

	return (
		<div className='page_full_inner'>
			{children}
		</div>
	);
};
