

export const listOfSelectablePeriod = [
	'last7Days',
	'last15Days',
	'last1Month',
	'last3Month',
	'last6Month',
	'last12Month'
];

export const getFormattedNumber = (n) => {
	if (typeof n == 'undefined') return '';
	const parts = n.toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	return parts.join('.');
};

export const getFormattedPercent = (n:number, decimals = 1):string => {
	if (typeof n == 'undefined') return '';

	let s = n.toFixed(decimals);
	s = getFormattedNumber(s);
	return s + '\u00a0%';
};

export const listOfKpis = [
	{
		name: 'campaigns',
		id:  'campaigns',
		label: 'Campaigns'
	},
	{
		name:   'turnover',
		id: 'turnover',
		label: 'Incremental turnover'
	},
	{
		name:  'global',
		id: 'global',
		label: 'Web-site traffic'
	},
	{
		name: 'ecommerce',
		id: 'ecommerce',
		label: 'E-commerce'
	},
	{
		name: 'products',
		id: 'products',
		label: 'Product insights'
	},
	{
		name: 'Ai insights',
		id: 'aiinsights',
		label: 'Ai-insights'
	},	
];
export const devices = [
	// 	{
	// 	value : 'all',
	// 	label : 'All'
	// },
	{
		value : 'Desktop',
		label : 'Desktop'
	},
	{
		value : 'Mobile',
		label : 'Mobile'
	},
	{
		value : 'Tablet',
		label : 'Tablet'
	}
];

export const getDeviceTranslationKey = (device) => {
	device = device.toLowerCase();
	if (device == 'desktop') return 'device.Desktop';
	if (device == 'tablet') return 'device.Tablet';
	if (device == 'mobile') return 'device.Mobile';
	return device;
};
export const campaign_dictionary = {
	AbandonedCartRetention: 'Rétention panier abandonné',
	ProductsViewedReminder : 'Rappel des produits consultés' ,
	PaymentFlexibilityHighlighting: 'Mise en avant facilité de paiement',
	LeavingCustomerRetention: 'Rétention, rattraper les sorties',
	StressMarketing: 'Stress marketing',
	Reinsurance:'Elément réassurance',
	RevisitAndLoyalty:'Fidelité et revisite',
	PromotionsAndSpecialOffersHighlighting:'Mise en avant promo et offres spéciales',
	Information:'Informatif',
	CartProductRecommendation:'Reco produit en page panier' ,
	FreeDeliveryCountdown:'Décompte livraison offerte' ,
	OtherProductRecommendation:'Reco produit autres' ,
	ProductPageRecommendation:'Reco produit en fiche produit',
	LeadCollection:'Collecte leads' ,
	ToDestockProductsHighlighting:'Mise en avant de produits à déstocker' ,
	UxImprovement:'Amélioration ou patch UX' ,
	ProductsSelection:'Sélection de produits du moment' ,
	PromotionalCode:'Codes promo' ,
	FeatureHighlighting:'Mise en valeur fonctionnalité' ,
	UpsellCrosssellPacksell:'Upsells crosssells packsells' ,
	HomepageProductRecommendation:'Reco produit en Home page' ,
	ProductListing:'Listing produit, ex rank' ,
	WebToStore:'Web to store' ,
	LowStockOrFallingPriceAlert:'Alerte stock ou prix en baisse' ,
	Gamification:'Gamification' ,
	VisitorSegmentOffer:'Offre pour segment visiteur' ,
	WebsiteDesign:'Habillage site' ,
	AppointmentMaking:'Prise rdv' ,
	AcquisitionBridge:'Relai acquisition' ,
	PaymentTunnelEngagement:'Engagement tunnel' ,
	Sponsorship:'Parrainage'
};
export const kpis = {
	'turnover': 'Turnover',
	'turnoverByDevice': 'Turnover by device',
	'transactionCount': 'Transactions count',
	'conversionRate': 'Conversion rate',
	'averageCartValue': 'Average cart value',
	'abandonedCartsCount': 'Abandoned carts count',
};

export function toCamelCase(str) {
	return str
		.toLowerCase()
		.split(' ')
		.map((word, index) =>
			index === 0
				? word
				: word.charAt(0).toUpperCase() + word.slice(1)
		)
		.join('');
}

export function toPascalCase(str) {
	return str
		.toLowerCase()
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join('');
}