import React from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { NavLink  } from 'react-router-dom';
import s from './IconBtn.module.css';

const IconBtn = React.forwardRef(({
	icon,
	isCrossedOut = false,
	fixedWidth = true,
	size = 'm',
	className = '',
	theme = 'medium',
	hoverColor = 'primary',
	tooltip,
	tooltipHTML,
	bubble,
	onClick,
	onMouseDown,
	href = null,
	target = null,
	disabled = false,
	readonly = false,
	isActive = false,
	isNavLink = false,
	rel,
	...otherProps
}, ref) => {

	const tooltipID = uuidv4();

	const handleClick = (ev) => {
		if( disabled || readonly ) {
			ev.stopPropagation();
			return;
		}

		if (typeof onClick === 'function') {
			onClick(ev);
		}
	};

	let btnClass = [s.btn];
	let iconClass = [s.icon];

	if (className) {
		btnClass = btnClass.concat(className);
	}

	if (size) {
		btnClass.push(s['btn_' + size]);
	}

	if (theme) {
		btnClass.push(s['btn_theme_' + theme]);
	}

	if (disabled) {
		btnClass.push(s.btn_disabled);
	} else if (hoverColor) {
		btnClass.push(s['btn_color_' + hoverColor]);
	}

	if (readonly) {
		btnClass.push(s.btn_readonly);
	}

	if (isActive) {
		btnClass.push(s.btn_active);
	}

	if (fixedWidth) {
		iconClass.push('fa-fw');
	}

	if (icon) {
		iconClass.push(icon);
	}

	if (isCrossedOut) {
		iconClass.push(s.icon_crossed_out);
	}

	return (
		<>
			{tooltip &&
				<ReactTooltip
					id={tooltipID}
					backgroundColor="black"
					effect="solid"
					place="bottom"
					globalEventOff="mouseup"
					delayShow={600}
				/>
			}
			{tooltipHTML &&
				<ReactTooltip
					id={tooltipID}
					backgroundColor="black"
					effect="solid"
					place="bottom"
					globalEventOff="mouseup"
					delayShow={600}
					getContent={function() {
						return tooltipHTML;
					}}
				/>
			}
			{isNavLink ?
				<NavLink
					className={btnClass.join(' ')}
					target={target}
					rel={rel}
					to={href}
					data-tip={tooltipHTML ? 'a' : tooltip}
					data-for={tooltipID}
					{...otherProps}>
					<i className={iconClass.join(' ')}></i>
				</NavLink>
				:
				<a
					className={btnClass.join(' ')}
					onClick={(e) => handleClick(e)}
					onMouseDown={onMouseDown}
					target={target}
					rel={rel}
					href={disabled ? null : href}
					ref={ref}
					data-tip={tooltipHTML ? 'a' : tooltip}
					data-for={tooltipID}
					{...otherProps}>
					<i className={iconClass.join(' ')}></i>
					{typeof bubble !== 'undefined' &&
						<span className={s.bubble}>{bubble}</span>
					}
				</a>
			}
		</>
	);
});

IconBtn.displayName = 'IconBtn';

export default IconBtn;
