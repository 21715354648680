import React from 'react';
import NavNavigation from '../NavNavigation';
import SelectFilter from '../../../../../../Components/SelectFilter.js';
import { devices } from '../../config.js';
import {useTranslation} from 'react-i18next';
import VisitorEngagementLevelFilter from './VisitorEngagementLevelFilter';
import MoreFilter from './MoreFilter';
import {OptionnalFilterItem} from './OptionnalFilterItem';
import styles from '../../funnel.module.css';

export const NavigationFiltersContainer = ({context}:any):React.ReactElement => {

	const {
		handleChangeDeviceSelected,
		resetDevices,
		deviceSelected,
		initializeFilters,
		optionalFiltersSelected,
		typeEnv
	} = context;

	const [t] = useTranslation(['kpi', 'utils']);
	function getTranslatedArray(arr, dimension) {
		return arr.map((label) => {
			const copyItemLabel = {...label};
			if (copyItemLabel.translation) {
				copyItemLabel.label = t(copyItemLabel.translation.key, copyItemLabel.translation.vars);
			} else {
				copyItemLabel.label = t(dimension + '.' + copyItemLabel.label);
			}
			return copyItemLabel;
		});
	}
	const devicesTranslated = getTranslatedArray(devices, 'device');

	if (initializeFilters) return <></>;

	return (
		<div className='flex'>
			<div className='flex_item_auto'>
				<div className={styles.filterList}>
					<NavNavigation context={context} />
					<SelectFilter
						label={t('data.device')}
						onChange={handleChangeDeviceSelected}
						onReset={resetDevices}
						value={deviceSelected}
						optionsList={devicesTranslated}
						hasResetButton={true}
					/>
					{typeEnv === 'navigationFunnel' &&
						<VisitorEngagementLevelFilter context={context}/>
					}
					{optionalFiltersSelected?.map(filter =>
						<OptionnalFilterItem
							context={context}
							key={filter}
							name={filter}
						/>
					)}
				</div>
			</div>
			<div className='flex_item_fix ml_15'>
				<MoreFilter context={context} />
			</div>
		</div>
	);
};
