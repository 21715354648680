import React, { useEffect, useState } from 'react';

const Highcharts = window.Highcharts;

const ChartComponent = ({
	visitsAverage,
	addToCartRateAverage,
	conversionRateAverage,
	emercheOrdinate,
	tagsStudied
}) => {

	if (!tagsStudied || tagsStudied?.length > 1) {
		return <></>;
	}

	const [chartOptions, setChartOptions] = useState({});

	useEffect(() => {
		const options = {
			chart: {
				type: 'bubble',
				plotBorderWidth: 1,
				zoomType: 'xy',
				zooming: {
					mouseWheel: {
						enabled: false
					}
				},
				panning: {
					enabled: true,
					type: 'xy'
				},
				panKey: 'shift'
			},	events: {
				render: function () {
					// @ts-ignore
					// eslint-disable-next-line @typescript-eslint/no-this-alias
					const chart = this;
					// @ts-ignore
					if (!chart.labelAdded) {
						// @ts-ignore
						chart.renderer.text('0', 20, 480)
							.css({ color: '#d9d7d7', fontSize: '14px' })
							.add();
						// @ts-ignore
						chart.labelAdded = true;
					}
				}
			},
			title: {
				text: ''
			},
			zoomButtons: {
				enabled: true
			},
			xAxis: {
				title: {
					text: 'Visits'
				},
				plotLines: [{
					color: '#006fff',
					width: 2,
					value: visitsAverage,
					dashStyle: 'dash',
					label: {
						text: 'Average visits',
						align: 'right',
						style: {
							color: '#006fff'
						}
					}
				}],
				gridLineWidth: 1,
				minRange: 1
			},
			yAxis: {
				title: {
					text: emercheOrdinate === 'addToCartRate' ? 'Add-to-cart rate (%)' : emercheOrdinate === 'conversionRate' ? 'Conversion rate (%)' : 'Conversions'
				},
				plotLines: [{
					color: '#006fff',
					width: 2,
					value: emercheOrdinate === 'addToCartRate' ? addToCartRateAverage : conversionRateAverage,
					dashStyle: 'dash',
					label: {
						text: 'Average ' + (emercheOrdinate === 'addToCartRate' ? 'add-to-cart rate' : emercheOrdinate === 'conversionRate' ? 'conversion rate' : 'conversions'),
						align: 'right',
						style: {
							color: '#006fff'
						}
					}
				}],
				labels: {
					format: '{value}' + (emercheOrdinate === 'addToCartRate' || emercheOrdinate === 'conversionRate' ? '%' : '')
				},
				gridLineWidth: 1,
				minRange: 0.1
			},
			tooltip: {
				useHTML: true,
				positioner: function (_labelWidth, _labelHeight, point) {
					const tooltipX = point.plotX + 0;
					const tooltipY = point.plotY + 50;
					return { x: tooltipX, y: tooltipY };
				},
				borderWidth: 0,
				hideDelay: 0,
				headerFormat: '<div class="graph_tooltip">',
				pointFormat: `<p><span>{point.tagType}</span>{point.tagName}</p>
						<div style="{{text-align:start }}">
						<div class="tooltip_label" >Visits: {point.visits}</div>
						 <div class="tooltip_label">Add to Cart: {point.addToCart}</div>
						<div class="tooltip_label">Conversions: {point.conversions}</div>
						<div class="tooltip_label">Amount: {point.amount} € </div>				
						<div class="tooltip_label">Abandonment Rate: {point.abandonmentsRate} %</div>
						<div class="tooltip_label">Conversion Rate: {point.conversionsRate} %</div>
						</div>
`,
				backgroundColor: '#38444A',
				// @ts-ignore
				color: '#FFF',
				style: { color: '#FFFFFF' },
				footerFormat: '</div>',
				followPointer: true,
				outside: true
			},
			plotOptions: {
				bubble: {
					minSize: 20,
					maxSize: 120,
					zMin: 0,
					zMax: Math.max(...tagsStudied[0].data.map(point => point.z)),
					dataLabels: {
						enabled: true,
						format: '{point.tagName}',
						style: {
							textOutline: 'none',
							fontWeight: 'normal'
						}
					},
					animation: {
						duration: 1000
					},
					states: {
						hover: {
							halo: {
								size: 5
							}
						}
					}
				}
			},
			navigation: {
				buttonOptions: {
					enabled: true
				}
			},
			exporting: {
				enabled: true,
				buttons: {
					contextButton: {
						menuItems: ['viewFullscreen', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
					}
				}
			},
			series: [{
				data: tagsStudied[0].data,
				name: 'Products',
				marker: {
					fillOpacity: 0.5,
					lineWidth: 0,
					fillColor: '#006fff'
				}
			}],
			credits: {
				enabled: false
			},
			legend: {
				enabled: false
			}
		};

		setChartOptions(options);
	}, [tagsStudied, visitsAverage, addToCartRateAverage, conversionRateAverage, emercheOrdinate]);

	useEffect(() => {
		Highcharts.chart('chart-container', chartOptions);
	}, [chartOptions]);

	return (
		<div id="chart-container" style={{height: '550px', minWidth: '310px', overflow: 'auto'}}></div>
	);
};


export default ChartComponent;