import React, { createContext, useContext, useState, Dispatch, SetStateAction, useEffect } from 'react';
import SystemServices from '../../../../Services/SystemServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import { SegmentGroupType, convertSegmentGroupTypeFromApi } from '../../../SegmentGroups/List/context/EntityTypes';
import SegmentRankingServices from '../../../../Services/SegmentRankingServices';
import ProductRankingServices from '../../../../Services/ProductRankingServices';
import SegmentGroupServices from '../../../../Services/SegmentGroupServices';

const SegmentGroupEditorContext = createContext<SegmentListContextType | undefined>(undefined);
import { Segment, TrafficSegmentationRuleSetup } from '../../../../Services/SegmentRankingServicesTypes';

function useSegmentGroupEditorContext() {
	const context = useContext(SegmentGroupEditorContext);
	if (!context) throw Error('useSegmentGroupEditorContext can only be used inside a SegmentGroupEditorContextProvider');
	return context;
}
import { SegmentRankingApiType } from '../../../../Services/SegmentRankingServicesTypes';
import {SegmentGroupStatus} from '../../../../Services/SegmentGroupServicesTypes';

type CreateSegmentRankingContextProviderType = {
	$http: any,
	$rootScope: any,
	$routeParams: any,
	$timeout: any,
	children: any,
	AuthServices: any,
	$location: any
}
type ExternalAudienceType = {
	name: string;
	ruleId: string;
}
const defaultPagin = {
	offset: 0,
	limit: 10,
};
type TypeRecommendationsObject = {
	description: string;
	id: string;
	lastUpdatedUtc: string;
	name: string;
	tenant: string | null;
	usedCount: number;
}
export type TrafficSegmentationRuleSetupPublication = {
	showPublish: boolean;
	enablePublish: boolean;
	isPublishing: boolean;
};
const emptyExternalAudience: ExternalAudienceType = {
	name: '',
	ruleId: ''
};
const CreateSegmentGroupEditorContextProvider = (props: CreateSegmentRankingContextProviderType) => {

	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const $routeParams = props.$routeParams;
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	// const isAdmin = impersonatingServices.isAnAdmin();
	// const $routeParams = props.$routeParams;
	const segmentRankingServices = new SegmentRankingServices(accountId, props.$http);
	const productRankingServices = new ProductRankingServices(props.$http, props.AuthServices, accountId);
	const segmentGroupServices = new SegmentGroupServices(accountId, props.$http);
	const [listRankingSegments, setlistRankingSegments] = useState<SegmentRankingApiType[]>([]);

	const [segmentGroups, setSegmentGroups] = useState<SegmentGroupType[]>([]);

	const [isLoading, setisLoading] = useState<boolean>(false);
	const [noData, setnoData] = useState<boolean>(true);
	const [dropDownisOpen, setdropDownisOpen] = useState<boolean>(false);
	const [addNewExternalAudience, setaddNewExternalAudience] = useState<boolean>(false);
	const [externalAudienceObject, setexternalAudienceObject] = useState<ExternalAudienceType>(emptyExternalAudience);
	const [rankingRules, setRankingRules] = useState<TypeRecommendationsObject[] | null>(null);
	const [sureToDelete, setSureToDelete] = useState<boolean>(false);
	const [idToDelete, setIdToDelete] = useState<string>();
	const [segmentType, setsegmentType] = useState<string | undefined>();
	const [hasErrorOnExternalItem, setHasErrorOnExternalItem] = useState<boolean>(false);
	const [trafficSegmentationRuleSetup, setTrafficSegmentationRuleSetup] = useState<TrafficSegmentationRuleSetup | null>(null);
	const [cookieName, setCookieName] = useState<string | undefined>();

	const [trafficSegmentationRuleSetupPublication, setTrafficSegmentationRuleSetupPublication] = useState<TrafficSegmentationRuleSetupPublication>({ 
		showPublish: false, 
		enablePublish: false,
		isPublishing: false 
	});

	const getRankingList = () => {
		return productRankingServices.getRankingList('', 'Name', false, defaultPagin, callbackSuccess => {
			setRankingRules(callbackSuccess);
		}, _error => {
			systemServices.showError();
		});
	};
	const handleChooseSegmentationType = (typeSegmentation) => {
		setsegmentType(typeSegmentation);
	};

	const refreshList = () => {
		segmentGroupServices.getSegmentGroups(data => {
			setSegmentGroups(data.map(convertSegmentGroupTypeFromApi));

		}, err => {
			console.error(err);
			systemServices.showError('Cannot get segment groups');
		});
	};

	const getEditURL = (): string => {
		const ka = props.$routeParams?.ka ? `&ka=${accountId}` : '';
		const provider = '?provider=ranking';
		const url = `/Insight/segment/edit${provider}${ka}`;
		return url;
	};
	const getManageURL = (): string => {
		const ka = props.$routeParams?.ka ? `?ka=${accountId}` : '';
		const url = `/Insight/segment/settings${ka}`;
		return url;
	};
	const handleChangeValueExternalAudience = (name: string, value: string) => {
		if (listRankingSegments && listRankingSegments.length > 0) {
			const valuesNamesOfSegmentsRancking: string[] = listRankingSegments[0]?.segments.map((segment: Segment) => segment.segmentId.toLowerCase());			
			if(valuesNamesOfSegmentsRancking && valuesNamesOfSegmentsRancking.includes(value.toLowerCase())){
				setHasErrorOnExternalItem(true);
			} else {
				setHasErrorOnExternalItem(false);
			}
		}
		setexternalAudienceObject(prev => ({
			...prev,
			[name]: value
		}));
	};
	const handleChooseRuleIdForUnMappedAudience = (idRule: string, rule) => {
		const segmentNewValue = listRankingSegments[0];
		sessionStorage.removeItem('segmentType');
		if (segmentNewValue) {
			segmentNewValue.segments = [...listRankingSegments[0].segments,
				{
					segmentId: rule.id,
					segmentItems: [{ abtestGroupId: 'default', ranking: idRule }]
				}
			];
			setisLoading(true);
			segmentRankingServices.updateSegmentRankingGroup(segmentNewValue, _data => {
				updateListRankingSegments();
			}, err => {
				console.error(err);
				systemServices.showError('Cannot create segment groups');
			});
		} else {
			const newSegmentGroup = {
				// id: '',
				segmentsType: 'Beyable',
				segments: [
					{
						segmentId: rule.id,
						segmentItems: [{ abtestGroupId: 'default', ranking: idRule }]
					}
				]
			};
			segmentRankingServices.createSegmentRankingGroup(newSegmentGroup, _data => {
				updateListRankingSegments();
			}, err => {
				console.error(err);
				systemServices.showError('Cannot create segment groups');
			});
		}

	};
	const handleChooseRuleIdForMappedAudience = (idRule: string, name) => {
		const segmentNewValue = listRankingSegments[0];
		sessionStorage.removeItem('segmentType');
		const elment = listRankingSegments[0].segments.find(x => x.segmentId === name);
		if (elment) {
			const indexOfItem = segmentNewValue.segments.indexOf(elment);
			segmentNewValue.segments.splice(indexOfItem, 1, {
				segmentId: name,
				segmentItems: [{ abtestGroupId: 'default', ranking: idRule }]
			});
			setisLoading(true);
			segmentRankingServices.updateSegmentRankingGroup(segmentNewValue, _data => {
				updateListRankingSegments();
			}, err => {
				console.error(err);
				systemServices.showError('Cannot create segment groups');
			});
		}

	};
	const handleSaveExternalAudience = () => {
		const segmentNewValue = listRankingSegments[0];
		if (segmentNewValue) {

			if (!hasErrorOnExternalItem) {
				segmentNewValue.segments = [...listRankingSegments[0].segments,
					{
						segmentId: externalAudienceObject.name,
						segmentItems: [{ abtestGroupId: 'default', ranking: externalAudienceObject.ruleId }]
					}
				];
				segmentRankingServices.updateSegmentRankingGroup(segmentNewValue, _data => {
					updateListRankingSegments();
					setexternalAudienceObject(emptyExternalAudience);
					setaddNewExternalAudience(false);
					handleChooseSegmentationType('External');
				}, err => {
					console.error(err);
					setisLoading(false);
					systemServices.showError('Cannot create segment groups');
				});

			}

		} else {

			const newSegmentGroup: SegmentRankingApiType = {
				// id: '',
				segmentsType: 'External',
				segments: [
					{
						segmentId: externalAudienceObject.name,
						segmentItems: [{ abtestGroupId: 'default', ranking: externalAudienceObject.ruleId }]
					}
				]
			};
			segmentRankingServices.createSegmentRankingGroup(newSegmentGroup, _data => {
				updateListRankingSegments();
				setexternalAudienceObject(emptyExternalAudience);
				setaddNewExternalAudience(false);
				handleChooseSegmentationType('External');
			}, err => {
				console.error(err);
				setisLoading(false);
				systemServices.showError('Cannot create segment groups');
			});
		}
		setisLoading(true);
	};
	const updateListRankingSegments = () => {
		setisLoading(true);
		segmentRankingServices.getSegmentRankingGroups((success: SegmentRankingApiType[]) => {
			if (success && success.length === 0) {
				setnoData(true);
				const storage = sessionStorage.getItem('segmentType');
				if (storage) {
					setsegmentType(storage);
				}
				syncTrafficSegmentationRuleInitial();
			} else {
				setnoData(false);
				setsegmentType(success[0].segmentsType);
				setlistRankingSegments(success);
				syncTrafficSegmentationRuleInitial();
				sessionStorage.removeItem('segmentType');
			}
			setisLoading(false);
		}, err => {
			console.log(err);
			systemServices.showError('Error while getting segmentation');
			setisLoading(false);
			setnoData(true);
		});
	};
	const handleChooseRuleId = (idRule: any) => {
		setexternalAudienceObject(prev => ({ ...prev, ruleId: idRule }));
	};
	const onDelete = (id: string) => {
		setSureToDelete(true);
		setIdToDelete(id);
	};
	const handleDeleteSegment = () => {
		if (idToDelete) {
			const segmentNewValue = listRankingSegments[0];
			segmentNewValue.segments = [...segmentNewValue.segments.filter(x => x.segmentId !== idToDelete),
			];
			segmentRankingServices.updateSegmentRankingGroup(segmentNewValue, () => {
				systemServices.showSuccess('segment deleted with success');
				updateListRankingSegments();
				getRankingList();
				refreshList();
			}, (_error) => {
				systemServices.showError('Error while getting segmentation');
				setisLoading(false);
			});
		}
	};
	const handleReorderSegments = (newSegmentOrder: SegmentRankingApiType) => {
		segmentRankingServices.updateSegmentRankingGroup(newSegmentOrder, () => {
			setlistRankingSegments([newSegmentOrder]);
			systemServices.showSuccess('segment order updated with success');
		}, (_error) => {
			systemServices.showError('Error while getting segmentation');
			setisLoading(false);
		});
	};

	const handleDeleteSegmentRankingGroup = (rankingGroupId: string) => {
		segmentRankingServices.deleteSegmentRankingGroup(rankingGroupId, () => {
			systemServices.showSuccess('Segmentation has been deleted');
			setsegmentType(undefined);
			setSegmentGroups([]);
			setlistRankingSegments([]);
			setisLoading(true);
			publishThisTrafficSegmentationRuleSetup([]);
			setTimeout(() => {
				updateListRankingSegments();
				getRankingList();
				refreshList();
			}, 1000);
		}, (_error) => {
			systemServices.showError('An error occurred while deleting segmentation');
			setisLoading(false);
		});
	};

	const syncTrafficSegmentationRuleInitial = () => {
		segmentRankingServices.getTrafficSegmentationRuleSetups(rules => {
			if (rules.length == 0) {
				setTrafficSegmentationRuleSetup(null);
			} else {
				setTrafficSegmentationRuleSetup(rules[0]);
			}
		}, (_error) => {
			systemServices.showError('An error occurred while getting segmentation rule');
		});
	};


	const syncTrafficSegmentationRule = () => {
		const emptyIds: string[] = [];
		const setup = trafficSegmentationRuleSetup?.segmentGroupIds ?? emptyIds;
		const currentState = listRankingSegments.length > 0 ? listRankingSegments[0].segments.map(s => s.segmentId) : [];
		const areSameArrays = setup.length === currentState.length && setup.every((value, index) => value === currentState[index]);
		setTrafficSegmentationRuleSetupPublication(x => ({ ...x, enablePublish: !areSameArrays }));
	};

	const publishTrafficSegmentationRuleSetup = () => publishThisTrafficSegmentationRuleSetup(listRankingSegments);	

	const publishThisTrafficSegmentationRuleSetup = (segments: SegmentRankingApiType[]) => {
		setTrafficSegmentationRuleSetupPublication(t => ({...t, isPublishing: true}));
		const segmentGroupIds = segments.length > 0 ? segments[0].segments.map(s => s.segmentId) : [];
		const onSuccess = () => {
			setTrafficSegmentationRuleSetupPublication(t => ({...t, isPublishing: false}));
			systemServices.showSuccess('Segments have been published with success');
			syncTrafficSegmentationRuleInitial();
		};
		const onError = (error) => {
			setTrafficSegmentationRuleSetupPublication(t => ({...t, isPublishing: false}));
			console.log(error);
			systemServices.showError('An error occurred while publishing segmentation rule');
		};
		if (!trafficSegmentationRuleSetup?.id) {
			segmentRankingServices.createTrafficSegmentationRuleSetup({
				name: '',
				segmentGroupIds
			}, onSuccess, onError);
		} else {
			segmentRankingServices.updateTrafficSegmentationRuleSetup(trafficSegmentationRuleSetup.id, {
				segmentGroupIds
			}, onSuccess, onError);
		}
	};

	useEffect(() => {
		syncTrafficSegmentationRule();
		setCookieName(trafficSegmentationRuleSetup?.cookieName);
	}, [listRankingSegments, trafficSegmentationRuleSetup]);

	useEffect(() => {
		setTrafficSegmentationRuleSetupPublication(s => ({ ...s, showPublish: segmentType === 'Beyable' }));
	}, [segmentType]);


	useEffect(() => {
		updateListRankingSegments();
		getRankingList();
		refreshList();
	}, []);
	const changeSegmentGroupStatus = (segmentGroupId: string, status: SegmentGroupStatus) => {
		segmentGroupServices.updateSegmentGroupStatus(segmentGroupId, status, () => {
			systemServices.showSuccess(status === 'Live' ? 'Segmentation is running' : 'Stopped Segmentation');
			refreshList();
		}, err => {
			console.log(err);
			systemServices.showError(status === 'Live' ? 'An error occured while starting Segmentation' : 'An error occured while stopping Segmentation');
		});
	};
	const context: SegmentListContextType = {
		listRankingSegments,
		isLoading,
		noData,
		setdropDownisOpen,
		dropDownisOpen,
		setaddNewExternalAudience,
		addNewExternalAudience,
		externalAudienceObject,
		handleChangeValueExternalAudience,
		handleSaveExternalAudience,
		setexternalAudienceObject,
		rankingRules,
		segmentGroups,
		handleChooseRuleId,
		handleChooseRuleIdForUnMappedAudience,
		handleChooseRuleIdForMappedAudience,
		getEditURL,
		handleDeleteSegment,
		onDelete,
		sureToDelete,
		idToDelete,
		setSureToDelete,
		handleReorderSegments,
		handleChooseSegmentationType,
		handleDeleteSegmentRankingGroup,
		segmentType,
		cookieName,
		getManageURL,
		$routeParams,
		accountId,
		hasErrorOnExternalItem,
		trafficSegmentationRuleSetupPublication,
		publishTrafficSegmentationRuleSetup,
		changeSegmentGroupStatus
	};

	return (
		<SegmentGroupEditorContext.Provider
			value={context}>
			{props.children}
		</SegmentGroupEditorContext.Provider>
	);

};

type SegmentListContextType = {
	listRankingSegments: SegmentRankingApiType[];
	isLoading: boolean;
	noData: boolean;
	setdropDownisOpen: Dispatch<SetStateAction<boolean>>;
	dropDownisOpen: boolean;
	setaddNewExternalAudience: Dispatch<SetStateAction<boolean>>;
	addNewExternalAudience: boolean;
	externalAudienceObject: ExternalAudienceType;
	handleChangeValueExternalAudience: (n: string, v: string) => void;
	handleSaveExternalAudience: () => void;
	setexternalAudienceObject: Dispatch<SetStateAction<ExternalAudienceType>>;
	rankingRules: TypeRecommendationsObject[] | null;
	segmentGroups: SegmentGroupType[];
	handleChooseRuleId: (st: string) => void;
	handleChooseRuleIdForUnMappedAudience: (st: string, rule: any) => void;
	handleChooseRuleIdForMappedAudience: (st: string, name: string) => void;
	handleDeleteSegment: () => void;
	onDelete: (st: string) => void;
	sureToDelete: boolean;
	idToDelete: string | undefined;
	setSureToDelete: Dispatch<SetStateAction<boolean>>;
	handleReorderSegments: (element: SegmentRankingApiType) => void;
	handleChooseSegmentationType: (st: string) => void;
	handleDeleteSegmentRankingGroup: (st: string) => void;
	segmentType: string | undefined;
	cookieName: string | undefined;
	getEditURL: () => string;
	getManageURL: () => string;
	$routeParams: any;
	accountId: string;
	hasErrorOnExternalItem: boolean;
	trafficSegmentationRuleSetupPublication: TrafficSegmentationRuleSetupPublication;
	publishTrafficSegmentationRuleSetup: () => void;
	changeSegmentGroupStatus: (segmentGroupId: string, status: SegmentGroupStatus) => void,
}


export default CreateSegmentGroupEditorContextProvider;

export { useSegmentGroupEditorContext };
