/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { react2angular } from 'react2angular';
import '../../../../Styles/shepherd.css';
import './InspirationPanel.css';
import Modal from '../../../Components/Modal';
import TabBar from '../../../Components/TabBar';
import TabItem from '../../../Components/TabItem';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import { objectives, formats, modules } from './InspirationData';

import InspirationModule from './InspirationModule';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import inspien from '../../../Translation/jsonfiles/en/inspirations_panel_en.json';
import inspifr from '../../../Translation/jsonfiles/fr/inspirations_panel_fr.json';
import TitleInspirations from './TitleInspirations';

export default function InspirationsPanel(props) {

	const i18nL = localStorage.getItem('i18nL');
	const newInstanceI18nextInspir = i18next.createInstance();
	newInstanceI18nextInspir.init({
		lng: 'en-EN',                             
		resources: {
			'en-US': {
				common: inspien            
			},
			'en-EN': {
				common: inspien            
			},
			'fr-FR': {
				common: inspifr
			},
		}},
	(err, t) => {
		if (err) return console.log('something went wrong loading', err);
		newInstanceI18nextInspir.changeLanguage(JSON.parse(i18nL));
	});

	return (
		<I18nextProvider i18n={newInstanceI18nextInspir}>
			<InspirationsPanelInner {...props} />
		</I18nextProvider>
	);
}
function InspirationsPanelInner(props) {
	const [userPermissions, setUserPermissions] = useState({ isAdmin: false, permissions: {}});
	const [groupBy, setGroupBy] = useState();
	const [t] = useTranslation('common');
	const showNotAvailable = false;

	const getModulesByFormat = (modules, formatId) => {
		return modules.filter(module => {
			return module.formatId === formatId;
		});
	};

	const getModulesByObj = (modules, objId) => {
		return modules.filter(module => {
			return module.objId === objId;
		});
	};

	const filteredModules = modules.filter(m => {
		if (m.available === false && !showNotAvailable) return false;
		if (userPermissions.isAdmin) return true;
		if (m.adminOnly) return false;
		if (!m.permissions) return true;
		return m.permissions.every(p => userPermissions.permissions[p]);
	}).map(m => {
		const formatId = m.formatId;
		const objId = m.objId;
		if(formatId){
			m.format = formats.find(f => f.id == formatId);
		}
		if(objId){
			m.obj = objectives.find(o => o.id == objId);
		}
		return m;
	});
	
	const filteredObjectives =  objectives.filter(obj => {
		const objModules = getModulesByObj(filteredModules, obj.id);
		return objModules.length > 0;
	});

	const filteredFormats = formats.filter(format => {
		const formatModules = getModulesByFormat(filteredModules, format.id);
		return formatModules.length > 0;
	});

	const [isOppen, setIsOpen] = useState(false);
	const [selectedObjective, setSelectedObjective] = useState();
	const [selectedFormat, setSelectedFormat] = useState();
	const [selectedModules, setSelectedModules] = useState([]);

	const impersonatingServices = new ImpersonatingServices(
		props.$rootScope,
		props.$routeParams
	);
	
	useEffect(() => {
		props.$rootScope.$on('event:changeStateOfInspirationPanel', function (event, element) {
			props.$timeout(() => {
				setIsOpen(element);
			}, 100);
		});

		// Get localstorage
		const storage = localStorage.getItem('inspiration-last-tab');
		let tmpGroupBy = 'objective';
		let tmpObjective = filteredObjectives[0];
		let tmpFormat = filteredFormats[0];
		if (storage) {
			const storageData = JSON.parse(storage);
			if (storageData.groupBy) {
				tmpGroupBy = storageData.groupBy;
			}

			const format = filteredFormats.find(f => f.id == storageData.format);
			if (format) {
				tmpFormat = format;
			}

			const objective = filteredObjectives.find(o => o.id == storageData.objective);
			if (objective) {
				tmpObjective = objective;
			}
		}
		setGroupBy(tmpGroupBy);
		setSelectedFormat(tmpFormat);
		setSelectedObjective(tmpObjective);
	}, []);

	useEffect(() => {
		let tmpModules = [];
		if (groupBy == 'objective' && selectedObjective) {
			tmpModules = getModulesByObj(filteredModules, selectedObjective.id);
		}
		if (groupBy == 'format' && selectedFormat) {
			tmpModules = getModulesByFormat(filteredModules, selectedFormat.id);
		}
		setSelectedModules(tmpModules);

		localStorage.setItem('inspiration-last-tab', JSON.stringify({
			groupBy: groupBy,
			objective: selectedObjective ? selectedObjective.id : '',
			format: selectedFormat ? selectedFormat.id : ''
		}));
	}, [selectedObjective, selectedFormat, groupBy, userPermissions]);

	useEffect(() => {
		if (props.$rootScope && props.$rootScope.$$childHead.permissions && props.$rootScope.User){
			setUserPermissions({ isAdmin: impersonatingServices.isAnAdmin(), permissions: props.$rootScope.$$childHead.permissions || [] });
		}
	}, [props.$rootScope, props.$rootScope.$$childHead.permissions, props.$rootScope.User]);
	
	const closeModal = function () {
		props.$rootScope.$broadcast('event:changeStateOfInspirationPanel', false);
	};

	return (
		<>
			<Modal
				isOpen={isOppen}
				onClose={() => closeModal()}
				noClose={true}
				closeOnOverlay={true}
				animation={'slide_down'}
				fullScreen={true}
			>
				<div className="inspi_wrapper">
					<div className="inspi_header flex">
						<div className="flex_item_full">
							<TabBar hasBorder={false} size="xl" className="has_v_pad s_17">
								<TabItem
									message={t('inspirations.objectives')}
									onClick={() => setGroupBy('objective')}
									selected={groupBy == 'objective'}
								/>
								<TabItem
									message={t('inspirations.formatTypes')}
									onClick={() => setGroupBy('format')}
									selected={groupBy == 'format'}
								/>

							</TabBar>
						</div>
						<div className="flex_item_fix">
							<a className="panel_close" onClick={(e) => closeModal()}></a>
						</div>
					</div>
					<div className="inspi_body">
						{groupBy !== 'inspi' &&
							<div className="inspi_sidebar flex flex_v">
								<div className="inspi_sidebar_inner flex_item_full vscroll">
									{groupBy == 'objective' &&
										<ul className="inspi_obj_list">
											{filteredObjectives.map((obj) =>
												<li key={obj.id}>
													<a className={selectedObjective && selectedObjective.id == obj.id ? 'active flex' : 'flex'}
														onClick={() => setSelectedObjective(obj)}>
														<span className={obj.icon + ' inspi_obj_item_icon icon_32 icon_color_turquoise flex_item_fix'}></span>
														<span className="inspi_obj_item_label flex_item_full">{t('objectives.' + obj.id + '.label')}</span>
														<span className="inspi_obj_item_arrow icon_16 icon_right_arrow flex_item_fix"></span>
													</a>
												</li>
											)}
										</ul>
									}
									{groupBy == 'format' &&
										<ul className="inspi_format_list">
											{filteredFormats.map((format) =>
												<li key={format.id}>
													<a className={selectedFormat && selectedFormat.id == format.id ? 'active' : ''}
														onClick={() => setSelectedFormat(format)}>
														<img src={'/Assets/format/format_'+format.id+'.svg'} className="inspi_format_item_img" />
														<span className="inspi_format_item_label">{t('formats.' + format.id + '.label')}</span>
													</a>
												</li>
											)}
										</ul>
									}
								</div>
							</div>
						}

						{groupBy !== 'inspi' &&
							<div className="inspi_modules vscroll">
								<div className="inspi_modules_inner">
									<div className="inspi_modules_header">
										<TitleInspirations 
											selectedModules={selectedModules}
										/>
										{groupBy == 'objective' && selectedObjective &&
											<>
												<div className="inspi_modules_header_title">{t('objectives.' + selectedObjective.id + '.title')}</div>
												<div className="inspi_modules_header_desc grey_1">{t('objectives.' + selectedObjective.id + '.description')}</div>
											</>
										}
										{groupBy == 'format' && selectedFormat &&
											<>
												<div className="inspi_modules_header_title">{t('formats.' + selectedFormat.id + '.title')}</div>
												<div className="inspi_modules_header_desc grey_1">{t('formats.' + selectedFormat.id + '.description')}</div>
											</>
										}
									</div>
									<div className="inspi_modules_body">
										{selectedModules.map((module) =>
											<InspirationModule
												key={module.id + module.editor}
												module={module}
												showFormat={groupBy != 'format'}
												showObjective={groupBy != 'objective'}
												$routeParams={props.$routeParams}
												$rootScope={props.$rootScope}
											/>
										)}
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</Modal>
		</>
	);
}
angular
	.module('beyableSaasApp.InspirationsPanel', [])
	.component('inspirationspanel', react2angular(InspirationsPanel, ['isOppen', 'close'], ['$http', '$rootScope', '$timeout', '$routeParams', '$location', '$scope','AuthServices']));
