import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import 'react-image-crop/dist/ReactCrop.css';
import './stylesCrop.css';
import './ReactCrop.css';
import ReactCrop from 'react-image-crop';
import {centerAspectCrop,centerAspectCropLocked,checkIfNbIsANumber} from './utils';
import TwicTransformationsImgOptions from './TwicTransformationsImgOptions';
import ModalHeader from '../../Components/ModalHeader';
import ModalBody from '../../Components/ModalBody';
import ModalFooter from '../../Components/ModalFooter';
import Breadcrumb from '../../Components/Breadcrumb';
import Btn from '../../Components/Btn';
import styles from './Twic.module.css';
import {listOfFormat,} from './utils';
export default function TwicContainer({selectedZone,
	imgSelected,
	exportImage,
	onClose,
	
}) {
	const imgRef = useRef(null);
	const [imgSrc, setImgSrc] = useState('');
	const [crop, setCrop] = useState();

	const [imageDataInformations, setimageDataInformations] = useState();
	const [completedCrop, setCompletedCrop] = useState();
	const [aspect, setAspect] = useState(16 / 9);
	const [twicPics, settwicPics] = useState('');
	const [resize, setResize] = useState({
		width: '',
		height : ''
	});

	const [step, setstep] = useState(1);
	const [isLoadingImgPreview, setisLoadingImgPreview] = useState(false);
	const [urlWithCrop, seturlWithCrop] = useState('');
	const [imgWithTransformations, setimgWithTransformations] = useState();
	const [urlTransformation, seturlTransformation] = useState('');
	const [imgPathForCalculateCrop, setimgPathForCalculateCrop] = useState('');
	const [refreshIsNeed, setrefreshIsNeed] = useState(false);

	function calculateDimensionsCropFromImgSrcFromEditorSizeAndGetPreviewFromApiTwicPic(){
		// Ratio W = Width image source / w image preview = ratio
		const ratioW =(imageDataInformations.naturalWidth / imageDataInformations.clientWidth);		
		const W = (completedCrop.width);
		const H = (completedCrop.height);
		const X = (completedCrop.x);
		const Y = (completedCrop.y);

		const xTwicPicValue = Math.floor(X * ratioW);
		const yTwicPicValue =  Math.floor(Y * ratioW);
		const wTwicPicValue = Math.floor(W * ratioW);
		const hTwicPicValue =  Math.floor(H * ratioW);

		const hasErrorsInCalcul = checkIfNbIsANumber([
			xTwicPicValue,
			yTwicPicValue,
			wTwicPicValue,
			hTwicPicValue
		]);

		if(hasErrorsInCalcul.includes(false)){
			return;
		}

		let url =  `${imgPathForCalculateCrop}?twic=v1/crop=${wTwicPicValue}x${hTwicPicValue}@${xTwicPicValue}x${yTwicPicValue}`;
		seturlWithCrop(url);
		settwicPics('');
		getTwicPic(url);
	}
	useEffect(() => {
		if (step === 2) {
			calculateDimensionsCropFromImgSrcFromEditorSizeAndGetPreviewFromApiTwicPic();
		}
	}, [step]);

	function getDatasInformationsFromCurrentImgPreview(){
		const objWithCurrentImgWidthHeight = {
			naturalHeight : imgRef.current?.naturalHeight,
			naturalWidth : imgRef.current?.naturalWidth,
			clientHeight : imgRef.current?.clientHeight,
			clientWidth : imgRef.current?.clientWidth,
		};
		setrefreshIsNeed(false)	;
		setimageDataInformations(objWithCurrentImgWidthHeight);
	}

	useEffect(()=>{
		if(step == 1 ){
			getDatasInformationsFromCurrentImgPreview();
			setrefreshIsNeed(true);
		}
	},[imgRef.current, step, imgSrc]);

	useEffect(()=>{
		if(step !== 1) return;
		if(!imageDataInformations) return;
		if(!completedCrop) return;

		const ratioW =(imageDataInformations.naturalWidth / imageDataInformations.clientWidth);		
		const W = (completedCrop.width);
		const H = (completedCrop.height);

		const wTwicPicValue = Math.floor(W * ratioW);
		const hTwicPicValue =  Math.floor(H * ratioW);

		setResize({
			width: wTwicPicValue,
			height: hTwicPicValue
		});

	},[completedCrop]);

	function onTransformBannerUrlToImage(e) {
		const url = imgSelected.banner.imagePath;
		let urlWithoutCroop = '';
		if(url.includes('?twic=v1')){
			const index = url.indexOf('?twic=v1');  // Gets the first part url 
			urlWithoutCroop = url.substr(0, index); // Gets the first part
		}else{
			urlWithoutCroop = url;
		}
		setimgPathForCalculateCrop(urlWithoutCroop);
		fetch(urlWithoutCroop)
			.then(function (response) {
				return response.blob();
			}).then(function (myBlob) {
				const bytes = myBlob;
				const reader = new FileReader();
				reader.addEventListener('load', () =>{
					setImgSrc(reader.result?.toString() || '');
				}
				);
				reader.readAsDataURL(myBlob);
			});
	}
	useEffect(() => {
		onTransformBannerUrlToImage(imgSelected);
	},[imgSelected]);

	function handleClickStep(e) {
		setstep(e);
	}
	function getTwicPic(url) {
		setisLoadingImgPreview(true);
		axios.get(url,{ responseType:'blob' }).then(res => {
			var reader = new window.FileReader();
			reader.readAsDataURL(res.data); 
			reader.onload = function () {
				var imageDataUrl = reader.result;
				settwicPics(imageDataUrl);
				setTimeout(()=>{
					setisLoadingImgPreview(false);
				},800);
			};
		}).catch(err =>{
			setisLoadingImgPreview(false);
		});
	}

	function onImageLoad(e) {
		const { width, height } = e?.currentTarget || e;
		const zoneHeight = selectedZone?.extensions?.height ;
		const zoneWidth = selectedZone?.extensions?.width;
		const newAspect = zoneWidth / zoneHeight;
		if(selectedZone?.extensions?.locked || selectedZone?.locked){
			setAspect(newAspect);
		}else{
			setAspect(undefined);
		}
		const crop = selectedZone?.extensions?.locked ?
			centerAspectCropLocked(width, height, newAspect,selectedZone?.extensions?.width,selectedZone?.extensions?.height) : centerAspectCrop(width, height, newAspect);
		setCrop(crop);
	}

	function handleSelectZone(c) {
		setCompletedCrop(c);
	}

	function handleTryToCropSelection(_, percentCrop) {
		if (percentCrop.height === 0 && percentCrop.width === 0) {
			return;
		} else {
			setCrop(percentCrop);
		}
	}
	function handleExportTransformation(){
		exportImage(urlTransformation);
	}
	function handleStep(s){
		setstep(s);
	}

	return (
		<>
			<ModalHeader>
				<div className='flex'>
					<div className='flex_item_fix_equal'>
						Edit image
					</div>
					<div className='flex_item_auto modal_header_breadcrumb'>
						<Breadcrumb
							itemOfBreadcrumb={[
								{ index: 1, title: 'Crop', toGo: 1 },
								{ index: 2, title: 'Optimize',toGo: 2  }
							]}
							onClick={(s)=>handleStep(s)}
							stepSelected={step}
						/>
					</div>
					<div className='flex_item_fix_equal'></div>
				</div>
			</ModalHeader>
			{/* // ! Important the div must be in dom */}
			<ModalBody className='pos_rel' style={{display: step === 1 ? null : 'none'}}>
				<div className={styles.root}>
					<div className={styles.preview + ' ' + styles.preview_no_scroll}>
						{imgSrc &&
							<ReactCrop
								// locked={selectedZone?.extensions?.locked}
								crop={crop}
								onChange={(_, percentCrop) => handleTryToCropSelection(_, percentCrop)}
								onComplete={(c) => handleSelectZone(c)}
								aspect={aspect}
							>
								<img
									ref={imgRef}
									alt="Crop me"
									src={imgSrc}
									onLoad={onImageLoad}
								/>
							</ReactCrop>
						}
					</div>
				</div>
			</ModalBody>
			<ModalBody className='pos_rel' style={{display: step === 2 ? null : 'none'}}>
				<TwicTransformationsImgOptions 
					isLoadingImgPreview={isLoadingImgPreview}
					twicPics={twicPics} 
					url={urlWithCrop}
					refreshIsNeed={refreshIsNeed}
					imgWithTransformations={imgWithTransformations}
					setimgWithTransformations={setimgWithTransformations}
					seturlTransformation={seturlTransformation}
					urlOrigin={imgSelected.banner.imagePath}
					resize={resize}
					setResize={setResize}
				/>
			</ModalBody>
			{step === 1 &&
				<ModalFooter hasBorder={true}
					primaryAction={
						<Btn
							onClick={(e) => handleClickStep(2)}
							message="Next"
							iconPosition="after"
							icon="fas fa-long-arrow-alt-right"
							className="btn_w_l"
						/>
					}
					secondaryAction={
						<Btn
							onClick={(e) => onClose()}
							message="Cancel"
							style="ghost"
							color="secondary"
						/>
					}
				/>
			}
			{step === 2 &&
				<ModalFooter hasBorder={true}
					primaryAction={
						<Btn
							onClick={(e) => handleExportTransformation()}
							message="Done"
							className="btn_w_l"
						/>
					}
					secondaryAction={
						<Btn
							onClick={(e) => handleClickStep(1)}
							message="Back to crop"
							style="ghost"
							color="secondary"
							icon="fas fa-long-arrow-alt-left"
						/>
					}
				/>
			}
		</>
	);
}
