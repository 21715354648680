export function createProcessTypeList(enumObj: any): { key: string; label: string }[] {
	return Object.keys(enumObj).map(key => ({
		key,
		label: enumObj[key]
	}));
}
export function formatLabel(label: string): string {
	return label
		.replace(/([a-z])([A-Z])/g, '$1 $2')
		.replace(/^./, (str) => str.toUpperCase());
}