import React from 'react';
import { react2angular } from 'react2angular';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import NotificationCenterNavReporting from './NotificationCenterNavReporting';
import ReportingCampaign from '../../ReportingCampaign/ReportingCampaign';

export default function NotificationCenterReportingContainer({ $location, AuthServices, $http, $routeParams, $rootScope }) {
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;
	const isAdmin = impersonatingServices.isAnAdmin();

	const navReporting = () => <NotificationCenterNavReporting 
		isAdmin={isAdmin}
		$routeParams={$routeParams}
		accountId={accountId}
	/>;

	const loadCampaignFunction = (service) => (a, c, cc) => {
		service.getNotificationCenterMessagesCampaignsForAccount(a, (notifications) => {
			service.getNotificationCenterCampaignsForAccount(a, (inboxes) => {
				const allCpgs = inboxes.concat(notifications);
				c(allCpgs);
			}, cc);
		}, cc);
	};

	return <ReportingCampaign
		$location={$location}
		AuthServices={AuthServices}
		$http={$http}
		$routeParams={$routeParams}
		$rootScope={$rootScope}
		initialCampaignId={$location && $location.$$search.id}
		loadCampaignFunction={loadCampaignFunction}
		initialDimension={$location.$$search.dimension}
		navReporting={navReporting}
		getEditorFunction={service => (campaignId, callbackSuccess, callbackError) => service.getCampaignEditor(accountId, campaignId, callbackSuccess, callbackError)}
		shouldDisplayEditorLink={true}
		shouldDisplayStatus={true}
		shouldDisplayDates={true}
	></ReportingCampaign>;
}



angular.module('beyableSaasApp.NotificationCenterReportingCampaign', [])
	.component('notificationcenterreportingcampaign', react2angular(NotificationCenterReportingContainer, [], ['$http', '$rootScope', '$routeParams', '$location', 'AuthServices']));
