import React, { useEffect } from 'react';
import { useEmailRemarketing } from '../context/contextEmailRemarketing';
import SearchBar from '../../../../Components/SearchBar';
import SelectCustom from '../../../../Components/SelectCustom';
import Nav from '../../../../Components/SelectPicker/Nav.js';

const HeaderDashboard = () => {
	const {
		campaigns,
		filterState,
		setFilterState,
		setCampaignsSorted,
		setNavFilter,
		setisCustomSelected,
		isCustomSelected,
		customDateRange,
		setCustomDateRange,
		initialDates,
		navFilter,
		setValue,
		value
	} = useEmailRemarketing();
	const sortCampaigns = (cpg) => {
		return cpg.sort((a, b) => {
			if (a.isStopped !== b.isStopped) {
				return a.isStopped ? 1 : -1;
			}
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
			if (a.id < b.id) return -1;
			if (a.id > b.id) return 1;
			return 0;
		});
	};

	useEffect(() => {
		const filteredCampaigns = campaigns.filter(campaign => {
			const matchesFilterState = filterState === '' ||
				(filterState === 'ongoing' && !campaign.isStopped) ||
				(filterState === 'stopped' && campaign.isStopped) ||
				(filterState === 'past' && new Date(campaign.endDate) < new Date());
			return  matchesFilterState;
		});
		const resultName = filteredCampaigns.filter(line => {
			if (line.name !== null && line.name.toUpperCase().includes(value.toUpperCase())) return true;
			if (line.id !== null && line.id.toUpperCase().includes(value.toUpperCase())) return true;
			return false;
		});
		const finalArray = [...resultName];
		const uniqueItem = [...new Set(finalArray)];
		const arrayMaj = sortCampaigns(uniqueItem);
		setCampaignsSorted(arrayMaj);
	}, [campaigns, value, filterState, navFilter]);

	const optionList = [
		{ value: '', label: 'On going or Stopped' },
		{ value: 'ongoing', label: 'On going' },
		{ value: 'stopped', label: 'Stopped' },
		{ value: 'past', label: 'Past' }
	];

	function handleClickOriginal(obj) {
		setNavFilter({
			fromDate: obj.fromDate,
			toDate: obj.toDate
		});
	}

	useEffect(() => {
		if (initialDates) {
			setNavFilter({
				fromDate: initialDates.initialFrom,
				toDate: initialDates.initialTo
			});
		}
	}, [initialDates, setNavFilter]);

	return (
		<>
			<div className="modules-header">
				<h2>Email Remarketing</h2>
				{campaigns.length > 0 && (
					<div className="header_inbox">
						<a className="btnUi btn_primary" href="/CampaignsCrmOnSite/Create">
							<i className="fa fa-plus"></i>
							<span className="btn_txt">Create a remarketing campaign</span>
						</a>
						<Nav
							handleClick={handleClickOriginal}
							initialDates={initialDates}
							showComparisonToggler={false}
							setisCustomSelected={setisCustomSelected}
							isCustomSelected={isCustomSelected}
							customDateRange={customDateRange}
							setCustomDateRange={setCustomDateRange}
						/>
						
					</div>
				)}
			</div>
			{campaigns.length > 0 && (
				<aside>
					<SearchBar
						onChange={(e) => setValue(e)}
						valueInSearchBar={value}
						placeholder={'Search for a campaign remarketing'}
						fullWidth={false}
						size="Full"
						width="l"
					/>
					<SelectCustom
						value={filterState}
						optionsList={optionList}
						onChangeFunction={(e) => setFilterState(e.target.value)}
					/>
				</aside>
			)}
		</>
	);
};

export default HeaderDashboard;
