import React, {ReactElement} from 'react';
import angular from 'angular';
import { react2angular } from 'react2angular';
import NavigationFunnelContextProvider from '../../../../context/ContextNavigationFunnel';

import {NavigationContainer} from '../../NavigationContainer';
type Props = {
	$http: any
	$rootScope: any
	$routeParams:any
	AuthServices:any
	$location:any
	$timeout:any
	children: React.ReactNode
}
export const NavigationFunnelContainer = (props:Props):ReactElement => {


	if(location.pathname !== '/Insight/funnel'){
		return <></>;
	}

	return (
		<NavigationFunnelContextProvider
			$http={props.$http}
			$routeParams={props.$routeParams}
			AuthServices={props.AuthServices}
			$timeout={props.$timeout}
			$location={props.$location}
			$rootScope={props.$rootScope}
		>
			<NavigationContainer />
		</NavigationFunnelContextProvider>
	);
};
angular.module('beyableSaasApp.NavigationFunnel', [])
	.component(
		'navigationfunnel',
		react2angular(
			NavigationFunnelContainer,
			[],
			['$http', '$rootScope', '$routeParams', '$location', '$timeout', '$scope']
		)
	);