import React, {useState, useEffect} from 'react';
import {Section} from '../../../../Components/Section/Section';
import { Article } from '../../../../Components/Article/Article';
import {useProductEmerchInsightsContext} from '../../context/ContextEmerchInsight';
import ChartComponent from './ChartEmerch';
import ChartEmerchComparison from './ChartEmerchComparison.jsx';
import NavGlobal from '../Nav/NavGlobal.js';
import { Spinner } from '../../../../Components/Spinner/Spinner';
import Btn from '../../../../Components/Btn';
import { PageBreadcrumb } from '../../../../Components/Page/PageBreadcrumb';
import { EmptyState } from '../../../../Components/EmptyState/EmptyState';
import SelectDropdown from '../../../../Components/SelectDropdown.js';
import { KPIArticle, KPIArticleList } from '../../../../Components/KPI/KPI';
import {SESSION_STORAGE_KEY, transformToDataStructureArray} from '../utils';
import Chip from '../Chip/Chip';
import {FilterProductInsightsPanel} from '../FilterPanel/FilterProductInsightsPanel';
import SelectMetric from './SelectEmerchAxis';
import { getFormattedNumber, getFormattedPercent } from '../../../../Util/numberFormats';
import styles from '../ProductsInsights.module.css';
import {TableEmerch} from './TableEmerch';
import {AccountHasNoData} from '../EmptyStates/AccountHasNoData';
import { useTranslation } from 'react-i18next';

export const EMerchContainer = ():React.ReactElement => {

	const {
		isLoading,

		visitsAverage,
		addToCartRateAverage,
		conversionRateAverage,

		visitsAverageComparison,
		addToCartRateAverageComparison,
		conversionRateAverageComparison,

		emercheOrdinate,
		tagStudiedEl,
		handleSelectComparaisonPeriodHandler,
		// navFilter,
		initialDates,
		setNavFilter,
		isCustomSelected,
		setisCustomSelected,
		customDateRange,
		setCustomDateRange,
		updateStorage,
		ComparisonSelected,
		setEmerchOrdinate,
		handleChangeFormData,
		handleOpenClosePanelForm,
		panelFormIsOpen,
		filterHierarchies,
		selectedValues,
		setSelectedValues,
		chipsByFilterSelected,
		setFilterSelected,
		categoryTypeSelected,
		categories,
		handleSelectNewCategory,
		navFilter,
		accountHasNoDatas
	} = useProductEmerchInsightsContext();

	const [t] = useTranslation('kpi');

	function handleDeleteChip(id){
		const newObj = JSON.parse((JSON.stringify(selectedValues)));
		delete newObj[id];
		const newFilters = transformToDataStructureArray(newObj);
		sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(newObj));
		setFilterSelected(newFilters);
		setSelectedValues(newObj);
	}

	const [isEmpty, setIsEmpty] = useState(false);
	const [isFirstLoading, setIsFirstLoading] = useState(true);
	const [visitsAverageUplift, setVisitsAverageUplift] = useState<false|number>(0);
	const [addToCartRateAverageUplift, setAddToCartRateAverageUplift] = useState<false|number>(0);
	const [conversionRateAverageUplift, setConversionRateAverageUplift] = useState<false|number>(0);
	
	useEffect(() => {
		const isEmpty = !(tagStudiedEl && tagStudiedEl[0] && tagStudiedEl[0].data && tagStudiedEl[0].data.length);
		setIsEmpty(isEmpty);
	}, [tagStudiedEl]);

	useEffect(() => {
		if (isLoading === false) setIsFirstLoading(false);
	}, [isLoading]);

	useEffect(() => {
		setVisitsAverageUplift(getUplift(visitsAverageComparison, visitsAverage));
	}, [visitsAverage, visitsAverageComparison]);

	useEffect(() => {
		setAddToCartRateAverageUplift(getUplift(addToCartRateAverageComparison, addToCartRateAverage));
	}, [addToCartRateAverage, addToCartRateAverageComparison]);

	useEffect(() => {
		setConversionRateAverageUplift(getUplift(conversionRateAverageComparison, conversionRateAverage));
	}, [conversionRateAverage, conversionRateAverageComparison]);

	function getUpliftColor(uplift) {
		if (uplift == 0) return 'c_warning';
		if (uplift > 0) return 'c_success';
		return 'c_alert';
	}

	function getUplift(a, b) {
		if (a === 0 || b === 0) return false;
		return (b - a) / a * 100;
	}

	return (
		<>
			{accountHasNoDatas &&
				<AccountHasNoData />
			}
			{!accountHasNoDatas &&
				<>
					<FilterProductInsightsPanel
						selectedValues={selectedValues}
						setSelectedValues={setSelectedValues}
						filterHierarchies={filterHierarchies}
						panelFormIsOpen={panelFormIsOpen}
						handleOpenClosePanelForm={handleOpenClosePanelForm}
						handleChangeFormData={handleChangeFormData}
					/>
					<Section hasMargin={false} width="l">
						<section className='section no_bottom_pad section_primary'>
							<PageBreadcrumb>
								<span className='h1'>{t('productInsights.productInsights')}</span>
								<span className='h1'>{t('productInsights.categoryPerformance')}</span>
							</PageBreadcrumb>
						</section>
						<section className='section'>
							<div className={styles.filters_line}>
								<div className='btn_group_l'>
									<NavGlobal
										ComparisonSelected={ComparisonSelected}
										setNavFilter={setNavFilter}
										initialDates={initialDates}
										navFilter={navFilter}
										handleSelectComparaisonPeriodHandler={handleSelectComparaisonPeriodHandler}
										setisCustomSelected={setisCustomSelected}
										isCustomSelected={isCustomSelected}
										customDateRange={customDateRange}
										setCustomDateRange={setCustomDateRange}
										updateStorage={updateStorage}
									/>
									<SelectDropdown
										label={'Category type'}
										labelPosition="inner"
										color="white"
										optionsList={categories}
										value={categoryTypeSelected?.value}
										onChange={(v) => handleSelectNewCategory(v)}
									/>
									<Btn
										style={'outline'}
										color={'secondary'}
										icon={'fa fa-filter'}
										tooltip="Filter by category"
										size="l"
										horizontalSize="xs"
										onClick={()=>handleOpenClosePanelForm(true)}
									/>
									{chipsByFilterSelected.map(ch => {
										return <Chip
											key={ch.type}
											type={ch.type}
											title={ch.title}
											handleDelete={() => handleDeleteChip(ch.type)}
										/>;
									})}
								</div>
							</div>
							{(!isEmpty || isLoading) &&
								<>
									{!ComparisonSelected &&
										<KPIArticleList>
											<KPIArticle
												isLoading={isLoading}
												label={'Average number of visits'}
												value={getFormattedNumber(visitsAverage)}
											/>
											<KPIArticle
												isLoading={isLoading}
												label={'Average conversion rate'}
												value={getFormattedPercent(conversionRateAverage, 2)}
											/>
											<KPIArticle
												isLoading={isLoading}
												label={'Average add-to-cart rate'}
												value={getFormattedPercent(addToCartRateAverage, 2)}
											/>
										</KPIArticleList>
									}
									{ComparisonSelected &&
										<KPIArticleList>
											<KPIArticle
												isLoading={isLoading}
												label={'Average number of visits'}
											>
												<div className='mb_3'>
													<span className='s_22 fw_medium'>
														{typeof visitsAverageUplift === 'number' ?
															<span className={getUpliftColor(visitsAverageUplift)}>
																{getFormattedPercent(visitsAverageUplift, 2, true)}
															</span>
															:
															<>-</>
														}
													</span>
												</div>
												<div className='flex s_13'>
													<div className='flex_item_fix'>
														{getFormattedNumber(visitsAverageComparison)}
													</div>
													<i className='fas fa-arrow-right s_11 ml_3 mr_3 grey_4'></i>
													<div className='flex_item_fix'>
														{getFormattedNumber(visitsAverage)}
													</div>
												</div>
											</KPIArticle>
											<KPIArticle
												isLoading={isLoading}
												label={'Average conversion rate'}
											>
												<div className='mb_3'>
													<span className='s_22 fw_medium'>
														{typeof conversionRateAverageUplift === 'number' ?
															<span className={getUpliftColor(conversionRateAverageUplift)}>
																{getFormattedPercent(conversionRateAverageUplift, 2, true)}
															</span>
															:
															<>-</>
														}
													</span>
												</div>
												<div className='flex s_13'>
													<div className='flex_item_fix'>
														{getFormattedPercent(conversionRateAverageComparison, 2)}
													</div>
													<i className='fas fa-arrow-right s_11 ml_3 mr_3 grey_4'></i>
													<div className='flex_item_fix'>
														{getFormattedPercent(conversionRateAverage, 2)}
													</div>
												</div>
											</KPIArticle>
											<KPIArticle
												isLoading={isLoading}
												label={'Average add-to-cart rate'}
											>
												<div className='mb_3'>
													<span className='s_22 fw_medium'>
														{typeof addToCartRateAverageUplift === 'number' ?
															<span className={getUpliftColor(addToCartRateAverageUplift)}>
																{getFormattedPercent(addToCartRateAverageUplift, 2, true)}
															</span>
															:
															<>-</>
														}
													</span>
												</div>
												<div className='flex s_13'>
													<div className='flex_item_fix'>
														{getFormattedPercent(addToCartRateAverageComparison, 2)}
													</div>
													<i className='fas fa-arrow-right s_11 ml_3 mr_3 grey_4'></i>
													<div className='flex_item_fix'>
														{getFormattedPercent(addToCartRateAverage, 2)}
													</div>
												</div>
											</KPIArticle>
										</KPIArticleList>
									}
								</>
							}
							{isFirstLoading &&
								<Article innerSize='l' hasMargin={false} className={styles.article}>
									<Spinner display="block" verticalSize="xl" />
								</Article>
							}

							{!isFirstLoading &&
								<>
									<Article innerSize='l' hasMargin={false} className={styles.article}>
										{isLoading &&
											<Spinner display="overlay" />
										}
										<div className='flex mb_10'>
											<div className='flex_item_full'>
												<SelectMetric
													onSelectMetric={setEmerchOrdinate}
													metricSelected={emercheOrdinate}
												/>
											</div>
											<div className='flex_item_fix ml_20'>
												<span className='s_13 grey_2'>
													<span className='fw_medium'>Bubble size:</span>
													{' '}Turnover
												</span>
											</div>
										</div>
									
										{isEmpty &&
											<EmptyState
												title="No categories match these filters"
												text="Maybe try with other filters"
												imageUrl='/Assets/empty_no_data.svg'
												verticalSize='xl'
												textSize='l'
											/>
										}
										{!isEmpty && 
											<>
												{!ComparisonSelected &&
													<ChartComponent
														visitsAverage={visitsAverage}
														addToCartRateAverage={addToCartRateAverage}
														conversionRateAverage={conversionRateAverage}
														emercheOrdinate={emercheOrdinate}
														tagsStudied={tagStudiedEl}
													/>
												}
												{ComparisonSelected &&
													<ChartEmerchComparison
														emercheOrdinate={emercheOrdinate}
														tagStudiedEl={tagStudiedEl}
														visitsAverage={visitsAverage}
														addToCartRateAverage={addToCartRateAverage}
														conversionRateAverage={conversionRateAverage}
														visitsAverageComparison={visitsAverageComparison}
														addToCartRateAverageComparison={addToCartRateAverageComparison}
														conversionRateAverageComparison={conversionRateAverageComparison}
													/>
												}
											</>
										}
									</Article>
									{!isEmpty && 
										<Article innerSize='l' hasMargin={false} className={styles.article}>
											<TableEmerch
												tagStudiedEl={tagStudiedEl}
												categoryTypeSelected={categoryTypeSelected?.value}
											/>
										</Article>
									}
								</>
							}
						</section>
					</Section>
				</>
			}
		</>
	);
};

