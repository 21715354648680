import React, { useState, useEffect } from 'react';
import { SplitBySelect } from './SplitBySelect';
import styles from '../../funnel.module.css';

import IconBtn from '../../../../../../Components/IconBtn';

export const FilterHead = ({
	context
}:any) :React.ReactElement=> {

	const {
		feetchingData,
		listOfDimensionsSplit
	} = context;

	function getLimitedList(list) {
		return list.slice(0, 6);
	}

	const [finalList, setFinalList] = useState([]);
	const [isLimited, setIsLimited] = useState(false);
	const [wantLimited, setWantLimited] = useState(true);

	useEffect(() => {
		const tmpList = getLimitedList(listOfDimensionsSplit || []); 
		setFinalList(wantLimited ? tmpList : listOfDimensionsSplit);
		setIsLimited(tmpList.length < listOfDimensionsSplit.length);
	}, [listOfDimensionsSplit, wantLimited]);

	if (feetchingData) return <></>;

	return (
		<div className={styles.header}>
			<div className='flex flex_justify_between'>
				<div className='flex_item_fix flex h3'>
					<span>Sessions</span>
					<SplitBySelect context={context}/>
				</div>
				<div className='flex_item_auto ml_40 flex'>
					<div className='flex_item_auto'>
						<ul className={styles.legendList}>
							{finalList.map((elementDimension:any,idx) =>
								<div key={idx} className={styles.legendItem}>
									<div className={[styles.legendItemColor, styles['color_' + elementDimension.color]].join(' ')}></div>
									<div className={styles.legendItemLabel}>{elementDimension.label}</div>
								</div>
							)}
						</ul>
					</div>
					{isLimited &&
						<div className='flex_item_fix ml_10'>
							<IconBtn
								tooltip={wantLimited ? 'More legend items' : 'Less legend items'}
								icon={wantLimited ? 'fas fa-ellipsis-h' : 'fas fa-minus'}
								onClick={() => setWantLimited(!wantLimited)}
							/>
						</div>
					}
				</div>
			</div>
		</div>
	);
};
