import React from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../Components/Panel/Panel';
import { useEmailTemplateListContext } from './context/EmailTemplateListContextProvider';
//import { useTranslation } from 'react-i18next';
import CodeSnippet from '../../../Components/CodeSnippet';
import SelectDropdown from '../../../Components/SelectDropdown';
import SectionMessage from '../../../Components/SectionMessage';
import Fieldset from '../../../Components/Fieldset';
import Btn from '../../../Components/Btn';
import { Article } from '../../../Components/Article/Article';
import { EmptyState } from '../../../Components/EmptyState/EmptyState';

export default function ModalHmlTemplate() {

	//const [t] = useTranslation('common'); 
	const {
		modaHtmlIsOpen,
		handleOpenViewWidgetModal,
		emailTemplateRenderingModel,
		handleSelectTenantForRendering,
		handleValidateSelectedTenantForRendering,
	} = useEmailTemplateListContext();

	// 	const html = `
	// <div style="background-color:#FAFAFA;">
	//     <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
	//     <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
	//       <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;">
	//         <tbody>
	//           <tr>
	//             <td style="direction:ltr;font-size:0px;padding:8px;text-align:center;">
	//               <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td align="center" class="" style="vertical-align:top;width:584px;" ><![endif]-->
	//               <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
	//                 <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
	//                   <tbody>
	//                     <tr>
	//                       <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
	//                         <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
	//                           <tbody>
	//                             <tr>
	//                               <td style="width:172px;">
	//                                 <a href="https://mail.beyable.com/reco/19BE8302" target="_blank"></a>
	//                               </td>
	//                             </tr>
	//                           </tbody>
	//                         </table>
	//                       </td>
	//                     </tr>
	//                   </tbody>
	//                 </table>
	//               </div>
	//               <!--[if mso | IE]></td></tr></table><![endif]-->
	//             </td>
	//           </tr>
	//         </tbody>
	//       </table>
	//     </div>
	//     <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#f4f1ed" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><v:rect style="width:600px;" xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false"><v:fill origin="0.5, 0" position="0.5, 0" src="https://tag.beyable.com/assets/de8ef2cc-40c2-40c9-bda0-e23d66f6168b/logo-d2b32b56-2ffa-4fd7-ab79-23b55c8b39c1.jpg" color="#f4f1ed" type="tile" /><v:textbox style="mso-fit-shape-to-text:true" inset="0,0,0,0"><![endif]-->
	//     <div style="background:#f4f1ed url(https://tag.beyable.com/assets/de8ef2cc-40c2-40c9-bda0-e23d66f6168b/logo-d2b32b56-2ffa-4fd7-ab79-23b55c8b39c1.jpg) center top / auto repeat;background-position:center top;background-repeat:repeat;background-size:auto;margin:0px auto;max-width:600px;">
	//       <div style="line-height:0;font-size:0;">
	//         <table align="center" background="https://tag.beyable.com/assets/de8ef2cc-40c2-40c9-bda0-e23d66f6168b/logo-d2b32b56-2ffa-4fd7-ab79-23b55c8b39c1.jpg" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#f4f1ed url(https://tag.beyable.com/assets/de8ef2cc-40c2-40c9-bda0-e23d66f6168b/logo-d2b32b56-2ffa-4fd7-ab79-23b55c8b39c1.jpg) center top / auto repeat;background-position:center top;background-repeat:repeat;background-size:auto;width:100%;">
	//           <tbody>
	//             <tr>
	//               <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:56px;padding-left:26px;padding-right:26px;padding-top:56px;text-align:center;">
	//                 <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td align="center" class="" style="vertical-align:top;width:356.2px;" ><![endif]-->
	//                 <div class="mj-column-per-65 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
	//                   <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
	//                     <tbody>
	//                       <tr>
	//                         <td style="background-color:#ffffff;vertical-align:top;padding:16px;">
	//                           <table border="0" cellpadding="0" cellspacing="0" role="presentation" style width="100%">
	//                             <tbody>
	//                               <tr>
	//                                 <td align="center" style="font-size:0px;padding:10px 25px;padding-right:0;padding-left:0;word-break:break-word;">
	//                                   <div style="font-family:Nunito,Verdana,sans-serif;font-size:18px;line-height:1.4;text-align:center;text-transform:uppercase;color:#3d3d3d;">Votre panier vous attend !</div>
	//                                 </td>
	//                               </tr>
	//                               <tr>
	//                                 <td align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;padding-top:4px;padding-right:0;padding-left:0;word-break:break-word;">
	//                                   <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;line-height:100%;">
	//                                     <tr>
	//                                       <td align="center" bgcolor="#5d804d" role="presentation" style="border:solid 2px #5d804d;border-radius:30px;cursor:auto;mso-padding-alt:7px 26px;background:#5d804d;" valign="middle">
	//                                         <a href="[PANIER]" style="display:inline-block;background:#5d804d;color:#ffffff;font-family:Nunito,Verdana,sans-serif;font-size:18px;font-weight:bold;line-height:1.4;margin:0;text-decoration:none;text-transform:none;padding:7px 26px;mso-padding-alt:0px;border-radius:30px;" target="_blank"> Voir mon panier </a>
	//                                       </td>
	//                                     </tr>
	//                                   </table>
	//                                 </td>
	//                               </tr>
	//                             </tbody>
	//                           </table>
	//                         </td>
	//                       </tr>
	//                     </tbody>
	//                   </table>
	//                 </div>
	//                 <!--[if mso | IE]></td><td align="center" class="" style="vertical-align:top;width:191.8px;" ><![endif]-->
	//                 <div class="mj-column-per-35 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
	//                   <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
	//                     <tbody>
	//                     </tbody>
	//                   </table>
	//                 </div>
	//                 <!--[if mso | IE]></td></tr></table><![endif]-->
	//               </td>
	//             </tr>
	//           </tbody>
	//         </table>
	//       </div>
	//     </div>
	// </div>
	// 	`.trim();

	if (emailTemplateRenderingModel === null) {
		return <></>;
	}
	const model = emailTemplateRenderingModel ?? { SelectedTenant: null, IsReadyForRendering: false, AvailableTenants: null, EmailTemplateId: null, NeedsSelectTenant: false, RenderingResult: null };
	const shouldDisplayNotReady = !model.IsReadyForRendering;
	const shouldDisplayTenantSelection = model.IsReadyForRendering && model.NeedsSelectTenant;
	const shouldDisplayRenderedContent = !shouldDisplayNotReady && !shouldDisplayTenantSelection;

	

	const displayRenderedContent = () => {
		const shouldDisplaySelectTenants = (model.AvailableTenants?.length ?? 0) > 1 ? true : false;
		const shouldDisplayCurrentTenant = model.SelectedTenant != null && model.AvailableTenants != null && (model.AvailableTenants.length === 1 && model.AvailableTenants[0] != '_default');
		return (
			<>
				{
					shouldDisplayCurrentTenant &&
					<SelectDropdown
						label="Current tenant"
						value={model.SelectedTenant}
						disabled={true}
						optionsList={[{ value: model.SelectedTenant, label: model.SelectedTenant }]}
					/>
				}
				{shouldDisplaySelectTenants &&
					displayTenantSelection(true)
				}
				<Fieldset label="HTML code">
					<SectionMessage
						text="Insert the HTML code below into your email template where desired."
						appearance="background_outline"
						verticalSize="xs"
						className="mt_5"
					/>
					<CodeSnippet
						code={model.RenderingResult?.trim()}
						rows="10"
					/>
				</Fieldset>
			</>
		);
	};

	const displayTenantSelection = (fromRendering: boolean) =>
		<>
			<SelectDropdown
				label="Choose the tenant to use"
				value={model.SelectedTenant ?? ''}
				onChange={(v) => handleSelectTenantForRendering(v, fromRendering)}
				optionsList={model.AvailableTenants?.map(tenant => ({ value: tenant, label: tenant }))}
			/>
			{!fromRendering &&
				<Btn
					message="Next"
					onClick={handleValidateSelectedTenantForRendering}
				/>
			}
		</>;

	const displayNotReady = () =>
		<EmptyState
			verticalSize="l"
			textSize="l"
			title="Not ready Yet"
			text="Your HTML code is not ready yet.. Please come back in a few minutes."
			imageUrl="/Assets/empty_email_widget.svg"
		/>;

	return (
		<Panel isOpen={modaHtmlIsOpen} width={740}>
			<PanelHeader
				title={'Email HTML code'}
				onClose={() => { handleOpenViewWidgetModal(false, null); }}
			/>
			<PanelBody color='grey'>
				{shouldDisplayNotReady && displayNotReady()}
				{!shouldDisplayNotReady &&
					<Article innerSize='l'>
						{shouldDisplayTenantSelection && displayTenantSelection(false)}
						{shouldDisplayRenderedContent && displayRenderedContent()}
					</Article>
				}
			</PanelBody>
		</Panel>
	);
}
