import React from 'react';
import ProductCard from '../ProductCard/ProductCard';
import PaginationProductList from './PaginationProductList';
import TableGridContainer from '../TableGridContainer';
import TableRowItem from '../TableRowItem';
import TableColItem from '../TableColItem';
import './NormalProduct.css';
import ProductItemDropDown from '../ProductCard/ProductItemDropDown';
import { isReplaceProduct } from '../../Ranking/utils';
import { useRankingContext } from '../../Ranking/context/RankingContextProvider';
import Collapse from '../../../../Components/Collapse';
import { ProductThumb } from '../../../../Components/Product/ProductThumb';
import { Article } from '../../../../Components/Article/Article';

const optionCssList = {
	gridTemplateColumns: '6fr 2fr 2f 2fr',
	userSelect: 'none',
};

const elementRanking = (elem, idInClipBoard) => {
	
	const Url = elem.url;

	return (
		<TableRowItem
			key={elem.id}
			specialClasseName={'normal_product table_body_row'}
			styleObj={optionCssList}>
			<TableColItem>
				<div className="flex">
					<div className="flex_item_fix">
						<a className="emerch_avatar emerch_avatar_margin"
							href={Url}
							target="_blank"
						>
							<ProductThumb
								alt={elem.id}
								url={elem.urlThumbnail}
								width={40}
							/>
						</a>
					</div>
					<div className="flex_item_full">
						<p className="table_row_name">{elem.name}</p>
					</div>
				</div>
			</TableColItem>
			<TableColItem>
				<p>{elem.price}</p>
			</TableColItem>
			<TableColItem>
				<p>{elem.id}</p>
			</TableColItem>
			<TableColItem specialClasseName={'normal_product_table_actions'}>
				<div className='normal_Product_actions'>
					<ProductItemDropDown
						idProduct={elem.id}
						idInClipBoard={idInClipBoard}
					/>
				</div>
			</TableColItem>
		</TableRowItem>
	);
};
function getTableProducts(productsToMaped, idInClipBoard) {
	return (
		<TableGridContainer specialClasseName="table_grid_emerch">
			<TableRowItem specialClasseName={'table_head_row'} styleObj={optionCssList}>
				<TableColItem>
					Product name
				</TableColItem>
				<TableColItem>
					Price
				</TableColItem>
				<TableColItem>
					Id
				</TableColItem>
				<TableColItem />
			</TableRowItem>
			{productsToMaped.map((elem) =>
				elementRanking(elem, idInClipBoard)
			)}
		</TableGridContainer>
	);
}

export default function NormalProducts({
	typeDimensions,
	screenTypeSelected,
	products,
}) {

	const {
		rankingObject,
		paginationOptions,
		handleChangePaginationOptions,
		currentPageProductList,
		setCurrentPageProductList,
		setNewPaginationNeed,
		idInClipBoard,
		copyToClipBoard,
		categorySelected,
		typeCategoriesSecondLevelSelected,
		promotedProducts,
		demotedProduct
	} = useRankingContext();

	const innerContent = (
		<>
			{screenTypeSelected === 'grid' &&
				<div className="collection_card_list">
					{products.pageItems.map(p => {
						const isReplace = isReplaceProduct(rankingObject.transformations, p.id);
						return <ProductCard
							key={p.id}
							isReplace={isReplace}
							product={p}
							idInClipBoard={idInClipBoard}
							copyToClipBoard={(e) => copyToClipBoard(e)}
						/>;
					})}
				</div>
			}
			{screenTypeSelected === 'list' &&
				<>{getTableProducts(products.pageItems, idInClipBoard)}</>
			}
			<PaginationProductList
				categorySelected={categorySelected}
				typeCategoriesSecondLevelSelected={typeCategoriesSecondLevelSelected}
				totalCount={products.totalCount}
				pageSize={20}
				paginate={(e) => {
					handleChangePaginationOptions('poffset', e >= 0 ? e : 0);
					setNewPaginationNeed(true);
				}}
				skipIdNumber={paginationOptions.poffset}
				currentPage={currentPageProductList}
				setCurrentPage={(e) => setCurrentPageProductList(e)}
			/>
		</>
	);

	const needCollapse = (promotedProducts.pageItems && promotedProducts.pageItems.length > 0) || (demotedProduct.pageItems && demotedProduct.pageItems.length > 0);

	if (needCollapse) {
		return (
			<Collapse
				label={typeDimensions === 'ranking' ? 'Others Products' : 'Products'}
				defaultOpen={true}>
				{screenTypeSelected === 'grid' ?
					<>{innerContent}</>
					:
					<Article hasMargin={false}>{innerContent}</Article>
				}
			</Collapse>
		);
	} else {
		return (
			<>
				{screenTypeSelected === 'grid' ?
					<>{innerContent}</>
					:
					<Article hasMargin={false}>{innerContent}</Article>
				}
			</>
		);
	}
}
