import styles from '../ProductsInsights.module.css';
import React, { useState, Fragment } from 'react';
import TableGridContainer from '../../../../Components/Table/TableGridContainer';
import TableHeadRow from '../../../../Components/Table/TableHeadRow';
import TableHeadCol from '../../../../Components/Table/TableHeadCol';
import TableRowItem from '../../../../Components/Table/TableRowItem.js';
import TableColItem from '../../../../Components/Table/TableColItem.js';
import { getFormattedNumber, getFormattedPercent } from '../../../../Util/numberFormats';

const tableRowStyle = { gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr' };

function getProgress(a, b) {
	return (b || 0) - (a || 0);
}

type Props = {
	categoryTypeSelected: undefined | string;
	tagStudiedEl: any;
};

export const TableEmerch = ({
	categoryTypeSelected,
	tagStudiedEl
}: Props) => {

	const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>({ key: 'tagName', direction: 'asc' });

	if (!tagStudiedEl) return <></>;

	const requestSort = (key: string, defaultDesc = 'desc') => {
		let direction = defaultDesc;
		if (sortConfig && sortConfig.key === key) {
			direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
		}
		setSortConfig({ key, direction });
	};

	const sortedData = React.useMemo(() => {
		if (!tagStudiedEl || !tagStudiedEl[0]?.data) return [];
		const sortableItems = [...tagStudiedEl[0].data];
		if (sortConfig !== null) {
			sortableItems.sort((a, b) => {
				const key = sortConfig.key;
				let aValue = a[key];
				let bValue = b[key];

				if (key !== 'tagName') {
					aValue = parseFloat(aValue) || 0;
					bValue = parseFloat(bValue) || 0;
				}
				
				if (aValue < bValue) {
					return sortConfig.direction === 'asc' ? -1 : 1;
				}
				if (aValue > bValue) {
					return sortConfig.direction === 'asc' ? 1 : -1;
				}
				return 0;
			});
		}
		return sortableItems;
	}, [tagStudiedEl, sortConfig]);

	return (
		<>
			<TableGridContainer hasAlternatingBackground={true} specialClassName={styles.grid}>
				<TableHeadRow style={tableRowStyle} isSticky={true}>
					<TableHeadCol sort={sortConfig?.key === 'tagName' ? sortConfig.direction : null} sortable={true} onClick={() => requestSort('tagName', 'asc')} fontSize='s'>
						{categoryTypeSelected}
					</TableHeadCol>
					<TableHeadCol sort={sortConfig?.key === 'visits' ? sortConfig.direction : null} sortable={true} onClick={() => requestSort('visits')} fontSize='s' align="right">
						Visits
					</TableHeadCol>
					<TableHeadCol sort={sortConfig?.key === 'addToCart' ? sortConfig.direction : null} sortable={true} onClick={() => requestSort('addToCart')} fontSize='s' align="right">
						Add to cart
					</TableHeadCol>
					<TableHeadCol sort={sortConfig?.key === 'conversions' ? sortConfig.direction : null} sortable={true} onClick={() => requestSort('conversions')} fontSize='s' align="right">
						Conversions
					</TableHeadCol>
					<TableHeadCol sort={sortConfig?.key === 'amount' ? sortConfig.direction : null} sortable={true} onClick={() => requestSort('amount')} fontSize='s' align="right">
						Turnover
					</TableHeadCol>
					<TableHeadCol sort={sortConfig?.key === 'abandonmentsRate' ? sortConfig.direction : null} sortable={true} onClick={() => requestSort('abandonmentsRate')} fontSize='s' align="right">
						Abandonment rate
					</TableHeadCol>
					<TableHeadCol sort={sortConfig?.key === 'conversionsRate' ? sortConfig.direction : null} sortable={true} onClick={() => requestSort('conversionsRate')} fontSize='s' align="right">
						Conversion rate
					</TableHeadCol>
				</TableHeadRow>
				{sortedData.map((c, i) => 
					<Fragment key={c.tagName + '_' + i}>
						{tagStudiedEl.length === 1 &&
							<CategoryLine category={c} />
						}
						{tagStudiedEl.length === 2 &&
							<CategoryComparisonLine category={c} />
						}
					</Fragment>
				)}
			</TableGridContainer>
		</>
	);
};

function CategoryLine ({
	category
}) {
	return (
		<TableRowItem styleObj={tableRowStyle} key={category.tagName}>
			<TableColItem>
				<div className='table_name'>{category.tagName}</div>
			</TableColItem>
			<TableColItem align="right">{getFormattedNumber(category.visits)}</TableColItem>
			<TableColItem align="right">{getFormattedNumber(category.addToCart)}</TableColItem>
			<TableColItem align="right">{getFormattedNumber(category.conversions)}</TableColItem>
			<TableColItem align="right">{getFormattedNumber(category.amount, 2, '€')}</TableColItem>
			<TableColItem align="right">{getFormattedPercent(category.abandonmentsRate, 2)}</TableColItem>
			<TableColItem align="right">{getFormattedPercent(category.conversionsRate, 2)}</TableColItem>
		</TableRowItem>
	);
}

function CategoryComparisonLine ({
	category
}:any):JSX.Element {

	return (
		<TableRowItem styleObj={tableRowStyle} key={`${category.tagName} studied`}>
			<TableColItem>
				<div className='table_name'>{category.tagName}</div>
			</TableColItem>
			<Cell
				oldValue={getFormattedNumber(category.visitsComparison)}
				newValue={getFormattedNumber(category.visits)}
				showSign={true}
				progress={getProgress(category.visitsComparison, category.visits)}
			/>
			<Cell
				oldValue={getFormattedNumber(category.addToCartComparison)}
				newValue={getFormattedNumber(category.addToCart)}
				showSign={true}
				progress={getProgress(category.addToCartComparison, category.addToCart)}
			/>
			<Cell
				oldValue={getFormattedNumber(category.conversionsComparison)}
				newValue={getFormattedNumber(category.conversions)}
				showSign={true}
				progress={getProgress(category.conversionsComparison, category.conversions)}
			/>
			<Cell
				oldValue={getFormattedNumber(category.amountComparison, 2, '€')}
				newValue={getFormattedNumber(category.amount, 2, '€')}
				showSign={true}
				progress={getProgress(category.amountComparison, category.amount)}
			/>
			<Cell
				oldValue={getFormattedPercent(category.abandonmentsRateComparison, 2)}
				newValue={getFormattedPercent(category.abandonmentsRate, 2)}
				showSign={true}
				progress={getProgress(category.abandonmentsRateComparison, category.abandonmentsRate)}
			/>
			<Cell
				oldValue={getFormattedPercent(category.conversionsRateComparison, 2)}
				newValue={getFormattedPercent(category.conversionsRate, 2)}
				showSign={true}
				progress={getProgress(category.conversionsRateComparison, category.conversionsRate)}
			/>
		</TableRowItem>
	);
}

function Cell({
	oldValue,
	newValue,
	showSign = false,
	normalDirection = true,
	progress,
	align = 'right'
}: any): JSX.Element {

	return (
		<TableColItem
			align={align}
			tooltipHTML={
				<table className={styles.grid_cell_tooltip_table}>
					<tbody>
						<tr>
							<td><strong>Previous period</strong></td>
							<td>{oldValue}</td>
							<td rowSpan={2}>
								<Sign
									progress={progress}
									normalDirection={normalDirection}
								/>
							</td>
						</tr>
						<tr>
							<td><strong>Studied period</strong></td>
							<td>{newValue}</td>
						</tr>
					</tbody>
				</table>
			}>
			<div>
				<div className='s_13 grey_2'>{oldValue}</div>
				<div>{newValue}</div>
			</div>
			{showSign && 
				<Sign
					progress={progress}
					normalDirection={normalDirection}
					className="ml_5"
				/>
			}
		</TableColItem>
	);
}

function Sign({
	progress,
	normalDirection = true,
	className
}: any): JSX.Element {

	const cssClass = ['fa'];

	if (progress == 0) {
		cssClass.push('fa-equals');
		cssClass.push('c_warning');
	} else if (progress > 0) {
		cssClass.push('fa-arrow-up');
		cssClass.push(normalDirection ? 'c_success' : 'c_alert');
	} else if (progress < 0) {
		cssClass.push('fa-arrow-down');
		cssClass.push(normalDirection ? 'c_alert' : 'c_success');
	}

	if (className) {
		cssClass.push(className);
	}

	return (
		<i className={cssClass.join(' ')}></i>
	);
}
