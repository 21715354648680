import React, { useState, useEffect } from 'react';
import './TriggersContainer.css';
import TriggerCard from './TriggerCard';
import { v4 as uuidv4 } from 'uuid';
import Btn from '../../../Components/Btn';
import InputCustom from '../../../Components/InputCustom';
import SelectCustom from '../../../Components/SelectCustom';
import Modal from '../../../Components/Modal';
import useEventBroker from '../../../Hooks/useEventBroker';
import TriggerUtil from './TriggerUtil';

export default function PersonaTrigger({
	personaTriggers,
	dataTriggers,
	dataTriggerOperands,
	personaInfo,
	saveTriggerListCallback,
	includeDeviceSelector
}) {
	const [triggerList, setTriggerList] = useState([]);
	const [filteredTriggerList, setFilteredTriggerList] = useState([]);
	const [selectedTriggerWhen, setSelectedTriggerWhen] = useState();
	const [marketingPressureTrigger, setMarketingPressureTrigger] = useState();
	const [spentOnPageTrigger, setSpentOnPageTrigger] = useState();
	const [deviceTrigger, setDeviceTrigger] = useState();
	const [showTriggerOptions, setShowTriggerOptions] = useState();
	const [exitInfoModalIsOpen, setExitInfoModalIsOpen] = useState();

	const { publish, subscribe } = useEventBroker();
	const { getTheOptionsMultipleList, getDefaultValueList, getNewTriggerFromDataTrigger } = TriggerUtil();

	useEffect(() => {
		setTriggerList(personaTriggers);
	}, [personaTriggers]);

	useEffect(() => {
		const tmp = triggerList.filter(t => t.Name !== 'TRGSCROLL' && t.Name !== 'TRGEXITINTENT' && t.Name !== 'TRGMARKPRESSURE' && t.Name !== 'TRGTIMESPENTONPAGE' && t.Categorie !== 'Hidden');
		setFilteredTriggerList(tmp);
	}, [triggerList]);

	useEffect(() => {
		if (personaTriggers) {
			const triggerWhen = personaTriggers.find(f => f.Name == 'TRGSCROLL' || f.Name == 'TRGEXITINTENT');
			!triggerWhen ? setShowTriggerOptions('time') : triggerWhen.Name === 'TRGSCROLL' ? setShowTriggerOptions('scroll') : setShowTriggerOptions();
			setSelectedTriggerWhen(triggerWhen);

			const triggerSpentOnPage = personaTriggers.find(f => f.Name == 'TRGTIMESPENTONPAGE');
			setSpentOnPageTrigger(triggerSpentOnPage);
			if (selectedTriggerWhen && spentOnPageTrigger) {
				triggerSpentOnPage && deleteTrigger(triggerSpentOnPage.Id);
			}

			const marketingPressure = personaTriggers.find(f => f.Name == 'TRGMARKPRESSURE');
			setMarketingPressureTrigger(marketingPressure);

			const deviceSelector = personaTriggers.find(f => f.Name === 'TRGCHECKDEVICE');
			includeDeviceSelector && deviceSelector ? setDeviceTrigger(deviceSelector) : addDeviceTriggerIfNotExists();
		}
	}, [personaTriggers]);

	subscribe('evt/triggers/triggerSelected', e => {
		const { trigger } = e.detail;
		addNewTrigger(trigger);
	});

	const openTriggerSelector = () => {
		publish('evt/triggers/openTriggerSelectorRequested');
	};

	const changeSelectedTriggerWhen = (triggerWhenName) => {
		let updatedList;
		if (!selectedTriggerWhen && !triggerWhenName)
			return;
		if (selectedTriggerWhen && triggerWhenName === selectedTriggerWhen.Name)
			return;

		const index = triggerList.findIndex((f => f.Name == 'TRGSCROLL' || f.Name == 'TRGEXITINTENT'));

		if (!triggerWhenName) {
			if (index >= 0) {
				updatedList = deleteTriggerByIndex(index);
			}
			setSelectedTriggerWhen();
		}
		else {
			const trigger = dataTriggers.find(t => t.value === triggerWhenName);
			index >= 0 ? updatedList = replaceTrigger(index, trigger) : updatedList = addNewTrigger(trigger);
			setSelectedTriggerWhen(trigger);
		}
		triggerWhenName === 'TRGEXITINTENT' ? updateMarketingPressure('1', updatedList) : updateMarketingPressure('-1', updatedList);
	};


	const deleteTrigger = (id) => {
		let newTriggerList = [...triggerList.filter(t => t.Id != id)];
		setTriggerList(newTriggerList);
		saveTriggerListCallback(newTriggerList);
	};

	const deleteTriggerByIndex = (i) => {
		let newTriggerList = [...triggerList];
		newTriggerList.splice(i, 1);
		setTriggerList(newTriggerList);
		saveTriggerListCallback(newTriggerList);
		return newTriggerList;
	};

	const newTriggerFromDataTrigger = (trigger) => {
		let newTrigger = getNewTriggerFromDataTrigger(trigger, dataTriggerOperands);
		newTrigger.Group = { Id: personaInfo.Id, Label: personaInfo.Label, Index: personaInfo.Index, IsSavedPersona: personaInfo.IsSavedPersona };
		newTrigger.Id = uuidv4();
		return newTrigger;
	};

	const replaceTrigger = (i, trigger) => {
		let newTrigger = newTriggerFromDataTrigger(trigger);
		let newTriggerList = [...triggerList];
		newTriggerList.splice(i, 1);
		newTriggerList.push(newTrigger);
		setTriggerList(newTriggerList);
		saveTriggerListCallback(newTriggerList);
		return newTriggerList;
	};

	const addNewTrigger = (trigger) => {
		let newTrigger = newTriggerFromDataTrigger(trigger);
		newTrigger.ValueToCompare = trigger.value === 'TRGCHECKDEVICE' ? ['desktop'] : newTrigger.ValueToCompare;
		let newTriggerList = [...triggerList];
		newTriggerList.push(newTrigger);
		setTriggerList(newTriggerList);
		saveTriggerListCallback(newTriggerList);
		return newTriggerList;
	};

	const saveTriggerCallBack = (trigger) => {
		let newTriggerList = triggerList.map(t => t.Id != trigger.Id ? t : trigger);
		setTriggerList(newTriggerList);
		saveTriggerListCallback(newTriggerList);
	};

	const updateTriggerWhen = (triggerWhen) => {
		setSelectedTriggerWhen(triggerWhen);
		let newTriggerList = [...triggerList.filter(t => t.Id != triggerWhen.Id), triggerWhen];
		setTriggerList(newTriggerList);
		saveTriggerListCallback(newTriggerList);
	};


	const getMarketingPressureOptions = () => {
		const trigger = marketingPressureTrigger || newTriggerFromDataTrigger(dataTriggers.find(t => t.value === 'TRGMARKPRESSURE'));
		if (trigger)
			return getTheOptionsMultipleList(trigger);
		else[];
	};
	const getMarketingPressureValue = () => {
		const trigger = marketingPressureTrigger || newTriggerFromDataTrigger(dataTriggers.find(t => t.value === 'TRGMARKPRESSURE'));
		if (trigger)
			return getDefaultValueList(trigger) || getMarketingPressureOptions().find(t => t.value == -1);
		return [];
	};

	const updateMarketingPressure = (value, updatedList) => {
		const listOfTriggers = updatedList || triggerList;
		const marketingPressure = listOfTriggers.find((f => f.Name == 'TRGMARKPRESSURE'))
			|| newTriggerFromDataTrigger(dataTriggers.find(t => t.value === 'TRGMARKPRESSURE'));
		const newMarketingPressure = { ...marketingPressure, ValueToCompare: value };
		setMarketingPressureTrigger(newMarketingPressure);

		let newTriggerList = [...listOfTriggers.filter(t => t.Id != newMarketingPressure.Id), newMarketingPressure];
		setTriggerList(newTriggerList);
		saveTriggerListCallback(newTriggerList);
	};

	const updateTimeSpentOnPage = (value) => {
		const timeSpentOnPage = triggerList.find(f => f.Name === 'TRGTIMESPENTONPAGE')
			|| newTriggerFromDataTrigger(dataTriggers.find(t => t.value === 'TRGTIMESPENTONPAGE'));
		const newTimeSpentOnPage = { ...timeSpentOnPage, ValueToCompare: value };
		setSpentOnPageTrigger(newTimeSpentOnPage);

		let newTriggerList = [...triggerList.filter(t => t.Id != newTimeSpentOnPage.Id), newTimeSpentOnPage];
		setTriggerList(newTriggerList);
		saveTriggerListCallback(newTriggerList);
	};

	const addDeviceTriggerIfNotExists = () => {
		const defaultDeviceTrigger = newTriggerFromDataTrigger(dataTriggers.find(t => t.value === 'TRGCHECKDEVICE'));
		let newTriggerList = [...personaTriggers.filter(t => t.Id != defaultDeviceTrigger.Id), defaultDeviceTrigger];
		saveTriggerListCallback(newTriggerList);
	};

	const updateDeviceTrigger = (value) => {
		const triggerExist = triggerList.find((f => f.Name === 'TRGCHECKDEVICE'));
		const devices = triggerExist || newTriggerFromDataTrigger(dataTriggers.find(t => t.value === 'TRGCHECKDEVICE'));
		let tempArray = [];
		if (devices.ValueToCompare.indexOf(value) != -1 && devices.ValueToCompare != devices.DefaultLabel) {
			if (devices.ValueToCompare.length <= 1)
				return;
			let prevState = [...deviceTrigger.ValueToCompare];
			prevState.splice(prevState.indexOf(value), 1);
			tempArray = prevState;
		}
		else {
			if (triggerExist) {
				devices.ValueToCompare.map(e => tempArray.push(e));
			}
			tempArray.push(value);
		}
		const newDevices = { ...devices, ValueToCompare: tempArray };
		setDeviceTrigger(newDevices);

		let newTriggerList = [...triggerList.filter(t => t.Id != newDevices.Id), newDevices];
		setTriggerList(newTriggerList);
		saveTriggerListCallback(newTriggerList);
	};

	const getIcon = (trigger) => {
		const dataTrigger = dataTriggers.find(t => t.value === trigger.Name);
		return dataTrigger && dataTrigger.categorieIcon;
	};


	return (
		<div>
			<div>
				{includeDeviceSelector &&
					<>
						<div className="trigger_block_title">Which devices?</div>
						<div className="trigger_block">
							<Btn
								message="Desktop"
								icon="fas fa-desktop"
								style="outline"
								color="secondary"
								size="l"
								onClick={() => updateDeviceTrigger('desktop')}
								className={deviceTrigger && deviceTrigger.ValueToCompare.indexOf('desktop') > -1 ? 'active' : ''}
							/>
							<Btn
								message="Tablet"
								icon="fas fa-tablet-alt"
								style="outline"
								color="secondary"
								size="l"
								onClick={() => updateDeviceTrigger('tablet')}
								className={deviceTrigger && deviceTrigger.ValueToCompare.indexOf('tablet') > -1 ? 'active' : ''}
							/>
							<Btn
								message="Mobile"
								icon="fas fa-mobile-alt"
								style="outline"
								color="secondary"
								size="l"
								onClick={() => updateDeviceTrigger('mobile')}
								className={deviceTrigger && deviceTrigger.ValueToCompare.indexOf('mobile') > -1 ? 'active' : ''}
							/>
						</div>
					</>
				}

				<div className="trigger_block_title">When?</div>
				<div className="trigger_block">
					<div className="flex flex_wrap flex_align_stretch">
						<div className="flex_item_third has_gutter_s">
							<Btn
								style="outline"
								color="secondary"
								size="l"
								fullWidth={true}
								fullHeight={true}
								onClick={() => changeSelectedTriggerWhen()}
								className={!selectedTriggerWhen ? 'trigger_btn active' : 'trigger_btn'}
							>
								<div>
									<div className="trigger_btn_text">On page load</div>
									<div className="trigger_btn_desc">When the page is loaded</div>
								</div>
							</Btn>
						</div>
						<div className="flex_item_third has_gutter_s">
							<Btn
								style="outline"
								color="secondary"
								size="l"
								fullWidth={true}
								fullHeight={true}
								onClick={() => changeSelectedTriggerWhen('TRGEXITINTENT')}
								className={selectedTriggerWhen && selectedTriggerWhen.Name == 'TRGEXITINTENT' ? 'trigger_btn active' : 'trigger_btn'}
							>
								<div>
									<div className="trigger_btn_text">On exit-intent</div>
									<div className="trigger_btn_desc">When the visitor leaves the page</div>
								</div>
							</Btn>
						</div>
						<div className="flex_item_third has_gutter_s">
							<Btn
								style="outline"
								color="secondary"
								size="l"
								fullWidth={true}
								fullHeight={true}
								onClick={() => changeSelectedTriggerWhen('TRGSCROLL')}
								className={selectedTriggerWhen && selectedTriggerWhen.Name == 'TRGSCROLL' ? 'trigger_btn active' : 'trigger_btn'}
							>
								<div>
									<div className="trigger_btn_text">On scroll</div>
									<div className="trigger_btn_desc">When the visitor scrolls down the page</div>
								</div>
							</Btn>
						</div>
					</div>
					{selectedTriggerWhen && selectedTriggerWhen.Name == 'TRGEXITINTENT' &&
						<div className="info_box grey_2 mt_20 s_14 flex">
							<div className="info_box_label flex_item_fix">INFO</div>
							<div className="info_box_content flex_item_full">
								The exit-intent has not the exact same definition on desktop and mobile
								<a className="icon_btn s v_al_bl" onClick={(e) => setExitInfoModalIsOpen(true)}>
									<i className="fas fa-info-circle"></i>
								</a>
							</div>
						</div>
					}
				</div>
			</div>
			{showTriggerOptions && showTriggerOptions === 'time' &&
				<>
					<div className="trigger_block_title">Time spent on page</div>
					<div className="trigger_block grey_0">
						<span className="s_14 mr_10">Display after</span>
						<InputCustom
							type="number"
							min={0}
							className="w_xxs"
							value={spentOnPageTrigger ? spentOnPageTrigger.ValueToCompare : ''}
							onChange={(e) => updateTimeSpentOnPage(parseInt(e.target.value) || 0)}
							placeHolder="0"
							selectOnFocus={true}
						/>
						<span className="s_14 ml_10">seconds spent on the page</span>
					</div>
				</>
			}
			{showTriggerOptions && showTriggerOptions === 'scroll' &&
				<>
					<div className="trigger_block_title">Scroll settings</div>
					<div className="trigger_block grey_0">
						<span className="s_14 mr_10">Display after scrolling</span>
						<InputCustom
							type="number"
							min={0}
							max={100}
							className="w_xxs"
							value={selectedTriggerWhen ? selectedTriggerWhen.ValueToCompare === 0 ? '' : selectedTriggerWhen.ValueToCompare : ''}
							onChange={(e) => updateTriggerWhen({ ...selectedTriggerWhen, ValueToCompare: parseInt(e.target.value) || 0 })}
							unit="%"
							selectOnFocus={true}
							placeHolder="0"
						/>
						<span className="s_14 ml_10">of the page</span>
					</div>
				</>
			}



			{dataTriggers.length > 0 &&
				<>
					<div className="trigger_block_title">How many times?</div>
					<div className="trigger_block_desc">
						How many times the campaign can be displayed?
					</div>
					<div className="trigger_block">
						<SelectCustom
							optionsList={getMarketingPressureOptions()}
							value={getMarketingPressureValue().value}
							onChange={(e) => updateMarketingPressure(e.target.value)}
							autoWidth={true}
						/>
					</div>
				</>
			}

			<div className="flex">
				<div className="flex_item_full">
					<div className="trigger_block_title">Target conditions</div>
					<div className="trigger_block_desc">
						All conditions must be met to display the campaign (AND)
					</div>
				</div>
				{filteredTriggerList && filteredTriggerList.length > 0 &&
					<div className="flex_item_fix ml_20">
						<Btn
							message="Target condition"
							icon="fas fa-plus"
							onClick={() => openTriggerSelector()}
						/>
					</div>
				}
			</div>
			<div className="trigger_block">
				{filteredTriggerList && filteredTriggerList.length > 0 ?
					<div className="trigger_card_list">
						{filteredTriggerList.map((trigger) =>
							<TriggerCard
								trigger={trigger}
								icon={getIcon(trigger)}
								key={trigger.Id}
								saveTriggerCallBack={saveTriggerCallBack}
								deleteTrigger={
									<button className="trigger_card_delete flex_item_fix">
										<i className="fa fa-times"
											onClick={() => deleteTrigger(trigger.Id)}
										/>
									</button>}
							/>
						)}
					</div>
					:
					<div className="trigger_card_empty">
						<div className="empty_state">
							<div className="empty_state_title">No target condition yet</div>
							<div className="empty_state_btn">
								<Btn
									message="Target condition"
									icon="fas fa-plus"
									onClick={() => openTriggerSelector()}
								/>
							</div>
						</div>
					</div>
				}
			</div>
			<Modal
				isOpen={exitInfoModalIsOpen}
				onClose={(e) => setExitInfoModalIsOpen(false)}
				width={600}
			>
				<div className="modal_body">
					<div className="flex flex_align_start">
						<div className="flex_item_fix mr_20">
							<i className="fas fa-info-circle grey_3" style={{ fontSize: '36px', 'width': 'auto' }}></i>
						</div>
						<div className="flex_item_full">
							<div className="fw_medium s_22">Exit-intent definition</div>
							<div className="mt_20">
								<div className="fw_medium s_17 mb_5">Desktop</div>
								<div className="grey_0">The mouse is moving out of the window</div>
							</div>
							<div className="mt_20">
								<div className="fw_medium s_17 mb_5">Mobile</div>
								<div className="grey_0">The user left your website but comes back in the same session (within less than 30 min. of inactivity)</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal_footer al_right">
					<Btn
						message="Ok"
						onClick={(e) => setExitInfoModalIsOpen(false)}
					/>
				</div>
			</Modal>
		</div>
	);
}
