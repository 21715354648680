import React, { useEffect, useState } from 'react';
import RangeSlider from '../../../../../Components/RangeSlider';
import Checkbox from '../../../../../Components/Checkbox';
import SectionMessage from '../../../../../Components/SectionMessage';
import { TypeValueTypes } from './ParamsFilter';

const operators = [
	{
		value: 'In',
		label: 'In',
	},
	{
		value: 'NotIn',
		label: 'Not In',
	},
];


export default function NumberRangeContainer({
	data,
	handleChangeFilterByUniqueId,
	filters,
	itemType,
	relay
}) {
	const [mode, setMode] = useState(null);
	const [currentValue, setCurrentValue] = useState(data.value['numberRange']);
	const [operatorSelected, setOperatorSelected] = useState();

	function change(d) {
		handleChangeFilterByUniqueId(d);
	}

	function handleChangeValue(numberRange) {
		change({
			idFilter: data.idFilter,
			op: operatorSelected,
			value: {
				numberRange: numberRange
			},
		});
	}


	function setValue() {
		let valueSimple = data.value.hasOwnProperty('numberRange');
		if (valueSimple && data.value['numberRange'] !== null) {
			setCurrentValue(data.value['numberRange']);
		}
	}

	function handleSetMode(newMode) {
		if (mode !== null && newMode != mode) {
			const newValue = newMode == 'Percentage' ? { min: -0.2, max: 0.2 } : { min: -20, max: 20 };
			setCurrentValue(newValue);
			setOperatorSelected(operators[0].value);
			handleChangeValue(newValue);
		} else {
			setValue();
			setOperatorSelected(data.op || operators[0].value);
		}
		setMode(newMode);
	}

	useEffect(() => {
		if (!data) return;
		if (itemType === 'tagValue') {
			const typeParamName = filters.find(f => f.id === data.id)?.params.find(x => TypeValueTypes.includes(x.valueType))?.name;
			if (typeParamName) {
				const stringValues = data.params.find(p => p.name === typeParamName)?.value?.stringValues;
				if (stringValues && stringValues.length > 0) {
					const type = stringValues[0];
					if (type === 'Date') {
						handleSetMode('NumberOfDays');
					} else {
						handleSetMode('Percentage');
					}
				}
			}
		} else {
			handleSetMode('Percentage');
		}
	}, [data, relay]);


	return (
		<>
			{data && data.name && data.name == 'Price comparison' &&
				<div className="mb_20">
					Filter products by comparing their price to the reference product.
					<br />You can set a minimum price, a maximum price or both.
					<br />The value is a percentage of the price of the reference product
				</div>
			}

			{mode === 'Percentage' &&
				<PercentageRangeContainer
					currentValue={currentValue}
					handleChangeValue={handleChangeValue}
				></PercentageRangeContainer>
			}
			{mode === 'NumberOfDays' &&
				<NumberOfDaysRangeContainer
					currentValue={currentValue}
					handleChangeValue={handleChangeValue}
				></NumberOfDaysRangeContainer>
			}
		</>
	);
}


function NumberOfDaysRangeContainer({ currentValue, handleChangeValue }) {

	const [hasMin, setHasMin] = useState(false);
	const [hasMax, setHasMax] = useState(false);
	const [needValueError, setNeedValueError] = useState(false);

	const [valueMin, setValueMin] = useState(currentValue ? currentValue.min : -20);
	const [valueMax, setValueMax] = useState(currentValue ? currentValue.min : 20);

	const changeMin = (value) => {
		setValueMin(parseInt(value, 10));
	};
	const changeMax = (value) => {
		setValueMax(parseInt(value, 10));
	};

	function handleChangeValuesById() {
		handleChangeValue({
			min: valueMin,
			max: valueMax
		});
	}

	useEffect(() => {
		setNeedValueError(!hasMin && !hasMax);
	}, [hasMin, hasMax]);

	function handleChangeMinOrMax(sign, value) {
		if (sign === 'min') {
			if (!value) {
				setValueMin(null);
			} else {
				setValueMin(-20);
			}
			setHasMin(value);
		} else {
			if (!value) {
				setValueMax(null);
			} else {
				setValueMax(20);
			}
			setHasMax(value);
		}
	}


	useEffect(() => {
		const min = currentValue != null ? currentValue.min : null;
		const max = currentValue != null ? currentValue.max : null;
		setValueMin(min);
		setValueMax(max);
		setHasMin(min !== null);
		setHasMax(max !== null);
	}, []);

	useEffect(() => {
		handleChangeValuesById();
	}, [valueMin, valueMax]);


	return (
		<>
			<div className="mb_15">
				<div className="mb_5">
					<Checkbox
						label="Minimum"
						forcedChecked={hasMin}
						onChange={(ev) => handleChangeMinOrMax('min', ev.target.checked)}
					/>
				</div>
				{hasMin &&
					<RangeSlider
						min={-100}
						max={100}
						value={valueMin}
						onChange={(e) => changeMin(e.target.value)}
						inputUnit={'days'}
						legendList={['-', '0', '+']}
						step={5}
						reverse={true}
					/>
				}
			</div>
			<div className="mb_15">
				<div className="mb_5">
					<Checkbox
						label="Maximum"
						forcedChecked={hasMax}
						onChange={(ev) => handleChangeMinOrMax('max', ev.target.checked)}
					/>
				</div>
				{hasMax &&
					<RangeSlider
						min={-100}
						max={100}
						value={valueMax}
						onChange={(e) => changeMax(e.target.value)}
						inputUnit={'days'}
						legendList={['-', '0', '+']}
						step={5}
					/>
				}
			</div>
			{needValueError &&
				<SectionMessage
					type="warning"
					title="Choose an option"
					text="You must choose at least one option : Minimum or Maximum."
				/>
			}
			{hasMin && hasMax && (valueMin > valueMax) &&
				<SectionMessage
					type="warning"
					title="The values are not correct"
					text="Maximum should be higher than Minimum."
				/>
			}
		</>
	);
}

function PercentageRangeContainer({ currentValue, handleChangeValue }) {

	const [hasMin, setHasMin] = useState(false);
	const [hasMax, setHasMax] = useState(false);
	const [needValueError, setNeedValueError] = useState(false);

	const [valueMin, setValueMin] = useState(currentValue ? currentValue.min : -20);
	const [valueMax, setValueMax] = useState(currentValue ? currentValue.min : 20);

	const changeMin = (value) => {
		setValueMin(parseInt(value, 10));
	};
	const changeMax = (value) => {
		setValueMax(parseInt(value, 10));
	};

	function handleChangeValuesById() {
		handleChangeValue({
			min: valueMin !== null ? valueMin / 100 : valueMin,
			max: valueMax !== null ? valueMax / 100 : valueMax
		});
	}

	useEffect(() => {
		setNeedValueError(!hasMin && !hasMax);
	}, [hasMin, hasMax]);

	function handleChangeMinOrMax(sign, value) {
		if (sign === 'min') {
			if (!value) {
				setValueMin(null);
			} else {
				setValueMin(-20);
			}
			setHasMin(value);
		} else {
			if (!value) {
				setValueMax(null);
			} else {
				setValueMax(20);
			}
			setHasMax(value);
		}
	}


	useEffect(() => {
		const min = currentValue && currentValue.min != null ? currentValue.min * 100 : null;
		const max = currentValue && currentValue.max != null ? currentValue.max * 100 : null;
		setValueMin(min);
		setValueMax(max);
		setHasMin(min !== null);
		setHasMax(max !== null);
	}, []);

	useEffect(() => {
		handleChangeValuesById();
	}, [valueMin, valueMax]);


	return (
		<>
			<div className="mb_15">
				<div className="mb_5">
					<Checkbox
						label="Minimum"
						forcedChecked={hasMin}
						onChange={(ev) => handleChangeMinOrMax('min', ev.target.checked)}
					/>
				</div>
				{hasMin &&
					<RangeSlider
						min={-100}
						max={100}
						value={valueMin}
						onChange={(e) => changeMin(e.target.value)}
						inputUnit={'%'}
						legendList={['-100%', '0%', '+100%']}
						step={5}
						reverse={true}
					/>
				}
			</div>
			<div className="mb_15">
				<div className="mb_5">
					<Checkbox
						label="Maximum"
						forcedChecked={hasMax}
						onChange={(ev) => handleChangeMinOrMax('max', ev.target.checked)}
					/>
				</div>
				{hasMax &&
					<RangeSlider
						min={-100}
						max={100}
						value={valueMax}
						onChange={(e) => changeMax(e.target.value)}
						inputUnit={'%'}
						legendList={['-100%', '0%', '+100%']}
						step={5}
					/>
				}
			</div>
			{needValueError &&
				<SectionMessage
					type="warning"
					title="Choose an option"
					text="You must choose at least one option : Minimum or Maximum."
				/>
			}
			{hasMin && hasMax && (valueMin > valueMax) &&
				<SectionMessage
					type="warning"
					title="The values are not correct"
					text="Maximum should be higher than Minimum."
				/>
			}
		</>
	);
}
