import React  from 'react';
import TableRowItem from '../../../../Components/Table/TableRowItem.js';
import TableColItem from '../../../../Components/Table/TableColItem.js';
import { ProductThumb } from '../../../../Components/Product/ProductThumb';
import { ProductScore } from '../../../../Components/Product/ProductScore';

export function ProductItemSpecific({
	product,
	tableRowStyle
}: any): JSX.Element {

	return (
		<TableRowItem key={product.AssociatedProduct.Id} styleObj={tableRowStyle}>
			<TableColItem>
				<div className='flex'>
					<div className="flex_item_fix">
						<ProductThumb
							width={60}
							height={'auto'}
							url={product.AssociatedProduct.Thumbnail}
							alt={product.AssociatedProduct.Name}
						/>
					</div>
					<div className="flex_item_full">
						<div className='s_13 fw_medium grey_2'>{product.AssociatedProduct.Id}</div>
						<div className="table_name">{product.AssociatedProduct.Name}</div>
					</div>
				</div>
			</TableColItem>
			<TableColItem></TableColItem>
			<TableColItem>
				<div>
					<ProductScore
						value={product.OverVisitsDecile + 1}
						min={1}
						max={10}
						tooltip={'Visits score compared to products in the same category (distribution in deciles - 1 to 10)'}
					/>
				</div>
			</TableColItem>
			<TableColItem>
				<div>
					<ProductScore
						value={product.OverObjectivesDecile + 1}
						min={1}
						max={10}
						tooltip={'Conversion score compared to products in the same category (distribution in deciles - 1 to 10)'}
					/>
				</div>
			</TableColItem>
		</TableRowItem>
	);
}
