import React, {ReactElement} from 'react';
import angular from 'angular';
import { react2angular } from 'react2angular';
import ProductInsightsContext from '../context/ContextProductInsights';
import {KpiContainer} from './Kpi/KpiContainer';
import {useProductInsightsGlobalContext} from '../context/ContextGlobalInsight';

type Props = {
	$http: any
	$rootScope: any
	$routeParams:any
	AuthServices:any
	$location:any
	$timeout:any
	children: React.ReactNode
	navFilter:any;
}
export const ProductInsightsContainer = (props:Props):ReactElement => {

	if(location.pathname !== '/Insight/scoring'){
		return <></>;
	}
	const {
		ComparisonSelected,
		handleSelectComparaisonPeriodHandler,
		onChangeNavFilter,
		initialDates,
		dateStringToTimestamp,
		navFilter,
		setNavFilter,
		isCustomSelected,
		setisCustomSelected,
		customDateRange,
		setCustomDateRange,
		updateStorage,
		selectedValues,
		setSelectedValues,
		accountHasNoDatas,
		setAccountHasNoDatas
	} =useProductInsightsGlobalContext();

	return (
		<ProductInsightsContext
			$http={props.$http}
			$routeParams={props.$routeParams}
			AuthServices={props.AuthServices}
			$timeout={props.$timeout}
			$location={props.$location}
			$rootScope={props.$rootScope}
			ComparisonSelected={ComparisonSelected}
			handleSelectComparaisonPeriodHandler={handleSelectComparaisonPeriodHandler}
			onChangeNavFilter={onChangeNavFilter}
			initialDates={initialDates}
			dateStringToTimestamp={dateStringToTimestamp}
			navFilter={navFilter}
			setNavFilter={setNavFilter}
			setisCustomSelected={setisCustomSelected}
			isCustomSelected={isCustomSelected}
			customDateRange={customDateRange}
			setCustomDateRange={setCustomDateRange}
			updateStorage={updateStorage}
			selectedValues={selectedValues}
			setSelectedValues={setSelectedValues}
			accountHasNoDatas={accountHasNoDatas}
			setAccountHasNoDatas={setAccountHasNoDatas}
		>
			<KpiContainer />
		</ProductInsightsContext>
	);
};
angular.module('beyableSaasApp.ProductInsightsKpi', [])
	.component(
		'productinsights',
		react2angular(
			ProductInsightsContainer,
			[],
			['$http', '$rootScope', '$routeParams', '$location', '$timeout', '$scope','AuthServices']
		)
	);