import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';

const ChartComponent = ({ visitsAverage, addtocartAverage, emercheOrdinate, tagsStudied, xUnitLabel, yUnitLabel }) => {
	const chartRef = useRef(null);

	useEffect(() => {
		if (!tagsStudied) return;

		Highcharts.setOptions({
			chart: {
				type: 'bubble',
				plotBorderWidth: 1,
				zoomType: 'xy',
				events: {
					load: function () {
						// @ts-ignore
						this.myTooltip = new Highcharts.Tooltip(this, this.options.tooltip);
					},
					render: function () {
						// @ts-ignore
						// eslint-disable-next-line @typescript-eslint/no-this-alias
						const chart = this;
						// @ts-ignore
						if (!chart.labelAdded) {
							chart.renderer.text('0', 20, 480)
								.css({ color: '#d9d7d7', fontSize: '14px' })
								.add();
							// @ts-ignore
							chart.labelAdded = true;
						}
					}
				}
			},
			legend: { enabled: false },
			credits: { enabled: false },
			title: { text: ' ' },
			plotOptions: {
				series: {
					dataLabels: {
						color: '#000',
						useHTML: true,
						// @ts-ignore
						pointFormat: '<p>{point.tagName}</p>',
					}
				}
			},
			xAxis: {
				title: { text: xUnitLabel },
				labels: { format: '{value}' }
			},
			yAxis: {
				title: { text: yUnitLabel },
				labels: { format: '{value}' }
			},
			tooltip: {
				backgroundColor: '#38444A',
				// @ts-ignore
				color: '#FFF',
				style: { color: '#FFFFFF' },
				crosshairs: false,
			},
			colors: ['#006fff']
		});

		const chartOptions = {
			tooltip: {
				positioner: function (_labelWidth, _labelHeight, point) {
					const tooltipX = point.plotX + 0;
					const tooltipY = point.plotY + 50;
					return { x: tooltipX, y: tooltipY };
				},
				borderWidth: 0,
				hideDelay: 0,
				useHTML: true,
				headerFormat: '<div class="graph_tooltip">',
				pointFormat: `<p><span>{point.tagType}</span>{point.tagName}</p>
						<div>
						<div class="tooltip_label">Add to cart: {point.addToCartRateReference}</div>
						 <div class="tooltip_label">${xUnitLabel}: {point.convReference}</div>
						<div class="tooltip_label">Visits: {point.visitReference}</div>
						</div>
`,
				footerFormat: '</div>',
			},
			plotOptions: {
				bubble: {
					minSize: 10,
					maxSize: 100
				},
				series: {
					dataLabels: {
						enabled: true,
						format: '{point.tagName}',
					},
					cursor: 'pointer',
					point: {
						events: {
							click: function () {
								// Function to call on mapping point click
								// $scope.breadcrumbProducts = [];
								// $scope.breadcrumbProductsOutliers = [];
								// $scope.categoryForProducts = [{ "Type": this.tagType, "Value": this.tagValue }];
								// $scope.categoryForProductsOutliers = [{ "Type": this.tagType, "Value": this.tagValue }];
								// $scope.gotoElement('insights_table_top');
								// $scope.currentProductsCategory = this.tagName;
								// $scope.currentProductsOutliersCategory = this.tagName;
								// $scope.updateProductsData();
								// $scope.updateProductsOutliersData();
							},
							mouseOver: function () {
								// @ts-ignore
								this.series.chart.series[0].data[this.index].update({
									marker: { fillColor: '#ffa133' }
								});
							},
							mouseOut: function () {
								// @ts-ignore
								this.series.chart.series[0].data[this.index].update({
									marker: { fillColor: '#006fff' }
								});
							}
						}
					}
				}
			},
			series: [{
				data: tagsStudied.data,
				marker: {
					fillColor: '#006fff',
					lineWidth: 0
				}
			}]
		};
		// @ts-ignore
		chartRef.current = Highcharts.chart('chart_mapping', chartOptions);

		return () => {
			if (chartRef.current) {
				// @ts-ignore
				chartRef.current.destroy();
			}
		};
	}, [tagsStudied, visitsAverage, addtocartAverage, emercheOrdinate, xUnitLabel, yUnitLabel]);

	return (
		<div id="chart_mapping" style={{ height: '500px', minWidth: '310px' }}></div>
	);
};


export default ChartComponent;