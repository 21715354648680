import React, {ReactElement} from 'react';
import angular from 'angular';
import { react2angular } from 'react2angular';

import {ProductSpecificContainer} from './ProductSpecificContainer';
import ProductSpecificContextProvider from '../../context/ContextProductSpecific';
import {useProductInsightsGlobalContext} from '../../context/ContextGlobalInsight';

type Props = {
	$http: any
	$rootScope: any
	$routeParams:any
	AuthServices:any
	$location:any
	$timeout:any
	children: React.ReactNode
}
export const ProductInsightsContainerWrapper = (props:Props):ReactElement => {

	if(location.pathname !== '/Insight/catalogaudit'){
		return <></>;
	}
	const {
		ComparisonSelected,
		handleSelectComparaisonPeriodHandler,
		onChangeNavFilter,
		initialDates,
		dateStringToTimestamp,
		navFilter,
		setNavFilter,
		isCustomSelected,
		setisCustomSelected,
		customDateRange,
		setCustomDateRange,
		updateStorage,
		selectedValues,
		setSelectedValues,
		accountHasNoDatas,
		setAccountHasNoDatas
	} =useProductInsightsGlobalContext();
	return ( <ProductSpecificContextProvider
		$http={props.$http}
		$routeParams={props.$routeParams}
		AuthServices={props.AuthServices}
		$timeout={props.$timeout}
		$location={props.$location}
		$rootScope={props.$rootScope}
		ComparisonSelected={ComparisonSelected}
		handleSelectComparaisonPeriodHandler={handleSelectComparaisonPeriodHandler}
		onChangeNavFilter={onChangeNavFilter}
		initialDates={initialDates}
		dateStringToTimestamp={dateStringToTimestamp}
		navFilter={navFilter}
		setNavFilter={setNavFilter}
		setisCustomSelected={setisCustomSelected}
		isCustomSelected={isCustomSelected}
		customDateRange={customDateRange}
		setCustomDateRange={setCustomDateRange}
		updateStorage={updateStorage}
		selectedValues={selectedValues}
		setSelectedValues={setSelectedValues}
		accountHasNoDatas={accountHasNoDatas}
		setAccountHasNoDatas={setAccountHasNoDatas}
	><ProductSpecificContainer/>
	</ProductSpecificContextProvider>
	);
};
angular.module('beyableSaasApp.ProductInsightsProductSpecific', [])
	.component(
		'productspecific',
		react2angular(
			ProductInsightsContainerWrapper,
			[],
			['$http', '$rootScope', '$routeParams', '$location', '$timeout', '$scope','AuthServices']
		)
	);