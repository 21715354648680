import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './ProductScore.module.css';

interface IProductScore {
	value: number;
	min?: number;
	max?: number;
	displayValue?: number | string;
	className?: string;
	display?: 'inline' | 'block',
	tooltip?: string;
	tooltipHTML?: React.ReactElement;
}

function ProductScore({
	value,
	min = 0,
	max = 100,
	displayValue,
	className,
	display = 'inline',
	tooltip,
	tooltipHTML,
	...otherProps
}: IProductScore): JSX.Element {

	const tooltipID = uuidv4();
	const cssClass = [styles.wrapper];
	const cleanValue = Math.min(Math.max(value, min), max);
	const ratio = (cleanValue - min) / (max - min);
	const color = Math.round((ratio * 10) + 1);
	const colorClass = styles['color_' + color];
	const [width, setWidth] = useState(0);

	if (className) {
		cssClass.push(className);
	}

	if (display === 'block') {
		cssClass.push(styles.wrapper_block);
	}

	useEffect(() => {
		const timer = setTimeout(function(){
			setWidth(ratio * 100);
		}, 200);
		return () => {
			clearTimeout(timer);
		};
	}, [value]);

	return (
		<div className={cssClass.join(' ')} {...otherProps} data-tip={tooltipHTML ? 'a' : tooltip} data-for={tooltipID}>
			<div className={styles.gaugeOuter}>
				<div className={[styles.gaugeInner, colorClass].join(' ')} style={{width: width + '%'}}></div>
			</div>
			<div className={styles.value}>
				{typeof displayValue !== 'undefined' ? displayValue : value}
			</div>
			{(tooltip || tooltipHTML) &&
				<Tooltip
					text={tooltip}
					html={tooltipHTML}
					id={tooltipID}
				/>
			}
		</div>
	);
}

export {
	ProductScore,
};
