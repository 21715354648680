import React from 'react';
import { useEmailTemplateEditorContext } from '../../context/EmailTemplateEditorContextProvider';
import InputCustom from '../../../../../Components/InputCustom';
import { Section } from '../../../../../Components/Section/Section';
import { Article } from '../../../../../Components/Article/Article';
import { List, ListItem } from '../../../../../Components/List';
import { Radio } from '../../../../../Components/Checkbox/Checkbox';
import { EmailProvider } from '../../../../../Services/EmailTemplateServicesTypes';

export default function OutputGenerator() {
	const {
		personalisationParameterName,
		handleSetPersonalisationParameterName,
		provider,
		handleChangeProvider,
		handleChangeName,
		handleChangeDescription,
		emailTemplate,
	} = useEmailTemplateEditorContext();

	const updatePersonalisationParameterName = (e) =>{
		e.preventDefault();
		handleSetPersonalisationParameterName(e.target.value);
	};

	return (
		<Section width='xs' hasMargin={true}>
			<Article innerSize='l' title="Campaign information">
				<InputCustom
					name='Name'
					label='Name'
					value={emailTemplate?.Name ?? ''}
					onChange={e => handleChangeName(e.target.value)}
					fullWidth={true}
				/>
				<InputCustom
					name='Description'
					label='Description'
					placeholder="Optional"
					type="textarea"
					rows="2"
					autogrow={true}
					value={emailTemplate?.Description ?? ''}
					onChange={e => handleChangeDescription(e.target.value)}
					fullWidth={true}
				/>
				
			</Article>
			<Article innerSize='l' title="Connector settings">
				<Article hasPadding={false}>
					<List>
						<ListItem
							picto={<img src="/Assets/brevo-42.svg" width={42} height={42} className='ml_10' />}
							text={<span className='fw_medium s_16'>Brevo</span>}
							onClick={() => handleChangeProvider(EmailProvider.Brevo)}
							isActive={provider === EmailProvider.Brevo}
							actions={
								<Radio forcedChecked={provider === EmailProvider.Brevo} className="mr_10" />
							}
						/>
						<ListItem
							picto={<img src="/Assets/splio-42.png" width={42} height={42} className='ml_10' />}
							text={<span className='fw_medium s_16'>Splio</span>}
							onClick={() => handleChangeProvider(EmailProvider.Splio)}
							isActive={provider === EmailProvider.Splio}
							actions={
								<Radio forcedChecked={provider === EmailProvider.Splio} className="mr_10" />
							}
						/>
					</List>
				</Article>
				<InputCustom
					name=''
					placeholder='{ EMAIL }'
					label='Name of email variable'
					value={personalisationParameterName ?? ''}
					onChange={e => updatePersonalisationParameterName(e)}
				/>
			</Article>
		</Section>
	);
}