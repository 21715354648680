const OrderByModificationDateDesc = { 'modificationDate': 'DESC' };
const OrderByModificationDateAsc = { 'modificationDate': 'ASC' };
const OrderByNameDesc = { 'name': 'DESC' };
const OrderByNameAsc = { 'name': 'ASC' };
const OrderByImpressionslast30Desc = { 'impressionsLast30DaysValueDisplayedAbsolute': 'DESC' };
const OrderByImpressionslast30Asc = { 'impressionsLast30DaysValueDisplayedAbsolute': 'ASC' };
const OrderByImpressionslastlast30Desc = { 'impressionsLastLast30DaysValueDisplayedAbsolute': 'DESC' };
const OrderByImpressionslastlast30Asc = { 'impressionsLastLast30DaysValueDisplayedAbsolute': 'ASC' };

export {
	OrderByModificationDateDesc,
	OrderByModificationDateAsc,
	OrderByNameDesc,
	OrderByNameAsc,
	OrderByImpressionslast30Desc,
	OrderByImpressionslast30Asc,
	OrderByImpressionslastlast30Desc,
	OrderByImpressionslastlast30Asc
};