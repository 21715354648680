import React, {useState,useEffect} from 'react';
import { react2angular } from 'react2angular';
import angular from 'angular';
import CatalogueContainer from './CatalogueContainer';
import CreateCatalogueContextProvider  from './Context/CatalogueContextProvider';
import Modal from '../../../Components/Modal';
import styles from './CatalogueContainer.module.css';
import { PanelClose } from '../../../Components/Panel/Panel';

export const CatalogueContainerWrapper = (props) => {

	const [isOpen, setIsOpen] = useState(false);
	function hideCatalog (){
		props.close(false);
		setIsOpen(false);
	}
	useEffect(() => {
		props.$rootScope.$on('event:changeStateOfCataloguePanel', function (_event, element) {
			props.$timeout(() => {
				setIsOpen(element);
			}, 100);
		});
	}, []);

	if(!isOpen){
		return null;
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => hideCatalog()}
			noClose={true}
			closeOnOverlay={true}
			animation={'slide_down'}
			fullScreen={true}
		>
			<div className={styles.root}>
				<CreateCatalogueContextProvider
					$http={props.$http}
					$routeParams={props.$routeParams}
					$rootScope={props.$rootScope}
					$timeout={props.$timeout}
					$location={props.$location}
					AuthServices={props.AuthServices}
					onHideCatalog={hideCatalog}
				>
					<CatalogueContainer />
				</CreateCatalogueContextProvider>
				<PanelClose
					position="absolute"
					onClick={() => hideCatalog()}
					style={{zIndex: 1}}
				/>
			</div>
		</Modal>
	);
};
angular
	.module('beyableSaasApp.Catalogue', [])
	.component('catalogue', react2angular(CatalogueContainerWrapper, ['isOppen', 'close'], ['$http', '$rootScope', '$timeout', '$routeParams', '$location', '$scope','AuthServices']));