import { useTranslation } from 'react-i18next';

export default function CampaignsListContextualLabels() {

    const [t] = useTranslation(['common']);

    const contextualLabels = {
		searchCampaignPlaceholder:t('search.searchCampaign'),
		listPageTitle:'Campaigns'
	};

    return contextualLabels;
} 
