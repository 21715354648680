import React, { createContext, useContext, useEffect, useState, SetStateAction, Dispatch } from 'react';
import SystemServices from '../../../../Services/SystemServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import CatalogueServices from '../../../../Services/CatalogueServices';

import { Template, TemplateObject} from './TemplateTypes';
import {  setupFormats } from './EditorOperations';
import { TemplateProperty, DeviceType } from '../../../WysiwygEditor/Types/BespokeTypes';
import { objectives, formats, modules } from '../../Inspirations/InspirationData.js';
import { StyleApi } from './StyleTypes';

export type Style = {
	id: string,
	properties: TemplateProperty[],
	name: string
}

const CollectionContext = createContext<CatalogueContextType | undefined>(undefined);

function useCatalogueEditorContext() {
	const context = useContext(CollectionContext);
	if (!context) throw Error('useEmailTemplateEditorContext can only be used inside an EmailTemplateEditorContextProvider');
	return context;
}
type readyTemplates = {
	BespokeTemplates : Template[];
	Identifier: string;
	Triggers : [];
}
type SassFormat = Template & {
	defaultStyle : TemplateProperty[]
}
type templateWithStyles = {
	BespokeTemplates : Template & {
		defaultStyle : TemplateProperty[]
	};
	Identifier: string;
	Triggers : [];
}
type TemplateGroup = {
	id: string;
	label?: string;
	items: Template[];
}
type ObjectiveForMenu = {
	id: string;
	nbItems?: number;
}
const CreateCatalogueContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;
	const onHideCatalog = props.onHideCatalog;

	// const authServices = props.AuthServices;
	// const getAccessToken = authServices && authServices.getAccessToken;
	const systemServices = new SystemServices($rootScope, $timeout);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;

	const crud = new CatalogueServices(accountId, $http);

	const [mode, setMode] = useState('templates');
	const [currentDevice, setCurrentDevice] = useState<DeviceType>(DeviceType.Desktop);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [template, setTemplate] = useState<Template>();
	const [formatSavedStyles, setFormatSavedStyle] = useState<StyleApi[]>([]);

	const [customTemplates, setCustomTemplates] = useState<SassFormat[]>([]);
	const [customTemplatesLoaded, setCustomTemplatesLoaded] = useState<boolean>(false);
	const [readyTemplatesLoaded, setReadyTemplatesLoaded] = useState<boolean>(false);
	const [readyTemplatesAndStylesLoaded, setReadyTemplatesAndStylesLoaded] = useState<boolean>(false);

	const [readyTemplates, setReadyTemplates] = useState<readyTemplates[]>();
	const [readyTemplatesAndStyles, setReadyTemplatesAndStyles] = useState<readyTemplates[]>();
	const [templateGroupsToMap, setTemplateGroupsToMap] = useState<TemplateGroup[]>([]);
	const [styleSelected, setStyleSelected] = useState<Style | undefined>();
	const [objectivesForMenu, setObjectivesForMenu] = useState<ObjectiveForMenu[]>([]);

	const [defaultStyleFormat, setDefaultStyleFormat] = useState<StyleApi>();

	const [selectedObjective, setSelectedObjective] = useState<string>('');
	const [selectedFormat, setSelectedFormat] = useState<string>('all');
	const [modelProperties, setModelProperties] = useState<TemplateProperty[]>([]);
	const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false);


	function getModuleURL (module, url)  {
		if (module.available === false) return;

		if (!url || url == '') {
			const editor = module.editor == 'builder' ? 'builder' : 'module';
			url = '/Campaigns/Editor?' + editor + '=' + module.id;
		}

		const sep = url.includes('?') ? '&' : '?';

		if( $routeParams.ka ){
			url += sep + 'ka=' + $routeParams.ka;
			url += sep + 'ku=' + $routeParams.ku;
		}
		return url;
	}

	function getModuleURLCustom (module, url)  {
		if (module.available === false) return;

		if (!url || url == '') {
			const editor =  'custom' ;
			url = '/Campaigns/Editor?' + editor + '=' + module.Key;
		}

		const sep = url.includes('?') ? '&' : '?';

		if( $routeParams.ka ){
			url += sep + 'ka=' + $routeParams.ka;
			url += sep + 'ku=' + $routeParams.ku;
		}
		return url;
	}

	const getTemplatesByObj = (modules, objId) => {
		if(objId !== ''){
			return modules.filter(module => {
				return module.Objective && module.Objective.id === objId;
			});
		}else{
			return modules;
		}
	};

	const getTemplatesByFormat = (modules, formatId) => {
		if(formatId !== 'all'){
			return modules.filter(module => {
				return module.Format && module.Format.id === formatId;
			});
		}else{
			return modules;
		}
	};

	const displayReadyTemplates = (withStyle = false) => {
		if (readyTemplatesLoaded && !withStyle) {
			setIsLoading(false);
			loadReadyTemplates(selectedObjective, readyTemplates);
			return;
		}

		if (readyTemplatesAndStylesLoaded && withStyle) {
			setIsLoading(false);
			loadReadyTemplates(selectedObjective, readyTemplatesAndStyles);
			return;
		}

		setIsLoading(true);
		const controller = new AbortController();
		crud.getModuleTemplates(
			data => {
				setReadyTemplates(data);
				setReadyTemplatesLoaded(true);

				if (withStyle) {
					let templatesAndStyles;
					(async () => {
						templatesAndStyles = await getAllTemplatesWithAllStyles(data);
						setReadyTemplatesAndStyles(templatesAndStyles);
						setReadyTemplatesAndStylesLoaded(true);
						loadReadyTemplates(selectedObjective, templatesAndStyles);
						setIsLoading(false);
					})();
				} else {
					loadReadyTemplates(selectedObjective, data);
					setIsLoading(false);
				}
			},
			() => {
				setIsLoading(false);
				systemServices.showError('An error occurred while getting templates.');
			},controller.signal
		);
	};

	const displayCustomTemplates = () => {
		if (customTemplatesLoaded) {
			setIsLoading(false);
			if (customTemplates && customTemplates.length > 0) {
				const templateGroups:TemplateGroup[] = [
					{
						id: 'default',
						items: getTemplatesByFormat(customTemplates, selectedFormat)
					}
				];
				setTemplateGroupsToMap(templateGroups);
			} else {
				setTemplateGroupsToMap([]);
			}
			return;
		}

		setIsLoading(true);
		const controller = new AbortController();
		crud.getAvailableFormatsLibrary(formats => {
			const templatesWithStylesProperties = setupFormats(formats);
			const templatesWithFormat = templatesWithStylesProperties.map((t) => {
				t.ModuleUrl = getModuleURLCustom(t, t.url);
				return {
					...t,
					Format: {
						id: t.Format
					}
				};
			});

			setCustomTemplates(templatesWithFormat);
			setCustomTemplatesLoaded(true);
			setIsLoading(false);

			if (templatesWithFormat && templatesWithFormat.length > 0) {
				const templateGroups:TemplateGroup[] = [
					{
						id: 'default',
						items: getTemplatesByFormat(templatesWithFormat, selectedFormat)
					}
				];
				setTemplateGroupsToMap(templateGroups);
			} else {
				setTemplateGroupsToMap([]);
			}
			
		}, err => {
			console.log(err);
			setIsLoading(false);
			systemServices.showError('An error occured while getting templates.');
		},controller.signal);
	};

	const templatesDefinition = modules.map(m => {
		const formatId = m.formatId;
		const objId = m.objId;
		if (formatId) {
			m.format = formats.find(f => f.id == formatId);
		}
		if (objId) {
			m.obj = objectives.find(o => o.id == objId);
		}
		m.moduleUrl = getModuleURL(m, m.url);
		return m;
	});

	const loadReadyTemplates = (obj:string, allTemplates:any) => {

		const templateGroups:TemplateGroup[] = objectives.map((o) => {
			return {
				id: o.id,
				items: []
			};
		});

		templateGroups.push({
			id: 'others',
			items: [],
		});

		const templatesWithInfo = allTemplates.reduce((final, tpl) => {
			const templateDefinition:any = templatesDefinition.find((m:any) => m.id === tpl.Identifier );
			if (templateDefinition) {
				final.push({
					...tpl.BespokeTemplates[0],
					IdentifierModule: tpl.Identifier,
					ModuleUrl: templateDefinition.moduleUrl,
					Objective: templateDefinition.obj,
					Format: templateDefinition.format,
					TranslationKey: templateDefinition.id
				});
			}
			return final;
		}, []);

		// Remplissage des groupes
		for (let i = 0; i < templatesWithInfo.length; i++) {
			const t = templatesWithInfo[i];
			const obj = t.Objective?.id || 'others';
			const group = templateGroups.find((g:TemplateGroup) => g.id === obj);
			group && group.items.push(t);
		}

		let finalTemplateGroups = templateGroups.filter((t) => t.items.length > 0);

		// On filtres les templates
		if (obj || selectedFormat !== 'all') {
			for (let i = 0; i < finalTemplateGroups.length; i++) {
				let items = finalTemplateGroups[i].items;
				items = getTemplatesByObj(items, obj);
				items = getTemplatesByFormat(items, selectedFormat);
				finalTemplateGroups[i].items = items;
			}
		}

		const objectivesForMenu:ObjectiveForMenu[] = finalTemplateGroups.map((t) => {
			return {
				id: t.id,
				nbItems: t.items.length
			};
		});

		finalTemplateGroups = finalTemplateGroups.filter((t) => t.items.length > 0);

		setObjectivesForMenu(objectivesForMenu);
		setTemplateGroupsToMap(finalTemplateGroups);
	};

	function handleStyleSelected(style) {
		setStyleSelected(style);
	}
	function loadSavedStylesForFormat(templateId,defaultStyle) {
		crud.getFormatSavedStyles(
			templateId,
			(data) => {
				setFormatSavedStyle([defaultStyle,...data]);
			},
			(err) => {
				console.log(err), systemServices.showError('an error occured');
			}
		);
	}
	const getFormatSavedStylesAsync = (templateId) => {
		return new Promise((resolve, reject) => {
			crud.getFormatSavedStyles(
				templateId,
				(data) => resolve(data),
				(err) => reject(err)
			);
		});
	};

	const getAllTemplatesWithAllStyles = async (allTemplates) => {
		try {
			const updatedTemplates = await Promise.all(
				allTemplates.map(async (template) => {
					try {
						const styles = await getFormatSavedStylesAsync(template.BespokeTemplates[0].Key);
						const t : templateWithStyles = { ...template, BespokeTemplates :[{...template.BespokeTemplates[0], stylesAssociates : styles}] };
						return t;
					} catch (err) {
						console.error(`Erreur pour le template ${template.Identifier}:`, err);
						return { ...template };
					}
				})
			);
			return updatedTemplates;
		} catch (error) {
			console.error('error during template fetching styles:', error);
		}
	};
	
	const handleFormatPreviewClicked = (format: any,style:StyleApi | undefined) => {
		setTemplate(format);
		loadSavedStylesForFormat(format.Key,{
			id: 'default',
			properties: format.Properties,
			name: 'Default style'
		});
		setDefaultStyleFormat(JSON.parse(JSON.stringify({
			id: 'default',
			properties: format.Properties,
			name: 'Default style'
		})));
		if(!style){
			setStyleSelected({
				id: 'default',
				properties: format.Properties,
				name: 'Default style'
			});
		}else{
			setStyleSelected(style);
		}
		setPanelIsOpen(true);
	};
	function handleSelectMode (m:string){
		// Check if loading before changing mode
		if (isLoading) {
			return; // Don't change mode while loading
		}
		
		setMode(m);
		setSelectedObjective('');
		// Fixed conditionals to use the new mode value (m) instead of current mode state
		if (m === 'custom') {
			setSelectedFormat('all');
		}
		if (m !== 'custom' && selectedFormat !== 'all') {
			setSelectedFormat('all');
		}
		setPanelIsOpen(false);
	}
	function handleSelectObjective (o:string,m:string,){
		setSelectedObjective(o);
		setMode(m);
		if(m === 'custom'){
			setSelectedFormat('all');
		}
	}
	function selectTemplatesByMode(){
		if(mode === 'custom'){
			displayCustomTemplates();
		}else if(mode === 'templates') {
			displayReadyTemplates(true);
		}else if(mode === 'realizations'){
			setTemplateGroupsToMap([]);
		}
	}
	function handleSelectFormat(f:string) {
		setSelectedFormat(f);
	}

	useEffect(() => {
		setMode('templates');
		setSelectedObjective('');
	}, []);

	useEffect(()=>{
		if(!isLoading) {
			selectTemplatesByMode();
		}
	}, [mode, selectedObjective, selectedFormat]);
	
	const context: CatalogueContextType = {
		isLoading,
		template,
		customTemplates,
		formatSavedStyles,
		handleStyleSelected,
		currentDevice,
		mode,
		setCurrentDevice,
		handleFormatPreviewClicked,
		templateGroupsToMap,
		modelProperties,
		setModelProperties,
		styleSelected,
		defaultStyleFormat,
		panelIsOpen,
		setPanelIsOpen,
		handleSelectMode,
		selectedObjective,
		handleSelectObjective,
		handleSelectFormat,
		selectedFormat,
		objectivesForMenu,
		onHideCatalog
	};

	return (
		<CollectionContext.Provider
			value={context}
		>
			{props.children}
		</CollectionContext.Provider>
	);
};

export default CreateCatalogueContextProvider;

export { useCatalogueEditorContext };

type CatalogueContextType = {
	isLoading: boolean;
	template: Template | undefined;
	customTemplates: SassFormat[];
	formatSavedStyles: StyleApi[];
	defaultStyleFormat:StyleApi | undefined;
	handleStyleSelected: (st:StyleApi)=>void;
	currentDevice: DeviceType;
	mode: string;
	styleSelected: Style | undefined;
	handleFormatPreviewClicked: (format: TemplateObject,style: StyleApi | undefined) => void;
	setCurrentDevice: Dispatch<SetStateAction<DeviceType>>;
	modelProperties: TemplateProperty[];
	setModelProperties: Dispatch<SetStateAction<TemplateProperty[]>>;
	panelIsOpen:boolean;
	setPanelIsOpen: (bo:boolean)=>void;
	handleSelectMode: (st:string)=>void;
	selectedObjective: string | undefined;
	handleSelectObjective: (newObjective:string,mode:string) => void;
	handleSelectFormat: (format:string) => void;
	templateGroupsToMap: TemplateGroup[];
	selectedFormat:string;
	objectivesForMenu: ObjectiveForMenu[];
	onHideCatalog: () => void;
}
