import React, { createContext, useState, useContext, useEffect } from 'react';
import moment,  { Moment } from 'moment';
import {
	SESSION_STORAGE_KEY,
	SESSION_STORAGE_KEY_COMPARISON,
	SESSION_STORAGE_KEY_NAV,
	getDayInRespectOfOtherDateRange,
	findNavLabel,
} from '../ProductInsights/utils';

import ProductInsightsServices from '../../../Services/ProductInsightsServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
interface CalculatedDates {
	fromDate: string;
	toDate: string;
	fromDateToCompare: string;
	toDateToCompare: string;
}

interface NavFilterState extends CalculatedDates {
	filters: any[];
	label: string;
	compareLabel: string;
	isMainCustom: boolean;
	isCompareCustom: boolean;
}
interface DateRange {
	startDate: Moment;
	endDate: Moment;
}
type ProductInsightsGlobalContextType = {
  isLoading: boolean;
  changeDeviceSelected: (s: Devices) => void;
  deviceType: Devices;
  ComparisonSelected: boolean;
  handleSelectComparaisonPeriodHandler: (b: boolean) => void;
  onChangeNavFilter: (o: CalculatedDates) => void;
  initialDates: any;
  dateStringToTimestamp: (o: any) => void;
  navFilter: NavFilterState;
  setNavFilter: React.Dispatch<React.SetStateAction<any>>;
  setisCustomSelected: React.Dispatch<React.SetStateAction<any>>;
  isCustomSelected: boolean;
  customDateRange: DateRange;
  setCustomDateRange: React.Dispatch<React.SetStateAction<DateRange>>;
  updateStorage: any;
  selectedValues:any;
  setSelectedValues:React.Dispatch<React.SetStateAction<any>>;
  accountHasNoDatas: boolean;
  setAccountHasNoDatas:React.Dispatch<React.SetStateAction<boolean>>;
};

type Devices = 'Mobile' | 'Desktop' | 'Tablet';
const FORMAT = 'YYYY/MM/DD';

interface ProductEmerchInsightsProviderProps {
  children: React.ReactNode;
	$http?: any
	$rootScope?: any
	$routeParams?:any
	AuthServices?:any
	$location?:any
	$timeout?:any
}

const ContextInsightGlobal = createContext<ProductInsightsGlobalContextType | undefined>(undefined);
const currentDate = new Date();

export const useProductInsightsGlobalContext = () => {
	const contextValue = useContext(ContextInsightGlobal);
	if (contextValue === undefined) {
		throw new Error('must be used within an insights element');
	}
	return contextValue;
};

export const ProductGlobalContextProvider: React.FC<ProductEmerchInsightsProviderProps> = ({ children,
	$rootScope,
	$routeParams,
	AuthServices,
	$http,

}) => {
	const [deviceType, setDeviceType] = useState<Devices>('Desktop');
	const [isLoading] = useState<boolean>(true);
	const [navFilter, setNavFilter] = useState<NavFilterState>({
		fromDate: moment(currentDate).subtract(7, 'day').format(FORMAT),
		toDate: moment(currentDate).format(FORMAT),
		fromDateToCompare: moment(currentDate).subtract(14, 'day').format(FORMAT),
		toDateToCompare: moment(currentDate).subtract(7, 'day').format(FORMAT),
		filters: [],
		label: findNavLabel( moment(currentDate).subtract(7, 'day'),moment(currentDate)),
		compareLabel: findNavLabel( moment(currentDate).subtract(14, 'day'), moment(currentDate).subtract(7, 'day')),
		isMainCustom: false,
		isCompareCustom: false
	});
	const [ComparisonSelected, setComparisonSelected] = useState(false);
	const [isCustomSelected, setisCustomSelected] = useState(false);
	const [customDateRange, setCustomDateRange] = useState<DateRange>({
		startDate: moment().subtract(1, 'month'),
		endDate: moment().subtract(1, 'day')
	});
	const [selectedValues, setSelectedValues] = useState({});
	const [initialDates, setInitialDate] = useState<any>();
	const [accountHasNoDatas, setAccountHasNoDatas] = useState(false);
	const getInitialSelectedValues = () => {
		const storedValues = sessionStorage.getItem(SESSION_STORAGE_KEY);
		return storedValues ? JSON.parse(storedValues) : {};
	};

	const impersonatingServices = new ImpersonatingServices($rootScope,$routeParams );
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;

	const insightsServices = new ProductInsightsServices($http,AuthServices,accountId);

	function calculateDefaultDate(initD){

		const { newStartDate, newEndDate } = getDayInRespectOfOtherDateRange(initD.initialFrom,initD.initialTo );
		const recalculatedLabel = findNavLabel(initD.initialFrom,initD.initialTo);
		const recalculatedCompareLabel = `${newStartDate} - ${newEndDate}`;
		const defaultData = {
			fromDate: initD.initialFrom,
			toDate: initD.initialTo,
			fromDateToCompare: initD.fromDateToCompare,
			toDateToCompare: initD.toDateToCompare,
			label: recalculatedLabel,
			compareLabel: recalculatedCompareLabel,
			isMainCustom: initD.isMainCustom,
			isCompareCustom: initD.isCompareCustom,
			filters:  []
		};
		return defaultData;
	}
	const getInitialDates = () => {
		const globalProductInsights = sessionStorage.getItem(SESSION_STORAGE_KEY_NAV);

		let initialDate ;
		if (globalProductInsights !== null ) {
			const insights = JSON.parse(globalProductInsights);
			initialDate = {
				initialLabel: insights.label ,
				initialLabel2: insights.compareLabel,
				initialFrom: insights.fromDate ,
				initialTo: insights.toDate ,
				fromDateToCompare: insights.fromDateToCompare ,
				toDateToCompare: insights.toDateToCompare ,
				isMainCustom: insights.isMainCustom ,
				isCompareCustom: insights.isCompareCustom
			};
		} else {
			initialDate = initiNavDate(navFilter);
			updateStorage(
				initialDate.initialFrom,
				initialDate.initialTo,
				initialDate.initialLabel,
				initialDate.fromDateToCompare,
				initialDate.toDateToCompare,
				initialDate.initialLabel2
			);
		}
		const compareSelected = sessionStorage.getItem(SESSION_STORAGE_KEY_COMPARISON);
		if (compareSelected !== null ) {
			const parseCompare = JSON.parse(compareSelected);
			if(parseCompare){
				const { newStartDate, newEndDate } = getDayInRespectOfOtherDateRange(initialDate.initialFrom,initialDate.initialTo );
				const recalculatedLabel = findNavLabel(initialDate.initialFrom,initialDate.initialTo);
				const recalculatedCompareLabel = `${newStartDate} - ${newEndDate}`;
				initialDate = {
					...initialDate,
					initialLabel: recalculatedLabel,
					initialLabel2: recalculatedCompareLabel,
				};
				const defaultData = calculateDefaultDate(initialDate);
				sessionStorage.setItem(SESSION_STORAGE_KEY_NAV, JSON.stringify(defaultData));
			}
		}
		else{
			const defaultData = calculateDefaultDate(initialDate);
			sessionStorage.setItem(SESSION_STORAGE_KEY_NAV, JSON.stringify(defaultData));
		}

		return initialDate;
	};


	const initiNavDate = (navFilter:NavFilterState) => {
		const navState = {
			fromDate: navFilter.fromDate,
			toDate: navFilter.toDate,
			fromDateToCompare: navFilter.fromDateToCompare,
			toDateToCompare: navFilter.toDateToCompare,
			label: navFilter.label,
			compareLabel: navFilter.compareLabel
		};
		return {
			initialLabel: navState.label,
			initialLabel2: navState.compareLabel,
			initialFrom: navState.fromDate,
			initialTo: navState.toDate,
			fromDateToCompare: navState.fromDateToCompare,
			toDateToCompare: navState.toDateToCompare
		};
	};

	const updateStorage = ( from, to, label, fromCompare,toCompare,labelCompare) => {

		const storedData = sessionStorage.getItem(SESSION_STORAGE_KEY_NAV);
		const existingData = storedData ? JSON.parse(storedData) : {};

		const defaultData = {
			fromDate: navFilter.fromDate,
			toDate: navFilter.toDate,
			fromDateToCompare: navFilter.fromDateToCompare,
			toDateToCompare: navFilter.toDateToCompare,
			label: navFilter.label,
			compareLabel: navFilter.compareLabel,
			isMainCustom: navFilter.isMainCustom,
			isCompareCustom: navFilter.isCompareCustom,
			filters: navFilter.filters || []
		};

		const updatedData = {
			...defaultData,
			...existingData,
			fromDateToCompare: fromCompare,
			toDateToCompare: toCompare,
			compareLabel: labelCompare,
			isCompareCustom: labelCompare === 'Custom',
			fromDate: from,
			toDate: to,
			label: label,
			isMainCustom: label === 'Custom'
		};
		const iniD  = {
			initialLabel: label,
			initialLabel2: labelCompare,
			initialFrom: from ,
			initialTo: to ,
			fromDateToCompare: fromCompare ,
			toDateToCompare: toCompare ,
			isMainCustom: label === 'Custom',
			isCompareCustom: labelCompare === 'Custom',
		};
		setInitialDate(iniD);
		sessionStorage.setItem(SESSION_STORAGE_KEY_NAV, JSON.stringify(updatedData));
	};


	const onChangeNavFilter = (nav:CalculatedDates) => {
		setNavFilter((currentNavFilter) => ({ ...currentNavFilter, ...nav }));
	};

	function handleSelectComparaisonPeriodHandler(value) {
		setComparisonSelected(value);
		sessionStorage.setItem('globalProductInsightsComparedPeriode', JSON.stringify(value));
	}

	function changeDeviceSelected(type: Devices): void {
		setDeviceType(type);
	}

	function dateStringToTimestamp(dateString: any): number {
		if (typeof dateString === 'string') {
			const [year, month, day] = dateString.split('/').map(Number);
			const date = new Date(year, month - 1, day);
			return date.getTime();
		} else {
			// Utiliser moment.js pour formater la date
			const formattedDate = moment(dateString).format('YYYY/MM/DD');
			const [year, month, day] = formattedDate.split('/').map(Number);
			const date = new Date(year, month - 1, day);
			return date.getTime();
		}
	}
	function checkDatas(){

		insightsServices.checkIfAccounthasDatas(
			(data:any) => {
				setAccountHasNoDatas(!data.isAvailable);
			},
			(_err:any,dataError) => {
				console.log('=>(CrudKpiInsights.tsx: ii) dataError', dataError);
			});
	}
	useEffect(()=>{
		checkDatas();
	},[]);
	useEffect(() => {
		const iniD = getInitialDates();
		setInitialDate(iniD);
		const initSelectedFilters = getInitialSelectedValues();
		if(initSelectedFilters){
			setSelectedValues(initSelectedFilters);
		}
		const storedData = sessionStorage.getItem(SESSION_STORAGE_KEY_COMPARISON);
		if (storedData !== null) {
			try {
				const parsedData = JSON.parse(storedData);
				setComparisonSelected(Boolean(parsedData));
			} catch (error) {
				console.error('Error parsing comparison period data:', error);
				setComparisonSelected(false);
			}
		}
	}, []);

	return (
		<ContextInsightGlobal.Provider
			value={{
				isLoading,
				changeDeviceSelected,
				deviceType,
				ComparisonSelected,
				handleSelectComparaisonPeriodHandler,
				onChangeNavFilter,
				initialDates,
				dateStringToTimestamp,
				navFilter,
				setNavFilter,
				isCustomSelected,
				setisCustomSelected,
				customDateRange,
				setCustomDateRange,
				updateStorage,
				selectedValues,
				setSelectedValues,
				accountHasNoDatas,
				setAccountHasNoDatas
			}}
		>
			{children}
		</ContextInsightGlobal.Provider>
	);
};



export default ProductGlobalContextProvider;