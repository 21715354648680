import React, { useState } from 'react';
import CustomGoalPanel from '../UI/CustomGoalPanel';
import Dropdown from '../../../../Components/Dropdown';
import Btn from '../../../../Components/Btn';
import { Flag } from '../../../../Components/Flag/Flag';
import Listbox from '../../../../Components/Listbox';
import ListboxItem from '../../../../Components/ListboxItem';
import ReportGeneratorModal from '../../../ReportGenerator/ReportGeneratorModal';
import { useTranslation } from 'react-i18next';

function CustomGoalButton(props) {

    const [goalDropdownIsOpen, setGoalDropdownIsOpen] = useState(false);
    const [customGoalPanelIsOpen, setCustomGoalPanelIsOpen] = useState(false);
    const [customGoalType, setCustomGoalType] = useState();

    const [t] = useTranslation(['common', 'goals']);

    return (
        <>
            <Dropdown
                isOpen={goalDropdownIsOpen}
                toggle={(s) => setGoalDropdownIsOpen(s)}
                button={
                    <Btn
                        message={t('nav.trackGoals')}
                        style="outline"
                        color="secondary"
                        arrow={true}
                    />
                }
                maxWidth={440}
            >
                <Listbox>
                    <ListboxItem
                        size="xl"
                        message={<span className="listbox_item_title">{t('nav.customGoal')}</span>}
                        description={t('nav.customGoalDescription')}
                        iconCustom={<span className="icon_32 icon_goal icon_color_turquoise"></span>}
                        onClick={() => { setCustomGoalType('goal'); setCustomGoalPanelIsOpen(true); setGoalDropdownIsOpen(false); }}
                    />
                    <li className='hr' />
                    <ListboxItem
                        size="xl"
                        message={<span className="listbox_item_title">{t('nav.transactionGoal')}</span>}
                        description={t('nav.transactionGoalDescription')}
                        iconCustom={<span className="icon_32 icon_transaction icon_color_turquoise"></span>}
                        onClick={() => { setCustomGoalType('transactionGoal'); setCustomGoalPanelIsOpen(true); setGoalDropdownIsOpen(false); }}
                    />
                </Listbox>
            </Dropdown>
            <CustomGoalPanel
                isOpen={customGoalPanelIsOpen}
                onClose={() => setCustomGoalPanelIsOpen(false)}
                goalType={customGoalType}
            />
        </>
    );
};


function ReportGeneratorButton({ isAdmin, accountId, AuthServices, systemServices, $http, $routeParams, $rootScope }) {
    const [modalReportingIsOpen, setModalReportingIsOpen] = useState(false);

    const [t] = useTranslation(['common']);

    return (
        <>
            {modalReportingIsOpen && (
                <ReportGeneratorModal
                    onClose={() => setModalReportingIsOpen(false)}
                    isOpen={modalReportingIsOpen}
                    accountId={accountId}
                    AuthServices={AuthServices}
                    $http={$http}
                    systemServices={systemServices}
                    $routeParams={$routeParams}
                    $rootScope={$rootScope}
                ></ReportGeneratorModal>
            )}
            {isAdmin &&
                <Btn
                    style="outline"
                    color="secondary"
                    onClick={() => { setModalReportingIsOpen(true); }}
                >
                    <span className='flex flex_align_center'>
                        <span>{t('nav.report')}</span>
                        <span style={{ marginTop: '-2px', marginBottom: '-2px' }}>
                            <Flag
                                icon="ai"
                                text="AI"
                                tooltip="This feature is powered by BEYABLE AI"
                                className="ml_8"
                                hasBorder={true}
                            />
                        </span>
                    </span>
                </Btn>
            }
        </>

    );
}


export default function CampaignsListAdditionnalButtons({ isAdmin, accountId, AuthServices, systemServices, $http, $routeParams, $rootScope }) {

    return (
        <>        
            <CustomGoalButton></CustomGoalButton>
            <ReportGeneratorButton
                isAdmin={isAdmin}
                accountId={accountId}
                AuthServices={AuthServices}
                systemServices={systemServices}
                $http={$http}
                $routeParams={$routeParams}
                $rootScope={$rootScope}
            ></ReportGeneratorButton>
        </>
    );
};