import React from 'react';
import { FindingType, PeriodType, DimensionsType } from '../context/AiInsightsApiTypesServices';
import { engagementLevels } from '../utils';

type Locale = 'en-EN' | 'fr-FR';

export default function SentenceGenerator({ insight }) {
	const { translatedFindingName, findingType, period, dimensions } = insight;
	const locale =  getLocale();
	const periodDescription = mapPeriod(period, locale);
	const context = buildContext(dimensions, locale);

	let sentence = '';

	switch (findingType) {
		case 'BigUplift':
		case 'SmallUplift':
		case 'BigFall':
		case 'SmallFall': {
			const { variationName, variationIntensity } = getVariationDetails(findingType, locale);
			sentence = `${translatedFindingName} ${context} ${variationName} ${variationIntensity} ${locale === 'fr-FR' ? 'sur' : 'in'} ${periodDescription}.`;
			break;
		}
		case 'ProductPositiveSpotlight':
			sentence = `${translatedFindingName} ${locale === 'fr-FR' ? 'a une performance positive' : 'has a positive performance'} ${locale === 'fr-FR' ? 'sur' : 'over'} ${periodDescription}.`;
			break;
		case 'ProductNegativeSpotlight':
			sentence = `${translatedFindingName} ${locale === 'fr-FR' ? 'a une performance négative' : 'has a negative performance'} ${locale === 'fr-FR' ? 'sur' : 'over'} ${periodDescription}.`;
			break;
		case 'PositiveBusinessComparison':
			sentence = `${translatedFindingName} ${locale === 'fr-FR' ? 'est meilleur que le marché' : 'is better than the market'} ${locale === 'fr-FR' ? 'sur' : 'over'} ${periodDescription}.`;
			break;
		case 'NegativeBusinessComparison':
			sentence = `${translatedFindingName} ${locale === 'fr-FR' ? 'est moins bon que le marché' : 'is worse than the market'} ${locale === 'fr-FR' ? 'sur' : 'over'} ${periodDescription}.`;
			break;
		default:
			sentence = `${translatedFindingName} ${context} ${locale === 'fr-FR' ? 'sur' : 'over'} ${periodDescription}.`;
			break;
	}

	return <>{sentence}</>;
}

function getLocale(): Locale {
	const localeString = localStorage.getItem('i18nL');
	if (localeString && localeString.startsWith('fr-FR')) {
		return 'fr-FR';
	} else {
		return 'en-EN';
	}
}

function mapPeriod(period: PeriodType, locale: Locale): string {
	const periods = {
		'en-EN': {
			LastDay: 'last day',
			Last7Days: 'the last 7 days',
			Last30Days: 'the last 30 days',
			Last365Days: 'the last year',
		},
		'fr-FR': {
			LastDay: 'la veille',
			Last7Days: 'ces 7 derniers jours',
			Last30Days: 'ces 30 derniers jours',
			Last365Days: 'cette dernière année',
		},
	};

	return periods[locale][period] || '';
}

function getVariationDetails(findingType: FindingType, locale: Locale) {
	const variations = {
		'en-EN': {
			BigUplift: { variationName: 'has increased', variationIntensity: 'significantly' },
			SmallUplift: { variationName: 'has increased', variationIntensity: '' },
			BigFall: { variationName: 'has decreased', variationIntensity: 'significantly' },
			SmallFall: { variationName: 'has decreased', variationIntensity: '' },
		},
		'fr-FR': {
			BigUplift: { variationName: 'est en hausse', variationIntensity: 'importante' },
			SmallUplift: { variationName: 'est en hausse', variationIntensity: '' },
			BigFall: { variationName: 'est en baisse', variationIntensity: 'importante' },
			SmallFall: { variationName: 'est en baisse', variationIntensity: '' },
		},
	};

	return variations[locale][findingType] || { variationName: '', variationIntensity: '' };
}

function buildContext(dimensions: DimensionsType, locale: Locale): string {
	const dimensionPhrases = Object.entries(dimensions).map(([key, value]) =>
		mapDimension(key, value, locale)
	);
	return dimensionPhrases.length > 0 ? dimensionPhrases.join(', ') : '';
}

function mapDimension(key: string, value, locale: Locale): string {
	const isFrench = locale === 'fr-FR';

	switch (key) {
		case 'TrafficSource':
			return isFrench ? `pour la source de trafic ${value}` : `for traffic source ${value}`;
		case 'Device':
			return isFrench ? `sur les appareils ${value}` : `on ${value} devices`;
		case 'VisEngLevel':
		case 'FirstVisEngLevel':
		case 'LastVisEngLevel': {
			const engagementLabel =
				engagementLevels.find((level) => level.value === value)?.label || value;
			if (key === 'VisEngLevel') {
				return isFrench ? `avec l'engagement visiteur ${engagementLabel}` : `with visitor engagement ${engagementLabel}`;
			}
			if (key === 'FirstVisEngLevel') {
				return isFrench ? `avec le premier engagement visiteur ${engagementLabel}` : `with first visitor engagement ${engagementLabel}`;
			}
			if (key === 'LastVisEngLevel') {
				return isFrench ? `avec le dernier engagement visiteur ${engagementLabel}` : `with last visitor engagement ${engagementLabel}`;
			}
			break;
		}
		case 'PageType':
		case 'FirstPageType':
		case 'LastPageType':
			return mapPageType(value, locale);
		case 'Tenant':
			return isFrench ? `pour le tenant ${value}` : `for tenant ${value}`;
		default:
			return `${key} ${value}`;
	}

	// Return a default value if none of the cases match
	return `${key} ${value}`;
}



function mapPageType(value: string, locale: Locale): string {
	const pageTypeDescriptions = {
		H: { en: 'the home page', fr: 'la page d\'accueil' },
		FP: { en: 'the product page', fr: 'la page produit' },
		C: { en: 'the category page', fr: 'la page catégorie' },
		T: { en: 'the transaction page', fr: 'la page transaction' },
		G: { en: 'uncategorized pages', fr: 'les pages non catégorisées' },
		O: { en: 'uncategorized pages', fr: 'les pages non catégorisées' },
		B: { en: 'cart pages', fr: 'les pages panier' },
		BS: { en: 'cart summary page', fr: 'la page récapitulative du panier' },
		BL: { en: 'cart login page', fr: 'la page de connexion au panier' },
		BA: { en: 'cart address page', fr: 'la page d\'adresse du panier' },
		BC: { en: 'cart confirmation page', fr: 'la page de confirmation du panier' },
		BDevis: { en: 'quote cart page', fr: 'la page de devis du panier' },
		E: { en: 'error pages', fr: 'les pages d\'erreur' },
	};

	let pageDescription: { en: string; fr: string } | undefined;

	if (value.startsWith('B')) {
		pageDescription = { en: 'cart pages', fr: 'les pages panier' };
	} else {
		pageDescription = pageTypeDescriptions[value];
	}

	const description: string = pageDescription
		? locale === 'fr-FR'
			? pageDescription.fr
			: pageDescription.en
		: value;

	return locale === 'fr-FR' ? `pour ${description}` : `for ${description}`;
}
