import React, {useState,useEffect} from 'react';
import moment from 'moment';

import Nav from './Nav.js';
// import NavCompared from './NavCompared.js';
import NavComparison from './NavComparison.js';
import {
	findNavLabel,
	getDayInRespectOfOtherDateRange,
	getDayInRespectOfOtherDateRangeForComparedPeriod
} from '../utils';
// import {datesOptions} from '../../../WebAnalytics/components/Nav/config';

export default function NavGlobal({
	ComparisonSelected,
	setNavFilter,
	initialDates,
	handleSelectComparaisonPeriodHandler,
	setisCustomSelected,
	isCustomSelected,
	customDateRange,
	setCustomDateRange,
	updateStorage,
	showComparedPeriod = true,
	navFilter
}) {

	if(!initialDates){
		return <></>;
	}

	const [customCompareDateRange, setCustomCompareDateRange] = useState( {
		startDate: moment().subtract(1, 'month'),
		endDate: moment().subtract(1, 'day')
	});
	function handleClickOriginal(obj) {

		if(!isCustomSelected){
			const startDate = moment(obj.fromDate);
			const endDate = moment(obj.toDate);
			const { newStartDate, newEndDate } = getDayInRespectOfOtherDateRangeForComparedPeriod(startDate,endDate);
			setNavFilter(stateInit => {
				const newState = {
					...stateInit,
					fromDate: obj.fromDate,
					toDate: obj.toDate,
					label: findNavLabel(obj.fromDate, obj.toDate),
					isMainCustom: obj.label === 'Custom'
				};
				newState.fromDateToCompare = newStartDate;
				newState.toDateToCompare = newEndDate;
				newState.isCompareCustom = false;
				setCustomCompareDateRange({
					startDate:  moment(newStartDate),
					endDate: moment(newEndDate),
				});

				return newState;
			});

			updateStorage(
				obj.fromDate,
				obj.toDate,
				obj.label,
				newStartDate,
				newEndDate,
				findNavLabel(newStartDate, newEndDate)
			);
		}else{
			const startDate = moment(obj.fromDate);
			const endDate = moment(obj.toDate);
			const { newStartDate, newEndDate } = getDayInRespectOfOtherDateRangeForComparedPeriod(startDate,endDate);
			setNavFilter(stateInit => {
				const newState = {
					...stateInit,
					fromDate: obj.fromDate,
					toDate: obj.toDate,
					label: 	findNavLabel(obj.fromDate, obj.toDate),
					isMainCustom: obj.label === 'Custom'
				};
				newState.fromDateToCompare = newStartDate;
				newState.toDateToCompare = newEndDate;
				newState.isCompareCustom = false;
				setCustomCompareDateRange({
					startDate:  moment(newStartDate),
					endDate: moment(newEndDate),
				});

				return newState;
			});

			updateStorage(
				obj.fromDate,
				obj.toDate,
				obj.label,
				newStartDate,
				newEndDate,
				findNavLabel(newStartDate, newEndDate)
			);
			// updateStorage(
			// 	obj.fromDate,
			// 	obj.toDate,
			// 	obj.label,
			// 	initialDates.fromDateToCompare,
			// 	initialDates.toDateToCompare,
			// 	findNavLabel(initialDates.fromDateToCompare, initialDates.toDateToCompare)
			// );
		}

	}

	function handleClickCompared(obj) {

		if (!isCustomSelected) {
			setNavFilter(stateInit => {
				const newState = {
					...stateInit,
				};
				newState.fromDateToCompare=  obj.fromDate;
				newState.toDateToCompare = obj.toDate;
				newState.compareLabel=  findNavLabel(obj.fromDate, obj.toDate);
				newState.isCompareCustom = obj.label === 'Custom';
				return newState;
			});
			updateStorage(
				initialDates.initialFrom,
				initialDates.initialTo,
				initialDates.initialLabel,
				obj.fromDate,
				obj.toDate,
				findNavLabel(obj.fromDate, obj.toDate));
		}else{
			setNavFilter(stateInit => {

				const newState = {
					...stateInit,
				};
				newState.fromDateToCompare=  obj.fromDate;
				newState.toDateToCompare = obj.toDate;
				newState.compareLabel=  findNavLabel(obj.fromDate, obj.toDate);
				newState.isCompareCustom = obj.label === 'Custom';
				return newState;
			});

			updateStorage(
				customDateRange.startDate,
				customDateRange.endDate,
				navFilter.label,
				obj.fromDate,
				obj.toDate,
				findNavLabel(obj.fromDate, obj.toDate));
		}
	}

	useEffect(()=>{
		if(initialDates){
			setCustomCompareDateRange({
				startDate:  moment(initialDates.fromDateToCompare),
				endDate: moment(initialDates.toDateToCompare),
			});
		}
	},[initialDates]);

	return (<>
		<Nav
			handleClick={handleClickOriginal}
			initialDates={initialDates}
			showComparisonToggler={showComparedPeriod}
			setComparisonIsEnable={handleSelectComparaisonPeriodHandler}
			comparisonSelected={ComparisonSelected}
			setisCustomSelected={setisCustomSelected}
			isCustomSelected={isCustomSelected}
			customDateRange={customDateRange}
			setCustomDateRange={setCustomDateRange}
		/>
		{ComparisonSelected && showComparedPeriod &&
			<>
				<NavComparison
					initialLabel={'initialLabel2'}
					handleClick={handleClickCompared}
					initialDates={initialDates}
					navLabel="Compared to"
					customDateRange={customCompareDateRange}
					setCustomDateRange={setCustomCompareDateRange}
					setComparisonIsEnable={handleSelectComparaisonPeriodHandler}
				/>
			</>
		}
	</>
	);
}
