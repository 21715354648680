import React from 'react';
import { Mask } from '../Mask/Mask';
import styles from './spinner.module.css';

import {
	ISpinner
} from './types';

function Spinner({
	size = 'l',
	display = 'overlay',
	verticalSize = 'l',
	className
}: ISpinner) {

	const cssClass = [styles.wheel];
	const sizeClass:any = {
		xxs: styles.size_xxs,
		xs: styles.size_xs,
		s: styles.size_s,
		m: styles.size_m,
		l: styles.size_l,
		xl: styles.size_xl,
	};
	const verticalSizeClass:any = {
		xs: styles.vertical_size_xs,
		s: styles.vertical_size_s,
		m: styles.vertical_size_m,
		l: styles.vertical_size_l,
		xl: styles.vertical_size_xl,
	};

	// wheelSize
	if (size && size in sizeClass) {
		cssClass.push(sizeClass[size]);
	}

	// Display
	if (display == 'block') {
		cssClass.push(styles.full);

		// verticalSize
		if (verticalSize && verticalSize in verticalSizeClass) {
			cssClass.push(verticalSizeClass[verticalSize]);
		}
	}

	if (className) {
		cssClass.push(className);
	}

	const wheel = (
		<div className={cssClass.join(' ')}></div>
	);

	if (display == 'overlay') {
		return (
			<Mask>{wheel}</Mask>
		);
	}

	return wheel;
}

export {
	Spinner
};
