import React from 'react';
import { NavigationFiltersContainer } from '../NavigationFunnel/NavigationFilters/NavigationFiltersContainer/NavigationFiltersContainer';
import { FilterHead } from '../NavigationFunnel/NavigationFilters/NavigationFiltersContainer/FilterHead';
import { WarningDataInfo } from '../NavigationFunnel/NavigationFunnelContainer/ui/WarningDataInfo';
import { useEngagementFunnelContext } from '../../context/ContextEngagementFunnel';
import NavigationItemContainer from '../NavigationFunnel/NavigationItem/NavigationItemContainer';
import { Section } from '../../../../Components/Section/Section';
import { Article } from '../../../../Components/Article/Article';
import { Spinner } from '../../../../Components/Spinner/Spinner';
import { EmptyState } from '../../../../Components/EmptyState/EmptyState';
import styles from '../NavigationFunnel/funnel.module.css';

export const  EngagementContainer= () => {

	const context = useEngagementFunnelContext();
	const {
		feetchingData,
		hasError
	} = context;

	return (
		<Section width="m">
			<section className="section no_bottom_pad section_primary">
				<div>
					<span className='h1'>Website traffic</span>
					<i className='fas fa-chevron-right fa-fw grey_5 s_13 ml_10 mr_10'></i>
					<span className='h1'>Engagement funnel</span>
				</div>
			</section>
			<section className="section">
				<NavigationFiltersContainer context={context} />
				<div className={styles.articleWrapper}>
					<Article innerSize='l'>
						{feetchingData && 
							<Spinner display='block' verticalSize='xl' />
						}
						{!feetchingData && !hasError &&
							<>
								<FilterHead context={context} />
								<WarningDataInfo context={context} />
								<NavigationItemContainer context={context} />
							</>
						}
						{!feetchingData && hasError &&
							<EmptyState
								title="No data yet"
								text="Please contact an administrator for more information"
								textSize='xl'
								verticalSize='l'
								imageUrl='/Assets/empty_warn_1.svg'
							/>
						}
					</Article>
				</div>
			</section>
		</Section>
	);
};
