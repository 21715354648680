import React from 'react';
import { dataInfos, PartsItem } from '../NavigationFunnelTypes';
import { ItemPart } from './ItemPart';
import { EmptyState } from '../../../../../Components/EmptyState/EmptyState';
import { v4 as uuidv4 } from 'uuid';
import styles from '../funnel.module.css';

interface FunnelItemCollection {
  [key: string]: dataInfos[];
}

export const NavigationItemContainer = ({ context }: any): React.ReactElement => {
	const { dataRaw, rawDimensions, splitSelected, typeEnv } = context;
	const arrayOfPageTypes = rawDimensions && typeEnv === 'navigationFunnel'
		? getPagetype(rawDimensions['PageType'])
		: rawDimensions && rawDimensions['VisEngLevel'];

	function getPagetype(array) {
		if (!array || !array.values) return null;
		const keyToDelete = ['', 'O', 'G'];
		const pt_tmp = { ...array };
		pt_tmp.values = array.values.filter(el => !keyToDelete.includes(el.id));
		return pt_tmp;
	}

	function sortByDataPages(partName: string, dimensionKey: string) {
		if (rawDimensions && dataRaw) {
			const objectData: PartsItem = dataRaw.parts[partName];
			if (!objectData || !objectData.items) {
				return;
			}
			return objectData.items.reduce((acc: FunnelItemCollection, item: dataInfos) => {
				const key = item.dimensions[dimensionKey];
				if (!acc[key]) {
					acc[key] = [{
						dimensions: { ...item.dimensions },
						value: item.value,
						sampleSize: item.sampleSize,
					}];
				} else {
					acc[key] = [...acc[key], item];
				}
				return acc;
			}, {} as FunnelItemCollection);
		}
	}

	const SessionFirstPT: FunnelItemCollection | undefined = sortByDataPages('SessionFirstPT', typeEnv === 'navigationFunnel' ? 'FirstPageType' : 'FirstVisEngLevel');
	const PageType: FunnelItemCollection | undefined = sortByDataPages('PageType', typeEnv === 'navigationFunnel' ? 'PageType' : 'VisEngLevel');
	const SessionLastPT: FunnelItemCollection | undefined = sortByDataPages('SessionLastPT', typeEnv === 'navigationFunnel' ? 'LastPageType' : 'LastVisEngLevel');

	const pageTypeArr = typeEnv === 'navigationFunnel' ? dataRaw?.parts['PageType'] : dataRaw?.parts['PageType'];
	const totalPagetype = pageTypeArr?.items.reduce((acc: number, item: any) => {
		acc += item.value;
		return acc;
	}, 0);

	const maximumValue = arrayOfPageTypes?.values.reduce((max: number, pageType: any) => {
		const id: any = pageType.id;
		const pageTypeTotal = PageType && PageType[id]?.reduce((total: number, dimension: any) => {
			return total + dimension.value;
		}, 0);
		if (pageTypeTotal && pageTypeTotal > max) max = pageTypeTotal;
		return max;
	}, 0);

	function getIsEmpty(parts): boolean {
		if (parts) {
			for (const key in parts.parts) {
				if (parts.parts.hasOwnProperty(key)) {
					if (parts.parts[key].items && parts.parts[key].items.length > 0) {
						return false;
					}
				}
			}
		}
		return true;
	}

	const isEmpty: boolean = getIsEmpty(dataRaw);
	const isRawDimensionsEmpty = !rawDimensions || Object.keys(rawDimensions).length === 0;
	return (
		<>
			{!isEmpty && SessionFirstPT && arrayOfPageTypes && (
				<div className={styles.funnel}>
					{arrayOfPageTypes.values.map((pageType, i) => (
						<div className={styles.row} key={uuidv4()}>
							<ItemPart
								name={'Entrances'}
								data={SessionFirstPT[pageType.id]}
								pageType={pageType}
								middleBlock={PageType}
								splitSelected={splitSelected}
								typeEnv={typeEnv}
								context={{ ...context }}
							/>
							{PageType && (
								<ItemPart
									name={'Middle'}
									data={PageType[pageType.id]}
									pageType={pageType}
									middleBlock={PageType}
									splitSelected={splitSelected}
									totalPageType={totalPagetype}
									maximumValue={maximumValue}
									typeEnv={typeEnv}
									context={{ ...context }}
									stepNumber={i + 1}
								/>
							)}
							{SessionLastPT && (
								<ItemPart
									name={'Exits'}
									splitSelected={splitSelected}
									data={SessionLastPT[pageType.id]}
									pageType={pageType}
									middleBlock={PageType}
									typeEnv={typeEnv}
									context={{ ...context }}
								/>
							)}
						</div>
					))}
				</div>
			)}
			{isEmpty && (
				<EmptyState
					icon="fas fa-search"
					title="No matching data"
					text="Please try another search"
					verticalSize="l"
					textSize="l"
				/>
			)}
			{isRawDimensionsEmpty && (
				<EmptyState
					icon="fas fa-search"
					title="No data yet"
					text="Please contact your admin"
					verticalSize="l"
					textSize="l"
				/>
			)}
		</>
	);
};

export default NavigationItemContainer;