import React, { useState } from 'react';
import { react2angular } from 'react2angular';
import Btn from './Btn';
import Link from './Link';
import Checkbox from './Checkbox';
import Radio from './Radio';
import Switch from './Switch';
import Modal from './Modal';
import Avatar from './Avatar';
import SelectCustom from './SelectCustom';
import SelectDropdown from './SelectDropdown';
import InputCustom from './InputCustom';
import NumericUpDown from './NumericUpDown';
import SearchBar from './SearchBar';
import InputCopyToClipBoard from './InputCopyToClipBoard';
import ColorPicker from './ColorPicker';
import Panel from './Panel';
import ReactDatePicker from 'react-datepicker';
import SectionMessage from './SectionMessage';
import Picto from './Picto';
import {
	iconSegment,
	iconPerformance,
	iconEmailTemplate,
	iconAbandonedCart,
	iconHistory,
	iconRecommendation,
	iconThumbUp,
	iconThumbDown,
	iconProgressUp,
	iconProgressDown,
	iconScales,
	iconFunnel,
	iconBarCode,
	iconTraffic,
	iconVariable,
	iconNotification,
	iconNotificationMessage,
	iconOnSite,
	iconEmr,
	iconEmerch,
	iconSimplify,
	iconABtest,
	iconAnalytics,
	iconProductMonitoring,
	iconScoring,
	iconGraphicPatch,
	iconCustomTemplate,
	iconTemplate,
	iconLight,
	iconMobile,
	iconDesktop,
	iconTablet,
	iconPage,
	iconTrafficSource,
	iconUserRecurring,
	iconGlobe
} from './CustomIcon/CustomIcon';

export default function UiContainer(props) {
	const click = () => {
		alert('Click !');
	};

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [panelIsOpen, setPanelsIsOpen] = useState(false);

	const toggleModal = () => {
		setModalIsOpen(!modalIsOpen);
	};
	const togglePanel = (index) => {
		if (panelIsOpen === index) {
			setPanelsIsOpen(false);
			return;
		}
		setPanelsIsOpen(index);
	};
	const [myBigState, setMyBigState] = useState({
		name: '',
		lorem: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
		num: 180,
		description: '',
		mySelect: '',
		mySelect2: '',
		city: ''
	});

	const [tmpColor, setTmpColor] = useState('#ff5598');

	const optionSelect = [
		{
			value: 'value1',
			label: 'Value 1'
		},
		{
			value: 'value2',
			label: 'Value 2'
		},
		{
			value: 'value3',
			label: 'Value 3'
		},
		{
			value: 'value4',
			label: 'Value 4'
		},
		{
			value: 'value5',
			label: 'Value 5',
			isDisabled: true
		},
	];

	const selectCityList = [
		{ value: 'Aix-en-Provence', label: 'Aix-en-Provence' },
		{ value: 'Amiens', label: 'Amiens' },
		{ value: 'Angers', label: 'Angers' },
		{ value: 'Argenteuil', label: 'Argenteuil' },
		{ value: 'Besançon', label: 'Besançon' },
		{ value: 'Bordeaux', label: 'Bordeaux' },
		{ value: 'Boulogne-Billancourt', label: 'Boulogne-Billancourt' },
		{ value: 'Brest', label: 'Brest' },
		{ value: 'Caen', label: 'Caen' },
		{ value: 'Clermont-Ferrand', label: 'Clermont-Ferrand' },
		{ value: 'Dijon', label: 'Dijon' },
		{ value: 'Grenoble', label: 'Grenoble' },
		{ value: 'Le Havre', label: 'Le Havre' },
		{ value: 'Le Mans', label: 'Le Mans' },
		{ value: 'Lille', label: 'Lille' },
		{ value: 'Limoges', label: 'Limoges' },
		{ value: 'Lyon', label: 'Lyon' },
		{ value: 'Marseille', label: 'Marseille' },
		{ value: 'Metz', label: 'Metz' },
		{ value: 'Montpellier', label: 'Montpellier' },
		{ value: 'Mulhouse', label: 'Mulhouse' },
		{ value: 'Nantes', label: 'Nantes' },
		{ value: 'Nice', label: 'Nice' },
		{ value: 'Nîmes', label: 'Nîmes' },
		{ value: 'Orléans', label: 'Orléans' },
		{ value: 'Paris', label: 'Paris' },
		{ value: 'Perpignan', label: 'Perpignan' },
		{ value: 'Reims', label: 'Reims' },
		{ value: 'Rennes', label: 'Rennes' },
		{ value: 'Rouen', label: 'Rouen' },
		{ value: 'Saint-Denis', label: 'Saint-Denis' },
		{ value: 'Saint-Denis', label: 'Saint-Denis' },
		{ value: 'Saint-Étienne', label: 'Saint-Étienne' },
		{ value: 'Strasbourg', label: 'Strasbourg' },
		{ value: 'Toulon', label: 'Toulon' },
		{ value: 'Toulouse', label: 'Toulouse' },
		{ value: 'Tours', label: 'Tours' },
		{ value: 'Villeurbanne', label: 'Villeurbanne' },
	];

	const [selectCityListFiltered, setSelectCityListFiltered] = useState(selectCityList);
	const [selectAutocompleteValue, setSelectAutocompleteValue] = useState('');

	const handleSelectACCHange = (v) => {
		setSelectAutocompleteValue(v);

		const s = v.toLowerCase();
		let tmp_list = selectCityList;
		if (v != '') {
			tmp_list = selectCityList.filter(el => {
				const l = el.label.toLowerCase();
				return l.indexOf(s) > -1;
			});
		}

		setSelectCityListFiltered(tmp_list);
	};

	const handleChange = (e) => {
		const newSelectValue = e.target.value;
		let myStateToChange = { ...myBigState };
		myStateToChange[e.target.name] = newSelectValue;
		setMyBigState(myStateToChange);
	};

	const handleChangeForm = (e) => {
		handleChangeFormValue(e.target.name, e.target.value);
	};

	const handleChangeFormValue = (name, value) => {
		let myStateToChange = { ...myBigState };
		myStateToChange[name] = value;
		setMyBigState(myStateToChange);
	};

	const [clipBoardContain, setClipBoardContain] = useState(false);
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setClipBoardContain(value);
	};

	const tmpUsers = [
		{
			firstName: 'Romain',
			lastName: 'Sossong',
			email: 'romain@beyable.com',
			id: '1',
		},
		{
			firstName: 'Romain',
			lastName: 'Sossong',
			email: 'romain@beyable.com',
			id: '2',
		},
		{
			firstName: 'Romain',
			lastName: 'Sossong',
			email: 'romain@beyable.com',
			id: '3',
		},
		{
			firstName: 'Romain',
			lastName: 'Sossong',
			email: 'romain@beyable.com',
			id: '4',
		},
		{
			firstName: 'Romain',
			lastName: 'Sossong',
			email: 'romain@beyable.com',
			id: '5',
		},
		{
			firstName: 'Romain',
			lastName: 'Sossong',
			email: 'romain@beyable.com',
			id: '6',
		},
		{
			firstName: 'Romain',
			lastName: 'Sossong',
			email: 'romain@beyable.com',
			id: '7',
		}
	];

	const [btnIsLoading, setBtnIsLoading] = useState(false);

	return (
		<div>
			<h1>Beyable UI Components</h1>

			<h2>Buttons</h2>
			<Checkbox forcedChecked={btnIsLoading} onChange={(e) => setBtnIsLoading(e.target.checked)}>
				Loading
			</Checkbox>

			<div>
				<h3>Style : default</h3>
				<Btn isLoading={btnIsLoading} color="primary">Primary</Btn>
				<Btn isLoading={btnIsLoading} color="secondary">Secondary</Btn>
				<Btn isLoading={btnIsLoading} color="alert">Alert</Btn>
				<Btn isLoading={btnIsLoading} color="warning">Warning</Btn>
				<Btn isLoading={btnIsLoading} color="success">Success</Btn>
				<Btn isLoading={btnIsLoading} color="primary" disabled={true}>Disabled</Btn>
				<br />
				<h3>Style : reverse</h3>
				<Btn isLoading={btnIsLoading} color="primary" style="reverse">Primary</Btn>
				<Btn isLoading={btnIsLoading} color="secondary" style="reverse">Secondary</Btn>
				<Btn isLoading={btnIsLoading} color="alert" style="reverse">Alert</Btn>
				<Btn isLoading={btnIsLoading} color="warning" style="reverse">Warning</Btn>
				<Btn isLoading={btnIsLoading} color="success" style="reverse">Success</Btn>
				<Btn isLoading={btnIsLoading} color="primary" style="reverse" disabled={true}>Disabled</Btn>
				<br />
				<h3>Style : outline</h3>
				<Btn isLoading={btnIsLoading} color="primary" style="outline">Primary</Btn>
				<Btn isLoading={btnIsLoading} color="secondary" style="outline">Secondary</Btn>
				<Btn isLoading={btnIsLoading} color="alert" style="outline">Alert</Btn>
				<Btn isLoading={btnIsLoading} color="warning" style="outline">Warning</Btn>
				<Btn isLoading={btnIsLoading} color="success" style="outline">Success</Btn>
				<Btn isLoading={btnIsLoading} color="primary" style="outline" disabled={true}>Disabled</Btn>
				<br />
				<h3>Style : reverse outline</h3>
				<Btn isLoading={btnIsLoading} color="primary" style="reverse_outline">Primary</Btn>
				<Btn isLoading={btnIsLoading} color="secondary" style="reverse_outline">Secondary</Btn>
				<Btn isLoading={btnIsLoading} color="alert" style="reverse_outline">Alert</Btn>
				<Btn isLoading={btnIsLoading} color="warning" style="reverse_outline">Warning</Btn>
				<Btn isLoading={btnIsLoading} color="success" style="reverse_outline">Success</Btn>
				<Btn isLoading={btnIsLoading} color="primary" style="reverse_outline" disabled={true}>Disabled</Btn>
				<br />
				<h3>Style : ghost</h3>
				<Btn isLoading={btnIsLoading} color="primary" style="ghost">Primary</Btn>
				<Btn isLoading={btnIsLoading} color="secondary" style="ghost">Secondary</Btn>
				<Btn isLoading={btnIsLoading} color="alert" style="ghost">Alert</Btn>
				<Btn isLoading={btnIsLoading} color="warning" style="ghost">Warning</Btn>
				<Btn isLoading={btnIsLoading} color="success" style="ghost">Success</Btn>
				<Btn isLoading={btnIsLoading} color="primary" style="ghost" disabled={true}>Disabled</Btn>
				<br />
				<h3>Style : ghost_outline</h3>
				<Btn isLoading={btnIsLoading} color="primary" style="ghost_outline">Primary</Btn>
				<Btn isLoading={btnIsLoading} color="secondary" style="ghost_outline">Secondary</Btn>
				<Btn isLoading={btnIsLoading} color="alert" style="ghost_outline">Alert</Btn>
				<Btn isLoading={btnIsLoading} color="warning" style="ghost_outline">Warning</Btn>
				<Btn isLoading={btnIsLoading} color="success" style="ghost_outline">Success</Btn>
				<Btn isLoading={btnIsLoading} color="primary" style="ghost_outline" disabled={true}>Disabled</Btn>
				<br />
				<h3>Style : link</h3>
				<Btn isLoading={btnIsLoading} color="primary" style="link" icon="fas fa-plus-circle">Primary</Btn>
				<Btn isLoading={btnIsLoading} color="secondary" style="link" icon="fas fa-plus-circle">Secondary</Btn>
				<Btn isLoading={btnIsLoading} color="alert" style="link" icon="fas fa-plus-circle">Alert</Btn>
				<Btn isLoading={btnIsLoading} color="warning" style="link" icon="fas fa-plus-circle">Warning</Btn>
				<Btn isLoading={btnIsLoading} color="success" style="link" icon="fas fa-plus-circle">Success</Btn>
				<Btn isLoading={btnIsLoading} color="primary" style="link" icon="fas fa-plus-circle" disabled={true}>Disabled</Btn>

				<h3>Size</h3>
				<Btn isLoading={btnIsLoading} size="xl">Size XL</Btn>
				<Btn isLoading={btnIsLoading} size="l">Size L</Btn>
				<Btn isLoading={btnIsLoading} size="m">Size M</Btn>
				<Btn isLoading={btnIsLoading} size="s">Size S</Btn>
				<Btn isLoading={btnIsLoading} size="xs">Size XS</Btn>
				<Btn isLoading={btnIsLoading} size="xxs">Size XXS</Btn>

				<h3>With icon</h3>
				<Btn icon="fas fa-pen"></Btn>
				<Btn icon="fas fa-chevron-right" color="success" iconPosition="after"></Btn>
				<Btn icon="fas fa-trash" color="alert"></Btn>
				<Btn icon="fas fa-pen">Edit</Btn>
				<Btn icon="fas fa-chevron-right" color="success" iconPosition="after">Next</Btn>
				<Btn icon="fas fa-trash" color="alert">Delete</Btn>
			</div>
			<br />

			<h2>Links</h2>
			<div>
				<Link
					message="Default link"
				/>
				<Link
					message="Inherited color"
					color=""
				/>
				<Link
					message="Underline"
					underline={true}
				/>
				<Link
					message="Light"
					strong={false}
				/>
				<Link
					message="Disabled"
					strong={false}
					disabled={true}
				/>
			</div>
			<br />

			<h2>Checkbox and Radio</h2>
			<div>
				<Checkbox checked={true}>Checkbox</Checkbox>
				<Checkbox>Checkbox</Checkbox>
				<Checkbox disabled={true} checked={true}>Checkbox</Checkbox>
				<Checkbox disabled={true}>Checkbox</Checkbox>
				<br />
				<br />
				<Switch checked={true}>Switch</Switch>
				<Switch>Switch</Switch>
				<Switch disabled={true} checked={true}>Switch</Switch>
				<Switch disabled={true}>Switch</Switch>
				<br />
				<br />
				<Switch size="s" checked={true}>Switch</Switch>
				<Switch size="s">Switch</Switch>
				<Switch size="s" disabled={true} checked={true}>Switch</Switch>
				<Switch size="s" disabled={true}>Switch</Switch>
				<br />
				<br />
				<Radio name="ui-page-radio" checked={true}>Radio</Radio>
				<Radio name="ui-page-radio">Radio</Radio>
				<Radio name="ui-page-radio2" disabled={true} checked={true}>Radio</Radio>
				<Radio name="ui-page-radio2" disabled={true}>Radio</Radio>
			</div>
			<br />
			<h2>Modal</h2>
			<div>
				<Btn onClick={(ev) => toggleModal()}>Open modal</Btn>
				<Modal
					isOpen={modalIsOpen}
					onClose={() => toggleModal()}>
					<div className="modal_header">Modal header</div>
					<div className="modal_body">Modal body</div>
				</Modal>
			</div>

			<h2>Panel</h2>
			<div>
				<Btn onClick={(ev) => togglePanel(2)}>Right</Btn>
				<Btn onClick={(ev) => togglePanel(1)}>Left</Btn>
				<Btn onClick={(ev) => togglePanel(3)}>800px</Btn>
				<Btn onClick={(ev) => togglePanel(4)}>Sans overlay</Btn>
				<Panel
					isOpen={panelIsOpen === 1}
					onClose={(e) => togglePanel(1)}
					side="left">
					<div className="modal_header">Panel header</div>
					<div className="modal_body">Modal body</div>
				</Panel>

				<Panel
					isOpen={panelIsOpen === 2}
					onClose={(e) => togglePanel(2)}
					side="right">
					<div className="modal_header">Panel header</div>
					<div className="modal_body">Modal body</div>
				</Panel>

				<Panel
					isOpen={panelIsOpen === 3}
					onClose={(e) => togglePanel(3)}
					width="800">
					<div className="modal_header">Panel header</div>
					<div className="modal_body">Modal body</div>
				</Panel>

				<Panel
					isOpen={panelIsOpen === 4}
					onClose={(e) => togglePanel(4)}
					overlay={false}>
					<div className="modal_header">Panel header</div>
					<div className="modal_body">Modal body</div>
				</Panel>
				<h2>Select Custom</h2>
				<div>
					<SelectCustom
						optionsList={optionSelect}
						label="My select"
						name="mySelect"
						value={myBigState.mySelect}
						onChangeFunction={(e) => handleChange(e)}
						defaultValueDisabled={{
							value: 'choose',
							label: 'Choose option',
							isDisabled: true
						}}
					/>
					<SelectCustom
						optionsList={optionSelect}
						label="My select"
						name="mySelect"
						value={myBigState.mySelect}
						onChangeFunction={(e) => handleChange(e)}
						fullWidth={true}
					/>
					<SelectCustom
						optionsList={optionSelect}
						label="My select"
						defaultValue={optionSelect[1]}
						name="mySelect2"
						value={myBigState.mySelect}
						onChangeFunction={(e) => handleChange(e)}
						autoWidth={true}
						color="white"
					/>
				</div>

				<h2>Select dropdown</h2>
				<div>
					<div>
						<SelectDropdown
							optionsList={optionSelect}
							value={myBigState.mySelect}
							onChange={(v) => handleChangeFormValue('mySelect', v)}
							placeHolder="Without label"
						/>
					</div>
					<div>
						<SelectDropdown
							label="With outer label"
							placeHolder="With outer label"
							optionsList={optionSelect}
							value={myBigState.mySelect}
							onChange={(v) => handleChangeFormValue('mySelect', v)}
						/>
					</div>
					<div>
						<SelectDropdown
							label="With inner label"
							placeHolder="With inner label"
							labelPosition="inner"
							optionsList={optionSelect}
							value={myBigState.mySelect}
							onChange={(v) => handleChangeFormValue('mySelect', v)}
						/>
					</div>
					<div>
						<SelectDropdown
							label="Disabled"
							placeHolder="Disabled"
							labelPosition="inner"
							optionsList={optionSelect}
							value={myBigState.mySelect}
							onChange={(v) => handleChangeFormValue('mySelect', v)}
							disabled={true}
						/>
					</div>
					<div>
						<SelectDropdown
							label="Clearable"
							placeHolder="Clearable"
							labelPosition="inner"
							optionsList={optionSelect}
							value={myBigState.mySelect}
							onChange={(v) => handleChangeFormValue('mySelect', v)}
							isClearable={true}
							color="white"
						/>
					</div>
					<div>
						<SelectDropdown
							label="With autocomplete"
							labelPosition="inner"
							optionsList={selectCityListFiltered}
							value={myBigState.city}
							onChange={(v) => handleChangeFormValue('city', v)}
							color="white"
							autocomplete={true}
							autocompleteValue={selectAutocompleteValue}
							autocompletePlaceholder={'Search for a product name or ID'}
							onAutocomplete={(v) => handleSelectACCHange(v)}
							isClearable={true}
						/>
					</div>
				</div>


				<h2>Texte fields</h2>
				<h3 className="fw_medium s_16">InputCustom</h3>
				<InputCustom
					value={myBigState.name}
					name="name"
					type="text"
					label="Text"
					placeholder="Text"
					onChange={(e) => handleChangeForm(e)}
				/>

				{/* <InputCustom
					value={myBigState.name}
					name="name"
					type="text"
					label="Text with autoFocus"
					placeholder="Text with autoFocus"
					onChange={(e) => handleChangeForm(e)}
					autoFocus={true}
				/> */}

				<InputCustom
					value={myBigState.name}
					name="name"
					type="text"
					label="Text with autogrow"
					placeholder="Text with autogrow"
					onChange={(e) => handleChangeForm(e)}
					autogrow={true}
				/>

				<InputCustom
					value={myBigState.lorem}
					name="lorem"
					type="text"
					label="Text with autogrow and max width"
					placeholder="Text with autogrow and max width"
					onChange={(e) => handleChangeForm(e)}
					autogrow={true}
					maxWidth={300}
				/>

				<InputCustom
					value={myBigState.name}
					name="name"
					type="text"
					label="Text with error"
					onChange={(e) => handleChangeForm(e)}
					isAnError={'Ce champ n\'est pas valide'}
				/>

				<InputCustom
					value={myBigState.name}
					name="name"
					type="text"
					label="Text with description"
					description="Here is the description"
					onChange={(e) => handleChangeForm(e)}
				/>

				<InputCustom
					value={myBigState.name}
					name="name"
					type="text"
					label="Text with sub description"
					descriptionBottom="Here is the sub description"
					onChange={(e) => handleChangeForm(e)}
				/>

				<InputCustom
					value={myBigState.name}
					name="name"
					type="text"
					label="Text width append"
					onChange={(e) => handleChangeForm(e)}
					append={
						<span className="custom_input_reset">
							<i className="fas fa-times-circle"></i>
						</span>
					}
				/>

				<InputCustom
					value={myBigState.num}
					name="num"
					type="number"
					label="Number"
					onChange={(e) => handleChangeForm(e)}
				/>

				<InputCustom
					value={myBigState.num}
					name="num"
					type="number"
					label="Number with unit"
					unit="km"
					className="w_s"
					onChange={(e) => handleChangeForm(e)}
				/>

				<InputCustom
					value={myBigState.num}
					name="num"
					type="number"
					label="Number with unit"
					unit="km"
					className="w_xs"
					onChange={(e) => handleChangeForm(e)}
				/>

				<InputCustom
					value={myBigState.num}
					name="num"
					type="number"
					label="Number with unit"
					unit="km"
					className="w_xxs"
					onChange={(e) => handleChangeForm(e)}
				/>

				<InputCustom
					value={myBigState.description}
					name="description"
					type="textarea"
					rows="5"
					label="Textarea (5 rows)"
					onChange={(e) => handleChangeForm(e)}
				/>

				<InputCustom
					value={myBigState.lorem}
					name="lorem"
					type="textarea"
					rows="3"
					autogrow={true}
					label="Textarea with autogrow"
					onChange={(e) => handleChangeForm(e)}
				/>

				<h3 className="fw_medium s_16">NumericUpDown</h3>
				<NumericUpDown
					value={myBigState.num}
					min={0}
					name="num"
					type="number"
					label="Numeric Up Down"
					canEdit={true}
					onChange={(e) => handleChangeFormValue('num', e.target.value)}
				/>

				<h3 className="fw_medium s_16">ColorPicker</h3>
				<ColorPicker
					label={'Color picker'}
					value={tmpColor}
					onChange={(e) => setTmpColor(e)}
				/>

				<ColorPicker
					label={'Color picker'}
					value={tmpColor}
					onChange={(e) => setTmpColor(e)}
					size="s"
				/>

				<ColorPicker
					label={'Color picker'}
					value={tmpColor}
					onChange={(e) => setTmpColor(e)}
					fullWidth={true}
				/>

				<h3 className="fw_medium s_16">SearchBar</h3>

				<div className="mb_10">
					<SearchBar
						onChange={(value) => handleChangeFormValue('name', value)}
						placeholder="Search for an operation"
						valueInSearchBar={myBigState.name}
					/>
				</div>
				<div className="mb_10">
					<SearchBar
						onChange={(value) => handleChangeFormValue('name', value)}
						placeholder="fullWidth: false"
						valueInSearchBar={myBigState.name}
						fullWidth={false}
					/>
				</div>
				<div className="mb_10">
					<SearchBar
						onChange={(value) => handleChangeFormValue('name', value)}
						placeholder="width: l"
						valueInSearchBar={myBigState.name}
						width={'l'}
					/>
				</div>
				<div className="mb_10">
					<SearchBar
						onChange={(value) => handleChangeFormValue('name', value)}
						placeholder="width: xl"
						valueInSearchBar={myBigState.name}
						width={'xl'}
					/>
				</div>
				<div className="mb_10">
					<SearchBar
						onChange={(value) => handleChangeFormValue('name', value)}
						placeholder="color: white"
						valueInSearchBar={myBigState.name}
						width={'xl'}
						color='white'
					/>
				</div>

				<h3 className="fw_medium s_16">ReactDatePicker</h3>

				<label className="custom_input">
					<ReactDatePicker
						showTimeSelect
						timeFormat="HH:mm"
						timeIntervals={15}
						timeCaption="time"
						dateFormat="dd MMM yyyy - H:mm"
					/>
					<i className="custom_input_icon fas fa-calendar-alt"></i>
				</label>

				<h2>Copy To ClipBoard</h2>
				<div>
					<InputCopyToClipBoard
						clipBoardContain={clipBoardContain}
						onClickFunction={(e) => copyToClipBoard(e)}
						value="966516cdca9b44ddb7c0302b4eb0a843"
					/>
					<br />
					<InputCopyToClipBoard
						clipBoardContain={clipBoardContain}
						onClickFunction={(e) => copyToClipBoard(e)}
						value="5555555"
					/>
				</div>
			</div>

			<h2>Notifications</h2>
			<div>
				<a onClick={() => props.$rootScope.$broadcast('event:alert-messageSent', { type: 'danger', msg: 'Error' })}>Error</a>
				<a onClick={() => props.$rootScope.$broadcast('event:alert-messageSent', { type: 'warning', msg: 'Warning' })}>Warning</a>
				<a onClick={() => props.$rootScope.$broadcast('event:alert-messageSent', { type: 'success', msg: 'Success' })}>Success</a>
			</div>


			<h2>Avatar</h2>
			{tmpUsers.map((u, i) =>
				<div key={i}>
					<Avatar user={u} size="s" />
					<Avatar user={u} size="m" />
					<Avatar user={u} size="l" />
					<Avatar user={u} size="xl" />
					<Avatar user={u} size="xxl" />
				</div>
			)}

			<h2>Custom icons</h2>
			{['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'].map((size) =>
				<div className='flex flex_wrap' style={{gap: '6px', margin: '6px 0'}}>
					<span style={{width: 50}}>{size}</span>
					<Picto size={size} color="blue" iconCustom={iconSegment} tooltip={'iconSegment'} />
					<Picto size={size} color="blue" iconCustom={iconPerformance} tooltip={'iconPerformance'} />
					<Picto size={size} color="blue" iconCustom={iconEmailTemplate} tooltip={'iconEmailTemplate'} />
					<Picto size={size} color="blue" iconCustom={iconAbandonedCart} tooltip={'iconAbandonedCart'} />
					<Picto size={size} color="blue" iconCustom={iconHistory} tooltip={'iconHistory'} />
					<Picto size={size} color="blue" iconCustom={iconRecommendation} tooltip={'iconRecommendation'} />
					<Picto size={size} color="blue" iconCustom={iconThumbUp} tooltip={'iconThumbUp'} />
					<Picto size={size} color="blue" iconCustom={iconThumbDown} tooltip={'iconThumbDown'} />
					<Picto size={size} color="blue" iconCustom={iconProgressUp} tooltip={'iconProgressUp'} />
					<Picto size={size} color="blue" iconCustom={iconProgressDown} tooltip={'iconProgressDown'} />
					<Picto size={size} color="blue" iconCustom={iconScales} tooltip={'iconScales'} />
					<Picto size={size} color="blue" iconCustom={iconFunnel} tooltip={'iconFunnel'} />
					<Picto size={size} color="blue" iconCustom={iconBarCode} tooltip={'iconBarCode'} />
					<Picto size={size} color="blue" iconCustom={iconTraffic} tooltip={'iconTraffic'} />
					<Picto size={size} color="blue" iconCustom={iconVariable} tooltip={'iconVariable'} />
					<Picto size={size} color="blue" iconCustom={iconNotification} tooltip={'iconNotification'} />
					<Picto size={size} color="blue" iconCustom={iconNotificationMessage} tooltip={'iconNotificationMessage'} />
					<Picto size={size} color="blue" iconCustom={iconOnSite} tooltip={'iconOnSite'} />
					<Picto size={size} color="blue" iconCustom={iconEmr} tooltip={'iconEmr'} />
					<Picto size={size} color="blue" iconCustom={iconEmerch} tooltip={'iconEmerch'} />
					<Picto size={size} color="blue" iconCustom={iconSimplify} tooltip={'iconSimplify'} />
					<Picto size={size} color="blue" iconCustom={iconABtest} tooltip={'iconABtest'} />
					<Picto size={size} color="blue" iconCustom={iconAnalytics} tooltip={'iconAnalytics'} />
					<Picto size={size} color="blue" iconCustom={iconProductMonitoring} tooltip={'iconProductMonitoring'} />
					<Picto size={size} color="blue" iconCustom={iconScoring} tooltip={'iconScoring'} />
					<Picto size={size} color="blue" iconCustom={iconGraphicPatch} tooltip={'iconGraphicPatch'} />
					<Picto size={size} color="blue" iconCustom={iconCustomTemplate} tooltip={'iconCustomTemplate'} />
					<Picto size={size} color="blue" iconCustom={iconTemplate} tooltip={'iconTemplate'} />
					<Picto size={size} color="blue" iconCustom={iconLight} tooltip={'iconLight'} />
					<Picto size={size} color="blue" iconCustom={iconMobile} tooltip={'iconMobile'} />
					<Picto size={size} color="blue" iconCustom={iconDesktop} tooltip={'iconDesktop'} />
					<Picto size={size} color="blue" iconCustom={iconTablet} tooltip={'iconTablet'} />
					<Picto size={size} color="blue" iconCustom={iconPage} tooltip={'iconPage'} />
					<Picto size={size} color="blue" iconCustom={iconTrafficSource} tooltip={'iconTrafficSource'} />
					<Picto size={size} color="blue" iconCustom={iconUserRecurring} tooltip={'iconUserRecurring'} />
					<Picto size={size} color="blue" iconCustom={iconGlobe} tooltip={'iconGlobe'} />
				</div>
			)}
			

			<h2>Section message</h2>
			<div>
				<SectionMessage
					title="Message d’information"
					text="You’re not allowed to change these restrictions. It’s either due to the restrictions on the page, or permission settings for this space."
				/>
				<SectionMessage
					title="Message de réussite"
					text="You’re not allowed to change these restrictions. It’s either due to the restrictions on the page, or permission settings for this space."
					type="success"
				/>
				<SectionMessage
					title="Message d’avertissement"
					text="You’re not allowed to change these restrictions. It’s either due to the restrictions on the page, or permission settings for this space."
					type="warning"
				/>
				<SectionMessage
					title="Message d’alerte ou d’erreur"
					text="You’re not allowed to change these restrictions. It’s either due to the restrictions on the page, or permission settings for this space."
					type="alert"
				/>

				<SectionMessage
					title="Message d’information"
					text="You’re not allowed to change these restrictions. It’s either due to the restrictions on the page, or permission settings for this space."
					appearance="outline"
				/>
				<SectionMessage
					title="Message de réussite"
					text="You’re not allowed to change these restrictions. It’s either due to the restrictions on the page, or permission settings for this space."
					type="success"
					appearance="outline"
				/>
				<SectionMessage
					title="Message d’avertissement"
					text="You’re not allowed to change these restrictions. It’s either due to the restrictions on the page, or permission settings for this space."
					type="warning"
					appearance="outline"
				/>
				<SectionMessage
					title="Message d’alerte ou d’erreur"
					text="You’re not allowed to change these restrictions. It’s either due to the restrictions on the page, or permission settings for this space."
					type="alert"
					appearance="outline"
				/>
			</div>


			<h2>Flex</h2>
			<div className="flex __flex_ex">
				<div className="flex_item_fix">
					FIX
				</div>
				<div className="flex_item_full">
					FULL
				</div>
				<div className="flex_item_fix">
					FIX
				</div>
			</div>
			<div className="flex __flex_ex">
				<div className="flex_item_fix">
					FIX
				</div>
				<div className="flex_item_fix">
					FIX
				</div>
				<div className="flex_item_auto">
					AUTO
					<div>Un peu de texte pour voir ce que ça donne.</div>
				</div>
				<div className="flex_item_fix">
					FIX
				</div>
			</div>
			<div className="flex __flex_ex">
				<div className="flex_item_fix">
					FIX
				</div>
				<div className="flex_item_fix">
					FIX
				</div>
				<div className="flex_item_auto">
					AUTO
					<div>
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
					</div>
				</div>
				<div className="flex_item_fix">
					FIX
				</div>
			</div>
			<div className="flex flex_align_start __flex_ex">
				<div className="flex_item_fix">
					FIX
				</div>
				<div className="flex_item_fix">
					FIX
				</div>
				<div className="flex_item_auto">
					AUTO
					<div>
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
					</div>
				</div>
				<div className="flex_item_fix">
					FIX
				</div>
			</div>

			<div className="flex flex_align_stretch __flex_ex">
				<div className="flex_item_fix">
					FIX
				</div>
				<div className="flex_item_fix">
					FIX
				</div>
				<div className="flex_item_auto">
					AUTO
					<div>
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
						Beaucoup de texte pour voir ce que ça donne.
					</div>
				</div>
				<div className="flex_item_fix">
					FIX
				</div>
			</div>

			<div style={{ 'height': 300 }} className="flex flex_v __flex_ex">
				<div className="flex_item_fix">
					FIX
				</div>
				<div className="flex_item_full">
					FULL
				</div>
				<div className="flex_item_fix">
					FIX
				</div>
			</div>

		</div>
	);
}
angular
	.module('beyableSaasApp.ui', [])
	.component('uicontainer', react2angular(UiContainer, [], ['$http', '$rootScope', '$routeParams']));