import React, { useCallback, useState, Fragment } from 'react';
import { Skeleton } from '../../../Components/Skeleton/Skeleton';
import { IconTooltip } from '../../../Components/Tooltip/IconTooltip';
import { BespokeStatistics } from '../KPI/typesKpiContainer';
import styles from './BespokeStatisticsList.module.css';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

type BespokeStatisticsListType = {
	isLoading: boolean,
	title: string,
	emptyText: string,
	list: BespokeStatistics[],
	max?: number
}

export default function BespokeStatisticsList({
	isLoading,
	title,
	list,
	max = 10
}: BespokeStatisticsListType) {

	const [t] = useTranslation('kpi');
	const tooltipID = uuidv4();
	const [needAfterIndicator, setNeedAfterIndicator] = useState<boolean>(false);
	const [needBeforeIndicator, setNeedBeforeIndicator] = useState<boolean>(false);

	const handleScroll = (target) => {
		const scrollEnd = target.offsetWidth + target.scrollLeft >= target.scrollWidth;
		setNeedAfterIndicator(!scrollEnd);
		setNeedBeforeIndicator(target.scrollLeft > 0);
	};

	const scrollRef = useCallback((node: HTMLDivElement) => {
		if (node !== null) {
			handleScroll(node);
		}
	}, []);

	const bespokeStatsList = () => list.slice(0, max).map(bespokeStats => (
		<div key={bespokeStats.identifier} className={styles.item}>
			<div className={styles.item_col_img}>
				<img className={styles.item_img} src={bespokeStats.thumbnailUrl}></img>
			</div>
			<div className={styles.item_col_info}>
				<div className={styles.item_format}>{'Header bar'}</div>
				<div className={styles.item_name}>{bespokeStats.name}</div>
			</div>
			<div className={styles.item_col_nb}>
				<div
					className={styles.item_nb}
					data-tip={t('bespokeStatistics.nbCampaignsUsingThisTemplate')}
					data-for={tooltipID}>
					{bespokeStats.campaignsCount}
				</div>
			</div>
		</div>
	));

	const repeat = (jsx:React.ReactNode, nb:number) => {
		const result:React.ReactNode[] = [];
		for (let i = 0; i < nb; i++) {
			result.push(
				<Fragment key={i}>{jsx}</Fragment>
			);
		}
		return result;
	};

	if (!isLoading && list.length === 0) {
		return <></>;
	}

	return (
		<>
			<div className={styles.title}>
				{title}
				<IconTooltip
					icon="fas fa-info-circle"
					tooltip={t('bespokeStatistics.bespokeWithCampaignUsageListTooltip') + ' - ' + t('common.lastNDays', {days: 30})}
					size="s"
				/>
			</div>
			<div className={[styles.list, needAfterIndicator ? styles.list_has_after_shadow : '', needBeforeIndicator ? styles.list_has_before_shadow : ''].join(' ')}>
				{isLoading && 
					<div className={styles.track}>
						{repeat(
							<div className={styles.item_empty}>
								<Skeleton appearance="entity" />
							</div>
							, 4
						)}
					</div>
				}
				{!isLoading && list && list.length > 0 &&
					<div className={styles.track} ref={scrollRef} onScroll={(ev) => handleScroll(ev.target)}>
						{bespokeStatsList()}
						<ReactTooltip
							id={tooltipID}
							backgroundColor="black"
							effect="solid"
							place="bottom"
							globalEventOff="mouseup"
							delayShow={600}
						/>
					</div>
				}
			</div>
		</>
	);
}