import React from 'react';
import ReactTooltip from 'react-tooltip';
import {ITooltip} from './types';

function Tooltip({
	text,
	html,
	id,
	delayShow = 600,
	globalEventOff = 'mouseup'
}: ITooltip) {

	return (
		<>
			{text &&
				<ReactTooltip
					id={id}
					backgroundColor="black"
					effect="solid"
					place="bottom"
					globalEventOff={globalEventOff}
					delayShow={delayShow}
				/>
			}
			{html &&
				<ReactTooltip
					id={id}
					backgroundColor="black"
					effect="solid"
					place="bottom"
					globalEventOff={globalEventOff}
					delayShow={delayShow}
					getContent={function() {
						return html;
					}}
				/>
			}
		</>
	);
}

export {
	Tooltip
};
