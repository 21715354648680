import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import './chartTooltip.css';
const ChartComponent = ({ visitsAverage, addtocartAverage, emercheOrdinate, tagStudiedEl, xUnitLabel, yUnitLabel }) => {
	const chartRef = useRef(null);

	useEffect(() => {
		if (!tagStudiedEl) return;

		Highcharts.setOptions({
			chart: {
				type: 'bubble',
				plotBorderWidth: 1,
				zoomType: 'xy',
			},
			legend: { enabled: true },
			credits: { enabled: false },
			title: { text: ' ' },
			events: {
				load: function () {
					// @ts-ignore
					this.myTooltip = new Highcharts.Tooltip(this, this.options.tooltip);
				},
				render: function () {
					// @ts-ignore
					// eslint-disable-next-line @typescript-eslint/no-this-alias
					const chart = this;
					// @ts-ignore
					if (!chart.labelAdded) {
						// @ts-ignore
						chart.renderer.text('0', 20, 480)
							.css({ color: '#d9d7d7', fontSize: '14px' })
							.add();
						// @ts-ignore
						chart.labelAdded = true;
					}
				}
			},
			plotOptions: {
				series: {
					dataLabels: {
						color: '#000',
						useHTML: true,
						// @ts-ignore
						pointFormat: '<p>{point.tagName}</p>',
					}
				}
			},
			xAxis: {
				title: { text: xUnitLabel },
				labels: { format: '{value}' }
			},
			yAxis: {
				title: { text: yUnitLabel },
				labels: { format: '{value}' }
			},
			tooltip: {
				useHTML: true,
				headerFormat: '<div class="tooltip_list_mapping_container">',
				pointFormat: `
        <div class="tooltip_list_mapping_content">
            <div class="tooltip_list_mapping_title">{point.tagName}</div>
            <div class="tooltip_list_mapping_row">
                <span class="tooltip_list_mapping_label">Conversions (Studied):</span>
                <span class="tooltip_list_mapping_value">{point.convReference}</span>
            </div>
            <div class="tooltip_list_mapping_row">
                <span class="tooltip_list_mapping_label">Conversions (Compared):</span>
                <span class="tooltip_list_mapping_value">{point.convComparison}%</span>
            </div>
            <div class="tooltip_list_mapping_row">
                <span class="tooltip_list_mapping_label">Visits (Studied):</span>
                <span class="tooltip_list_mapping_value">{point.visitReference}</span>
            </div>
            <div class="tooltip_list_mapping_row">
                <span class="tooltip_list_mapping_label">Visits (Compared):</span>
                <span class="tooltip_list_mapping_value">{point.visitComparison}%</span>
            </div>
            <div class="tooltip_list_mapping_row">
                <span class="tooltip_list_mapping_label">Add-to-cart (Studied):</span>
                <span class="tooltip_list_mapping_value">{point.addToCartRateReference}</span>
            </div>
            <div class="tooltip_list_mapping_row">
                <span class="tooltip_list_mapping_label">Add-to-cart (Compared):</span>
                <span class="tooltip_list_mapping_value">{point.addToCartRateComparison}%</span>
            </div>
        </div>
    `,
				footerFormat: '</div>',
				// @ts-ignore
				crosshairs: true,
				backgroundColor: '#38444A',
				// @ts-ignore
				color: '#FFF',
				style: { color: '#FFFFFF' },
			},

			colors: ['#006fff', '#ffa133']
		});

		const chartOptions = {
			tooltip: {
				useHTML: true,
				headerFormat: '<div class="graph_tooltip">',
				pointFormat:` <p><span>{point.tagType}</span>{point.tagName}</p> 
					<div class="tooltip_label">Visits</div>
					<div><span>studied</span>{point.visitReference}</div>
		<div class="tooltip_compared"><span>compared</span> {point.visitComparison}</div>
					<div class="tooltip_label">Conversions</div>
		<div>{point.convReference}</div>
					<div class="tooltip_compared">{point.convComparison}</div>
					<div class="tooltip_label">Add to cart </div>
					<div>{point.addToCartRateReference} </div>
					<div class="tooltip_compared">{point.addToCartRateComparison}</div>`,
				footerFormat: '</div>',
				crosshairs: true,
			},
			plotOptions: {
				bubble: {
					minSize: 10,
					maxSize: 100
				},
				series: {
					dataLabels: {
						enabled: true,
						format: '{point.tagName}',
					},
					cursor: 'pointer',
					point: {
						events: {
							click: function () {
								// Function to call on mapping point click
							},
							mouseOver: function () {
								// @ts-ignore
								this.series.chart.series[0].data[this.index].update({
									marker: { fillColor: '#ffa133' }
								});
							},
							mouseOut: function () {
								// @ts-ignore
								this.series.chart.series[0].data[this.index].update({
									marker: { fillColor: '#006fff' }
								});
							}
						}
					}
				}
			},
			series: [{
				name: 'Studied Period',
				data: tagStudiedEl.data,
				marker: {
					fillColor: '#006fff',
					lineWidth: 0
				}
			}, {
				name: 'Previous Period',
				data: tagStudiedEl.data.map(point => ({
					...point,
					x: point.convComparison,
					y: point.visitComparison,
					z: point.addToCartRateComparison
				})),
				marker: {
					fillColor: '#ffa133',
					lineWidth: 0
				}
			}]
		};

		// @ts-ignore
		chartRef.current = Highcharts.chart('chart_mapping', chartOptions);

		return () => {
			if (chartRef.current) {
				// @ts-ignore
				chartRef.current.destroy();
			}
		};
	}, [tagStudiedEl, visitsAverage, addtocartAverage, emercheOrdinate, xUnitLabel, yUnitLabel]);

	return (
		<div id="chart_mapping" style={{ height: '700px', minWidth: '310px' }}></div>
	);
};

export default ChartComponent;
