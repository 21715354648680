import React from 'react';
import { KPI } from '../../../../Components/KPI/KPI';
import { Article } from '../../../../Components/Article/Article';
import { getFormattedNumber, getFormattedPercent } from '../../../../Util/numberFormats';

const Metric = ({
	label,
	value,
	evolution,
	isFavorable,
	type,
	prevValue
}) => {

	const getEvolutionColor = (isFavorable) => {
		if (isFavorable == 0) return 'c_warning';
		if (isFavorable > 0) return 'c_success';
		return 'c_alert';
	};
	
	const formattedEvolution = () => {
		let ellips = '';
		let displayedEvolution = evolution;
		if (evolution > 100) {
			displayedEvolution = 100;
			ellips = '>\u00A0';
		} else if (evolution < -100) {
			displayedEvolution = -100;
			ellips = '<\u00A0';
		}

		return ellips + getFormattedPercent(displayedEvolution * 100, 2, true);
	};

	const formattedValue = (v, otherValue) => {
		if (type === 'Percentage') {
			return getFormattedPercent(v * 100, 2);
		}
		if (type === 'Amount') {
			return getFormattedNumber(v, 2, '€');
		}
		return getFormattedNumber(v, (Number.isInteger(v) && Number.isInteger(otherValue)) ? 0 : 2);
	};

	return (
		<Article hasMargin={false} style={{minWidth: 170}}>
			<KPI label={label}>
				<div className='mb_3'>
					<span className='s_22 fw_medium'>
						<div className={getEvolutionColor(isFavorable)}>
							{formattedEvolution()}
						</div>
					</span>
				</div>
				<div className='flex s_13'>
					<div className='flex_item_fix'>
						{formattedValue(prevValue, value)}
					</div>
					<i className='fas fa-arrow-right s_11 ml_3 mr_3 grey_4'></i>
					<div className='flex_item_fix'>
						{formattedValue(value, prevValue)}
					</div>
				</div>
			</KPI>
		</Article>
	);
};

export default Metric;
