import React from 'react';
import { ListItem } from '../../../../Components/List';
import IconBtn from '../../../../Components/IconBtn';

export default function DeleteItem({ onClickFn, children }) {

	return (
		<ListItem
			text={children}
			actions={
				<IconBtn
					icon="fas fa-trash"
					tooltip="Remove"
					onClick={onClickFn}
					hoverColor="alert"
				/>
			}
		/>
	);
}
