import React,{useEffect,useState} from 'react';
import TableRowItem from '../../../../Components/Table/TableRowItem.js';
import TableColItem from '../../../../Components/Table/TableColItem.js';
import { ProductThumb } from '../../../../Components/Product/ProductThumb';
import { ProductScore } from '../../../../Components/Product/ProductScore';
import { getFormattedNumber, getFormattedPercent } from '../../../../Util/numberFormats';

export function SegmentItem({
	product,
	tableRowStyle
}: any): JSX.Element {
	if (typeof product.Score !== 'number' ) {
		return <></>;
	}

	const [updatedScore, setUpdatedScore] = useState(Math.round(product.Score * 100));

	useEffect(() => {
		setUpdatedScore(Math.round(product.Score * 100));
	}, [product]);

	return (
		<TableRowItem key={product.AssociatedProduct.Id} styleObj={tableRowStyle}>
			<TableColItem>
				<div className='flex'>
					<div className="flex_item_fix">
						<ProductThumb
							width={60}
							height={'auto'}
							url={product.AssociatedProduct.Thumbnail}
							alt={product.AssociatedProduct.Name}
						/>
					</div>
					<div className="flex_item_full">
						<div className='s_13 fw_medium grey_2'>{product.AssociatedProduct.Id}</div>
						<div className="table_name">{product.AssociatedProduct.Name}</div>
					</div>
				</div>
			</TableColItem>
			<TableColItem>
				<ProductScore
					value={updatedScore || 0}
					displayValue={getFormattedNumber(updatedScore || 0)}
					tooltip='Score from 0 to 100'
				/>
			</TableColItem>
			<TableColItem align="right">
				{getFormattedNumber(product.NumberOfVisits)}
			</TableColItem>
			<TableColItem align="right">
				{getFormattedNumber(product.NumberOfCartAdded)}
			</TableColItem>
			<TableColItem align="right">
				{getFormattedNumber(product.NumberOfAbandonments)}
			</TableColItem>
			<TableColItem align="right">
				{getFormattedNumber(product.NumberOfObjectives)}
			</TableColItem>
			<TableColItem align="right">
				{getFormattedPercent((product.AddToCartRate || 0) * 100, 2)}
			</TableColItem>
			<TableColItem align="right">
				{getFormattedPercent((product.AbandonmentRate || 0) * 100, 2)}
			</TableColItem>
		</TableRowItem>
	);
}
