import React, { createContext, useContext, useState, useEffect } from 'react';
import { crudEmerchInsights } from '../api/CrudWorkflow';
import { ItemProcess, ProcessItem } from './typesWorkflow';
import { useCallbackApiPolling } from '../../../../Hooks/useApiPolling';

type MyContextType = {
	isLoading: boolean;
	handleItemClick: (el: string) => void;
	selectedItem: null | string;
	workflowProcessItems: ItemProcess[];
	sendAWorkflowRequest: (arrayElem: ProcessItem[]) => void;
};

const WorkflowContext = createContext<MyContextType | undefined>(undefined);

function useWorkFlowContext() {
	const context = useContext(WorkflowContext);
	if (!context) {
		throw new Error('useMyContext must be used within a MyContextProvider');
	}
	return context;
}

export interface ProviderProps {
	$http: any;
	children?: React.ReactNode;
	$rootScope: any;
	$routeParams: any;
	$timeout: any;
	$location: any;
	AuthServices: any;
	tabSelected: boolean;
}
const WorkflowProvider: React.FC<ProviderProps> = ({
	children,
	$http,
	$rootScope, $routeParams,
	tabSelected
}) => {
	const { loadWorkflowState, accountWorkflowRequest } = crudEmerchInsights($http, $rootScope, $routeParams);
	const [isLoading, setIsLoading] = useState(true);
	const [initialized, setInitialized] = useState(false);

	const [selectedItem, setSelectedItem] = useState('ProductFeed');

	const [workflowProcessItems, setWorkflowProcessItems] = useState<ItemProcess[]>([]);

	const handleItemClick = (key) => {
		setSelectedItem(key);
	};
	const contextValue: MyContextType = {
		isLoading,
		selectedItem,
		handleItemClick,
		workflowProcessItems,
		sendAWorkflowRequest
	};

	function loadWorkflow() {
		const controller = new AbortController();
		const signal = controller.signal;
		setIsLoading(true);
		loadWorkflowState((items: ItemProcess[]) => {
			setWorkflowProcessItems(items);
			setIsLoading(false);
			setInitialized(true);
		}, _error => {
			setWorkflowProcessItems([]);
			setIsLoading(false);
		}, signal);
	}

	function sendAWorkflowRequest(requests: ProcessItem[]) {
		const controller = new AbortController();
		const signal = controller.signal;
		accountWorkflowRequest(requests,
			() => {
				loadWorkflow();
			}, () => {
				undefined;
			}, signal);
	}
	useEffect(() => {
		if (tabSelected) {
			loadWorkflow();
		}
	}, [tabSelected]);

	const pollingData = useCallbackApiPolling<ItemProcess[]>((callbackSuccess, callbackError) => {
		if (!initialized) return;
		const controller = new AbortController();
		const signal = controller.signal;
		loadWorkflowState(callbackSuccess, callbackError, signal);
	}, null, null, 10000);

	useEffect(() => {
		setWorkflowProcessItems(pollingData || []);
	}, [pollingData]);

	return (
		<WorkflowContext.Provider value={contextValue}>
			{children}
		</WorkflowContext.Provider>
	);
};
export default WorkflowProvider;
export { useWorkFlowContext };