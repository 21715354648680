import React from 'react';
import Panel from '../../../../Components/Panel';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalBody from '../../../../Components/ModalBody';
import Btn from '../../../../Components/Btn';
import './FilterProductInsinghtsPanel.css';
import { SelectMultiples } from './SelectMultiples';
import {Spinner} from '../../../../Components/Spinner/Spinner';

export const FilterProductInsightsPanel = ({
	panelFormIsOpen,
	handleOpenClosePanelForm,
	handleChangeFormData,
	filterHierarchies,
	selectedValues,
	setSelectedValues
}) => {
	const handleSelectChange = (dimension, selectedOptions) => {

		if (!selectedOptions || selectedOptions.length === 0) {
			// Si selectedOptions est null ou vide, on supprime la clé de l'objet
			setSelectedValues((prevSelectedValues) => {
				const { [dimension]: _, ...otherValues } = prevSelectedValues;
				return otherValues;
			});
			return;
		}

		const extractedObjects = selectedOptions.map(option => ({
			name: option.label,
			value: option.value,
			Type: dimension
		}));

		setSelectedValues((prevSelectedValues) => {
			const existingValues = prevSelectedValues[dimension] || [];
			const filteredValues = existingValues.filter(existing =>
				extractedObjects.some(extracted => extracted.value === existing.value)
			);

			const combinedValues = [...filteredValues, ...extractedObjects];

			const uniqueValues = Array.from(new Map(combinedValues.map(item =>
				[item.value, item])).values());

			return {
				...prevSelectedValues,
				[dimension]: uniqueValues
			};
		});
	};


	function handleSubmit() {
		const payLoadForHiercharchies = [];
		for (const payLoadForHiercharchiesKey in selectedValues) {
			const key = payLoadForHiercharchiesKey;
			const value = selectedValues[key];


			payLoadForHiercharchies.push({
				// @ts-ignore
				key,
				// @ts-ignore
				value
			});
		}

		handleChangeFormData(payLoadForHiercharchies);
		handleOpenClosePanelForm(false);
	}

	return (
		<Panel
			width="560"
			isOpen={panelFormIsOpen}
			onClose={() => handleOpenClosePanelForm(false)}
			noClose={true}
		>
			<ModalHeader>
				<div className='flex'>
					<div className="flex_item_fix">
						<a className="panel_close panel_close_left" onClick={() => handleOpenClosePanelForm(false)}></a>
					</div>
					<div className='flex_item_full'>
						Filter by category
					</div>
					<div className='flex_item_fix ml_30'>
						<Btn
							message="Done"
							color="primary"
							onClick={handleSubmit}
						/>
					</div>
				</div>
			</ModalHeader>
			<ModalBody color='grey' position='relative'>
				{filterHierarchies.length === 0 && <Spinner display="overlay" />}
				{filterHierarchies.length > 0 && filterHierarchies?.map((item, index) => {
					const list = item?.categories?.map(cat => {
						return {
							label: cat.name,
							value: cat.value,
							type: item.name
						};
					});

					const listFilter = selectedValues.hasOwnProperty(item.name) ? selectedValues[item.name] : [];
					const initialSelected = listFilter.map(option =>
						list.find(el => el.label === option.name)
					);

					return (
						<div key={item.name || index}>
							<SelectMultiples
								initialSelected={initialSelected}
								list={list}
								label={item.name}
								onChange={(selectedOptions) => handleSelectChange(item.name, selectedOptions)}
							/>
						</div>
					);
				})}
			</ModalBody>
		</Panel>
	);
};

