import React from 'react';
import { react2angular } from 'react2angular';
import '../ReportingCampaign/ReportingCampaignGlobal.css';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import NavReporting from './NavReporting';
import ReportingCampaign from './ReportingCampaign';

export default function ReportingCampaignContainer({ $location, AuthServices, $http, $routeParams, $rootScope }) {
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;
	const isAdmin = impersonatingServices.isAnAdmin();

	const navReporting = () => <NavReporting 
		isAdmin={isAdmin}
		$routeParams={$routeParams}
		accountId={accountId}
	/>;

	return <ReportingCampaign
		$location={$location}
		AuthServices={AuthServices}
		$http={$http}
		$routeParams={$routeParams}
		$rootScope={$rootScope}
		initialCampaignId={$location && $location.$$search.id}
		loadCampaignFunction={service => (a, c, cc) => service.getActualCampaignsForAccount(a, c, cc)}
		initialDimension={$location.$$search.dimension}
		navReporting={navReporting}
		getEditorFunction={service => (campaignId, callbackSuccess, callbackError) => service.getCampaignEditor(accountId, campaignId, callbackSuccess, callbackError)}
		shouldDisplayEditorLink={true}
		shouldDisplayStatus={true}
		shouldDisplayDates={true}
	></ReportingCampaign>;
}



angular.module('beyableSaasApp.ReportingCampaign', [])
	.component('reportingcampaign', react2angular(ReportingCampaignContainer, [], ['$http', '$rootScope', '$routeParams', '$location', 'AuthServices']));
