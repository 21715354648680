import React, { useState, useEffect, Fragment } from 'react';
import formatMoment from '../../../../Constants/FormatMoment';
import classes from './CampaignList.module.css';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
import { onSiteContext } from '../context';
import { useTranslation } from 'react-i18next';
import Link from '../../../../Components/Link';
import Picto from '../../../../Components/Picto';

import { iconNotificationMessage } from '../../../../Components/CustomIcon/CustomIcon';
import { color } from 'highcharts';

export default function CampaignInformations({
	campaign,
	devices,
	isHovered
}) {

	if (!campaign) {
		return (<></>);
	}
	const [t, i18n] = useTranslation('common');
	const {
		devMode,
		accountId,
		permissionsAccount,
		$routeParams,
		isAdmin,
	} = onSiteContext();

	const getCampaignPicto = (campaign) => {
		if (campaign.editor && campaign.editor === 'notificationCenter/notification') {
			return {
				icon: iconNotificationMessage,
				color: 'orange'
			};
		}
		return null;
	};

	const { formatDateToLocal } = formatMoment;
	const isTestingMode = campaign.executionMode == 'TEST' ? true : false;
	const today = moment();
	const dateBeginOfOperation = formatDateToLocal(campaign.startDate);
	let dateEndOfOperation;
	if (campaign.endDate) {
		dateEndOfOperation = formatDateToLocal(campaign.endDate);
	}
	const campaignPicto = getCampaignPicto(campaign);
	const isStoppedBoolOperation = campaign.isStopped;
	const isTestModeOperationBool = isTestingMode;
	const [clipBoardContain, setClipBoardContain] = useState();
	const [canAccessToStatistics, setcanAccessToStatistics] = useState(false);
	const idTooltip = uuidv4();
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setClipBoardContain(value);
		setTimeout(() => {
			setClipBoardContain();
		}, 3000);
	};
	const canAccessStatistics = () => {
		const statisticsAcess = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'STATISTICSCAMPAIGNS_CAN_ACCESS');
		const can = isAdmin || statisticsAcess && statisticsAcess.Value == true;
		setcanAccessToStatistics(can);
	};
	useEffect(() => {
		canAccessStatistics();
	}, []);

	const reportingUrlBase = campaign.technicalPurpose?.startsWith('NOTIFICATION_CENTER') ? '/NotificationCenter/Reporting/' : '/CampaignReporting/Home/';
	let campaignUrl = reportingUrlBase + '?dimension=onSite&id=' + campaign.id;
	if ($routeParams) {
		if (isAdmin) {
			campaignUrl += '&ka=' + accountId + '&ku=' + $routeParams.ku;
		}
	}

	return (
		<div>
			<div className='flex_inline'>
				{campaignPicto &&
					<div className='flex_item_fix mr_15'>
						<Picto
							iconCustom={campaignPicto.icon}
							color={campaignPicto.color}
							size='l'
						/>
					</div>
				}
				<div className='flex_item_auto'>
					<ReactTooltip id={idTooltip} className="labelKpi" backgroundColor='black' effect='solid' />
					<div className="campaign_name">
						{canAccessToStatistics
							?
							<Link
								href={campaignUrl}
								className='table_name_link'
								color='black'
								tooltip={t('CampaignItem.viewReport')}
								message={campaign.name}
								controlledHover={isHovered}
								iconPosition='after'
								iconVisibility='controlledHover'
								icon="far fa-chart-bar"
							/>
							:
							<>{campaign.name}</>
						}
					</div>
					<div className="campaign_date">
						{dateBeginOfOperation.format('DD/MM/YYYY')}
						<i className="fas fa-arrow-right"></i>
						{dateEndOfOperation ?
							<>{dateEndOfOperation.format('DD/MM/YYYY')}</>
							:
							<span className="grey_2 fw_normal">({t('CampaignInformations.noEndDate')})</span>
						}
					</div>
					<div className="campaign_description">
						{campaign.description}
					</div>
					{devMode &&
						<div className={classes.campaignId}>
							<p className={clipBoardContain && clipBoardContain === campaign.id ?
								'admin_account_key copy_success' : 'admin_account_key'}>ID : <span
									onClick={(e) => copyToClipBoard(campaign.id)}
									className="account_key_copy">{campaign.id}</span><i className="far fa-clipboard"></i></p>
						</div>
					}
				</div>
			</div>
		</div>
	);
}
