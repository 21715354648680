import React from 'react';
import Confirm from '../../../../Components/Confirm';
import Header from '../../UI/Header';
import PannelOptionsList from '../../UI/PannelOptionsList';
import ProductList from '../../UI/ProductList/ProductList';
import { useRankingContext } from '../context/RankingContextProvider';
import MessageErrorNoConfiguration from '../../UI/MessageErrorNoConfiguration';
export default function RankinSettingsContainer() {
	const {
		deviceType,
		changeDeviceSelected,
		accountWithoutCategories,
		loadingSaving,
		checkIfCanQuitWithoutSave,
		modalConfirmIsOpen,
		returnToDashboard,
		handleCloseConfirm,
		mode,
		rankingObject,
		handleChangeRankingName,
		handeChangeFilterSorting,
		onRemoveGroupSorting,
		onSetGroupSorting,
		updapteAttribute,
		removeAttribute,
		onChangeAttributeOfSign,
		productList,
		changeCategorySelected,
		categorySelected,
		typeCategories,
		typeCategoriesSecondLevel,
		typeCategoriesSecondLevelSelected,
		changeSecondCategorySelected,
		promotedProducts,
		demotedProduct,
		changeOrderOfPromotedProduct,
		changeOrderOfDemotedProduct,
		loadingProductList,
		CreateRanking,
		PutRankingById,
		ListFilter,
		ruleName,
		handleChangeTenant,
		listTenantAccount,
		descriptionRule,
		handleChangeRankingDescription,
		replaceProducts,
		cancelActions,
		paginationOptions,
		handleChangePaginationOptions,
		currentPageProductList,
		setCurrentPageProductList,
		setNewPaginationNeed,
		idInClipBoard,
		copyToClipBoard,
		handleChangeTenantPreview,
		previewTenant,
		tenantsCatalog,
		errorNoConfiguration,
		handleChangeSortingFactors,
		accountId,
		setupAccountServices,
		arrayOfValuetags,
		getCheckIfIsAValidUrlFetch
	} = useRankingContext();

	return (
		<>
			<div className="collection_container page_full flex flex_v">
				{loadingSaving &&  !errorNoConfiguration
					&& <div className="mask">
						<span className="wheel"></span>
					</div>
				}

				<Confirm
					isOpen={modalConfirmIsOpen}
					onClose={(e) => handleCloseConfirm()}
					title="Close rule editor"
					text="Are you sure to close without saving?"
					cancelText="Stay"
					confirmText="Quit without saving"
					confirmCallback={(e) => returnToDashboard()}
				/>

				<Header
					title="Ranking rule"
					subTitle={ruleName}
					returnUrl='/ProductSettings/Ranking'
					valid={mode === 'edition' ? PutRankingById : CreateRanking}
					checkIfCanQuitWithoutSave={checkIfCanQuitWithoutSave}
					cancelActions={cancelActions}
				/>
				<div className="collection_body flex_item_full flex flex_align_stretch">
					{!errorNoConfiguration && <>
						<PannelOptionsList
							getCheckIfIsAValidUrlFetch={getCheckIfIsAValidUrlFetch}
							loadingProductList={loadingProductList}
							nameInput={ruleName}
							descriptionRule={descriptionRule}
							handleChangeTenant={handleChangeTenant}
							listTenantAccount={listTenantAccount}
							handleChangeRankingDescription={handleChangeRankingDescription}
							typeDimensions='ranking'
							data={rankingObject}
							setRuleName={handleChangeRankingName}
							handeChangeFilterSorting={handeChangeFilterSorting}
							onRemoveGroupSorting={onRemoveGroupSorting}
							onSetGroupSorting={onSetGroupSorting}
							ListFilter={ListFilter}
							updapteAttribute={updapteAttribute}
							removeAttribute={removeAttribute}
							onChangeAttributeOfSign={onChangeAttributeOfSign}
							promotedProducts={promotedProducts}
							demotedProduct={demotedProduct}
							replaceProducts={replaceProducts}
							tenantsCatalog={tenantsCatalog}
							handleChangeSortingFactors={handleChangeSortingFactors}
							accountId={accountId}
							setupAccountServices={setupAccountServices}
							arrayOfValuetags={arrayOfValuetags}
						/>

						<ProductList
							typeDimensions='ranking'
							products={productList}
							changeCategorySelected={changeCategorySelected}
							categorySelected={categorySelected}
							typeCategories={typeCategories}
							typeCategoriesSecondLevel={typeCategoriesSecondLevel}
							typeCategoriesSecondLevelSelected={typeCategoriesSecondLevelSelected}
							changeSecondCategorySelected={changeSecondCategorySelected}
							isLoading={loadingProductList}
							context={{
								rankingObject,
								promotedProducts,
								demotedProduct,
								changeOrderOfPromotedProduct,
								changeOrderOfDemotedProduct,
								productList,
								paginationOptions,
								handleChangePaginationOptions,
								currentPageProductList,
								setCurrentPageProductList,
								setNewPaginationNeed,
								idInClipBoard,
								copyToClipBoard,
								categorySelected,
								typeCategoriesSecondLevelSelected
							}}
							handleChangeTenantPreview={handleChangeTenantPreview}
							previewTenant={previewTenant}
							listTenantAccount={listTenantAccount}
							AccountWithoutCategories={accountWithoutCategories}
							needToHidePreviewTenants={
								(rankingObject.tenant !== null && tenantsCatalog.length > 1) ||
							listTenantAccount.length === 1}
							tenantsCatalog={tenantsCatalog}
							deviceType={deviceType}
							changeDeviceSelected={changeDeviceSelected}
						/></>}
					{errorNoConfiguration && 
				<MessageErrorNoConfiguration/>

					}
				</div>
			</div>
		</>
	);
}
