const initType = [
	{ key: 'Page', label: 'Page' }, 
	{ key: 'PageType', label: 'Page Type' }, 
	{ key: 'Tag1', label: 'Custom Dimension 1' }, 
	{ key: 'Tag2', label: 'Custom Dimension 2' }, 
	{ key: 'Tag3', label: 'Custom Dimension 3' }, 
	{ key: 'Tag4', label: 'Custom Dimension 4' }, 
	{ key: 'Tag5', label: 'Custom Dimension 5' }];
    
export const metricsTypes = [{
	key: 'UniqueViews',
	label: 'Unique Views'
},
{
	key: 'Views',
	label: 'Views'
},
{
	key: 'AverageTimeOnPage',
	label: 'Average time on page'
},
{
	key: 'BounceRate',
	label: 'Bounce rate'
},
// {
//     key: 'TrafficSource',
//     label: 'Traffic source'
// },
// {
//     key: 'Landings',
//     label: 'Landings'
// },
// {
//     key: 'Exits',
//     label: 'Exits'
// }
];

export const sourceTypes = [
	// 'All',
	'Direct',
	'Organic',
	'Referral',
	'Social',
	'Paid',
	'Email',
	'Display',
	'Other',
];
export default initType;

export const initialUtmFilter = {
	source: {
		name: '',
		label: ''
	},
	medium: {
		name: '',
		label: ''
	},
	campaign: {
		name: '',
		label: ''
	},
	content: {
		name: '',
		label: ''
	}
};
export const initialUtmFilterV2 = {
	source: [
		
	],
	medium: [
		
	],
	campaign: [
		
	],
	content: [
	
	]
};
export const cardFetchOptions = {
	'fromDate': '',
	'toDate': '',
	'metrics': [
		'NumberOfSessions'
	],
	'timeGranularity': 'None',
	'dimensions': [],
	'limit': 15,
	'offset': 0,
	'filters': [],
	'search': null
};
export const search = (fnSetter,table, word,selectList) => {
	if (word.length > 0) {
		fnSetter([]);
		let resultName = table.filter(
			line =>
				line.toUpperCase().match(`.*${word.toUpperCase()}.*`)
		);
		const res = resultName.map(i => {
			return {
				value: i,
				label: i
			};
		});
		fnSetter(res);
	}
	else {
		fnSetter(selectList);
	}
};
export const devices = [
// 	{
// 	value : 'all',
// 	label : 'All'
// },
	{
		value : 'Desktop',
		label : 'Desktop'
	},
	{
		value : 'Mobile',
		label : 'Mobile'
	},
	{
		value : 'Tablet',
		label : 'Tablet'
	}
];
export const FORMAT = 'YYYY/MM/DD';
export const today = moment();
export const monday = moment().startOf('isoWeek').format(FORMAT);
export const formatToday = moment().format(FORMAT);

export const dataTest = [
	{
		"lat": 56.983604492734244,
		"lon": 9.910753351619427,
		"name": "North Denmark",
		"iso": "DK",
		"NumberOfSessions": 8
	},
	{
		"lat": 43.8453,
		"lon": 18.35920000000001,
		"name": "Sarajevo Canton",
		"iso": "BA",
		"NumberOfSessions": 3
	},
	{
		"lat": 34.733,
		"lon": 36.72619999999999,
		"name": "Homs Governorate",
		"iso": "SY",
		"NumberOfSessions": 1
	},
	{
		"lat": 47.00420000000001,
		"lon": 28.857400000000005,
		"name": "Chișinău Municipality",
		"iso": "MD",
		"NumberOfSessions": 15
	},
	{
		"lat": 11.558300000000001,
		"lon": 104.91210000000001,
		"name": "Phnom Penh",
		"iso": "KH",
		"NumberOfSessions": 22
	},
	{
		"lat": -20.12585005774264,
		"lon": -66.37318477062489,
		"name": "Potosí Department",
		"iso": "BO",
		"NumberOfSessions": 2
	},
	{
		"lat": 33.503,
		"lon": 36.3032,
		"name": "Damascus Governorate",
		"iso": "SY",
		"NumberOfSessions": 1
	},
	{
		"lat": 15.496699999999999,
		"lon": -88.0338,
		"name": "Cortés Department",
		"iso": "HN",
		"NumberOfSessions": 12
	},
	{
		"lat": 41.261499999999984,
		"lon": 69.21770000000001,
		"name": "Tashkent",
		"iso": "UZ",
		"NumberOfSessions": 18
	},
	{
		"lat": 6.90543402292412,
		"lon": 79.88118912284492,
		"name": "Colombo District",
		"iso": "LK",
		"NumberOfSessions": 9
	},
	{
		"lat": 22.2848,
		"lon": 114.1703,
		"name": "Wan Chai",
		"iso": "HK",
		"NumberOfSessions": 2
	},
	{
		"lat": 9.026,
		"lon": 38.7439,
		"name": "Addis Ababa",
		"iso": "ET",
		"NumberOfSessions": 1
	},
	{
		"lat": 22.3361,
		"lon": 114.1698,
		"name": "Sham Shui Po",
		"iso": "HK",
		"NumberOfSessions": 1
	},
	{
		"lat": 32.069882790015804,
		"lon": 34.78842367305981,
		"name": "Tel Aviv",
		"iso": "IL",
		"NumberOfSessions": 42
	},
	{
		"lat": 32.9001,
		"lon": 13.187399999999998,
		"name": "Tripoli",
		"iso": "LY",
		"NumberOfSessions": 1
	},
	{
		"lat": -33.957481517382284,
		"lon": 18.446940093372778,
		"name": "Western Cape",
		"iso": "ZA",
		"NumberOfSessions": 24
	},
	{
		"lat": 10.3275,
		"lon": -84.4089,
		"name": "Alajuela Province",
		"iso": "CR",
		"NumberOfSessions": 1
	},
	{
		"lat": 31.301285971675497,
		"lon": 34.768267613505245,
		"name": "Southern District",
		"iso": "IL",
		"NumberOfSessions": 20
	},
	{
		"lat": 13.1229,
		"lon": -59.59599999999999,
		"name": "Saint Michael",
		"iso": "BB",
		"NumberOfSessions": 2
	},
	{
		"lat": 0.3809999999999999,
		"lon": 9.448699999999997,
		"name": "Estuaire",
		"iso": "GA",
		"NumberOfSessions": 2
	},
	{
		"lat": 64.13175556411359,
		"lon": -21.8844772973846,
		"name": "Reykjavíkurborg",
		"iso": "IS",
		"NumberOfSessions": 12
	},
	{
		"lat": 65.6826,
		"lon": -18.079700000000006,
		"name": "Akureyrarkaupstaður",
		"iso": "IS",
		"NumberOfSessions": 1
	},
	{
		"lat": 26.446753108154287,
		"lon": 50.101630276731775,
		"name": "Eastern Province",
		"iso": "SA",
		"NumberOfSessions": 10
	},
	{
		"lat": 34.741,
		"lon": 10.7648,
		"name": "Sfax Governorate",
		"iso": "TN",
		"NumberOfSessions": 1
	},
	{
		"lat": 18.474015806739366,
		"lon": -69.90550110982778,
		"name": "Santo Domingo Province",
		"iso": "DO",
		"NumberOfSessions": 3
	},
	{
		"lat": 25.292499999999993,
		"lon": 51.53210000000001,
		"name": "Baladīyat ad Dawḩah",
		"iso": "QA",
		"NumberOfSessions": 5
	},
	{
		"lat": 19.444824471312376,
		"lon": -70.6838989198638,
		"name": "Santiago Province",
		"iso": "DO",
		"NumberOfSessions": 5
	},
	{
		"lat": 36.1828,
		"lon": 44.01049999999999,
		"name": "Erbil",
		"iso": "IQ",
		"NumberOfSessions": 4
	},
	{
		"lat": 7.220099999999997,
		"lon": 80.1939,
		"name": "Kegalle District",
		"iso": "LK",
		"NumberOfSessions": 1
	},
	{
		"lat": 29.610599999999998,
		"lon": 52.52449999999999,
		"name": "Fars",
		"iso": "IR",
		"NumberOfSessions": 1
	},
	{
		"lat": 11.592200000000002,
		"lon": 43.1405,
		"name": "Djibouti",
		"iso": "DJ",
		"NumberOfSessions": 2
	},
	{
		"lat": 15.648300000000003,
		"lon": 32.5245,
		"name": "Khartoum",
		"iso": "SD",
		"NumberOfSessions": 1
	},
	{
		"lat": 22.284900000000007,
		"lon": 114.164,
		"name": "Central and Western District",
		"iso": "HK",
		"NumberOfSessions": 1
	},
	{
		"lat": 32.01734864875597,
		"lon": 34.83134481723111,
		"name": "Central District",
		"iso": "IL",
		"NumberOfSessions": 12
	},
	{
		"lat": 33.3364,
		"lon": 44.4004,
		"name": "Baghdad",
		"iso": "IQ",
		"NumberOfSessions": 9
	},
	{
		"lat": 18.466772748488772,
		"lon": -69.8956090757228,
		"name": "Nacional",
		"iso": "DO",
		"NumberOfSessions": 11
	},
	{
		"lat": -20.230000000000008,
		"lon": 57.4915,
		"name": "Moka District",
		"iso": "MU",
		"NumberOfSessions": 2
	},
	{
		"lat": 26.241,
		"lon": 50.577900000000014,
		"name": "Manama",
		"iso": "BH",
		"NumberOfSessions": 4
	},
	{
		"lat": -26.791570717810995,
		"lon": 26.88504496855307,
		"name": "North West",
		"iso": "ZA",
		"NumberOfSessions": 2
	},
	{
		"lat": 42.4241,
		"lon": 18.7674,
		"name": "Kotor",
		"iso": "ME",
		"NumberOfSessions": 2
	},
	{
		"lat": 24.81299999999999,
		"lon": 89.3152,
		"name": "Bogra",
		"iso": "BD",
		"NumberOfSessions": 1
	},
	{
		"lat": -4.616,
		"lon": 55.4461,
		"name": "La Rivière Anglaise",
		"iso": "SC",
		"NumberOfSessions": 1
	},
	{
		"lat": 23.149200000000004,
		"lon": -82.395,
		"name": "Havana",
		"iso": "CU",
		"NumberOfSessions": 3
	},
	{
		"lat": 31.5019,
		"lon": 34.46660000000001,
		"name": "Gaza Governorate",
		"iso": "PS",
		"NumberOfSessions": 1
	},
	{
		"lat": 22.3157,
		"lon": 114.15900000000002,
		"name": "Yau Tsim Mong",
		"iso": "HK",
		"NumberOfSessions": 1
	},
	{
		"lat": 47.1322,
		"lon": 9.5115,
		"name": "Vaduz",
		"iso": "LI",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.682399999999994,
		"lon": 51.41580000000002,
		"name": "Tehran",
		"iso": "IR",
		"NumberOfSessions": 15
	},
	{
		"lat": 44.88002663855969,
		"lon": 17.9656225668839,
		"name": "Republika Srpska",
		"iso": "BA",
		"NumberOfSessions": 2
	},
	{
		"lat": 41.3438,
		"lon": 21.5561,
		"name": "Prilep",
		"iso": "MK",
		"NumberOfSessions": 1
	},
	{
		"lat": 14.661099999999992,
		"lon": -86.2208,
		"name": "Olancho Department",
		"iso": "HN",
		"NumberOfSessions": 1
	},
	{
		"lat": -34.89265510523375,
		"lon": -56.153722531710244,
		"name": "Montevideo Department",
		"iso": "UY",
		"NumberOfSessions": 14
	},
	{
		"lat": -19.5451,
		"lon": 169.2837,
		"name": "Tafea",
		"iso": "VU",
		"NumberOfSessions": 1
	},
	{
		"lat": 10.6698,
		"lon": -61.51420000000001,
		"name": "Port of Spain",
		"iso": "TT",
		"NumberOfSessions": 5
	},
	{
		"lat": 47.9094,
		"lon": 106.8819,
		"name": "Ulaanbaatar Hot",
		"iso": "MN",
		"NumberOfSessions": 6
	},
	{
		"lat": -18.1301,
		"lon": 178.4098,
		"name": "Rewa Province",
		"iso": "FJ",
		"NumberOfSessions": 2
	},
	{
		"lat": 9.910200641651073,
		"lon": -84.062459964096,
		"name": "Provincia de San José",
		"iso": "CR",
		"NumberOfSessions": 28
	},
	{
		"lat": 9.9083,
		"lon": -83.9909,
		"name": "Cartago Province",
		"iso": "CR",
		"NumberOfSessions": 1
	},
	{
		"lat": 14.012800000000002,
		"lon": -60.999300000000005,
		"name": "Castries",
		"iso": "LC",
		"NumberOfSessions": 1
	},
	{
		"lat": 26.6264,
		"lon": 87.0802,
		"name": "Koshi",
		"iso": "NP",
		"NumberOfSessions": 1
	},
	{
		"lat": -14.834599999999998,
		"lon": -64.9008,
		"name": "Beni Department",
		"iso": "BO",
		"NumberOfSessions": 1
	},
	{
		"lat": -1.2841,
		"lon": 36.81549999999999,
		"name": "Nairobi County",
		"iso": "KE",
		"NumberOfSessions": 10
	},
	{
		"lat": 12.1346,
		"lon": -86.2469,
		"name": "Managua Department",
		"iso": "NI",
		"NumberOfSessions": 3
	},
	{
		"lat": 17.2501,
		"lon": -88.7676,
		"name": "Cayo District",
		"iso": "BZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 40.18169999999998,
		"lon": 44.509899999999995,
		"name": "Yerevan",
		"iso": "AM",
		"NumberOfSessions": 29
	},
	{
		"lat": -17.783400000000007,
		"lon": -63.18219999999999,
		"name": "Santa Cruz Department",
		"iso": "BO",
		"NumberOfSessions": 11
	},
	{
		"lat": 17.996199999999998,
		"lon": -76.8019,
		"name": "Kingston",
		"iso": "JM",
		"NumberOfSessions": 2
	},
	{
		"lat": 7.696200000000001,
		"lon": -5.0308,
		"name": "Vallée du Bandama District",
		"iso": "CI",
		"NumberOfSessions": 1
	},
	{
		"lat": 24.108999999999998,
		"lon": 90.0988,
		"name": "Tangail",
		"iso": "BD",
		"NumberOfSessions": 1
	},
	{
		"lat": 24.0242,
		"lon": 90.27,
		"name": "Gazipur",
		"iso": "BD",
		"NumberOfSessions": 1
	},
	{
		"lat": 13.5112,
		"lon": 2.117,
		"name": "Niamey",
		"iso": "NE",
		"NumberOfSessions": 1
	},
	{
		"lat": -25.512500000000006,
		"lon": -54.6187,
		"name": "Departamento del Alto Paraná",
		"iso": "PY",
		"NumberOfSessions": 1
	},
	{
		"lat": 31.649571373091682,
		"lon": 73.96847045534871,
		"name": "Punjab",
		"iso": "PK",
		"NumberOfSessions": 14
	},
	{
		"lat": 37.9519,
		"lon": 58.3958,
		"name": "Ahal",
		"iso": "TM",
		"NumberOfSessions": 1
	},
	{
		"lat": -26.059079926872325,
		"lon": 28.1272233687525,
		"name": "Gauteng",
		"iso": "ZA",
		"NumberOfSessions": 45
	},
	{
		"lat": 27.694442458200896,
		"lon": 85.34052212485099,
		"name": "Bagmati Province",
		"iso": "NP",
		"NumberOfSessions": 10
	},
	{
		"lat": 6.447400000000003,
		"lon": 3.3903000000000016,
		"name": "Lagos",
		"iso": "NG",
		"NumberOfSessions": 3
	},
	{
		"lat": -6.050000000000002,
		"lon": 21.9041,
		"name": "Kasai-Central",
		"iso": "CD",
		"NumberOfSessions": 1
	},
	{
		"lat": 10.2008,
		"lon": -61.403999999999996,
		"name": "Penal/Debe",
		"iso": "TT",
		"NumberOfSessions": 1
	},
	{
		"lat": 25.440999999999992,
		"lon": 55.532,
		"name": "Ajman",
		"iso": "AE",
		"NumberOfSessions": 1
	},
	{
		"lat": 36.341,
		"lon": 43.1155,
		"name": "Nineveh",
		"iso": "IQ",
		"NumberOfSessions": 1
	},
	{
		"lat": 55.70174655908385,
		"lon": 12.518495009800581,
		"name": "Capital Region",
		"iso": "DK",
		"NumberOfSessions": 76
	},
	{
		"lat": 42.869599999999984,
		"lon": 74.5932,
		"name": "Gorod Bishkek",
		"iso": "KG",
		"NumberOfSessions": 13
	},
	{
		"lat": 23.597869042109597,
		"lon": 58.355258222146745,
		"name": "Muscat",
		"iso": "OM",
		"NumberOfSessions": 9
	},
	{
		"lat": 25.0565,
		"lon": -77.3524,
		"name": "New Providence District",
		"iso": "BS",
		"NumberOfSessions": 3
	},
	{
		"lat": 16.796400000000002,
		"lon": 96.1667,
		"name": "Yangon",
		"iso": "MM",
		"NumberOfSessions": 3
	},
	{
		"lat": 18.469,
		"lon": -77.9295,
		"name": "Saint James Parish",
		"iso": "JM",
		"NumberOfSessions": 1
	},
	{
		"lat": -4.256799999999998,
		"lon": 15.2872,
		"name": "Brazzaville",
		"iso": "CG",
		"NumberOfSessions": 1
	},
	{
		"lat": 64.1634,
		"lon": -21.703000000000003,
		"name": "Mosfellsbaer",
		"iso": "IS",
		"NumberOfSessions": 2
	},
	{
		"lat": 24.686900000000023,
		"lon": 46.7224,
		"name": "Riyadh Region",
		"iso": "SA",
		"NumberOfSessions": 30
	},
	{
		"lat": 42.3924,
		"lon": 18.924199999999995,
		"name": "Cetinje",
		"iso": "ME",
		"NumberOfSessions": 1
	},
	{
		"lat": -17.38169999999999,
		"lon": -66.138,
		"name": "Departamento de Cochabamba",
		"iso": "BO",
		"NumberOfSessions": 5
	},
	{
		"lat": -34.3776,
		"lon": -55.23810000000001,
		"name": "Lavalleja",
		"iso": "UY",
		"NumberOfSessions": 1
	},
	{
		"lat": 18.6177,
		"lon": -68.704,
		"name": "La Altagracia Province",
		"iso": "DO",
		"NumberOfSessions": 2
	},
	{
		"lat": 32.75661395123266,
		"lon": 35.250448546187094,
		"name": "Northern District",
		"iso": "IL",
		"NumberOfSessions": 3
	},
	{
		"lat": 29.364500000000007,
		"lon": 47.988900000000015,
		"name": "Al Asimah",
		"iso": "KW",
		"NumberOfSessions": 13
	},
	{
		"lat": 22.326200188969135,
		"lon": 114.19204944298224,
		"name": "Kowloon City",
		"iso": "HK",
		"NumberOfSessions": 2
	},
	{
		"lat": -20.13629999999999,
		"lon": 57.6204,
		"name": "Pamplemousses District",
		"iso": "MU",
		"NumberOfSessions": 1
	},
	{
		"lat": 13.962000000000003,
		"lon": 44.1902,
		"name": "Ibb Governorate",
		"iso": "YE",
		"NumberOfSessions": 1
	},
	{
		"lat": 8.994800000000001,
		"lon": -79.523,
		"name": "Provincia de Panamá",
		"iso": "PA",
		"NumberOfSessions": 13
	},
	{
		"lat": 12.049200000000004,
		"lon": -61.7516,
		"name": "Saint George Parish",
		"iso": "GD",
		"NumberOfSessions": 1
	},
	{
		"lat": 27.189000000000007,
		"lon": 56.28360000000001,
		"name": "Hormozgan",
		"iso": "IR",
		"NumberOfSessions": 1
	},
	{
		"lat": 36.8232,
		"lon": 10.1701,
		"name": "Tunis Governorate",
		"iso": "TN",
		"NumberOfSessions": 1
	},
	{
		"lat": -16.5002,
		"lon": -68.1493,
		"name": "La Paz Department",
		"iso": "BO",
		"NumberOfSessions": 4
	},
	{
		"lat": 3.4536,
		"lon": 8.555799999999996,
		"name": "Bioko Sur",
		"iso": "GQ",
		"NumberOfSessions": 1
	},
	{
		"lat": 13.680599999999991,
		"lon": -89.1803,
		"name": "San Salvador Department",
		"iso": "SV",
		"NumberOfSessions": 15
	},
	{
		"lat": 55.480177048544874,
		"lon": 12.134973085259782,
		"name": "Zealand",
		"iso": "DK",
		"NumberOfSessions": 20
	},
	{
		"lat": 46.780000000000015,
		"lon": -56.16810000000001,
		"name": "Commune de Saint-Pierre",
		"iso": "PM",
		"NumberOfSessions": 1
	},
	{
		"lat": 7.314553987078685,
		"lon": 80.57805298483451,
		"name": "Central Province",
		"iso": "LK",
		"NumberOfSessions": 2
	},
	{
		"lat": 23.75533220105134,
		"lon": 90.39174178845055,
		"name": "Dhaka",
		"iso": "BD",
		"NumberOfSessions": 10
	},
	{
		"lat": 35.8251,
		"lon": 10.6446,
		"name": "Sousse Governorate",
		"iso": "TN",
		"NumberOfSessions": 1
	},
	{
		"lat": 24.97131749679423,
		"lon": 67.29727775959601,
		"name": "Sindh",
		"iso": "PK",
		"NumberOfSessions": 5
	},
	{
		"lat": 41.6473,
		"lon": 41.6258,
		"name": "Adjara",
		"iso": "GE",
		"NumberOfSessions": 5
	},
	{
		"lat": 25.073100000000018,
		"lon": 55.298,
		"name": "Dubai",
		"iso": "AE",
		"NumberOfSessions": 18
	},
	{
		"lat": 5.866700000000002,
		"lon": -55.1642,
		"name": "Paramaribo District",
		"iso": "SR",
		"NumberOfSessions": 2
	},
	{
		"lat": -10.2136,
		"lon": 31.1785,
		"name": "Northern Province",
		"iso": "ZM",
		"NumberOfSessions": 1
	},
	{
		"lat": 6.801,
		"lon": -58.1704,
		"name": "Demerara-Mahaica",
		"iso": "GY",
		"NumberOfSessions": 1
	},
	{
		"lat": 22.3539,
		"lon": 114.1342,
		"name": "Kwai Tsing",
		"iso": "HK",
		"NumberOfSessions": 2
	},
	{
		"lat": 25.7935,
		"lon": 55.944,
		"name": "Imarat Ra's al Khaymah",
		"iso": "AE",
		"NumberOfSessions": 1
	},
	{
		"lat": 24.466200000000004,
		"lon": 39.6168,
		"name": "Medina Region",
		"iso": "SA",
		"NumberOfSessions": 8
	},
	{
		"lat": 44.50668130790269,
		"lon": 18.300435357822074,
		"name": "Federation of Bosnia and Herzegovina",
		"iso": "BA",
		"NumberOfSessions": 5
	},
	{
		"lat": 42.5055,
		"lon": 1.5242999999999995,
		"name": "Andorra la Vella",
		"iso": "AD",
		"NumberOfSessions": 4
	},
	{
		"lat": 40.7859,
		"lon": 72.346,
		"name": "Andijan Region",
		"iso": "UZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 56.19697979153531,
		"lon": 9.970456289825885,
		"name": "Central Jutland",
		"iso": "DK",
		"NumberOfSessions": 25
	},
	{
		"lat": 35.8359,
		"lon": 51.00250000000001,
		"name": "Alborz Province",
		"iso": "IR",
		"NumberOfSessions": 3
	},
	{
		"lat": 55.26413083799406,
		"lon": 9.249720412956819,
		"name": "South Denmark",
		"iso": "DK",
		"NumberOfSessions": 19
	},
	{
		"lat": 6.1316000000000015,
		"lon": 1.224,
		"name": "Maritime",
		"iso": "TG",
		"NumberOfSessions": 1
	},
	{
		"lat": -19.0459,
		"lon": -65.2769,
		"name": "Chuquisaca Department",
		"iso": "BO",
		"NumberOfSessions": 1
	},
	{
		"lat": 14.1538,
		"lon": -89.4839,
		"name": "Santa Ana Department",
		"iso": "SV",
		"NumberOfSessions": 3
	},
	{
		"lat": 41.695900000000016,
		"lon": 44.832000000000015,
		"name": "Tbilisi",
		"iso": "GE",
		"NumberOfSessions": 42
	},
	{
		"lat": 33.29360000000001,
		"lon": 36.0127,
		"name": "Rif-dimashq",
		"iso": "SY",
		"NumberOfSessions": 1
	},
	{
		"lat": 18.4207,
		"lon": -68.9608,
		"name": "La Romana",
		"iso": "DO",
		"NumberOfSessions": 1
	},
	{
		"lat": 10.511100000000004,
		"lon": -61.4237,
		"name": "Chaguanas",
		"iso": "TT",
		"NumberOfSessions": 3
	},
	{
		"lat": 14.085133453797715,
		"lon": -87.20293322370338,
		"name": "Francisco Morazán Department",
		"iso": "HN",
		"NumberOfSessions": 9
	},
	{
		"lat": 17.9641,
		"lon": 102.5987,
		"name": "Vientiane Prefecture",
		"iso": "LA",
		"NumberOfSessions": 4
	},
	{
		"lat": -25.37524185736579,
		"lon": -57.400533813998265,
		"name": "Central Department",
		"iso": "PY",
		"NumberOfSessions": 2
	},
	{
		"lat": 32.4633,
		"lon": 44.4179,
		"name": "Muhafazat Babil",
		"iso": "IQ",
		"NumberOfSessions": 2
	},
	{
		"lat": 14.8345,
		"lon": -91.5183,
		"name": "Quetzaltenango",
		"iso": "GT",
		"NumberOfSessions": 1
	},
	{
		"lat": 24.9328,
		"lon": 91.8739,
		"name": "Sylhet",
		"iso": "BD",
		"NumberOfSessions": 1
	},
	{
		"lat": 13.4954,
		"lon": -88.8646,
		"name": "La Paz Department",
		"iso": "SV",
		"NumberOfSessions": 1
	},
	{
		"lat": 4.1772,
		"lon": 73.5107,
		"name": "Male",
		"iso": "MV",
		"NumberOfSessions": 2
	},
	{
		"lat": -16.1529,
		"lon": 33.5878,
		"name": "Tete",
		"iso": "MZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 33.7233,
		"lon": 73.0435,
		"name": "Islamabad",
		"iso": "PK",
		"NumberOfSessions": 3
	},
	{
		"lat": -33.9672,
		"lon": 25.5785,
		"name": "Eastern Cape",
		"iso": "ZA",
		"NumberOfSessions": 2
	},
	{
		"lat": 17.5018,
		"lon": -88.1956,
		"name": "Belize District",
		"iso": "BZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 4.8499,
		"lon": 31.5812,
		"name": "Central Equatoria",
		"iso": "SS",
		"NumberOfSessions": 1
	},
	{
		"lat": -22.266500000000008,
		"lon": 166.4742,
		"name": "South Province",
		"iso": "NC",
		"NumberOfSessions": 1
	},
	{
		"lat": 5.3536,
		"lon": -4.0012,
		"name": "Abidjan Autonomous District",
		"iso": "CI",
		"NumberOfSessions": 4
	},
	{
		"lat": -20.1608,
		"lon": 57.49940000000001,
		"name": "Port Louis District",
		"iso": "MU",
		"NumberOfSessions": 2
	},
	{
		"lat": 34.69021253453532,
		"lon": 33.037699738316185,
		"name": "Limassol District",
		"iso": "CY",
		"NumberOfSessions": 16
	},
	{
		"lat": 36.3006,
		"lon": 59.5904,
		"name": "Razavi Khorasan",
		"iso": "IR",
		"NumberOfSessions": 2
	},
	{
		"lat": 5.548600000000002,
		"lon": -0.2012,
		"name": "Greater Accra Region",
		"iso": "GH",
		"NumberOfSessions": 4
	},
	{
		"lat": 10.007362590735283,
		"lon": -84.11316260847367,
		"name": "Heredia Province",
		"iso": "CR",
		"NumberOfSessions": 8
	},
	{
		"lat": -14.274799999999997,
		"lon": -170.6895,
		"name": "Eastern District",
		"iso": "AS",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.163799999999995,
		"lon": 33.36390000000001,
		"name": "Nicosia",
		"iso": "CY",
		"NumberOfSessions": 6
	},
	{
		"lat": 31.767400000000002,
		"lon": 35.2186,
		"name": "Jerusalem",
		"iso": "IL",
		"NumberOfSessions": 8
	},
	{
		"lat": 49.6113,
		"lon": 6.129399999999999,
		"name": "Luxembourg",
		"iso": "LU",
		"NumberOfSessions": 7
	},
	{
		"lat": 24.432645975596866,
		"lon": 54.519233045739455,
		"name": "Abu Dhabi",
		"iso": "AE",
		"NumberOfSessions": 12
	},
	{
		"lat": -29.880610271621528,
		"lon": 30.99271308683971,
		"name": "KwaZulu-Natal",
		"iso": "ZA",
		"NumberOfSessions": 6
	},
	{
		"lat": 25.3412,
		"lon": 55.4224,
		"name": "Sharjah",
		"iso": "AE",
		"NumberOfSessions": 3
	},
	{
		"lat": 42.0653,
		"lon": 19.51,
		"name": "Shkodër County",
		"iso": "AL",
		"NumberOfSessions": 1
	},
	{
		"lat": 32.81119754975974,
		"lon": 35.027114671759236,
		"name": "Haifa",
		"iso": "IL",
		"NumberOfSessions": 7
	},
	{
		"lat": 9.5612,
		"lon": -84.321,
		"name": "Puntarenas Province",
		"iso": "CR",
		"NumberOfSessions": 1
	},
	{
		"lat": 6.847699999999998,
		"lon": 80.2598,
		"name": "Ratnapura District",
		"iso": "LK",
		"NumberOfSessions": 1
	},
	{
		"lat": 6.719500000000002,
		"lon": 80.06440000000002,
		"name": "Kalutara District",
		"iso": "LK",
		"NumberOfSessions": 1
	},
	{
		"lat": -22.556200000000004,
		"lon": 17.0759,
		"name": "Khomas Region",
		"iso": "NA",
		"NumberOfSessions": 2
	},
	{
		"lat": 39.6588,
		"lon": 66.9615,
		"name": "Samarqand Region",
		"iso": "UZ",
		"NumberOfSessions": 1
	},
	{
		"lat": -25.4677,
		"lon": -57.0431,
		"name": "Cordillera Department",
		"iso": "PY",
		"NumberOfSessions": 1
	},
	{
		"lat": 18.2811,
		"lon": -70.3243,
		"name": "Provincia de Peravia",
		"iso": "DO",
		"NumberOfSessions": 1
	},
	{
		"lat": 66.0715,
		"lon": -23.1301,
		"name": "Ísafjarðarbær",
		"iso": "IS",
		"NumberOfSessions": 1
	},
	{
		"lat": 40.377100000000006,
		"lon": 49.88749999999999,
		"name": "Baku City",
		"iso": "AZ",
		"NumberOfSessions": 25
	},
	{
		"lat": 35.5641,
		"lon": 45.4326,
		"name": "Sulaymaniyah",
		"iso": "IQ",
		"NumberOfSessions": 4
	},
	{
		"lat": 31.9555,
		"lon": 35.94350000000001,
		"name": "Amman Governorate",
		"iso": "JO",
		"NumberOfSessions": 7
	},
	{
		"lat": -24.6437,
		"lon": 25.9112,
		"name": "Gaborone",
		"iso": "BW",
		"NumberOfSessions": 3
	},
	{
		"lat": 21.453579335543164,
		"lon": 39.385481708687045,
		"name": "Mecca Region",
		"iso": "SA",
		"NumberOfSessions": 35
	},
	{
		"lat": 14.640000001938734,
		"lon": -90.5138500142914,
		"name": "Guatemala",
		"iso": "GT",
		"NumberOfSessions": 20
	},
	{
		"lat": 36.8061,
		"lon": 10.0931,
		"name": "Manouba",
		"iso": "TN",
		"NumberOfSessions": 1
	},
	{
		"lat": -15.4183,
		"lon": 28.287000000000003,
		"name": "Lusaka Province",
		"iso": "ZM",
		"NumberOfSessions": 1
	},
	{
		"lat": 34.7493,
		"lon": 72.3529,
		"name": "Khyber Pakhtunkhwa",
		"iso": "PK",
		"NumberOfSessions": 1
	},
	{
		"lat": 42.44109999999999,
		"lon": 19.2632,
		"name": "Podgorica",
		"iso": "ME",
		"NumberOfSessions": 3
	},
	{
		"lat": 30.510100000000012,
		"lon": 47.7847,
		"name": "Basra",
		"iso": "IQ",
		"NumberOfSessions": 2
	},
	{
		"lat": 46.8411,
		"lon": 29.6351,
		"name": "Administrative-Territorial Units of the Left Bank of the Dniester",
		"iso": "MD",
		"NumberOfSessions": 1
	},
	{
		"lat": 33.89160000000001,
		"lon": 35.5024,
		"name": "Beyrouth",
		"iso": "LB",
		"NumberOfSessions": 1
	},
	{
		"lat": 36.856200000000015,
		"lon": 10.190600000000002,
		"name": "Ariana Governorate",
		"iso": "TN",
		"NumberOfSessions": 1
	},
	{
		"lat": 42.0791,
		"lon": 20.420199999999994,
		"name": "Kukës County",
		"iso": "AL",
		"NumberOfSessions": 1
	},
	{
		"lat": -28.5,
		"lon": -59.050000000000004,
		"name": "Corrientes",
		"iso": "AR",
		"NumberOfSessions": 1
	},
	{
		"lat": -33.339217526895425,
		"lon": -68.69087975263407,
		"name": "Mendoza",
		"iso": "AR",
		"NumberOfSessions": 4
	},
	{
		"lat": -27.2044316337823,
		"lon": -55.59560558369962,
		"name": "Misiones",
		"iso": "AR",
		"NumberOfSessions": 3
	},
	{
		"lat": -39.53032388942754,
		"lon": -70.27207993301906,
		"name": "Neuquen",
		"iso": "AR",
		"NumberOfSessions": 2
	},
	{
		"lat": -36.6192,
		"lon": -64.2797,
		"name": "La Pampa",
		"iso": "AR",
		"NumberOfSessions": 1
	},
	{
		"lat": -24.805600000000002,
		"lon": -65.3417,
		"name": "Salta",
		"iso": "AR",
		"NumberOfSessions": 2
	},
	{
		"lat": -41.130399999999995,
		"lon": -71.2936,
		"name": "Rio Negro",
		"iso": "AR",
		"NumberOfSessions": 2
	},
	{
		"lat": -26.84255025701768,
		"lon": -65.2322486670993,
		"name": "Tucuman",
		"iso": "AR",
		"NumberOfSessions": 2
	},
	{
		"lat": -28.4333,
		"lon": -65.75,
		"name": "Catamarca",
		"iso": "AR",
		"NumberOfSessions": 1
	},
	{
		"lat": -31.7279,
		"lon": -60.529300000000006,
		"name": "Entre Rios",
		"iso": "AR",
		"NumberOfSessions": 2
	},
	{
		"lat": -53.4993,
		"lon": -68.0451,
		"name": "Tierra del Fuego",
		"iso": "AR",
		"NumberOfSessions": 1
	},
	{
		"lat": -35.45736253740216,
		"lon": -59.108359565111236,
		"name": "Buenos Aires",
		"iso": "AR",
		"NumberOfSessions": 71
	},
	{
		"lat": -44.516696914320285,
		"lon": -66.86421174962416,
		"name": "Chubut",
		"iso": "AR",
		"NumberOfSessions": 2
	},
	{
		"lat": -34.6142,
		"lon": -58.3811,
		"name": "Buenos Aires F.D.",
		"iso": "AR",
		"NumberOfSessions": 8
	},
	{
		"lat": -31.437400000000004,
		"lon": -68.5612,
		"name": "San Juan",
		"iso": "AR",
		"NumberOfSessions": 2
	},
	{
		"lat": -24.1894,
		"lon": -65.2978,
		"name": "Jujuy",
		"iso": "AR",
		"NumberOfSessions": 2
	},
	{
		"lat": -31.84661984068604,
		"lon": -64.0768137665919,
		"name": "Cordoba",
		"iso": "AR",
		"NumberOfSessions": 9
	},
	{
		"lat": -32.9330052766467,
		"lon": -60.67475156008174,
		"name": "Santa Fe",
		"iso": "AR",
		"NumberOfSessions": 4
	},
	{
		"lat": 47.61329966547958,
		"lon": 16.54967189958007,
		"name": "Burgenland",
		"iso": "AT",
		"NumberOfSessions": 3
	},
	{
		"lat": 47.09195662358579,
		"lon": 15.493717492750685,
		"name": "Styria",
		"iso": "AT",
		"NumberOfSessions": 8
	},
	{
		"lat": 48.20581344915907,
		"lon": 16.363307533165393,
		"name": "Vienna",
		"iso": "AT",
		"NumberOfSessions": 193
	},
	{
		"lat": 47.28208210006632,
		"lon": 11.44514615371758,
		"name": "Tyrol",
		"iso": "AT",
		"NumberOfSessions": 8
	},
	{
		"lat": 47.60586671558131,
		"lon": 13.035039095527107,
		"name": "Salzburg",
		"iso": "AT",
		"NumberOfSessions": 5
	},
	{
		"lat": 48.23349609229963,
		"lon": 14.179587819744667,
		"name": "Upper Austria",
		"iso": "AT",
		"NumberOfSessions": 14
	},
	{
		"lat": 48.20588621636893,
		"lon": 15.77391630598984,
		"name": "Lower Austria",
		"iso": "AT",
		"NumberOfSessions": 13
	},
	{
		"lat": 46.68709271882437,
		"lon": 14.039512855915792,
		"name": "Carinthia",
		"iso": "AT",
		"NumberOfSessions": 3
	},
	{
		"lat": -42.25253285247556,
		"lon": 147.1087053753373,
		"name": "Tasmania",
		"iso": "AU",
		"NumberOfSessions": 15
	},
	{
		"lat": -34.910540636113936,
		"lon": 138.59743397813602,
		"name": "South Australia",
		"iso": "AU",
		"NumberOfSessions": 74
	},
	{
		"lat": -12.4768,
		"lon": 130.9756,
		"name": "Northern Territory",
		"iso": "AU",
		"NumberOfSessions": 1
	},
	{
		"lat": -37.82985787409958,
		"lon": 144.99389835264085,
		"name": "Victoria",
		"iso": "AU",
		"NumberOfSessions": 209
	},
	{
		"lat": -33.85702207290659,
		"lon": 151.09219970692916,
		"name": "New South Wales",
		"iso": "AU",
		"NumberOfSessions": 310
	},
	{
		"lat": -35.2371784764197,
		"lon": 149.1302854797333,
		"name": "Australian Capital Territory",
		"iso": "AU",
		"NumberOfSessions": 10
	},
	{
		"lat": -31.954943149205896,
		"lon": 115.8648943857057,
		"name": "Western Australia",
		"iso": "AU",
		"NumberOfSessions": 99
	},
	{
		"lat": -26.791831555003895,
		"lon": 152.47568185221576,
		"name": "Queensland",
		"iso": "AU",
		"NumberOfSessions": 232
	},
	{
		"lat": 50.893891896753665,
		"lon": 4.568279484381838,
		"name": "Flemish Brabant Province",
		"iso": "BE",
		"NumberOfSessions": 7
	},
	{
		"lat": 50.959705570660084,
		"lon": 5.3603424607778045,
		"name": "Limburg Province",
		"iso": "BE",
		"NumberOfSessions": 7
	},
	{
		"lat": 50.63025580076954,
		"lon": 5.560493563069526,
		"name": "Liège Province",
		"iso": "BE",
		"NumberOfSessions": 33
	},
	{
		"lat": 50.49996022097972,
		"lon": 4.744414073475061,
		"name": "Namur Province",
		"iso": "BE",
		"NumberOfSessions": 2
	},
	{
		"lat": 50.510115313223295,
		"lon": 4.109915119057485,
		"name": "Hainaut Province",
		"iso": "BE",
		"NumberOfSessions": 31
	},
	{
		"lat": 50.719285104324605,
		"lon": 4.645596667287566,
		"name": "Walloon Brabant Province",
		"iso": "BE",
		"NumberOfSessions": 2
	},
	{
		"lat": 49.67993880855986,
		"lon": 5.453499919359876,
		"name": "Luxembourg Province",
		"iso": "BE",
		"NumberOfSessions": 2
	},
	{
		"lat": 50.85563543148309,
		"lon": 4.356487062479024,
		"name": "Brussels Capital",
		"iso": "BE",
		"NumberOfSessions": 67
	},
	{
		"lat": 51.207957180229926,
		"lon": 4.539898893929999,
		"name": "Antwerp Province",
		"iso": "BE",
		"NumberOfSessions": 28
	},
	{
		"lat": 51.01090832892629,
		"lon": 3.8092148627350166,
		"name": "East Flanders Province",
		"iso": "BE",
		"NumberOfSessions": 19
	},
	{
		"lat": 51.086051073797044,
		"lon": 3.166904893080589,
		"name": "West Flanders Province",
		"iso": "BE",
		"NumberOfSessions": 10
	},
	{
		"lat": 43.8627,
		"lon": 25.964800000000004,
		"name": "Ruse",
		"iso": "BG",
		"NumberOfSessions": 4
	},
	{
		"lat": 43.783300000000004,
		"lon": 23.733300000000007,
		"name": "Vratsa",
		"iso": "BG",
		"NumberOfSessions": 1
	},
	{
		"lat": 42.4963,
		"lon": 27.464600000000004,
		"name": "Burgas",
		"iso": "BG",
		"NumberOfSessions": 5
	},
	{
		"lat": 43.0848,
		"lon": 25.660600000000002,
		"name": "Veliko Tarnovo",
		"iso": "BG",
		"NumberOfSessions": 2
	},
	{
		"lat": 42.695100000000004,
		"lon": 23.325000000000014,
		"name": "Sofia-Capital",
		"iso": "BG",
		"NumberOfSessions": 46
	},
	{
		"lat": 43.2002,
		"lon": 27.942500000000003,
		"name": "Varna",
		"iso": "BG",
		"NumberOfSessions": 12
	},
	{
		"lat": 42.1978,
		"lon": 25.336699999999997,
		"name": "Stara Zagora",
		"iso": "BG",
		"NumberOfSessions": 1
	},
	{
		"lat": 41.71001717626021,
		"lon": 23.15935356638338,
		"name": "Blagoevgrad",
		"iso": "BG",
		"NumberOfSessions": 2
	},
	{
		"lat": 42.6034,
		"lon": 23.025699999999993,
		"name": "Pernik",
		"iso": "BG",
		"NumberOfSessions": 1
	},
	{
		"lat": 42.117862159435006,
		"lon": 24.782373297600657,
		"name": "Plovdiv",
		"iso": "BG",
		"NumberOfSessions": 8
	},
	{
		"lat": 43.2612,
		"lon": 26.9086,
		"name": "Shumen",
		"iso": "BG",
		"NumberOfSessions": 1
	},
	{
		"lat": 43.5606,
		"lon": 27.8299,
		"name": "Dobrich",
		"iso": "BG",
		"NumberOfSessions": 1
	},
	{
		"lat": 43.4126,
		"lon": 24.6089,
		"name": "Pleven",
		"iso": "BG",
		"NumberOfSessions": 1
	},
	{
		"lat": -22.825731280649908,
		"lon": -43.17242683933351,
		"name": "Rio de Janeiro",
		"iso": "BR",
		"NumberOfSessions": 43
	},
	{
		"lat": -24.884021799064627,
		"lon": -50.23919527439397,
		"name": "Paraná",
		"iso": "BR",
		"NumberOfSessions": 28
	},
	{
		"lat": -29.756799830894195,
		"lon": -51.4496160430674,
		"name": "Rio Grande do Sul",
		"iso": "BR",
		"NumberOfSessions": 32
	},
	{
		"lat": -8.112534194522219,
		"lon": -35.24536491338084,
		"name": "Pernambuco",
		"iso": "BR",
		"NumberOfSessions": 8
	},
	{
		"lat": -21.033186055383656,
		"lon": -54.21120071752249,
		"name": "Mato Grosso do Sul",
		"iso": "BR",
		"NumberOfSessions": 6
	},
	{
		"lat": -23.16123961306268,
		"lon": -47.06767036373763,
		"name": "São Paulo",
		"iso": "BR",
		"NumberOfSessions": 121
	},
	{
		"lat": -27.201880805200584,
		"lon": -49.23677807422271,
		"name": "Santa Catarina",
		"iso": "BR",
		"NumberOfSessions": 28
	},
	{
		"lat": -14.585991085976277,
		"lon": -55.78552417507352,
		"name": "Mato Grosso",
		"iso": "BR",
		"NumberOfSessions": 5
	},
	{
		"lat": -16.612087180723258,
		"lon": -49.22776380916714,
		"name": "Goiás",
		"iso": "BR",
		"NumberOfSessions": 7
	},
	{
		"lat": -3.1031999999999993,
		"lon": -60.0288,
		"name": "Amazonas",
		"iso": "BR",
		"NumberOfSessions": 2
	},
	{
		"lat": -19.969173417836437,
		"lon": -44.338436708164785,
		"name": "Minas Gerais",
		"iso": "BR",
		"NumberOfSessions": 82
	},
	{
		"lat": -10.2127,
		"lon": -63.8205,
		"name": "Rondônia",
		"iso": "BR",
		"NumberOfSessions": 1
	},
	{
		"lat": -9.572232772695179,
		"lon": -48.30105963174594,
		"name": "Tocantins",
		"iso": "BR",
		"NumberOfSessions": 5
	},
	{
		"lat": -7.143890506784075,
		"lon": -35.904995915489046,
		"name": "Paraíba",
		"iso": "BR",
		"NumberOfSessions": 6
	},
	{
		"lat": -12.192045286627303,
		"lon": -39.64489753259667,
		"name": "Bahia",
		"iso": "BR",
		"NumberOfSessions": 10
	},
	{
		"lat": 2.823599999999999,
		"lon": -60.6745,
		"name": "Roraima",
		"iso": "BR",
		"NumberOfSessions": 1
	},
	{
		"lat": -19.989124683256787,
		"lon": -40.46033892371909,
		"name": "Espírito Santo",
		"iso": "BR",
		"NumberOfSessions": 9
	},
	{
		"lat": -5.868150075939567,
		"lon": -35.21425094662009,
		"name": "Rio Grande do Norte",
		"iso": "BR",
		"NumberOfSessions": 4
	},
	{
		"lat": -1.6434008678990386,
		"lon": -48.45824113818943,
		"name": "Pará",
		"iso": "BR",
		"NumberOfSessions": 10
	},
	{
		"lat": -9.879857767533093,
		"lon": -36.25624241430391,
		"name": "Alagoas",
		"iso": "BR",
		"NumberOfSessions": 3
	},
	{
		"lat": -3.3503376966249934,
		"lon": -44.90728199730271,
		"name": "Maranhão",
		"iso": "BR",
		"NumberOfSessions": 7
	},
	{
		"lat": -15.778709093649354,
		"lon": -47.93289999757893,
		"name": "Federal District",
		"iso": "BR",
		"NumberOfSessions": 11
	},
	{
		"lat": -3.729009201737747,
		"lon": -39.6145723465439,
		"name": "Ceará",
		"iso": "BR",
		"NumberOfSessions": 8
	},
	{
		"lat": 0.0331,
		"lon": -51.0634,
		"name": "Amapá",
		"iso": "BR",
		"NumberOfSessions": 1
	},
	{
		"lat": 53.9965,
		"lon": 28.002,
		"name": "Minsk",
		"iso": "BY",
		"NumberOfSessions": 1
	},
	{
		"lat": 55.26055317386028,
		"lon": 29.881741962050604,
		"name": "Vitebsk",
		"iso": "BY",
		"NumberOfSessions": 5
	},
	{
		"lat": 53.90070000000001,
		"lon": 27.570900000000005,
		"name": "Minsk City",
		"iso": "BY",
		"NumberOfSessions": 67
	},
	{
		"lat": 52.11457254251996,
		"lon": 24.886204818526245,
		"name": "Brest",
		"iso": "BY",
		"NumberOfSessions": 6
	},
	{
		"lat": 53.664528109506655,
		"lon": 29.966865317336023,
		"name": "Mogilev",
		"iso": "BY",
		"NumberOfSessions": 3
	},
	{
		"lat": 53.48959019248207,
		"lon": 24.33195550733935,
		"name": "Grodnenskaya",
		"iso": "BY",
		"NumberOfSessions": 6
	},
	{
		"lat": 52.4319,
		"lon": 30.973100000000002,
		"name": "Homyel’ Voblasc’",
		"iso": "BY",
		"NumberOfSessions": 3
	},
	{
		"lat": 51.55561997414328,
		"lon": -105.4988915070758,
		"name": "Saskatchewan",
		"iso": "CA",
		"NumberOfSessions": 37
	},
	{
		"lat": 48.16239155491461,
		"lon": -54.47479019745352,
		"name": "Newfoundland and Labrador",
		"iso": "CA",
		"NumberOfSessions": 7
	},
	{
		"lat": 43.917687547822815,
		"lon": -79.38936319085222,
		"name": "Ontario",
		"iso": "CA",
		"NumberOfSessions": 573
	},
	{
		"lat": 62.456399999999995,
		"lon": -114.3368,
		"name": "Northwest Territories",
		"iso": "CA",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.04216816334774,
		"lon": -63.02877464350524,
		"name": "Nova Scotia",
		"iso": "CA",
		"NumberOfSessions": 42
	},
	{
		"lat": 46.142938996093434,
		"lon": -65.614140894646,
		"name": "New Brunswick",
		"iso": "CA",
		"NumberOfSessions": 24
	},
	{
		"lat": 52.26413932995809,
		"lon": -113.833416017223,
		"name": "Alberta",
		"iso": "CA",
		"NumberOfSessions": 160
	},
	{
		"lat": 46.2761,
		"lon": -62.9985,
		"name": "Prince Edward Island",
		"iso": "CA",
		"NumberOfSessions": 1
	},
	{
		"lat": 49.45930673734502,
		"lon": -122.24507234117532,
		"name": "British Columbia",
		"iso": "CA",
		"NumberOfSessions": 196
	},
	{
		"lat": 49.917642594073854,
		"lon": -97.36222155046855,
		"name": "Manitoba",
		"iso": "CA",
		"NumberOfSessions": 37
	},
	{
		"lat": 60.718,
		"lon": -135.0475,
		"name": "Yukon",
		"iso": "CA",
		"NumberOfSessions": 6
	},
	{
		"lat": 46.279296682681995,
		"lon": -72.56698783135937,
		"name": "Quebec",
		"iso": "CA",
		"NumberOfSessions": 446
	},
	{
		"lat": 47.38824223186182,
		"lon": 8.532542787938707,
		"name": "Zurich",
		"iso": "CH",
		"NumberOfSessions": 52
	},
	{
		"lat": 47.321263227218516,
		"lon": 7.656673144696085,
		"name": "Solothurn",
		"iso": "CH",
		"NumberOfSessions": 5
	},
	{
		"lat": 47.50180000000001,
		"lon": 7.587100000000001,
		"name": "Basel-Landschaft",
		"iso": "CH",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.202378972966194,
		"lon": 6.94051722499183,
		"name": "Valais",
		"iso": "CH",
		"NumberOfSessions": 4
	},
	{
		"lat": 46.9838,
		"lon": 9.0545,
		"name": "Glarus",
		"iso": "CH",
		"NumberOfSessions": 1
	},
	{
		"lat": 47.5511007931338,
		"lon": 7.5840787449943,
		"name": "Basel-City",
		"iso": "CH",
		"NumberOfSessions": 4
	},
	{
		"lat": 46.19921746076833,
		"lon": 6.151981200845853,
		"name": "Geneva",
		"iso": "CH",
		"NumberOfSessions": 6
	},
	{
		"lat": 46.50467088349011,
		"lon": 6.672761329391522,
		"name": "Vaud",
		"iso": "CH",
		"NumberOfSessions": 27
	},
	{
		"lat": 47.3996658548159,
		"lon": 8.115145091285203,
		"name": "Aargau",
		"iso": "CH",
		"NumberOfSessions": 5
	},
	{
		"lat": 47.3883,
		"lon": 9.279199999999998,
		"name": "Appenzell Ausserrhoden",
		"iso": "CH",
		"NumberOfSessions": 1
	},
	{
		"lat": 47.44024844710287,
		"lon": 9.307633511513222,
		"name": "Saint Gallen",
		"iso": "CH",
		"NumberOfSessions": 15
	},
	{
		"lat": 47.52850485271778,
		"lon": 9.24572602479206,
		"name": "Thurgau",
		"iso": "CH",
		"NumberOfSessions": 3
	},
	{
		"lat": 46.9904,
		"lon": 6.9339,
		"name": "Neuchâtel",
		"iso": "CH",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.851000000000006,
		"lon": 9.538900000000003,
		"name": "Grisons",
		"iso": "CH",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.05046668163891,
		"lon": 8.951569678512909,
		"name": "Ticino",
		"iso": "CH",
		"NumberOfSessions": 3
	},
	{
		"lat": 46.752455829258125,
		"lon": 7.078967377586446,
		"name": "Fribourg",
		"iso": "CH",
		"NumberOfSessions": 16
	},
	{
		"lat": 47.7191,
		"lon": 8.624600000000001,
		"name": "Schaffhausen",
		"iso": "CH",
		"NumberOfSessions": 1
	},
	{
		"lat": 47.200037848208936,
		"lon": 8.459262702334247,
		"name": "Zug",
		"iso": "CH",
		"NumberOfSessions": 3
	},
	{
		"lat": 47.05615396732991,
		"lon": 7.521993195476804,
		"name": "Bern",
		"iso": "CH",
		"NumberOfSessions": 11
	},
	{
		"lat": 47.103374318619075,
		"lon": 8.207116567856726,
		"name": "Lucerne",
		"iso": "CH",
		"NumberOfSessions": 3
	},
	{
		"lat": -33.43651941260151,
		"lon": -70.6660009830678,
		"name": "Santiago Metropolitan",
		"iso": "CL",
		"NumberOfSessions": 79
	},
	{
		"lat": -35.64505319392803,
		"lon": -71.62927138174926,
		"name": "Maule Region",
		"iso": "CL",
		"NumberOfSessions": 2
	},
	{
		"lat": -34.1703,
		"lon": -70.74060000000001,
		"name": "O'Higgins Region",
		"iso": "CL",
		"NumberOfSessions": 2
	},
	{
		"lat": -20.2701,
		"lon": -70.11500000000001,
		"name": "Tarapacá",
		"iso": "CL",
		"NumberOfSessions": 1
	},
	{
		"lat": -41.14685497137473,
		"lon": -73.01678159064195,
		"name": "Los Lagos Region",
		"iso": "CL",
		"NumberOfSessions": 6
	},
	{
		"lat": -22.467600000000008,
		"lon": -68.9335,
		"name": "Antofagasta",
		"iso": "CL",
		"NumberOfSessions": 2
	},
	{
		"lat": -39.8119,
		"lon": -73.2493,
		"name": "Los Ríos Region",
		"iso": "CL",
		"NumberOfSessions": 3
	},
	{
		"lat": -33.0276078088575,
		"lon": -71.52635418013408,
		"name": "Región de Valparaíso",
		"iso": "CL",
		"NumberOfSessions": 4
	},
	{
		"lat": -30.0905311354102,
		"lon": -71.26453606330911,
		"name": "Coquimbo Region",
		"iso": "CL",
		"NumberOfSessions": 4
	},
	{
		"lat": -18.4787,
		"lon": -70.3027,
		"name": "Arica y Parinacota Region",
		"iso": "CL",
		"NumberOfSessions": 1
	},
	{
		"lat": -36.82460168897333,
		"lon": -73.05724422065734,
		"name": "Región del Biobío",
		"iso": "CL",
		"NumberOfSessions": 13
	},
	{
		"lat": -45.5646,
		"lon": -72.0778,
		"name": "Aysén",
		"iso": "CL",
		"NumberOfSessions": 1
	},
	{
		"lat": 24.479300000000006,
		"lon": 118.06729999999999,
		"name": "Fujian",
		"iso": "CN",
		"NumberOfSessions": 1
	},
	{
		"lat": 30.2994,
		"lon": 120.1612,
		"name": "Zhejiang",
		"iso": "CN",
		"NumberOfSessions": 1
	},
	{
		"lat": 34.7599,
		"lon": 113.6459,
		"name": "Henan",
		"iso": "CN",
		"NumberOfSessions": 1
	},
	{
		"lat": 34.328700000000005,
		"lon": 109.0337,
		"name": "Shaanxi",
		"iso": "CN",
		"NumberOfSessions": 1
	},
	{
		"lat": 39.911,
		"lon": 116.39499999999998,
		"name": "Beijing",
		"iso": "CN",
		"NumberOfSessions": 3
	},
	{
		"lat": 32.0589,
		"lon": 118.77380000000001,
		"name": "Jiangsu",
		"iso": "CN",
		"NumberOfSessions": 1
	},
	{
		"lat": 38.9384,
		"lon": 121.62239999999998,
		"name": "Liaoning",
		"iso": "CN",
		"NumberOfSessions": 1
	},
	{
		"lat": 10.953799999999996,
		"lon": -72.7893,
		"name": "La Guajira Department",
		"iso": "CO",
		"NumberOfSessions": 1
	},
	{
		"lat": 7.0679,
		"lon": -73.8536,
		"name": "Santander Department",
		"iso": "CO",
		"NumberOfSessions": 1
	},
	{
		"lat": 7.8946,
		"lon": -72.5018,
		"name": "Norte de Santander Department",
		"iso": "CO",
		"NumberOfSessions": 4
	},
	{
		"lat": 4.8093,
		"lon": -75.697,
		"name": "Risaralda Department",
		"iso": "CO",
		"NumberOfSessions": 2
	},
	{
		"lat": 10.46531578947446,
		"lon": -73.24979473684692,
		"name": "Cesar Department",
		"iso": "CO",
		"NumberOfSessions": 19
	},
	{
		"lat": 3.412900000000001,
		"lon": -76.51910000000001,
		"name": "Valle del Cauca Department",
		"iso": "CO",
		"NumberOfSessions": 5
	},
	{
		"lat": 11.0071,
		"lon": -74.8092,
		"name": "Atlántico",
		"iso": "CO",
		"NumberOfSessions": 2
	},
	{
		"lat": 2.5732,
		"lon": -72.64790000000002,
		"name": "Guaviare Department",
		"iso": "CO",
		"NumberOfSessions": 2
	},
	{
		"lat": 10.401299999999999,
		"lon": -75.5267,
		"name": "Departamento de Bolívar",
		"iso": "CO",
		"NumberOfSessions": 1
	},
	{
		"lat": 4.8588,
		"lon": -74.0553,
		"name": "Cundinamarca",
		"iso": "CO",
		"NumberOfSessions": 2
	},
	{
		"lat": 4.1409,
		"lon": -73.626,
		"name": "Meta Department",
		"iso": "CO",
		"NumberOfSessions": 1
	},
	{
		"lat": 11.041829035014679,
		"lon": -74.2819696525138,
		"name": "Magdalena Department",
		"iso": "CO",
		"NumberOfSessions": 4
	},
	{
		"lat": 4.6214287411624655,
		"lon": -74.0854833532776,
		"name": "Bogota D.C.",
		"iso": "CO",
		"NumberOfSessions": 49
	},
	{
		"lat": 6.246580024120724,
		"lon": -75.56540329548746,
		"name": "Antioquia",
		"iso": "CO",
		"NumberOfSessions": 31
	},
	{
		"lat": 2.9208,
		"lon": -75.283,
		"name": "Huila Department",
		"iso": "CO",
		"NumberOfSessions": 1
	},
	{
		"lat": 5.017,
		"lon": -72.7566,
		"name": "Casanare Department",
		"iso": "CO",
		"NumberOfSessions": 1
	},
	{
		"lat": 49.43958674979293,
		"lon": 18.03953195045857,
		"name": "Vsetín District",
		"iso": "CZ",
		"NumberOfSessions": 4
	},
	{
		"lat": 50.8032,
		"lon": 15.0007,
		"name": "Liberec District",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 49.74168791710001,
		"lon": 13.38432129546205,
		"name": "Okres Plzeň-město",
		"iso": "CZ",
		"NumberOfSessions": 10
	},
	{
		"lat": 50.1192,
		"lon": 16.2899,
		"name": "Rychnov nad Kněžnou District",
		"iso": "CZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 49.6809,
		"lon": 18.3456,
		"name": "Okres Frýdek-Místek",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 50.0788,
		"lon": 12.353199999999998,
		"name": "Cheb District",
		"iso": "CZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 50.5048,
		"lon": 13.646599999999996,
		"name": "Most District",
		"iso": "CZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 49.9019,
		"lon": 16.4504,
		"name": "Ústí nad Orlicí District",
		"iso": "CZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 49.3503,
		"lon": 16.0186,
		"name": "Žďár nad Sázavou District",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 50.6347,
		"lon": 13.8242,
		"name": "Teplice District",
		"iso": "CZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 50.04085242316244,
		"lon": 14.701221115644245,
		"name": "Okres Praha-východ",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 50.1436,
		"lon": 14.101,
		"name": "Kladno District",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 50.08819668912079,
		"lon": 14.432491454349398,
		"name": "Prague",
		"iso": "CZ",
		"NumberOfSessions": 124
	},
	{
		"lat": 49.860179734493784,
		"lon": 17.837500533221668,
		"name": "Opava District",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 49.088775335315155,
		"lon": 17.458705201433766,
		"name": "Uherské Hradiště District",
		"iso": "CZ",
		"NumberOfSessions": 4
	},
	{
		"lat": 49.634662667398366,
		"lon": 13.449718249849687,
		"name": "Okres Plzeň-jih",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 50.20277443060005,
		"lon": 14.961584696119587,
		"name": "Nymburk District",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 49.433600000000006,
		"lon": 12.713300000000004,
		"name": "Domažlice District",
		"iso": "CZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 50.20487054877331,
		"lon": 15.805130542115599,
		"name": "Hradec Králové District",
		"iso": "CZ",
		"NumberOfSessions": 3
	},
	{
		"lat": 49.91282687219381,
		"lon": 14.017116437987092,
		"name": "Beroun District",
		"iso": "CZ",
		"NumberOfSessions": 3
	},
	{
		"lat": 48.88060312073949,
		"lon": 17.17596977330908,
		"name": "Hodonín District",
		"iso": "CZ",
		"NumberOfSessions": 4
	},
	{
		"lat": 49.97007055088533,
		"lon": 14.333417889894266,
		"name": "Okres Praha-západ",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 50.367,
		"lon": 15.627699999999994,
		"name": "Jičín District",
		"iso": "CZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 49.80291545306576,
		"lon": 18.244206378208276,
		"name": "Okres Ostrava-město",
		"iso": "CZ",
		"NumberOfSessions": 8
	},
	{
		"lat": 50.67315118597035,
		"lon": 14.048059169029102,
		"name": "Okres Ústí nad Labem",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 50.181,
		"lon": 12.637199999999996,
		"name": "Sokolov District",
		"iso": "CZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 49.364,
		"lon": 16.6599,
		"name": "Blansko District",
		"iso": "CZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 49.14810319709517,
		"lon": 16.038114362259495,
		"name": "Třebíč District",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 50.4144,
		"lon": 16.1682,
		"name": "Náchod District",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 49.48048387925905,
		"lon": 17.11963287921971,
		"name": "Prostějov District",
		"iso": "CZ",
		"NumberOfSessions": 6
	},
	{
		"lat": 49.1458,
		"lon": 16.866000000000003,
		"name": "Vyškov District",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 50.6873,
		"lon": 14.5429,
		"name": "Česká Lípa District",
		"iso": "CZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 49.177647331600795,
		"lon": 16.583119102652432,
		"name": "Město Brno",
		"iso": "CZ",
		"NumberOfSessions": 28
	},
	{
		"lat": 50.2578425188373,
		"lon": 14.38728253197877,
		"name": "Mělník District",
		"iso": "CZ",
		"NumberOfSessions": 3
	},
	{
		"lat": 49.78045001394076,
		"lon": 16.230596896114523,
		"name": "Svitavy District",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 50.343477218962576,
		"lon": 13.66615874639591,
		"name": "Louny District",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 50.032662365753936,
		"lon": 15.909183348838843,
		"name": "Pardubice District",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 49.7139,
		"lon": 17.901,
		"name": "Nový Jičín District",
		"iso": "CZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 49.718,
		"lon": 13.229900000000002,
		"name": "Okres Plzeň-sever",
		"iso": "CZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 48.975611111477114,
		"lon": 14.467355558710722,
		"name": "České Budějovice District",
		"iso": "CZ",
		"NumberOfSessions": 9
	},
	{
		"lat": 50.40230025411509,
		"lon": 14.94350560498484,
		"name": "Mladá Boleslav District",
		"iso": "CZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 49.82538667608557,
		"lon": 18.48263047407852,
		"name": "Okres Karviná",
		"iso": "CZ",
		"NumberOfSessions": 4
	},
	{
		"lat": 49.675742760894344,
		"lon": 17.28462209177807,
		"name": "Olomouc District",
		"iso": "CZ",
		"NumberOfSessions": 5
	},
	{
		"lat": 49.58610000000001,
		"lon": 17.70070000000001,
		"name": "Přerov District",
		"iso": "CZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 53.56761289694416,
		"lon": 10.001346970211598,
		"name": "Free and Hanseatic City of Hamburg",
		"iso": "DE",
		"NumberOfSessions": 176
	},
	{
		"lat": 48.71789902522592,
		"lon": 11.388404128563648,
		"name": "Bavaria",
		"iso": "DE",
		"NumberOfSessions": 476
	},
	{
		"lat": 50.21173429013631,
		"lon": 8.671961974386585,
		"name": "Hesse",
		"iso": "DE",
		"NumberOfSessions": 438
	},
	{
		"lat": 53.83168728332172,
		"lon": 12.299375690694228,
		"name": "Mecklenburg-Vorpommern",
		"iso": "DE",
		"NumberOfSessions": 25
	},
	{
		"lat": 49.788223193774535,
		"lon": 7.963029506124176,
		"name": "Rheinland-Pfalz",
		"iso": "DE",
		"NumberOfSessions": 103
	},
	{
		"lat": 48.725678216367655,
		"lon": 8.908943615546114,
		"name": "Baden-Wurttemberg",
		"iso": "DE",
		"NumberOfSessions": 397
	},
	{
		"lat": 52.52238894485088,
		"lon": 9.518822313151755,
		"name": "Lower Saxony",
		"iso": "DE",
		"NumberOfSessions": 262
	},
	{
		"lat": 49.328653246433745,
		"lon": 6.962164660079364,
		"name": "Saarland",
		"iso": "DE",
		"NumberOfSessions": 28
	},
	{
		"lat": 51.062245403456835,
		"lon": 12.945897960350868,
		"name": "Saxony",
		"iso": "DE",
		"NumberOfSessions": 201
	},
	{
		"lat": 52.51137979842011,
		"lon": 13.404985237869418,
		"name": "Land Berlin",
		"iso": "DE",
		"NumberOfSessions": 253
	},
	{
		"lat": 54.07969631119098,
		"lon": 10.11582056793576,
		"name": "Schleswig-Holstein",
		"iso": "DE",
		"NumberOfSessions": 75
	},
	{
		"lat": 52.353263303225994,
		"lon": 13.465404760954247,
		"name": "Brandenburg",
		"iso": "DE",
		"NumberOfSessions": 54
	},
	{
		"lat": 51.70807745289609,
		"lon": 11.877806659196839,
		"name": "Saxony-Anhalt",
		"iso": "DE",
		"NumberOfSessions": 60
	},
	{
		"lat": 51.376578422073486,
		"lon": 7.197483070785277,
		"name": "North Rhine-Westphalia",
		"iso": "DE",
		"NumberOfSessions": 653
	},
	{
		"lat": 53.119076845625365,
		"lon": 8.77795341597893,
		"name": "Bremen",
		"iso": "DE",
		"NumberOfSessions": 47
	},
	{
		"lat": 50.978995078834835,
		"lon": 10.975083566339048,
		"name": "Thuringia",
		"iso": "DE",
		"NumberOfSessions": 47
	},
	{
		"lat": 36.2394,
		"lon": 1.3342000000000003,
		"name": "Chlef",
		"iso": "DZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 33.9164,
		"lon": 2.5379,
		"name": "Laghouat",
		"iso": "DZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 30.905799999999996,
		"lon": -2.3049999999999997,
		"name": "Béchar",
		"iso": "DZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 36.3758,
		"lon": 6.608300000000001,
		"name": "Constantine",
		"iso": "DZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 35.5064,
		"lon": 6.0707,
		"name": "Batna",
		"iso": "DZ",
		"NumberOfSessions": 3
	},
	{
		"lat": 36.74563049421181,
		"lon": 3.079702235757018,
		"name": "Algiers",
		"iso": "DZ",
		"NumberOfSessions": 4
	},
	{
		"lat": 36.8866,
		"lon": 6.905,
		"name": "Skikda",
		"iso": "DZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 34.8992,
		"lon": -1.2874,
		"name": "Tlemcen",
		"iso": "DZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 36.5312,
		"lon": 3.0698,
		"name": "Blida",
		"iso": "DZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 36.4682,
		"lon": 4.7891,
		"name": "Béjaïa",
		"iso": "DZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.7204,
		"lon": -0.6659000000000002,
		"name": "Oran",
		"iso": "DZ",
		"NumberOfSessions": 2
	},
	{
		"lat": 32.6027,
		"lon": 5.913499999999999,
		"name": "Ouargla",
		"iso": "DZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 36.0718,
		"lon": 4.7602,
		"name": "Bordj Bou Arréridj",
		"iso": "DZ",
		"NumberOfSessions": 1
	},
	{
		"lat": -0.2365416683428087,
		"lon": -78.52369998688918,
		"name": "Pichincha",
		"iso": "EC",
		"NumberOfSessions": 12
	},
	{
		"lat": -0.93,
		"lon": -75.401,
		"name": "Orellana Province",
		"iso": "EC",
		"NumberOfSessions": 1
	},
	{
		"lat": -3.9908999999999986,
		"lon": -79.202,
		"name": "Loja",
		"iso": "EC",
		"NumberOfSessions": 1
	},
	{
		"lat": -0.9443999999999999,
		"lon": -80.7356,
		"name": "Manabí",
		"iso": "EC",
		"NumberOfSessions": 1
	},
	{
		"lat": -0.9336000000000004,
		"lon": -78.62,
		"name": "Cotopaxi",
		"iso": "EC",
		"NumberOfSessions": 2
	},
	{
		"lat": -2.1978000000000004,
		"lon": -79.8764,
		"name": "Guayas",
		"iso": "EC",
		"NumberOfSessions": 8
	},
	{
		"lat": 0.3512,
		"lon": -78.1178,
		"name": "Imbabura",
		"iso": "EC",
		"NumberOfSessions": 1
	},
	{
		"lat": 59.3465,
		"lon": 26.356600000000007,
		"name": "Rakvere linn",
		"iso": "EE",
		"NumberOfSessions": 1
	},
	{
		"lat": 58.3891,
		"lon": 24.498300000000004,
		"name": "Pärnu linn",
		"iso": "EE",
		"NumberOfSessions": 1
	},
	{
		"lat": 58.361900000000006,
		"lon": 25.589699999999997,
		"name": "Viljandi linn",
		"iso": "EE",
		"NumberOfSessions": 2
	},
	{
		"lat": 59.43810000000001,
		"lon": 24.736899999999995,
		"name": "Tallinn",
		"iso": "EE",
		"NumberOfSessions": 50
	},
	{
		"lat": 59.3755,
		"lon": 28.2032,
		"name": "Narva linn",
		"iso": "EE",
		"NumberOfSessions": 1
	},
	{
		"lat": 59.29820000000001,
		"lon": 24.4106,
		"name": "Keila linn",
		"iso": "EE",
		"NumberOfSessions": 1
	},
	{
		"lat": 59.398300000000006,
		"lon": 27.268000000000015,
		"name": "Kohtla-Järve linn",
		"iso": "EE",
		"NumberOfSessions": 5
	},
	{
		"lat": 59.46589999999998,
		"lon": 24.975,
		"name": "Maardu linn",
		"iso": "EE",
		"NumberOfSessions": 3
	},
	{
		"lat": 58.379400000000004,
		"lon": 26.732200000000002,
		"name": "Tartu linn",
		"iso": "EE",
		"NumberOfSessions": 2
	},
	{
		"lat": 30.008000000000003,
		"lon": 31.219400000000004,
		"name": "Giza",
		"iso": "EG",
		"NumberOfSessions": 7
	},
	{
		"lat": 30.061560261929145,
		"lon": 31.230638657926782,
		"name": "Cairo Governorate",
		"iso": "EG",
		"NumberOfSessions": 22
	},
	{
		"lat": 29.0776,
		"lon": 31.098600000000005,
		"name": "Beni Suweif",
		"iso": "EG",
		"NumberOfSessions": 1
	},
	{
		"lat": 24.091000000000008,
		"lon": 32.8942,
		"name": "Aswan",
		"iso": "EG",
		"NumberOfSessions": 1
	},
	{
		"lat": 30.6173,
		"lon": 31.72919999999999,
		"name": "Sharqia",
		"iso": "EG",
		"NumberOfSessions": 1
	},
	{
		"lat": 31.0371,
		"lon": 31.38439999999999,
		"name": "Dakahlia",
		"iso": "EG",
		"NumberOfSessions": 1
	},
	{
		"lat": 30.9604,
		"lon": 31.246800000000004,
		"name": "Gharbia",
		"iso": "EG",
		"NumberOfSessions": 1
	},
	{
		"lat": 31.41660000000001,
		"lon": 31.81050000000001,
		"name": "Damietta Governorate",
		"iso": "EG",
		"NumberOfSessions": 3
	},
	{
		"lat": 30.997700000000002,
		"lon": 29.7432,
		"name": "Alexandria",
		"iso": "EG",
		"NumberOfSessions": 2
	},
	{
		"lat": 40.956700000000005,
		"lon": -5.693200000000001,
		"name": "Salamanca",
		"iso": "ES",
		"NumberOfSessions": 3
	},
	{
		"lat": 38.625177910459904,
		"lon": -6.754284051645754,
		"name": "Badajoz",
		"iso": "ES",
		"NumberOfSessions": 5
	},
	{
		"lat": 40.191285248491035,
		"lon": 0.15890678440723013,
		"name": "Castellon",
		"iso": "ES",
		"NumberOfSessions": 4
	},
	{
		"lat": 28.250272607044973,
		"lon": -16.542105336222182,
		"name": "Santa Cruz de Tenerife",
		"iso": "ES",
		"NumberOfSessions": 9
	},
	{
		"lat": 41.4521196394223,
		"lon": 2.1568239126074795,
		"name": "Barcelona",
		"iso": "ES",
		"NumberOfSessions": 167
	},
	{
		"lat": 37.831646835091114,
		"lon": -4.734971226085079,
		"name": "Cordoba",
		"iso": "ES",
		"NumberOfSessions": 9
	},
	{
		"lat": 38.996620023599604,
		"lon": -1.854179996744329,
		"name": "Albacete",
		"iso": "ES",
		"NumberOfSessions": 5
	},
	{
		"lat": 40.6089437415216,
		"lon": -3.192553456752147,
		"name": "Guadalajara",
		"iso": "ES",
		"NumberOfSessions": 3
	},
	{
		"lat": 37.37920733676079,
		"lon": -5.907113496125301,
		"name": "Seville",
		"iso": "ES",
		"NumberOfSessions": 23
	},
	{
		"lat": 42.336612500004755,
		"lon": -7.869087500017391,
		"name": "Ourense",
		"iso": "ES",
		"NumberOfSessions": 8
	},
	{
		"lat": 36.899281436278166,
		"lon": -2.4784414121363816,
		"name": "Almeria",
		"iso": "ES",
		"NumberOfSessions": 11
	},
	{
		"lat": 42.28270752372383,
		"lon": -8.700971462472683,
		"name": "Pontevedra",
		"iso": "ES",
		"NumberOfSessions": 12
	},
	{
		"lat": 36.72610000000002,
		"lon": -4.426000000000003,
		"name": "Andalusia",
		"iso": "ES",
		"NumberOfSessions": 19
	},
	{
		"lat": 42.4701,
		"lon": -2.4523999999999986,
		"name": "La Rioja",
		"iso": "ES",
		"NumberOfSessions": 8
	},
	{
		"lat": 39.450276240636676,
		"lon": -0.415243101327848,
		"name": "Valencia",
		"iso": "ES",
		"NumberOfSessions": 56
	},
	{
		"lat": 39.59377495828945,
		"lon": 2.781378643121389,
		"name": "Balearic Islands",
		"iso": "ES",
		"NumberOfSessions": 18
	},
	{
		"lat": 41.061698163562085,
		"lon": 1.0958081271294728,
		"name": "Tarragona",
		"iso": "ES",
		"NumberOfSessions": 18
	},
	{
		"lat": 43.28596113644228,
		"lon": -2.9627298002013562,
		"name": "Biscay",
		"iso": "ES",
		"NumberOfSessions": 35
	},
	{
		"lat": 28.1,
		"lon": -15.4098,
		"name": "Canary Islands",
		"iso": "ES",
		"NumberOfSessions": 3
	},
	{
		"lat": 42.61216035489623,
		"lon": -5.581282311302479,
		"name": "Leon",
		"iso": "ES",
		"NumberOfSessions": 5
	},
	{
		"lat": 43.30344878023268,
		"lon": -1.9933897411222903,
		"name": "Gipuzkoa",
		"iso": "ES",
		"NumberOfSessions": 36
	},
	{
		"lat": 38.99419361808046,
		"lon": -3.6466806727078422,
		"name": "Ciudad Real",
		"iso": "ES",
		"NumberOfSessions": 2
	},
	{
		"lat": 43.243611376014755,
		"lon": -8.413243712156065,
		"name": "A Coruña",
		"iso": "ES",
		"NumberOfSessions": 35
	},
	{
		"lat": 42.16713663832082,
		"lon": -3.581290910314323,
		"name": "Burgos",
		"iso": "ES",
		"NumberOfSessions": 6
	},
	{
		"lat": 37.90043080154673,
		"lon": -1.1464109821159052,
		"name": "Murcia",
		"iso": "ES",
		"NumberOfSessions": 17
	},
	{
		"lat": 41.83344190388663,
		"lon": 2.7910288555972427,
		"name": "Girona",
		"iso": "ES",
		"NumberOfSessions": 4
	},
	{
		"lat": 36.49120071141751,
		"lon": -6.063438499275023,
		"name": "Cadiz",
		"iso": "ES",
		"NumberOfSessions": 14
	},
	{
		"lat": 37.07255975187523,
		"lon": -3.6067728115006568,
		"name": "Granada",
		"iso": "ES",
		"NumberOfSessions": 19
	},
	{
		"lat": 41.64031330189109,
		"lon": -0.9083060786925937,
		"name": "Saragossa",
		"iso": "ES",
		"NumberOfSessions": 16
	},
	{
		"lat": 40.0098,
		"lon": -3.0009999999999994,
		"name": "Cuenca",
		"iso": "ES",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.877500209092176,
		"lon": -5.3280005711185705,
		"name": "Ceuta",
		"iso": "ES",
		"NumberOfSessions": 5
	},
	{
		"lat": 41.6547,
		"lon": -4.7344,
		"name": "Castille and León",
		"iso": "ES",
		"NumberOfSessions": 5
	},
	{
		"lat": 36.651461420412005,
		"lon": -4.592675936925174,
		"name": "Malaga",
		"iso": "ES",
		"NumberOfSessions": 43
	},
	{
		"lat": 39.702757367811714,
		"lon": -6.318173129423119,
		"name": "Caceres",
		"iso": "ES",
		"NumberOfSessions": 5
	},
	{
		"lat": 41.5,
		"lon": -5.7438,
		"name": "Zamora",
		"iso": "ES",
		"NumberOfSessions": 1
	},
	{
		"lat": 42.84435001828498,
		"lon": -2.674650456272155,
		"name": "Araba / Álava",
		"iso": "ES",
		"NumberOfSessions": 2
	},
	{
		"lat": 37.2416007384583,
		"lon": -6.946439056648689,
		"name": "Huelva",
		"iso": "ES",
		"NumberOfSessions": 3
	},
	{
		"lat": 39.9424817675793,
		"lon": -3.8698590018681616,
		"name": "Toledo",
		"iso": "ES",
		"NumberOfSessions": 14
	},
	{
		"lat": 41.6674,
		"lon": 1.2689999999999997,
		"name": "Lleida",
		"iso": "ES",
		"NumberOfSessions": 2
	},
	{
		"lat": 41.99928573384476,
		"lon": -0.3281090396246539,
		"name": "Huesca",
		"iso": "ES",
		"NumberOfSessions": 11
	},
	{
		"lat": 41.39490000000001,
		"lon": 2.1756000000000006,
		"name": "Catalonia",
		"iso": "ES",
		"NumberOfSessions": 16
	},
	{
		"lat": 42.79883228916064,
		"lon": -1.6714299989543293,
		"name": "Navarre",
		"iso": "ES",
		"NumberOfSessions": 19
	},
	{
		"lat": 37.873306240515056,
		"lon": -3.6522472347174264,
		"name": "Jaen",
		"iso": "ES",
		"NumberOfSessions": 3
	},
	{
		"lat": 43.3699,
		"lon": -8.4096,
		"name": "Galicia",
		"iso": "ES",
		"NumberOfSessions": 1
	},
	{
		"lat": 38.37432597420562,
		"lon": -0.4552887094792194,
		"name": "Alicante",
		"iso": "ES",
		"NumberOfSessions": 39
	},
	{
		"lat": 40.41095735095852,
		"lon": -3.698830504081857,
		"name": "Madrid",
		"iso": "ES",
		"NumberOfSessions": 316
	},
	{
		"lat": 28.278981448693358,
		"lon": -14.981131422547051,
		"name": "Las Palmas",
		"iso": "ES",
		"NumberOfSessions": 11
	},
	{
		"lat": 41.63605284739472,
		"lon": -4.748862370660738,
		"name": "Valladolid",
		"iso": "ES",
		"NumberOfSessions": 14
	},
	{
		"lat": 43.43497213091964,
		"lon": -5.75436059564634,
		"name": "Asturias",
		"iso": "ES",
		"NumberOfSessions": 18
	},
	{
		"lat": 43.01290000009795,
		"lon": -7.552249992184251,
		"name": "Lugo",
		"iso": "ES",
		"NumberOfSessions": 4
	},
	{
		"lat": 43.39329892118284,
		"lon": -3.8385401913852712,
		"name": "Cantabria",
		"iso": "ES",
		"NumberOfSessions": 10
	},
	{
		"lat": 61.05995129918555,
		"lon": 28.207752160189845,
		"name": "South Karelia Region",
		"iso": "FI",
		"NumberOfSessions": 2
	},
	{
		"lat": 62.61339999999999,
		"lon": 29.8242,
		"name": "North Karelia",
		"iso": "FI",
		"NumberOfSessions": 4
	},
	{
		"lat": 61.50673061090964,
		"lon": 23.74259772415853,
		"name": "Pirkanmaa",
		"iso": "FI",
		"NumberOfSessions": 11
	},
	{
		"lat": 66.4822,
		"lon": 25.6835,
		"name": "Lapland",
		"iso": "FI",
		"NumberOfSessions": 1
	},
	{
		"lat": 62.893802507747324,
		"lon": 27.64196357092432,
		"name": "North Savo",
		"iso": "FI",
		"NumberOfSessions": 3
	},
	{
		"lat": 63.8413,
		"lon": 23.1317,
		"name": "Central Ostrobothnia",
		"iso": "FI",
		"NumberOfSessions": 1
	},
	{
		"lat": 65.0013,
		"lon": 25.451500000000003,
		"name": "North Ostrobothnia",
		"iso": "FI",
		"NumberOfSessions": 3
	},
	{
		"lat": 60.98839999999999,
		"lon": 24.4634,
		"name": "Kanta-Häme",
		"iso": "FI",
		"NumberOfSessions": 1
	},
	{
		"lat": 60.18402429678742,
		"lon": 24.92717611576287,
		"name": "Uusimaa",
		"iso": "FI",
		"NumberOfSessions": 135
	},
	{
		"lat": 60.98170211876854,
		"lon": 25.66370221901604,
		"name": "Paijat-Hame Region",
		"iso": "FI",
		"NumberOfSessions": 4
	},
	{
		"lat": 62.22800879879775,
		"lon": 25.709073847268016,
		"name": "Central Finland",
		"iso": "FI",
		"NumberOfSessions": 3
	},
	{
		"lat": 60.53859077993969,
		"lon": 22.171205520213324,
		"name": "Southwest Finland",
		"iso": "FI",
		"NumberOfSessions": 5
	},
	{
		"lat": 48.833012247467295,
		"lon": 2.4066408825867684,
		"name": "Paris",
		"iso": "FR",
		"NumberOfSessions": 490
	},
	{
		"lat": 48.07100271870197,
		"lon": -0.8277217370768786,
		"name": "Mayenne",
		"iso": "FR",
		"NumberOfSessions": 5
	},
	{
		"lat": 47.46060717291009,
		"lon": -0.5612613735611046,
		"name": "Maine-et-Loire",
		"iso": "FR",
		"NumberOfSessions": 27
	},
	{
		"lat": 47.643,
		"lon": 6.855699999999999,
		"name": "Territoire de Belfort",
		"iso": "FR",
		"NumberOfSessions": 3
	},
	{
		"lat": 45.66771423805833,
		"lon": 4.273116867301736,
		"name": "Loire",
		"iso": "FR",
		"NumberOfSessions": 25
	},
	{
		"lat": 47.98272515951926,
		"lon": 0.20436538115273203,
		"name": "Sarthe",
		"iso": "FR",
		"NumberOfSessions": 41
	},
	{
		"lat": 46.80366145342497,
		"lon": 5.615561855177569,
		"name": "Jura",
		"iso": "FR",
		"NumberOfSessions": 14
	},
	{
		"lat": 45.078906674720756,
		"lon": 0.9389631192114993,
		"name": "Dordogne",
		"iso": "FR",
		"NumberOfSessions": 11
	},
	{
		"lat": 48.671501794963,
		"lon": 2.3359933537597706,
		"name": "Essonne",
		"iso": "FR",
		"NumberOfSessions": 35
	},
	{
		"lat": 44.960650687678886,
		"lon": 4.9916335647951735,
		"name": "Drôme",
		"iso": "FR",
		"NumberOfSessions": 33
	},
	{
		"lat": 48.26422582846144,
		"lon": 4.120471343652912,
		"name": "Aube",
		"iso": "FR",
		"NumberOfSessions": 6
	},
	{
		"lat": 50.48416074948079,
		"lon": 2.6141247016503466,
		"name": "Pas-de-Calais",
		"iso": "FR",
		"NumberOfSessions": 52
	},
	{
		"lat": 47.8854442919731,
		"lon": 3.5411287185506297,
		"name": "Yonne",
		"iso": "FR",
		"NumberOfSessions": 8
	},
	{
		"lat": 46.377870702258086,
		"lon": -0.3645834544269239,
		"name": "Deux-Sèvres",
		"iso": "FR",
		"NumberOfSessions": 30
	},
	{
		"lat": 45.85479559452262,
		"lon": 1.2421490513786397,
		"name": "Haute-Vienne",
		"iso": "FR",
		"NumberOfSessions": 27
	},
	{
		"lat": 48.86110000000003,
		"lon": 2.326900000000007,
		"name": "Île-de-France",
		"iso": "FR",
		"NumberOfSessions": 96
	},
	{
		"lat": 43.66212763165309,
		"lon": 7.166318905360411,
		"name": "Alpes-Maritimes",
		"iso": "FR",
		"NumberOfSessions": 36
	},
	{
		"lat": 44.385270709504326,
		"lon": 2.2295329846703096,
		"name": "Aveyron",
		"iso": "FR",
		"NumberOfSessions": 5
	},
	{
		"lat": 43.21560154210715,
		"lon": 6.100165616375326,
		"name": "Var",
		"iso": "FR",
		"NumberOfSessions": 47
	},
	{
		"lat": 44.78669302893156,
		"lon": 1.7566311144253817,
		"name": "Lot",
		"iso": "FR",
		"NumberOfSessions": 9
	},
	{
		"lat": 48.51468944392818,
		"lon": -2.7678307894914522,
		"name": "Côtes-d'Armor",
		"iso": "FR",
		"NumberOfSessions": 14
	},
	{
		"lat": 47.2355760321603,
		"lon": 6.223498485199102,
		"name": "Doubs",
		"iso": "FR",
		"NumberOfSessions": 35
	},
	{
		"lat": 43.97770054868499,
		"lon": 4.991015091304049,
		"name": "Vaucluse",
		"iso": "FR",
		"NumberOfSessions": 11
	},
	{
		"lat": 47.912853362366754,
		"lon": 2.021650448712371,
		"name": "Loiret",
		"iso": "FR",
		"NumberOfSessions": 26
	},
	{
		"lat": 46.70905823166167,
		"lon": -1.61691129858741,
		"name": "Vendée",
		"iso": "FR",
		"NumberOfSessions": 12
	},
	{
		"lat": 50.56096302279489,
		"lon": 3.1851245906519488,
		"name": "North",
		"iso": "FR",
		"NumberOfSessions": 155
	},
	{
		"lat": 48.86051720451928,
		"lon": 2.0687833765750567,
		"name": "Yvelines",
		"iso": "FR",
		"NumberOfSessions": 65
	},
	{
		"lat": 45.61215959693439,
		"lon": 6.008660784893203,
		"name": "Savoy",
		"iso": "FR",
		"NumberOfSessions": 14
	},
	{
		"lat": 48.899440278997986,
		"lon": 2.452371696866852,
		"name": "Seine-Saint-Denis",
		"iso": "FR",
		"NumberOfSessions": 56
	},
	{
		"lat": 48.46294635606092,
		"lon": 1.4695160927676147,
		"name": "Eure-et-Loir",
		"iso": "FR",
		"NumberOfSessions": 10
	},
	{
		"lat": 48.3710905596568,
		"lon": 5.050847705869144,
		"name": "Haute-Marne",
		"iso": "FR",
		"NumberOfSessions": 2
	},
	{
		"lat": 43.197942548589296,
		"lon": 0.12096343850542762,
		"name": "Hautes-Pyrénées",
		"iso": "FR",
		"NumberOfSessions": 8
	},
	{
		"lat": 44.9192,
		"lon": 2.4429,
		"name": "Cantal",
		"iso": "FR",
		"NumberOfSessions": 2
	},
	{
		"lat": 43.94161081098233,
		"lon": 1.2878233348940786,
		"name": "Tarn-et-Garonne",
		"iso": "FR",
		"NumberOfSessions": 7
	},
	{
		"lat": 43.82900716749083,
		"lon": 2.1308743200616633,
		"name": "Tarn",
		"iso": "FR",
		"NumberOfSessions": 11
	},
	{
		"lat": 45.222579697572186,
		"lon": 3.7690994407565372,
		"name": "Haute-Loire",
		"iso": "FR",
		"NumberOfSessions": 2
	},
	{
		"lat": 46.199625281416886,
		"lon": 5.70936275730179,
		"name": "Ain",
		"iso": "FR",
		"NumberOfSessions": 21
	},
	{
		"lat": 46.10660051220865,
		"lon": 6.397853339620948,
		"name": "Upper Savoy",
		"iso": "FR",
		"NumberOfSessions": 37
	},
	{
		"lat": 47.352677923556584,
		"lon": 4.925256355739436,
		"name": "Cote d'Or",
		"iso": "FR",
		"NumberOfSessions": 14
	},
	{
		"lat": 48.67186402984038,
		"lon": 6.2249731496530565,
		"name": "Meurthe et Moselle",
		"iso": "FR",
		"NumberOfSessions": 23
	},
	{
		"lat": 44.88823525310873,
		"lon": 4.596964718559539,
		"name": "Ardèche",
		"iso": "FR",
		"NumberOfSessions": 12
	},
	{
		"lat": 49.22729617890589,
		"lon": 1.165074059872388,
		"name": "Eure",
		"iso": "FR",
		"NumberOfSessions": 32
	},
	{
		"lat": 44.5489,
		"lon": 6.282,
		"name": "Hautes-Alpes",
		"iso": "FR",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.842879213929976,
		"lon": -0.783058043377704,
		"name": "Charente-Maritime",
		"iso": "FR",
		"NumberOfSessions": 33
	},
	{
		"lat": 47.78699692923015,
		"lon": -3.1323594450751195,
		"name": "Morbihan",
		"iso": "FR",
		"NumberOfSessions": 36
	},
	{
		"lat": 48.21389906359694,
		"lon": 6.540476908616138,
		"name": "Vosges",
		"iso": "FR",
		"NumberOfSessions": 9
	},
	{
		"lat": 46.81519999999999,
		"lon": 1.6943,
		"name": "Indre",
		"iso": "FR",
		"NumberOfSessions": 6
	},
	{
		"lat": 48.44438639843316,
		"lon": -4.136042623868653,
		"name": "Finistère",
		"iso": "FR",
		"NumberOfSessions": 38
	},
	{
		"lat": 49.12940873838364,
		"lon": 4.127071058557213,
		"name": "Marne",
		"iso": "FR",
		"NumberOfSessions": 14
	},
	{
		"lat": 48.73348701086392,
		"lon": -0.005795614373591425,
		"name": "Orne",
		"iso": "FR",
		"NumberOfSessions": 4
	},
	{
		"lat": 43.79995551090032,
		"lon": -0.8555519174888023,
		"name": "Landes",
		"iso": "FR",
		"NumberOfSessions": 14
	},
	{
		"lat": 45.66398520532297,
		"lon": 0.06840161079065826,
		"name": "Charente",
		"iso": "FR",
		"NumberOfSessions": 23
	},
	{
		"lat": 47.6269513417907,
		"lon": 6.493191530611779,
		"name": "Haute-Saône",
		"iso": "FR",
		"NumberOfSessions": 23
	},
	{
		"lat": 43.63187239047434,
		"lon": 0.5711443747783436,
		"name": "Gers",
		"iso": "FR",
		"NumberOfSessions": 4
	},
	{
		"lat": 47.0509902185663,
		"lon": 2.347942828559821,
		"name": "Cher",
		"iso": "FR",
		"NumberOfSessions": 3
	},
	{
		"lat": 48.73203036018226,
		"lon": 5.294890446179379,
		"name": "Meuse",
		"iso": "FR",
		"NumberOfSessions": 4
	},
	{
		"lat": 44.80761312745244,
		"lon": -0.6180227362157387,
		"name": "Gironde",
		"iso": "FR",
		"NumberOfSessions": 68
	},
	{
		"lat": 42.99322562726629,
		"lon": 1.581082374475454,
		"name": "Ariège",
		"iso": "FR",
		"NumberOfSessions": 3
	},
	{
		"lat": 45.759721242300074,
		"lon": 4.843343544208537,
		"name": "Rhône",
		"iso": "FR",
		"NumberOfSessions": 115
	},
	{
		"lat": 48.752502345482796,
		"lon": 2.7126969983039437,
		"name": "Seine-et-Marne",
		"iso": "FR",
		"NumberOfSessions": 50
	},
	{
		"lat": 42.69469960122661,
		"lon": 2.89098518893602,
		"name": "Pyrénées-Orientales",
		"iso": "FR",
		"NumberOfSessions": 62
	},
	{
		"lat": 49.24871879473928,
		"lon": -1.2271112779508957,
		"name": "Manche",
		"iso": "FR",
		"NumberOfSessions": 4
	},
	{
		"lat": 43.4013853830506,
		"lon": 5.300021387897415,
		"name": "Bouches-du-Rhône",
		"iso": "FR",
		"NumberOfSessions": 55
	},
	{
		"lat": 48.86954767166138,
		"lon": 2.2693682243370312,
		"name": "Hauts-de-Seine",
		"iso": "FR",
		"NumberOfSessions": 86
	},
	{
		"lat": 41.9199,
		"lon": 8.7424,
		"name": "South Corsica",
		"iso": "FR",
		"NumberOfSessions": 5
	},
	{
		"lat": 49.364457102819735,
		"lon": 2.451028396159348,
		"name": "Oise",
		"iso": "FR",
		"NumberOfSessions": 24
	},
	{
		"lat": 49.35214666394001,
		"lon": 3.352465153975228,
		"name": "Aisne",
		"iso": "FR",
		"NumberOfSessions": 19
	},
	{
		"lat": 43.137055078347785,
		"lon": 2.9461007860909603,
		"name": "Aude",
		"iso": "FR",
		"NumberOfSessions": 58
	},
	{
		"lat": 44.351918384071745,
		"lon": 0.43776428167860415,
		"name": "Lot-et-Garonne",
		"iso": "FR",
		"NumberOfSessions": 5
	},
	{
		"lat": 46.1742,
		"lon": 1.8727999999999998,
		"name": "Creuse",
		"iso": "FR",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.72498366166289,
		"lon": 4.749948472040939,
		"name": "Saône-et-Loire",
		"iso": "FR",
		"NumberOfSessions": 24
	},
	{
		"lat": 45.28298493633912,
		"lon": 1.514206858522451,
		"name": "Corrèze",
		"iso": "FR",
		"NumberOfSessions": 7
	},
	{
		"lat": 49.01727366383668,
		"lon": 2.2142808101993463,
		"name": "Val d'Oise",
		"iso": "FR",
		"NumberOfSessions": 86
	},
	{
		"lat": 45.75953433043062,
		"lon": 3.1444271706789864,
		"name": "Puy-de-Dôme",
		"iso": "FR",
		"NumberOfSessions": 40
	},
	{
		"lat": 43.59787497766697,
		"lon": 1.393141902794409,
		"name": "Upper Garonne",
		"iso": "FR",
		"NumberOfSessions": 85
	},
	{
		"lat": 49.19198845473776,
		"lon": -0.3158715701276686,
		"name": "Calvados",
		"iso": "FR",
		"NumberOfSessions": 39
	},
	{
		"lat": 47.10104972861613,
		"lon": 3.241307538528017,
		"name": "Nièvre",
		"iso": "FR",
		"NumberOfSessions": 6
	},
	{
		"lat": 45.32620392875526,
		"lon": 5.512805873793834,
		"name": "Isère",
		"iso": "FR",
		"NumberOfSessions": 42
	},
	{
		"lat": 48.61498247727855,
		"lon": 7.6426380935684985,
		"name": "Bas-Rhin",
		"iso": "FR",
		"NumberOfSessions": 52
	},
	{
		"lat": 43.57323762053033,
		"lon": 3.7366599899249184,
		"name": "Hérault",
		"iso": "FR",
		"NumberOfSessions": 54
	},
	{
		"lat": 48.157024233997035,
		"lon": -1.6999663455102039,
		"name": "Ille-et-Vilaine",
		"iso": "FR",
		"NumberOfSessions": 55
	},
	{
		"lat": 47.546892125964874,
		"lon": 1.321665206600142,
		"name": "Loir-et-Cher",
		"iso": "FR",
		"NumberOfSessions": 11
	},
	{
		"lat": 49.15316157865205,
		"lon": 6.299150341014461,
		"name": "Moselle",
		"iso": "FR",
		"NumberOfSessions": 54
	},
	{
		"lat": 43.40635490421503,
		"lon": -0.8455101191219582,
		"name": "Pyrénées-Atlantiques",
		"iso": "FR",
		"NumberOfSessions": 27
	},
	{
		"lat": 49.49623415675253,
		"lon": 0.850146523585705,
		"name": "Seine-Maritime",
		"iso": "FR",
		"NumberOfSessions": 32
	},
	{
		"lat": 46.48650794596746,
		"lon": 0.28105499643406656,
		"name": "Vienne",
		"iso": "FR",
		"NumberOfSessions": 8
	},
	{
		"lat": 47.219390326948734,
		"lon": -1.5871881992313013,
		"name": "Loire-Atlantique",
		"iso": "FR",
		"NumberOfSessions": 109
	},
	{
		"lat": 47.843392671374644,
		"lon": 7.352451289636594,
		"name": "Haut-Rhin",
		"iso": "FR",
		"NumberOfSessions": 38
	},
	{
		"lat": 42.56447756920774,
		"lon": 9.350683253318174,
		"name": "Upper Corsica",
		"iso": "FR",
		"NumberOfSessions": 5
	},
	{
		"lat": 43.86095049136,
		"lon": 4.2963348917749,
		"name": "Gard",
		"iso": "FR",
		"NumberOfSessions": 20
	},
	{
		"lat": 49.93628457036442,
		"lon": 2.271007185379485,
		"name": "Somme",
		"iso": "FR",
		"NumberOfSessions": 37
	},
	{
		"lat": 48.788708766014416,
		"lon": 2.429322774081763,
		"name": "Val-de-Marne",
		"iso": "FR",
		"NumberOfSessions": 67
	},
	{
		"lat": 49.64799966906846,
		"lon": 4.8132621204279475,
		"name": "Ardennes",
		"iso": "FR",
		"NumberOfSessions": 5
	},
	{
		"lat": 47.36646817007806,
		"lon": 0.6757375158964252,
		"name": "Indre-et-Loire",
		"iso": "FR",
		"NumberOfSessions": 24
	},
	{
		"lat": 46.187075317562616,
		"lon": 3.2104229357762097,
		"name": "Allier",
		"iso": "FR",
		"NumberOfSessions": 7
	},
	{
		"lat": 51.364912741181556,
		"lon": -0.06377193447770936,
		"name": "Croydon",
		"iso": "GB",
		"NumberOfSessions": 22
	},
	{
		"lat": 51.46326713978468,
		"lon": -0.023109778386906137,
		"name": "Lewisham",
		"iso": "GB",
		"NumberOfSessions": 23
	},
	{
		"lat": 54.885390360772725,
		"lon": -1.4522412747197988,
		"name": "Sunderland",
		"iso": "GB",
		"NumberOfSessions": 11
	},
	{
		"lat": 53.832341695186436,
		"lon": -1.8382955838819244,
		"name": "Bradford",
		"iso": "GB",
		"NumberOfSessions": 13
	},
	{
		"lat": 53.78251094458315,
		"lon": -2.5392214114491622,
		"name": "Blackburn with Darwen",
		"iso": "GB",
		"NumberOfSessions": 6
	},
	{
		"lat": 52.70157147993214,
		"lon": -2.428180155003778,
		"name": "Telford and Wrekin",
		"iso": "GB",
		"NumberOfSessions": 8
	},
	{
		"lat": 52.68843426917804,
		"lon": -1.2018800084049441,
		"name": "Leicestershire",
		"iso": "GB",
		"NumberOfSessions": 21
	},
	{
		"lat": 51.978595846886336,
		"lon": -0.49275426418279017,
		"name": "Central Bedfordshire",
		"iso": "GB",
		"NumberOfSessions": 16
	},
	{
		"lat": 51.39852502224164,
		"lon": 0.044424290053531185,
		"name": "Bromley",
		"iso": "GB",
		"NumberOfSessions": 8
	},
	{
		"lat": 54.684672846659566,
		"lon": -6.035074779308037,
		"name": "Antrim and Newtownabbey",
		"iso": "GB",
		"NumberOfSessions": 7
	},
	{
		"lat": 53.38631561148299,
		"lon": -2.173325034724268,
		"name": "Stockport",
		"iso": "GB",
		"NumberOfSessions": 9
	},
	{
		"lat": 50.704563945107935,
		"lon": -1.1416752180905372,
		"name": "Isle of Wight",
		"iso": "GB",
		"NumberOfSessions": 4
	},
	{
		"lat": 51.32350674398407,
		"lon": -2.9390993925518543,
		"name": "North Somerset",
		"iso": "GB",
		"NumberOfSessions": 2
	},
	{
		"lat": 55.03490000000001,
		"lon": -7.2718,
		"name": "Derry City and Strabane",
		"iso": "GB",
		"NumberOfSessions": 2
	},
	{
		"lat": 53.67238423915785,
		"lon": -1.724088351285279,
		"name": "Kirklees",
		"iso": "GB",
		"NumberOfSessions": 11
	},
	{
		"lat": 51.83625885615724,
		"lon": -4.188335993912887,
		"name": "Carmarthenshire",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 51.9231521981363,
		"lon": -0.48750994208227993,
		"name": "Luton",
		"iso": "GB",
		"NumberOfSessions": 30
	},
	{
		"lat": 53.76699450298382,
		"lon": -0.359295364850698,
		"name": "Kingston upon Hull",
		"iso": "GB",
		"NumberOfSessions": 23
	},
	{
		"lat": 51.697480339755124,
		"lon": -1.2538618593406259,
		"name": "Oxfordshire",
		"iso": "GB",
		"NumberOfSessions": 26
	},
	{
		"lat": 50.791766496847025,
		"lon": -2.3588732957647904,
		"name": "Dorset",
		"iso": "GB",
		"NumberOfSessions": 14
	},
	{
		"lat": 54.28391644833325,
		"lon": -6.186494272324145,
		"name": "Newry Mourne and Down",
		"iso": "GB",
		"NumberOfSessions": 2
	},
	{
		"lat": 55.091800882906284,
		"lon": -3.4313201663207455,
		"name": "Dumfries and Galloway",
		"iso": "GB",
		"NumberOfSessions": 2
	},
	{
		"lat": 55.84378882896044,
		"lon": -4.4435077797656195,
		"name": "Renfrewshire",
		"iso": "GB",
		"NumberOfSessions": 7
	},
	{
		"lat": 51.658798741087274,
		"lon": -3.4528063537079605,
		"name": "Rhondda Cynon Taf",
		"iso": "GB",
		"NumberOfSessions": 8
	},
	{
		"lat": 51.51138000740215,
		"lon": -0.09484010895036864,
		"name": "City of London",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 52.41382005005335,
		"lon": -1.5081785117871436,
		"name": "Coventry",
		"iso": "GB",
		"NumberOfSessions": 21
	},
	{
		"lat": 53.961529401633456,
		"lon": -1.3967498233426283,
		"name": "North Yorkshire",
		"iso": "GB",
		"NumberOfSessions": 7
	},
	{
		"lat": 52.307796271772055,
		"lon": -1.5137065247754864,
		"name": "Warwickshire",
		"iso": "GB",
		"NumberOfSessions": 13
	},
	{
		"lat": 56.1185,
		"lon": -3.7941,
		"name": "Clackmannanshire",
		"iso": "GB",
		"NumberOfSessions": 7
	},
	{
		"lat": 51.57954812798609,
		"lon": -0.01964439208007644,
		"name": "Waltham Forest",
		"iso": "GB",
		"NumberOfSessions": 19
	},
	{
		"lat": 52.511977680969146,
		"lon": -2.001882573097739,
		"name": "Sandwell",
		"iso": "GB",
		"NumberOfSessions": 24
	},
	{
		"lat": 52.90626457454335,
		"lon": -1.4777797952983607,
		"name": "Derby",
		"iso": "GB",
		"NumberOfSessions": 14
	},
	{
		"lat": 51.48214074873219,
		"lon": -0.6798567820034127,
		"name": "Windsor and Maidenhead",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 56.16504395856128,
		"lon": -5.08905080429566,
		"name": "Argyll and Bute",
		"iso": "GB",
		"NumberOfSessions": 3
	},
	{
		"lat": 51.431067847410304,
		"lon": -1.013112705510449,
		"name": "Reading",
		"iso": "GB",
		"NumberOfSessions": 23
	},
	{
		"lat": 51.51171723430948,
		"lon": -0.03265211312586507,
		"name": "Tower Hamlets",
		"iso": "GB",
		"NumberOfSessions": 63
	},
	{
		"lat": 51.7079195036502,
		"lon": -0.7664374835163372,
		"name": "Buckinghamshire",
		"iso": "GB",
		"NumberOfSessions": 23
	},
	{
		"lat": 54.9893524933157,
		"lon": -1.6405707372967884,
		"name": "Newcastle upon Tyne",
		"iso": "GB",
		"NumberOfSessions": 25
	},
	{
		"lat": 54.0771,
		"lon": -0.2306,
		"name": "East Riding of Yorkshire",
		"iso": "GB",
		"NumberOfSessions": 2
	},
	{
		"lat": 54.68878462003791,
		"lon": -1.2400148009078773,
		"name": "Hartlepool",
		"iso": "GB",
		"NumberOfSessions": 6
	},
	{
		"lat": 57.5908007461516,
		"lon": -4.207381977112387,
		"name": "Highland",
		"iso": "GB",
		"NumberOfSessions": 7
	},
	{
		"lat": 53.02478589182657,
		"lon": -2.1757509321433184,
		"name": "Stoke-on-Trent",
		"iso": "GB",
		"NumberOfSessions": 28
	},
	{
		"lat": 52.27010010151923,
		"lon": -2.010562396344556,
		"name": "Worcestershire",
		"iso": "GB",
		"NumberOfSessions": 31
	},
	{
		"lat": 53.438178050060436,
		"lon": -2.7133758446428917,
		"name": "St. Helens",
		"iso": "GB",
		"NumberOfSessions": 8
	},
	{
		"lat": 52.646835279880705,
		"lon": -1.0990181698254875,
		"name": "Leicester",
		"iso": "GB",
		"NumberOfSessions": 193
	},
	{
		"lat": 54.9705009092563,
		"lon": -1.4101957114908317,
		"name": "South Tyneside",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 50.81743024320715,
		"lon": -1.0784691288408754,
		"name": "Portsmouth",
		"iso": "GB",
		"NumberOfSessions": 12
	},
	{
		"lat": 51.54218896516665,
		"lon": -0.11112654366143913,
		"name": "Islington",
		"iso": "GB",
		"NumberOfSessions": 50
	},
	{
		"lat": 53.40866007016164,
		"lon": -2.589122115913639,
		"name": "Warrington",
		"iso": "GB",
		"NumberOfSessions": 10
	},
	{
		"lat": 51.53062520959372,
		"lon": 0.027208438081276452,
		"name": "Newham",
		"iso": "GB",
		"NumberOfSessions": 21
	},
	{
		"lat": 55.74226643077297,
		"lon": -3.987456049521366,
		"name": "South Lanarkshire",
		"iso": "GB",
		"NumberOfSessions": 6
	},
	{
		"lat": 52.476987950883114,
		"lon": -2.0891130847854162,
		"name": "Dudley",
		"iso": "GB",
		"NumberOfSessions": 8
	},
	{
		"lat": 51.9634,
		"lon": -5.1263,
		"name": "Pembrokeshire",
		"iso": "GB",
		"NumberOfSessions": 1
	},
	{
		"lat": 55.809,
		"lon": -3.2075,
		"name": "Midlothian",
		"iso": "GB",
		"NumberOfSessions": 1
	},
	{
		"lat": 51.53809035363167,
		"lon": -0.6683116802592666,
		"name": "Slough",
		"iso": "GB",
		"NumberOfSessions": 29
	},
	{
		"lat": 50.67437954969012,
		"lon": -3.5999545792221483,
		"name": "Devon",
		"iso": "GB",
		"NumberOfSessions": 19
	},
	{
		"lat": 56.748099999999994,
		"lon": -2.4272999999999985,
		"name": "Angus",
		"iso": "GB",
		"NumberOfSessions": 7
	},
	{
		"lat": 51.661244027447424,
		"lon": -3.929339598624733,
		"name": "City and County of Swansea",
		"iso": "GB",
		"NumberOfSessions": 6
	},
	{
		"lat": 51.40225115867725,
		"lon": -0.29493885714493573,
		"name": "Kingston upon Thames",
		"iso": "GB",
		"NumberOfSessions": 10
	},
	{
		"lat": 51.36602031137684,
		"lon": -0.8563770012056824,
		"name": "Wokingham",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 51.45931474195144,
		"lon": -2.5627342494894414,
		"name": "Bristol",
		"iso": "GB",
		"NumberOfSessions": 91
	},
	{
		"lat": 53.041525263314604,
		"lon": -1.5046565459913024,
		"name": "Derbyshire",
		"iso": "GB",
		"NumberOfSessions": 40
	},
	{
		"lat": 51.60289895930846,
		"lon": -0.2135142351290916,
		"name": "Barnet",
		"iso": "GB",
		"NumberOfSessions": 14
	},
	{
		"lat": 53.384829729010065,
		"lon": -1.4598387605809087,
		"name": "Sheffield",
		"iso": "GB",
		"NumberOfSessions": 45
	},
	{
		"lat": 53.48704898113223,
		"lon": -2.2382413814922453,
		"name": "Manchester",
		"iso": "GB",
		"NumberOfSessions": 111
	},
	{
		"lat": 53.85095081503418,
		"lon": -2.6110253182007184,
		"name": "Lancashire",
		"iso": "GB",
		"NumberOfSessions": 34
	},
	{
		"lat": 51.22490309247751,
		"lon": 0.6452074082631151,
		"name": "Kent",
		"iso": "GB",
		"NumberOfSessions": 54
	},
	{
		"lat": 57.22439078737604,
		"lon": -2.4117729532566927,
		"name": "Aberdeenshire",
		"iso": "GB",
		"NumberOfSessions": 8
	},
	{
		"lat": 53.60284451995356,
		"lon": -0.5643211495938238,
		"name": "North Lincolnshire",
		"iso": "GB",
		"NumberOfSessions": 6
	},
	{
		"lat": 51.52206808527532,
		"lon": -0.29997293518136076,
		"name": "Ealing",
		"iso": "GB",
		"NumberOfSessions": 20
	},
	{
		"lat": 54.9912,
		"lon": -1.5336,
		"name": "North Tyneside",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 51.53833163205644,
		"lon": 0.7111071846790775,
		"name": "Southend-on-Sea",
		"iso": "GB",
		"NumberOfSessions": 16
	},
	{
		"lat": 51.47444043569821,
		"lon": -0.11912501952428912,
		"name": "Lambeth",
		"iso": "GB",
		"NumberOfSessions": 15
	},
	{
		"lat": 50.91190074704256,
		"lon": -1.3975912138774906,
		"name": "Southampton",
		"iso": "GB",
		"NumberOfSessions": 46
	},
	{
		"lat": 51.446402401538414,
		"lon": -0.3154050285020535,
		"name": "Richmond upon Thames",
		"iso": "GB",
		"NumberOfSessions": 6
	},
	{
		"lat": 51.41162409011155,
		"lon": -1.3157927073115951,
		"name": "West Berkshire",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 52.412200949069764,
		"lon": -1.7991007822374145,
		"name": "Solihull",
		"iso": "GB",
		"NumberOfSessions": 2
	},
	{
		"lat": 51.706787341605775,
		"lon": -3.3395190372851062,
		"name": "Merthyr Tydfil",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 51.49558072401257,
		"lon": -0.07949072507545159,
		"name": "Southwark",
		"iso": "GB",
		"NumberOfSessions": 75
	},
	{
		"lat": 53.786182092780614,
		"lon": -1.5572697247759526,
		"name": "Leeds",
		"iso": "GB",
		"NumberOfSessions": 51
	},
	{
		"lat": 51.36542827574921,
		"lon": 0.5326237330855733,
		"name": "Medway",
		"iso": "GB",
		"NumberOfSessions": 21
	},
	{
		"lat": 54.60376495276189,
		"lon": -6.8827831239262665,
		"name": "Mid Ulster",
		"iso": "GB",
		"NumberOfSessions": 15
	},
	{
		"lat": 53.41954290920135,
		"lon": -2.7813412794598764,
		"name": "Knowsley",
		"iso": "GB",
		"NumberOfSessions": 3
	},
	{
		"lat": 51.61870075081192,
		"lon": -3.06966112887488,
		"name": "Newport",
		"iso": "GB",
		"NumberOfSessions": 16
	},
	{
		"lat": 51.57356116014537,
		"lon": -2.47340676093865,
		"name": "South Gloucestershire",
		"iso": "GB",
		"NumberOfSessions": 2
	},
	{
		"lat": 51.484200372307924,
		"lon": -0.20735879374571503,
		"name": "Hammersmith and Fulham",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 51.407766896917316,
		"lon": -0.16999767268836738,
		"name": "Merton",
		"iso": "GB",
		"NumberOfSessions": 3
	},
	{
		"lat": 52.951776639750484,
		"lon": -1.172839508919386,
		"name": "Nottingham",
		"iso": "GB",
		"NumberOfSessions": 41
	},
	{
		"lat": 56.47166081423731,
		"lon": -3.7797941309773107,
		"name": "Perth and Kinross",
		"iso": "GB",
		"NumberOfSessions": 3
	},
	{
		"lat": 53.00714658669597,
		"lon": -2.985101819704952,
		"name": "Wrexham",
		"iso": "GB",
		"NumberOfSessions": 6
	},
	{
		"lat": 53.20356666636675,
		"lon": -3.165065850250484,
		"name": "Flintshire",
		"iso": "GB",
		"NumberOfSessions": 3
	},
	{
		"lat": 55.54095317504578,
		"lon": -4.371531838441938,
		"name": "East Ayrshire",
		"iso": "GB",
		"NumberOfSessions": 3
	},
	{
		"lat": 51.42144669587793,
		"lon": -3.3612740238786802,
		"name": "Vale of Glamorgan",
		"iso": "GB",
		"NumberOfSessions": 6
	},
	{
		"lat": 53.12426705060869,
		"lon": -0.3657679423382458,
		"name": "Lincolnshire",
		"iso": "GB",
		"NumberOfSessions": 33
	},
	{
		"lat": 52.759898224259985,
		"lon": -2.8202906281313216,
		"name": "Shropshire",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 51.31596706275213,
		"lon": -2.0189463272882158,
		"name": "Wiltshire",
		"iso": "GB",
		"NumberOfSessions": 20
	},
	{
		"lat": 52.57878743037606,
		"lon": -0.2568183607055485,
		"name": "Peterborough",
		"iso": "GB",
		"NumberOfSessions": 7
	},
	{
		"lat": 53.41187238150525,
		"lon": -2.9413717520310434,
		"name": "Liverpool",
		"iso": "GB",
		"NumberOfSessions": 31
	},
	{
		"lat": 51.503064370947456,
		"lon": -3.1754589306271677,
		"name": "Cardiff",
		"iso": "GB",
		"NumberOfSessions": 65
	},
	{
		"lat": 55.1205898647679,
		"lon": -1.6788909156448706,
		"name": "Northumberland",
		"iso": "GB",
		"NumberOfSessions": 7
	},
	{
		"lat": 53.94193337097205,
		"lon": -1.0192505012782636,
		"name": "York",
		"iso": "GB",
		"NumberOfSessions": 13
	},
	{
		"lat": 51.844025346601185,
		"lon": -2.126876987475449,
		"name": "Gloucestershire",
		"iso": "GB",
		"NumberOfSessions": 26
	},
	{
		"lat": 51.336000787505625,
		"lon": -2.3985317516517033,
		"name": "Bath and North East Somerset",
		"iso": "GB",
		"NumberOfSessions": 10
	},
	{
		"lat": 52.26666932342187,
		"lon": -0.8120061479293226,
		"name": "Northamptonshire",
		"iso": "GB",
		"NumberOfSessions": 57
	},
	{
		"lat": 50.39429036981794,
		"lon": -4.1225978148589215,
		"name": "Plymouth",
		"iso": "GB",
		"NumberOfSessions": 15
	},
	{
		"lat": 54.709305187612095,
		"lon": -1.6694523056506991,
		"name": "Durham",
		"iso": "GB",
		"NumberOfSessions": 25
	},
	{
		"lat": 51.52907404300423,
		"lon": -0.4488938234075329,
		"name": "Hillingdon",
		"iso": "GB",
		"NumberOfSessions": 10
	},
	{
		"lat": 55.64405347742018,
		"lon": -4.721563270546512,
		"name": "North Ayrshire",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 55.883082350650135,
		"lon": -3.5182457243351686,
		"name": "West Lothian",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 51.592879191089104,
		"lon": -0.11586821705899226,
		"name": "Haringey",
		"iso": "GB",
		"NumberOfSessions": 12
	},
	{
		"lat": 51.507787267984824,
		"lon": -0.11225349681350555,
		"name": "England",
		"iso": "GB",
		"NumberOfSessions": 105
	},
	{
		"lat": 55.94311628023807,
		"lon": -3.205660736364583,
		"name": "Edinburgh",
		"iso": "GB",
		"NumberOfSessions": 23
	},
	{
		"lat": 51.64734431891394,
		"lon": -3.2372423074171137,
		"name": "Caerphilly County Borough",
		"iso": "GB",
		"NumberOfSessions": 10
	},
	{
		"lat": 51.55192555745072,
		"lon": -0.2498305556115432,
		"name": "Brent",
		"iso": "GB",
		"NumberOfSessions": 21
	},
	{
		"lat": 53.52258383815284,
		"lon": -2.60541839358239,
		"name": "Wigan",
		"iso": "GB",
		"NumberOfSessions": 21
	},
	{
		"lat": 51.64866390850472,
		"lon": -0.07834664235015984,
		"name": "Enfield",
		"iso": "GB",
		"NumberOfSessions": 8
	},
	{
		"lat": 57.15379376374257,
		"lon": -2.1172442245696104,
		"name": "Aberdeen City",
		"iso": "GB",
		"NumberOfSessions": 8
	},
	{
		"lat": 58.9705,
		"lon": -2.9655,
		"name": "Orkney Islands",
		"iso": "GB",
		"NumberOfSessions": 1
	},
	{
		"lat": 51.449912886996806,
		"lon": -0.18040052162983553,
		"name": "Wandsworth",
		"iso": "GB",
		"NumberOfSessions": 21
	},
	{
		"lat": 53.53732980966995,
		"lon": -2.1096466287974462,
		"name": "Oldham",
		"iso": "GB",
		"NumberOfSessions": 7
	},
	{
		"lat": 57.65183564050779,
		"lon": -3.415407818452831,
		"name": "Moray",
		"iso": "GB",
		"NumberOfSessions": 3
	},
	{
		"lat": 53.226578425046,
		"lon": -3.407691394298824,
		"name": "Denbighshire",
		"iso": "GB",
		"NumberOfSessions": 3
	},
	{
		"lat": 51.55885503132784,
		"lon": 0.06973768126848033,
		"name": "Redbridge",
		"iso": "GB",
		"NumberOfSessions": 59
	},
	{
		"lat": 54.57890365093002,
		"lon": -1.045342351009271,
		"name": "Redcar and Cleveland",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 51.52712190290918,
		"lon": -0.15630406670654637,
		"name": "Camden",
		"iso": "GB",
		"NumberOfSessions": 74
	},
	{
		"lat": 53.18965418911882,
		"lon": -1.1545638667158606,
		"name": "Nottinghamshire",
		"iso": "GB",
		"NumberOfSessions": 31
	},
	{
		"lat": 50.456516601182024,
		"lon": -3.549353527090327,
		"name": "Torbay",
		"iso": "GB",
		"NumberOfSessions": 7
	},
	{
		"lat": 52.1121431920137,
		"lon": -0.48886225585479226,
		"name": "Bedford",
		"iso": "GB",
		"NumberOfSessions": 10
	},
	{
		"lat": 54.564225309429744,
		"lon": -1.3262113191040004,
		"name": "Stockton-on-Tees",
		"iso": "GB",
		"NumberOfSessions": 21
	},
	{
		"lat": 50.84387234565151,
		"lon": 0.3426879748766622,
		"name": "East Sussex",
		"iso": "GB",
		"NumberOfSessions": 20
	},
	{
		"lat": 53.53472867544864,
		"lon": -1.4575621099713718,
		"name": "Barnsley",
		"iso": "GB",
		"NumberOfSessions": 4
	},
	{
		"lat": 52.04608518404615,
		"lon": -2.6624351446834127,
		"name": "Herefordshire",
		"iso": "GB",
		"NumberOfSessions": 6
	},
	{
		"lat": 52.59105115621544,
		"lon": -2.142143170955602,
		"name": "Wolverhampton",
		"iso": "GB",
		"NumberOfSessions": 16
	},
	{
		"lat": 51.804157150523835,
		"lon": -0.23822547691447027,
		"name": "Hertfordshire",
		"iso": "GB",
		"NumberOfSessions": 65
	},
	{
		"lat": 54.450834249803655,
		"lon": -6.308031777851683,
		"name": "Armagh City Banbridge and Craigavon",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 52.497728865443584,
		"lon": -1.879412369400582,
		"name": "Birmingham",
		"iso": "GB",
		"NumberOfSessions": 82
	},
	{
		"lat": 51.53472309467846,
		"lon": 0.0934642418888441,
		"name": "Barking and Dagenham",
		"iso": "GB",
		"NumberOfSessions": 9
	},
	{
		"lat": 53.81791874709358,
		"lon": -3.014930215410307,
		"name": "Blackpool",
		"iso": "GB",
		"NumberOfSessions": 6
	},
	{
		"lat": 54.847608016773066,
		"lon": -6.1058204695213725,
		"name": "Mid and East Antrim",
		"iso": "GB",
		"NumberOfSessions": 4
	},
	{
		"lat": 55.919,
		"lon": -2.7551999999999994,
		"name": "East Lothian",
		"iso": "GB",
		"NumberOfSessions": 2
	},
	{
		"lat": 53.73630000000001,
		"lon": -2.003899999999999,
		"name": "Calderdale",
		"iso": "GB",
		"NumberOfSessions": 1
	},
	{
		"lat": 53.385939020959775,
		"lon": -3.034854423330403,
		"name": "Wirral",
		"iso": "GB",
		"NumberOfSessions": 9
	},
	{
		"lat": 51.60096816867769,
		"lon": 0.18323651480193015,
		"name": "Havering",
		"iso": "GB",
		"NumberOfSessions": 14
	},
	{
		"lat": 51.476616746384536,
		"lon": -0.3674166416248876,
		"name": "Hounslow",
		"iso": "GB",
		"NumberOfSessions": 20
	},
	{
		"lat": 51.02381449354349,
		"lon": -2.7741903483312216,
		"name": "Somerset",
		"iso": "GB",
		"NumberOfSessions": 29
	},
	{
		"lat": 51.77630142831869,
		"lon": -3.222651626566861,
		"name": "Blaenau Gwent",
		"iso": "GB",
		"NumberOfSessions": 2
	},
	{
		"lat": 51.51387830498067,
		"lon": -3.6186938757465077,
		"name": "County Borough of Bridgend",
		"iso": "GB",
		"NumberOfSessions": 3
	},
	{
		"lat": 56.25095296049107,
		"lon": -4.357751853438202,
		"name": "Stirling",
		"iso": "GB",
		"NumberOfSessions": 2
	},
	{
		"lat": 51.49989620540093,
		"lon": -0.19182417504053056,
		"name": "Royal Kensington and Chelsea",
		"iso": "GB",
		"NumberOfSessions": 24
	},
	{
		"lat": 50.84213243830171,
		"lon": -0.16697920823481643,
		"name": "Brighton and Hove",
		"iso": "GB",
		"NumberOfSessions": 14
	},
	{
		"lat": 52.03531244620399,
		"lon": -0.7419921605125859,
		"name": "Milton Keynes",
		"iso": "GB",
		"NumberOfSessions": 30
	},
	{
		"lat": 53.5758336137035,
		"lon": -2.397171359736504,
		"name": "Bolton",
		"iso": "GB",
		"NumberOfSessions": 13
	},
	{
		"lat": 51.362730472299035,
		"lon": -0.17199356507045244,
		"name": "Sutton",
		"iso": "GB",
		"NumberOfSessions": 24
	},
	{
		"lat": 55.958794845661295,
		"lon": -4.525793079457798,
		"name": "West Dunbartonshire",
		"iso": "GB",
		"NumberOfSessions": 3
	},
	{
		"lat": 52.151240843847994,
		"lon": 1.0319148172049644,
		"name": "Suffolk",
		"iso": "GB",
		"NumberOfSessions": 24
	},
	{
		"lat": 51.58242341636379,
		"lon": -0.3764745710702738,
		"name": "Harrow",
		"iso": "GB",
		"NumberOfSessions": 15
	},
	{
		"lat": 56.504628268680584,
		"lon": -3.0145923979014824,
		"name": "Dundee City",
		"iso": "GB",
		"NumberOfSessions": 11
	},
	{
		"lat": 51.13285210983634,
		"lon": -1.1315601031981615,
		"name": "Hampshire",
		"iso": "GB",
		"NumberOfSessions": 45
	},
	{
		"lat": 52.329473209506084,
		"lon": 0.10294280152401572,
		"name": "Cambridgeshire",
		"iso": "GB",
		"NumberOfSessions": 34
	},
	{
		"lat": 50.949567084315866,
		"lon": -0.3503617989320459,
		"name": "West Sussex",
		"iso": "GB",
		"NumberOfSessions": 33
	},
	{
		"lat": 53.489522687570386,
		"lon": -2.2836915414830274,
		"name": "Salford",
		"iso": "GB",
		"NumberOfSessions": 15
	},
	{
		"lat": 51.49912361590612,
		"lon": 0.3674307290072453,
		"name": "Thurrock",
		"iso": "GB",
		"NumberOfSessions": 7
	},
	{
		"lat": 53.428629367238955,
		"lon": -1.3090028229225958,
		"name": "Rotherham",
		"iso": "GB",
		"NumberOfSessions": 13
	},
	{
		"lat": 53.51863032962854,
		"lon": -1.0973156297410067,
		"name": "Doncaster",
		"iso": "GB",
		"NumberOfSessions": 14
	},
	{
		"lat": 54.559206569283205,
		"lon": -1.207875472464599,
		"name": "Middlesbrough",
		"iso": "GB",
		"NumberOfSessions": 8
	},
	{
		"lat": 51.51744440099517,
		"lon": -0.16073274980771587,
		"name": "City of Westminster",
		"iso": "GB",
		"NumberOfSessions": 22
	},
	{
		"lat": 53.479102847554664,
		"lon": -2.0924226279948788,
		"name": "Tameside",
		"iso": "GB",
		"NumberOfSessions": 3
	},
	{
		"lat": 53.289,
		"lon": -3.7684000000000006,
		"name": "Conwy",
		"iso": "GB",
		"NumberOfSessions": 1
	},
	{
		"lat": 55.85737703588852,
		"lon": -4.269657048351979,
		"name": "Glasgow City",
		"iso": "GB",
		"NumberOfSessions": 85
	},
	{
		"lat": 51.47520597760201,
		"lon": 0.04177788997591878,
		"name": "Greenwich",
		"iso": "GB",
		"NumberOfSessions": 14
	},
	{
		"lat": 50.467128140085514,
		"lon": -4.439587563273153,
		"name": "Cornwall",
		"iso": "GB",
		"NumberOfSessions": 51
	},
	{
		"lat": 53.255772011442005,
		"lon": -2.795682332145063,
		"name": "Cheshire",
		"iso": "GB",
		"NumberOfSessions": 22
	},
	{
		"lat": 53.2069,
		"lon": -4.1184,
		"name": "Gwynedd",
		"iso": "GB",
		"NumberOfSessions": 1
	},
	{
		"lat": 51.67884069302734,
		"lon": -3.0251303714393956,
		"name": "Torfaen County Borough",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 54.544666693958135,
		"lon": -5.9742604094836445,
		"name": "Belfast",
		"iso": "GB",
		"NumberOfSessions": 27
	},
	{
		"lat": 51.72720124812629,
		"lon": -2.693818663187092,
		"name": "Monmouthshire",
		"iso": "GB",
		"NumberOfSessions": 2
	},
	{
		"lat": 55.1181,
		"lon": -6.334800000000001,
		"name": "Causeway Coast and Glens",
		"iso": "GB",
		"NumberOfSessions": 2
	},
	{
		"lat": 55.82060449248899,
		"lon": -3.965781661845273,
		"name": "North Lanarkshire",
		"iso": "GB",
		"NumberOfSessions": 16
	},
	{
		"lat": 52.76446109457278,
		"lon": -1.8116328047693302,
		"name": "Staffordshire",
		"iso": "GB",
		"NumberOfSessions": 20
	},
	{
		"lat": 53.588162529998705,
		"lon": -2.1767761314580563,
		"name": "Rochdale",
		"iso": "GB",
		"NumberOfSessions": 8
	},
	{
		"lat": 53.68582318792334,
		"lon": -1.418474264643476,
		"name": "Wakefield",
		"iso": "GB",
		"NumberOfSessions": 10
	},
	{
		"lat": 53.62463625751453,
		"lon": -3.0016158362314656,
		"name": "Sefton",
		"iso": "GB",
		"NumberOfSessions": 4
	},
	{
		"lat": 51.72924836043602,
		"lon": 0.5028125742860727,
		"name": "Essex",
		"iso": "GB",
		"NumberOfSessions": 61
	},
	{
		"lat": 51.568815812358615,
		"lon": -1.7768311713929024,
		"name": "Swindon",
		"iso": "GB",
		"NumberOfSessions": 20
	},
	{
		"lat": 54.53870276498703,
		"lon": -1.5605646944043303,
		"name": "Darlington",
		"iso": "GB",
		"NumberOfSessions": 6
	},
	{
		"lat": 52.62734614632443,
		"lon": 1.1692928635315523,
		"name": "Norfolk",
		"iso": "GB",
		"NumberOfSessions": 31
	},
	{
		"lat": 51.46937813319774,
		"lon": 0.15232860062581785,
		"name": "Bexley",
		"iso": "GB",
		"NumberOfSessions": 8
	},
	{
		"lat": 52.59395271221006,
		"lon": -1.9766011485125141,
		"name": "Walsall",
		"iso": "GB",
		"NumberOfSessions": 8
	},
	{
		"lat": 54.764680912979394,
		"lon": -3.0957235266626313,
		"name": "Cumbria",
		"iso": "GB",
		"NumberOfSessions": 24
	},
	{
		"lat": 54.94890508794419,
		"lon": -1.6027404050352152,
		"name": "Gateshead",
		"iso": "GB",
		"NumberOfSessions": 6
	},
	{
		"lat": 53.148936583513226,
		"lon": -2.3372233015777444,
		"name": "Cheshire East",
		"iso": "GB",
		"NumberOfSessions": 10
	},
	{
		"lat": 52.72039999999999,
		"lon": -0.6845999999999998,
		"name": "Rutland",
		"iso": "GB",
		"NumberOfSessions": 1
	},
	{
		"lat": 53.55210430445596,
		"lon": -0.06560781159866012,
		"name": "North East Lincolnshire",
		"iso": "GB",
		"NumberOfSessions": 5
	},
	{
		"lat": 51.4036,
		"lon": -0.7618,
		"name": "Bracknell Forest",
		"iso": "GB",
		"NumberOfSessions": 1
	},
	{
		"lat": 53.225,
		"lon": -4.2735,
		"name": "Anglesey",
		"iso": "GB",
		"NumberOfSessions": 1
	},
	{
		"lat": 51.7106,
		"lon": -3.6988000000000003,
		"name": "Neath Port Talbot",
		"iso": "GB",
		"NumberOfSessions": 1
	},
	{
		"lat": 53.3006,
		"lon": -2.6604,
		"name": "Halton",
		"iso": "GB",
		"NumberOfSessions": 4
	},
	{
		"lat": 51.54672963348256,
		"lon": -0.0586060580104849,
		"name": "Hackney",
		"iso": "GB",
		"NumberOfSessions": 14
	},
	{
		"lat": 56.109450814208984,
		"lon": -3.3215162351019725,
		"name": "Fife",
		"iso": "GB",
		"NumberOfSessions": 32
	},
	{
		"lat": 55.4754,
		"lon": -4.6062,
		"name": "South Ayrshire",
		"iso": "GB",
		"NumberOfSessions": 4
	},
	{
		"lat": 50.73834989043233,
		"lon": -1.9106504318418247,
		"name": "Bournemouth, Christchurch and Poole Council",
		"iso": "GB",
		"NumberOfSessions": 24
	},
	{
		"lat": 51.30370346842348,
		"lon": -0.5034395842138871,
		"name": "Surrey",
		"iso": "GB",
		"NumberOfSessions": 35
	},
	{
		"lat": 53.57435092563529,
		"lon": -2.3162080523027404,
		"name": "Borough of Bury",
		"iso": "GB",
		"NumberOfSessions": 2
	},
	{
		"lat": 37.7557,
		"lon": 26.9724,
		"name": "Samos",
		"iso": "GR",
		"NumberOfSessions": 1
	},
	{
		"lat": 40.84089999999999,
		"lon": 25.873699999999992,
		"name": "Evros",
		"iso": "GR",
		"NumberOfSessions": 1
	},
	{
		"lat": 40.2969,
		"lon": 21.785500000000003,
		"name": "Kozani",
		"iso": "GR",
		"NumberOfSessions": 2
	},
	{
		"lat": 38.369,
		"lon": 26.139899999999997,
		"name": "Chios",
		"iso": "GR",
		"NumberOfSessions": 1
	},
	{
		"lat": 38.9007,
		"lon": 22.426000000000002,
		"name": "Phthiotis",
		"iso": "GR",
		"NumberOfSessions": 2
	},
	{
		"lat": 37.0799,
		"lon": 22.4282,
		"name": "Laconia",
		"iso": "GR",
		"NumberOfSessions": 2
	},
	{
		"lat": 37.981752329221806,
		"lon": 23.731031735490163,
		"name": "Attica",
		"iso": "GR",
		"NumberOfSessions": 111
	},
	{
		"lat": 39.667200000000015,
		"lon": 20.859900000000003,
		"name": "Ioannina",
		"iso": "GR",
		"NumberOfSessions": 2
	},
	{
		"lat": 38.2475,
		"lon": 21.7311,
		"name": "Achaea",
		"iso": "GR",
		"NumberOfSessions": 3
	},
	{
		"lat": 35.51740000000001,
		"lon": 24.0169,
		"name": "Chania",
		"iso": "GR",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.3272,
		"lon": 25.1371,
		"name": "Heraklion Regional Unit",
		"iso": "GR",
		"NumberOfSessions": 2
	},
	{
		"lat": 37.79189999999999,
		"lon": 20.900399999999994,
		"name": "Nomós Zakýnthou",
		"iso": "GR",
		"NumberOfSessions": 1
	},
	{
		"lat": 37.03880000000001,
		"lon": 22.1078,
		"name": "Messenia",
		"iso": "GR",
		"NumberOfSessions": 1
	},
	{
		"lat": 39.923,
		"lon": 21.798999999999996,
		"name": "Grevena",
		"iso": "GR",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.3679,
		"lon": 24.467000000000006,
		"name": "Rethymno",
		"iso": "GR",
		"NumberOfSessions": 1
	},
	{
		"lat": 40.643900000000016,
		"lon": 22.93580000000001,
		"name": "Thessaloniki",
		"iso": "GR",
		"NumberOfSessions": 30
	},
	{
		"lat": 46.3862,
		"lon": 16.4308,
		"name": "Međimurje County",
		"iso": "HR",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.29275975208212,
		"lon": 16.29098872923146,
		"name": "Varaždin County",
		"iso": "HR",
		"NumberOfSessions": 2
	},
	{
		"lat": 45.4675,
		"lon": 16.3868,
		"name": "Sisak-Moslavina County",
		"iso": "HR",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.743258053092106,
		"lon": 15.949521598903996,
		"name": "Zagreb County",
		"iso": "HR",
		"NumberOfSessions": 3
	},
	{
		"lat": 44.8194,
		"lon": 13.9312,
		"name": "Istria County",
		"iso": "HR",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.622410088394375,
		"lon": 18.66109141886735,
		"name": "County of Osijek-Baranja",
		"iso": "HR",
		"NumberOfSessions": 3
	},
	{
		"lat": 43.5098,
		"lon": 16.441099999999995,
		"name": "Split-Dalmatia County",
		"iso": "HR",
		"NumberOfSessions": 2
	},
	{
		"lat": 45.8287823748183,
		"lon": 15.980624603345342,
		"name": "City of Zagreb",
		"iso": "HR",
		"NumberOfSessions": 50
	},
	{
		"lat": 46.1622,
		"lon": 16.830699999999997,
		"name": "Koprivnica-Križevci County",
		"iso": "HR",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.715787390074546,
		"lon": 19.578669199221764,
		"name": "Bács-Kiskun",
		"iso": "HU",
		"NumberOfSessions": 6
	},
	{
		"lat": 46.55122951322579,
		"lon": 20.749837487992508,
		"name": "Bekes County",
		"iso": "HU",
		"NumberOfSessions": 3
	},
	{
		"lat": 46.49242548283608,
		"lon": 20.1660893352766,
		"name": "Csongrád megye",
		"iso": "HU",
		"NumberOfSessions": 27
	},
	{
		"lat": 48.09771897731117,
		"lon": 21.008346351207148,
		"name": "Borsod-Abaúj-Zemplén",
		"iso": "HU",
		"NumberOfSessions": 11
	},
	{
		"lat": 46.58040978135987,
		"lon": 18.737476825721682,
		"name": "Tolna County",
		"iso": "HU",
		"NumberOfSessions": 5
	},
	{
		"lat": 47.07106140192764,
		"lon": 17.90306070669181,
		"name": "Veszprém megye",
		"iso": "HU",
		"NumberOfSessions": 5
	},
	{
		"lat": 47.2200140442814,
		"lon": 18.58938998906801,
		"name": "Fejér",
		"iso": "HU",
		"NumberOfSessions": 9
	},
	{
		"lat": 47.57346898630286,
		"lon": 21.62263666737002,
		"name": "Hajdú-Bihar",
		"iso": "HU",
		"NumberOfSessions": 12
	},
	{
		"lat": 47.23585687283174,
		"lon": 20.28853845151394,
		"name": "Jász-Nagykun-Szolnok",
		"iso": "HU",
		"NumberOfSessions": 9
	},
	{
		"lat": 46.707779192116334,
		"lon": 16.92827792283918,
		"name": "Zala County",
		"iso": "HU",
		"NumberOfSessions": 5
	},
	{
		"lat": 47.71109109960426,
		"lon": 17.42934080636577,
		"name": "Győr-Moson-Sopron",
		"iso": "HU",
		"NumberOfSessions": 7
	},
	{
		"lat": 47.56359999999998,
		"lon": 19.094700000000042,
		"name": "Budapest",
		"iso": "HU",
		"NumberOfSessions": 112
	},
	{
		"lat": 47.99166408906418,
		"lon": 21.966742621514094,
		"name": "Szabolcs-Szatmár-Bereg",
		"iso": "HU",
		"NumberOfSessions": 5
	},
	{
		"lat": 47.515215969168565,
		"lon": 19.147165998451104,
		"name": "Pest County",
		"iso": "HU",
		"NumberOfSessions": 17
	},
	{
		"lat": 46.07524579998364,
		"lon": 18.309627532311232,
		"name": "Baranya",
		"iso": "HU",
		"NumberOfSessions": 6
	},
	{
		"lat": 47.77416462301096,
		"lon": 20.051458831978376,
		"name": "Heves County",
		"iso": "HU",
		"NumberOfSessions": 5
	},
	{
		"lat": 46.3682,
		"lon": 17.7945,
		"name": "Somogy County",
		"iso": "HU",
		"NumberOfSessions": 4
	},
	{
		"lat": 47.23797527825802,
		"lon": 16.669318189701954,
		"name": "Vas County",
		"iso": "HU",
		"NumberOfSessions": 2
	},
	{
		"lat": -2.2074000000000003,
		"lon": 113.9164,
		"name": "Central Kalimantan",
		"iso": "ID",
		"NumberOfSessions": 1
	},
	{
		"lat": 1.481,
		"lon": 124.85179999999998,
		"name": "North Sulawesi",
		"iso": "ID",
		"NumberOfSessions": 1
	},
	{
		"lat": -2.9146,
		"lon": 104.7535,
		"name": "South Sumatra",
		"iso": "ID",
		"NumberOfSessions": 4
	},
	{
		"lat": -3.321899999999999,
		"lon": 114.5871,
		"name": "South Kalimantan",
		"iso": "ID",
		"NumberOfSessions": 4
	},
	{
		"lat": -6.764353745001317,
		"lon": 107.39439098752683,
		"name": "West Java",
		"iso": "ID",
		"NumberOfSessions": 35
	},
	{
		"lat": -5.214563502836859,
		"lon": 105.13070531662346,
		"name": "Lampung",
		"iso": "ID",
		"NumberOfSessions": 2
	},
	{
		"lat": -8.650699999999999,
		"lon": 115.2124,
		"name": "Bali",
		"iso": "ID",
		"NumberOfSessions": 6
	},
	{
		"lat": -0.9497000000000001,
		"lon": 100.3505,
		"name": "West Sumatra",
		"iso": "ID",
		"NumberOfSessions": 1
	},
	{
		"lat": -6.213868489614379,
		"lon": 106.65959561237585,
		"name": "Banten",
		"iso": "ID",
		"NumberOfSessions": 3
	},
	{
		"lat": -6.216851968656115,
		"lon": 106.84364439364784,
		"name": "Jakarta",
		"iso": "ID",
		"NumberOfSessions": 27
	},
	{
		"lat": -5.151999999999999,
		"lon": 119.437,
		"name": "South Sulawesi",
		"iso": "ID",
		"NumberOfSessions": 5
	},
	{
		"lat": 0.5395,
		"lon": 101.4457,
		"name": "Riau",
		"iso": "ID",
		"NumberOfSessions": 2
	},
	{
		"lat": -0.030999999999999986,
		"lon": 109.3257,
		"name": "West Kalimantan",
		"iso": "ID",
		"NumberOfSessions": 3
	},
	{
		"lat": -7.203641419086717,
		"lon": 110.08109283243967,
		"name": "Central Java",
		"iso": "ID",
		"NumberOfSessions": 7
	},
	{
		"lat": 3.5847000000000007,
		"lon": 98.6629,
		"name": "North Sumatra",
		"iso": "ID",
		"NumberOfSessions": 4
	},
	{
		"lat": 5.5418,
		"lon": 95.34130000000002,
		"name": "Aceh",
		"iso": "ID",
		"NumberOfSessions": 1
	},
	{
		"lat": -7.615503537236072,
		"lon": 112.68639758781599,
		"name": "East Java",
		"iso": "ID",
		"NumberOfSessions": 2
	},
	{
		"lat": -10.1676,
		"lon": 123.6107,
		"name": "East Nusa Tenggara",
		"iso": "ID",
		"NumberOfSessions": 1
	},
	{
		"lat": 52.8501,
		"lon": -6.2123,
		"name": "County Wicklow",
		"iso": "IE",
		"NumberOfSessions": 1
	},
	{
		"lat": 53.4219,
		"lon": -7.9384999999999994,
		"name": "County Westmeath",
		"iso": "IE",
		"NumberOfSessions": 1
	},
	{
		"lat": 51.89592398779297,
		"lon": -8.459907614630197,
		"name": "County Cork",
		"iso": "IE",
		"NumberOfSessions": 5
	},
	{
		"lat": 53.81080723154104,
		"lon": -6.371564726675109,
		"name": "Louth",
		"iso": "IE",
		"NumberOfSessions": 3
	},
	{
		"lat": 53.726600000000005,
		"lon": -7.796499999999999,
		"name": "Co Longford",
		"iso": "IE",
		"NumberOfSessions": 1
	},
	{
		"lat": 53.06004032907978,
		"lon": -7.303834870390492,
		"name": "Laois",
		"iso": "IE",
		"NumberOfSessions": 5
	},
	{
		"lat": 53.268900000000016,
		"lon": -9.053300000000004,
		"name": "County Galway",
		"iso": "IE",
		"NumberOfSessions": 1
	},
	{
		"lat": 53.427432319309816,
		"lon": -6.471126754207568,
		"name": "Meath",
		"iso": "IE",
		"NumberOfSessions": 20
	},
	{
		"lat": 54.1032,
		"lon": -7.447600000000002,
		"name": "Cavan",
		"iso": "IE",
		"NumberOfSessions": 1
	},
	{
		"lat": 52.6669,
		"lon": -8.627400000000002,
		"name": "Limerick",
		"iso": "IE",
		"NumberOfSessions": 3
	},
	{
		"lat": 54.1162,
		"lon": -9.1656,
		"name": "County Mayo",
		"iso": "IE",
		"NumberOfSessions": 1
	},
	{
		"lat": 53.33799286405186,
		"lon": -6.259217280745784,
		"name": "Leinster",
		"iso": "IE",
		"NumberOfSessions": 10133
	},
	{
		"lat": 11.878500000000004,
		"lon": 79.81910000000002,
		"name": "Union Territory of Puducherry",
		"iso": "IN",
		"NumberOfSessions": 1
	},
	{
		"lat": 23.02379485855059,
		"lon": 76.72868523697441,
		"name": "Madhya Pradesh",
		"iso": "IN",
		"NumberOfSessions": 28
	},
	{
		"lat": 26.905458381110883,
		"lon": 75.53593922417474,
		"name": "Rajasthan",
		"iso": "IN",
		"NumberOfSessions": 15
	},
	{
		"lat": 31.103399999999997,
		"lon": 77.1659,
		"name": "Himachal Pradesh",
		"iso": "IN",
		"NumberOfSessions": 1
	},
	{
		"lat": 22.720235789103583,
		"lon": 88.3310387786304,
		"name": "West Bengal",
		"iso": "IN",
		"NumberOfSessions": 27
	},
	{
		"lat": 9.97383394120128,
		"lon": 76.2792827093926,
		"name": "Kerala",
		"iso": "IN",
		"NumberOfSessions": 22
	},
	{
		"lat": 28.833535195293802,
		"lon": 76.9628365526379,
		"name": "Haryana",
		"iso": "IN",
		"NumberOfSessions": 6
	},
	{
		"lat": 17.372400000000003,
		"lon": 78.4378,
		"name": "Telangana",
		"iso": "IN",
		"NumberOfSessions": 25
	},
	{
		"lat": 27.25926874260677,
		"lon": 79.99987136767757,
		"name": "Uttar Pradesh",
		"iso": "IN",
		"NumberOfSessions": 23
	},
	{
		"lat": 21.2129,
		"lon": 81.4294,
		"name": "Chhattisgarh",
		"iso": "IN",
		"NumberOfSessions": 1
	},
	{
		"lat": 30.327500000000004,
		"lon": 78.03250000000001,
		"name": "Uttarakhand",
		"iso": "IN",
		"NumberOfSessions": 1
	},
	{
		"lat": 19.004215858943052,
		"lon": 73.46395821927335,
		"name": "Maharashtra",
		"iso": "IN",
		"NumberOfSessions": 55
	},
	{
		"lat": 22.324589014193077,
		"lon": 72.31583797305939,
		"name": "Gujarat",
		"iso": "IN",
		"NumberOfSessions": 26
	},
	{
		"lat": 32.732400000000005,
		"lon": 74.8799,
		"name": "Jammu and Kashmir",
		"iso": "IN",
		"NumberOfSessions": 1
	},
	{
		"lat": 30.7339,
		"lon": 76.7889,
		"name": "Chandigarh",
		"iso": "IN",
		"NumberOfSessions": 3
	},
	{
		"lat": 24.8103,
		"lon": 93.939,
		"name": "Manipur",
		"iso": "IN",
		"NumberOfSessions": 1
	},
	{
		"lat": 28.651330107658275,
		"lon": 77.19483519726946,
		"name": "National Capital Territory of Delhi",
		"iso": "IN",
		"NumberOfSessions": 31
	},
	{
		"lat": 25.590800000000005,
		"lon": 85.1348,
		"name": "Bihar",
		"iso": "IN",
		"NumberOfSessions": 5
	},
	{
		"lat": 23.728500000000004,
		"lon": 92.71880000000002,
		"name": "Mizoram",
		"iso": "IN",
		"NumberOfSessions": 1
	},
	{
		"lat": 12.120142643846323,
		"lon": 79.07735231391945,
		"name": "Tamil Nadu",
		"iso": "IN",
		"NumberOfSessions": 15
	},
	{
		"lat": 23.01899623185545,
		"lon": 85.83572486077595,
		"name": "Jharkhand",
		"iso": "IN",
		"NumberOfSessions": 5
	},
	{
		"lat": 16.993354539900842,
		"lon": 81.83322537815229,
		"name": "Andhra Pradesh",
		"iso": "IN",
		"NumberOfSessions": 10
	},
	{
		"lat": 26.120600000000003,
		"lon": 91.6523,
		"name": "Assam",
		"iso": "IN",
		"NumberOfSessions": 7
	},
	{
		"lat": 20.27060000000001,
		"lon": 85.8334,
		"name": "Odisha",
		"iso": "IN",
		"NumberOfSessions": 3
	},
	{
		"lat": 31.144435114906535,
		"lon": 75.88913770187071,
		"name": "Punjab",
		"iso": "IN",
		"NumberOfSessions": 7
	},
	{
		"lat": 13.042509199669892,
		"lon": 77.41401035140339,
		"name": "Karnataka",
		"iso": "IN",
		"NumberOfSessions": 30
	},
	{
		"lat": 45.474199999999975,
		"lon": 9.199399999999992,
		"name": "Lombardy",
		"iso": "IT",
		"NumberOfSessions": 40
	},
	{
		"lat": 45.64695738292323,
		"lon": 8.917940908092808,
		"name": "Province of Varese",
		"iso": "IT",
		"NumberOfSessions": 6
	},
	{
		"lat": 37.09676231689715,
		"lon": 14.990699607955374,
		"name": "Syracuse",
		"iso": "IT",
		"NumberOfSessions": 4
	},
	{
		"lat": 44.0591015992655,
		"lon": 10.12115886197246,
		"name": "Province of Massa-Carrara",
		"iso": "IT",
		"NumberOfSessions": 2
	},
	{
		"lat": 44.799,
		"lon": 10.3234,
		"name": "Province of Parma",
		"iso": "IT",
		"NumberOfSessions": 2
	},
	{
		"lat": 38.12168674461811,
		"lon": 13.33978527865683,
		"name": "Province of Palermo",
		"iso": "IT",
		"NumberOfSessions": 11
	},
	{
		"lat": 44.70194729157743,
		"lon": 10.610259638030302,
		"name": "Province of Reggio Emilia",
		"iso": "IT",
		"NumberOfSessions": 5
	},
	{
		"lat": 45.741656476436994,
		"lon": 12.337243279579798,
		"name": "Province of Treviso",
		"iso": "IT",
		"NumberOfSessions": 8
	},
	{
		"lat": 45.6482,
		"lon": 13.7747,
		"name": "Trieste",
		"iso": "IT",
		"NumberOfSessions": 2
	},
	{
		"lat": 42.4545432168842,
		"lon": 14.17353112521092,
		"name": "Province of Pescara",
		"iso": "IT",
		"NumberOfSessions": 6
	},
	{
		"lat": 41.3126,
		"lon": 16.2838,
		"name": "Province of Barletta-Andria-Trani",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 42.4172,
		"lon": 12.101799999999995,
		"name": "Province of Viterbo",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 43.8828,
		"lon": 11.1008,
		"name": "Provincia di Prato",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 40.289,
		"lon": 18.2991,
		"name": "Provincia di Lecce",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.424010518805865,
		"lon": 11.891109907695242,
		"name": "Padua",
		"iso": "IT",
		"NumberOfSessions": 12
	},
	{
		"lat": 45.219950658534835,
		"lon": 8.943433543099403,
		"name": "Province of Pavia",
		"iso": "IT",
		"NumberOfSessions": 2
	},
	{
		"lat": 41.13440000000001,
		"lon": 14.7746,
		"name": "Province of Benevento",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 36.87876342129274,
		"lon": 14.787769696226283,
		"name": "Province of Ragusa",
		"iso": "IT",
		"NumberOfSessions": 3
	},
	{
		"lat": 43.8397,
		"lon": 13.0125,
		"name": "Pesaro and Urbino",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.570299999999996,
		"lon": 8.184699999999998,
		"name": "Province of Biella",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 44.22509999999999,
		"lon": 12.046999999999999,
		"name": "Province of Forlì-Cesena",
		"iso": "IT",
		"NumberOfSessions": 5
	},
	{
		"lat": 45.4031,
		"lon": 11.8747,
		"name": "Veneto",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.471376187596086,
		"lon": 9.188202783216381,
		"name": "Milan",
		"iso": "IT",
		"NumberOfSessions": 199
	},
	{
		"lat": 44.723000000000006,
		"lon": 11.285399999999997,
		"name": "Province of Ferrara",
		"iso": "IT",
		"NumberOfSessions": 2
	},
	{
		"lat": 38.7541,
		"lon": 16.1822,
		"name": "Province of Vibo Valentia",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.587198172880505,
		"lon": 9.672004775488729,
		"name": "Province of Bergamo",
		"iso": "IT",
		"NumberOfSessions": 14
	},
	{
		"lat": 40.52945031509359,
		"lon": 17.235058643783304,
		"name": "Province of Taranto",
		"iso": "IT",
		"NumberOfSessions": 2
	},
	{
		"lat": 45.091454496935796,
		"lon": 7.663245918136748,
		"name": "Turin",
		"iso": "IT",
		"NumberOfSessions": 42
	},
	{
		"lat": 42.598961762082546,
		"lon": 14.081467513922101,
		"name": "Province of Teramo",
		"iso": "IT",
		"NumberOfSessions": 5
	},
	{
		"lat": 39.2074,
		"lon": 9.1095,
		"name": "South Sardinia",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.40189151262485,
		"lon": 11.008569467317225,
		"name": "Province of Verona",
		"iso": "IT",
		"NumberOfSessions": 7
	},
	{
		"lat": 37.50298708588157,
		"lon": 15.083440620618314,
		"name": "Catania",
		"iso": "IT",
		"NumberOfSessions": 6
	},
	{
		"lat": 39.299200000000006,
		"lon": 16.2493,
		"name": "Province of Cosenza",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.20040403133771,
		"lon": 10.836051781561164,
		"name": "Province of Mantua",
		"iso": "IT",
		"NumberOfSessions": 3
	},
	{
		"lat": 41.07261071038176,
		"lon": 16.870281390722674,
		"name": "Bari",
		"iso": "IT",
		"NumberOfSessions": 11
	},
	{
		"lat": 44.636702941424524,
		"lon": 10.931742298563059,
		"name": "Province of Modena",
		"iso": "IT",
		"NumberOfSessions": 10
	},
	{
		"lat": 37.073899999999995,
		"lon": 14.237399999999996,
		"name": "Province of Caltanissetta",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.0674,
		"lon": 13.236400000000001,
		"name": "Udine",
		"iso": "IT",
		"NumberOfSessions": 2
	},
	{
		"lat": 45.7734,
		"lon": 9.2761,
		"name": "Province of Lecco",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 44.48469999999999,
		"lon": 11.327999999999998,
		"name": "Emilia-Romagna",
		"iso": "IT",
		"NumberOfSessions": 7
	},
	{
		"lat": 43.69811865354872,
		"lon": 10.663023174092721,
		"name": "Province of Pisa",
		"iso": "IT",
		"NumberOfSessions": 4
	},
	{
		"lat": 45.7522527152405,
		"lon": 9.062224764189656,
		"name": "Province of Como",
		"iso": "IT",
		"NumberOfSessions": 2
	},
	{
		"lat": 44.105699999999985,
		"lon": 9.8311,
		"name": "Province of La Spezia",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 38.16598095420471,
		"lon": 15.404371176969287,
		"name": "Province of Messina",
		"iso": "IT",
		"NumberOfSessions": 11
	},
	{
		"lat": 43.07079211498343,
		"lon": 12.508319551750997,
		"name": "Province of Perugia",
		"iso": "IT",
		"NumberOfSessions": 4
	},
	{
		"lat": 40.8325,
		"lon": 14.2548,
		"name": "Campania",
		"iso": "IT",
		"NumberOfSessions": 3
	},
	{
		"lat": 44.876099999999994,
		"lon": 7.9942,
		"name": "Province of Asti",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.27019978960267,
		"lon": 11.507743775752871,
		"name": "Province of Trente",
		"iso": "IT",
		"NumberOfSessions": 2
	},
	{
		"lat": 45.44610600612339,
		"lon": 12.406907947857013,
		"name": "Venice",
		"iso": "IT",
		"NumberOfSessions": 13
	},
	{
		"lat": 43.78500403037388,
		"lon": 11.223476139877478,
		"name": "Province of Florence",
		"iso": "IT",
		"NumberOfSessions": 14
	},
	{
		"lat": 45.5283530147622,
		"lon": 10.172195796990776,
		"name": "Province of Brescia",
		"iso": "IT",
		"NumberOfSessions": 14
	},
	{
		"lat": 45.69675000436204,
		"lon": 8.631399544877015,
		"name": "Province of Novara",
		"iso": "IT",
		"NumberOfSessions": 2
	},
	{
		"lat": 38.74560000000002,
		"lon": 16.5145,
		"name": "Province of Catanzaro",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 41.889213722161806,
		"lon": 12.521837869199327,
		"name": "Rome",
		"iso": "IT",
		"NumberOfSessions": 75
	},
	{
		"lat": 41.01789171419322,
		"lon": 14.219844727671438,
		"name": "Province of Caserta",
		"iso": "IT",
		"NumberOfSessions": 10
	},
	{
		"lat": 44.48922921791953,
		"lon": 11.382886918139672,
		"name": "Bologna",
		"iso": "IT",
		"NumberOfSessions": 24
	},
	{
		"lat": 44.583864301609644,
		"lon": 7.743286493865397,
		"name": "Province of Cuneo",
		"iso": "IT",
		"NumberOfSessions": 5
	},
	{
		"lat": 44.415307994367396,
		"lon": 12.09810187311997,
		"name": "Province of Ravenna",
		"iso": "IT",
		"NumberOfSessions": 2
	},
	{
		"lat": 40.86867674655574,
		"lon": 14.263547708960186,
		"name": "Naples",
		"iso": "IT",
		"NumberOfSessions": 52
	},
	{
		"lat": 44.38937809517195,
		"lon": 8.955042803622202,
		"name": "Province of Genoa",
		"iso": "IT",
		"NumberOfSessions": 27
	},
	{
		"lat": 46.1479,
		"lon": 12.6476,
		"name": "Province of Pordenone",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 43.05084341082967,
		"lon": 13.595396906386766,
		"name": "Province of Fermo",
		"iso": "IT",
		"NumberOfSessions": 3
	},
	{
		"lat": 43.84700000000001,
		"lon": 10.5018,
		"name": "Province of Lucca",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.60125690030288,
		"lon": 11.600241939521656,
		"name": "Province of Vicenza",
		"iso": "IT",
		"NumberOfSessions": 6
	},
	{
		"lat": 43.591163327244494,
		"lon": 11.823117656663797,
		"name": "Province of Arezzo",
		"iso": "IT",
		"NumberOfSessions": 2
	},
	{
		"lat": 40.72837135267339,
		"lon": 14.676935404799316,
		"name": "Province of Salerno",
		"iso": "IT",
		"NumberOfSessions": 3
	},
	{
		"lat": 45.597607120576235,
		"lon": 9.240128924796164,
		"name": "Monza Brianza",
		"iso": "IT",
		"NumberOfSessions": 13
	},
	{
		"lat": 44.0545,
		"lon": 12.5686,
		"name": "Province of Rimini",
		"iso": "IT",
		"NumberOfSessions": 2
	},
	{
		"lat": 43.26061348894042,
		"lon": 13.444868058179367,
		"name": "Province of Macerata",
		"iso": "IT",
		"NumberOfSessions": 9
	},
	{
		"lat": 41.933,
		"lon": 13.841999999999999,
		"name": "Province of L'Aquila",
		"iso": "IT",
		"NumberOfSessions": 3
	},
	{
		"lat": 38.11855847928782,
		"lon": 15.672544115933219,
		"name": "Province of Reggio Calabria",
		"iso": "IT",
		"NumberOfSessions": 60
	},
	{
		"lat": 43.54280000000001,
		"lon": 10.3298,
		"name": "Province of Livorno",
		"iso": "IT",
		"NumberOfSessions": 2
	},
	{
		"lat": 41.900800000000004,
		"lon": 12.4874,
		"name": "Lazio",
		"iso": "IT",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.1466,
		"lon": 132.5932,
		"name": "Shimane",
		"iso": "JP",
		"NumberOfSessions": 1
	},
	{
		"lat": 32.7968,
		"lon": 130.7687,
		"name": "Kumamoto",
		"iso": "JP",
		"NumberOfSessions": 1
	},
	{
		"lat": 39.59012320324822,
		"lon": 140.29802947120615,
		"name": "Akita",
		"iso": "JP",
		"NumberOfSessions": 2
	},
	{
		"lat": 34.710377971516095,
		"lon": 135.26433184201827,
		"name": "Hyōgo",
		"iso": "JP",
		"NumberOfSessions": 2
	},
	{
		"lat": 35.0203,
		"lon": 135.7602,
		"name": "Kyoto",
		"iso": "JP",
		"NumberOfSessions": 1
	},
	{
		"lat": 34.33160000000001,
		"lon": 134.0452,
		"name": "Kagawa",
		"iso": "JP",
		"NumberOfSessions": 2
	},
	{
		"lat": 36.216800000000006,
		"lon": 138.4859,
		"name": "Nagano",
		"iso": "JP",
		"NumberOfSessions": 1
	},
	{
		"lat": 36.5968,
		"lon": 136.5998,
		"name": "Ishikawa",
		"iso": "JP",
		"NumberOfSessions": 1
	},
	{
		"lat": 26.3297,
		"lon": 127.8047,
		"name": "Okinawa",
		"iso": "JP",
		"NumberOfSessions": 1
	},
	{
		"lat": 37.67642705127432,
		"lon": 138.9378063805861,
		"name": "Niigata",
		"iso": "JP",
		"NumberOfSessions": 2
	},
	{
		"lat": 34.111,
		"lon": 134.5724,
		"name": "Tokushima",
		"iso": "JP",
		"NumberOfSessions": 1
	},
	{
		"lat": 34.296800000000005,
		"lon": 135.3876,
		"name": "Wakayama",
		"iso": "JP",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.67366591653622,
		"lon": 139.67780395508223,
		"name": "Tokyo",
		"iso": "JP",
		"NumberOfSessions": 79
	},
	{
		"lat": 31.5649,
		"lon": 130.5601,
		"name": "Kagoshima",
		"iso": "JP",
		"NumberOfSessions": 1
	},
	{
		"lat": 34.6595,
		"lon": 133.9342,
		"name": "Okayama",
		"iso": "JP",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.179871458277155,
		"lon": 136.965047557969,
		"name": "Aichi",
		"iso": "JP",
		"NumberOfSessions": 6
	},
	{
		"lat": 43.07504006968595,
		"lon": 141.36282075980102,
		"name": "Hokkaido",
		"iso": "JP",
		"NumberOfSessions": 5
	},
	{
		"lat": 33.2337,
		"lon": 131.5928,
		"name": "Oita",
		"iso": "JP",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.56391520337335,
		"lon": 137.02971211869306,
		"name": "Gifu",
		"iso": "JP",
		"NumberOfSessions": 3
	},
	{
		"lat": 35.46283429605195,
		"lon": 139.55162614542294,
		"name": "Kanagawa",
		"iso": "JP",
		"NumberOfSessions": 18
	},
	{
		"lat": 36.35926996680318,
		"lon": 139.18741330477633,
		"name": "Gunma",
		"iso": "JP",
		"NumberOfSessions": 3
	},
	{
		"lat": 33.699545568030935,
		"lon": 130.59570662868725,
		"name": "Fukuoka",
		"iso": "JP",
		"NumberOfSessions": 5
	},
	{
		"lat": 33.836499999999994,
		"lon": 132.742,
		"name": "Ehime",
		"iso": "JP",
		"NumberOfSessions": 2
	},
	{
		"lat": 35.968390968816,
		"lon": 139.57700728061263,
		"name": "Saitama",
		"iso": "JP",
		"NumberOfSessions": 5
	},
	{
		"lat": 34.8853,
		"lon": 136.5796,
		"name": "Mie",
		"iso": "JP",
		"NumberOfSessions": 2
	},
	{
		"lat": 36.568476824686826,
		"lon": 139.66362978328917,
		"name": "Tochigi",
		"iso": "JP",
		"NumberOfSessions": 2
	},
	{
		"lat": 38.29420180112356,
		"lon": 140.86818756575633,
		"name": "Miyagi",
		"iso": "JP",
		"NumberOfSessions": 2
	},
	{
		"lat": 36.35019999999999,
		"lon": 140.4258,
		"name": "Ibaraki",
		"iso": "JP",
		"NumberOfSessions": 1
	},
	{
		"lat": 34.69778530001235,
		"lon": 135.5222335469642,
		"name": "Osaka",
		"iso": "JP",
		"NumberOfSessions": 21
	},
	{
		"lat": 35.72328739085787,
		"lon": 140.02662402062046,
		"name": "Chiba",
		"iso": "JP",
		"NumberOfSessions": 9
	},
	{
		"lat": 37.513883548761584,
		"lon": 126.68205594789057,
		"name": "Incheon",
		"iso": "KR",
		"NumberOfSessions": 10
	},
	{
		"lat": 35.87424793389103,
		"lon": 128.55489369488413,
		"name": "Daegu",
		"iso": "KR",
		"NumberOfSessions": 3
	},
	{
		"lat": 36.83430441359818,
		"lon": 126.99674205500447,
		"name": "Chungcheongnam-do",
		"iso": "KR",
		"NumberOfSessions": 7
	},
	{
		"lat": 36.807253428739244,
		"lon": 127.67940371244683,
		"name": "North Chungcheong",
		"iso": "KR",
		"NumberOfSessions": 2
	},
	{
		"lat": 35.58250000000001,
		"lon": 126.8676,
		"name": "Jeollabuk-do",
		"iso": "KR",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.5457,
		"lon": 128.499,
		"name": "Gyeongsangnam-do",
		"iso": "KR",
		"NumberOfSessions": 1
	},
	{
		"lat": 37.5492621660684,
		"lon": 126.97088387553657,
		"name": "Seoul",
		"iso": "KR",
		"NumberOfSessions": 51
	},
	{
		"lat": 37.612604541956266,
		"lon": 127.84109943275664,
		"name": "Gangwon-do",
		"iso": "KR",
		"NumberOfSessions": 2
	},
	{
		"lat": 37.57931761528818,
		"lon": 127.01344157851533,
		"name": "Gyeonggi-do",
		"iso": "KR",
		"NumberOfSessions": 52
	},
	{
		"lat": 36.41170219288821,
		"lon": 127.39221133496984,
		"name": "Daejeon",
		"iso": "KR",
		"NumberOfSessions": 3
	},
	{
		"lat": 35.191948911131895,
		"lon": 129.06887234749394,
		"name": "Busan",
		"iso": "KR",
		"NumberOfSessions": 3
	},
	{
		"lat": 43.243300000000005,
		"lon": 76.8646,
		"name": "Almaty",
		"iso": "KZ",
		"NumberOfSessions": 12
	},
	{
		"lat": 52.06489280394388,
		"lon": 76.28236966012564,
		"name": "Pavlodar Region",
		"iso": "KZ",
		"NumberOfSessions": 5
	},
	{
		"lat": 49.89433140641953,
		"lon": 73.03339234327119,
		"name": "Karaganda",
		"iso": "KZ",
		"NumberOfSessions": 8
	},
	{
		"lat": 43.642199999999995,
		"lon": 51.1668,
		"name": "Mangistauskaya Oblast'",
		"iso": "KZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 54.8735,
		"lon": 69.1491,
		"name": "North Kazakhstan",
		"iso": "KZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 44,
		"lon": 76.2833,
		"name": "Almaty Oblysy",
		"iso": "KZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 49.97130000000001,
		"lon": 82.6103,
		"name": "East Kazakhstan",
		"iso": "KZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 47.78979999999999,
		"lon": 67.7162,
		"name": "Ulytau Region",
		"iso": "KZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 51.229000000000006,
		"lon": 51.35769999999999,
		"name": "West Kazakhstan",
		"iso": "KZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 47.1169,
		"lon": 51.885299999999994,
		"name": "Atyrau Oblisi",
		"iso": "KZ",
		"NumberOfSessions": 4
	},
	{
		"lat": 51.1876,
		"lon": 71.4491,
		"name": "Astana",
		"iso": "KZ",
		"NumberOfSessions": 7
	},
	{
		"lat": 50.29569999999999,
		"lon": 57.23790000000001,
		"name": "Aktyubinskaya Oblast'",
		"iso": "KZ",
		"NumberOfSessions": 1
	},
	{
		"lat": 54.5608,
		"lon": 23.3462,
		"name": "Marijampolė Municipality",
		"iso": "LT",
		"NumberOfSessions": 1
	},
	{
		"lat": 54.83330000000001,
		"lon": 25.33330000000001,
		"name": "Vilnius",
		"iso": "LT",
		"NumberOfSessions": 1
	},
	{
		"lat": 55.248300000000015,
		"lon": 23.9688,
		"name": "Kėdainiai District Municipality",
		"iso": "LT",
		"NumberOfSessions": 1
	},
	{
		"lat": 54.9039500060505,
		"lon": 23.89290998923286,
		"name": "Kaunas",
		"iso": "LT",
		"NumberOfSessions": 20
	},
	{
		"lat": 55.9318,
		"lon": 23.3289,
		"name": "Šiauliai City Municipality",
		"iso": "LT",
		"NumberOfSessions": 1
	},
	{
		"lat": 55.7158,
		"lon": 21.110999999999997,
		"name": "Klaipėda City Municipality",
		"iso": "LT",
		"NumberOfSessions": 7
	},
	{
		"lat": 55.9781,
		"lon": 22.2491,
		"name": "Telšiai District Municipality",
		"iso": "LT",
		"NumberOfSessions": 1
	},
	{
		"lat": 54.9038,
		"lon": 23.892400000000006,
		"name": "Kupiškis District Municipality",
		"iso": "LT",
		"NumberOfSessions": 1
	},
	{
		"lat": 55.7385,
		"lon": 24.3506,
		"name": "Panevėžys City",
		"iso": "LT",
		"NumberOfSessions": 1
	},
	{
		"lat": 54.69578791877264,
		"lon": 25.299487891713774,
		"name": "Vilnius City Municipality",
		"iso": "LT",
		"NumberOfSessions": 37
	},
	{
		"lat": 54.3065,
		"lon": 25.3905,
		"name": "Šalčininkai District Municipality",
		"iso": "LT",
		"NumberOfSessions": 1
	},
	{
		"lat": 55.71399999999999,
		"lon": 21.408099999999997,
		"name": "Klaipėda District Municipality",
		"iso": "LT",
		"NumberOfSessions": 1
	},
	{
		"lat": 54.6466,
		"lon": 25.045099999999998,
		"name": "Trakai District Municipality",
		"iso": "LT",
		"NumberOfSessions": 1
	},
	{
		"lat": 57.4344,
		"lon": 25.9,
		"name": "Smiltene Municipality",
		"iso": "LV",
		"NumberOfSessions": 1
	},
	{
		"lat": 56.687200000000004,
		"lon": 23.799600000000005,
		"name": "Jelgava Municipality",
		"iso": "LV",
		"NumberOfSessions": 3
	},
	{
		"lat": 55.8822,
		"lon": 26.526800000000005,
		"name": "Daugavpils",
		"iso": "LV",
		"NumberOfSessions": 1
	},
	{
		"lat": 56.8351,
		"lon": 24.5058,
		"name": "Ogre",
		"iso": "LV",
		"NumberOfSessions": 2
	},
	{
		"lat": 56.5128,
		"lon": 21.015400000000007,
		"name": "Liepāja",
		"iso": "LV",
		"NumberOfSessions": 1
	},
	{
		"lat": 56.1654,
		"lon": 21.1558,
		"name": "South Kurzeme Municipality",
		"iso": "LV",
		"NumberOfSessions": 1
	},
	{
		"lat": 56.949600000000004,
		"lon": 24.09779999999995,
		"name": "Rīga",
		"iso": "LV",
		"NumberOfSessions": 57
	},
	{
		"lat": 56.5085,
		"lon": 27.348100000000002,
		"name": "Rēzekne",
		"iso": "LV",
		"NumberOfSessions": 2
	},
	{
		"lat": 56.9658,
		"lon": 23.7757,
		"name": "Jūrmala",
		"iso": "LV",
		"NumberOfSessions": 2
	},
	{
		"lat": 56.6001,
		"lon": 25.251299999999997,
		"name": "Aizkraukle Municipality",
		"iso": "LV",
		"NumberOfSessions": 1
	},
	{
		"lat": 33.5792,
		"lon": -7.613300000000001,
		"name": "Casablanca",
		"iso": "MA",
		"NumberOfSessions": 3
	},
	{
		"lat": 35.7758,
		"lon": -5.790899999999999,
		"name": "Tanger-Assilah",
		"iso": "MA",
		"NumberOfSessions": 3
	},
	{
		"lat": 33.663,
		"lon": -7.066600000000001,
		"name": "Benslimane Province",
		"iso": "MA",
		"NumberOfSessions": 1
	},
	{
		"lat": 34.0072,
		"lon": -6.8407,
		"name": "Rabat",
		"iso": "MA",
		"NumberOfSessions": 1
	},
	{
		"lat": 34.2619,
		"lon": -6.5821,
		"name": "Kenitra Province",
		"iso": "MA",
		"NumberOfSessions": 1
	},
	{
		"lat": 30.420100000000005,
		"lon": -9.5912,
		"name": "Agadir-Ida-ou-Tnan",
		"iso": "MA",
		"NumberOfSessions": 1
	},
	{
		"lat": 33.262,
		"lon": -7.593600000000001,
		"name": "Settat Province",
		"iso": "MA",
		"NumberOfSessions": 1
	},
	{
		"lat": 33.8916,
		"lon": -5.5396,
		"name": "Meknès Prefecture",
		"iso": "MA",
		"NumberOfSessions": 2
	},
	{
		"lat": 35.17170000000001,
		"lon": -2.9320000000000004,
		"name": "Nador",
		"iso": "MA",
		"NumberOfSessions": 1
	},
	{
		"lat": 34.0508,
		"lon": -5.0099,
		"name": "Fes",
		"iso": "MA",
		"NumberOfSessions": 3
	},
	{
		"lat": 35.8762,
		"lon": 14.5341,
		"name": "Ħaż-Żabbar",
		"iso": "MT",
		"NumberOfSessions": 5
	},
	{
		"lat": 35.9142,
		"lon": 14.425300000000004,
		"name": "Il-Mosta",
		"iso": "MT",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.8956,
		"lon": 14.516099999999998,
		"name": "Valletta",
		"iso": "MT",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.8973,
		"lon": 14.4813,
		"name": "L-Imsida",
		"iso": "MT",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.89529999999999,
		"lon": 14.478499999999997,
		"name": "Saint Venera",
		"iso": "MT",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.9546,
		"lon": 14.410300000000001,
		"name": "Saint Paul’s Bay",
		"iso": "MT",
		"NumberOfSessions": 1
	},
	{
		"lat": 35.89529999999999,
		"lon": 14.4589,
		"name": "Birkirkara",
		"iso": "MT",
		"NumberOfSessions": 1
	},
	{
		"lat": 25.428205147544503,
		"lon": -98.85352546345472,
		"name": "Tamaulipas",
		"iso": "MX",
		"NumberOfSessions": 6
	},
	{
		"lat": 29.941240751671803,
		"lon": -106.23692066564021,
		"name": "Chihuahua",
		"iso": "MX",
		"NumberOfSessions": 19
	},
	{
		"lat": 19.3244,
		"lon": -98.1821,
		"name": "Tlaxcala",
		"iso": "MX",
		"NumberOfSessions": 1
	},
	{
		"lat": 20.756100000000004,
		"lon": -105.3313,
		"name": "Nayarit",
		"iso": "MX",
		"NumberOfSessions": 1
	},
	{
		"lat": 16.740850476708253,
		"lon": -93.13327527532033,
		"name": "Chiapas",
		"iso": "MX",
		"NumberOfSessions": 4
	},
	{
		"lat": 19.2561,
		"lon": -103.7486,
		"name": "Colima",
		"iso": "MX",
		"NumberOfSessions": 1
	},
	{
		"lat": 25.71983488207626,
		"lon": -100.32349651416085,
		"name": "Nuevo León",
		"iso": "MX",
		"NumberOfSessions": 35
	},
	{
		"lat": 20.812598003673124,
		"lon": -87.07697934724351,
		"name": "Quintana Roo",
		"iso": "MX",
		"NumberOfSessions": 11
	},
	{
		"lat": 19.413051543287438,
		"lon": -101.73615139771229,
		"name": "Michoacán",
		"iso": "MX",
		"NumberOfSessions": 9
	},
	{
		"lat": 16.909435152536812,
		"lon": -96.31276297540315,
		"name": "Oaxaca",
		"iso": "MX",
		"NumberOfSessions": 4
	},
	{
		"lat": 20.989501889655592,
		"lon": -89.64574132886311,
		"name": "Yucatán",
		"iso": "MX",
		"NumberOfSessions": 9
	},
	{
		"lat": 20.98334955211553,
		"lon": -101.42650686081387,
		"name": "Guanajuato",
		"iso": "MX",
		"NumberOfSessions": 9
	},
	{
		"lat": 19.222049755768904,
		"lon": -91.18218855564155,
		"name": "Campeche",
		"iso": "MX",
		"NumberOfSessions": 2
	},
	{
		"lat": 19.446706177316738,
		"lon": -99.21355416939822,
		"name": "México",
		"iso": "MX",
		"NumberOfSessions": 36
	},
	{
		"lat": 25.41548435270313,
		"lon": -102.21128790469915,
		"name": "Coahuila",
		"iso": "MX",
		"NumberOfSessions": 4
	},
	{
		"lat": 29.520455517545475,
		"lon": -110.98212646766733,
		"name": "Sonora",
		"iso": "MX",
		"NumberOfSessions": 5
	},
	{
		"lat": 20.10945133896009,
		"lon": -98.76210087067331,
		"name": "Hidalgo",
		"iso": "MX",
		"NumberOfSessions": 2
	},
	{
		"lat": 16.910299999999996,
		"lon": -99.7727,
		"name": "Guerrero",
		"iso": "MX",
		"NumberOfSessions": 2
	},
	{
		"lat": 21.849704479871424,
		"lon": -102.25896122317616,
		"name": "Aguascalientes",
		"iso": "MX",
		"NumberOfSessions": 2
	},
	{
		"lat": 23.90467808128034,
		"lon": -104.526728297547,
		"name": "Durango",
		"iso": "MX",
		"NumberOfSessions": 2
	},
	{
		"lat": 22.139526194833028,
		"lon": -100.95967484470933,
		"name": "San Luis Potosí",
		"iso": "MX",
		"NumberOfSessions": 4
	},
	{
		"lat": 19.726979749348633,
		"lon": -96.84622066010598,
		"name": "Veracruz",
		"iso": "MX",
		"NumberOfSessions": 6
	},
	{
		"lat": 23.167685006603644,
		"lon": -110.01850516854513,
		"name": "Baja California Sur",
		"iso": "MX",
		"NumberOfSessions": 5
	},
	{
		"lat": 20.614661942131445,
		"lon": -100.43307126335291,
		"name": "Querétaro",
		"iso": "MX",
		"NumberOfSessions": 14
	},
	{
		"lat": 32.49987618642608,
		"lon": -116.41566969976493,
		"name": "Baja California",
		"iso": "MX",
		"NumberOfSessions": 17
	},
	{
		"lat": 19.399223378881114,
		"lon": -99.13985969233833,
		"name": "Mexico City",
		"iso": "MX",
		"NumberOfSessions": 103
	},
	{
		"lat": 18.777525781282804,
		"lon": -99.21555263357773,
		"name": "Morelos",
		"iso": "MX",
		"NumberOfSessions": 4
	},
	{
		"lat": 24.735840395559002,
		"lon": -107.59269299524959,
		"name": "Sinaloa",
		"iso": "MX",
		"NumberOfSessions": 5
	},
	{
		"lat": 19.028854304572757,
		"lon": -98.18732529197898,
		"name": "Puebla",
		"iso": "MX",
		"NumberOfSessions": 9
	},
	{
		"lat": 20.674780452946884,
		"lon": -103.43898953765088,
		"name": "Jalisco",
		"iso": "MX",
		"NumberOfSessions": 36
	},
	{
		"lat": 17.98476853990489,
		"lon": -92.91590128359319,
		"name": "Tabasco",
		"iso": "MX",
		"NumberOfSessions": 6
	},
	{
		"lat": 2.2446,
		"lon": 102.2411,
		"name": "Melaka",
		"iso": "MY",
		"NumberOfSessions": 1
	},
	{
		"lat": 5.951850474038183,
		"lon": 116.05975016495012,
		"name": "Sabah",
		"iso": "MY",
		"NumberOfSessions": 2
	},
	{
		"lat": 1.5608,
		"lon": 110.3071,
		"name": "Sarawak",
		"iso": "MY",
		"NumberOfSessions": 1
	},
	{
		"lat": 3.031510268561999,
		"lon": 101.64249908390366,
		"name": "Selangor",
		"iso": "MY",
		"NumberOfSessions": 35
	},
	{
		"lat": 4.577700000000002,
		"lon": 101.1113,
		"name": "Perak",
		"iso": "MY",
		"NumberOfSessions": 1
	},
	{
		"lat": 5.375880031156399,
		"lon": 100.38595572501553,
		"name": "Penang",
		"iso": "MY",
		"NumberOfSessions": 4
	},
	{
		"lat": 3.135503156875942,
		"lon": 101.68676459440053,
		"name": "Kuala Lumpur",
		"iso": "MY",
		"NumberOfSessions": 34
	},
	{
		"lat": 1.549520000118704,
		"lon": 103.69676000232973,
		"name": "Johor",
		"iso": "MY",
		"NumberOfSessions": 5
	},
	{
		"lat": 51.57643148660368,
		"lon": 5.185242791323926,
		"name": "North Brabant",
		"iso": "NL",
		"NumberOfSessions": 34
	},
	{
		"lat": 52.35043894565605,
		"lon": 6.484303294703664,
		"name": "Overijssel",
		"iso": "NL",
		"NumberOfSessions": 12
	},
	{
		"lat": 52.11056254465939,
		"lon": 5.13393992031384,
		"name": "Utrecht",
		"iso": "NL",
		"NumberOfSessions": 29
	},
	{
		"lat": 51.4451,
		"lon": 3.5765000000000002,
		"name": "Zeeland",
		"iso": "NL",
		"NumberOfSessions": 1
	},
	{
		"lat": 52.06704556968165,
		"lon": 5.844890148194509,
		"name": "Gelderland",
		"iso": "NL",
		"NumberOfSessions": 26
	},
	{
		"lat": 52.394372493792474,
		"lon": 4.878088279211659,
		"name": "North Holland",
		"iso": "NL",
		"NumberOfSessions": 132
	},
	{
		"lat": 50.97877182947778,
		"lon": 5.914028998815798,
		"name": "Limburg",
		"iso": "NL",
		"NumberOfSessions": 7
	},
	{
		"lat": 53.19633381730721,
		"lon": 6.630614604186858,
		"name": "Groningen",
		"iso": "NL",
		"NumberOfSessions": 14
	},
	{
		"lat": 52.738972956322264,
		"lon": 6.547904926219169,
		"name": "Drenthe",
		"iso": "NL",
		"NumberOfSessions": 2
	},
	{
		"lat": 52.46116394662823,
		"lon": 5.407653599829929,
		"name": "Flevoland",
		"iso": "NL",
		"NumberOfSessions": 18
	},
	{
		"lat": 53.18882880648667,
		"lon": 5.800837429454335,
		"name": "Friesland",
		"iso": "NL",
		"NumberOfSessions": 6
	},
	{
		"lat": 51.97447597940334,
		"lon": 4.411065359354598,
		"name": "South Holland",
		"iso": "NL",
		"NumberOfSessions": 68
	},
	{
		"lat": 63.23151288051677,
		"lon": 10.182844001783689,
		"name": "Trøndelag",
		"iso": "NO",
		"NumberOfSessions": 10
	},
	{
		"lat": 59.304591735999246,
		"lon": 10.31493486702868,
		"name": "Vestfold",
		"iso": "NO",
		"NumberOfSessions": 5
	},
	{
		"lat": 62.47250422663439,
		"lon": 6.472428869403474,
		"name": "Møre og Romsdal",
		"iso": "NO",
		"NumberOfSessions": 3
	},
	{
		"lat": 59.93021046135815,
		"lon": 11.19569543403003,
		"name": "Akershus",
		"iso": "NO",
		"NumberOfSessions": 3
	},
	{
		"lat": 60.417144310715635,
		"lon": 5.279485869452177,
		"name": "Vestland",
		"iso": "NO",
		"NumberOfSessions": 7
	},
	{
		"lat": 69.35418336005748,
		"lon": 18.597417212449983,
		"name": "Troms",
		"iso": "NO",
		"NumberOfSessions": 4
	},
	{
		"lat": 59.89785670180564,
		"lon": 10.737136884231777,
		"name": "Oslo County",
		"iso": "NO",
		"NumberOfSessions": 46
	},
	{
		"lat": 60.764921000664565,
		"lon": 10.678666574359601,
		"name": "Innlandet",
		"iso": "NO",
		"NumberOfSessions": 6
	},
	{
		"lat": 69.74492693617277,
		"lon": 25.468430771734592,
		"name": "Finnmark",
		"iso": "NO",
		"NumberOfSessions": 3
	},
	{
		"lat": 58.93511882274665,
		"lon": 5.611969886233111,
		"name": "Rogaland",
		"iso": "NO",
		"NumberOfSessions": 5
	},
	{
		"lat": 58.20698006383004,
		"lon": 8.099161490754012,
		"name": "Agder",
		"iso": "NO",
		"NumberOfSessions": 11
	},
	{
		"lat": 59.7437637884948,
		"lon": 10.041360012561361,
		"name": "Buskerud",
		"iso": "NO",
		"NumberOfSessions": 6
	},
	{
		"lat": 66.32110117554373,
		"lon": 13.535030280814924,
		"name": "Nordland",
		"iso": "NO",
		"NumberOfSessions": 3
	},
	{
		"lat": -37.92764226854476,
		"lon": 176.2039224791216,
		"name": "Bay of Plenty",
		"iso": "NZ",
		"NumberOfSessions": 6
	},
	{
		"lat": -41.496,
		"lon": 173.8352,
		"name": "Marlborough",
		"iso": "NZ",
		"NumberOfSessions": 1
	},
	{
		"lat": -37.798103565715635,
		"lon": 175.30313015111997,
		"name": "Waikato Region",
		"iso": "NZ",
		"NumberOfSessions": 9
	},
	{
		"lat": -43.54456376076285,
		"lon": 172.54882921944133,
		"name": "Canterbury",
		"iso": "NZ",
		"NumberOfSessions": 22
	},
	{
		"lat": -46.3888,
		"lon": 168.37790000000004,
		"name": "Southland",
		"iso": "NZ",
		"NumberOfSessions": 1
	},
	{
		"lat": -36.85220725255312,
		"lon": 174.76411717180486,
		"name": "Auckland",
		"iso": "NZ",
		"NumberOfSessions": 50
	},
	{
		"lat": -40.3761,
		"lon": 175.5809,
		"name": "Manawatu-Wanganui",
		"iso": "NZ",
		"NumberOfSessions": 1
	},
	{
		"lat": -42.5622,
		"lon": 171.2183,
		"name": "West Coast",
		"iso": "NZ",
		"NumberOfSessions": 2
	},
	{
		"lat": -41.24052184570419,
		"lon": 174.871929827156,
		"name": "Wellington Region",
		"iso": "NZ",
		"NumberOfSessions": 11
	},
	{
		"lat": -45.88875079483298,
		"lon": 170.47739784905258,
		"name": "Otago",
		"iso": "NZ",
		"NumberOfSessions": 2
	},
	{
		"lat": -8.1191,
		"lon": -79.0355,
		"name": "La Libertad",
		"iso": "PE",
		"NumberOfSessions": 1
	},
	{
		"lat": -7.163,
		"lon": -78.5008,
		"name": "Cajamarca Department",
		"iso": "PE",
		"NumberOfSessions": 1
	},
	{
		"lat": -12.043200000000008,
		"lon": -77.02819999999998,
		"name": "Lima Province",
		"iso": "PE",
		"NumberOfSessions": 49
	},
	{
		"lat": -16.4014,
		"lon": -71.5343,
		"name": "Arequipa",
		"iso": "PE",
		"NumberOfSessions": 4
	},
	{
		"lat": -13.1603,
		"lon": -74.2257,
		"name": "Ayacucho",
		"iso": "PE",
		"NumberOfSessions": 1
	},
	{
		"lat": -4.9017,
		"lon": -80.6887,
		"name": "Piura",
		"iso": "PE",
		"NumberOfSessions": 1
	},
	{
		"lat": -11.943902004038755,
		"lon": -76.79587654073482,
		"name": "Lima region",
		"iso": "PE",
		"NumberOfSessions": 7
	},
	{
		"lat": -18.0052,
		"lon": -70.2489,
		"name": "Tacna",
		"iso": "PE",
		"NumberOfSessions": 1
	},
	{
		"lat": -13.640899999999998,
		"lon": -72.8793,
		"name": "Apurímac Department",
		"iso": "PE",
		"NumberOfSessions": 1
	},
	{
		"lat": -12.063699999999999,
		"lon": -75.21720000000002,
		"name": "Junin",
		"iso": "PE",
		"NumberOfSessions": 1
	},
	{
		"lat": 7.071199999999998,
		"lon": 125.6089,
		"name": "Province of Davao del Sur",
		"iso": "PH",
		"NumberOfSessions": 4
	},
	{
		"lat": 14.443587362132831,
		"lon": 121.03983729730275,
		"name": "Province of Rizal",
		"iso": "PH",
		"NumberOfSessions": 7
	},
	{
		"lat": 17.608,
		"lon": 121.72190000000002,
		"name": "Province of Cagayan",
		"iso": "PH",
		"NumberOfSessions": 2
	},
	{
		"lat": 16.169999999999995,
		"lon": 120.50720000000001,
		"name": "Province of Pangasinan",
		"iso": "PH",
		"NumberOfSessions": 1
	},
	{
		"lat": 16.412399999999998,
		"lon": 120.5933,
		"name": "Province of Benguet",
		"iso": "PH",
		"NumberOfSessions": 3
	},
	{
		"lat": 14.431213305032763,
		"lon": 120.90832071710588,
		"name": "Province of Cavite",
		"iso": "PH",
		"NumberOfSessions": 9
	},
	{
		"lat": 14.294435884814414,
		"lon": 121.11734287656795,
		"name": "Province of Laguna",
		"iso": "PH",
		"NumberOfSessions": 6
	},
	{
		"lat": 10.6689,
		"lon": 122.9466,
		"name": "Province of Negros Occidental",
		"iso": "PH",
		"NumberOfSessions": 3
	},
	{
		"lat": 8.9449,
		"lon": 125.54679999999999,
		"name": "Province of Agusan del Norte",
		"iso": "PH",
		"NumberOfSessions": 1
	},
	{
		"lat": 10.316546166350316,
		"lon": 123.8945691202385,
		"name": "Province of Cebu",
		"iso": "PH",
		"NumberOfSessions": 13
	},
	{
		"lat": 16.4844,
		"lon": 121.14419999999998,
		"name": "Province of Nueva Vizcaya",
		"iso": "PH",
		"NumberOfSessions": 2
	},
	{
		"lat": 15.080934315960965,
		"lon": 120.6337198474462,
		"name": "Province of Pampanga",
		"iso": "PH",
		"NumberOfSessions": 6
	},
	{
		"lat": 10.7019,
		"lon": 122.5622,
		"name": "Province of Iloilo",
		"iso": "PH",
		"NumberOfSessions": 3
	},
	{
		"lat": 14.589652251036068,
		"lon": 121.02466117026243,
		"name": "Metro Manila",
		"iso": "PH",
		"NumberOfSessions": 52
	},
	{
		"lat": 8.485,
		"lon": 124.64799999999998,
		"name": "Province of Misamis Oriental",
		"iso": "PH",
		"NumberOfSessions": 10
	},
	{
		"lat": 6.690900000000002,
		"lon": 124.6762,
		"name": "Province of Sultan Kudarat",
		"iso": "PH",
		"NumberOfSessions": 1
	},
	{
		"lat": 13.783569691091627,
		"lon": 121.07988833352397,
		"name": "Province of Batangas",
		"iso": "PH",
		"NumberOfSessions": 6
	},
	{
		"lat": 52.22960769954503,
		"lon": 21.006155011396903,
		"name": "Mazovia",
		"iso": "PL",
		"NumberOfSessions": 166
	},
	{
		"lat": 50.707768454710056,
		"lon": 17.78192789704559,
		"name": "Opole Voivodeship",
		"iso": "PL",
		"NumberOfSessions": 6
	},
	{
		"lat": 50.84435713113431,
		"lon": 20.92112489469909,
		"name": "Świętokrzyskie",
		"iso": "PL",
		"NumberOfSessions": 5
	},
	{
		"lat": 51.69097494834731,
		"lon": 19.578267350478935,
		"name": "Łódź Voivodeship",
		"iso": "PL",
		"NumberOfSessions": 69
	},
	{
		"lat": 51.93355006609455,
		"lon": 15.50424983714599,
		"name": "Lubusz",
		"iso": "PL",
		"NumberOfSessions": 2
	},
	{
		"lat": 54.39195227142801,
		"lon": 18.51515797918096,
		"name": "Pomerania",
		"iso": "PL",
		"NumberOfSessions": 37
	},
	{
		"lat": 50.03588990438387,
		"lon": 19.927902652346972,
		"name": "Lesser Poland",
		"iso": "PL",
		"NumberOfSessions": 60
	},
	{
		"lat": 51.30322283854553,
		"lon": 22.71414786379982,
		"name": "Lublin",
		"iso": "PL",
		"NumberOfSessions": 16
	},
	{
		"lat": 50.189826413385084,
		"lon": 18.979939390026832,
		"name": "Silesia",
		"iso": "PL",
		"NumberOfSessions": 55
	},
	{
		"lat": 52.291519738696785,
		"lon": 16.951847811706,
		"name": "Greater Poland",
		"iso": "PL",
		"NumberOfSessions": 53
	},
	{
		"lat": 50.066664279957415,
		"lon": 22.070087224030107,
		"name": "Subcarpathia",
		"iso": "PL",
		"NumberOfSessions": 6
	},
	{
		"lat": 53.88675602864047,
		"lon": 20.460869806404496,
		"name": "Warmia-Masuria",
		"iso": "PL",
		"NumberOfSessions": 6
	},
	{
		"lat": 53.089775844712406,
		"lon": 18.413477863307616,
		"name": "Kujawsko-Pomorskie",
		"iso": "PL",
		"NumberOfSessions": 24
	},
	{
		"lat": 51.10853619021063,
		"lon": 16.90502845899092,
		"name": "Lower Silesia",
		"iso": "PL",
		"NumberOfSessions": 49
	},
	{
		"lat": 53.58103391215069,
		"lon": 14.956044325131526,
		"name": "West Pomerania",
		"iso": "PL",
		"NumberOfSessions": 16
	},
	{
		"lat": 53.37212240758668,
		"lon": 23.06742196190814,
		"name": "Podlasie",
		"iso": "PL",
		"NumberOfSessions": 4
	},
	{
		"lat": 41.80469999999999,
		"lon": -6.7577,
		"name": "Bragança",
		"iso": "PT",
		"NumberOfSessions": 1
	},
	{
		"lat": 39.574942020906256,
		"lon": -8.42123675492449,
		"name": "Santarém",
		"iso": "PT",
		"NumberOfSessions": 5
	},
	{
		"lat": 40.82865000786795,
		"lon": -8.648853635056643,
		"name": "Aveiro",
		"iso": "PT",
		"NumberOfSessions": 4
	},
	{
		"lat": 40.2057,
		"lon": -8.4213,
		"name": "Coimbra",
		"iso": "PT",
		"NumberOfSessions": 1
	},
	{
		"lat": 41.55150000000001,
		"lon": -8.4204,
		"name": "Braga",
		"iso": "PT",
		"NumberOfSessions": 1
	},
	{
		"lat": 38.750959696848994,
		"lon": -9.175675906117275,
		"name": "Lisbon",
		"iso": "PT",
		"NumberOfSessions": 47
	},
	{
		"lat": 38.62070838428654,
		"lon": -9.053726665446268,
		"name": "Setúbal",
		"iso": "PT",
		"NumberOfSessions": 8
	},
	{
		"lat": 41.19085010475337,
		"lon": -8.643179828681529,
		"name": "Porto",
		"iso": "PT",
		"NumberOfSessions": 28
	},
	{
		"lat": 32.63330000000001,
		"lon": -16.900000000000002,
		"name": "Madeira",
		"iso": "PT",
		"NumberOfSessions": 3
	},
	{
		"lat": 38.64315779693121,
		"lon": -7.935953982264435,
		"name": "Évora",
		"iso": "PT",
		"NumberOfSessions": 2
	},
	{
		"lat": 39.578156412996606,
		"lon": -9.023145932495492,
		"name": "Leiria",
		"iso": "PT",
		"NumberOfSessions": 6
	},
	{
		"lat": 37.7392,
		"lon": -25.661700000000003,
		"name": "Azores",
		"iso": "PT",
		"NumberOfSessions": 1
	},
	{
		"lat": 39.249,
		"lon": -8.005500000000001,
		"name": "Portalegre",
		"iso": "PT",
		"NumberOfSessions": 2
	},
	{
		"lat": 37.09625017428258,
		"lon": -8.24120431370063,
		"name": "Faro",
		"iso": "PT",
		"NumberOfSessions": 3
	},
	{
		"lat": 40.28329999999999,
		"lon": -7.508000000000001,
		"name": "Castelo Branco",
		"iso": "PT",
		"NumberOfSessions": 1
	},
	{
		"lat": 41.6929,
		"lon": -8.839100000000002,
		"name": "Viana do Castelo",
		"iso": "PT",
		"NumberOfSessions": 1
	},
	{
		"lat": 40.98930000000001,
		"lon": -7.616100000000003,
		"name": "Viseu",
		"iso": "PT",
		"NumberOfSessions": 1
	},
	{
		"lat": 47.75020000000001,
		"lon": 26.671299999999995,
		"name": "Botoșani County",
		"iso": "RO",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.83271797383053,
		"lon": 25.695373882862803,
		"name": "Covasna County",
		"iso": "RO",
		"NumberOfSessions": 3
	},
	{
		"lat": 47.218570386334235,
		"lon": 22.928534785539753,
		"name": "Sălaj County",
		"iso": "RO",
		"NumberOfSessions": 2
	},
	{
		"lat": 44.6319,
		"lon": 22.656100000000002,
		"name": "Mehedinți County",
		"iso": "RO",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.43655624305543,
		"lon": 27.70041250484285,
		"name": "Vaslui County",
		"iso": "RO",
		"NumberOfSessions": 2
	},
	{
		"lat": 45.8017,
		"lon": 24.1455,
		"name": "Sibiu County",
		"iso": "RO",
		"NumberOfSessions": 4
	},
	{
		"lat": 47.67756009656236,
		"lon": 23.855558682303403,
		"name": "Maramureş",
		"iso": "RO",
		"NumberOfSessions": 3
	},
	{
		"lat": 44.161300110708204,
		"lon": 28.630903191051335,
		"name": "Constanța County",
		"iso": "RO",
		"NumberOfSessions": 7
	},
	{
		"lat": 47.167199999999994,
		"lon": 27.608300000000007,
		"name": "Iași County",
		"iso": "RO",
		"NumberOfSessions": 15
	},
	{
		"lat": 44.9316,
		"lon": 25.462199999999992,
		"name": "Dâmbovița County",
		"iso": "RO",
		"NumberOfSessions": 2
	},
	{
		"lat": 47.1493007145609,
		"lon": 24.396676256572754,
		"name": "Bistrița-Năsăud County",
		"iso": "RO",
		"NumberOfSessions": 3
	},
	{
		"lat": 46.0653,
		"lon": 23.5917,
		"name": "Alba County",
		"iso": "RO",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.0489,
		"lon": 23.290400000000005,
		"name": "Gorj County",
		"iso": "RO",
		"NumberOfSessions": 1
	},
	{
		"lat": 44.41529071364027,
		"lon": 26.118718545276955,
		"name": "București",
		"iso": "RO",
		"NumberOfSessions": 52
	},
	{
		"lat": 45.7525,
		"lon": 22.903299999999998,
		"name": "Hunedoara County",
		"iso": "RO",
		"NumberOfSessions": 1
	},
	{
		"lat": 47.001696290281295,
		"lon": 26.41147442891798,
		"name": "Neamț County",
		"iso": "RO",
		"NumberOfSessions": 3
	},
	{
		"lat": 46.57010000000001,
		"lon": 26.912400000000005,
		"name": "Bacău County",
		"iso": "RO",
		"NumberOfSessions": 2
	},
	{
		"lat": 45.1002,
		"lon": 24.364399999999993,
		"name": "Vâlcea County",
		"iso": "RO",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.6998,
		"lon": 27.1875,
		"name": "Vrancea",
		"iso": "RO",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.642001066880596,
		"lon": 25.491119989483547,
		"name": "Brașov County",
		"iso": "RO",
		"NumberOfSessions": 15
	},
	{
		"lat": 45.750600000000006,
		"lon": 21.233399999999993,
		"name": "Timiș County",
		"iso": "RO",
		"NumberOfSessions": 11
	},
	{
		"lat": 44.318,
		"lon": 23.7967,
		"name": "Dolj",
		"iso": "RO",
		"NumberOfSessions": 4
	},
	{
		"lat": 47.0479,
		"lon": 21.918900000000004,
		"name": "Bihor County",
		"iso": "RO",
		"NumberOfSessions": 5
	},
	{
		"lat": 46.763391572516454,
		"lon": 23.579324718053986,
		"name": "Cluj County",
		"iso": "RO",
		"NumberOfSessions": 7
	},
	{
		"lat": 44.9467,
		"lon": 26.013499999999997,
		"name": "Prahova",
		"iso": "RO",
		"NumberOfSessions": 4
	},
	{
		"lat": 46.43568314930332,
		"lon": 24.644929716911786,
		"name": "Mureș County",
		"iso": "RO",
		"NumberOfSessions": 3
	},
	{
		"lat": 45.1637,
		"lon": 28.8023,
		"name": "Tulcea County",
		"iso": "RO",
		"NumberOfSessions": 1
	},
	{
		"lat": 44.4348,
		"lon": 24.356000000000005,
		"name": "Olt",
		"iso": "RO",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.57227382763456,
		"lon": 27.84528492190743,
		"name": "Galați County",
		"iso": "RO",
		"NumberOfSessions": 4
	},
	{
		"lat": 45.2662,
		"lon": 27.9804,
		"name": "Brăila County",
		"iso": "RO",
		"NumberOfSessions": 1
	},
	{
		"lat": 44.46283373905637,
		"lon": 26.04698820566834,
		"name": "Ilfov",
		"iso": "RO",
		"NumberOfSessions": 9
	},
	{
		"lat": 46.30440000000001,
		"lon": 25.290900000000004,
		"name": "Harghita County",
		"iso": "RO",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.184,
		"lon": 21.3225,
		"name": "Arad County",
		"iso": "RO",
		"NumberOfSessions": 3
	},
	{
		"lat": 44.944269378846926,
		"lon": 24.798113197944105,
		"name": "Arges",
		"iso": "RO",
		"NumberOfSessions": 3
	},
	{
		"lat": 45.1494,
		"lon": 26.824399999999997,
		"name": "Buzău County",
		"iso": "RO",
		"NumberOfSessions": 1
	},
	{
		"lat": 43.32610000000001,
		"lon": 21.901400000000006,
		"name": "Nišava",
		"iso": "RS",
		"NumberOfSessions": 1
	},
	{
		"lat": 45.7972,
		"lon": 20.1329,
		"name": "North Banat",
		"iso": "RS",
		"NumberOfSessions": 1
	},
	{
		"lat": 44.804599999999965,
		"lon": 20.463699999999992,
		"name": "Belgrade",
		"iso": "RS",
		"NumberOfSessions": 48
	},
	{
		"lat": 43.5839,
		"lon": 21.335,
		"name": "Rasina",
		"iso": "RS",
		"NumberOfSessions": 2
	},
	{
		"lat": 44.7476,
		"lon": 19.686600000000006,
		"name": "Mačva",
		"iso": "RS",
		"NumberOfSessions": 1
	},
	{
		"lat": 44.0177,
		"lon": 20.9222,
		"name": "Šumadija",
		"iso": "RS",
		"NumberOfSessions": 2
	},
	{
		"lat": 45.37210437128119,
		"lon": 19.901881583436126,
		"name": "South Bačka",
		"iso": "RS",
		"NumberOfSessions": 3
	},
	{
		"lat": 44.618,
		"lon": 21.172299999999996,
		"name": "Branicevo",
		"iso": "RS",
		"NumberOfSessions": 1
	},
	{
		"lat": 44.7539,
		"lon": 20.974599999999995,
		"name": "South Banat",
		"iso": "RS",
		"NumberOfSessions": 1
	},
	{
		"lat": 55.01528622926645,
		"lon": 73.38000288938477,
		"name": "Omsk Oblast",
		"iso": "RU",
		"NumberOfSessions": 14
	},
	{
		"lat": 54.276320390698174,
		"lon": 45.40141759322311,
		"name": "Mordoviya Republic",
		"iso": "RU",
		"NumberOfSessions": 4
	},
	{
		"lat": 56.166112177584644,
		"lon": 47.30007461636385,
		"name": "Chuvash Republic",
		"iso": "RU",
		"NumberOfSessions": 12
	},
	{
		"lat": 51.56206925158089,
		"lon": 46.01798522120166,
		"name": "Saratov Oblast",
		"iso": "RU",
		"NumberOfSessions": 18
	},
	{
		"lat": 59.56860000000001,
		"lon": 150.8133,
		"name": "Magadan Oblast",
		"iso": "RU",
		"NumberOfSessions": 1
	},
	{
		"lat": 56.33672947679514,
		"lon": 43.99022857861218,
		"name": "Nizhny Novgorod Oblast",
		"iso": "RU",
		"NumberOfSessions": 42
	},
	{
		"lat": 44.96671249849915,
		"lon": 42.055688752066054,
		"name": "Stavropol Kray",
		"iso": "RU",
		"NumberOfSessions": 15
	},
	{
		"lat": 51.67249334036371,
		"lon": 36.09453839184436,
		"name": "Kursk Oblast",
		"iso": "RU",
		"NumberOfSessions": 9
	},
	{
		"lat": 58.60050327452463,
		"lon": 49.73911324696888,
		"name": "Kirov Oblast",
		"iso": "RU",
		"NumberOfSessions": 8
	},
	{
		"lat": 55.72025173025056,
		"lon": 37.78027581039711,
		"name": "Moscow Oblast",
		"iso": "RU",
		"NumberOfSessions": 78
	},
	{
		"lat": 53.19500000000001,
		"lon": 44.9945,
		"name": "Penza Oblast",
		"iso": "RU",
		"NumberOfSessions": 6
	},
	{
		"lat": 56.6376,
		"lon": 47.905,
		"name": "Mariy-El Republic",
		"iso": "RU",
		"NumberOfSessions": 5
	},
	{
		"lat": 57.768600000000006,
		"lon": 40.9352,
		"name": "Kostroma Oblast",
		"iso": "RU",
		"NumberOfSessions": 5
	},
	{
		"lat": 43.034400000000005,
		"lon": 44.67340000000001,
		"name": "North Ossetia–Alania",
		"iso": "RU",
		"NumberOfSessions": 2
	},
	{
		"lat": 54.14953504370424,
		"lon": 37.708149516321456,
		"name": "Tula Oblast",
		"iso": "RU",
		"NumberOfSessions": 14
	},
	{
		"lat": 43.09516949468151,
		"lon": 132.18620585145345,
		"name": "Primorye",
		"iso": "RU",
		"NumberOfSessions": 20
	},
	{
		"lat": 55.61724483357884,
		"lon": 65.59306008775062,
		"name": "Kurgan Oblast",
		"iso": "RU",
		"NumberOfSessions": 3
	},
	{
		"lat": 51.7214,
		"lon": 94.4553,
		"name": "Republic of Tyva",
		"iso": "RU",
		"NumberOfSessions": 3
	},
	{
		"lat": 44.936585572807346,
		"lon": 39.57914611748001,
		"name": "Adygeya Republic",
		"iso": "RU",
		"NumberOfSessions": 4
	},
	{
		"lat": 51.955,
		"lon": 85.9532,
		"name": "Altai",
		"iso": "RU",
		"NumberOfSessions": 1
	},
	{
		"lat": 65.35670722145925,
		"lon": 76.32976078242048,
		"name": "Yamalo-Nenets",
		"iso": "RU",
		"NumberOfSessions": 4
	},
	{
		"lat": 55.747754331846686,
		"lon": 37.617435107198254,
		"name": "Moscow",
		"iso": "RU",
		"NumberOfSessions": 437
	},
	{
		"lat": 51.64817359197593,
		"lon": 39.20292020050543,
		"name": "Voronezh Oblast",
		"iso": "RU",
		"NumberOfSessions": 23
	},
	{
		"lat": 46.34633750840657,
		"lon": 48.040224936970176,
		"name": "Astrakhan Oblast",
		"iso": "RU",
		"NumberOfSessions": 8
	},
	{
		"lat": 57.68160073090251,
		"lon": 39.42943546511199,
		"name": "Yaroslavl Oblast",
		"iso": "RU",
		"NumberOfSessions": 6
	},
	{
		"lat": 61.7788,
		"lon": 34.360600000000005,
		"name": "Karelia",
		"iso": "RU",
		"NumberOfSessions": 3
	},
	{
		"lat": 57.30879589790667,
		"lon": 92.25700167885302,
		"name": "Krasnoyarsk Krai",
		"iso": "RU",
		"NumberOfSessions": 31
	},
	{
		"lat": 58.106890317354186,
		"lon": 56.243348444578366,
		"name": "Perm Krai",
		"iso": "RU",
		"NumberOfSessions": 32
	},
	{
		"lat": 51.82570000179143,
		"lon": 107.61824995815363,
		"name": "Buryatiya Republic",
		"iso": "RU",
		"NumberOfSessions": 2
	},
	{
		"lat": 46.94840000000001,
		"lon": 142.7465,
		"name": "Sakhalin Oblast",
		"iso": "RU",
		"NumberOfSessions": 8
	},
	{
		"lat": 46.307500000000005,
		"lon": 44.248099999999994,
		"name": "Kalmykiya Republic",
		"iso": "RU",
		"NumberOfSessions": 1
	},
	{
		"lat": 54.7686046104506,
		"lon": 55.71271394499146,
		"name": "Bashkortostan Republic",
		"iso": "RU",
		"NumberOfSessions": 31
	},
	{
		"lat": 52.7324,
		"lon": 41.4313,
		"name": "Tambov Oblast",
		"iso": "RU",
		"NumberOfSessions": 5
	},
	{
		"lat": 54.7609,
		"lon": 32.12539999999999,
		"name": "Smolensk Oblast",
		"iso": "RU",
		"NumberOfSessions": 8
	},
	{
		"lat": 52.626455108922734,
		"lon": 104.30153209337473,
		"name": "Irkutsk Oblast",
		"iso": "RU",
		"NumberOfSessions": 16
	},
	{
		"lat": 57.04693275999401,
		"lon": 41.00027810224796,
		"name": "Ivanovo Oblast",
		"iso": "RU",
		"NumberOfSessions": 5
	},
	{
		"lat": 59.1743584469412,
		"lon": 38.9022571800125,
		"name": "Vologda Oblast",
		"iso": "RU",
		"NumberOfSessions": 6
	},
	{
		"lat": 55.66597641209463,
		"lon": 49.91263465284848,
		"name": "Tatarstan Republic",
		"iso": "RU",
		"NumberOfSessions": 25
	},
	{
		"lat": 59.9398693676671,
		"lon": 30.298697623434187,
		"name": "St.-Petersburg",
		"iso": "RU",
		"NumberOfSessions": 204
	},
	{
		"lat": 61.16010901981025,
		"lon": 74.31989222693804,
		"name": "Khanty-Mansia",
		"iso": "RU",
		"NumberOfSessions": 14
	},
	{
		"lat": 50.555851127046026,
		"lon": 36.789579791442286,
		"name": "Belgorod Oblast",
		"iso": "RU",
		"NumberOfSessions": 11
	},
	{
		"lat": 52.904342230969256,
		"lon": 36.1455201802771,
		"name": "Oryol oblast",
		"iso": "RU",
		"NumberOfSessions": 6
	},
	{
		"lat": 69.04910749242528,
		"lon": 33.5238144221196,
		"name": "Murmansk",
		"iso": "RU",
		"NumberOfSessions": 11
	},
	{
		"lat": 50.2828,
		"lon": 127.5299,
		"name": "Amur Oblast",
		"iso": "RU",
		"NumberOfSessions": 4
	},
	{
		"lat": 47.278361973316635,
		"lon": 39.59516285948311,
		"name": "Rostov Oblast",
		"iso": "RU",
		"NumberOfSessions": 28
	},
	{
		"lat": 53.24543868379107,
		"lon": 50.01486796779544,
		"name": "Samara Oblast",
		"iso": "RU",
		"NumberOfSessions": 40
	},
	{
		"lat": 58.51100000000001,
		"lon": 31.3251,
		"name": "Novgorod Oblast",
		"iso": "RU",
		"NumberOfSessions": 6
	},
	{
		"lat": 53.75906198325209,
		"lon": 91.39038846726214,
		"name": "Khakasiya Republic",
		"iso": "RU",
		"NumberOfSessions": 3
	},
	{
		"lat": 52.035453100381446,
		"lon": 113.54387148889303,
		"name": "Transbaikal Territory",
		"iso": "RU",
		"NumberOfSessions": 4
	},
	{
		"lat": 57.1615,
		"lon": 65.5346,
		"name": "Tyumen Oblast",
		"iso": "RU",
		"NumberOfSessions": 15
	},
	{
		"lat": 57.90903170031484,
		"lon": 28.357734927827398,
		"name": "Pskov Oblast",
		"iso": "RU",
		"NumberOfSessions": 4
	},
	{
		"lat": 56.18594256877252,
		"lon": 40.61492280193214,
		"name": "Vladimir Oblast",
		"iso": "RU",
		"NumberOfSessions": 16
	},
	{
		"lat": 55.08132625376685,
		"lon": 61.1706445515607,
		"name": "Chelyabinsk Oblast",
		"iso": "RU",
		"NumberOfSessions": 52
	},
	{
		"lat": 52.59028788645273,
		"lon": 39.51825922396015,
		"name": "Lipetsk Oblast",
		"iso": "RU",
		"NumberOfSessions": 13
	},
	{
		"lat": 45.03458859994985,
		"lon": 38.843841914611914,
		"name": "Krasnodar Krai",
		"iso": "RU",
		"NumberOfSessions": 56
	},
	{
		"lat": 54.32049999999999,
		"lon": 48.44679999999999,
		"name": "Ulyanovsk Oblast",
		"iso": "RU",
		"NumberOfSessions": 15
	},
	{
		"lat": 56.68824711180927,
		"lon": 35.43602030978264,
		"name": "Tver Oblast",
		"iso": "RU",
		"NumberOfSessions": 7
	},
	{
		"lat": 51.75748700232966,
		"lon": 55.35111950361891,
		"name": "Orenburg Oblast",
		"iso": "RU",
		"NumberOfSessions": 9
	},
	{
		"lat": 56.91829510056438,
		"lon": 60.57493929439299,
		"name": "Sverdlovsk Oblast",
		"iso": "RU",
		"NumberOfSessions": 63
	},
	{
		"lat": 57.00042142973927,
		"lon": 53.16680499942651,
		"name": "Udmurtiya Republic",
		"iso": "RU",
		"NumberOfSessions": 17
	},
	{
		"lat": 54.6161,
		"lon": 39.73760000000001,
		"name": "Ryazan Oblast",
		"iso": "RU",
		"NumberOfSessions": 7
	},
	{
		"lat": 54.93346431289666,
		"lon": 83.01270038034112,
		"name": "Novosibirsk Oblast",
		"iso": "RU",
		"NumberOfSessions": 48
	},
	{
		"lat": 61.901925240427104,
		"lon": 126.68674026728323,
		"name": "Sakha",
		"iso": "RU",
		"NumberOfSessions": 5
	},
	{
		"lat": 54.781058426405934,
		"lon": 86.45474275459034,
		"name": "Kemerovo Oblast",
		"iso": "RU",
		"NumberOfSessions": 28
	},
	{
		"lat": 48.7844482182296,
		"lon": 44.616048347201804,
		"name": "Volgograd Oblast",
		"iso": "RU",
		"NumberOfSessions": 19
	},
	{
		"lat": 54.76840790583086,
		"lon": 21.002772248135305,
		"name": "Kaliningrad Oblast",
		"iso": "RU",
		"NumberOfSessions": 8
	},
	{
		"lat": 54.31535710082055,
		"lon": 35.156962960267684,
		"name": "Kaluga Oblast",
		"iso": "RU",
		"NumberOfSessions": 14
	},
	{
		"lat": 64.5516067013445,
		"lon": 40.29214892536975,
		"name": "Arkhangelskaya",
		"iso": "RU",
		"NumberOfSessions": 18
	},
	{
		"lat": 53.17096514843136,
		"lon": 34.126338929491766,
		"name": "Bryansk Oblast",
		"iso": "RU",
		"NumberOfSessions": 10
	},
	{
		"lat": 56.5033294667825,
		"lon": 84.97873705809023,
		"name": "Tomsk Oblast",
		"iso": "RU",
		"NumberOfSessions": 10
	},
	{
		"lat": 66.36873596669821,
		"lon": 60.66943070007291,
		"name": "Komi",
		"iso": "RU",
		"NumberOfSessions": 8
	},
	{
		"lat": 53.22149356327991,
		"lon": 84.03958470400184,
		"name": "Altai Krai",
		"iso": "RU",
		"NumberOfSessions": 12
	},
	{
		"lat": 59.78777189243518,
		"lon": 30.370014808360274,
		"name": "Leningrad Oblast",
		"iso": "RU",
		"NumberOfSessions": 14
	},
	{
		"lat": 48.46269999999999,
		"lon": 135.0551,
		"name": "Khabarovsk",
		"iso": "RU",
		"NumberOfSessions": 10
	},
	{
		"lat": 57.07816373731843,
		"lon": 12.47065415742498,
		"name": "Halland County",
		"iso": "SE",
		"NumberOfSessions": 2
	},
	{
		"lat": 59.499430435464895,
		"lon": 13.369697219341663,
		"name": "Värmland County",
		"iso": "SE",
		"NumberOfSessions": 3
	},
	{
		"lat": 59.2808088854133,
		"lon": 16.694126044537786,
		"name": "Södermanland County",
		"iso": "SE",
		"NumberOfSessions": 4
	},
	{
		"lat": 60.67410000000001,
		"lon": 17.1483,
		"name": "Gävleborg County",
		"iso": "SE",
		"NumberOfSessions": 3
	},
	{
		"lat": 63.893930495515384,
		"lon": 19.09070278988085,
		"name": "Västerbotten County",
		"iso": "SE",
		"NumberOfSessions": 3
	},
	{
		"lat": 56.1666,
		"lon": 14.862200000000001,
		"name": "Blekinge County",
		"iso": "SE",
		"NumberOfSessions": 1
	},
	{
		"lat": 56.85275982436886,
		"lon": 14.376036603634047,
		"name": "Kronoberg County",
		"iso": "SE",
		"NumberOfSessions": 6
	},
	{
		"lat": 59.19985187460229,
		"lon": 15.169639857230628,
		"name": "Örebro County",
		"iso": "SE",
		"NumberOfSessions": 6
	},
	{
		"lat": 55.802766702730764,
		"lon": 13.130626064473132,
		"name": "Skåne County",
		"iso": "SE",
		"NumberOfSessions": 17
	},
	{
		"lat": 58.498086267577115,
		"lon": 15.916806588671239,
		"name": "Östergötland County",
		"iso": "SE",
		"NumberOfSessions": 2
	},
	{
		"lat": 59.32815262961974,
		"lon": 18.003061386799278,
		"name": "Stockholm County",
		"iso": "SE",
		"NumberOfSessions": 69
	},
	{
		"lat": 62.544271553699325,
		"lon": 17.60182686558014,
		"name": "Västernorrland County",
		"iso": "SE",
		"NumberOfSessions": 7
	},
	{
		"lat": 56.6591,
		"lon": 16.3569,
		"name": "Kalmar",
		"iso": "SE",
		"NumberOfSessions": 1
	},
	{
		"lat": 60.49326122718366,
		"lon": 15.39987423194123,
		"name": "Dalarna County",
		"iso": "SE",
		"NumberOfSessions": 4
	},
	{
		"lat": 59.644886435640956,
		"lon": 16.56559197632338,
		"name": "Västmanland County",
		"iso": "SE",
		"NumberOfSessions": 6
	},
	{
		"lat": 57.82695006293134,
		"lon": 14.141596598549638,
		"name": "Jönköping",
		"iso": "SE",
		"NumberOfSessions": 2
	},
	{
		"lat": 65.78039809039295,
		"lon": 21.973766656533847,
		"name": "Norrbotten County",
		"iso": "SE",
		"NumberOfSessions": 21
	},
	{
		"lat": 57.96820884285828,
		"lon": 12.49575714698955,
		"name": "Västra Götaland County",
		"iso": "SE",
		"NumberOfSessions": 39
	},
	{
		"lat": 60.03632686830263,
		"lon": 17.734901423243592,
		"name": "Uppsala County",
		"iso": "SE",
		"NumberOfSessions": 7
	},
	{
		"lat": 46.2285,
		"lon": 14.6132,
		"name": "Kamnik",
		"iso": "SI",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.65059999999998,
		"lon": 16.291999999999994,
		"name": "Lendava",
		"iso": "SI",
		"NumberOfSessions": 16
	},
	{
		"lat": 46.368,
		"lon": 14.1074,
		"name": "Občina Bled",
		"iso": "SI",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.228600000000014,
		"lon": 15.257700000000002,
		"name": "Celje",
		"iso": "SI",
		"NumberOfSessions": 3
	},
	{
		"lat": 46.2383,
		"lon": 14.3524,
		"name": "Kranj",
		"iso": "SI",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.374599999999994,
		"lon": 15.084199999999996,
		"name": "Velenje",
		"iso": "SI",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.3932,
		"lon": 15.566000000000003,
		"name": "Slovenska Bistrica",
		"iso": "SI",
		"NumberOfSessions": 2
	},
	{
		"lat": 46.555400000000006,
		"lon": 15.6465,
		"name": "Maribor City Municipality",
		"iso": "SI",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.05029999999999,
		"lon": 14.504599999999988,
		"name": "Ljubljana",
		"iso": "SI",
		"NumberOfSessions": 24
	},
	{
		"lat": 46.181300000000014,
		"lon": 13.737100000000003,
		"name": "Občina Tolmin",
		"iso": "SI",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.1948,
		"lon": 14.5573,
		"name": "Komenda",
		"iso": "SI",
		"NumberOfSessions": 1
	},
	{
		"lat": 46.4171,
		"lon": 15.8684,
		"name": "Ptuj",
		"iso": "SI",
		"NumberOfSessions": 1
	},
	{
		"lat": 48.15635688270206,
		"lon": 17.10262750120434,
		"name": "Bratislava Region",
		"iso": "SK",
		"NumberOfSessions": 63
	},
	{
		"lat": 48.33801468453646,
		"lon": 17.646642527403852,
		"name": "Trnava Region",
		"iso": "SK",
		"NumberOfSessions": 13
	},
	{
		"lat": 48.820033283103186,
		"lon": 18.316675284507987,
		"name": "Trenčín Region",
		"iso": "SK",
		"NumberOfSessions": 4
	},
	{
		"lat": 48.27210674379278,
		"lon": 18.181225020490544,
		"name": "Nitra Region",
		"iso": "SK",
		"NumberOfSessions": 4
	},
	{
		"lat": 48.7315334473546,
		"lon": 21.25986840594669,
		"name": "Košice Region",
		"iso": "SK",
		"NumberOfSessions": 3
	},
	{
		"lat": 49.20987528103702,
		"lon": 18.89025846399519,
		"name": "Žilina Region",
		"iso": "SK",
		"NumberOfSessions": 15
	},
	{
		"lat": 48.738600000000005,
		"lon": 19.1477,
		"name": "Banská Bystrica Region",
		"iso": "SK",
		"NumberOfSessions": 3
	},
	{
		"lat": 49.09425666222494,
		"lon": 21.128117639158717,
		"name": "Prešov Region",
		"iso": "SK",
		"NumberOfSessions": 4
	},
	{
		"lat": 7.95,
		"lon": 98.3564,
		"name": "Phuket",
		"iso": "TH",
		"NumberOfSessions": 2
	},
	{
		"lat": 13.919499999999996,
		"lon": 102.018,
		"name": "Sa Kaeo",
		"iso": "TH",
		"NumberOfSessions": 2
	},
	{
		"lat": 15.116300000000003,
		"lon": 104.1456,
		"name": "Si Sa Ket",
		"iso": "TH",
		"NumberOfSessions": 2
	},
	{
		"lat": 16.0147,
		"lon": 103.215,
		"name": "Maha Sarakham",
		"iso": "TH",
		"NumberOfSessions": 1
	},
	{
		"lat": 14.368504605148427,
		"lon": 100.56308601815226,
		"name": "Phra Nakhon Si Ayutthaya",
		"iso": "TH",
		"NumberOfSessions": 3
	},
	{
		"lat": 13.866700000000002,
		"lon": 100.1917,
		"name": "Nakhon Pathom",
		"iso": "TH",
		"NumberOfSessions": 2
	},
	{
		"lat": 12.6774,
		"lon": 101.2576,
		"name": "Rayong",
		"iso": "TH",
		"NumberOfSessions": 1
	},
	{
		"lat": 13.0508,
		"lon": 100.9367,
		"name": "Chon Buri",
		"iso": "TH",
		"NumberOfSessions": 5
	},
	{
		"lat": 16.2912,
		"lon": 102.6162,
		"name": "Khon Kaen",
		"iso": "TH",
		"NumberOfSessions": 2
	},
	{
		"lat": 13.745757684948094,
		"lon": 100.50387279813158,
		"name": "Bangkok",
		"iso": "TH",
		"NumberOfSessions": 69
	},
	{
		"lat": 18.792899999999996,
		"lon": 99.0004,
		"name": "Chiang Mai",
		"iso": "TH",
		"NumberOfSessions": 8
	},
	{
		"lat": 14.071200000000003,
		"lon": 100.6549,
		"name": "Pathum Thani",
		"iso": "TH",
		"NumberOfSessions": 1
	},
	{
		"lat": 13.599999999999996,
		"lon": 100.7164,
		"name": "Samut Prakan",
		"iso": "TH",
		"NumberOfSessions": 1
	},
	{
		"lat": 7.020200000000002,
		"lon": 100.4718,
		"name": "Songkhla",
		"iso": "TH",
		"NumberOfSessions": 2
	},
	{
		"lat": 15.0598,
		"lon": 102.2215,
		"name": "Nakhon Ratchasima",
		"iso": "TH",
		"NumberOfSessions": 1
	},
	{
		"lat": 14.802500000000007,
		"lon": 100.6181,
		"name": "Lopburi",
		"iso": "TH",
		"NumberOfSessions": 1
	},
	{
		"lat": 14.879799999999994,
		"lon": 103.4925,
		"name": "Surin",
		"iso": "TH",
		"NumberOfSessions": 1
	},
	{
		"lat": 36.831900000000005,
		"lon": 37.9918,
		"name": "Gaziantep",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 38.4207,
		"lon": 43.2328,
		"name": "Van",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 37.2167,
		"lon": 28.3674,
		"name": "Muğla",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 36.89230997461257,
		"lon": 30.685665354555752,
		"name": "Antalya",
		"iso": "TR",
		"NumberOfSessions": 10
	},
	{
		"lat": 40.09190000000001,
		"lon": 41.35520000000002,
		"name": "Erzurum",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 37.91575002033139,
		"lon": 32.42185059885925,
		"name": "Konya",
		"iso": "TR",
		"NumberOfSessions": 4
	},
	{
		"lat": 38.6645,
		"lon": 27.3294,
		"name": "Manisa",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 38.6992,
		"lon": 30.692100000000007,
		"name": "Afyonkarahisar Province",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 37.61740000000001,
		"lon": 30.28220000000001,
		"name": "Burdur",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 40.7782,
		"lon": 30.4017,
		"name": "Sakarya",
		"iso": "TR",
		"NumberOfSessions": 5
	},
	{
		"lat": 40.21871804425083,
		"lon": 28.953649277433804,
		"name": "Bursa Province",
		"iso": "TR",
		"NumberOfSessions": 9
	},
	{
		"lat": 41.266,
		"lon": 31.728500000000004,
		"name": "Zonguldak Province",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 37.2588,
		"lon": 36.3837,
		"name": "Osmaniye",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 39.934562380690934,
		"lon": 32.83379336963413,
		"name": "Ankara",
		"iso": "TR",
		"NumberOfSessions": 16
	},
	{
		"lat": 40.77108217349578,
		"lon": 30.100393545138466,
		"name": "Kocaeli",
		"iso": "TR",
		"NumberOfSessions": 4
	},
	{
		"lat": 36.35017829200943,
		"lon": 36.287491044003936,
		"name": "Hatay",
		"iso": "TR",
		"NumberOfSessions": 3
	},
	{
		"lat": 38.32025163025689,
		"lon": 38.376752278219655,
		"name": "Malatya",
		"iso": "TR",
		"NumberOfSessions": 3
	},
	{
		"lat": 40.8049,
		"lon": 31.606199999999987,
		"name": "Bolu",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 41.04940000000001,
		"lon": 27.3014,
		"name": "Tekirdağ",
		"iso": "TR",
		"NumberOfSessions": 2
	},
	{
		"lat": 41.2508,
		"lon": 32.6991,
		"name": "Karabük Province",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 41.028623278153084,
		"lon": 28.951481156210356,
		"name": "Istanbul",
		"iso": "TR",
		"NumberOfSessions": 74
	},
	{
		"lat": 36.96586289589831,
		"lon": 35.29204761065241,
		"name": "Adana",
		"iso": "TR",
		"NumberOfSessions": 5
	},
	{
		"lat": 37.86215846800737,
		"lon": 27.79386053476315,
		"name": "Aydın",
		"iso": "TR",
		"NumberOfSessions": 2
	},
	{
		"lat": 41.6767,
		"lon": 26.594400000000004,
		"name": "Edirne",
		"iso": "TR",
		"NumberOfSessions": 2
	},
	{
		"lat": 38.6167,
		"lon": 34.6333,
		"name": "Nevşehir Province",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 39.7302,
		"lon": 30.534500000000005,
		"name": "Eskişehir",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 38.7483,
		"lon": 35.5301,
		"name": "Kayseri",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 41.424099999999996,
		"lon": 33.7322,
		"name": "Kastamonu",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 36.78157401749773,
		"lon": 34.619647672105096,
		"name": "Mersin",
		"iso": "TR",
		"NumberOfSessions": 3
	},
	{
		"lat": 38.44099596534115,
		"lon": 27.131787648314358,
		"name": "İzmir Province",
		"iso": "TR",
		"NumberOfSessions": 18
	},
	{
		"lat": 38.6338,
		"lon": 39.312200000000004,
		"name": "Elazığ",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 39.642400000000016,
		"lon": 27.8106,
		"name": "Balıkesir",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 41.2477,
		"lon": 36.2395,
		"name": "Samsun",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 37.9414,
		"lon": 40.1933,
		"name": "Diyarbakır Province",
		"iso": "TR",
		"NumberOfSessions": 1
	},
	{
		"lat": 24.14399999999999,
		"lon": 120.6844,
		"name": "Taichung City",
		"iso": "TW",
		"NumberOfSessions": 13
	},
	{
		"lat": 24.754070136793516,
		"lon": 121.0835508107977,
		"name": "Hsinchu County",
		"iso": "TW",
		"NumberOfSessions": 2
	},
	{
		"lat": 22.614799999999995,
		"lon": 120.3139,
		"name": "Kaohsiung",
		"iso": "TW",
		"NumberOfSessions": 4
	},
	{
		"lat": 24.5641,
		"lon": 120.8275,
		"name": "Miaoli",
		"iso": "TW",
		"NumberOfSessions": 1
	},
	{
		"lat": 24.0759,
		"lon": 120.5657,
		"name": "Changhua",
		"iso": "TW",
		"NumberOfSessions": 1
	},
	{
		"lat": 24.96598586562078,
		"lon": 121.25601437036372,
		"name": "Taoyuan",
		"iso": "TW",
		"NumberOfSessions": 6
	},
	{
		"lat": 23.5748,
		"lon": 119.6098,
		"name": "Penghu County",
		"iso": "TW",
		"NumberOfSessions": 1
	},
	{
		"lat": 24.981673228320467,
		"lon": 121.55821375291337,
		"name": "New Taipei City",
		"iso": "TW",
		"NumberOfSessions": 9
	},
	{
		"lat": 22.9917,
		"lon": 120.2148,
		"name": "Tainan",
		"iso": "TW",
		"NumberOfSessions": 3
	},
	{
		"lat": 25.049460000715715,
		"lon": 121.53250501529189,
		"name": "Taipei City",
		"iso": "TW",
		"NumberOfSessions": 20
	},
	{
		"lat": 48.11555644034771,
		"lon": 37.75726195630694,
		"name": "Donetsk",
		"iso": "UA",
		"NumberOfSessions": 7
	},
	{
		"lat": 44.608900000000006,
		"lon": 33.53,
		"name": "Sebastopol City",
		"iso": "UA",
		"NumberOfSessions": 2
	},
	{
		"lat": 48.99225049342772,
		"lon": 24.72949506867417,
		"name": "Ivano-Frankivsk Oblast",
		"iso": "UA",
		"NumberOfSessions": 30
	},
	{
		"lat": 44.9301267745934,
		"lon": 34.1023179246142,
		"name": "Crimea",
		"iso": "UA",
		"NumberOfSessions": 14
	},
	{
		"lat": 49.4729130703244,
		"lon": 25.435459453446043,
		"name": "Ternopil Oblast",
		"iso": "UA",
		"NumberOfSessions": 8
	},
	{
		"lat": 48.42684779259503,
		"lon": 39.34233997137633,
		"name": "Luhansk",
		"iso": "UA",
		"NumberOfSessions": 5
	},
	{
		"lat": 50.230173746226804,
		"lon": 28.675989211555944,
		"name": "Zhytomyr",
		"iso": "UA",
		"NumberOfSessions": 9
	},
	{
		"lat": 48.2932,
		"lon": 25.9448,
		"name": "Chernivtsi",
		"iso": "UA",
		"NumberOfSessions": 3
	},
	{
		"lat": 48.346091477865095,
		"lon": 34.69198645017324,
		"name": "Dnipropetrovsk Oblast",
		"iso": "UA",
		"NumberOfSessions": 39
	},
	{
		"lat": 46.31323166099641,
		"lon": 30.43817035916864,
		"name": "Odessa",
		"iso": "UA",
		"NumberOfSessions": 34
	},
	{
		"lat": 49.154951913341755,
		"lon": 28.356854820455254,
		"name": "Vinnytsia",
		"iso": "UA",
		"NumberOfSessions": 16
	},
	{
		"lat": 50.98686456335559,
		"lon": 25.02086095256466,
		"name": "Volyn",
		"iso": "UA",
		"NumberOfSessions": 4
	},
	{
		"lat": 50.6223,
		"lon": 26.239599999999996,
		"name": "Rivne",
		"iso": "UA",
		"NumberOfSessions": 3
	},
	{
		"lat": 51.43803590382417,
		"lon": 33.85385369363123,
		"name": "Sumy",
		"iso": "UA",
		"NumberOfSessions": 7
	},
	{
		"lat": 50.46055516459879,
		"lon": 30.529390095765653,
		"name": "Kyiv City",
		"iso": "UA",
		"NumberOfSessions": 118
	},
	{
		"lat": 48.45443274198181,
		"lon": 22.801037729283177,
		"name": "Zakarpattia Oblast",
		"iso": "UA",
		"NumberOfSessions": 3
	},
	{
		"lat": 50.29107514786729,
		"lon": 30.378467971031704,
		"name": "Kyiv Oblast",
		"iso": "UA",
		"NumberOfSessions": 23
	},
	{
		"lat": 48.56952893050078,
		"lon": 32.354960104829885,
		"name": "Kirovohrad Oblast",
		"iso": "UA",
		"NumberOfSessions": 10
	},
	{
		"lat": 49.58517499732301,
		"lon": 34.55861141538149,
		"name": "Poltava Oblast",
		"iso": "UA",
		"NumberOfSessions": 15
	},
	{
		"lat": 49.409321398980524,
		"lon": 27.02248358288357,
		"name": "Khmelnytskyi Oblast",
		"iso": "UA",
		"NumberOfSessions": 3
	},
	{
		"lat": 49.80840380033203,
		"lon": 23.96585078162207,
		"name": "Lviv",
		"iso": "UA",
		"NumberOfSessions": 23
	},
	{
		"lat": 49.94665064131766,
		"lon": 36.221879303177474,
		"name": "Kharkivs’ka Oblast’",
		"iso": "UA",
		"NumberOfSessions": 25
	},
	{
		"lat": 47.80223583372273,
		"lon": 35.05738034319209,
		"name": "Zaporizhzhia",
		"iso": "UA",
		"NumberOfSessions": 9
	},
	{
		"lat": 47.042336625328126,
		"lon": 31.920651595333627,
		"name": "Mykolaiv",
		"iso": "UA",
		"NumberOfSessions": 8
	},
	{
		"lat": 51.203212135766464,
		"lon": 31.663059929111355,
		"name": "Chernihiv",
		"iso": "UA",
		"NumberOfSessions": 3
	},
	{
		"lat": 49.32554374033726,
		"lon": 31.624083471552552,
		"name": "Cherkasy Oblast",
		"iso": "UA",
		"NumberOfSessions": 10
	},
	{
		"lat": 41.43033659257373,
		"lon": -97.76933578103696,
		"name": "Nebraska",
		"iso": "US",
		"NumberOfSessions": 106
	},
	{
		"lat": 47.411560476128294,
		"lon": -121.95908161680019,
		"name": "Washington",
		"iso": "US",
		"NumberOfSessions": 352
	},
	{
		"lat": 35.465805622122,
		"lon": -79.9967387066835,
		"name": "North Carolina",
		"iso": "US",
		"NumberOfSessions": 338
	},
	{
		"lat": 33.6100304207923,
		"lon": -84.04927912647115,
		"name": "Georgia",
		"iso": "US",
		"NumberOfSessions": 355
	},
	{
		"lat": 35.42012277706846,
		"lon": -119.32658533908534,
		"name": "California",
		"iso": "US",
		"NumberOfSessions": 1337
	},
	{
		"lat": 44.84652306757983,
		"lon": -122.63105212727811,
		"name": "Oregon",
		"iso": "US",
		"NumberOfSessions": 218
	},
	{
		"lat": 21.3336492793374,
		"lon": -157.83311212294615,
		"name": "Hawaii",
		"iso": "US",
		"NumberOfSessions": 47
	},
	{
		"lat": 43.2354601150453,
		"lon": -71.45568090398967,
		"name": "New Hampshire",
		"iso": "US",
		"NumberOfSessions": 35
	},
	{
		"lat": 40.358035982447056,
		"lon": -82.94030525899258,
		"name": "Ohio",
		"iso": "US",
		"NumberOfSessions": 353
	},
	{
		"lat": 41.75551906882704,
		"lon": -71.42436583320278,
		"name": "Rhode Island",
		"iso": "US",
		"NumberOfSessions": 31
	},
	{
		"lat": 42.274950316748914,
		"lon": -71.27987317272259,
		"name": "Massachusetts",
		"iso": "US",
		"NumberOfSessions": 177
	},
	{
		"lat": 41.81149199527125,
		"lon": -93.34660480536431,
		"name": "Iowa",
		"iso": "US",
		"NumberOfSessions": 99
	},
	{
		"lat": 47.63833041123329,
		"lon": -99.04372522453227,
		"name": "North Dakota",
		"iso": "US",
		"NumberOfSessions": 25
	},
	{
		"lat": 32.92825427699891,
		"lon": -89.98492565752353,
		"name": "Mississippi",
		"iso": "US",
		"NumberOfSessions": 46
	},
	{
		"lat": 35.71681203310151,
		"lon": -96.98468502481855,
		"name": "Oklahoma",
		"iso": "US",
		"NumberOfSessions": 125
	},
	{
		"lat": 41.52078195869348,
		"lon": -88.16749740972266,
		"name": "Illinois",
		"iso": "US",
		"NumberOfSessions": 400
	},
	{
		"lat": 31.089644925009875,
		"lon": -97.4220474116474,
		"name": "Texas",
		"iso": "US",
		"NumberOfSessions": 964
	},
	{
		"lat": 41.45902091417961,
		"lon": -74.59305904630162,
		"name": "New York",
		"iso": "US",
		"NumberOfSessions": 574
	},
	{
		"lat": 44.06395872322823,
		"lon": -98.49957532734561,
		"name": "South Dakota",
		"iso": "US",
		"NumberOfSessions": 17
	},
	{
		"lat": 41.506508064610664,
		"lon": -72.87850293683216,
		"name": "Connecticut",
		"iso": "US",
		"NumberOfSessions": 73
	},
	{
		"lat": 45.067021923676464,
		"lon": -93.3190835154901,
		"name": "Minnesota",
		"iso": "US",
		"NumberOfSessions": 192
	},
	{
		"lat": 40.139982508782076,
		"lon": -86.28155096602379,
		"name": "Indiana",
		"iso": "US",
		"NumberOfSessions": 178
	},
	{
		"lat": 33.95740812687151,
		"lon": -81.1037863688474,
		"name": "South Carolina",
		"iso": "US",
		"NumberOfSessions": 119
	},
	{
		"lat": 37.809318250978016,
		"lon": -85.2643173123773,
		"name": "Kentucky",
		"iso": "US",
		"NumberOfSessions": 126
	},
	{
		"lat": 46.6988266432452,
		"lon": -112.01936764607312,
		"name": "Montana",
		"iso": "US",
		"NumberOfSessions": 24
	},
	{
		"lat": 39.65206495364751,
		"lon": -105.09012750861193,
		"name": "Colorado",
		"iso": "US",
		"NumberOfSessions": 253
	},
	{
		"lat": 42.75661987564985,
		"lon": -83.90999077963099,
		"name": "Michigan",
		"iso": "US",
		"NumberOfSessions": 309
	},
	{
		"lat": 39.430539390983405,
		"lon": -75.57132180280561,
		"name": "Delaware",
		"iso": "US",
		"NumberOfSessions": 30
	},
	{
		"lat": 43.855825448905414,
		"lon": -89.05723424369684,
		"name": "Wisconsin",
		"iso": "US",
		"NumberOfSessions": 215
	},
	{
		"lat": 38.62921753286001,
		"lon": -92.32988611128077,
		"name": "Missouri",
		"iso": "US",
		"NumberOfSessions": 184
	},
	{
		"lat": 36.65553097352555,
		"lon": -115.70986497291379,
		"name": "Nevada",
		"iso": "US",
		"NumberOfSessions": 105
	},
	{
		"lat": 35.869196024218034,
		"lon": -86.2854511765502,
		"name": "Tennessee",
		"iso": "US",
		"NumberOfSessions": 210
	},
	{
		"lat": 38.98952729583067,
		"lon": -80.71783847012206,
		"name": "West Virginia",
		"iso": "US",
		"NumberOfSessions": 49
	},
	{
		"lat": 43.70797258512809,
		"lon": -114.92948344321543,
		"name": "Idaho",
		"iso": "US",
		"NumberOfSessions": 45
	},
	{
		"lat": 38.57394350938788,
		"lon": -96.1854360431451,
		"name": "Kansas",
		"iso": "US",
		"NumberOfSessions": 77
	},
	{
		"lat": 35.46143513183114,
		"lon": -93.17673750587585,
		"name": "Arkansas",
		"iso": "US",
		"NumberOfSessions": 46
	},
	{
		"lat": 42.20476432598031,
		"lon": -106.72709846131148,
		"name": "Wyoming",
		"iso": "US",
		"NumberOfSessions": 24
	},
	{
		"lat": 28.042128616137006,
		"lon": -81.82713671257318,
		"name": "Florida",
		"iso": "US",
		"NumberOfSessions": 582
	},
	{
		"lat": 39.15416179007204,
		"lon": -76.788632613538,
		"name": "Maryland",
		"iso": "US",
		"NumberOfSessions": 192
	},
	{
		"lat": 30.655718963484777,
		"lon": -91.21530263323899,
		"name": "Louisiana",
		"iso": "US",
		"NumberOfSessions": 97
	},
	{
		"lat": 40.54471757094883,
		"lon": -111.91355539039833,
		"name": "Utah",
		"iso": "US",
		"NumberOfSessions": 200
	},
	{
		"lat": 34.84230904933199,
		"lon": -106.4641920373472,
		"name": "New Mexico",
		"iso": "US",
		"NumberOfSessions": 92
	},
	{
		"lat": 38.91343450246898,
		"lon": -77.01490463347668,
		"name": "District of Columbia",
		"iso": "US",
		"NumberOfSessions": 29
	},
	{
		"lat": 33.373903521316635,
		"lon": -111.90572741919027,
		"name": "Arizona",
		"iso": "US",
		"NumberOfSessions": 271
	},
	{
		"lat": 44.164543043755415,
		"lon": -72.96901439753734,
		"name": "Vermont",
		"iso": "US",
		"NumberOfSessions": 14
	},
	{
		"lat": 40.323364804897594,
		"lon": -76.56973587251804,
		"name": "Pennsylvania",
		"iso": "US",
		"NumberOfSessions": 363
	},
	{
		"lat": 40.44538448897218,
		"lon": -74.42365938296086,
		"name": "New Jersey",
		"iso": "US",
		"NumberOfSessions": 226
	},
	{
		"lat": 61.59857809018671,
		"lon": -148.75941365742653,
		"name": "Alaska",
		"iso": "US",
		"NumberOfSessions": 22
	},
	{
		"lat": 33.25411096075482,
		"lon": -86.79725349543075,
		"name": "Alabama",
		"iso": "US",
		"NumberOfSessions": 122
	},
	{
		"lat": 38.69945139097027,
		"lon": -77.53050661945215,
		"name": "Virginia",
		"iso": "US",
		"NumberOfSessions": 990
	},
	{
		"lat": 44.13000095959482,
		"lon": -69.87987011395094,
		"name": "Maine",
		"iso": "US",
		"NumberOfSessions": 58
	},
	{
		"lat": 8.6311,
		"lon": -70.2079,
		"name": "Barinas",
		"iso": "VE",
		"NumberOfSessions": 2
	},
	{
		"lat": 10.180953425468202,
		"lon": -67.49260122827468,
		"name": "Aragua",
		"iso": "VE",
		"NumberOfSessions": 2
	},
	{
		"lat": 9.3148,
		"lon": -70.6081,
		"name": "Estado Trujillo",
		"iso": "VE",
		"NumberOfSessions": 1
	},
	{
		"lat": 10.1825,
		"lon": -68.0172,
		"name": "Carabobo",
		"iso": "VE",
		"NumberOfSessions": 8
	},
	{
		"lat": 10.54705741299952,
		"lon": -71.53757959197314,
		"name": "Zulia",
		"iso": "VE",
		"NumberOfSessions": 6
	},
	{
		"lat": 9.560600130354178,
		"lon": -69.21044989331975,
		"name": "Portuguesa",
		"iso": "VE",
		"NumberOfSessions": 2
	},
	{
		"lat": 10.1369,
		"lon": -64.6864,
		"name": "Anzoátegui",
		"iso": "VE",
		"NumberOfSessions": 1
	},
	{
		"lat": 9.7477,
		"lon": -63.18189999999999,
		"name": "Monagas",
		"iso": "VE",
		"NumberOfSessions": 1
	},
	{
		"lat": 10.95,
		"lon": -63.849999999999994,
		"name": "Nueva Esparta",
		"iso": "VE",
		"NumberOfSessions": 1
	},
	{
		"lat": 10.066399999999998,
		"lon": -69.3586,
		"name": "Lara",
		"iso": "VE",
		"NumberOfSessions": 1
	},
	{
		"lat": 8.5997,
		"lon": -71.1445,
		"name": "Mérida",
		"iso": "VE",
		"NumberOfSessions": 1
	},
	{
		"lat": 10.080799999999996,
		"lon": -69.1297,
		"name": "Yaracuy",
		"iso": "VE",
		"NumberOfSessions": 1
	},
	{
		"lat": 10.246199999999996,
		"lon": -66.4165,
		"name": "Miranda",
		"iso": "VE",
		"NumberOfSessions": 2
	},
	{
		"lat": 10.487299999999998,
		"lon": -66.8738,
		"name": "Distrito Federal",
		"iso": "VE",
		"NumberOfSessions": 6
	},
	{
		"lat": 10.5998,
		"lon": -66.9373,
		"name": "Vargas",
		"iso": "VE",
		"NumberOfSessions": 4
	},
	{
		"lat": 10.822000000000003,
		"lon": 106.62570000000001,
		"name": "Ho Chi Minh",
		"iso": "VN",
		"NumberOfSessions": 47
	},
	{
		"lat": 11.170099999999996,
		"lon": 106.6687,
		"name": "Bình Dương Province",
		"iso": "VN",
		"NumberOfSessions": 8
	},
	{
		"lat": 12.007300000000003,
		"lon": 107.6903,
		"name": "Đăk Nông Province",
		"iso": "VN",
		"NumberOfSessions": 1
	},
	{
		"lat": 10.344400000000004,
		"lon": 107.0875,
		"name": "Bà Rịa–Vũng Tàu Province",
		"iso": "VN",
		"NumberOfSessions": 1
	},
	{
		"lat": 20.8559,
		"lon": 106.6697,
		"name": "Haiphong",
		"iso": "VN",
		"NumberOfSessions": 2
	},
	{
		"lat": 16.0685,
		"lon": 108.22150000000002,
		"name": "Da Nang",
		"iso": "VN",
		"NumberOfSessions": 1
	},
	{
		"lat": 10.0359,
		"lon": 105.7808,
		"name": "Can Tho",
		"iso": "VN",
		"NumberOfSessions": 5
	},
	{
		"lat": 10.941300000000004,
		"lon": 106.8221,
		"name": "Đồng Nai Province",
		"iso": "VN",
		"NumberOfSessions": 2
	},
	{
		"lat": 19.797,
		"lon": 105.7674,
		"name": "Thanh Hóa Province",
		"iso": "VN",
		"NumberOfSessions": 1
	},
	{
		"lat": 12.253900000000003,
		"lon": 109.1832,
		"name": "Khánh Hòa Province",
		"iso": "VN",
		"NumberOfSessions": 2
	},
	{
		"lat": 10.362800000000002,
		"lon": 105.5174,
		"name": "Đồng Tháp Province",
		"iso": "VN",
		"NumberOfSessions": 2
	},
	{
		"lat": 21.029200000000017,
		"lon": 105.85260000000001,
		"name": "Hanoi",
		"iso": "VN",
		"NumberOfSessions": 56
	},
	{
		"lat": 21.5941,
		"lon": 105.8432,
		"name": "Thái Nguyên Province",
		"iso": "VN",
		"NumberOfSessions": 1
	},
	{
		"lat": 22.6652,
		"lon": 106.2541,
		"name": "Cao Bằng Province",
		"iso": "VN",
		"NumberOfSessions": 1
	}
]