import React, { useState, useEffect } from 'react';
import { useEmailRemarketing } from '../context/contextEmailRemarketing';
import subNavStyles from './ListEmailRemarketing.module.css';
import EmailRemarketingConfiguration from '../../../Tooling/EmailRemarketingConfiguration/EmailRemarketingConfiguration.js';
import TableGridContainer from '../../../../Components/Table/TableGridContainer';
import TableHeadRow from '../../../../Components/Table/TableHeadRow';
import TableHeadCol from '../../../../Components/Table/TableHeadCol';
import EmptyState from '../../../../Components/EmptyState';
import Confirm from '../../../../Components/Confirm';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
const tableRowStyle = {
	gridTemplateColumns: '2.7fr 140px 1fr 1fr 2fr 1fr',
	alignItems: 'center',
	justifyContent: 'flex-start',
};

const centerStyle = {
	display: 'flex',
	alignItems: 'center',
	height: '100%',
};

interface EmailRemarketingStatisticsTotal {
  numberOfEmailsSent: number;
  numberOfEmailsDelivered: number;
  numberOfEmailsOpened: number;
  numberOfEmailsClicked: number;
  __typename: string;
}

interface EmailRemarketingStatistics {
  total: EmailRemarketingStatisticsTotal;
  byDate: any[];
  __typename: string;
}

interface EmailRemarketingCampaign {
  id: string;
  name: string;
  description: string | null;
  searchId: string;
  isStopped: boolean;
  startDate: string;
  endDate: string;
  creationDate: string;
  statistics: EmailRemarketingStatistics;
  __typename: string;
}

interface DataCRM {
  CampaignAccount: {
    Campaigns: EmailRemarketingCampaign[];
  };
}

const ListEmailRemarketing: React.FC = (props) => {
	const [dataCRM, setDataCRM] = useState<DataCRM>({ CampaignAccount: { Campaigns: [] } });
	const [sortType, setSortType] = useState<string>('');
	const [sortReverseCRM, setSortReverseCRM] = useState<boolean>(false);
	const [loadingProcessCampaignCRM, setLoadingProcessCampaignCRM] = useState<boolean>(true);
	const [openSubMenuId, setOpenSubMenuId] = useState<string | null>(null);
	const [modalCampaignToDeleteIsOpen, setModalCampaignToDelete] = useState(false);
	const [currentCampaign, setCurrentCampaign] = useState<any>({});
	const [t] = useTranslation('common');
	const {
		campaigns,
		stopCampaignCRM,
		goCampaignCRM,
		duplicateCampaign,
		removeCampaign,
		showConfigurationEmr,
		showPopinConfigurationEmr,
		idOfTheCampaign,
		campaignName,
		campaignsSorted,
		value
	} = useEmailRemarketing();

	useEffect(() => {
		if (campaignsSorted) {
			setDataCRM({ CampaignAccount: { Campaigns: campaignsSorted } });
			setLoadingProcessCampaignCRM(false);
		}
	}, [campaigns, campaignsSorted]);

	const handleSort = (type: string) => {
		setSortType(type);
		setSortReverseCRM(!sortReverseCRM);
	};

	const toggleSubMenu = (campaignId: string) => {
		setOpenSubMenuId(openSubMenuId === campaignId ? null : campaignId);
	};

	const onLoad = () => {
		showConfigurationEmr('', '', false);
	};

	const sortCampaigns = (campaigns: EmailRemarketingCampaign[]) => {
		return campaigns.sort((a, b) => {
			let comparison = 0;
			if (sortType === 'name') {
				comparison = a.name.localeCompare(b.name);
			} else if (sortType === 'numberOfEmailsSent') {
				comparison = a.statistics.total.numberOfEmailsSent - b.statistics.total.numberOfEmailsSent;
			} else if (sortType === 'numberOfEmailsDelivered') {
				comparison = a.statistics.total.numberOfEmailsDelivered - b.statistics.total.numberOfEmailsDelivered;
			} else if (sortType === 'numberOfEmailsOpened') {
				comparison = a.statistics.total.numberOfEmailsOpened - b.statistics.total.numberOfEmailsOpened;
			} else if (sortType === 'numberOfEmailsClicked') {
				comparison = a.statistics.total.numberOfEmailsClicked - b.statistics.total.numberOfEmailsClicked;
			}
			return sortReverseCRM ? -comparison : comparison;
		});
	};

	const renderCampaigns = () => {
		if (loadingProcessCampaignCRM) {
			return (
				<div className="loading">
					<p>Loading campaigns...</p>
				</div>
			);
		}
		if(dataCRM.CampaignAccount.Campaigns.length === 0 && value !==  '') {
			return (<>
				<EmptyState
					icon="fas fa-search"
					title="No matching campaigns"
					text='Please try with another search'
					textSize='l'
					verticalSize='l'
				/>
			</>);
		}


		if (dataCRM.CampaignAccount.Campaigns.length === 0 && value === '') {
			return (
				<div className="empty_page has_picto">
					<div className="empty_page_picto empty_page_picto_email_remarketing"></div>
					<div className="empty_page_title">Email remarketing</div>
					<div className="empty_page_text">
            Stay in touch with your most valuable visitors and generate additional income with abandoned cart email automations!
					</div>
					<div className="empty_page_btn">
						<a className="btnUi btn_primary btn_l btn_fs_l" href="/CampaignsCrmOnSite/Create">
							<i className="fa fa-plus"></i>
							<span className="btn_txt">Create a remarketing campaign</span>
						</a>
					</div>
				</div>
			);
		}
		function showDeleteCampaign(cp){
			setCurrentCampaign(cp);
			setModalCampaignToDelete(true);
		}
		function deleteCampaign (){
			removeCampaign('crm', currentCampaign, currentCampaign?.name);
		}
		const sortedCampaigns = sortCampaigns(dataCRM.CampaignAccount.Campaigns);

		return (
			<React.Fragment>
				<EmailRemarketingConfiguration
					idOfCampaign={idOfTheCampaign}
					campaignName={campaignName}
					onLoad={onLoad}
					{...props}
					isOppen={showPopinConfigurationEmr}
				/>
				<Confirm
					title={t('EditCampaignDelete.doYouWant')}
					text={currentCampaign?.name}
					isOpen={modalCampaignToDeleteIsOpen}
					onClose={() => setModalCampaignToDelete(false)}
					confirmCallback={() => deleteCampaign()}
					confirmText={t('EditCampaignDelete.delete')}
					cancelText={t('EditCampaignDelete.cancel')}
					confirmColor = "alert"
				/>
				<TableGridContainer hasAlternatingBackground={true} specialClassName={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
					<TableHeadRow style={tableRowStyle} isSticky={true}>
						<TableHeadCol onClick={() => handleSort('name')} fontSize='s'>
              Title {sortType === 'name' && <i className={`fa fa-caret-${sortReverseCRM ? 'up' : 'down'}`}></i>}
						</TableHeadCol>
						<TableHeadCol onClick={() => handleSort('numberOfEmailsSent')} fontSize='s'>
              Sent {sortType === 'numberOfEmailsSent' && <i className={`fa fa-caret-${sortReverseCRM ? 'up' : 'down'}`}></i>}
						</TableHeadCol>
						<TableHeadCol onClick={() => handleSort('numberOfEmailsDelivered')} fontSize='s'>
              Delivered {sortType === 'numberOfEmailsDelivered' && <i className={`fa fa-caret-${sortReverseCRM ? 'up' : 'down'}`}></i>}
						</TableHeadCol>
						<TableHeadCol onClick={() => handleSort('numberOfEmailsOpened')} fontSize='s'>
              Opened {sortType === 'numberOfEmailsOpened' && <i className={`fa fa-caret-${sortReverseCRM ? 'up' : 'down'}`}></i>}
						</TableHeadCol>
						<TableHeadCol onClick={() => handleSort('numberOfEmailsClicked')} fontSize='s'>
              Clicked {sortType === 'numberOfEmailsClicked' && <i className={`fa fa-caret-${sortReverseCRM ? 'up' : 'down'}`}></i>}
						</TableHeadCol>
						<TableHeadCol fontSize='s'>Actions</TableHeadCol>
					</TableHeadRow>
					<div className="grid-head"></div>
					{sortedCampaigns.map((campaign, index) => (
						<React.Fragment key={index}>
							<TableHeadRow style={tableRowStyle}>
								<TableHeadCol style={centerStyle}>
									<div className="flex flex_v">
										<p className="campaign-title">{campaign.name}</p>
										<div className="campaign-id">
											<p className="by_code">
                        ID : <span>{campaign.id}</span> <i className="far fa-clipboard"></i>
											</p>
										</div>
										<div className="campaign-detail">
											<div className="campaign-status" dangerouslySetInnerHTML={{ __html: campaign.isStopped ? 'Stopped' : 'Active' }}></div>
											<p className="campaign-date">
												{moment(campaign.startDate).format('DD/MM/YYYY')} - {moment(campaign.endDate).format('DD/MM/YYYY')}
											</p>
										</div>
										<p className="campaign-description">{campaign.description}</p>
									</div>
								</TableHeadCol>
								<TableHeadCol style={centerStyle}>
									{campaign.statistics.total.numberOfEmailsSent > 0 ? campaign.statistics.total.numberOfEmailsSent : '-'}
								</TableHeadCol>
								<TableHeadCol style={centerStyle}>
									{campaign.statistics.total.numberOfEmailsDelivered > 0 ? campaign.statistics.total.numberOfEmailsDelivered : '-'}
								</TableHeadCol>
								<TableHeadCol style={centerStyle}>
									{campaign.statistics.total.numberOfEmailsOpened > 0 ? campaign.statistics.total.numberOfEmailsOpened : '-'}
								</TableHeadCol>
								<TableHeadCol style={centerStyle}>
									{campaign.statistics.total.numberOfEmailsClicked > 0 ? campaign.statistics.total.numberOfEmailsClicked : '-'}
								</TableHeadCol>
								<TableHeadCol style={centerStyle}>
									<div className={subNavStyles.sub_nav}>
										<button onClick={() => toggleSubMenu(campaign.id)}>
											<i className="fa fa-cog"></i>
										</button>
										{openSubMenuId === campaign.id && (
											<ul className={subNavStyles.sub_menu}>
												<li>
													<div  onClick={() => stopCampaignCRM(campaign.id)}>
														<i className="fa fa-pause"></i> <span>Stop</span>
													</div>
												</li>
												<li>
													<div  onClick={() => goCampaignCRM(campaign.id)}>
														<i className="fa fa-play"></i> <span>Go</span>
													</div>
												</li>
												<li>
													<a href={`/CampaignsCrmOnSite/Create/${campaign.id}`}>
														<i className="fa fa-pen"></i> <span>Edit</span>
													</a>
												</li>
												<li>
													<a href={`/EmailingReporting/?id=${campaign.id}`}>
														<i className="fa fa-chart-area"></i> <span>Stats</span>
													</a>
												</li>
												<li>
													<div  onClick={() => duplicateCampaign(campaign)}>
														<i className="fa fa-plus"></i> <span>Duplicate</span>
													</div>
												</li>
												<li>
													<div  onClick={()=>showDeleteCampaign(campaign)}>
														<i className="fa fa-trash"></i> <span>Delete</span>
													</div>
												</li>
												<li>
													<div onClick={() => showConfigurationEmr(campaign.id, campaign.name, true)}>
														<i className="far fa-envelope-open"></i> <span>Config</span>
													</div>
												</li>
											</ul>
										)}
									</div>
								</TableHeadCol>
							</TableHeadRow>
						</React.Fragment>
					))}
				</TableGridContainer>
			</React.Fragment>
		);
	};

	return <>{renderCampaigns()}</>;
};

export default ListEmailRemarketing;