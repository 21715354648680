import React, { useEffect, useState } from 'react';
import { useProductInsightsContext } from '../../context/ContextProductInsights';
import { Section } from '../../../../Components/Section/Section';
import { Article } from '../../../../Components/Article/Article';
import TableGridContainer from '../../../../Components/Table/TableGridContainer';
import TableHeadRow from '../../../../Components/Table/TableHeadRow';
import TableHeadCol from '../../../../Components/Table/TableHeadCol';
import { Spinner } from '../../../../Components/Spinner/Spinner';
import SearchBar from '../../../../Components/SearchBar';
import { PageBreadcrumb } from '../../../../Components/Page/PageBreadcrumb';
import { EmptyState } from '../../../../Components/EmptyState/EmptyState';
import { SegmentItem } from './ProductItem';
import PaginationProduct from './PaginationProduct';
import NavGlobal from '../Nav/NavGlobal';
import {ProductItemCompared} from './ProductItemCompared';
import {FilterProductInsightsPanel} from '../FilterPanel/FilterProductInsightsPanel';
import Btn from '../../../../Components/Btn.js';
import Chip from'../Chip/Chip';
import {SESSION_STORAGE_KEY, transformToDataStructureArray} from '../utils';
import styles from '../ProductsInsights.module.css';
import {TopInsights} from './TopInsights';
import {AccountHasNoData} from '../EmptyStates/AccountHasNoData';
import { useTranslation } from 'react-i18next';

const tableRowStyle = {
	gridTemplateColumns: '2.7fr 150px 1fr 1fr 1fr 1fr 1fr 1fr',
};

export const KpiContainer = () => {
	if (location.pathname !== '/Insight/scoring') {
		return <></>;
	}

	const {
		isLoading,
		isFirstLoading,
		productsSelections,
		productsSelectionsType,
		paginationOptions,
		currentPageProductList,
		setCurrentPageProductList,
		totalCount,
		fetchExportKpis,
		isExporting,
		handleChangePaginationOptions,
		valueInSearchBar,
		handleChange,
		handleSelectComparaisonPeriodHandler,
		initialDates,
		setNavFilter,
		isCustomSelected,
		setisCustomSelected,
		customDateRange,
		setCustomDateRange,
		updateStorage,
		ComparisonSelected,
		handleChangeFormData,
		handleOpenClosePanelForm,
		panelFormIsOpen,
		filterHierarchies,
		selectedValues,
		setSelectedValues,
		chipsByFilterSelected,
		setFilterSelected,
		navFilter,
		accountHasNoDatas
	} = useProductInsightsContext();

	const [t] = useTranslation('kpi');

	function handleDeleteChip(id){
		const newObj = JSON.parse((JSON.stringify(selectedValues)));
		delete newObj[id];
		const newFilters = transformToDataStructureArray(newObj);
		sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(newObj));
		setFilterSelected(newFilters);
		setSelectedValues(newObj);
	}

	const [isEmpty, setIsEmpty] = useState(false);

	useEffect(() => {
		setIsEmpty(productsSelections.length === 0);
	}, [productsSelections]);

	return (
		<>
			{accountHasNoDatas && <AccountHasNoData/>}
			{!accountHasNoDatas &&	<>
				<FilterProductInsightsPanel
					selectedValues={selectedValues}
					setSelectedValues={setSelectedValues}
					filterHierarchies={filterHierarchies}
					panelFormIsOpen={panelFormIsOpen}
					handleOpenClosePanelForm={handleOpenClosePanelForm}
					handleChangeFormData={handleChangeFormData}
				/>
				<Section hasMargin={false} width="l">
					<section className='section no_bottom_pad section_primary'>
						<PageBreadcrumb>
							<span className='h1'>{t('productInsights.productInsights')}</span>
							<span className='h1'>{t('productInsights.scoring')}</span>
						</PageBreadcrumb>
					</section>
					<section className='section'>
						<div className={styles.filters_line}>
							<div className='btn_group_l'>
								<NavGlobal
									ComparisonSelected={ComparisonSelected}
									setNavFilter={setNavFilter}
									navFilter={navFilter}
									initialDates={initialDates}
									handleSelectComparaisonPeriodHandler={handleSelectComparaisonPeriodHandler}
									setisCustomSelected={setisCustomSelected}
									isCustomSelected={isCustomSelected}
									customDateRange={customDateRange}
									setCustomDateRange={setCustomDateRange}
									updateStorage={updateStorage}
								/>
							</div>
						</div>
						<TopInsights />
						<Article innerSize='l' hasMargin={false}>
							{isFirstLoading &&
								<Spinner display="block" verticalSize="xl" />
							}
							{!isFirstLoading && (
								<>
									<div className='flex mb_10'>
										<div className="flex_item_full">
											<span className='fw_medium'>All products</span>
										</div>
										<div className="flex_item_fix ml_30">
											<div className='btn_group'>
												{chipsByFilterSelected && chipsByFilterSelected.map(ch =>
													<Chip
														key={ch.type}
														type={ch.type}
														title={ch.title}
														handleDelete={() => handleDeleteChip(ch.type)}
														size="s"
													/>
												)}
												<Btn
													style={'outline'}
													color={'secondary'}
													icon={'fa fa-filter fa-sm'}
													tooltip="Filter by category"
													horizontalSize="s"
													onClick={()=>handleOpenClosePanelForm(true)}
													disabled={isLoading}
												/>
											</div>
										</div>
										<div className='flex_item_fix ml_10'>
											<SearchBar
												placeholder={'Search for a product'}
												valueInSearchBar={valueInSearchBar}
												onChange={(value) => handleChange(value)}
												width="l"
												disabled={isLoading}
												color="white"
											/>
										</div>
										<div className='flex_item_fix ml_10'>
											<Btn
												onClick={() => fetchExportKpis()}
												tooltip={'Export data'}
												icon={'fa fa-download fa-sm'}
												color="secondary"
												style="outline"
												disabled={isEmpty || isLoading}
												isLoading={isExporting}
											/>
										</div>
									</div>
									<div className='pos_rel'>
										{isLoading &&
											<Spinner display="overlay" />
										}
										{isEmpty &&
											<EmptyState
												title="No products match these filters"
												text="Maybe try with other filters"
												imageUrl='/Assets/empty_no_data.svg'
												verticalSize='xl'
												textSize='l'
											/>
										}
										{!isEmpty &&
											<>
												<TableGridContainer hasAlternatingBackground={true} specialClasseName={styles.grid}>
													<TableHeadRow style={tableRowStyle} isSticky={true}>
														<TableHeadCol fontSize='s'>Product</TableHeadCol>
														<TableHeadCol
															sort={paginationOptions.orderBy === 'score' ? paginationOptions.directions : null}
															sortable={true}
															onClick={() => handleChangePaginationOptions('orderBy', 'score')}
															tooltip="Attractiveness score from 0 to 100"
															fontSize='s'>
															Attractiveness score
														</TableHeadCol>
														<TableHeadCol
															sortable={true}
															sort={paginationOptions.orderBy === 'numberofsessions' ? paginationOptions.directions : null}
															onClick={() => handleChangePaginationOptions('orderBy', 'numberofsessions')}
															align="right"
															tooltip="Number of visits"
															fontSize='s'>
															Visits
														</TableHeadCol>
														<TableHeadCol
															sort={paginationOptions.orderBy === 'numberofcartadded' ? paginationOptions.directions : null}
															sortable={true}
															onClick={() => handleChangePaginationOptions('orderBy', 'numberofcartadded')}
															align="right"
															tooltip="Number of add-to-cart"
															fontSize='s'>
															Add to cart
														</TableHeadCol>
														<TableHeadCol
															sort={paginationOptions.orderBy === 'numberofabandonments' ? paginationOptions.directions : null}
															sortable={true}
															onClick={() => handleChangePaginationOptions('orderBy', 'numberofabandonments')}
															align="right"
															tooltip="Number of abandoned carts"
															fontSize='s'>
															Abandoned cart
														</TableHeadCol>
														<TableHeadCol
															sort={paginationOptions.orderBy === 'numberofobjectives' ? paginationOptions.directions : null}
															sortable={true}
															onClick={() => handleChangePaginationOptions('orderBy', 'numberofobjectives')}
															align="right"
															tooltip="Number of transactions"
															fontSize='s'>
															Transactions
														</TableHeadCol>
														<TableHeadCol
															sort={paginationOptions.orderBy === 'cartaddedrate' ? paginationOptions.directions : null}
															sortable={true}
															onClick={() => handleChangePaginationOptions('orderBy', 'cartaddedrate')}
															align="right"
															fontSize='s'
															tooltip="Number of add-to-cart / Number of visits">
															Add to cart rate
														</TableHeadCol>
														<TableHeadCol
															sort={paginationOptions.orderBy === 'abandonmentrate' ? paginationOptions.directions : null}
															sortable={true}
															onClick={() => handleChangePaginationOptions('orderBy', 'abandonmentrate')}
															align="right"
															tooltip="Number of abandoned carts / Number of visits"
															fontSize='s'>
															Abandoned carts rate
														</TableHeadCol>
													</TableHeadRow>
													{productsSelectionsType != 'comparison' && productsSelections.map((s, i) => (
														<SegmentItem
															key={s.AssociatedProduct.Id + '_' + i}
															product={s}
															tableRowStyle={tableRowStyle}
														/>
													))}
													{productsSelectionsType == 'comparison' && productsSelections.map((s, i) => (
														<ProductItemCompared
															key={s.AssociatedProduct.Id + '_' + i}
															product={s}
															tableRowStyle={tableRowStyle}
														/>
													))}
												</TableGridContainer>
												<PaginationProduct
													totalCount={totalCount}
													pageSize={paginationOptions.rowsperpage}
													paginate={(e) => {
														handleChangePaginationOptions('poffset', e >= 0 ? e : 0);
													}}
													currentPage={currentPageProductList}
													setCurrentPage={(e) => setCurrentPageProductList(e)}
												/>
											</>
										}
									</div>
								</>
							)}
						</Article>
					</section>
				</Section>
			</>}

		</>
	);
};