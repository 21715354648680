import React, {useEffect, useState} from 'react';
import { getCampaignsNotificationsSource } from '../Reporting/Queries';
import { onSiteContext } from '../../Dashboard/OnSite/context/index';
import {NotificationCenterItem} from './NotificationCenterItem';
import NotificationCenterCreationButton from './NotificationCenterCreationButton';
import styles from './NotificationCenter.module.css';

export default function NotificationCenterList() {
	const {
		client,
		accountId,
		systemServices,
		isAdmin,
		$routeParams,
		permissionsAccount,
		needFetchList
	} = onSiteContext();

	const andFilters = {technicalPurpose: {in: ['NOTIFICATION_CENTER']}};
	const [centersAreOpen, setCentersAreOpen] = useState(false);
	const [notificationSourcesArray, setNotificationSourcesArray] = useState([]);

	/// Notifs
	/// center ? Notifs
	const getCenter = ():void => {
		client
			.query({
				query: getCampaignsNotificationsSource,
				variables: {
					accountId: accountId,
					// order: [orderCampaignsBy.query],

					where: {
						and: andFilters,
					},
				},
			})
			.then((response) => {
				setNotificationSourcesArray(response.data.account.onSiteCampaigns.items);
			})
			.catch((e) => {
				console.log('err', e);
				systemServices.showError('an error occured');
			});
	};

	const getCenterListClass = (nb:number):string => {
		const cssClass = [styles.centerListItems];
		if (nb === 1) {
			cssClass.push(styles.centerListItems_1_item);
		} else if (nb === 2) {
			cssClass.push(styles.centerListItems_2_item);
		} else {
			cssClass.push(styles.centerListItems_3_item);
		}
		return cssClass.join(' ');
	};

	useEffect(() => {
		getCenter();
	}, [needFetchList]);

	return (
		<div className={styles.centerList}>
			{notificationSourcesArray && notificationSourcesArray.length > 0 &&
				<div className={getCenterListClass(notificationSourcesArray.length)}>
					{notificationSourcesArray.map((center:any) => {
						return (
							<NotificationCenterItem
								key={center.id}
								center={center}
								permissionsAccount={permissionsAccount}
								isAdmin={isAdmin}
								accountId={accountId}
								$routeParams={$routeParams}
								centersAreOpen={centersAreOpen}
								setCentersAreOpen={setCentersAreOpen}
							/>
						);
					})}
				</div>
			}
			<NotificationCenterCreationButton
				isAdmin={isAdmin}
				$routeParams={$routeParams}
				permissionsAccount={permissionsAccount}
			/>
		</div>
	);
}
