import React , {useState, useRef} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import styles from './Survey.module.css';
import 'codemirror/theme/ttcn.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css';
import 'codemirror/keymap/sublime';
import Overview from './Overview';
import { useFormContext } from './ContextFormAndSurvey';

const editorOpts = {
	toolbar_items_size: 'small',
	statusbar: false,
	height: 300,
	fontsize_formats: '8px 10px 11px 12px 13px 14px 15px 16px 17px 18px 19px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 42px 44px 46px 48px 50px 52px 54px 56px 58px 60px 62px 64px 66px 68px 70px 72px',
	font_formats: `
		Arial=arial,helvetica,sans-serif;
		Helvetica=helvetica,arial,sans-serif;
		Verdana=verdana,helvetica,sans-serif;
		Courier=courier new,courier,monospace;
	`,
	font_css: ['https://tag.beyable.com/fonts/_v1/all.min.css'],
	content_style: 'p {margin-top: 0; margin-bottom: 0;}',
	plugins: [
		'autolink link',
		'code',
		'emoticons',
		'paste'
	],
	menubar: 'edit insert format tools',
	toolbar: [
		'bold italic underline | alignleft aligncenter alignright | forecolor backcolor',
		'fontsizeselect | lineheight | fontselect'
	],
	paste_as_text: true
};

function ThanksSection({
	setInputValue,
	inputValue,
	state
}) {

	const { updatePanel } = useFormContext();
	const editorRef = useRef(null);

	// const fontMigration = [
	// 	{ old: "Bodoni Moda", new: "'BY Bodoni Moda', serif"},
	// 	{ old: "Fira Sans", new: "'BY Fira Sans', sans-serif"},
	// 	{ old: "Inter", new: "'BY Inter', sans-serif"},
	// 	{ old: "Josefin Sans", new: "'BY Josefin Sans', sans-serif"},
	// 	{ old: "lato", new: "'BY Lato', sans-serif"},
	// 	{ old: "Montserrat", new: "'BY Montserrat', sans-serif"},
	// 	{ old: "Nunito Sans", new: "'BY Nunito Sans', sans-serif"},
	// 	{ old: "Open Sans", new: "'BY Open Sans', sans-serif"},
	// 	{ old: "pacifico", new: "'BY Pacifico', cursive"},
	// 	{ old: "Playfair Display", new: "'BY Playfair Display', serif"},
	// 	{ old: "Poppins", new: "'BY Poppins', sans-serif"},
	// 	{ old: "PT sans", new: "'BY PT Sans', sans-serif"},
	// 	{ old: "Raleway", new: "'BY Raleway', sans-serif"},
	// 	{ old: "roboto", new: "'BY Roboto', sans-serif"},
	// 	{ old: "Source Sans Pro", new: "'BY Source Sans Pro', sans-serif"},
	// ];

	const {stepSurvey} = useFormContext();


	const onEditorValueChange = (e) => {
		setInputValue(e);

		updatePanel(null, null, {thanksSection : e});
		
	};
	
	if(stepSurvey !== 2){
		return null;
	}
	if (editorRef.current) {
		editorRef.current.addShortcut('meta+b', '', '');
		editorRef.current.addShortcut('meta+u', '', '');
		editorRef.current.addShortcut('meta+i', '', '');
	}

	return (
		<>
			<div className={styles.col_editor}>
				<div className={'vscroll_custom ' + styles.editor_body}>
					{editorOpts &&
						<>
							<div className='h3 mb_15'>Thank you message</div>
							<Editor
								apiKey='no-api-key'
								licenseKey="gpl"
								tinymceScriptSrc='/Scripts/tinymce/tinymce.min.js'
								init={editorOpts}
								value={inputValue}
								onInit={(ev, editor) => editorRef.current = editor}
								onEditorChange={(value, editor) => onEditorValueChange(value)}
							/>
						</>
					}
				</div>
			</div>
			<div className={styles.col_preview}>
				<Overview
					thankSectionValue={inputValue}
					state={state}
				/>
			</div>
		</>
	);
}

export default ThanksSection;